import { InstrumentRiskProfile, InvestorProfile } from "../financial";
import { DataSheetSection } from "./DataSheetSection";

export const AssetInvestmentObjectiveDataSheetSectionTypeName =
  "mesh::marketData/AssetInvestmentObjective";

export const AssetInvestmentObjectiveDataSheetSectionName =
  "Investment Objective";

export class AssetInvestmentObjectiveDataSheetSection
  implements DataSheetSection
{
  public ["@type"] = AssetInvestmentObjectiveDataSheetSectionTypeName;

  public investmentObjective = "";

  public socialMediaLinks?: SocialMediaLinks;

  public riskSection: AssetInvestmentObjectiveRiskSection | undefined =
    undefined;

  constructor(l?: AssetInvestmentObjectiveDataSheetSection) {
    if (!l) {
      return;
    }

    this.investmentObjective = l.investmentObjective;
    this.socialMediaLinks = l.socialMediaLinks;
    if (l.riskSection) {
      this.riskSection = new AssetInvestmentObjectiveRiskSection(l.riskSection);
    }
  }

  public DataSheetSectionName(): string {
    return AssetInvestmentObjectiveDataSheetSectionName;
  }
}

export type SocialMediaLinks = {
  web: string;
  facebook: string;
  linkedIn: string;
  youtube: string;
  instagram: string;
  twitter: string;
};

export class AssetInvestmentObjectiveRiskSection {
  public instrumentRiskProfile: InstrumentRiskProfile | "" = "";

  public investorProfile: InvestorProfile | "" = "";

  constructor(l?: AssetInvestmentObjectiveRiskSection) {
    if (!l) {
      return;
    }
    this.instrumentRiskProfile = l.instrumentRiskProfile;
    this.investorProfile = l.investorProfile;
  }
}
