import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BigNumber } from "bignumber.js";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Edit as EditIcon,
  Info as InfoIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Refresh as ReloadIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import {
  MarketSubscriptionOrderBookSettlementViewReader,
  MarketSubscriptionOrderBookSettlementViewUnscopedReader,
  MarketSubscriptionOrderBookSettlementViewModel,
} from "james/views/marketSubscriptionOrderBookSettlementView";
import { TextExactCriterion } from "james/search/criterion";
import { TransactionSignatureController } from "james/stellar/TransactionSignatureController";
import { SubscriptionOrderBookStateChip } from "components/MarketSubscriptions/Chips";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";
import { TransactionState } from "@mesh/common-js/dist/ledger/transaction_pb";
import { Amount } from "components/Ledger/Amount";
import { LoadingBar } from "components/LoadingBar/LoadingBar";
import { SubscriptionUnderwritingTable } from "components/MarketSubscriptions";
import { SubscriptionOrderTable } from "components/MarketSubscriptions/SubscriptionOrderTable";
import {
  TransactionSucceededNotificationTypeName,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionFailedNotification,
  TransactionSubmissionResolutionFailedNotification,
} from "james/ledger/TransactionNotifications";
import { useNotificationContext } from "context/Notification/Notification";
import { Notification } from "james/notification/Notification";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import { enqueueSnackbar } from "notistack";
import { useIsMounted } from "hooks";
import { CancellationConfirmationDialog } from "./components";
import { useStellarAccountContext } from "context/Account/StellarAccount";
import { LedgerIDIdentifier } from "james/search/identifier";
import { UnderwritingManagementDialog } from "./components/UnderwritingManagementDialog";
import { useSubscriptionOrderBookContext, ViewMode } from "../../Context";
import { TextNumField } from "components/FormFields";
import { unitToString } from "@mesh/common-js/dist/financial";
import { MechanismType } from "james/market";
import { useAPIContext } from "context/API";
import { DownloadSubscriptionOrderBookRequest } from "@mesh/common-js/dist/market/subscriptionOrderBookDownloader_pb";
import { downloadExcelFile } from "utilities/network/download";

export type Props = {
  system: boolean;
  assetName: string;
};

export const SubscriptionOrderBookDetail = (props: Props) => {
  const isMounted = useIsMounted();
  const {
    market: { subscriptionOrderBookDownloader },
  } = useAPIContext();
  const { checkUserSignatoryOnAccount } = useStellarAccountContext();
  const {
    initialised: contextInitialised,
    viewMode,
    settlementTransactionID,
    settlementTransactionState,
    reload: contextReload,
    formData,
    potentialSourceAccounts,
    startUnitPriceUpdate,
    saveUnitPriceUpdate,
    cancelUnitPriceUpdate,
    smartInstrumentUnit,
    formUpdater,
    formDataValidationResult,
    apiCallInProgress,
  } = useSubscriptionOrderBookContext();
  const getFieldValidation = (field: string) => {
    return formDataValidationResult.fieldValidations[
      `${MechanismType.Subscription}-${field}`
    ];
  };

  const { registerNotificationCallback } = useNotificationContext();
  const { authContext, viewConfiguration } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultWarningFeedback } =
    useErrorContext();
  const [showCancellationDialog, setShowCancellationDialog] = useState(false);
  const [showUnderwriterManagementDialog, setShowUnderwriterManagementDialog] =
    useState(false);

  const [reloadToggle, setReloadToggle] = useState(false);
  const reload = () => {
    contextReload();
    setReloadToggle((prev) => !prev);
  };

  const [settlementModel, setSettlementModel] = useState<
    MarketSubscriptionOrderBookSettlementViewModel | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      // if book not yet set then do nothing
      if (formData.subscriptionOrderBook.id === "") {
        return;
      }

      // if model already set and is for the current book token then do nothing
      if (
        settlementModel &&
        settlementModel.outstandingTokens.token.isEqualTo(
          formData.subscriptionOrderBook.token,
        )
      ) {
        return;
      }
      try {
        setSettlementModel(
          props.system
            ? (
                await MarketSubscriptionOrderBookSettlementViewUnscopedReader.UnscopedReadOne(
                  {
                    context: authContext,
                    criteria: {
                      id: TextExactCriterion(formData.subscriptionOrderBook.id),
                    },
                  },
                )
              ).model
            : (
                await MarketSubscriptionOrderBookSettlementViewReader.ReadOne({
                  context: authContext,
                  criteria: {
                    id: TextExactCriterion(formData.subscriptionOrderBook.id),
                  },
                })
              ).model,
        );
      } catch (e) {
        errorContextDefaultWarningFeedback(
          e,
          "error reading subscription order settlement view",
        );
      }
    })();
  }, [formData.subscriptionOrderBook, authContext, reloadToggle]);

  const [issuanceAccSignatory, setIssuanceAccSignatory] = useState(true);
  const [tradingAccSignatory, setTradingAccSignatory] = useState(true);
  useEffect(() => {
    (async () => {
      if (!potentialSourceAccounts.length || !contextInitialised) {
        return;
      }
      try {
        setIssuanceAccSignatory(
          await checkUserSignatoryOnAccount(
            LedgerIDIdentifier(formData.subscriptionOrderBook.token.issuer),
          ),
        );
        setTradingAccSignatory(
          await checkUserSignatoryOnAccount(
            LedgerIDIdentifier(
              formData.subscriptionOrderBook.sourceAccountLedgerID,
            ),
          ),
        );
      } catch (e) {
        console.error("error determing signatory status", e);
      }
    })();
  }, [potentialSourceAccounts, settlementModel, contextInitialised]);

  const [signingInProgress, setSigningInProgress] = useState(false);
  const handleSign = useCallback(async () => {
    if (!settlementTransactionID || settlementTransactionID === "") {
      return;
    }

    // indicate that signing is in progress
    setSigningInProgress(true);
    try {
      await TransactionSignatureController.AddMyMeshKeySignatureToTransaction({
        context: authContext,
        transactionID: settlementTransactionID,
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error signing: ${err.message ? err.message : err.toString()}`,
      );
      errorContextDefaultWarningFeedback(e, "error signing for settlement");
      setSigningInProgress(false);
    }

    // notify that signing was successful
    enqueueSnackbar(`Signing Successful - Settlement in Progress`, {
      variant: "success",
    });

    // register for listing notification feedback
    try {
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: settlementTransactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (!settlementTransactionID || settlementTransactionID === "") {
            return;
          }

          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === settlementTransactionID
          ) {
            if (isMounted()) {
              reload();
              setSigningInProgress(false);
            }
            enqueueSnackbar(`Settlement Successful`, {
              variant: "success",
            });
          } else if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === settlementTransactionID
          ) {
            if (isMounted()) {
              reload();
              setSigningInProgress(false);
            }
            enqueueSnackbar(
              "Settlement Failed. Settlement Will be Retried. Please Refresh Until You Can Sign Again.",
              {
                variant: "warning",
              },
            );
          } else if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === settlementTransactionID
          ) {
            if (isMounted()) {
              reload();
              setSigningInProgress(false);
            }
            enqueueSnackbar(
              "Something Has Gone Wrong With Settlement - Please Contact Support",
              { variant: "warning" },
            );
          }
          deregister();
        },
      );
    } catch (e) {
      setSigningInProgress(false);
      console.error("error registering to monitor settlement transaction", e);
      enqueueSnackbar("Refresh to Monitor Settlement Progress", {
        variant: "success",
      });
    }
  }, [authContext]);

  return (
    <>
      <CardContent
        sx={{
          pt: 3,
          px: 4,
          pb: 5,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            borderBottom: `${theme.palette.divider} 1px solid`,
            alignItems: "center",
          })}
        >
          <Typography
            variant={"h4"}
            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          >
            Book {formData.subscriptionOrderBook.number}
          </Typography>
          <SubscriptionOrderBookStateChip
            state={formData.subscriptionOrderBook.state}
          />
          {viewConfiguration?.["Subscription"]?.["Subscription Order Book"]?.[
            "Download"
          ] && (
            <Tooltip placement="top" title={"Download Book Data"}>
              <span>
                <IconButton
                  onClick={async () => {
                    try {
                      const response =
                        await subscriptionOrderBookDownloader.downloadSubscriptionOrderBook(
                          new DownloadSubscriptionOrderBookRequest()
                            .setContext(authContext.toFuture())
                            .setSubscriptionorderbookid(
                              formData.subscriptionOrderBook.id,
                            ),
                        );
                      downloadExcelFile({
                        encodedString: response.getExceldata(),
                        fileName: "subscription-order-book",
                      });
                    } catch (e) {
                      console.error("error downloading book data", e);
                    }
                  }}
                  id={"subscriptionOrderBookView-downloadData-iconButton"}
                  size={"small"}
                  disabled={
                    viewMode === ViewMode.UpdatePrice || apiCallInProgress
                  }
                >
                  <DownloadIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {signingInProgress && <CircularProgress size={20} />}
          <Box sx={{ marginLeft: "auto" }} />
          {viewConfiguration?.["Subscriptions"]?.["Sign for Settlement"] &&
            formData.subscriptionOrderBook.state ===
              SubscriptionOrderBookState.PreparingSettlement &&
            settlementTransactionState ===
              TransactionState.SIGNING_IN_PROGRESS_TRANSACTION_STATE && (
              <Box sx={{ marginLeft: "auto" }}>
                <Tooltip
                  title={(() => {
                    if (signingInProgress) {
                      return "Loading...";
                    }

                    if (!(issuanceAccSignatory || tradingAccSignatory)) {
                      return "You are not a signatory on either the issuance or trading account";
                    }

                    if (!issuanceAccSignatory) {
                      return "You are not a signatory on the asset issuance account";
                    }

                    if (!tradingAccSignatory) {
                      return "You are not a signatory on the owner group trading account";
                    }

                    return "";
                  })()}
                >
                  <span>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      disabled={
                        signingInProgress ||
                        !(issuanceAccSignatory && tradingAccSignatory)
                      }
                      onClick={handleSign}
                    >
                      Sign for Settlement
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          {viewConfiguration?.["Subscriptions"]?.["Request Cancellation"] &&
            (
              [
                SubscriptionOrderBookState.Pending,
                SubscriptionOrderBookState.Open,
                SubscriptionOrderBookState.Closed,
              ] as (SubscriptionOrderBookState | "")[]
            ).includes(formData.subscriptionOrderBook.state) && (
              <Button
                variant={"contained"}
                color={"primary"}
                disabled={
                  viewMode === ViewMode.UpdatePrice || apiCallInProgress
                }
                onClick={() => setShowCancellationDialog(true)}
              >
                Cancel
              </Button>
            )}
          <Tooltip title={"Refresh"}>
            <span>
              <IconButton
                onClick={reload}
                id={"subscriptionOrderBookView-refresh-iconButton"}
                size={"small"}
                disabled={
                  viewMode === ViewMode.UpdatePrice || apiCallInProgress
                }
              >
                <ReloadIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            columnGap: 5,
            rowGap: 2,
          }}
        >
          <>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Open Date
              </Typography>
              <Typography
                id={"primaryMarket-subscriptionOrderBook-openDate"}
                variant="h5"
              >
                {dayjs(formData.subscriptionOrderBook.openDate).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Close Date
              </Typography>
              <Typography
                variant="h5"
                id={"primaryMarket-subscriptionOrderBook-closeDate"}
              >
                {dayjs(formData.subscriptionOrderBook.closeDate).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Settlement Date
              </Typography>
              <Typography
                variant="h5"
                id={"primaryMarket-subscriptionOrderBook-settlementDate"}
              >
                {dayjs(formData.subscriptionOrderBook.settlementDate).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Source Account
              </Typography>
              <Typography
                id={"primaryMarket-subscriptionOrderBook-sourceAccount"}
                variant="h5"
              >
                {(() => {
                  const acc = potentialSourceAccounts.find(
                    (acc) =>
                      acc.getLedgerid() ===
                      formData.subscriptionOrderBook.sourceAccountLedgerID,
                  );
                  return acc ? `${acc.getLabel()} - ${acc.getNumber()}` : "-";
                })()}
              </Typography>
            </Box>
          </>

          <>
            <Box>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: theme.spacing(0.2),
                })}
              >
                <Typography
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                  variant={"body2"}
                >
                  {unitToString(smartInstrumentUnit)} Price -
                </Typography>
                {formData.subscriptionOrderBook.flexibleUnitPrice ? (
                  <Typography
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                      color: theme.palette.success.main,
                    })}
                  >
                    FLEXIBLE
                  </Typography>
                ) : (
                  <Typography
                    variant={"body2"}
                    sx={(theme) => ({
                      fontWeight: "bold",
                      color: theme.palette.text.secondary,
                    })}
                  >
                    Cannot Change
                  </Typography>
                )}
                <Typography
                  variant={"body2"}
                  sx={(theme) => ({
                    fontWeight: "bold",
                    color: theme.palette.text.secondary,
                  })}
                >
                  -
                </Typography>
                {formData.subscriptionOrderBook.fractionalisationAllowed ? (
                  <Typography
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                      color: theme.palette.success.main,
                    })}
                  >
                    FRACTIONISABLE
                  </Typography>
                ) : (
                  <Typography
                    variant={"body2"}
                    sx={(theme) => ({
                      fontWeight: "bold",
                      color: theme.palette.text.secondary,
                    })}
                  >
                    Integer Units Only
                  </Typography>
                )}
              </Box>
              {viewMode === ViewMode.UpdatePrice ? (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: theme.spacing(1),
                  })}
                >
                  <TextNumField
                    id={
                      "marketListing-mechanisms-subscriptionMechanism-unitPrice-textNumField"
                    }
                    disabled={apiCallInProgress}
                    fullWidth
                    noDecimalPlaces={7}
                    disallowNegative
                    value={formData.subscriptionOrderBook.unitPrice.value}
                    sx={{ maxWidth: 320 }}
                    onChange={(e) => {
                      formData.subscriptionOrderBook.unitPrice =
                        formData.subscriptionOrderBook.unitPrice.setValue(
                          e.target.value,
                        );
                      formUpdater.subscriptionOrderBook(
                        formData.subscriptionOrderBook,
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                          {formData.subscriptionOrderBook.unitPrice.token.code}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          children={
                            <Tooltip
                              placement="top"
                              title={`Cost of a single ${unitToString(smartInstrumentUnit)}`}
                            >
                              <InfoIcon
                                sx={(theme) => ({
                                  cursor: "pointer",
                                  color: theme.palette.text.secondary,
                                  width: "16px",
                                  height: "16px",
                                  mb: 0.5,
                                })}
                              />
                            </Tooltip>
                          }
                        />
                      ),
                    }}
                    error={!!getFieldValidation("unitPrice")}
                    helperText={getFieldValidation("unitPrice")}
                  />
                  <Tooltip
                    placement="top"
                    title={(() => {
                      if (!formDataValidationResult.valid) {
                        const problems: string[] = [];
                        Object.keys(
                          formDataValidationResult.fieldValidations,
                        ).forEach((k) => {
                          if (formDataValidationResult.fieldValidations[k]) {
                            problems.push(
                              `${k}: ${formDataValidationResult.fieldValidations[k]}`,
                            );
                          }
                        });
                        return (
                          <Box>
                            <Typography>
                              Resolve Problems or Cancel Update
                            </Typography>
                            <ul>
                              {problems.map((p, idx) => (
                                <li key={idx}>{p}</li>
                              ))}
                            </ul>
                          </Box>
                        );
                      }
                      return "Save Changes";
                    })()}
                  >
                    <span>
                      <IconButton
                        id={"primaryMarket-subscriptionOrderBook-unitPriceEdit"}
                        onClick={saveUnitPriceUpdate}
                        disabled={
                          !formDataValidationResult.valid || apiCallInProgress
                        }
                        sx={{
                          width: "24px",
                          height: "24px",
                          padding: "4px",
                        }}
                      >
                        <SaveIcon sx={{ fontSize: "20px" }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip placement="top" title={"Cancel Unit Price Update"}>
                    <IconButton
                      id={
                        "primaryMarket-subscriptionOrderBook-cancelUnitPriceEdit"
                      }
                      disabled={apiCallInProgress}
                      onClick={cancelUnitPriceUpdate}
                      sx={{
                        width: "24px",
                        height: "24px",
                        padding: "4px",
                      }}
                    >
                      <CancelIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: theme.spacing(1),
                  })}
                >
                  <Amount
                    id={"primaryMarket-subscriptionOrderBook-unitPrice"}
                    amount={formData.subscriptionOrderBook.unitPrice}
                    codeTypographyProps={{
                      variant: "h5",
                      sx: (theme) => ({
                        color: theme.palette.text.secondary,
                      }),
                    }}
                    valueTypographyProps={{
                      variant: "h5",
                    }}
                  />
                  {(
                    [
                      SubscriptionOrderBookState.Pending,
                      SubscriptionOrderBookState.Open,
                      SubscriptionOrderBookState.Closed,
                    ] as (SubscriptionOrderBookState | "")[]
                  ).includes(formData.subscriptionOrderBook.state) &&
                    viewConfiguration?.["Subscriptions"]?.[
                      "Update Unit Price"
                    ] &&
                    formData.subscriptionOrderBook.flexibleUnitPrice && (
                      <Tooltip placement="top" title={"Update Unit Price"}>
                        <IconButton
                          id={
                            "primaryMarket-subscriptionOrderBook-unitPriceEdit"
                          }
                          onClick={startUnitPriceUpdate}
                          sx={{
                            width: "24px",
                            height: "24px",
                            padding: "4px",
                          }}
                        >
                          <EditIcon sx={{ fontSize: "20px" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                </Box>
              )}
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Subscription Target
              </Typography>
              <Amount
                id={"primaryMarket-subscriptionOrderBook-subscriptionAmount"}
                amount={formData.subscriptionOrderBook.subscriptionAmount}
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Over Subscription Maximum
              </Typography>
              <Amount
                id={
                  "primaryMarket-subscriptionOrderBook-overSubscriptionAmount"
                }
                amount={formData.subscriptionOrderBook.overSubscriptionAmount}
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Public Display Threshold
              </Typography>
              <Amount
                id={
                  "primaryMarket-subscriptionOrderBook-marketingThresholdSubscriptionAmount"
                }
                amount={
                  formData.subscriptionOrderBook
                    .marketingThresholdSubscriptionAmount
                }
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
          </>

          <>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                {unitToString(smartInstrumentUnit)} Price Description
              </Typography>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body1"}
              >
                {formData.subscriptionOrderBook.unitPriceDescription === ""
                  ? "-"
                  : formData.subscriptionOrderBook.unitPriceDescription}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                variant={"body2"}
              >
                Minimum Order Amount
              </Typography>
              <Amount
                id={"primaryMarket-subscriptionOrderBook-minimumOrderamount"}
                amount={formData.subscriptionOrderBook.minimumOrderAmount}
                codeTypographyProps={{
                  variant: "h5",
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h5",
                }}
              />
            </Box>
          </>
        </Box>
      </CardContent>

      {/* Settlement Information */}
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={(theme) => ({
            pl: 4,
            flexDirection: "row-reverse",
            backgroundColor: theme.palette.custom.midnight,
          })}
          expandIcon={
            <ExpandMoreIcon
              id="primaryMarket-settlementInformation-accordionToggleIconButton"
              color="primary"
            />
          }
        >
          <Typography sx={{ ml: 4 }} variant={"h6"}>
            Settlement Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={(theme) => ({
            padding: theme.spacing(3, 4, 5, 4),
            display: "flex",
            flexDirection: "column",
            gap: 1,
          })}
        >
          {settlementModel ? (
            <>
              <Box>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: theme.spacing(2),
                  })}
                >
                  <Box>
                    <Typography
                      variant={"body2"}
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      Amount Subscribed
                    </Typography>
                    <Amount
                      codeTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          fontWeight: theme.typography.fontWeightBold,
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      valueTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          fontWeight: theme.typography.fontWeightBold,
                        }),
                      }}
                      amount={settlementModel.subscribedAmount}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      marginLeft: "auto",
                    }}
                  >
                    <Typography
                      variant={"body2"}
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      Outstanding Amount
                    </Typography>
                    <Amount
                      codeTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          fontWeight: theme.typography.fontWeightBold,
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      valueTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          fontWeight: theme.typography.fontWeightBold,
                        }),
                      }}
                      amount={settlementModel.outstandingAmount}
                    />
                  </Box>
                </Box>
                <LoadingBar
                  percent={settlementModel.subscribedAmount.value
                    .dividedBy(
                      formData.subscriptionOrderBook.subscriptionAmount.value,
                    )
                    .multipliedBy(new BigNumber(100))}
                />
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    marginTop: theme.spacing(2),
                  })}
                >
                  <Box>
                    <Amount
                      codeTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          fontWeight: theme.typography.fontWeightBold,
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      valueTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          fontWeight: theme.typography.fontWeightBold,
                        }),
                      }}
                      amount={settlementModel.subscribedTokens}
                    />
                    <Typography
                      variant={"body2"}
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      Tokens Subscribed
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      marginLeft: "auto",
                    }}
                  >
                    <Amount
                      codeTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          fontWeight: theme.typography.fontWeightBold,
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      valueTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          fontWeight: theme.typography.fontWeightBold,
                        }),
                      }}
                      amount={settlementModel.outstandingTokens}
                    />
                    <Typography
                      variant={"body2"}
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      Outstanding Tokens
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {/* ------------------ Settlement Amount ------------------ */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    columnGap: 5,
                    rowGap: 2,
                  }}
                >
                  <Box>
                    <Typography
                      variant={"body2"}
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      Total Settlement Amount
                    </Typography>
                    <Amount
                      codeTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      valueTypographyProps={{
                        variant: "h5",
                      }}
                      amount={settlementModel.settlementAmount}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant={"body2"}
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      Left Over Amount
                    </Typography>
                    <Amount
                      codeTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      valueTypographyProps={{
                        variant: "h5",
                      }}
                      amount={settlementModel.settlementAmount.setValue(
                        settlementModel.leftOverAmount,
                      )}
                    />
                  </Box>
                </Box>

                {/* ------------------ Settlement Tokens ------------------ */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    columnGap: 5,
                    rowGap: 2,
                  }}
                >
                  <Box>
                    <Typography
                      variant={"body2"}
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      Total Settlement Tokens
                    </Typography>
                    <Amount
                      codeTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      valueTypographyProps={{
                        variant: "h5",
                      }}
                      amount={settlementModel.settlementTokens}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant={"body2"}
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      Left Over Tokens
                    </Typography>
                    <Amount
                      codeTypographyProps={{
                        variant: "h5",
                        sx: (theme) => ({
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      valueTypographyProps={{
                        variant: "h5",
                      }}
                      amount={settlementModel.settlementTokens.setValue(
                        settlementModel.leftOverTokens,
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "1fr",
                rowGap: theme.spacing(2),
                alignItems: "center",
                justifyItems: "center",
              })}
            >
              <CircularProgress size={40} />
              <Typography
                variant={"h5"}
                color={"textSecondary"}
                children={"Getting things ready for you..."}
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Underwriters  - not yet supported for subscriptions with either a flexible unit price or where fractionalisation is allowed */}
      {!(
        formData.subscriptionOrderBook.fractionalisationAllowed ||
        formData.subscriptionOrderBook.flexibleUnitPrice
      ) && (
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={(theme) => ({
              pl: 4,
              flexDirection: "row-reverse",
              backgroundColor: theme.palette.custom.midnight,
            })}
            expandIcon={
              <ExpandMoreIcon
                id="primaryMarket-underwriters-accordionToggleIconButton"
                color="primary"
              />
            }
          >
            <Typography sx={{ ml: 4 }} variant={"h6"}>
              Underwriters
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              pt: 3,
              px: 4,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            {settlementModel && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  columnGap: 5,
                }}
              >
                <Box>
                  <Typography
                    variant={"body2"}
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    Total Underwriter Take Up Amount
                  </Typography>
                  <Amount
                    codeTypographyProps={{
                      variant: "h5",
                      sx: (theme) => ({
                        color: theme.palette.text.secondary,
                      }),
                    }}
                    valueTypographyProps={{
                      variant: "h5",
                    }}
                    amount={settlementModel.underwriterTakeUpAmount}
                  />
                </Box>
                <Box>
                  <Typography
                    variant={"body2"}
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    Total Underwriter Take Up Tokens
                  </Typography>
                  <Amount
                    codeTypographyProps={{
                      variant: "h5",
                      sx: (theme) => ({
                        color: theme.palette.text.secondary,
                      }),
                    }}
                    valueTypographyProps={{
                      variant: "h5",
                    }}
                    amount={settlementModel.underwriterTakeUpTokens}
                  />
                </Box>
                {viewConfiguration?.["Subscriptions"]?.["Underwriter"]?.[
                  "Add"
                ] &&
                  (
                    [
                      SubscriptionOrderBookState.Pending,
                      SubscriptionOrderBookState.Open,
                      SubscriptionOrderBookState.Closed,
                    ] as (SubscriptionOrderBookState | "")[]
                  ).includes(formData.subscriptionOrderBook.state) && (
                    <Box sx={{ marginLeft: "auto" }}>
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => setShowUnderwriterManagementDialog(true)}
                        disabled={
                          viewMode === ViewMode.UpdatePrice || apiCallInProgress
                        }
                      >
                        Add Underwriter
                      </Button>
                    </Box>
                  )}
              </Box>
            )}
            <SubscriptionUnderwritingTable
              style={(theme) => ({
                ".BPTable-tableWrapper": {
                  backgroundColor: theme.palette.custom.grape,
                },
              })}
              system={props.system}
              height={400}
              subscriptionOrderBookID={formData.subscriptionOrderBook.id}
              hideColumns={["Res. Count", "Asset Name", "Book"]}
              showEmptyCard
            />
          </AccordionDetails>
        </Accordion>
      )}

      {/* Subscriptions */}
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={(theme) => ({
            pl: 4,
            flexDirection: "row-reverse",
            backgroundColor: theme.palette.custom.midnight,
          })}
          expandIcon={
            <ExpandMoreIcon
              id="primaryMarket-subscriptions-accordionToggleIconButton"
              color="primary"
            />
          }
        >
          <Typography sx={{ ml: 4 }} variant={"h6"}>
            Subscriptions
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            pt: 3,
            px: 4,
            pb: 5,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <SubscriptionOrderTable
            style={(theme) => ({
              ".BPTable-tableWrapper": {
                backgroundColor: theme.palette.custom.grape,
              },
            })}
            system={props.system}
            height={400}
            hideColumns={["Res. Count", "Asset Name", "Book"]}
            subscriptionOrderBookID={formData.subscriptionOrderBook.id}
            showEmptyCard
          />
        </AccordionDetails>
      </Accordion>

      {showCancellationDialog && (
        <CancellationConfirmationDialog
          assetName={props.assetName}
          subscriptionOrderBook={formData.subscriptionOrderBook}
          onClose={() => setShowCancellationDialog(false)}
        />
      )}

      {showUnderwriterManagementDialog && (
        <UnderwritingManagementDialog
          onClose={() => {
            setShowUnderwriterManagementDialog(false);
            reload();
          }}
          subscriptionOrderBook={formData.subscriptionOrderBook}
        />
      )}
    </>
  );
};

interface NoDataCardProps {
  title: string;
  subTitle: string[];
  refresh?: () => void;
  loading?: boolean;
}
export const NoDataCard = ({
  title,
  subTitle,
  refresh,
  loading,
}: NoDataCardProps) => (
  <Card
    sx={(theme) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.secondary,
      textAlign: "center",
      backgroundColor: theme.palette.custom.grape,
      height: 166,
      position: "relative",
    })}
  >
    {refresh && (
      <IconButton
        sx={{ position: "absolute", top: 4, right: 8 }}
        onClick={refresh}
      >
        <RefreshIcon />
      </IconButton>
    )}
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        <Typography
          sx={{
            mb: 2,
            fontWeight: "bold",
          }}
          variant="h3"
          children={title}
          color="textSecondary"
        />
        {subTitle.map((text, idx) => {
          return (
            <Typography
              key={idx}
              variant="body1"
              children={text}
              color="textSecondary"
            />
          );
        })}
      </>
    )}
  </Card>
);
