import React from "react";
import { DialogTitle, Button, Tooltip } from "@mui/material";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { ValidationResult } from "common/validation";

export interface InputFooterProps {
  isMobile: boolean;
  loading: boolean;
  assetName: string;
  formDataValidationResult: ValidationResult;
  setShowConfirmation: (showConfirmation: boolean) => void;
}

export const InputFooter = ({
  isMobile,
  loading,
  assetName,
  formDataValidationResult,
  setShowConfirmation,
}: InputFooterProps) => {
  return (
    <DialogTitle
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: { sm: 6, xs: 3 },
        pb: 4,
        pt: 3,
        boxShadow: {
          xs: "0px 0px 18px 4px rgba(0,0,0,0.4);",
          sm: 0,
        },
      }}
    >
      {" "}
      <Tooltip
        placement="bottom"
        arrow
        title={(() => {
          switch (true) {
            case !!formDataValidationResult.fieldValidations
              .selectedSourceAccountViewModel:
              return formDataValidationResult.fieldValidations
                .selectedSourceAccountViewModel;
            case !!formDataValidationResult.fieldValidations.investmentAmount:
              return formDataValidationResult.fieldValidations.investmentAmount;
            default:
              return "";
          }
        })()}
      >
        <span style={{ width: "100%" }}>
          <Button
            fullWidth={true}
            size={isMobile ? "large" : "medium"}
            variant="contained"
            color="primary"
            disabled={!formDataValidationResult.valid || loading}
            title={(() => {
              switch (true) {
                case !!formDataValidationResult.fieldValidations
                  .selectedSourceAccountViewModel:
                  return "You are not a signatory on the trading account";
              }
              return "";
            })()}
            onClick={() => {
              setShowConfirmation(true);
            }}
            data-link-info={JSON.stringify({
              content_interaction_id: assetName + "_subscription_form_complete",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: InteractionType.Button,
              content_interaction_driver:
                InteractionDriver.SubscriptionOrderComplete,
            } as DataLinkInfoType)}
          >
            Continue
          </Button>
        </span>
      </Tooltip>
    </DialogTitle>
  );
};
