import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  useTheme,
  Typography,
  Theme,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import {
  MarketplaceCard,
  MarketPlaceSkeletonCard,
} from "components/Cards/MarketplaceCard";
import { NewSorting, Query } from "james/search/query";
import { useIsMounted } from "hooks";
import cx from "classnames";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { useAppTourContext } from "context/AppTour/AppTour";
import { MarketListingViewModel } from "james/views/marketListingView";
import { useQueryClient } from "react-query";
import { Reader } from "james/views/marketListingView/Reader";
import {
  CheckQueryCacheDataValid,
  GenerateAPIRequestUniqueKey,
} from "utilities/reactQuery";
import { useRQMarketListingViewReader } from "hooks/reactQuery/useRQMarketListingViewReader";
import { FilterTabOpt, getFilterCriteria, pageSize, SearchOpts } from "./const";
import { OfferTabFilter } from "./component/OffersTabFilter/OfferTabFilter";
import { useErrorContext } from "context/Error";
import { TextField } from "components/FormFields";
import SearchIcon from "@mui/icons-material/Search";
import { AnimatePresence, motion } from "framer-motion";
import { range } from "lodash";
import { useSearchParams } from "react-router-dom";
import { Clear } from "@mui/icons-material";

const PREFIX = "Offers";

const classes = {
  cardWrapper: `${PREFIX}-cardWrapper`,
  titleLayout: `${PREFIX}-titleLayout`,
  titleLayoutMobile: `${PREFIX}-titleLayoutMobile`,
  boldText: `${PREFIX}-boldText`,
  loadMoreButton: `${PREFIX}-loadMoreButton`,
  nothingToShowText: `${PREFIX}-nothingToShowText`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.cardWrapper}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  [`& .${classes.titleLayout}`]: {},

  [`& .${classes.titleLayoutMobile}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  [`& .${classes.boldText}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.loadMoreButton}`]: {
    justifySelf: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.nothingToShowText}`]: {
    justifySelf: "center",
  },
}));

export const Offers = () => {
  const { errorContextErrorTranslator } = useErrorContext();
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { authContext } = useApplicationContext();
  const isMounted = useIsMounted();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [loadingModels, setLoadingModels] = useState(true);
  const [loadingMoreModels, setLoadingMoreModels] = useState(false);
  const [marketListingModels, setMarketListingModels] = useState<
    MarketListingViewModel[]
  >([]);
  const [totalMarketListingModels, setTotalMarketListingModels] = useState(0);
  const { Read: ReadMarketListingViewModel } = useRQMarketListingViewReader();
  const [searchParams, setSearchParams] = useSearchParams();

  // detect changes in filtering triggered from url
  const [searchOptions, setSearchOptions] = useState<SearchOpts>({
    tab: (searchParams.get("tab") as FilterTabOpt) ?? FilterTabOpt.All,
    textSearch: searchParams.get("search") ?? "",
  });
  const [query, setQuery] = useState<Query>(
    new Query({
      limit: pageSize,
      offset: 0,
      sorting: [NewSorting("priority", "desc"), NewSorting("id", "desc")],
    }),
  );
  const { registerElement } = useAppTourContext();
  const reactQueryClient = useQueryClient();
  const [loaderCount, setLoaderCount] = useState(0);

  // use effect to load models after the criteria has been changed
  // debounced to allow for loading to happen after user stops typing
  useEffect(() => {
    const deb = setTimeout(async () => {
      setLoadingModels(true);

      await retrieveModels();

      setLoadingModels(false);
    }, 450);

    return () => clearTimeout(deb);
  }, [searchOptions]);

  // subscribe to scroll events to load more data
  useEffect(() => {
    const marketplaceFullyScrolled = async () => {
      const remainingModels =
        totalMarketListingModels - marketListingModels.length;

      // if more than a page can still be loaded then show a page of skeletons
      // otherwise show as many skeletons as there are models remaining
      const noLoaders = remainingModels > pageSize ? pageSize : remainingModels;
      setLoaderCount(noLoaders);
      if (
        marketListingModels.length >= totalMarketListingModels ||
        loadingMoreModels ||
        query.offset > totalMarketListingModels - pageSize
      )
        return;
      setLoadingMoreModels(true);

      await handleLoadMore();
    };

    window.addEventListener(
      "marketplaceFullyScrolled",
      marketplaceFullyScrolled,
    );

    return () => {
      window.removeEventListener(
        "marketplaceFullyScrolled",
        marketplaceFullyScrolled,
      );
    };
  });

  // set loading counters back to 0 after loading asset listings
  useEffect(() => {
    if (loadingMoreModels || loadingModels) return;
    setLoaderCount(0);
  }, [marketListingModels, loadingModels, loadingMoreModels]);

  // Retrieve marketListingViewModels
  const retrieveModels = useCallback(async () => {
    // build the request to fetch market listing
    const request = {
      context: authContext,
      criteria: getFilterCriteria(searchOptions),
      query: new Query({
        ...query,
        sorting: [NewSorting("priority", "desc"), NewSorting("id", "desc")],
      }),
    };

    //
    // check if there is already a query that fetches that has the
    // data for the request and is still valid
    //

    const validQueryCacheDataAvailable = CheckQueryCacheDataValid(
      GenerateAPIRequestUniqueKey(Reader.serviceProvider, "Read", request),
      reactQueryClient,
    );

    // show skeleton loaders if data is not available
    if (!validQueryCacheDataAvailable) {
      setLoadingModels(true);
      setTotalMarketListingModels(0);
    }

    // defer update to take place in 200ms if data is not available
    try {
      const readResponse = await ReadMarketListingViewModel(request);
      if (isMounted()) {
        setTotalMarketListingModels(readResponse.total);
        setMarketListingModels(readResponse.models);
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        "Error fetching market listings, please try again later",
        { variant: "error" },
      );
      console.error(
        `error fetching models: ${err.message ? err.message : err.toString()}`,
      );
    }
  }, [loadingModels, searchOptions, query, isMounted, authContext]);

  // when load more button is pushed fetch models with selected filter criteria and
  // increment offset
  const handleLoadMore = useCallback(async () => {
    // do nothing if already fetching models
    if (loadingModels) {
      return;
    }
    setLoadingMoreModels(true);

    // increment offset
    const newOffset = query.offset + pageSize;
    setQuery(
      new Query({
        ...query,
        offset: newOffset,
      }),
    );

    // fetch more models
    try {
      const readResponse = await ReadMarketListingViewModel({
        context: authContext,
        criteria: getFilterCriteria(searchOptions),
        query: new Query({
          limit: pageSize,
          offset: newOffset,
          sorting: [NewSorting("priority", "desc"), NewSorting("id", "desc")],
        }),
      });
      if (isMounted()) {
        setTotalMarketListingModels(readResponse.total);
        setMarketListingModels((prevModels) => [
          ...prevModels,
          ...readResponse.models,
        ]);
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        "Error fetching market listings, please try again later",
        { variant: "error" },
      );
      console.error(
        `error fetching models: ${err.message ? err.message : err.toString()}`,
      );
    }

    if (isMounted()) {
      setLoadingMoreModels(false);
    }
  }, [loadingModels, searchOptions, query, isMounted, authContext]);

  return (
    <Root
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        minHeight: { sm: "calc(100% - 274px)" },
        gap: 2,
        my: {
          sm: 3,
        },
      }}
    >
      <Box
        className={cx({
          [classes.titleLayout]: !isSmall,
        })}
        sx={{
          display: "flex",
          alignItems: "center",
          pt: { xs: 2, sm: 0 },
          "&.Offers-titleLayout": {
            width: "100%",
          },
        }}
      >
        {!mdDown && (
          <Typography
            color={"textSecondary"}
            className={classes.boldText}
            variant={"h4"}
            children={"Marketplace"}
          />
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            ml: "auto",
            columnGap: 2,
          }}
        >
          <OfferTabFilter
            activeFilterTabOpt={searchOptions.tab}
            onChange={(newFilterTabOpt) => {
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("tab", String(newFilterTabOpt));
              setSearchParams(newSearchParams);
              setQuery(
                new Query({
                  ...query,
                  offset: 0,
                }),
              );
              setSearchOptions({
                ...searchOptions,
                tab: newFilterTabOpt,
              });
            }}
          />
          <TextField
            label={!searchOptions.textSearch ? "Search Assets" : ""}
            InputLabelProps={{
              sx: {
                ml: 4,
                mt: { sm: 0.5 },
                fontSize: 16,
                "& .Mui-focused": {
                  color: "white",
                },
              },
              shrink: false,
            }}
            value={searchOptions.textSearch}
            sx={(theme) => ({
              width: { md: 260, sm: 344, xs: 328 },
              ml: {
                md: 2,
              },
              backgroundColor: theme.palette.custom.cardInner,
              borderRadius: "10px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                  color: theme.palette.text.secondary,
                },
                pr: 1,
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.text.secondary,
              },
            })}
            onChange={(e) => {
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("search", String(e.target.value));
              setSearchParams(newSearchParams);
              setQuery(
                new Query({
                  ...query,
                  offset: 0,
                }),
              );
              setSearchOptions({
                ...searchOptions,
                textSearch: e.target.value,
              });
            }}
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: loadingModels ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 0.5,
                  }}
                >
                  <CircularProgress size={18} />
                </Box>
              ) : searchOptions.textSearch !== "" ? (
                <IconButton
                  sx={(theme) => ({
                    [theme.breakpoints.down("sm")]: {
                      backgroundColor: theme.palette.common.white,
                      color: theme.palette.background.default,
                      "&:hover": {
                        backgroundColor: theme.palette.text.secondary,
                        color: theme.palette.background.default,
                      },
                    },
                    width: 24,
                    height: 24,
                  })}
                  onClick={() => {
                    const newSearchParams = new URLSearchParams(searchParams);
                    newSearchParams.delete("search");
                    setSearchParams(newSearchParams);
                    setSearchOptions({ ...searchOptions, textSearch: "" });
                  }}
                >
                  <Clear />
                </IconButton>
              ) : null,
              sx: {
                gap: 1,
                height: { sm: 48, xs: 40 },
              },
            }}
          />
        </Box>
      </Box>
      {!(loadingModels || loadingMoreModels) && !marketListingModels.length ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            mt: 9,
          }}
        >
          <Typography
            color="textPrimary"
            fontWeight={"bold"}
            variant="h4"
            sx={{ mb: 2 }}
            children="No Results Found"
          />
          <Typography
            className={classes.nothingToShowText}
            color={"textSecondary"}
            variant={"h4"}
            children={"We couldn't find any assets matching your search."}
            sx={{ maxWidth: 300, mb: 3 }}
          />
          {loadingModels ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="secondary"
              children="Try Again"
              onClick={() => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.delete("search");
                setSearchParams(newSearchParams);
                setQuery(new Query({ ...query, offset: 0 }));
                setSearchOptions({ ...searchOptions, textSearch: "" });
              }}
            />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            component={motion.div}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "flex-start",
              minHeight: 308,
              width: "100%",
              gap: {
                sm: 6,
                md: 7,
              },
            }}
          >
            <AnimatePresence>
              {/* Render marketplace cards */}
              {marketListingModels.map((model, idx) => (
                <motion.div
                  layoutId={model.id}
                  initial={{ x: 0, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: 0, opacity: 0 }}
                  key={`${model.id}-${idx}`}
                  whileHover={{
                    scale: 1.02,
                  }}
                  whileTap={{
                    scale: lgDown ? 0.98 : 1,
                  }}
                >
                  <Box
                    ref={registerElement(`marketplaceCard-${idx}`)}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: {
                        xs: "344px",
                        md: "378px",
                      },
                      my: {
                        xs: 1.5,
                        sm: 0,
                      },
                      height: 395,
                    }}
                  >
                    <MarketplaceCard marketListingViewModel={model} />
                  </Box>
                </motion.div>
              ))}

              {/* Render skeleton loaders */}
              {range(loaderCount).map((i) => {
                return (
                  <Box
                    component={motion.div}
                    key={i}
                    layoutId={`${marketListingModels.length + i}`}
                    initial={{ x: 0, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 0, opacity: 0 }}
                    sx={{
                      width: {
                        xs: "344px",
                        md: "378px",
                      },
                      my: {
                        xs: 1,
                        sm: 0,
                      },
                      height: 395,
                    }}
                  >
                    <MarketPlaceSkeletonCard />
                  </Box>
                );
              })}
            </AnimatePresence>
          </Box>
          {marketListingModels.length < totalMarketListingModels && (
            <Button
              variant="outlined"
              sx={{ mt: 5, mb: 3, width: "80px" }}
              disabled={loadingMoreModels || loadingModels}
              onClick={async () => {
                const remainingModels =
                  totalMarketListingModels - marketListingModels.length;

                // if more than a page can still be loaded then show a page of skeletons
                // otherwise show as many skeletons as there are models remaining
                const noLoaders =
                  remainingModels > pageSize ? pageSize : remainingModels;
                setLoaderCount(noLoaders);
                if (
                  marketListingModels.length >= totalMarketListingModels ||
                  loadingMoreModels ||
                  query.offset > totalMarketListingModels - pageSize
                )
                  return;
                setLoadingMoreModels(true);

                await handleLoadMore();
              }}
            >
              Load More
            </Button>
          )}
        </Box>
      )}
    </Root>
  );
};
