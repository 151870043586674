import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import background from "./background.jpg";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export const TroyGoldSubscribeBanner = () => {
  return (
    <Box
      id="troygold_invest_now_banner"
      sx={{
        height: "200px",
        backgroundSize: "1280px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundImage: `url(${background})`,
      }}
      data-component-info={JSON.stringify({
        component_id: "hero_banner",
        component_business_name: "hero banner",
        component_title: "die mos",
        component_driver: "showcase",
      })}
      data-testid="troygold_invest_now_banner"
    >
      <Box
        sx={{
          width: 600,
          ml: {
            lg: "600px",
            md: "400px",
            sm: "300px",
          },
        }}
      >
        <Typography
          textAlign="start"
          fontSize={28}
          letterSpacing={0}
          lineHeight={"32px"}
        >
          The best time to invest in gold, is now.
        </Typography>
        <Stack direction={"column"} spacing={1} sx={{ alignItems: "start" }}>
          <Typography
            textAlign="start"
            fontSize={14}
            letterSpacing={0}
            color={(theme) => theme.palette.text.secondary}
          >
            Be first in line to secure our 2-week Krugerrand™ launch price.
          </Typography>
          <Button
            id="waterFinBanner-exploreBond-button"
            href="https://app.mesh.trade/market/asset-overview?code=TGLD&issuer=GCRCLUSKGUASW2PMIGZXAKGCI7NSP3MTEJ7PYA62NA5LZ5AX2OESW6CV&network=Stellar%20Public"
            variant="contained"
            color="primary"
            rel="noopener noreferrer"
            target="_blank"
            data-link-info={JSON.stringify({
              content_interaction_id: "hero-banner-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "invest now",
              content_interaction_type: InteractionType.Button,
              content_interaction_driver: InteractionDriver.Explore,
            } as DataLinkInfoType)}
          >
            Invest Now
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
