import config from "react-global-configuration";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { Context } from "james/security/Context";

export const TOTPVerifierServiceProviderName = "verification-TOTPVerifier";

export interface VerifyEmailRequest {
  firebaseToken: string;
  totp: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VerifyEmailResponse {}

export interface VerifyPhoneNumberRequest {
  phoneNumber: string;
  context: Context;
  totp: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VerifyPhoneNumberResponse {}

export const TOTPVerifier = {
  async VerifyEmail(request: VerifyEmailRequest): Promise<VerifyEmailResponse> {
    return await jsonRPCRequest({
      url: config.get("meshPublicRatedLimitedURL"),
      method: `${TOTPVerifierServiceProviderName}.VerifyEmail`,
      request,
    });
  },
  async VerifyPhoneNumber(
    request: VerifyPhoneNumberRequest,
  ): Promise<VerifyPhoneNumberResponse> {
    return await jsonRPCRequest({
      url: config.get("meshConsistencyAuthenticatedURL"),
      method: `${TOTPVerifierServiceProviderName}.VerifyPhoneNumber`,
      request,
    });
  },
};
