import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  alpha,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Skeleton,
  Switch,
  Tab,
  Tabs,
  TextFieldProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  FormControlLabel,
} from "@mui/material";
import {
  DownloadForOffline as DownloadCircleIcon,
  FileCopy as CopyPasteIcon,
  Launch,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import {
  Balance,
  Model,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import cx from "classnames";
import { useSearchAccountSignatoryGroupUsers } from "james/stellar/AccountSignatoryInspector";
import { GroupUser } from "james/user";
import { TextExactCriterion } from "james/search/criterion/text";
import Tooltip from "@mui/material/Tooltip";
import isEqual from "lodash/isEqual";
import { NetworkLogo } from "components/Cards/MarketplaceCard/components/NetworkLogo";
import { formatTextNum } from "utilities/number";
import { FundAccountDialog } from "../FundAccountDialog/FundAccountDialog";
import { LedgerAccountCategory, Token } from "james/ledger";
import { AccountOverview } from "james/views/stellarPortfolioView";
import { ValuationChart } from "components/Charts/Portfolio/Valuation/ValuationChart";
import { AssetAllocationChart } from "components/Charts/Portfolio/AssetAllocation/AssetAllocationChart";
import { ClientKind } from "james/client/Client";
import { usePortfolioContext } from "context/Portfolio";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { SkeletonLoader } from "components/SkeletonLoader";
import { useApplicationContext } from "context/Application/Application";
import { Amount as LedgerAmount } from "james/ledger/Amount";
import { BigNumber } from "bignumber.js";
import dayjs from "dayjs";
import { TextField } from "components/FormFields/TextField";
import { DateField } from "components/FormFields";
import { dateIsValid } from "utilities/date/dateIsValid";
import { DateRangeValue } from "james/search/criterion/date/Range";
import { AccountStatementDownloader } from "james/reporting/accountStatementDownloader";
import { downloadExcelFile } from "utilities/network/download";
import { useSnackbar } from "notistack";
import { Model as MarketSubscriptionOrderViewModel } from "james/views/marketSubscriptionOrderView";
import {
  LinkedBankAccountDialog,
  LinkedBankAccountMode,
} from "views/Accounts/components/LinkedBankAccountDialog/LinkedBankAccountDialog";
import { BankAccountsList } from "views/Accounts/components/AccountDetails/components/BankAccountList/BankAccountList";
import { AccountSignatoriesList } from "views/Accounts/components/AccountDetails/components/AccountSignatoriesList/AccountSignatoriesList";
import { ClaimableBalanceList } from "views/Accounts/components/AccountDetails/components/ClaimableBalancesList/ClaimableBalanceList";
import { CashBalances } from "views/Accounts/components/AccountDetails/components/CashBalances/CashBalances";
import { SubscriptionOrders } from "views/Accounts/components/AccountDetails/components/OrdersSubscriptions/SubscriptionOrders";
import { ClaimableBalanceBadge } from "views/Accounts/components/AccountDetails/components/ClaimableBalancesList/components/ClaimableBalancesBadge";
import { AssetCard } from "views/Accounts/components/AccountDetails/components/AssetCard/AssetCard";
import { AddSignatoryDialog } from "views/Accounts/components/AddSignatortiesDialog/AddSignatoryDialog";
import { DefundAccountDialog } from "../DefundAccountDialog/DefundAccountDialog";
import { useSearchParams } from "react-router-dom";
import { SetLimitSection } from "../SetLimitDialog/SetLimitSection";
import { useIsMounted } from "hooks";
import { LoadingButton } from "@mui/lab";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { AccountState } from "@mesh/common-js/dist/stellar/enrichedAccount_pb";
import { Network } from "@mesh/common-js/dist/stellar/network_pb";
import { StellarNetwork } from "james/stellar";

const AccountDetailStyles = (theme: Theme) => ({
  assetDetailContentBox: {
    margin: theme.spacing(0, 2, 1, 3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2, 3, 2),
    },
  },
});

interface AccountDetailsProps {
  accountModel?: Model;
  singleAccount?: boolean;
  subscriptionOrders?: MarketSubscriptionOrderViewModel[];
}

interface AccountSignatoriesContextType {
  accountSignatories: GroupUser[];
  retrieveGroupUsers: () => void;
  retrievalInProgress: boolean;
  accountSignatoriesBeingRemoved: { [key: string]: boolean };
  setAccountSignatoriesBeingRemoved: (updatedValue: {
    [key: string]: boolean;
  }) => void;
}

export const AccountSignatoriesContext = React.createContext(
  {} as AccountSignatoriesContextType,
);

export function AccountDetails(props: AccountDetailsProps) {
  const { authContext, viewConfiguration, loginClaims } =
    useApplicationContext();
  const [searchParams] = useSearchParams();

  const { accountOverviews: portfolioContextAccountOverviews } =
    usePortfolioContext();

  const accountID = useMemo(() => {
    const accountIDFromURL = searchParams.get("id");
    if (accountIDFromURL) {
      return accountIDFromURL;
    } else {
      return "";
    }
  }, [searchParams.get("id")]);

  const focusAccount = useMemo(() => {
    const focusStatement = searchParams.get("focusStatement");
    if (focusStatement === "true") {
      return true;
    }
    return false;
  }, [searchParams.get("focusStatement")]);

  // account overview
  const accountOverview = portfolioContextAccountOverviews?.find(
    (accOverview) => {
      if (props.accountModel) {
        return accOverview.accountID === accountID;
      }
    },
  );

  const [accountSignatoriesBeingRemoved, setAccountSignatoriesBeingRemoved] =
    useState<{ [key: string]: boolean }>({});

  // search for signatories on the selected account
  const {
    searchAccountSignatoryGroupUsersRequest,
    setSearchAccountSignatoryGroupUsersRequest,
    searchAccountSignatoryGroupUsersResponse,
    loading: searchAccountSignatoryGroupUsersLoading,
  } = useSearchAccountSignatoryGroupUsers(
    {
      context: authContext,
      accountNumber: props.accountModel ? props.accountModel.getNumber() : "",
      criteria: {
        clientID: TextExactCriterion(loginClaims.clientID),
      },
    },
    !viewConfiguration.SignatoriesViewer || !props.accountModel,
  );

  // each time searchAccountSignatoryGroupUsersResponse is updated
  // update accountSignatoriesBeingRemoved state as required
  useEffect(() => {
    const updated = { ...accountSignatoriesBeingRemoved };
    Object.keys(updated).forEach((k) => {
      if (
        !searchAccountSignatoryGroupUsersResponse.records.find(
          (u) => u.id === k,
        )
      ) {
        delete updated[k];
      }
    });
    if (!isEqual(updated, accountSignatoriesBeingRemoved)) {
      setAccountSignatoriesBeingRemoved(updated);
    }
  }, [
    searchAccountSignatoryGroupUsersResponse.records,
    accountSignatoriesBeingRemoved,
  ]);

  // refresh component when switching between accounts
  useEffect(() => {
    let isMounted = true;

    // if account model is not set return
    if (!props.accountModel) {
      return;
    }

    if (isMounted) {
      setSearchAccountSignatoryGroupUsersRequest({
        context: authContext,
        accountNumber: props.accountModel ? props.accountModel.getNumber() : "",
        criteria: {},
      });
    }
    return () => {
      isMounted = false;
    };
  }, [
    props.accountModel,
    authContext,
    setSearchAccountSignatoryGroupUsersRequest,
  ]);

  return (
    <AccountSignatoriesContext.Provider
      value={{
        accountSignatories: searchAccountSignatoryGroupUsersResponse.records,
        retrievalInProgress: searchAccountSignatoryGroupUsersLoading,
        retrieveGroupUsers: () =>
          setSearchAccountSignatoryGroupUsersRequest({
            ...searchAccountSignatoryGroupUsersRequest,
          }),
        accountSignatoriesBeingRemoved,
        setAccountSignatoriesBeingRemoved,
      }}
    >
      <Box
        sx={(theme) => ({
          width: "100%",
          backgroundColor: theme.palette.custom.midnight,
        })}
      >
        <AccountSummary
          accountModel={props.accountModel}
          accountOverview={accountOverview}
          singleAccount={props.singleAccount}
          subscriptionOrders={props.subscriptionOrders}
        />
        <AccountPerformanceOverview
          clientKind={loginClaims.clientKind as ClientKind}
          loading={!props.accountModel}
          accountValuationOverview={accountOverview}
          accountCategory={
            props.accountModel
              ? (props.accountModel.getLabel() as LedgerAccountCategory)
              : LedgerAccountCategory.Trading
          }
        />
        <AccountInformation
          accountModel={props.accountModel}
          focusAccountStatement={focusAccount}
          selectedTab={
            focusAccount
              ? AccountDetailsTabs.options
              : AccountDetailsTabs.assets
          }
        />
      </Box>
    </AccountSignatoriesContext.Provider>
  );
}

interface AccountDetailsViewProp {
  accountModel?: Model;
  selectedTab?: AccountDetailsTabs;
  focusAccountStatement?: boolean;
  pieChartColorPicker?: (s: string) => string;
}

enum AccountDetailsTabs {
  assets,
  options,
  claimableBalances,
}

function AccountInformation(props: AccountDetailsViewProp) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(props.selectedTab);
  const [openAddBankAccountDialog, setOpenAddBankAccountDialog] =
    useState(false);
  const [openAddSignatoriesDialog, setOpenAddSignatoriesDialog] =
    useState(false);
  const theme = useTheme();
  const { viewConfiguration, authContext } = useApplicationContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const [badgeCount, setGetBadgeCount] = useState(0);
  const [accountStatementFrom, setAccountStatementFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [accountStatementTo, setAccountStatementTo] = useState<
    DateRangeValue | undefined
  >(undefined);
  // const [errors, setErrors] = useState<>({});
  const { enqueueSnackbar } = useSnackbar();
  const accountStatementRef = useRef<HTMLDivElement | null>(null);

  const portfolioContext = usePortfolioContext();

  useEffect(() => {
    if (
      isMounted() &&
      accountStatementRef &&
      props.focusAccountStatement === true
    ) {
      const t = setTimeout(() => {
        accountStatementRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 500);
      return () => clearTimeout(t);
    }
  }, [isMounted, accountStatementRef]);

  useEffect(() => {
    if (props.accountModel) {
      setLoading(false);
    }
  }, [props.accountModel]);

  // account overview
  const accountOverview = useMemo(() => {
    if (!props.accountModel) {
      return undefined;
    }

    if (!portfolioContext.accountOverviews) {
      return undefined;
    }

    return portfolioContext.accountOverviews.find((accOverview) => {
      if (props.accountModel) {
        return accOverview.accountID === props.accountModel.getId();
      }
    });
  }, [props.accountModel, portfolioContext.accountOverviews]);

  const getAssetValuation = useCallback(
    (assetToken: Token): LedgerAmount | undefined => {
      return accountOverview?.currentAssetAllocation?.find((v): boolean => {
        return v.asset.isEqualTo(assetToken);
      })?.assetValuation;
    },
    [accountOverview],
  );

  let orderedAssetList: Balance[] = [];
  if (props.accountModel) {
    // mZAR should always display at the top of the asset list
    orderedAssetList = props.accountModel
      .getBalancesList()
      .filter((b) => b.getTokenviewmodel()?.getToken()?.getCode() === "mZAR");

    // liquidity pool shares should always be displayed at the bottom
    const orderedLiquidityPoolSharesAssets = props.accountModel
      .getBalancesList()
      .filter(
        (b) =>
          b.getTokenviewmodel()?.getTokencategory() ===
          TokenCategory.LiquidityPoolShares,
      )
      .sort((a, b) =>
        (a.getTokenviewmodel()?.getName() ?? "").localeCompare(
          b.getTokenviewmodel()?.getName() ?? "",
        ),
      );

    // sort all other assets from highest to lowest according to market valuations
    const orderedAssetListWithoutMZAR = props.accountModel
      .getBalancesList()
      .sort((a, b) => {
        let aVal = getAssetValuation(
          Token.fromFutureToken(a.getTokenviewmodel()?.getToken()),
        );
        let bVal = getAssetValuation(
          Token.fromFutureToken(b.getTokenviewmodel()?.getToken()),
        );

        if (!aVal) {
          aVal = new LedgerAmount({
            token: Token.fromFutureToken(a.getAmount()?.getToken()),
            value: new BigNumber(0),
          });
        }

        if (!bVal) {
          bVal = new LedgerAmount({
            token: Token.fromFutureToken(b.getAmount()?.getToken()),
            value: new BigNumber(0),
          });
        }

        const valDiff = bVal.value.minus(aVal.value).toNumber();

        if (valDiff === 0) {
          // sort alphabetically according the to asset name if valuation is equal
          return (a.getTokenviewmodel()?.getName() ?? "").localeCompare(
            b.getTokenviewmodel()?.getName() ?? "",
          );
        }

        return valDiff;
      })
      .filter(
        (b) =>
          b.getTokenviewmodel()?.getToken()?.getCode() !== "mZAR" &&
          b.getTokenviewmodel()?.getTokencategory() !==
            TokenCategory.LiquidityPoolShares,
      );

    orderedAssetList.push(...orderedAssetListWithoutMZAR);
    orderedAssetList.push(...orderedLiquidityPoolSharesAssets);
  }

  const validateDate = (
    date: dayjs.Dayjs,
    minDate: dayjs.Dayjs,
    maxDate: dayjs.Dayjs,
  ): boolean => {
    if (
      !dateIsValid(dayjs(date).startOf("day").format()) ||
      date.isBefore(minDate.startOf("day")) ||
      date.isAfter(maxDate)
    ) {
      return true;
    }
    return false;
  };

  const handleDownloadAccountStatement = async () => {
    if (!props.accountModel) {
      return;
    }

    if (!accountStatementFrom || !accountStatementTo) {
      enqueueSnackbar("Please include both the from and to date", {
        variant: "error",
      });
      return;
    }

    if (
      validateDate(
        dayjs(accountStatementFrom?.date),
        dayjs(props.accountModel?.getCreatedtime()?.toDate()),
        dayjs(accountStatementTo?.date),
      )
    ) {
      enqueueSnackbar("Please make sure both dates are correct", {
        variant: "error",
      });
      return;
    }

    try {
      const downloadAccountStatementResponse =
        await AccountStatementDownloader.DownloadAccountStatement({
          context: authContext,
          accountID: props.accountModel.getId(),
          timePeriod: {
            fromDate: accountStatementFrom.date,
            toDate: accountStatementTo.date,
          },
        });
      downloadExcelFile({
        encodedString: downloadAccountStatementResponse.excelFileBase64,
        fileName:
          "AccountStatement-" +
          dayjs(accountStatementFrom.date).toDate().toDateString() +
          " - " +
          dayjs(accountStatementTo.date).toDate().toDateString(),
      });
    } catch (e) {
      const err = e as Error;
      console.error(
        `unable to download account statement: ${
          err.message ? err.message : err.toString()
        }`,
      );
    }
  };

  const [shouldHideZeroBalance, setShouldHideZeroBalance] = useState(true);
  return (
    <>
      {props.accountModel ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={selectedTab}
            textColor="inherit"
            id="accountDetails-primary-tabs"
            sx={{
              margin: {
                sm: theme.spacing(0, 0, 2, 0),
                xs: theme.spacing(0, 2, 2, 2),
              },
            }}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            onChange={(_, value) => {
              if (selectedTab === value) {
                return;
              }
              setSelectedTab(value);
            }}
            TabIndicatorProps={{
              hidden: true,
              style: {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <Tab
              value={AccountDetailsTabs.assets}
              sx={(theme) => ({
                fontSize: "16px",
                fontWeight: 600,
                minWidth: "unset",
                padding: 0,
                margin: theme.spacing(0, 2, 0, 0),
                minHeight: "unset",
                height: "40px",
                textTransform: "unset",
                "&.Mui-selected": {
                  color: theme.palette.primary.main,
                  borderBottom: `1px solid ${theme.palette.primary.main}`,
                },
              })}
              id="accountDetails-assets-tab"
              component="div"
              label="Assets"
            />
            {viewConfiguration.StellarClaimableBalanceViewer && (
              <Tab
                value={AccountDetailsTabs.claimableBalances}
                sx={(theme) => ({
                  fontSize: "16px",
                  fontWeight: 600,
                  minWidth: "unset",
                  padding: badgeCount > 0 ? theme.spacing(0, 2, 0, 0) : 0,
                  margin: theme.spacing(0),
                  minHeight: "unset",
                  height: "40px",
                  textTransform: "unset",
                  "&.Mui-selected": {
                    color: theme.palette.primary.main,
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                  },
                })}
                component={"div"}
                id="accountDetails-claimableBalances-tab"
                label={
                  <ClaimableBalanceBadge
                    accountLedgerID={props.accountModel.getLedgerid()}
                    accountOwnerID={props.accountModel.getOwnerid()}
                    getBadgeCount={setGetBadgeCount}
                  >
                    Claimable Balances
                  </ClaimableBalanceBadge>
                }
              />
            )}
            <Tab
              value={AccountDetailsTabs.options}
              sx={(theme) => ({
                fontSize: "16px",
                fontWeight: 600,
                minWidth: "unset",
                padding: 0,
                margin: theme.spacing(0, 0, 0, 2),
                minHeight: "unset",
                textTransform: "unset",
                height: "40px",
                "&.Mui-selected": {
                  color: theme.palette.primary.main,
                  borderBottom: `1px solid ${theme.palette.primary.main}`,
                },
              })}
              id="accountDetails-options-tab"
              component={"div"}
              label="Account Options"
            />
          </Tabs>
          {selectedTab === AccountDetailsTabs.assets && (
            <FormControlLabel
              control={
                <Switch
                  checked={shouldHideZeroBalance}
                  onChange={() =>
                    setShouldHideZeroBalance(!shouldHideZeroBalance)
                  }
                  name="hideZeroBalanceSwitch"
                  color="secondary"
                />
              }
              label="Hide Zero Balance"
              sx={(theme) => ({
                pr: 3,
                pb: { sm: 2, xs: 0 },
                "& .MuiFormControlLabel-label": {
                  color: theme.palette.text.secondary,
                  fontSize: "14px",
                },
              })}
            />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            paddingLeft: "40px",
          }}
        >
          <Skeleton
            component={"div"}
            sx={{
              marginRight: "8px",
            }}
            width={"80px"}
            height={"60px"}
          />
          <Skeleton
            component={"div"}
            sx={{
              marginRight: "8px",
            }}
            width={"160px"}
            height={"60px"}
          />
          <Skeleton component={"div"} width={"140px"} height={"60px"} />
        </Box>
      )}
      {(() => {
        switch (selectedTab) {
          case AccountDetailsTabs.assets:
            return (
              <Box
                className={cx("meshScroll")}
                sx={(theme) => AccountDetailStyles(theme).assetDetailContentBox}
              >
                {props.accountModel ? (
                  orderedAssetList.map((value, idx) => (
                    <React.Fragment key={idx}>
                      {props.accountModel &&
                        (shouldHideZeroBalance
                          ? decimalToBigNumber(
                              value.getAmount()?.getValue(),
                            ).gt(0)
                          : true) && (
                          <AssetCard
                            key={`assetCard-${idx}`}
                            accountModel={props.accountModel}
                            balance={value}
                            assetValuation={getAssetValuation(
                              Token.fromFutureToken(
                                value.getAmount()?.getToken(),
                              ),
                            )}
                            hideValuation={
                              value.getTokenviewmodel()?.getTokencategory() ===
                              TokenCategory.LiquidityPoolShares
                            }
                          />
                        )}
                    </React.Fragment>
                  ))
                ) : (
                  <Skeleton width={"100%"} height={"90px"} />
                )}
              </Box>
            );

          case AccountDetailsTabs.options:
            return (
              <div>
                {!isMobile && (
                  <Box
                    className={"meshScroll"}
                    sx={(theme) =>
                      AccountDetailStyles(theme).assetDetailContentBox
                    }
                  >
                    {viewConfiguration.SetAssetLimitMC &&
                      props.accountModel && (
                        <SetLimitSection
                          accountID={props.accountModel?.getId()}
                        />
                      )}
                    {viewConfiguration.BankAccountViewer && (
                      <>
                        <Box
                          sx={(theme) => ({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: theme.spacing(3),
                          })}
                        >
                          <Typography
                            sx={{
                              fontWeight: theme.typography.fontWeightBold,
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "16px",
                              },
                            }}
                            variant="h4"
                          >
                            Linked Bank Accounts
                          </Typography>
                          <div>
                            {viewConfiguration.BankAccountCreator && (
                              <Button
                                id="accountDetails-addBankAccount-button"
                                onClick={() =>
                                  setOpenAddBankAccountDialog(
                                    !openAddBankAccountDialog,
                                  )
                                }
                                color="secondary"
                                size="large"
                                startIcon={<AddCircleIcon />}
                              >
                                add account
                              </Button>
                            )}
                          </div>
                        </Box>
                        <BankAccountsList />
                      </>
                    )}
                    <div>
                      {viewConfiguration.Accounts[
                        "DownloadAccountStatement"
                      ] && (
                        <Box
                          sx={(theme) => ({
                            display: "flex",
                            flexDirection: "column",
                            margin: theme.spacing(9, 0, 3),
                          })}
                        >
                          <Typography
                            sx={{
                              fontWeight: theme.typography.fontWeightBold,
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "16px",
                              },
                            }}
                            variant="h4"
                          >
                            Download Account Statement
                          </Typography>
                          <Box
                            sx={(theme) => ({
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: theme.spacing(3),
                            })}
                          >
                            <Button
                              id="accountDetails-accountStatementDownload-button"
                              sx={{
                                fontSize: "14px",
                              }}
                              onClick={() => {
                                setAccountStatementFrom({
                                  date: dayjs().startOf("day").format(),
                                  inclusive: true,
                                  ignore: false,
                                });
                                setAccountStatementTo({
                                  date: dayjs().endOf("day").format(),
                                  inclusive: true,
                                  ignore: false,
                                });
                              }}
                              color="info"
                            >
                              Set Today
                            </Button>
                            <Button
                              id="accountDetails-accountStatementDownload-button"
                              onClick={() => {
                                const startDate = dayjs()
                                  .subtract(7, "day")
                                  .startOf("day");
                                setAccountStatementFrom({
                                  date:
                                    props.accountModel &&
                                    startDate.isBefore(
                                      dayjs(
                                        props.accountModel
                                          ?.getCreatedtime()
                                          ?.toDate(),
                                      ).format(),
                                    )
                                      ? dayjs(
                                          props.accountModel
                                            .getCreatedtime()
                                            ?.toDate(),
                                        ).format()
                                      : startDate.format(),
                                  inclusive: true,
                                  ignore: false,
                                });
                                setAccountStatementTo({
                                  date: dayjs().endOf("day").format(),
                                  inclusive: true,
                                  ignore: false,
                                });
                              }}
                              color="info"
                            >
                              Set Last 7 Days
                            </Button>
                            <LoadingButton
                              id="accountDetails-accountStatementDownload-button"
                              loading={downloading}
                              onClick={async () => {
                                setDownloading(true);
                                await handleDownloadAccountStatement();
                                setDownloading(false);
                              }}
                              color="secondary"
                              size="large"
                              disabled={
                                !accountStatementFrom || !accountStatementTo
                              }
                              startIcon={<DownloadCircleIcon />}
                            >
                              Download
                            </LoadingButton>
                          </Box>
                          <DateField
                            label={"From"}
                            id={
                              "accountDetails-accountStatementFromDate-dateField"
                            }
                            disabled={loading}
                            minDate={dayjs(
                              props.accountModel?.getCreatedtime()?.toDate(),
                            )}
                            maxDate={dayjs().endOf("day")}
                            value={
                              accountStatementFrom
                                ? accountStatementFrom.date
                                : null
                            }
                            onChange={(newValue) => {
                              setAccountStatementFrom({
                                date: newValue?.format() ?? "",
                                ignore: false,
                                inclusive: true,
                              });
                            }}
                            renderInput={(textFieldProps: TextFieldProps) => (
                              <TextField
                                {...textFieldProps}
                                id={
                                  "accountDetails-accountStatementFromDate-dateFieldTextField"
                                }
                                sx={{ width: 240 }}
                                variant={"outlined"}
                                margin={"dense"}
                                FormHelperTextProps={{
                                  sx: { color: theme.palette.error.main },
                                }}
                              />
                            )}
                          />
                          <DateField
                            label={"To"}
                            disabled={loading}
                            minDate={
                              accountStatementFrom
                                ? dayjs(accountStatementFrom.date)
                                : dayjs(
                                    props.accountModel
                                      ?.getCreatedtime()
                                      ?.toDate(),
                                  )
                            }
                            maxDate={dayjs().endOf("day")}
                            id={
                              "accountDetails-accountStatementToDate-dateField"
                            }
                            value={
                              accountStatementTo
                                ? accountStatementTo.date
                                : null
                            }
                            onChange={(newValue) => {
                              setAccountStatementTo(
                                newValue
                                  ? {
                                      date: newValue.endOf("day").format(),
                                      inclusive: true,
                                      ignore: false,
                                    }
                                  : undefined,
                              );
                            }}
                            renderInput={(textFieldProps: TextFieldProps) => (
                              <TextField
                                {...textFieldProps}
                                id={
                                  "accountDetails-accountStatementToDate-dateFieldTextField"
                                }
                                sx={{ width: 240 }}
                                variant={"outlined"}
                                margin={"dense"}
                                // helperText={errors?.toDate ?? ""}
                                FormHelperTextProps={{
                                  sx: { color: theme.palette.error.main },
                                }}
                              />
                            )}
                          />
                        </Box>
                      )}
                    </div>
                    <div ref={accountStatementRef} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: theme.spacing(
                          viewConfiguration.BankAccountViewer ? 9 : 0,
                        ),
                      }}
                    >
                      {(viewConfiguration.AddSignatories ||
                        viewConfiguration.SignatoriesViewer) && (
                        <Typography
                          sx={{
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                            [theme.breakpoints.down("sm")]: {
                              fontSize: "16px",
                            },
                          }}
                          variant="h4"
                        >
                          Signatories
                        </Typography>
                      )}
                      <div>
                        {viewConfiguration.AddSignatories && (
                          <Tooltip
                            placement="top"
                            title={(() => {
                              if (
                                props.accountModel?.getState() !==
                                AccountState.OPEN_ACCOUNT_STATE
                              ) {
                                return "Account is not Open";
                              }
                              return "";
                            })()}
                          >
                            <span>
                              <Button
                                id="accountDetails-addSignatories-button"
                                color="secondary"
                                onClick={() =>
                                  setOpenAddSignatoriesDialog(
                                    !openAddSignatoriesDialog,
                                  )
                                }
                                size="large"
                                startIcon={<AddCircleIcon />}
                                disabled={
                                  props.accountModel?.getState() !==
                                  AccountState.OPEN_ACCOUNT_STATE
                                }
                              >
                                add signatories
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </Box>
                    {viewConfiguration.SignatoriesViewer &&
                      props.accountModel && (
                        <AccountSignatoriesList
                          accountModel={props.accountModel}
                        />
                      )}
                  </Box>
                )}
                {isMobile && (
                  <Box
                    className={"meshScroll"}
                    sx={(theme) =>
                      AccountDetailStyles(theme).assetDetailContentBox
                    }
                  >
                    {viewConfiguration.SetAssetLimitMC &&
                      props.accountModel && (
                        <SetLimitSection
                          accountID={props.accountModel?.getId()}
                        />
                      )}
                    {viewConfiguration.BankAccountViewer && (
                      <>
                        <Box>
                          <Grid
                            sx={(theme) => ({
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                              marginBottom: theme.spacing(2),
                            })}
                          >
                            <Typography
                              sx={{
                                fontWeight: theme.typography.fontWeightBold,
                                fontSize: "20px",
                                marginBottom: theme.spacing(2),
                              }}
                              variant="h4"
                            >
                              Linked Bank Accounts
                            </Typography>
                            <div>
                              {viewConfiguration.BankAccountCreator && (
                                <Button
                                  id="accountDetails-addBankAccount-button"
                                  onClick={() =>
                                    setOpenAddBankAccountDialog(
                                      !openAddBankAccountDialog,
                                    )
                                  }
                                  color="secondary"
                                  size="large"
                                  startIcon={<AddIcon />}
                                >
                                  add account
                                </Button>
                              )}
                            </div>
                          </Grid>
                        </Box>
                        <BankAccountsList />
                      </>
                    )}
                    <div>
                      {viewConfiguration.Accounts[
                        "DownloadAccountStatement"
                      ] && (
                        <Box
                          sx={(theme) => ({
                            display: "flex",
                            flexDirection: "column",
                            margin: theme.spacing(9, 0, 3),
                          })}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignSelf: "center",
                              width: "100%",
                              fontWeight: theme.typography.fontWeightBold,
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "20px",
                              },
                            }}
                            variant="h4"
                          >
                            Download Account Statement
                          </Typography>
                          <Box
                            sx={(theme) => ({
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              marginBottom: theme.spacing(3),
                            })}
                          >
                            <Button
                              id="accountDetails-accountStatementDownload-button"
                              sx={{
                                ml: "-20px",
                              }}
                              onClick={() => {
                                setAccountStatementFrom({
                                  date: dayjs().startOf("day").format(),
                                  inclusive: true,
                                  ignore: false,
                                });
                                setAccountStatementTo({
                                  date: dayjs().endOf("day").format(),
                                  inclusive: true,
                                  ignore: false,
                                });
                              }}
                              color="info"
                            >
                              Today
                            </Button>
                            <Button
                              id="accountDetails-accountStatementDownload-button"
                              sx={{
                                ml: "-20px",
                              }}
                              onClick={() => {
                                const startDate = dayjs()
                                  .subtract(7, "day")
                                  .startOf("day");
                                setAccountStatementFrom({
                                  date:
                                    props.accountModel &&
                                    startDate.isBefore(
                                      dayjs(
                                        props.accountModel
                                          ?.getCreatedtime()
                                          ?.toDate(),
                                      ),
                                    )
                                      ? dayjs(
                                          props.accountModel
                                            .getCreatedtime()
                                            ?.toDate(),
                                        ).format()
                                      : startDate.format(),
                                  inclusive: true,
                                  ignore: false,
                                });
                                setAccountStatementTo({
                                  date: dayjs().endOf("day").format(),
                                  inclusive: true,
                                  ignore: false,
                                });
                              }}
                              color="info"
                            >
                              Last 7 Days
                            </Button>
                            <LoadingButton
                              id="accountDetails-accountStatementDownload-button"
                              loading={downloading}
                              onClick={async () => {
                                setDownloading(true);
                                await handleDownloadAccountStatement();
                                setDownloading(false);
                              }}
                              color="secondary"
                              size="large"
                              disabled={
                                !accountStatementFrom || !accountStatementTo
                              }
                              startIcon={<DownloadCircleIcon />}
                            >
                              Download
                            </LoadingButton>
                          </Box>
                          <DateField
                            label={"From"}
                            id={
                              "accountDetails-accountStatementFromDate-dateField"
                            }
                            disabled={loading}
                            minDate={dayjs(
                              props.accountModel?.getCreatedtime()?.toDate(),
                            )}
                            maxDate={dayjs().endOf("day")}
                            value={
                              accountStatementFrom
                                ? accountStatementFrom.date
                                : null
                            }
                            onChange={(newValue) => {
                              setAccountStatementFrom({
                                date: newValue?.toString() ?? "",
                                ignore: false,
                                inclusive: true,
                              });
                            }}
                            renderInput={(textFieldProps: TextFieldProps) => (
                              <TextField
                                {...textFieldProps}
                                id={
                                  "accountDetails-accountStatementFromDate-dateFieldTextField"
                                }
                                sx={{ width: 240 }}
                                variant={"outlined"}
                                margin={"dense"}
                                // helperText={errors?.fromDate ?? ""}
                                FormHelperTextProps={{
                                  sx: { color: theme.palette.error.main },
                                }}
                              />
                            )}
                          />
                          <DateField
                            label={"To"}
                            disabled={loading}
                            minDate={
                              accountStatementFrom
                                ? dayjs(accountStatementFrom.date)
                                : dayjs(
                                    props.accountModel
                                      ?.getCreatedtime()
                                      ?.toDate(),
                                  )
                            }
                            maxDate={dayjs().endOf("day")}
                            id={
                              "accountDetails-accountStatementToDate-dateField"
                            }
                            value={
                              accountStatementTo
                                ? accountStatementTo.date
                                : null
                            }
                            onChange={(newValue) => {
                              setAccountStatementTo(
                                newValue
                                  ? {
                                      date: newValue.endOf("day").format(),
                                      inclusive: true,
                                      ignore: false,
                                    }
                                  : undefined,
                              );
                            }}
                            renderInput={(textFieldProps: TextFieldProps) => (
                              <TextField
                                {...textFieldProps}
                                id={
                                  "accountDetails-accountStatementToDate-dateFieldTextField"
                                }
                                sx={{ width: 240 }}
                                variant={"outlined"}
                                margin={"dense"}
                                // helperText={errors?.toDate ?? ""}
                                FormHelperTextProps={{
                                  sx: { color: theme.palette.error.main },
                                }}
                              />
                            )}
                          />
                        </Box>
                      )}
                      <div ref={accountStatementRef} />
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        marginBottom: theme.spacing(2),
                        marginTop: theme.spacing(3),
                      }}
                    >
                      {(viewConfiguration.AddSignatories ||
                        viewConfiguration.SignatoriesViewer) && (
                        <Typography
                          sx={{
                            fontWeight: theme.typography.fontWeightBold,
                            fontSize: "20px",
                            marginBottom: theme.spacing(2),
                          }}
                          variant="h4"
                        >
                          Signatories
                        </Typography>
                      )}
                      <div>
                        {viewConfiguration.AddSignatories && (
                          <Tooltip
                            placement="top"
                            title={(() => {
                              if (
                                props.accountModel?.getState() !==
                                AccountState.OPEN_ACCOUNT_STATE
                              ) {
                                return "Account is not Open";
                              }
                              return "";
                            })()}
                          >
                            <span>
                              <Button
                                id="accountDetails-addSignatories-button"
                                color="secondary"
                                onClick={() =>
                                  setOpenAddSignatoriesDialog(
                                    !openAddSignatoriesDialog,
                                  )
                                }
                                size="large"
                                startIcon={<AddIcon />}
                                disabled={
                                  props.accountModel?.getState() !==
                                  AccountState.OPEN_ACCOUNT_STATE
                                }
                              >
                                add signatories
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </Box>
                    {viewConfiguration.SignatoriesViewer &&
                      props.accountModel && (
                        <AccountSignatoriesList
                          accountModel={props.accountModel}
                        />
                      )}
                  </Box>
                )}
              </div>
            );

          case AccountDetailsTabs.claimableBalances:
            return (
              <Box
                className={"meshScroll"}
                sx={(theme) => AccountDetailStyles(theme).assetDetailContentBox}
              >
                {props.accountModel && (
                  <ClaimableBalanceList accountViewModel={props.accountModel} />
                )}
              </Box>
            );

          default:
            return null;
        }
      })()}
      {openAddBankAccountDialog && props.accountModel && (
        <LinkedBankAccountDialog
          mode={LinkedBankAccountMode.Create}
          open={openAddBankAccountDialog}
          onClose={() => setOpenAddBankAccountDialog(!openAddBankAccountDialog)}
          ownerID={props.accountModel.getOwnerid()}
        />
      )}

      {openAddSignatoriesDialog && props.accountModel && (
        <AddSignatoryDialog
          accountID={props.accountModel.getId()}
          open={openAddSignatoriesDialog}
          onClose={() => setOpenAddSignatoriesDialog(!openAddSignatoriesDialog)}
        />
      )}
    </>
  );
}

interface AccountSummaryProps {
  accountModel?: Model;
  accountOverview?: AccountOverview;
  singleAccount?: boolean;
  subscriptionOrders?: MarketSubscriptionOrderViewModel[];
}

function AccountSummary(props: AccountSummaryProps) {
  const theme = useTheme();
  const [showFundAccDialog, setShowFundAccDialog] = useState(false);
  const [showDefundDialog, setShowDefundDialog] = useState(false);
  const [stellarAccountCopied, setStellarAccountCopied] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  return (
    <>
      <Card
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.midnight,
          borderRadius: theme.spacing(1, 1, 0, 0),
          boxShadow: "unset",
          padding: isMobile
            ? theme.spacing(2, 0, 1)
            : theme.spacing(2, 3, 1, 3),
        })}
      >
        {(() => {
          //  if on mobile display the my mesh account text
          if (isMobile) {
            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingTop: theme.spacing(2),
                  justifyContent: "center",
                })}
              >
                <SkeletonLoader loading={!props.accountModel}>
                  <Typography
                    sx={{
                      fontWeight: (theme) =>
                        theme.typography.fontWeightBold + " !important",
                    }}
                    variant="h4"
                  >
                    My Mesh Account
                  </Typography>
                </SkeletonLoader>
              </Box>
            );
          }
          return <></>;
        })()}
        <CashBalances
          accountModel={props.accountModel}
          setShowDeFundDialog={setShowDefundDialog}
          setShowFundAccDialog={setShowFundAccDialog}
          showFundAccDialog={showFundAccDialog}
          showDeFundDialog={showDefundDialog}
          singleAccount={props.singleAccount}
          accountOverview={props.accountOverview}
        />
        {props.subscriptionOrders && props.subscriptionOrders.length >= 1 && (
          <SubscriptionOrders
            subscriptionOrders={props.subscriptionOrders}
            accountModel={props.accountModel}
          />
        )}
        <Box
          sx={(theme) => ({
            display: "flex",
            [theme.breakpoints.down("sm")]: {
              margin: theme.spacing(3, 2, 0),
            },
            [theme.breakpoints.up("sm")]: {
              margin: theme.spacing(3, 0, 0),
            },
            paddingTop: "8px",
            paddingBottom: "8px",
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            alignItems: "center",
            justifyContent: {
              sm: "space-between",
              xs: "flex-end",
            },
            flexDirection: {
              sm: "row",
              xs: "row-reverse",
            },
          })}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: {
                sm: "flex-start",
                xs: "space-between",
              },
              flexGrow: {
                sm: 0,
                xs: 1,
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SkeletonLoader
                sx={{ marginRight: "8px", height: "24px" }}
                loading={!props.accountModel}
              >
                <Typography
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                  variant="caption"
                  component={"div"}
                >
                  {isMobile ? "" : "Stellar "}Public Key: &nbsp;
                </Typography>
              </SkeletonLoader>
              {props.accountModel ? (
                <Typography
                  id="accountDetails-accountPublicKey-typography"
                  variant="caption"
                  noWrap
                  sx={(theme) => ({
                    width: 108,
                    [theme.breakpoints.down("sm")]: {
                      width: 70,
                    },
                  })}
                  component={"div"}
                >
                  {props.accountModel.getLedgerid()}
                </Typography>
              ) : (
                <Skeleton
                  sx={{
                    alignSelf: "center",
                  }}
                  width={"180px"}
                  height={"24px"}
                />
              )}
            </Box>
            <Box sx={{ display: "flex" }}>
              <SkeletonLoader
                sx={{ marginLeft: "16px" }}
                loading={!props.accountModel}
              >
                <Tooltip
                  PopperProps={{ disablePortal: true }}
                  onClose={() => setStellarAccountCopied(false)}
                  open={stellarAccountCopied}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Copied"
                >
                  <Box component={"span"}>
                    <IconButton
                      id={`accountDetails-stellarAccountCopied-iconButton-${props.accountModel?.getLedgerid()}`}
                      disabled={!props.accountModel}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            props.accountModel
                              ? props.accountModel.getLedgerid()
                              : "",
                          )
                          .then(() => setStellarAccountCopied(true));
                      }}
                      onMouseLeave={() => setStellarAccountCopied(false)}
                      size="small"
                      sx={(theme) => ({
                        marginLeft: theme.spacing(2),
                        padding: "4px",
                      })}
                    >
                      <CopyPasteIcon
                        sx={{
                          fill: "text.secondary",
                        }}
                      />
                    </IconButton>
                  </Box>
                </Tooltip>
              </SkeletonLoader>
              <SkeletonLoader
                sx={{ marginLeft: "16px" }}
                loading={!props.accountModel}
              >
                <Tooltip
                  placement="top"
                  title="View on Stellar Expert (Blockchain Explorer)"
                >
                  <Box component={"span"}>
                    <IconButton
                      id="accountCard-stellarExpertLink-button"
                      disabled={!props.accountModel}
                      onClick={(e) => {
                        e.stopPropagation();

                        if (!props.accountModel) {
                          console.warn("account model not set");
                          return;
                        }
                        switch (props.accountModel.getNetwork()) {
                          case Network.STELLAR_TEST_SDF_NETWORK:
                            window.open(
                              `https://stellar.expert/explorer/testnet/account/${props.accountModel?.getLedgerid()}`,
                              "_blank",
                            );
                            break;
                          case Network.STELLAR_PUBLIC_NETWORK:
                            window.open(
                              `https://stellar.expert/explorer/public/account/${props.accountModel?.getLedgerid()}`,
                              "_blank",
                            );
                            break;
                        }
                      }}
                      sx={(theme) => ({
                        marginRight: { sm: theme.spacing(1) },
                        padding: "4px",
                      })}
                      size="small"
                    >
                      <Launch
                        sx={{
                          fill: "text.secondary",
                        }}
                      />
                    </IconButton>
                  </Box>
                </Tooltip>
              </SkeletonLoader>
            </Box>
          </Box>
          <Box
            sx={{
              justifySelf: "end",
              margin: {
                xs: theme.spacing(0.5, 1, 0, 0),
                sm: theme.spacing(0.5, 0, 0, 0),
              },
            }}
          >
            {props.accountModel ? (
              <NetworkLogo
                network={StellarNetwork.PublicNetwork}
                breakPoint={"sm"}
              />
            ) : (
              <Skeleton
                sx={{
                  transform: "none",
                }}
                width={"88px"}
                height={"22px"}
              />
            )}
          </Box>
        </Box>
      </Card>

      {(() => {
        if (showFundAccDialog && props.accountModel) {
          return (
            <FundAccountDialog
              onClose={() => setShowFundAccDialog(!showFundAccDialog)}
              open={showFundAccDialog}
              accountID={props.accountModel.getId()}
            />
          );
        }

        if (showDefundDialog && props.accountModel) {
          return (
            <DefundAccountDialog
              onClose={() => setShowDefundDialog(!showDefundDialog)}
              open={showDefundDialog}
              accountModel={props.accountModel}
            />
          );
        }
      })()}
    </>
  );
}

// Define the types for props expected by AccountPerformanceOverview component.
type AccountPerformanceOverviewProps = {
  loading: boolean;
  accountCategory: LedgerAccountCategory;
  clientKind: ClientKind;
  accountValuationOverview?: AccountOverview;
};

function AccountPerformanceOverview(props: AccountPerformanceOverviewProps) {
  // Determine if the screen size is desktop or not.
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  // Memoize the display currency to prevent unnecessary recalculations.
  const displayCurrency = useMemo(() => {
    const { accountValuationOverview } = props;
    const code = accountValuationOverview?.currentValue.token.code;
    return { value: code, label: code };
  }, [props.accountValuationOverview]);

  // Calculate if the Profit & Loss (PnL) change is positive.
  const pnlChangeIsPositive = useMemo(() => {
    return props.accountValuationOverview
      ? +props.accountValuationOverview.pnlAmount.value >= 0
      : undefined;
  }, [props.accountValuationOverview]);

  // Conditionally render nothing for individual clients on desktop.
  if (props.clientKind === ClientKind.IndividualType && isDesktop) {
    return null;
  }

  // Conditionally render nothing for specific account categories.
  if (
    [
      LedgerAccountCategory.MarketInfrastructure,
      LedgerAccountCategory.Operational,
      LedgerAccountCategory.Issuance,
    ].includes(props.accountCategory)
  ) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        margin: theme.spacing(2, 2, 3, 2),
        [theme.breakpoints.down("sm")]: {
          height: "auto",
          flexDirection: "column",
        },
        gap: (theme) => theme.spacing(2),
        gridGap: (theme) => theme.spacing(2),
      })}
    >
      {/* Profit & Loss Value Display */}
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.opaqueBackground,
          padding: theme.spacing(2, 3, 2, 3),
          width: "100%",
          minWidth: "250px",
          borderRadius: theme.spacing(1),
          height: "160px",
        })}
      >
        <SkeletonLoader loading={props.loading}>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              textTransform: "none",
            }}
            variant="overline"
            children="Profit & Loss Value"
          />
        </SkeletonLoader>
        {!props.loading && !props.accountValuationOverview?.notEnoughPnlData ? (
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {displayCurrency.value && (
              <Box
                component={"span"}
                sx={(theme) => ({
                  color: props.accountValuationOverview
                    ?.notEnoughValuationHistoryData
                    ? theme.palette.text.secondary
                    : pnlChangeIsPositive
                      ? alpha(theme.palette.success.light, 0.38)
                      : alpha(theme.palette.error.light, 0.38),
                })}
              >
                {`${displayCurrency.value} `}
              </Box>
            )}
            <Box
              component={"span"}
              sx={(theme) => ({
                color: props.accountValuationOverview
                  ?.notEnoughValuationHistoryData
                  ? theme.palette.common.white
                  : pnlChangeIsPositive
                    ? theme.palette.success.light
                    : theme.palette.error.light,
              })}
            >
              {formatTextNum(
                props.accountValuationOverview
                  ? props.accountValuationOverview.pnlAmount.value
                  : "",
                {
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                },
              )}
            </Box>
          </Typography>
        ) : (
          <Skeleton width={"80px"} height={"28px"} />
        )}
        <ValuationChart
          valuations={props.accountValuationOverview?.valuationHistory.map(
            (v) => ({
              date: v.date,
              valuation: v.accountValuation,
            }),
          )}
          noInfoDisplay={
            props.accountValuationOverview?.notEnoughValuationHistoryData
          }
        />
        <SkeletonLoader loading={props.loading}>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              textTransform: "none",
              marginTop: (theme) => theme.spacing(2),
            }}
            component={"div"}
            variant={"overline"}
            children="Market Valuation"
          />
        </SkeletonLoader>
      </Box>
      {/* Account Allocation Display */}
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.opaqueBackground,
          padding: "16px 24px 16px 24px",
          width: "100%",
          borderRadius: theme.spacing(1),
          display: "flex",
          maxWidth: "305px",
          [theme.breakpoints.down("sm")]: {
            maxWidth: "unset",
          },
          flexDirection: "column",
          height: "160px",
        })}
      >
        <SkeletonLoader loading={props.loading}>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              textTransform: "none",
              [theme.breakpoints.down("sm")]: {
                alignSelf: "center",
              },
            })}
            variant="overline"
            children="Account Allocation"
          />
        </SkeletonLoader>
        <AssetAllocationChart
          loading={props.accountValuationOverview === undefined}
          assetAllocations={
            props.accountValuationOverview
              ? props.accountValuationOverview.currentAssetAllocation.map(
                  (v) => ({
                    asset: v.asset,
                    percentage: v.assetAllocationPercentage,
                  }),
                )
              : []
          }
          noDataTextLeftPos={80}
        />
      </Box>
    </Box>
  );
}
