import React, { useRef, useState } from "react";
import {
  DialogContent,
  Typography,
  IconButton,
  useTheme,
  Button,
  Box,
  MenuItem,
  Divider,
  Tooltip,
  alpha,
  InputAdornment,
  Collapse,
} from "@mui/material";
import {
  Info as InfoIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import range from "lodash/range";
import { MarketListingViewModel } from "james/views/marketListingView";
import dayjs from "dayjs";
import { Amount as AmountType } from "james/ledger";
import { Amount } from "components/Ledger/Amount";
import { FormData, FormDataUpdaterSpecsType } from "../useValidatedForm";
import { TextField, TextNumField } from "components/FormFields";
import { FundAccountDialog } from "views/Accounts/components/FundAccountDialog/FundAccountDialog";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { ValidationResult } from "common/validation";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";

export interface InputContentProps {
  isMobile: boolean;
  loading: boolean;
  marketListingViewModel: MarketListingViewModel;
  quoteTokenViewModel: LedgerTokenViewModel | undefined;
  formData: FormData;
  formDataUpdate: FormDataUpdaterSpecsType;
  formDataValidationResult: ValidationResult;
  potentialSourceAccounts: StellarAccountViewModel[];
  hasPriceBreakdown: boolean;
  unitPrice: AmountType;
  unitPriceDescription: string;
  estSpotPlusMarginPrice?: AmountType;
  estStorageCost?: AmountType;
}

export const InputContent = ({
  isMobile,
  loading,
  marketListingViewModel,
  quoteTokenViewModel,
  formData,
  formDataUpdate,
  formDataValidationResult,
  potentialSourceAccounts,
  hasPriceBreakdown,
  unitPrice,
  unitPriceDescription,
  estSpotPlusMarginPrice,
  estStorageCost,
}: InputContentProps) => {
  const theme = useTheme();
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [showFundAccDialog, setShowFundAccDialog] = useState(false);

  // Set investment amount to account balance or left over sub amount
  const setMax = () => {
    if (!marketListingViewModel.marketSubscriptionOrderBookViewModel) return;

    const orderBook =
      marketListingViewModel.marketSubscriptionOrderBookViewModel;

    const remainingAmount = orderBook.minimumOrderAmount.token.newAmountOf(
      orderBook.overSubscriptionAmount.value.minus(
        orderBook.subscribedAmount.value,
      ),
    );
    const maxInvestmentAmount = formData.accountBalance.value.isLessThan(
      remainingAmount.value,
    )
      ? formData.accountBalance
      : remainingAmount;

    formDataUpdate.investmentAmount(maxInvestmentAmount);
  };

  // show/hide price breakdown
  const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
  const toggleShowPriceBreakdown = () => {
    // open order breakdown
    setShowPriceBreakdown(!showPriceBreakdown);

    // and scroll to the bottom in 500ms
    range(10, 500, 10).forEach((v) =>
      setTimeout(() => {
        if (dialogContentRef.current) {
          dialogContentRef.current.scrollTop =
            dialogContentRef.current.scrollHeight;
        }
      }, v),
    );
  };

  return (
    <DialogContent
      ref={dialogContentRef}
      sx={(theme) => ({
        p: 0,
        backgroundColor: theme.palette.custom.midnight,
      })}
      className={isMobile ? "" : "meshScroll"}
    >
      {/* Subscription Amount */}
      <Box
        sx={{
          backgroundColor: theme.palette.custom.cardInner,
          padding: isMobile
            ? theme.spacing(4, 3, 3, 3)
            : theme.spacing(4, 6, 3, 6),
        }}
      >
        <Typography
          variant="h4"
          children={`How much would you like to invest?`}
          sx={{
            fontWeight: "bold",
            mb: 2,
            mr: 5, // for wrapping the 'to'
          }}
        />
        <TextNumField
          id="subscriptionTicket-investmentAmount-textNumField"
          disallowNegative
          label="Investment Amount"
          noDecimalPlaces={7}
          fullWidth
          disabled={loading}
          value={formData.investmentAmount.value}
          InputProps={{
            startAdornment: (
              <Tooltip
                title={`Issued by ${quoteTokenViewModel?.issuer}`}
                placement="top"
              >
                <Typography
                  id={
                    "subscriptionTicket-investmentAmount-textNumField-tokenStartAdornment"
                  }
                  variant="body1"
                  children={quoteTokenViewModel?.token.code}
                  sx={{
                    marginRight: 1,
                    color: theme.palette.text.secondary,
                    cursor: "pointer",
                    "&:hover": {
                      color: theme.palette.primary.light,
                    },
                  }}
                />
              </Tooltip>
            ),
            endAdornment: (
              <InputAdornment position={"end"} sx={{ alignItems: "center" }}>
                <Button
                  id={
                    "subscriptionTicket-investmentAmount-textNumField-setMax-button"
                  }
                  sx={{
                    width: "39px !important",
                    minWidth: 0,
                    padding: theme.spacing(0, 0.5),
                    marginBottom: "-3px",
                  }}
                  disabled={loading}
                  variant={"text"}
                  color={"secondary"}
                  children={"max"}
                  onClick={setMax}
                />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            formDataUpdate.investmentAmount(
              formData.investmentAmount.setValue(e.target.value),
            );
          }}
        />

        {/* Investment amount invalidation or available balance */}
        {formDataValidationResult.fieldValidations.investmentAmount ? (
          <Box sx={{ ml: 2 }}>
            <Typography
              variant={"caption"}
              sx={(theme) => ({
                color: theme.palette.error.main,
              })}
            >
              {formDataValidationResult.fieldValidations.investmentAmount}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              ml: 2,
            }}
          >
            <Typography
              variant="caption"
              sx={{ color: "text.disabled", mr: 0.5 }}
            >
              Available:
            </Typography>
            <Amount
              id="subscriptionTicket-available-amountField"
              amount={formData.accountBalance}
              formatTextNumOpts={{ noDecimalPlaces: 2 }}
              codeTypographyProps={{
                variant: "caption",
                color: "textSecondary",
              }}
              valueTypographyProps={{
                variant: "caption",
                color: "textSecondary",
              }}
            />
          </Box>
        )}

        {/* Account Selector */}
        {potentialSourceAccounts.length > 1 && (
          <TextField
            select
            value={formData.selectedAccountIdx}
            label={"Mesh Account"}
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {potentialSourceAccounts.map((account, idx) => {
              return (
                <MenuItem
                  key={idx}
                  value={idx}
                  onClick={() => formDataUpdate.selectedAccountIdx(idx)}
                >
                  {account.getLabel()} - {account.getNumber()}
                </MenuItem>
              );
            })}
          </TextField>
        )}

        {formDataValidationResult.fieldValidations.investmentAmount?.includes(
          "Insufficient balance",
        ) && (
          <Box>
            <Button
              sx={{
                mt: 2,
                color: isMobile
                  ? theme.palette.text.primary
                  : theme.palette.secondary.main,
              }}
              variant={isMobile ? "contained" : "text"}
              fullWidth={isMobile ? true : false}
              size="small"
              color="secondary"
              onClick={() => setShowFundAccDialog(true)}
            >
              Fund Account
            </Button>
          </Box>
        )}

        {showFundAccDialog && (
          <FundAccountDialog
            accountID={formData.selectedSourceAccountViewModel.getId()}
            open
            onClose={() => setShowFundAccDialog(false)}
          />
        )}
      </Box>
      <Box
        sx={{
          padding: isMobile ? 3 : theme.spacing(3, 6),
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography
            variant="h6"
            children="Estimated Values:"
            sx={{ mb: "2px" }}
          />
          <Tooltip
            arrow
            placement={isMobile ? "top" : "right"}
            title={`
                      Final values are calculated on or before the settlement date
                      based on the prevailing market spot price.
                      `}
            PopperProps={{
              sx: { maxWidth: 280 },
            }}
          >
            <InfoIcon
              sx={{
                height: 20,
                color: theme.palette.text.secondary,
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {/* Number of Tokens */}
          <Box sx={{ margin: theme.spacing(1, 1, 0, 0) }}>
            <Typography
              color="textSecondary"
              variant="caption"
              children={`
                          ${marketListingViewModel.assetType}
                          Tokens
                        `}
            />
            <Amount
              reverse
              id="subscriptionTicket-tokens-amountField"
              codeTypographyProps={{
                variant: "h5",
                fontWeight: "bold",
                sx: {
                  color: alpha(theme.palette.warning.light, 0.38),
                  cursor: "pointer",
                  "&:hover": {
                    color: alpha(theme.palette.warning.light, 0.5),
                  },
                },
              }}
              valueTypographyProps={{
                variant: "h5",
                fontWeight: "bold",
                sx: {
                  color: theme.palette.warning.light,
                },
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 7,
                addDecimalPadding: false,
              }}
              amount={formData.tokenAmount}
            />
          </Box>

          {/* Price per Token */}
          <Box
            sx={{
              mt: 1,
              mr: isMobile ? 1 : 0,
            }}
          >
            <Typography
              color="textSecondary"
              variant="caption"
              children="Price per Token"
            />
            <Amount
              id="subscriptionTicket-price-amountField"
              codeTypographyProps={{
                variant: "h5",
                fontWeight: "bold",
                sx: {
                  color: alpha(theme.palette.warning.light, 0.38),
                  cursor: "pointer",
                  "&:hover": {
                    color: alpha(theme.palette.warning.light, 0.5),
                  },
                },
              }}
              valueTypographyProps={{
                variant: "h5",
                fontWeight: "bold",
                sx: {
                  color: theme.palette.warning.light,
                },
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 7,
                addDecimalPadding: false,
              }}
              amount={unitPrice}
            />
          </Box>
        </Box>
      </Box>
      <Divider></Divider>

      {hasPriceBreakdown && (
        <Box
          sx={{
            padding: isMobile
              ? theme.spacing(3, 3, 2, 3)
              : theme.spacing(3, 6, 0, 6),
          }}
        >
          {/* Price Breakdown Toggle */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              columnGap: theme.spacing(1),
              alignItems: "center",
            }}
          >
            <IconButton
              id="subscriptionTicket-priceBreakdownToggle-iconButton"
              size="small"
              edge="start"
              onClick={toggleShowPriceBreakdown}
            >
              {showPriceBreakdown ? (
                <ExpandLessIcon color="primary" />
              ) : (
                <ExpandMoreIcon color="primary" />
              )}
            </IconButton>
            <Typography variant="h6" children="Price Breakdown" />
          </Box>
          {/* Price Breakdown Items */}
          <Collapse in={showPriceBreakdown}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                alignItems: "center",
                paddingY: theme.spacing(2),
                paddingRight: isMobile ? 1 : 0,
                columnGap: theme.spacing(2),
                justifyContent: "space-between",
              }}
            >
              {/* Unit Price Row */}
              <Typography
                variant="caption"
                sx={{ color: theme.palette.text.secondary }}
              >
                {`${unitPriceDescription}:`}
              </Typography>
              <Amount
                id="subscriptionTicket-priceBreakdown-unitPrice-amountField"
                codeTypographyProps={{
                  variant: "caption",
                  sx: { color: theme.palette.text.primary },
                }}
                valueTypographyProps={{
                  variant: "caption",
                  sx: { color: theme.palette.text.primary },
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={estSpotPlusMarginPrice}
              />
              {/* Storage & Insurance Row */}
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(0.5),
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  Storage & Insurance
                </Typography>
                <Tooltip
                  arrow
                  placement={isMobile ? "top" : "right"}
                  title={`
                              Vaulting and insurance costs for your
                              ${marketListingViewModel.assetType}
                              up to
                              ${dayjs(
                                formData.subscriptionOrderBookViewModel
                                  .settlementDate,
                              )
                                .add(2, "year")
                                .format("DD MMM YYYY")},
                              calculated as 1.2% of spot price.
                            `}
                >
                  <InfoIcon
                    sx={{
                      height: 15,
                      width: 15,
                      color: theme.palette.text.secondary,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  :
                </Typography>
              </Box>
              <Amount
                id="marketDirectOrderInvestorBuyCard-orderBreakDown-tradeFee-amountField"
                codeTypographyProps={{
                  variant: "caption",
                  sx: { color: theme.palette.text.primary },
                }}
                valueTypographyProps={{
                  variant: "caption",
                  sx: { color: theme.palette.text.primary },
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={estStorageCost}
              />
            </Box>
          </Collapse>
        </Box>
      )}
    </DialogContent>
  );
};
