import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { Client } from "james/client";
import { useApplicationContext } from "context/Application/Application";
import { useBankingDetailsViewStore } from "../store";
import { useAPIContext } from "context/API";
import { ReadManyBankAccountRequest } from "@mesh/common-js/src/banking/bankAccountReader_meshproto_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { CreateNewBankAccountUnscopedRequest } from "@mesh/common-js/src/banking/bankAccountCreator_pb";
import {
  BankAccountType,
  BankName,
} from "@mesh/common-js/src/banking/bankAccount_pb";

// custom hook to capture/create bank account for an client.
export const useCaptureBankAccountDetails = () => {
  const state = useBankingDetailsViewStore();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();
  const { banking } = useAPIContext();

  const captureClientBankAccountDetails = async (client: Client) => {
    // validation to ensure all the necessary field are populated
    if (!state.isBankDetailsPopulated()) {
      enqueueSnackbar("Please fill all fields before submitting", {
        variant: "warning",
      });
      return;
    }

    state.setLoading(true);
    try {
      const createRequest = new CreateNewBankAccountUnscopedRequest()
        .setContext(authContext.toFuture())
        .setOwnerid(client.ownerID)
        .setCountrycode(state.countryCode)
        .setBankname(state.bankName || BankName.BANK_NAME_UNDEFINED)
        .setAccounttype(
          state.accountType || BankAccountType.BANK_ACCOUNT_TYPE_UNDEFINED,
        )
        .setBranchcode(state.branchCode)
        .setNumber(state.number);

      // Attempt to save bank account details
      await banking.bankAccountCreator.createNewBankAccountUnscoped(
        createRequest,
      );

      const request = new ReadManyBankAccountRequest()
        .setContext(authContext.toFuture())
        .setCriteriaList([newTextExactCriterion("ownerID", client.ownerID)]);
      const response =
        await banking.bankAccountReader.readManyBankAccount(request);
      // Update local state with the fetched bank account details.
      state.updateStateWithBankAccount(response.getRecordsList()[0]);
      state.setLastUpdateBy(
        response.getRecordsList()[0].getAuditentry()?.getUserid() || "",
      );
      state.setLastUpdateDate(
        response.getRecordsList()[0].getAuditentry()?.getTime()?.toString() ||
          "",
      );
      state.setCachedBankAccount(response.getRecordsList()[0]);
      state.setBankNotFound(false);
      enqueueSnackbar("Bank Account Details Captured", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error capturing bank account details: ${
          err.message ? err.message : err.toString()
        }`,
      );
      errorContextDefaultErrorFeedback(e);
    }
    state.setLoading(false);
  };

  return {
    captureClientBankAccountDetails,
  };
};
