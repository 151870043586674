import dayjs from "dayjs";
import { Entry } from "../audit/Entry";
import { Amount } from "../ledger/Amount";
import { Token } from "../ledger";

const IndicativePriceTypeName = "mesh::market/IndicativePrice";

export class IndicativePrice {
  public ["@type"]: string = IndicativePriceTypeName;

  public id = "";

  public token: Token = new Token();

  public buyPrice: Amount = new Amount();

  public sellPrice: Amount = new Amount();

  public timeOfPrice: string = dayjs().format();

  public auditEntry: Entry = new Entry();

  constructor(l?: IndicativePrice) {
    if (!l) {
      return;
    }
    this.id = l.id;
    this.token = new Token(l.token);
    this.buyPrice = new Amount(l.buyPrice);
    this.sellPrice = new Amount(l.sellPrice);
    this.timeOfPrice = l.timeOfPrice;
    this.auditEntry = new Entry(l.auditEntry);
  }
}
