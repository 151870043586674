import React, { useState } from "react";
import { CompanyOnboardingRequest } from "views/SignUp/components/CompanyOnboardingRequest/CompanyOnboardingrequest";
import { useFirebaseContext } from "context/Firebase";
import { Navigate, Route, Routes } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";
import { UserState } from "james/user/User";
import { VerifyEmailOTP } from "views/SignUp/components/VerifyEmailOTP/VerifyEmailOTP";
import { InitiateUserRegistration } from "./const";
import { Register } from "./components/Register/Register";
import { CountrySelector } from "./components/CountrySelector/CountrySelector";
import { NonSouthAfricanClient } from "./components/NonSouthAfricanClient/NonSouthAfricanClient";
import { ClientSelector } from "./components/ClientSelector/ClientSelector";
import { Helmet } from "react-helmet-async";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

export function SignUp() {
  const [selectedCountry, setSelectedCountry] = useState("");
  const { Banner } = useAppNoticeContext();

  return (
    <>
      <Helmet>
        <title>Mesh | Sign up</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="sign up to Mesh, create a Mesh account"
        />
      </Helmet>
      <Routes>
        <Route
          path="/company-onboarding-request"
          element={
            <SignUpRouteWrapper
              routeName={SignUpRouteName.CompanyOnboardingRequest}
            >
              <div>
                {Banner}
                <CompanyOnboardingRequest />
              </div>
            </SignUpRouteWrapper>
          }
        />
        <Route
          path="/verify-email"
          element={
            <SignUpRouteWrapper routeName={SignUpRouteName.VerifyEmail}>
              <div>
                {Banner}
                <VerifyEmailOTP />
              </div>
            </SignUpRouteWrapper>
          }
        />
        <Route
          path="/client-selector"
          element={
            <SignUpRouteWrapper routeName={SignUpRouteName.ClientSelector}>
              <div>
                {Banner}
                <ClientSelector />
              </div>
            </SignUpRouteWrapper>
          }
        />
        <Route path="/verify-mobile" />
        <Route
          path="/country-selector"
          element={
            <SignUpRouteWrapper routeName={SignUpRouteName.CountrySelector}>
              <div>
                {Banner}
                <CountrySelector
                  selectedCountry={(countryCode) =>
                    setSelectedCountry(countryCode)
                  }
                />
              </div>
            </SignUpRouteWrapper>
          }
        />
        <Route
          path="/non-sa-client"
          element={
            <SignUpRouteWrapper
              selectedCountry={selectedCountry}
              routeName={SignUpRouteName.NonSouthAfricanClient}
            >
              <div>
                {Banner}
                <NonSouthAfricanClient countryCode={selectedCountry} />
              </div>
            </SignUpRouteWrapper>
          }
        />
        <Route
          path="/register"
          element={
            <SignUpRouteWrapper routeName={SignUpRouteName.Register}>
              <div>
                {Banner}
                <Register />
              </div>
            </SignUpRouteWrapper>
          }
        />
        <Route
          path="/"
          element={<Navigate to={"/sign-up/client-selector"} replace />}
        />
        <Route path="*" element={<Navigate to={"/sign-up"} replace />} />
      </Routes>
    </>
  );
}

export enum SignUpRouteName {
  ClientSelector = "Client Selector",
  CountrySelector = "Country Selector",
  CompanyOnboardingRequest = "Company Onboarding Request",
  VerifyEmail = "Verify Email",
  Register = "Register",
  NonSouthAfricanClient = "Non South African Client",
}

interface SignUpRouteWrapper {
  children: JSX.Element;
  routeName: SignUpRouteName;
  selectedCountry?: string;
}

const SignUpRouteWrapper = (props: SignUpRouteWrapper) => {
  const { loginClaims, userAuthenticated } = useApplicationContext();
  const { firebaseAuthenticated, firebaseUser } = useFirebaseContext();
  const loggedIn = firebaseAuthenticated && userAuthenticated;

  if (loggedIn && loginClaims.userState === UserState.Registered) {
    return <Navigate to={"/"} />;
  }

  if (
    props.routeName === SignUpRouteName.NonSouthAfricanClient &&
    !props.selectedCountry
  ) {
    return <Navigate to={"/sign-up/country-selector"} replace />;
  }

  if (
    firebaseAuthenticated &&
    props.routeName !== SignUpRouteName.VerifyEmail &&
    !firebaseUser?.emailVerified
  ) {
    return <Navigate to={"/sign-up/verify-email"} replace />;
  } else if (
    localStorage.getItem(InitiateUserRegistration) &&
    firebaseAuthenticated &&
    props.routeName === SignUpRouteName.VerifyEmail &&
    !firebaseUser?.emailVerified
  ) {
    return props.children;
  }

  return props.children;
};
