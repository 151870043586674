import React from "react";
import {
  CircularProgress,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";

export const LoadingContent = () => {
  return (
    <DialogContent
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: { sm: 472 },
        width: { sm: 420 },
      }}
    >
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "1fr",
          rowGap: theme.spacing(2),
          alignItems: "center",
          justifyItems: "center",
        })}
      >
        <CircularProgress size={70} />
        <Typography
          variant={"h5"}
          color={"textSecondary"}
          children={"Getting things ready for you..."}
        />
      </Box>
    </DialogContent>
  );
};
