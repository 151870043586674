// source: banking/bankAccountCreator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
var banking_bankAccount_pb = require('../banking/bankAccount_pb.js');
goog.object.extend(proto, banking_bankAccount_pb);
goog.exportSymbol('proto.banking.CreateNewBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.CreateNewBankAccountResponse', null, global);
goog.exportSymbol('proto.banking.CreateNewBankAccountUnscopedRequest', null, global);
goog.exportSymbol('proto.banking.CreateNewBankAccountUnscopedResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.CreateNewBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.CreateNewBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.CreateNewBankAccountRequest.displayName = 'proto.banking.CreateNewBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.CreateNewBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.CreateNewBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.CreateNewBankAccountResponse.displayName = 'proto.banking.CreateNewBankAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.CreateNewBankAccountUnscopedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.CreateNewBankAccountUnscopedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.CreateNewBankAccountUnscopedRequest.displayName = 'proto.banking.CreateNewBankAccountUnscopedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.CreateNewBankAccountUnscopedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.CreateNewBankAccountUnscopedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.CreateNewBankAccountUnscopedResponse.displayName = 'proto.banking.CreateNewBankAccountUnscopedResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.CreateNewBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.CreateNewBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.CreateNewBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateNewBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
ownerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
countrycode: jspb.Message.getFieldWithDefault(msg, 3, ""),
bankname: jspb.Message.getFieldWithDefault(msg, 4, 0),
accounttype: jspb.Message.getFieldWithDefault(msg, 5, 0),
branchcode: jspb.Message.getFieldWithDefault(msg, 6, ""),
number: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.CreateNewBankAccountRequest}
 */
proto.banking.CreateNewBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.CreateNewBankAccountRequest;
  return proto.banking.CreateNewBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.CreateNewBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.CreateNewBankAccountRequest}
 */
proto.banking.CreateNewBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountrycode(value);
      break;
    case 4:
      var value = /** @type {!proto.banking.BankName} */ (reader.readEnum());
      msg.setBankname(value);
      break;
    case 5:
      var value = /** @type {!proto.banking.BankAccountType} */ (reader.readEnum());
      msg.setAccounttype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchcode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.CreateNewBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.CreateNewBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.CreateNewBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateNewBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getOwnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountrycode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBankname();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAccounttype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBranchcode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.CreateNewBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.CreateNewBankAccountRequest} returns this
*/
proto.banking.CreateNewBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.CreateNewBankAccountRequest} returns this
 */
proto.banking.CreateNewBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.CreateNewBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string OwnerID = 2;
 * @return {string}
 */
proto.banking.CreateNewBankAccountRequest.prototype.getOwnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateNewBankAccountRequest} returns this
 */
proto.banking.CreateNewBankAccountRequest.prototype.setOwnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CountryCode = 3;
 * @return {string}
 */
proto.banking.CreateNewBankAccountRequest.prototype.getCountrycode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateNewBankAccountRequest} returns this
 */
proto.banking.CreateNewBankAccountRequest.prototype.setCountrycode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BankName bankName = 4;
 * @return {!proto.banking.BankName}
 */
proto.banking.CreateNewBankAccountRequest.prototype.getBankname = function() {
  return /** @type {!proto.banking.BankName} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.banking.BankName} value
 * @return {!proto.banking.CreateNewBankAccountRequest} returns this
 */
proto.banking.CreateNewBankAccountRequest.prototype.setBankname = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional BankAccountType accountType = 5;
 * @return {!proto.banking.BankAccountType}
 */
proto.banking.CreateNewBankAccountRequest.prototype.getAccounttype = function() {
  return /** @type {!proto.banking.BankAccountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.banking.BankAccountType} value
 * @return {!proto.banking.CreateNewBankAccountRequest} returns this
 */
proto.banking.CreateNewBankAccountRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string branchCode = 6;
 * @return {string}
 */
proto.banking.CreateNewBankAccountRequest.prototype.getBranchcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateNewBankAccountRequest} returns this
 */
proto.banking.CreateNewBankAccountRequest.prototype.setBranchcode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string number = 7;
 * @return {string}
 */
proto.banking.CreateNewBankAccountRequest.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateNewBankAccountRequest} returns this
 */
proto.banking.CreateNewBankAccountRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.CreateNewBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.CreateNewBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.CreateNewBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateNewBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.CreateNewBankAccountResponse}
 */
proto.banking.CreateNewBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.CreateNewBankAccountResponse;
  return proto.banking.CreateNewBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.CreateNewBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.CreateNewBankAccountResponse}
 */
proto.banking.CreateNewBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.CreateNewBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.CreateNewBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.CreateNewBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateNewBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount BankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.CreateNewBankAccountResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.CreateNewBankAccountResponse} returns this
*/
proto.banking.CreateNewBankAccountResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.CreateNewBankAccountResponse} returns this
 */
proto.banking.CreateNewBankAccountResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.CreateNewBankAccountResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.CreateNewBankAccountUnscopedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.CreateNewBankAccountUnscopedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateNewBankAccountUnscopedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
ownerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
countrycode: jspb.Message.getFieldWithDefault(msg, 3, ""),
bankname: jspb.Message.getFieldWithDefault(msg, 4, 0),
accounttype: jspb.Message.getFieldWithDefault(msg, 5, 0),
branchcode: jspb.Message.getFieldWithDefault(msg, 6, ""),
number: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.CreateNewBankAccountUnscopedRequest;
  return proto.banking.CreateNewBankAccountUnscopedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.CreateNewBankAccountUnscopedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountrycode(value);
      break;
    case 4:
      var value = /** @type {!proto.banking.BankName} */ (reader.readEnum());
      msg.setBankname(value);
      break;
    case 5:
      var value = /** @type {!proto.banking.BankAccountType} */ (reader.readEnum());
      msg.setAccounttype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchcode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.CreateNewBankAccountUnscopedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.CreateNewBankAccountUnscopedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateNewBankAccountUnscopedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getOwnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountrycode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBankname();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAccounttype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBranchcode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest} returns this
*/
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest} returns this
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string OwnerID = 2;
 * @return {string}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.getOwnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest} returns this
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.setOwnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CountryCode = 3;
 * @return {string}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.getCountrycode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest} returns this
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.setCountrycode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BankName bankName = 4;
 * @return {!proto.banking.BankName}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.getBankname = function() {
  return /** @type {!proto.banking.BankName} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.banking.BankName} value
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest} returns this
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.setBankname = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional BankAccountType accountType = 5;
 * @return {!proto.banking.BankAccountType}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.getAccounttype = function() {
  return /** @type {!proto.banking.BankAccountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.banking.BankAccountType} value
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest} returns this
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string branchCode = 6;
 * @return {string}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.getBranchcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest} returns this
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.setBranchcode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string number = 7;
 * @return {string}
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateNewBankAccountUnscopedRequest} returns this
 */
proto.banking.CreateNewBankAccountUnscopedRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.CreateNewBankAccountUnscopedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.CreateNewBankAccountUnscopedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.CreateNewBankAccountUnscopedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateNewBankAccountUnscopedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.CreateNewBankAccountUnscopedResponse}
 */
proto.banking.CreateNewBankAccountUnscopedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.CreateNewBankAccountUnscopedResponse;
  return proto.banking.CreateNewBankAccountUnscopedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.CreateNewBankAccountUnscopedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.CreateNewBankAccountUnscopedResponse}
 */
proto.banking.CreateNewBankAccountUnscopedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.CreateNewBankAccountUnscopedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.CreateNewBankAccountUnscopedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.CreateNewBankAccountUnscopedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateNewBankAccountUnscopedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount BankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.CreateNewBankAccountUnscopedResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.CreateNewBankAccountUnscopedResponse} returns this
*/
proto.banking.CreateNewBankAccountUnscopedResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.CreateNewBankAccountUnscopedResponse} returns this
 */
proto.banking.CreateNewBankAccountUnscopedResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.CreateNewBankAccountUnscopedResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.banking);
