import { Skeleton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { InstrumentTypeC } from "components/Cards/MarketplaceCard/components/InstrumentType/InstrumentType";
import { IssuerNameCard } from "components/Cards/MarketplaceCard/components/IssuerName/IssuerName";
import { NetworkLogo } from "components/Cards/MarketplaceCard/components/NetworkLogo";
import { MetricItem } from "components/Cards/MarketplaceCard/components/MetricItem";
import { DataComponentTransaction } from "const/gtm";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { useMarketContext } from "context/Market";
import {
  MechanismType,
  SpotType,
  IndicativePriceRepository,
} from "james/market";
import { IndicativePriceFetcher } from "james/market/IndicativePriceFetcher";
import { TextExactCriterion } from "james/search/criterion";
import { TokenIdentifier } from "james/search/identifier";
import { NewSorting, Query } from "james/search/query";
import { AssetType } from "james/views/marketListingView/Model";
import { CouldNotGetPrice } from "pkgTemp/market";
import { useState, useEffect, useMemo } from "react";
import {
  AssetOverviewSectionProps,
  cryptoCardAssetTypes,
} from "../AssetOverview";
import { RiskProfile } from "components/Cards/MarketplaceCard/components/RiskProfile";
import { PrivatePlacementChip } from "components/Cards/MarketplaceCard/components/PrivatePlacements/PrivatePlacementChip";

export const MobileAssetCard = (props: AssetOverviewSectionProps) => {
  const theme = useTheme();

  const [initialised, setInitialised] = useState(false);
  const [isCryptoCard, setIsCryptoCard] = useState(false);

  const { marketContextSpotPricer } = useMarketContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const isPublic = location.pathname.startsWith("/public");
  const { authContext } = useApplicationContext();
  const [price, setPrice] = useState<{
    buy: string;
    sell: string;
  }>({
    buy: "",
    sell: "",
  });

  // initialisation
  useEffect(() => {
    // do nothing until given market listing view model is set
    if (!props.marketListingViewModel) {
      return;
    }

    // compute required parameters
    setIsCryptoCard(
      cryptoCardAssetTypes.includes(props.marketListingViewModel?.assetType),
    );

    setInitialised(true);
  }, [props.marketListingViewModel]);

  // spot price setting
  useEffect(() => {
    setTimeout(async () => {
      if (props.marketListingViewModel?.marketSubscriptionOrderBookViewModel) {
        const unitPrice =
          props.marketListingViewModel?.marketSubscriptionOrderBookViewModel
            .unitPrice;
        setPrice({
          buy: unitPrice.value.toString(),
          sell: unitPrice.value.toString(),
        });
        return;
      }

      if (
        cryptoCardAssetTypes.includes(
          props.marketListingViewModel?.assetType,
        ) &&
        props.marketListingViewModel
      ) {
        const spotMarketMechanism =
          props.marketListingViewModel.listingMarketMechanisms.find(
            (mm) => mm.type === MechanismType.Spot,
          );
        if (!spotMarketMechanism) {
          console.error("expected to find spot market mechanism");
          return;
        }
        if (!spotMarketMechanism.quoteParameters.length) {
          console.error("expected at least 1 quote parameter");
          return;
        }

        // get default quote parameter
        let defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
          (qp) => qp.quoteToken.code === "mZAR",
        );
        if (!defaultQuoteParameter) {
          defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
            (qp) => qp.quoteToken.code === "USDC",
          );
        }

        // return if no quote parameter was found
        try {
          if (!defaultQuoteParameter) {
            return;
          }

          if (
            !defaultQuoteParameter &&
            spotMarketMechanism.quoteParameters.length
          ) {
            defaultQuoteParameter = spotMarketMechanism.quoteParameters[0];
          }
          (
            await marketContextSpotPricer.PriceSpot({
              spotType: SpotType.Buy,
              baseAmount: defaultQuoteParameter.minimumDealSize,
              quoteAmount: defaultQuoteParameter.quoteToken.newAmountOf("0"),
            })
          ).quoteAmount.value.div(defaultQuoteParameter.minimumDealSize.value);
        } catch (e) {
          // log all errors
          console.error(`error pricing spot: ${e}`);

          if (!(e instanceof CouldNotGetPrice)) {
            // return without stopping price loading for unexpected error
            return;
          }
        }
      }

      if (props.marketListingViewModel) {
        if (
          props.marketListingViewModel.listingMarketMechanisms.find(
            (mm) => mm.type === MechanismType.Spot,
          )
        ) {
          return;
        }
        try {
          let latestIndicativePrice;
          if (isPublic) {
            latestIndicativePrice = (
              await IndicativePriceFetcher.PublicFetchLatestIndicativePrice({
                assetIdentifier: TokenIdentifier(
                  props.marketListingViewModel.token,
                ),
              })
            ).publicIndicativePrice;
          } else {
            latestIndicativePrice = (
              await IndicativePriceRepository.SearchIndicativePrice({
                context: authContext,
                criteria: {
                  "token.code": TextExactCriterion(
                    props.marketListingViewModel.token.code,
                  ),
                  "token.issuer": TextExactCriterion(
                    props.marketListingViewModel.token.issuer,
                  ),
                  "token.network": TextExactCriterion(
                    props.marketListingViewModel.token.network,
                  ),
                },
                query: new Query({
                  limit: 1,
                  offset: 0,
                  sorting: [NewSorting("timeOfPrice", "desc")],
                }),
              })
            ).records[0];
          }

          // confirm at least 1 record was retrieved
          if (!latestIndicativePrice) {
            console.error(
              "expected at least 1 indicative price to exist for asset",
            );
            return;
          }

          setPrice({
            buy: latestIndicativePrice.buyPrice.value.toString(),
            sell: latestIndicativePrice.sellPrice.value.toString(),
          });
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error loading indicative price information: ${
              err.message ? err.message : err.toString()
            }`,
          );
          // intentionally not stopping loading - errors will result in card seeming to load forever
        }
      }
    });
  }, [props.marketListingViewModel]);

  const hasRiskProfile = useMemo(
    () => props.marketListingViewModel?.instrumentRiskProfile !== "",
    [props.marketListingViewModel?.instrumentRiskProfile],
  );

  const hasMetricData = useMemo(
    () => props.marketListingViewModel?.metricDescription !== "",
    [props.marketListingViewModel?.metricDescription],
  );

  if (props.marketListingViewModel && initialised) {
    return (
      <Box>
        <Box
          sx={{
            // width: "100%",
            margin: theme.spacing(2, 3),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
          data-component-transaction={JSON.stringify({
            transaction_asset_buy_price: price.buy,
            transaction_asset_sell_price: price.sell,
            transaction_asset_id: props.marketListingViewModel
              ? `${props.marketListingViewModel.token.code}:${props.marketListingViewModel.token.issuer}:${props.marketListingViewModel.token.network}`
              : "-",
            transaction_asset_name: props.marketListingViewModel
              ? props.marketListingViewModel.assetName
              : "-",
            transaction_asset_issuer: props.marketListingViewModel
              ? props.marketListingViewModel.assetOwnerClientShortName
              : "-",
            transaction_asset_risk_rating: props.marketListingViewModel
              ? props.marketListingViewModel.instrumentRiskProfileDescription
              : "-",
            transaction_asset_investor_profile: props.marketListingViewModel
              ? props.marketListingViewModel.instrumentRiskProfile
              : "-",
            transaction_asset_type: props.marketListingViewModel
              ? props.marketListingViewModel.assetType
              : "-",
            transaction_asset_currency: props.marketListingViewModel
              ? props.marketListingViewModel.listingMarketMechanisms[0]
                  .quoteParameters[0].quoteToken.code
              : "-",
          } as DataComponentTransaction)}
        >
          <Box
            sx={{
              zIndex: 1,
              marginTop: "auto",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr auto",
              "&.bond": {
                display: "flex",
                flexDirection: "column-reverse",
              },
            }}
          >
            {isCryptoCard ? (
              <Box sx={{ alignSelf: "flex-end" }}>
                <NetworkLogo
                  breakPoint={"sm"}
                  network={props.marketListingViewModel.token.network}
                />
              </Box>
            ) : (
              // non-cryptos
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {hasMetricData && (
                  <Box>
                    <MetricItem
                      description={
                        props.marketListingViewModel.metricDescription
                      }
                      value={props.marketListingViewModel.metricValue}
                      disclaimer={props.marketListingViewModel.metricDisclaimer}
                      reverseAlign
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    maxWidth: "50%",
                  }}
                >
                  <IssuerNameCard
                    reverseAlign={!hasMetricData}
                    token={props.marketListingViewModel.token}
                    typographyProps={{
                      sx: {
                        color:
                          props.marketListingViewModel.assetType ===
                          AssetType.YieldBearingStablecoin
                            ? theme.palette.text.disabled
                            : theme.palette.text.primary,
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              justifyContent: "space-between",
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <InstrumentTypeC
              token={props.marketListingViewModel.token}
              assetType={props.marketListingViewModel.assetType}
            />
            {props.marketListingViewModel.privateOffer && (
              <PrivatePlacementChip />
            )}
          </Box>

          {hasRiskProfile && (
            <>
              <Box sx={{ width: "100%" }}>
                <RiskProfile
                  riskProfile={
                    props.marketListingViewModel.instrumentRiskProfile
                  }
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 285,
                }}
              >
                <Typography
                  component={"div"}
                  variant={"caption"}
                  sx={{
                    color: "text.disabled",
                    lineHeight: "18px",
                  }}
                  marginTop={"8px"}
                  align={"center"}
                >
                  *Note that the Mesh price might differ from the exchange
                  traded price.
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: "100%",
          height: "77px",
          display: "grid",
          gridTemplateColumns: "1fr auto",
          columnGap: theme.spacing(5),
          padding: theme.spacing(0, 3),
        }}
      >
        <Skeleton width={"100%"} height={"100%"} />
        <Skeleton width={"100px"} height={"100%"} />
      </Box>
    );
  }
};
