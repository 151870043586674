/**
 * @fileoverview gRPC-Web generated client stub for banking
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: banking/bankAccountReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var banking_bankAccount_pb = require('../banking/bankAccount_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var banking_fundingOrderState_pb = require('../banking/fundingOrderState_pb.js')
const proto = {};
proto.banking = require('./bankAccountReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ReadOneBankAccountRequest,
 *   !proto.banking.ReadOneBankAccountResponse>}
 */
const methodDescriptor_BankAccountReader_ReadOneBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountReader/ReadOneBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.ReadOneBankAccountRequest,
  proto.banking.ReadOneBankAccountResponse,
  /**
   * @param {!proto.banking.ReadOneBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.ReadOneBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ReadOneBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ReadOneBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ReadOneBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountReaderClient.prototype.readOneBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountReader/ReadOneBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountReader_ReadOneBankAccount,
      callback);
};


/**
 * @param {!proto.banking.ReadOneBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ReadOneBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountReaderPromiseClient.prototype.readOneBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountReader/ReadOneBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountReader_ReadOneBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ReadManyBankAccountRequest,
 *   !proto.banking.ReadManyBankAccountResponse>}
 */
const methodDescriptor_BankAccountReader_ReadManyBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountReader/ReadManyBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.ReadManyBankAccountRequest,
  proto.banking.ReadManyBankAccountResponse,
  /**
   * @param {!proto.banking.ReadManyBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.ReadManyBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ReadManyBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ReadManyBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ReadManyBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountReaderClient.prototype.readManyBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountReader/ReadManyBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountReader_ReadManyBankAccount,
      callback);
};


/**
 * @param {!proto.banking.ReadManyBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ReadManyBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountReaderPromiseClient.prototype.readManyBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountReader/ReadManyBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountReader_ReadManyBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ReadAllBankAccountRequest,
 *   !proto.banking.ReadAllBankAccountResponse>}
 */
const methodDescriptor_BankAccountReader_ReadAllBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountReader/ReadAllBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.ReadAllBankAccountRequest,
  proto.banking.ReadAllBankAccountResponse,
  /**
   * @param {!proto.banking.ReadAllBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.ReadAllBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ReadAllBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ReadAllBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ReadAllBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountReaderClient.prototype.readAllBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountReader/ReadAllBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountReader_ReadAllBankAccount,
      callback);
};


/**
 * @param {!proto.banking.ReadAllBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ReadAllBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountReaderPromiseClient.prototype.readAllBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountReader/ReadAllBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountReader_ReadAllBankAccount);
};


module.exports = proto.banking;

