/**
 * @fileoverview gRPC-Web generated client stub for banking
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: banking/bankAccountUpdater.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var banking_bankAccount_pb = require('../banking/bankAccount_pb.js')
const proto = {};
proto.banking = require('./bankAccountUpdater_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountUpdaterClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountUpdaterPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.UpdateBankAccountTypeRequest,
 *   !proto.banking.UpdateBankAccountTypeResponse>}
 */
const methodDescriptor_BankAccountUpdater_UpdateBankAccountType = new grpc.web.MethodDescriptor(
  '/banking.BankAccountUpdater/UpdateBankAccountType',
  grpc.web.MethodType.UNARY,
  proto.banking.UpdateBankAccountTypeRequest,
  proto.banking.UpdateBankAccountTypeResponse,
  /**
   * @param {!proto.banking.UpdateBankAccountTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.UpdateBankAccountTypeResponse.deserializeBinary
);


/**
 * @param {!proto.banking.UpdateBankAccountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.UpdateBankAccountTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.UpdateBankAccountTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountUpdaterClient.prototype.updateBankAccountType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBankAccountType',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBankAccountType,
      callback);
};


/**
 * @param {!proto.banking.UpdateBankAccountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.UpdateBankAccountTypeResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountUpdaterPromiseClient.prototype.updateBankAccountType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBankAccountType',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBankAccountType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.UpdateBankAccountNumberRequest,
 *   !proto.banking.UpdateBankAccountNumberResponse>}
 */
const methodDescriptor_BankAccountUpdater_UpdateBankAccountNumber = new grpc.web.MethodDescriptor(
  '/banking.BankAccountUpdater/UpdateBankAccountNumber',
  grpc.web.MethodType.UNARY,
  proto.banking.UpdateBankAccountNumberRequest,
  proto.banking.UpdateBankAccountNumberResponse,
  /**
   * @param {!proto.banking.UpdateBankAccountNumberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.UpdateBankAccountNumberResponse.deserializeBinary
);


/**
 * @param {!proto.banking.UpdateBankAccountNumberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.UpdateBankAccountNumberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.UpdateBankAccountNumberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountUpdaterClient.prototype.updateBankAccountNumber =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBankAccountNumber',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBankAccountNumber,
      callback);
};


/**
 * @param {!proto.banking.UpdateBankAccountNumberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.UpdateBankAccountNumberResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountUpdaterPromiseClient.prototype.updateBankAccountNumber =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBankAccountNumber',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBankAccountNumber);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.UpdateBankNameRequest,
 *   !proto.banking.UpdateBankNameResponse>}
 */
const methodDescriptor_BankAccountUpdater_UpdateBankName = new grpc.web.MethodDescriptor(
  '/banking.BankAccountUpdater/UpdateBankName',
  grpc.web.MethodType.UNARY,
  proto.banking.UpdateBankNameRequest,
  proto.banking.UpdateBankNameResponse,
  /**
   * @param {!proto.banking.UpdateBankNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.UpdateBankNameResponse.deserializeBinary
);


/**
 * @param {!proto.banking.UpdateBankNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.UpdateBankNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.UpdateBankNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountUpdaterClient.prototype.updateBankName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBankName',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBankName,
      callback);
};


/**
 * @param {!proto.banking.UpdateBankNameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.UpdateBankNameResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountUpdaterPromiseClient.prototype.updateBankName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBankName',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBankName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.UpdateBranchCodeRequest,
 *   !proto.banking.UpdateBranchCodeResponse>}
 */
const methodDescriptor_BankAccountUpdater_UpdateBranchCode = new grpc.web.MethodDescriptor(
  '/banking.BankAccountUpdater/UpdateBranchCode',
  grpc.web.MethodType.UNARY,
  proto.banking.UpdateBranchCodeRequest,
  proto.banking.UpdateBranchCodeResponse,
  /**
   * @param {!proto.banking.UpdateBranchCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.UpdateBranchCodeResponse.deserializeBinary
);


/**
 * @param {!proto.banking.UpdateBranchCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.UpdateBranchCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.UpdateBranchCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountUpdaterClient.prototype.updateBranchCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBranchCode',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBranchCode,
      callback);
};


/**
 * @param {!proto.banking.UpdateBranchCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.UpdateBranchCodeResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountUpdaterPromiseClient.prototype.updateBranchCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBranchCode',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBranchCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.UpdateBankAccountUnscopedRequest,
 *   !proto.banking.UpdateBankAccountUnscopedResponse>}
 */
const methodDescriptor_BankAccountUpdater_UpdateBankAccountUnscoped = new grpc.web.MethodDescriptor(
  '/banking.BankAccountUpdater/UpdateBankAccountUnscoped',
  grpc.web.MethodType.UNARY,
  proto.banking.UpdateBankAccountUnscopedRequest,
  proto.banking.UpdateBankAccountUnscopedResponse,
  /**
   * @param {!proto.banking.UpdateBankAccountUnscopedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.UpdateBankAccountUnscopedResponse.deserializeBinary
);


/**
 * @param {!proto.banking.UpdateBankAccountUnscopedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.UpdateBankAccountUnscopedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.UpdateBankAccountUnscopedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountUpdaterClient.prototype.updateBankAccountUnscoped =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBankAccountUnscoped',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBankAccountUnscoped,
      callback);
};


/**
 * @param {!proto.banking.UpdateBankAccountUnscopedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.UpdateBankAccountUnscopedResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountUpdaterPromiseClient.prototype.updateBankAccountUnscoped =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountUpdater/UpdateBankAccountUnscoped',
      request,
      metadata || {},
      methodDescriptor_BankAccountUpdater_UpdateBankAccountUnscoped);
};


module.exports = proto.banking;

