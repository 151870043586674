import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Link,
  Theme,
  useTheme,
  Popover,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import { BigNumber } from "bignumber.js";
import { LoadingBar } from "components/LoadingBar/LoadingBar";
import { Amount } from "components/Ledger/Amount";
import { Amount as AmountType } from "james/ledger";
import { AssetSubscriptionOverviewDataSheetSection } from "james/marketData/AssetSubscriptionOverviewDataSheetSection";
import { List as TimelineIcon } from "@mui/icons-material";
import { TimelineItem } from "components/Timeline";
import { DateTimeFormatFancy } from "const/dateformats";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

interface AssetSubscriptionOverviewRendererProps {
  dataSheetSection: AssetSubscriptionOverviewDataSheetSection;
}

export const AssetSubscriptionOverviewRenderer = ({
  dataSheetSection: {
    targetSubscriptionAmount,
    subscribedAmount,
    underwriterCommitmentAmount,
    tokenCode,
    minimumOrderAmount,
    openDate,
    closeDate,
    settlementDate,
    flexibleUnitPrice,
  },
}: AssetSubscriptionOverviewRendererProps) => {
  const theme = useTheme();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [subscribedPercentage, setSubscribedPercentage] = useState(
    new BigNumber(0),
  );
  const [aggregatedUnderwriterPercentage, setAggregatedUnderwriterPercentage] =
    useState(new BigNumber(0));

  // set subscribed percentage
  useEffect(() => {
    if (targetSubscriptionAmount && subscribedAmount) {
      const percentage = subscribedAmount.value
        .dividedBy(targetSubscriptionAmount.value)
        .multipliedBy(100);
      setSubscribedPercentage(percentage);
      return;
    }
  }, [targetSubscriptionAmount, subscribedAmount]);

  // set aggregate underwriter percentage (subscribed + underwritten)
  useEffect(() => {
    if (!targetSubscriptionAmount || !underwriterCommitmentAmount) return;

    const rawPercentage = underwriterCommitmentAmount.value
      .dividedBy(targetSubscriptionAmount.value)
      .multipliedBy(100);

    const aggregatedPercentage = BigNumber.sum(
      rawPercentage,
      subscribedPercentage,
    );

    if (aggregatedPercentage.isGreaterThanOrEqualTo(new BigNumber(100))) {
      setAggregatedUnderwriterPercentage(new BigNumber(100));
      return;
    }
    setAggregatedUnderwriterPercentage(aggregatedPercentage);
  }, [targetSubscriptionAmount, underwriterCommitmentAmount]);

  return (
    <Box
      sx={{
        padding: smDown ? theme.spacing(4, 3, 4, 0) : theme.spacing(4, 5, 4, 0),
      }}
    >
      {subscribedAmount && (
        <>
          {/* Above the loading bar */}
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                sm: "row",
                xs: "column",
              },
              alignItems: smDown ? "start" : "center",
              justifyContent: "space-between",
              mb: 2,
              gap: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightMedium,
              })}
              children={`${subscribedPercentage.dp(2)}% Subscribed`}
            />

            {flexibleUnitPrice && (
              <AmountLegendItem
                description={"Subscribed Amount"}
                amount={
                  subscribedAmount.isUndefined()
                    ? targetSubscriptionAmount.setValue("0")
                    : subscribedAmount
                }
                amountColour={theme.palette.success.light}
                reverseAlign={!smDown}
              />
            )}

            {smDown && !flexibleUnitPrice && (
              <AmountLegendItem
                description={"Target Amount"}
                amount={targetSubscriptionAmount}
                amountColour={theme.palette.text.primary}
              />
            )}
          </Box>

          {/* The loading bar */}
          <LoadingBar
            percent={subscribedPercentage}
            secondaryPercent={aggregatedUnderwriterPercentage}
          />

          {/* Under the loading bar */}
          {!flexibleUnitPrice && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 2,
                gap: 2,
                alignItems: "flex-end",
              }}
            >
              {/* FIXME: Edge case for amounts in excess of 10,000,000 cause wrapping on mobile screens */}
              {/* FIXME: Added the same row thing as well */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  flexBasis: { xs: "100%", sm: "auto" },
                  gap: { sm: 5, xs: 2 },
                  flexDirection: { sm: "row", xs: "column" },
                }}
              >
                <AmountLegendItem
                  description={"Amount Subscribed"}
                  amount={
                    subscribedAmount.isUndefined()
                      ? targetSubscriptionAmount.setValue("0")
                      : subscribedAmount
                  }
                  amountColour={theme.palette.success.light}
                />
                {underwriterCommitmentAmount.value.gt(0) && (
                  <AmountLegendItem
                    description={"Underwriter Commitment"}
                    amount={
                      underwriterCommitmentAmount.isUndefined()
                        ? underwriterCommitmentAmount.setValue("0")
                        : underwriterCommitmentAmount
                    }
                    amountColour={theme.palette.secondary.main}
                  />
                )}
              </Box>
              {!smDown && (
                <AmountLegendItem
                  description={"Target Amount"}
                  amount={targetSubscriptionAmount}
                  amountColour={theme.palette.text.primary}
                  reverseAlign={true}
                />
              )}
            </Box>
          )}

          {flexibleUnitPrice && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <TimelineInfoButton
                openDate={openDate}
                closeDate={closeDate}
                settlementDate={settlementDate}
                minimumOrderAmoun={minimumOrderAmount}
                tokenCode={tokenCode}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

interface TimelineInfoButtonProps {
  openDate: string;
  closeDate: string;
  settlementDate: string;
  minimumOrderAmoun: AmountType;
  tokenCode: string;
}

const TimelineInfoButton: React.FC<TimelineInfoButtonProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "subscriptionOverview-timeline-popover" : undefined;

  return (
    <>
      <Button
        onClick={handlePopoverOpen}
        variant="outlined"
        sx={{ gap: 1, fontWeight: "normal" }}
      >
        <TimelineIcon />
        <Typography variant="body1">Asset Timeline</Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ mt: 1 }}
      >
        <TimelineInfo
          openDate={formatDate(props.openDate)}
          closeDate={formatDate(props.closeDate)}
          settlementDate={formatDate(props.settlementDate)}
          minimumOrderAmount={props.minimumOrderAmoun}
          tokenCode={props.tokenCode}
          onClose={handlePopoverClose}
        />
      </Popover>
    </>
  );
};

interface TimelineInfoProps {
  openDate: string;
  closeDate: string;
  settlementDate: string;
  minimumOrderAmount: AmountType;
  tokenCode: string;
  onClose: () => void;
}

const TimelineInfo: React.FC<TimelineInfoProps> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { openDate, closeDate, settlementDate, onClose } = props;

  if (!openDate) {
    return (
      <Card style={{ backgroundColor: theme.palette.custom.midnight }}>
        <CardContent sx={{ padding: theme.spacing(0) }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: theme.spacing(1, 1, 0, 1),
              }}
            >
              <IconButton
                id="subscriptionOverviewSection-popover-close-iconButton"
                size="small"
                onClick={onClose}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ padding: theme.spacing(1, 3, 0, 3) }}>
              <Typography
                fontWeight={"Bold"}
                children={"Could Not Find Timeline"}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      style={{
        backgroundColor: theme.palette.custom.midnight,
        maxWidth: 550,
        width: "100%",
      }}
    >
      <CardContent sx={{ padding: theme.spacing(0) }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: theme.spacing(1, 1, 0, 1),
            }}
          >
            <IconButton
              id="subscriptionOverviewSection-popover-close-iconButton"
              size="small"
              onClick={onClose}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ padding: theme.spacing(0, 3, 0, 3) }}>
            <TimelineItem
              hasConnector={true}
              title="Subscriptions open"
              subtitle={props.openDate}
              body={
                <Typography variant="body2">
                  {`
                  From this date, subscription orders can be placed with a
                  minimum order amount of
                  ${AmountToString(props.minimumOrderAmount)}.
                  `}
                </Typography>
              }
            />
            <TimelineItem
              hasConnector={true}
              title="Subscriptions close"
              subtitle={closeDate}
              body={
                <Typography variant="body2">
                  After this date, no new subscriptions will be accepted. You
                  can view your open orders{" "}
                  <Link
                    underline="none"
                    onClick={() => {
                      navigate("/market/subscriptions");
                    }}
                  >
                    here
                  </Link>
                  .
                </Typography>
              }
            />
            <TimelineItem
              hasConnector={false}
              title="Settlement"
              subtitle={settlementDate}
              body={
                <Typography variant="body2">
                  The subscribed amount is transferred to the issuer, and{" "}
                  {props.tokenCode} tokens are credited to investors' accounts.
                </Typography>
              }
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

interface AmountLegendItemProps {
  description: string;
  amount: AmountType;
  amountColour: string;
  reverseAlign?: boolean;
}

const AmountLegendItem = ({
  description,
  amount,
  amountColour,
  reverseAlign,
}: AmountLegendItemProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: reverseAlign ? "flex-end" : "flex-start",
    }}
  >
    <Typography
      variant="caption"
      sx={(theme) => ({
        color: theme.palette.text.secondary,
      })}
      children={description}
    />
    <Amount
      codeTypographyProps={{
        sx: (theme) => ({
          fontSize: "16px",
          fontWeight: theme.typography.fontWeightBold,
          color: amountColour,
        }),
      }}
      valueTypographyProps={{
        sx: (theme) => ({
          fontSize: "16px",
          fontWeight: theme.typography.fontWeightBold,
          color: amountColour,
        }),
      }}
      amount={amount}
    />
  </Box>
);

const AmountToString = (amount: AmountType): string => {
  return `${amount.token.code} ${amount.value.toFormat(2)}`;
};

const formatDate = (date: string | undefined): string => {
  const formattedDate = dayjs(date).format(DateTimeFormatFancy);
  return formattedDate.includes("GMT+2")
    ? formattedDate.replace("GMT+2", "SAST")
    : formattedDate;
};
