import React from "react";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { Box, Typography } from "@mui/material";
import { allFilterTapOptions, FilterTabOpt } from "../../const";

type OfferTabFilterProps = {
  activeFilterTabOpt: FilterTabOpt;
  onChange: (filterTabOpt: FilterTabOpt) => void;
};

export const OfferTabFilter = (props: OfferTabFilterProps) => {
  return (
    <Box
      id={"Marketplace-OfferTabFilter"}
      data-testid={"Marketplace-OfferTabFilter"}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        overflowX: "auto",
        gap: {
          sm: 3,
          xs: 2,
        },
        zIndex: 2,
        pb: 1.5,
      }}
      data-component-info={JSON.stringify({
        component_id: "trading_workspace_nav",
        component_business_name: "trading workspace navigation",
        component_title: "Marketplace",
        component_driver: InteractionDriver.Navigation,
      } as DataComponentInfo)}
    >
      {allFilterTapOptions.map((fo, idx) => (
        <Box
          id={`Marketplace-OfferTabFilter-Tab-${fo}`}
          data-testid={`Marketplace-OfferTabFilter-Tab-${fo}`}
          key={idx}
          data-link-info={JSON.stringify({
            content_interaction_id: "nav-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: fo.toLowerCase(),
            content_interaction_type: InteractionType.Link,
            content_interaction_driver: InteractionDriver.Explore,
          } as DataLinkInfoType)}
          sx={[
            {
              cursor: "pointer",
              paddingBottom: (theme) => theme.spacing(0.5),
              borderBottom: (theme) =>
                `1px solid ${theme.palette.background.default}`,
            },
            props.activeFilterTabOpt === fo && {
              borderBottom: (theme) =>
                `1px solid ${theme.palette.primary.main}`,
            },
          ]}
          onClick={() => {
            if (props.activeFilterTabOpt === fo) {
              return;
            }
            props.onChange(fo);
          }}
        >
          <Typography
            variant={"h5"}
            data-link-info={JSON.stringify({
              content_interaction_id: "nav-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: fo.toLowerCase(),
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.Explore,
            } as DataLinkInfoType)}
            color={"textSecondary"}
            sx={[
              {
                fontWeight: (theme) => theme.typography.fontWeightMedium,
              },
              props.activeFilterTabOpt === fo && {
                color: (theme) => theme.palette.primary.main,
              },
            ]}
            children={fo}
          />
        </Box>
      ))}
    </Box>
  );
};
