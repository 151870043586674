/**
 * @fileoverview gRPC-Web generated client stub for authentication
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: security/authentication/mfaService.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../../security/context_pb.js')

var iam_user_pb = require('../../iam/user_pb.js')
const proto = {};
proto.authentication = require('./mfaService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.authentication.MFAServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.authentication.MFAServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authentication.EnableMFAForMyUserRequest,
 *   !proto.authentication.EnableMFAForMyUserResponse>}
 */
const methodDescriptor_MFAService_EnableMFAForMyUser = new grpc.web.MethodDescriptor(
  '/authentication.MFAService/EnableMFAForMyUser',
  grpc.web.MethodType.UNARY,
  proto.authentication.EnableMFAForMyUserRequest,
  proto.authentication.EnableMFAForMyUserResponse,
  /**
   * @param {!proto.authentication.EnableMFAForMyUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authentication.EnableMFAForMyUserResponse.deserializeBinary
);


/**
 * @param {!proto.authentication.EnableMFAForMyUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authentication.EnableMFAForMyUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authentication.EnableMFAForMyUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authentication.MFAServiceClient.prototype.enableMFAForMyUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authentication.MFAService/EnableMFAForMyUser',
      request,
      metadata || {},
      methodDescriptor_MFAService_EnableMFAForMyUser,
      callback);
};


/**
 * @param {!proto.authentication.EnableMFAForMyUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authentication.EnableMFAForMyUserResponse>}
 *     Promise that resolves to the response
 */
proto.authentication.MFAServicePromiseClient.prototype.enableMFAForMyUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authentication.MFAService/EnableMFAForMyUser',
      request,
      metadata || {},
      methodDescriptor_MFAService_EnableMFAForMyUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authentication.RequestMFAForMyUserRequest,
 *   !proto.authentication.RequestMFAForMyUserResponse>}
 */
const methodDescriptor_MFAService_RequestMFAForMyUser = new grpc.web.MethodDescriptor(
  '/authentication.MFAService/RequestMFAForMyUser',
  grpc.web.MethodType.UNARY,
  proto.authentication.RequestMFAForMyUserRequest,
  proto.authentication.RequestMFAForMyUserResponse,
  /**
   * @param {!proto.authentication.RequestMFAForMyUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authentication.RequestMFAForMyUserResponse.deserializeBinary
);


/**
 * @param {!proto.authentication.RequestMFAForMyUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authentication.RequestMFAForMyUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authentication.RequestMFAForMyUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authentication.MFAServiceClient.prototype.requestMFAForMyUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authentication.MFAService/RequestMFAForMyUser',
      request,
      metadata || {},
      methodDescriptor_MFAService_RequestMFAForMyUser,
      callback);
};


/**
 * @param {!proto.authentication.RequestMFAForMyUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authentication.RequestMFAForMyUserResponse>}
 *     Promise that resolves to the response
 */
proto.authentication.MFAServicePromiseClient.prototype.requestMFAForMyUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authentication.MFAService/RequestMFAForMyUser',
      request,
      metadata || {},
      methodDescriptor_MFAService_RequestMFAForMyUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authentication.GetMFATokenForMyUserRequest,
 *   !proto.authentication.GetMFATokenForMyUserResponse>}
 */
const methodDescriptor_MFAService_GetMFATokenForMyUser = new grpc.web.MethodDescriptor(
  '/authentication.MFAService/GetMFATokenForMyUser',
  grpc.web.MethodType.UNARY,
  proto.authentication.GetMFATokenForMyUserRequest,
  proto.authentication.GetMFATokenForMyUserResponse,
  /**
   * @param {!proto.authentication.GetMFATokenForMyUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authentication.GetMFATokenForMyUserResponse.deserializeBinary
);


/**
 * @param {!proto.authentication.GetMFATokenForMyUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authentication.GetMFATokenForMyUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authentication.GetMFATokenForMyUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authentication.MFAServiceClient.prototype.getMFATokenForMyUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authentication.MFAService/GetMFATokenForMyUser',
      request,
      metadata || {},
      methodDescriptor_MFAService_GetMFATokenForMyUser,
      callback);
};


/**
 * @param {!proto.authentication.GetMFATokenForMyUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authentication.GetMFATokenForMyUserResponse>}
 *     Promise that resolves to the response
 */
proto.authentication.MFAServicePromiseClient.prototype.getMFATokenForMyUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authentication.MFAService/GetMFATokenForMyUser',
      request,
      metadata || {},
      methodDescriptor_MFAService_GetMFATokenForMyUser);
};


module.exports = proto.authentication;

