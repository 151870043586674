import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  alpha,
  Autocomplete,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import cx from "classnames";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Close as CloseIcon,
  Done as AllGoodIcon,
  ErrorOutline as ErrorIcon,
  FaceOutlined as FaceIcon,
  FileCopy as CopyPasteIcon,
  Notes as NoteIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import {
  ConsistencyTransaction,
  Sinoatrial,
  useGetAfterEffectPhase,
  useGetCommitPhase,
  useGetValidationPhase,
  ValidationPhaseController,
} from "consistency/consistency";
import dayjs from "dayjs";
import { FETable } from "components/Table/FETable";
import { RunningTransaction } from "consistency/consistency/RunningTransaction";
import { RunningAfterEffect } from "consistency/consistency/RunningAfterEffect";
import { TransactionState } from "consistency/consistency/Transaction";
import { WriteOperationState } from "consistency/consistency/WriteOperation";
import { TextField } from "components/FormFields";
import MultiRowInfoIcon from "./MultiRowInfoIcon";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

const PREFIX = "Dashboard";

const classes = {
  root: `${PREFIX}-root`,
  lastActionAnnotationDialogTitleRootOverride: `${PREFIX}-lastActionAnnotationDialogTitleRootOverride`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
  textArea: `${PREFIX}-textArea`,
  textAreaError: `${PREFIX}-textAreaError`,
  commonLastActionAnnotationsFieldWidth: `${PREFIX}-commonLastActionAnnotationsFieldWidth`,
  phaseHeaderCardRoot: `${PREFIX}-phaseHeaderCardRoot`,
  switchLayout: `${PREFIX}-switchLayout`,
  actionLayout: `${PREFIX}-actionLayout`,
  runningStateText: `${PREFIX}-runningStateText`,
  runningStateStoppedText: `${PREFIX}-runningStateStoppedText`,
  autoRefreshSwitchLayout: `${PREFIX}-autoRefreshSwitchLayout`,
  copyPasteIcon: `${PREFIX}-copyPasteIcon`,
  idTextField: `${PREFIX}-idTextField`,
  row: `${PREFIX}-row`,
  noDataSplashCardContent: `${PREFIX}-noDataSplashCardContent`,
  noDataSplashBody: `${PREFIX}-noDataSplashBody`,
  noDataSplashIcon: `${PREFIX}-noDataSplashIcon`,
  noDataSlashErrorIcon: `${PREFIX}-noDataSlashErrorIcon`,
  splashRow: `${PREFIX}-splashRow`,
};

const StyledRoot = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },

  // validation phase
  [`& .${classes.lastActionAnnotationDialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(2)}`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    width: 540,
  },

  [`& .${classes.textArea}`]: {
    color: theme.palette.text.primary,
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    width: 508,
    maxWidth: 508,
  },

  [`& .${classes.textAreaError}`]: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  [`& .${classes.commonLastActionAnnotationsFieldWidth}`]: {
    width: 300,
  },

  // common
  [`& .${classes.phaseHeaderCardRoot}`]: {
    marginTop: -3,
    zIndex: 1000,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    columnGap: theme.spacing(1),
  },

  [`& .${classes.switchLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
    border: `solid 1px ${theme.palette.divider}`,
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.actionLayout}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.runningStateText}`]: {
    color: theme.palette.success.main,
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.runningStateStoppedText}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.autoRefreshSwitchLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    border: `solid 1px ${theme.palette.divider}`,
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.copyPasteIcon}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },

  [`& .${classes.idTextField}`]: {
    width: 270,
    margin: 0,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
  },

  // no data splash
  [`& .${classes.noDataSplashCardContent}`]: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.noDataSplashBody}`]: {
    display: "grid",
    gridTemplateColumns: "auto",
    justifyItems: "center",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.noDataSplashIcon}`]: {
    fontSize: 110,
    color: alpha(theme.palette.background.default, 0.5),
  },

  [`& .${classes.noDataSlashErrorIcon}`]: {
    color: theme.palette.error.light,
  },

  [`& .${classes.splashRow}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
}));

enum ValidationPhaseAction {
  Stop = "Stop",
  Start = "Start",
}

export enum TableMode {
  problems = "Show Problems",
  everything = "Show Everything",
}

const reasonsToShowTransactionsInValidationPhaseProblems = [
  "they haven't entered the commit phase 5s after being accepted",
  "they have dropped out of the commit phase without reaching Completed or Rolled Back",
];

const commonReasonsToStopTheValidationPhase = [
  "start: deployment of appX @ vX.X.X",
  "complete: deployment of appX @ vX.X.X",
  "start: clean up of fallout related to transaction xxx failure",
  "complete: clean up of fallout related to transaction xxx failure",
];

const reasonsToShowTransactionsInCommitPhaseProblems = [
  "they have run 2 or more times - i.e. they did not succeed on the first run",
];

const reasonsToShowAfterEffectsInAfterEffectPhaseProblems = [
  "they have run 2 or more times - i.e. they did not succeed on the first run",
];

export function Dashboard() {
  const { errorContextErrorTranslator } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const { viewConfiguration, authContext } = useApplicationContext();
  const allTablesTheSameHeight = (window.innerHeight - 150) / 3;
  const dashboardViewConfiguration = viewConfiguration.Consistency
    ? viewConfiguration.Consistency
      ? viewConfiguration.Consistency.Consistency
      : {}
    : {};

  // ---------- validation phase ----------
  const {
    getValidationPhaseResponse,
    reload: reloadValidationPhase,
    loading: validationPhaseLoading,
    error: getValidationPhaseError,
  } = useGetValidationPhase();
  const [validationPhaseAutoRefresh, setValidationPhaseAutoRefresh] =
    useState(false);
  const validationGetInterval = useRef<NodeJS.Timer | undefined>(undefined);
  useEffect(() => {
    if (validationPhaseAutoRefresh) {
      validationGetInterval.current = setInterval(reloadValidationPhase, 1500);
    } else {
      clearInterval(validationGetInterval.current);
    }
  }, [validationPhaseAutoRefresh, reloadValidationPhase]);
  const [validationPhaseTableMode, setValidationPhaseTableMode] = useState(
    TableMode.problems,
  );
  const [validationPhaseAPILoading, setValidationPhaseAPILoading] =
    useState<boolean>(false);
  const [validationPhaseAction, setValidationPhaseAction] = useState<
    ValidationPhaseAction | undefined
  >(undefined);
  const [lastActionAnnotation, setLastActionAnnotation] = useState("");
  const [annotationBlankError, setAnnotationBlankError] = useState(false);
  const handleSinoatrialTick = async () => {
    setValidationPhaseAPILoading(true);
    try {
      await Sinoatrial.HeartbeatTick({
        context: authContext,
      });
      reloadValidationPhase();
      enqueueSnackbar("Tick Success", { variant: "success" });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `tick error: ${err.message ? err.message : err.toString()}`,
      );
      enqueueSnackbar(
        `Tick Error: ${err.message ? err.message : err.toString()}`,
        {
          variant: "error",
        },
      );
    }
    setValidationPhaseAPILoading(false);
  };
  const handleValidationPhaseAction = async () => {
    setValidationPhaseAPILoading(true);
    switch (validationPhaseAction) {
      case ValidationPhaseAction.Start:
        try {
          await ValidationPhaseController.StartValidation({
            context: authContext,
            lastActionAnnotation,
          });
          reloadValidationPhase();
          enqueueSnackbar("Start Success", { variant: "success" });
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `start error: ${err.message ? err.message : err.toString()}`,
          );
          enqueueSnackbar(
            `Start Error: ${err.message ? err.message : err.toString()}`,
            { variant: "error" },
          );
        }
        setValidationPhaseAPILoading(false);
        return;

      case ValidationPhaseAction.Stop:
        try {
          await ValidationPhaseController.StopValidation({
            context: authContext,
            lastActionAnnotation,
          });
          reloadValidationPhase();
          enqueueSnackbar("Stop Success", { variant: "success" });
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `stop error: ${err.message ? err.message : err.toString()}`,
          );
          enqueueSnackbar(
            `Stop Error: ${err.message ? err.message : err.toString()}`,
            { variant: "error" },
          );
        }
        setValidationPhaseAPILoading(false);
        return;

      default:
        console.error("validation phase action is not set");
    }
    setLastActionAnnotation("");
    setValidationPhaseAction(undefined);
    setValidationPhaseAPILoading(false);
  };

  // ---------- commit phase ----------
  const {
    getCommitPhaseResponse,
    reload: reloadCommitPhase,
    loading: commitPhaseLoading,
    error: getCommitPhaseError,
  } = useGetCommitPhase();
  const [commitPhaseAutoRefresh, setCommitPhaseAutoRefresh] = useState(false);
  const commitGetInterval = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (commitPhaseAutoRefresh) {
      commitGetInterval.current = setInterval(reloadCommitPhase, 1500);
    } else {
      clearInterval(commitGetInterval.current);
    }
  }, [commitPhaseAutoRefresh, reloadCommitPhase]);
  const [transactionsInCommitPhaseIdx, setTransactionsInCommitPhaseIdx] =
    useState<{ [key: string]: boolean }>({});
  useEffect(() => {
    setTransactionsInCommitPhaseIdx((currentIdx) => {
      const newIdx: { [key: string]: boolean } = {};
      let missingValues = false;
      getCommitPhaseResponse.commitPhase.runningTransactions.forEach((rt) => {
        missingValues = currentIdx[rt.transaction.id];
        newIdx[rt.transaction.id] = true;
      });
      return missingValues ||
        Object.keys(currentIdx).length !== Object.keys(newIdx).length
        ? newIdx
        : currentIdx;
    });
  }, [getCommitPhaseResponse.commitPhase.runningTransactions]);
  const [commitPhaseTableMode, setCommitPhaseTableMode] = useState(
    TableMode.problems,
  );

  // ---------- after effect phase ----------
  const {
    getAfterEffectPhaseResponse,
    reload: reloadAfterEffectPhase,
    loading: afterEffectPhaseLoading,
    error: getAfterEffectPhaseError,
  } = useGetAfterEffectPhase();
  const [afterEffectPhaseAutoRefresh, setAfterEffectPhaseAutoRefresh] =
    useState(false);
  const afterEffectGetInterval = useRef<NodeJS.Timer | undefined>(undefined);
  useEffect(() => {
    if (afterEffectPhaseAutoRefresh) {
      afterEffectGetInterval.current = setInterval(
        reloadAfterEffectPhase,
        1500,
      );
    } else {
      clearInterval(afterEffectGetInterval.current);
    }
  }, [afterEffectPhaseAutoRefresh, reloadAfterEffectPhase]);
  const [afterEffectPhaseTableMode, setAfterEffectPhaseTableMode] = useState(
    TableMode.problems,
  );

  // -------- data filtering functions -----------
  const validationPhaseTransactions = useMemo<ConsistencyTransaction[]>(() => {
    if (getValidationPhaseError) {
      return [];
    }

    if (validationPhaseTableMode === TableMode.everything) {
      return getValidationPhaseResponse.validationPhase.acceptedTransactions;
    }
    const transactions: ConsistencyTransaction[] = [];
    getValidationPhaseResponse.validationPhase.acceptedTransactions.forEach(
      (t) => {
        // only transactions that are Accepted may constitute a problem
        if (t.state !== TransactionState.Accepted) {
          return;
        }

        // transactions that are in the commit phase should be considered there and cannot
        // constitute a problem in the validation phase.
        if (transactionsInCommitPhaseIdx[t.id]) {
          return;
        }

        // if transaction is 'write operation pending' and it was last modified
        // less than 5s ago then it should not be considered as a potential problem
        // yet since it is most likely that the transaction has just been
        // accepted and has not yet entered the commit phase.
        const [writeOperationsState] = t.writeOperationsState();
        if (
          writeOperationsState === WriteOperationState.Pending &&
          dayjs().subtract(5, "s").isBefore(dayjs(t.auditEntry.time))
        ) {
          return;
        }

        // If write operation state is either rolled back or complete then the transaction
        // is not problematic. It is still in memory for validation.
        if (
          writeOperationsState === WriteOperationState.RolledBack ||
          writeOperationsState === WriteOperationState.Complete
        ) {
          return;
        }

        // all other transactions may constitute a problem
        transactions.push(t);
      },
    );

    return transactions;
  }, [
    getValidationPhaseError,
    validationPhaseTableMode,
    getValidationPhaseResponse.validationPhase.acceptedTransactions,
    transactionsInCommitPhaseIdx,
  ]);
  const commitPhaseRunningTransactions = useMemo<RunningTransaction[]>(() => {
    if (getCommitPhaseError) {
      return [];
    }

    if (commitPhaseTableMode === TableMode.everything) {
      return getCommitPhaseResponse.commitPhase.runningTransactions;
    }
    const runningTransactions: RunningTransaction[] = [];
    getCommitPhaseResponse.commitPhase.runningTransactions.forEach((t) => {
      // if transaction has run less than 2 times they do not constitute a problem
      if (t.runCount < 2) {
        return;
      }
      runningTransactions.push(t);
    });

    return runningTransactions;
  }, [
    getCommitPhaseError,
    commitPhaseTableMode,
    getCommitPhaseResponse.commitPhase.runningTransactions,
  ]);
  const afterEffectPhaseRunningAfterEffects = useMemo<
    RunningAfterEffect[]
  >(() => {
    if (getAfterEffectPhaseError) {
      return [];
    }

    if (afterEffectPhaseTableMode === TableMode.everything) {
      return getAfterEffectPhaseResponse.afterEffectPhase.runningAfterEffects;
    }
    const runningAfterEffects: RunningAfterEffect[] = [];
    getAfterEffectPhaseResponse.afterEffectPhase.runningAfterEffects.forEach(
      (t) => {
        // if afterEffect has run less than 2 times they do not constitute a problem
        if (
          Object.keys(t.actionsRun).reduce(
            (prevValue, currentValue) => prevValue + t.actionsRun[currentValue],
            0,
          ) < 2
        ) {
          return;
        }

        runningAfterEffects.push(t);
      },
    );

    return runningAfterEffects;
  }, [
    getAfterEffectPhaseError,
    afterEffectPhaseTableMode,
    getAfterEffectPhaseResponse.afterEffectPhase.runningAfterEffects,
  ]);

  return (
    <StyledRoot className={classes.root}>
      {/* ---------- validation phase ---------- */}
      <FETable
        disableSelect
        initialDenseTable
        height={allTablesTheSameHeight}
        title={
          <div className={classes.phaseHeaderCardRoot}>
            <Grid container direction="row" spacing={1} alignItems="center">
              {[
                <Typography variant="subtitle1" children="Validation" />,
                <Typography
                  variant="subtitle1"
                  className={cx(classes.runningStateText, {
                    [classes.runningStateStoppedText]:
                      !getValidationPhaseResponse.validationPhase.running,
                  })}
                  children={
                    getValidationPhaseResponse.validationPhase.running
                      ? "Running"
                      : "Stopped"
                  }
                />,
                <MultiRowInfoIcon
                  invertColors
                  title={
                    getValidationPhaseResponse.validationPhase
                      .lastActionAnnotation
                  }
                />,
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  children="since"
                />,
                <Typography
                  variant="subtitle2"
                  children={dayjs(
                    getValidationPhaseResponse.validationPhase.auditEntry.time,
                  ).format("DD/MM/YYYY HH:mm:ss")}
                />,
                <FancyTableModeButton
                  tableMode={validationPhaseTableMode}
                  onTableModeChange={(newTableMode) =>
                    setValidationPhaseTableMode(newTableMode)
                  }
                  idPostFix="validationPhase"
                  buttonInfoEndAdornment={
                    validationPhaseTableMode === TableMode.problems ? (
                      <MultiRowInfoIcon
                        title="Transactions will show here if:"
                        listRows={
                          reasonsToShowTransactionsInValidationPhaseProblems
                        }
                      />
                    ) : (
                      <MultiRowInfoIcon title="Every transaction in the validation phase accepted transaction cache will show here" />
                    )
                  }
                  onDoubleClickArrowBtn={() => {
                    if (validationPhaseTableMode === TableMode.problems) {
                      setValidationPhaseTableMode(TableMode.everything);
                      setCommitPhaseTableMode(TableMode.everything);
                      setAfterEffectPhaseTableMode(TableMode.everything);
                    } else {
                      setValidationPhaseTableMode(TableMode.problems);
                      setCommitPhaseTableMode(TableMode.problems);
                      setAfterEffectPhaseTableMode(TableMode.problems);
                    }
                  }}
                />,
                validationPhaseTableMode === TableMode.everything ? (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    children={`${validationPhaseTransactions.length} transaction(s) in this phase`}
                  />
                ) : validationPhaseTransactions.length ? (
                  <Typography color="textSecondary" variant="subtitle2">
                    {validationPhaseTransactions.length} potentially problematic
                    transactions
                  </Typography>
                ) : (
                  <div />
                ),
                validationPhaseAPILoading || validationPhaseLoading ? (
                  <CircularProgress size={10} />
                ) : (
                  <div />
                ),
                validationPhaseTransactions.length ? (
                  <Tooltip placement="top" title="Open all in new">
                    <IconButton
                      size="small"
                      onClick={() => {
                        validationPhaseTransactions.forEach((txn) => {
                          window.open(
                            `${
                              window.location.origin
                            }/consistency/transactions/view?id=${txn.id}`,
                            "_blank",
                          );
                        });
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <div />
                ),
              ].map((item, idx) => (
                <Grid item key={idx}>
                  {item}
                </Grid>
              ))}
            </Grid>
            <Grid container direction="row" spacing={1} alignItems="center">
              {(() => {
                const actionButtons: ReactNode[] = [];

                if (dashboardViewConfiguration.Control) {
                  actionButtons.push(
                    <Button
                      variant="contained"
                      color="secondary"
                      children="Tick"
                      disabled={validationPhaseAPILoading}
                      onClick={handleSinoatrialTick}
                      endIcon={
                        <MultiRowInfoIcon title="Publish a heartbeat event to tick the validation phase" />
                      }
                    />,
                  );
                }

                if (dashboardViewConfiguration.Control) {
                  if (getValidationPhaseResponse.validationPhase.running) {
                    actionButtons.push(
                      <Button
                        variant="contained"
                        color="primary"
                        children="Stop"
                        onClick={() =>
                          setValidationPhaseAction(ValidationPhaseAction.Stop)
                        }
                        disabled={validationPhaseAPILoading}
                        endIcon={
                          <MultiRowInfoIcon title="Stop the validation phase. Prevents the initiation or submission of transactions via horizon." />
                        }
                      />,
                    );
                  } else {
                    actionButtons.push(
                      <Button
                        variant="contained"
                        color="primary"
                        children="Start"
                        disabled={validationPhaseAPILoading}
                        onClick={() =>
                          setValidationPhaseAction(ValidationPhaseAction.Start)
                        }
                        endIcon={
                          <MultiRowInfoIcon title="Start the validation phase." />
                        }
                      />,
                    );
                  }
                }

                actionButtons.push(
                  <div className={classes.autoRefreshSwitchLayout}>
                    <Switch
                      color="primary"
                      checked={validationPhaseAutoRefresh}
                      onChange={() =>
                        setValidationPhaseAutoRefresh(
                          !validationPhaseAutoRefresh,
                        )
                      }
                    />
                    <Typography
                      children="Stream"
                      variant="body2"
                      onDoubleClick={() => {
                        if (validationPhaseAutoRefresh) {
                          setValidationPhaseAutoRefresh(false);
                          setCommitPhaseAutoRefresh(false);
                          setAfterEffectPhaseAutoRefresh(false);
                        } else {
                          setValidationPhaseAutoRefresh(true);
                          setCommitPhaseAutoRefresh(true);
                          setAfterEffectPhaseAutoRefresh(true);
                        }
                      }}
                    />
                  </div>,
                );

                if (!validationPhaseAutoRefresh) {
                  actionButtons.push(
                    <Tooltip title="Reload">
                      <span>
                        <IconButton
                          disabled={
                            validationPhaseAPILoading || validationPhaseLoading
                          }
                          size="small"
                          onClick={reloadValidationPhase}
                        >
                          <ReloadIcon />
                        </IconButton>
                      </span>
                    </Tooltip>,
                  );
                }

                return actionButtons;
              })().map((n, idx) => (
                <Grid item key={idx}>
                  {n}
                </Grid>
              ))}
            </Grid>
          </div>
        }
        data={validationPhaseTransactions}
        noDataSplashComponent={
          getValidationPhaseError ? (
            <div className={classes.noDataSplashCardContent}>
              <div className={classes.noDataSplashBody}>
                <ErrorIcon
                  className={cx(
                    classes.noDataSplashIcon,
                    classes.noDataSlashErrorIcon,
                  )}
                />
                <Typography
                  color="error"
                  variant="h4"
                  children="Error retrieving Validation Phase"
                />
                <Typography
                  variant="body2"
                  children={getValidationPhaseError}
                />
              </div>
            </div>
          ) : validationPhaseTableMode === TableMode.problems ? (
            <div className={classes.noDataSplashCardContent}>
              <div className={classes.noDataSplashBody}>
                <AllGoodIcon className={classes.noDataSplashIcon} />
                <Typography
                  color="textSecondary"
                  variant="h4"
                  children="All Good"
                />
                <div className={classes.splashRow}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    children="Transactions will show here if there is an issue"
                  />
                  <MultiRowInfoIcon
                    invertColors
                    title="Transactions will show here if:"
                    listRows={
                      reasonsToShowTransactionsInValidationPhaseProblems
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.noDataSplashCardContent}>
              <div className={classes.noDataSplashBody}>
                <FaceIcon className={classes.noDataSplashIcon} />
                <Typography
                  color="secondary"
                  variant="h4"
                  children="Nothing to see here"
                />
                <Typography
                  variant="body2"
                  children="You will see the list when there are transactions in this phase"
                />
              </div>
            </div>
          )
        }
        columns={[
          {
            field: "",
            label: "",
            minWidth: 40,
            accessor: (data) => (
              <Tooltip
                placement="top"
                title="View transaction detail in new tab"
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    window.open(
                      `${
                        window.location.origin
                      }/consistency/transactions/view?id=${
                        (data as ConsistencyTransaction).id
                      }`,
                      "_blank",
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: "id",
            label: "ID",
            minWidth: 280,
            accessor: (data) => {
              const txn = data as ConsistencyTransaction;
              return (
                <div className={classes.row}>
                  <Typography variant="body1" children={txn.id} />
                  <CopyPasteIcon
                    className={classes.copyPasteIcon}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(txn.id)
                        .then(() => enqueueSnackbar("Transaction ID copied"))
                    }
                  />
                </div>
              );
            },
          },
          {
            field: "state",
            label: "State",
            minWidth: 140,
            accessor: (data) => {
              const txn = data as ConsistencyTransaction;
              return (
                <div className={classes.row}>
                  <Typography variant="body1" children={txn.state} />
                  <MultiRowInfoIcon
                    invertColors
                    title={txn.lastActionAnnotation}
                  />
                </div>
              );
            },
          },
          {
            field: "auditEntry.time",
            label: "Last Modified",
            minWidth: 180,
            accessor: (data) =>
              dayjs((data as ConsistencyTransaction).auditEntry.time).format(
                "DD/MM/YYYY HH:mm:ss",
              ),
          },
          {
            field: "writeOperationState",
            label: "Write Op State",
            minWidth: 140,
            accessor: (data) => {
              const txn = data as ConsistencyTransaction;
              const [writeOperationState, lastModified] =
                txn.writeOperationsState();
              return `${writeOperationState} @ ${lastModified.format(
                "DD/MM/YYYY HH:mm:ss",
              )}`;
            },
          },
          {
            field: "originatingProcess",
            label: "Process",
            minWidth: 400,
            accessor: (data) => {
              let processed = "";
              for (const c of (data as ConsistencyTransaction)
                .originatingProcess) {
                processed += c;
                if (c === "/") {
                  processed += " ";
                }
              }

              return processed;
            },
          },
          {
            field: "originatingLocation",
            label: "Originating Location",
            minWidth: 400,
            accessor: (data) => {
              let processed = "";
              for (const c of (data as ConsistencyTransaction)
                .originatingLocation) {
                processed += c;
                if (c === "/") {
                  processed += " ";
                }
              }

              return processed;
            },
          },
        ]}
      />

      {validationPhaseAction && (
        <Dialog
          open
          maxWidth="lg"
          onClose={() => {
            setValidationPhaseAction(undefined);
            setLastActionAnnotation("");
          }}
        >
          <DialogTitle
            classes={{
              root: classes.lastActionAnnotationDialogTitleRootOverride,
            }}
          >
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <NoteIcon />
              </Grid>
              <Grid item>
                <Typography variant="h5" children="Action Annotation" />
              </Grid>
              {(validationPhaseAPILoading || validationPhaseLoading) && (
                <Grid item>
                  <CircularProgress size={20} />
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  children={
                    validationPhaseAction === ValidationPhaseAction.Start
                      ? "Start"
                      : "Stop"
                  }
                  onClick={() =>
                    handleValidationPhaseAction().then(() => {
                      setValidationPhaseAction(undefined);
                      setLastActionAnnotation("");
                    })
                  }
                />
              </Grid>
              <Grid item>
                <Tooltip title="Close" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setValidationPhaseAction(undefined);
                      setLastActionAnnotation("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogContentRootOverride}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography color="textSecondary">
                  Provide a note around why &apos;
                  {validationPhaseAction === ValidationPhaseAction.Start
                    ? "Validation Start"
                    : "Validation Stop"}
                  &apos; is being performed.
                </Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option === value}
                  id="consistency-lastActionAnnotationCommonReasons-autoComplete"
                  className={classes.commonLastActionAnnotationsFieldWidth}
                  options={commonReasonsToStopTheValidationPhase}
                  disabled={validationPhaseAPILoading}
                  onChange={(a, reason: string | null) =>
                    reason ? setLastActionAnnotation(reason) : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={validationPhaseAPILoading}
                      id="consistency-lastActionAnnotationCommonReasons-autocompleteTextField"
                      InputLabelProps={{ shrink: true }}
                      label="Common Reasons"
                      placeholder="Select one to pre-populate note..."
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <TextareaAutosize
                  minRows={9}
                  maxRows={9}
                  value={lastActionAnnotation}
                  onChange={(e) => {
                    setAnnotationBlankError(false);
                    let newValue: string = e.target.value;
                    if (newValue.length >= 250) {
                      newValue = newValue.slice(0, 250);
                    }
                    setLastActionAnnotation(newValue);
                  }}
                  placeholder="Note"
                  className={cx(classes.textArea, {
                    [classes.textAreaError]: annotationBlankError,
                  })}
                />
                {annotationBlankError ? (
                  <FormHelperText error>add some text</FormHelperText>
                ) : (
                  <FormHelperText>
                    {250 - lastActionAnnotation.length} Characters Left
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}

      {/* ---------- commit phase ---------- */}
      <FETable
        disableSelect
        initialDenseTable
        height={allTablesTheSameHeight}
        title={
          <div className={classes.phaseHeaderCardRoot}>
            <Grid container direction="row" spacing={1} alignItems="center">
              {[
                <Typography variant="subtitle1" children="Commit" />,
                <FancyTableModeButton
                  tableMode={commitPhaseTableMode}
                  onTableModeChange={(newTableMode) =>
                    setCommitPhaseTableMode(newTableMode)
                  }
                  idPostFix="commitPhase"
                  buttonInfoEndAdornment={
                    commitPhaseTableMode === TableMode.problems ? (
                      <MultiRowInfoIcon
                        title="Running transactions will show here if:"
                        listRows={
                          reasonsToShowTransactionsInCommitPhaseProblems
                        }
                      />
                    ) : (
                      <MultiRowInfoIcon title="Every running transaction in the commit phase will show here" />
                    )
                  }
                  onDoubleClickArrowBtn={() => {
                    if (commitPhaseTableMode === TableMode.problems) {
                      setValidationPhaseTableMode(TableMode.everything);
                      setCommitPhaseTableMode(TableMode.everything);
                      setAfterEffectPhaseTableMode(TableMode.everything);
                    } else {
                      setValidationPhaseTableMode(TableMode.problems);
                      setCommitPhaseTableMode(TableMode.problems);
                      setAfterEffectPhaseTableMode(TableMode.problems);
                    }
                  }}
                />,
                commitPhaseTableMode === TableMode.everything ? (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    children={`${commitPhaseRunningTransactions.length} running transaction(s) in this phase`}
                  />
                ) : commitPhaseRunningTransactions.length ? (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    children={`${commitPhaseRunningTransactions.length} potentially problematic running transactions`}
                  />
                ) : (
                  <div />
                ),
                commitPhaseLoading ? <CircularProgress size={10} /> : <div />,
              ].map((item, idx) => (
                <Grid item key={idx}>
                  {item}
                </Grid>
              ))}
            </Grid>
            <Grid container direction="row" spacing={1} alignItems="center">
              {(() => {
                const actionButtons: ReactNode[] = [];

                actionButtons.push(
                  <div className={classes.autoRefreshSwitchLayout}>
                    <Switch
                      color="primary"
                      checked={commitPhaseAutoRefresh}
                      onChange={() =>
                        setCommitPhaseAutoRefresh(!commitPhaseAutoRefresh)
                      }
                    />
                    <Typography
                      children="Stream"
                      variant="body2"
                      onDoubleClick={() => {
                        if (commitPhaseAutoRefresh) {
                          setValidationPhaseAutoRefresh(false);
                          setCommitPhaseAutoRefresh(false);
                          setAfterEffectPhaseAutoRefresh(false);
                        } else {
                          setValidationPhaseAutoRefresh(true);
                          setCommitPhaseAutoRefresh(true);
                          setAfterEffectPhaseAutoRefresh(true);
                        }
                      }}
                    />
                  </div>,
                );

                if (!commitPhaseAutoRefresh) {
                  actionButtons.push(
                    <Tooltip title="Reload">
                      <span>
                        <IconButton
                          disabled={commitPhaseLoading}
                          size="small"
                          onClick={reloadCommitPhase}
                        >
                          <ReloadIcon />
                        </IconButton>
                      </span>
                    </Tooltip>,
                  );
                }

                return actionButtons;
              })().map((n, idx) => (
                <Grid item key={idx}>
                  {n}
                </Grid>
              ))}
            </Grid>
          </div>
        }
        data={commitPhaseRunningTransactions}
        noDataSplashComponent={
          getCommitPhaseError ? (
            <div className={classes.noDataSplashCardContent}>
              <div className={classes.noDataSplashBody}>
                <ErrorIcon
                  className={cx(
                    classes.noDataSplashIcon,
                    classes.noDataSlashErrorIcon,
                  )}
                />
                <Typography
                  color="error"
                  variant="h4"
                  children="Error retrieving Commit Phase"
                />
                <Typography variant="body2" children={getCommitPhaseError} />
              </div>
            </div>
          ) : commitPhaseTableMode === TableMode.problems ? (
            <div className={classes.noDataSplashCardContent}>
              <div className={classes.noDataSplashBody}>
                <AllGoodIcon className={classes.noDataSplashIcon} />
                <Typography
                  color="textSecondary"
                  variant="h4"
                  children="All Good"
                />
                <div className={classes.splashRow}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    children="Running transactions will show here if their state indicates that there may be an issue"
                  />
                  <MultiRowInfoIcon
                    invertColors
                    title="Running transactions will show here if:"
                    listRows={reasonsToShowTransactionsInCommitPhaseProblems}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.noDataSplashCardContent}>
              <div className={classes.noDataSplashBody}>
                <FaceIcon className={classes.noDataSplashIcon} />
                <Typography
                  color="secondary"
                  variant="h4"
                  children="Nothing to see here"
                />
                <Typography
                  variant="body2"
                  children="You will see the list when there are running transactions in this phase"
                />
              </div>
            </div>
          )
        }
        columns={[
          {
            field: "",
            label: "",
            minWidth: 40,
            accessor: (data) => (
              <Tooltip
                placement="top"
                title="View transaction detail in new tab"
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    window.open(
                      `${
                        window.location.origin
                      }/consistency/transactions/view?id=${
                        (data as RunningTransaction).transaction.id
                      }`,
                      "_blank",
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: "id",
            label: "ID",
            minWidth: 280,
            accessor: (data) => {
              const txn = data as RunningTransaction;
              return (
                <div className={classes.row}>
                  <Typography variant="body1" children={txn.transaction.id} />
                  <CopyPasteIcon
                    className={classes.copyPasteIcon}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(txn.transaction.id)
                        .then(() => enqueueSnackbar("Transaction ID copied"))
                    }
                  />
                </div>
              );
            },
          },
          {
            field: "numRuns",
            label: "Runs",
            minWidth: 40,
            accessor: (data) => {
              const txn = data as RunningTransaction;
              return txn.runCount;
            },
          },
          {
            field: "state",
            label: "State",
            minWidth: 140,
            accessor: (data) => {
              const txn = data as RunningTransaction;
              return (
                <div className={classes.row}>
                  <Typography
                    variant="body1"
                    children={txn.transaction.state}
                  />
                  <MultiRowInfoIcon
                    invertColors
                    title={txn.transaction.lastActionAnnotation}
                  />
                </div>
              );
            },
          },
          {
            field: "auditEntry.time",
            label: "Last Modified",
            minWidth: 180,
            accessor: (data) => {
              const txn = data as RunningTransaction;
              const [, lastModified] = txn.transaction.writeOperationsState();
              return lastModified.format("DD/MM/YYYY HH:mm:ss");
            },
          },
          {
            field: "writeOperationState",
            label: "Write Op State",
            minWidth: 140,
            accessor: (data) => {
              const txn = data as RunningTransaction;
              const [writeOperationState, lastModified] =
                txn.transaction.writeOperationsState();
              return `${writeOperationState} @ ${lastModified.format(
                "DD/MM/YYYY HH:mm:ss",
              )}`;
            },
          },
          {
            field: "originatingProcess",
            label: "Process",
            minWidth: 400,
            accessor: (data) => {
              let processed = "";
              for (const c of (data as RunningTransaction).transaction
                .originatingProcess) {
                processed += c;
                if (c === "/") {
                  processed += " ";
                }
              }

              return processed;
            },
          },
          {
            field: "originatingLocation",
            label: "Originating Location",
            minWidth: 400,
            accessor: (data) => {
              let processed = "";
              for (const c of (data as RunningTransaction).transaction
                .originatingLocation) {
                processed += c;
                if (c === "/") {
                  processed += " ";
                }
              }

              return processed;
            },
          },
        ]}
      />

      {/* ---------- after effect phase ---------- */}
      <FETable
        disableSelect
        initialDenseTable
        height={allTablesTheSameHeight}
        title={
          <div className={classes.phaseHeaderCardRoot}>
            <Grid container direction="row" spacing={1} alignItems="center">
              {[
                <Typography variant="subtitle1" children="After Effect" />,
                <FancyTableModeButton
                  tableMode={afterEffectPhaseTableMode}
                  onTableModeChange={(newTableMode) =>
                    setAfterEffectPhaseTableMode(newTableMode)
                  }
                  idPostFix="afterEffectPhase"
                  buttonInfoEndAdornment={
                    afterEffectPhaseTableMode === TableMode.problems ? (
                      <MultiRowInfoIcon
                        title="Running after effects will show here if:"
                        listRows={
                          reasonsToShowAfterEffectsInAfterEffectPhaseProblems
                        }
                      />
                    ) : (
                      <MultiRowInfoIcon title="Every running after effect in the after effect phase will show here" />
                    )
                  }
                  onDoubleClickArrowBtn={() => {
                    if (afterEffectPhaseTableMode === TableMode.problems) {
                      setValidationPhaseTableMode(TableMode.everything);
                      setCommitPhaseTableMode(TableMode.everything);
                      setAfterEffectPhaseTableMode(TableMode.everything);
                    } else {
                      setValidationPhaseTableMode(TableMode.problems);
                      setCommitPhaseTableMode(TableMode.problems);
                      setAfterEffectPhaseTableMode(TableMode.problems);
                    }
                  }}
                />,
                afterEffectPhaseTableMode === TableMode.everything ? (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    children={`${afterEffectPhaseRunningAfterEffects.length} running after effect(s) in this phase`}
                  />
                ) : afterEffectPhaseRunningAfterEffects.length ? (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    children={`${afterEffectPhaseRunningAfterEffects.length} potentially problematic running after effects`}
                  />
                ) : (
                  <div />
                ),
                afterEffectPhaseLoading ? (
                  <CircularProgress size={10} />
                ) : (
                  <div />
                ),
              ].map((item, idx) => (
                <Grid item key={idx}>
                  {item}
                </Grid>
              ))}
            </Grid>
            <Grid container direction="row" spacing={1} alignItems="center">
              {(() => {
                const actionButtons: ReactNode[] = [];

                actionButtons.push(
                  <div className={classes.autoRefreshSwitchLayout}>
                    <Switch
                      color="primary"
                      checked={afterEffectPhaseAutoRefresh}
                      onChange={() =>
                        setAfterEffectPhaseAutoRefresh(
                          !afterEffectPhaseAutoRefresh,
                        )
                      }
                    />
                    <Typography
                      children="Stream"
                      variant="body2"
                      onDoubleClick={() => {
                        if (afterEffectPhaseAutoRefresh) {
                          setValidationPhaseAutoRefresh(false);
                          setCommitPhaseAutoRefresh(false);
                          setAfterEffectPhaseAutoRefresh(false);
                        } else {
                          setValidationPhaseAutoRefresh(true);
                          setCommitPhaseAutoRefresh(true);
                          setAfterEffectPhaseAutoRefresh(true);
                        }
                      }}
                    />
                  </div>,
                );

                if (!afterEffectPhaseAutoRefresh) {
                  actionButtons.push(
                    <Tooltip title="Reload">
                      <span>
                        <IconButton
                          disabled={afterEffectPhaseLoading}
                          size="small"
                          onClick={reloadAfterEffectPhase}
                        >
                          <ReloadIcon />
                        </IconButton>
                      </span>
                    </Tooltip>,
                  );
                }

                return actionButtons;
              })().map((n, idx) => (
                <Grid item key={idx}>
                  {n}
                </Grid>
              ))}
            </Grid>
          </div>
        }
        data={afterEffectPhaseRunningAfterEffects}
        noDataSplashComponent={
          getAfterEffectPhaseError ? (
            <div className={classes.noDataSplashCardContent}>
              <div className={classes.noDataSplashBody}>
                <ErrorIcon
                  className={cx(
                    classes.noDataSplashIcon,
                    classes.noDataSlashErrorIcon,
                  )}
                />
                <Typography
                  color="error"
                  variant="h4"
                  children="Error retrieving After Effect Phase"
                />
                <Typography
                  variant="body2"
                  children={getAfterEffectPhaseError}
                />
              </div>
            </div>
          ) : afterEffectPhaseTableMode === TableMode.problems ? (
            <div className={classes.noDataSplashCardContent}>
              <div className={classes.noDataSplashBody}>
                <AllGoodIcon className={classes.noDataSplashIcon} />
                <Typography
                  color="textSecondary"
                  variant="h4"
                  children="All Good"
                />
                <div className={classes.splashRow}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    children="Running after effects will show here if their state indicates that there may be an issue"
                  />
                  <MultiRowInfoIcon
                    invertColors
                    title="Running after effects will show here if:"
                    listRows={
                      reasonsToShowAfterEffectsInAfterEffectPhaseProblems
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.noDataSplashCardContent}>
              <div className={classes.noDataSplashBody}>
                <FaceIcon className={classes.noDataSplashIcon} />
                <Typography
                  color="secondary"
                  variant="h4"
                  children="Nothing to see here"
                />
                <Typography
                  variant="body2"
                  children="You will see the list when there are running after effects in this phase"
                />
              </div>
            </div>
          )
        }
        columns={[
          {
            field: "",
            label: "",
            minWidth: 40,
            accessor: (data) => (
              <Tooltip
                placement="top"
                title="View after effect in associated transaction detail in new tab"
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    window.open(
                      `${
                        window.location.origin
                      }/consistency/transactions/view?id=${
                        (data as RunningAfterEffect).afterEffect.transactionID
                      }`,
                      "_blank",
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: "id",
            label: "ID",
            minWidth: 280,
            accessor: (data) => {
              const txn = data as RunningAfterEffect;
              return (
                <div className={classes.row}>
                  <Typography variant="body1" children={txn.afterEffect.id} />
                  <CopyPasteIcon
                    className={classes.copyPasteIcon}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(txn.afterEffect.id)
                        .then(() => enqueueSnackbar("After Effect ID copied"))
                    }
                  />
                </div>
              );
            },
          },
          {
            field: "numRuns",
            label: "Runs",
            minWidth: 40,
            accessor: (data) => {
              const txn = data as RunningAfterEffect;
              return Object.keys(txn.actionsRun).reduce(
                (prevValue, currentValue) =>
                  prevValue + txn.actionsRun[currentValue],
                0,
              );
            },
          },
          {
            field: "state",
            label: "State",
            minWidth: 140,
            accessor: (data) => {
              const txn = data as RunningAfterEffect;
              return (
                <div className={classes.row}>
                  <Typography
                    variant="body1"
                    children={txn.afterEffect.state}
                  />
                  <MultiRowInfoIcon
                    invertColors
                    title={txn.afterEffect.lastActionAnnotation}
                  />
                </div>
              );
            },
          },
          {
            field: "auditEntry.time",
            label: "Last Modified",
            minWidth: 180,
            accessor: (data) => {
              const runningAfterEffect = data as RunningAfterEffect;
              return dayjs(
                runningAfterEffect.afterEffect.auditEntry.time,
              ).format("DD/MM/YYYY HH:mm:ss");
            },
          },
        ]}
      />
    </StyledRoot>
  );
}

interface FancyTableModeButtonProps {
  tableMode: TableMode;
  onTableModeChange: (newTableMode: TableMode) => void;
  idPostFix: string;
  buttonInfoEndAdornment: ReactNode;
  onDoubleClickArrowBtn?: () => void;
}

const allTableModes: TableMode[] = [TableMode.problems, TableMode.everything];

function FancyTableModeButton(props: FancyTableModeButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <ButtonGroup
          variant="contained"
          color="secondary"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            color="secondary"
            id={`consistencyDashboardTable-changeTableMode-selectedModeButton-${props.idPostFix}`}
            children={props.tableMode}
            endIcon={props.buttonInfoEndAdornment}
          />
          <Button
            id={`consistencyDashboardTable-changeTableMode-expandMenuButton-${props.idPostFix}`}
            color="secondary"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Select Filter Option"
            aria-haspopup="menu"
            onClick={handleToggle}
            onDoubleClick={props.onDoubleClickArrowBtn}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id={`consistencyDashboardTable-changeTableMode-menuList-${props.idPostFix}`}
                  >
                    {allTableModes.map((tm, idx) => (
                      <MenuItem
                        key={idx}
                        id={`consistencyDashboardTable-changeTableMode-menuItem-${props.idPostFix}-${idx}`}
                        selected={props.tableMode === tm}
                        onClick={() => {
                          // always close menu
                          handleToggle();

                          // only call on change if mode is changing
                          if (props.tableMode !== tm) {
                            props.onTableModeChange(tm);
                          }
                        }}
                      >
                        {tm}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
