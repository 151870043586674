/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserManagerRead } from "james/user";
import { Client, ClientKYCStatus } from "james/client";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useFirebaseContext } from "../Firebase";
import {
  Authenticator,
  ErrUserNotVerifiedErrorCode,
} from "james/security/authentication";
import { Login } from "james/security/claims/Login";
import { Context as AuthContext } from "james/security";
import * as Sentry from "@sentry/react";
import { ConfigurationManager, ViewConfiguration } from "james/configuration";
import { Manager } from "james/client/ManagerRead";
import LogRocket from "logrocket";
import { InitiateUserRegistration } from "views/SignUp/const";
import { TranslatedError, useErrorContext } from "../Error";
import { CompanyUserRegistrationLocalStorageKey } from "views/CompanyUserRegistration/CompanyUserRegistration";
import { Role } from "james/role";
import { useLocalStorage } from "hooks/persistentStateStore/useLocalStorage";
import { Reader } from "james/legal/company/Reader";
import { IDIdentifier } from "james/search/identifier";
import { ClientKind } from "james/client/Client";
import { Company } from "james/legal/company";
import { useMFAContext } from "../MFA/MFA";

interface ContextType {
  myClient?: Client;
  myCompany?: Company;
  myClientRetrievalErr?: Error;
  refreshMyClient: () => void;
  refreshMyCompany: () => void;

  myRoles?: Role[];
  myRolesRetrievalErr?: Error;
  refreshMyRole: () => void;

  myClientKYCStatus: ClientKYCStatus | "";

  myProfilePictureURL: string;
  refreshProfilePictureURL: () => void;

  loginClaims: Login;
  loginClaimsUpdate: (loginClaims: Login) => Promise<void>;
  refreshLoginClaims: () => Promise<void>;

  authContext: AuthContext;

  logout: () => Promise<void>;

  applicationContextUpdating: boolean;

  viewConfiguration: ViewConfiguration;

  userAuthenticated: boolean;
  userLoginErr?: TranslatedError;
}

const Context = React.createContext({} as ContextType);

export let logoutUser: () => Promise<void>;

function ApplicationContext({ children }: { children?: React.ReactNode }) {
  const [roles, setRoles] = useState<undefined | Role[]>(undefined);
  const [rolesRetrievalErr, setRolesRetrievalErr] = useState<undefined | Error>(
    undefined,
  );
  const [company, setCompany] = useState<undefined | Company>(undefined);

  const [client, setClient] = useState<undefined | Client>(undefined);
  const [clientRetrievalErr, setClientRetrievalErr] = useState<
    undefined | Error
  >(undefined);

  const [clientKYCStatus, setClientKYCStatus] = useState<ClientKYCStatus | "">(
    "",
  );
  const [applicationContextUpdating, setApplicationContextUpdating] =
    useState(false);

  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
  const [viewConfiguration, setViewConfiguration] = useState(
    {} as ViewConfiguration,
  );
  const [loginClaims, setLoginClaims] = useState<Login>(new Login());
  const [authContext, setAuthContext] = useState<AuthContext>(
    new AuthContext(),
  );
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const {
    firebaseAuthenticated,
    firebaseUserIDToken,
    firebaseLogout,
    firebaseContextUpdating,
  } = useFirebaseContext();
  const [userLoginErr, setUserLoginErr] = useState<undefined | TranslatedError>(
    undefined,
  );
  const [profilePictureURL, setProfilePictureURL] = useState("");

  const { setAuthState } = useMFAContext();

  const { clearStore } = useLocalStorage();

  useEffect(() => {
    if (
      firebaseContextUpdating ||
      !firebaseAuthenticated ||
      userAuthenticated
    ) {
      return;
    }

    if (
      localStorage.getItem(InitiateUserRegistration) ||
      localStorage.getItem(CompanyUserRegistrationLocalStorageKey)
    ) {
      return;
    }

    setApplicationContextUpdating(true);
    (async () => {
      let newLoginClaims = loginClaims;
      try {
        // attempt to login the user
        const loginResponse = await Authenticator.Login({
          firebaseUserIDToken,
        });

        // prepare set of user traits for LogRocket
        const logRocketUserTraits: { [key: string]: string } = {};

        const getMyViewConfigurationResponse =
          await ConfigurationManager.GetMyViewConfiguration({
            context: new AuthContext({
              userID: loginResponse.loginClaims.userID,
            }),
          });

        setViewConfiguration(getMyViewConfigurationResponse.viewConfiguration);

        setClientKYCStatus(
          loginResponse.loginClaims.kycStatus as ClientKYCStatus,
        );
        setAuthContext(
          new AuthContext({
            userID: loginResponse.loginClaims.userID,
          }),
        );

        setAuthState(
          new AuthContext({
            userID: loginResponse.loginClaims.userID,
          }),
        );

        newLoginClaims = loginResponse.loginClaims;
        setLoginClaims(newLoginClaims);

        //
        // setup logrocket
        //
        // set the LogRocket user traits
        logRocketUserTraits.name = `${newLoginClaims.firstName} ${newLoginClaims.lastName}`;
        logRocketUserTraits.email = newLoginClaims.email;
        logRocketUserTraits.clientKind = newLoginClaims.clientKind;
        LogRocket.identify(
          loginResponse.loginClaims.userID,
          logRocketUserTraits,
        );

        //
        // setup sentry
        //
        // Set the sentry user context
        Sentry.setUser({
          email: loginClaims.email,
          id: loginClaims.userID,
          ip_address: "{{auto}}",
        });

        setUserLoginErr(undefined);
        setUserAuthenticated(true);
        setApplicationContextUpdating(false);
      } catch (e: any) {
        Sentry.captureException(e);
        const err = errorContextErrorTranslator.translateError(e);
        setUserLoginErr(err);
        if (err.code === ErrUserNotVerifiedErrorCode) {
          // we do nothing since the app will redirect you to sign up
          setApplicationContextUpdating(false);
          return;
        }
        errorContextDefaultErrorFeedback(err);
        // logout user from firebase
        await firebaseLogout();
        setApplicationContextUpdating(false);
        return;
      }
    })();
  }, [firebaseAuthenticated, firebaseContextUpdating]);

  useEffect(() => {
    if (!firebaseContextUpdating && !firebaseAuthenticated) {
      logout().finally();
    }
  }, [firebaseAuthenticated]);

  useEffect(() => {
    if (!userAuthenticated) {
      return;
    }

    // retrieve the client
    fetchMyClient().finally();

    // retrieve the company
    fetchMyCompany().finally();

    // retrieve the user roles
    fetchMyRoles().finally();

    // retrieve use profile picture
    refreshProfilePictureURL().finally();

    logoutUser = logout;
  }, [userAuthenticated]);

  useEffect(() => {
    if (!userAuthenticated) {
      return;
    }

    // retrieve the company
    fetchMyCompany().finally();

    logoutUser = logout;
  }, [client]);

  const logout = useCallback(async () => {
    try {
      // clear access token
      // do this in a try catch as logout will throw an error if there is a network error
      await Authenticator.Logout();
    } catch (e) {
      console.error("error logging out from mesh", e);
    }

    // update the logged in state
    setUserAuthenticated(false);

    // logout out of firebase
    await firebaseLogout();

    // clear the persistent state store
    clearStore();

    // Clear the sentry user context
    Sentry.configureScope((scope) => scope.setUser(null));

    // clean up state
    setLoginClaims(new Login());
    setProfilePictureURL("");
    setClient(undefined);
    setCompany(undefined);
    setClientRetrievalErr(undefined);
    setClientKYCStatus("");
    setAuthContext(new AuthContext());
  }, []);

  const fetchMyClient = useCallback(async () => {
    try {
      const responseMyClient = await Manager.RetrieveMyClient({
        context: authContext,
      });
      setClientRetrievalErr(undefined);
      setClientKYCStatus(responseMyClient.client.kycStatus as ClientKYCStatus);
      setClient(responseMyClient.client);
    } catch (e: any) {
      console.error("error retrieving client:", e);
      setClientRetrievalErr(e);
    }
  }, [authContext]);

  const fetchMyCompany = useCallback(async () => {
    if (client?.clientKind !== ClientKind.CompanyType) {
      return;
    }
    try {
      const retrieveMyCompanyResponse = await Reader.RetrieveMyCompany({
        context: authContext,
        identifier: IDIdentifier(client.legalEntityID),
      });
      setCompany(retrieveMyCompanyResponse.company);

      return retrieveMyCompanyResponse.company;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      errorContextDefaultErrorFeedback(err);
    }
  }, [client, authContext]);

  const fetchMyRoles = useCallback(async () => {
    try {
      const responseMyRoles = await UserManagerRead.RetrieveMyRoles({
        context: authContext,
      });
      setRoles(responseMyRoles.roles);
      setRolesRetrievalErr(undefined);
    } catch (e: any) {
      console.error("error retrieving roles:", e);
      setRolesRetrievalErr(e);
    }
  }, [authContext]);

  const refreshProfilePictureURL = useCallback(async () => {
    try {
      const response = await UserManagerRead.GetMyProfilePictureDownloadURL({
        context: authContext,
      });

      setProfilePictureURL(response.url);
    } catch (e) {
      console.error("error getting my profile picture download url:", e);
    }
  }, [authContext]);

  const loginClaimsUpdate = useCallback(
    async (loginClaims: Login) => {
      const getMyViewConfigurationResponse =
        await ConfigurationManager.GetMyViewConfiguration({
          context: new AuthContext({
            userID: loginClaims.userID,
          }),
        });

      setViewConfiguration(getMyViewConfigurationResponse.viewConfiguration);
      setClientKYCStatus(loginClaims.kycStatus as ClientKYCStatus);
      setAuthContext(
        new AuthContext({
          userID: loginClaims.userID,
        }),
      );

      // Set the sentry user context
      Sentry.setUser({
        email: loginClaims.email,
        id: loginClaims.userID,
        ip_address: "{{auto}}",
      });
      setLoginClaims(loginClaims);
      setUserLoginErr(undefined);
      setUserAuthenticated(true);
    },
    [loginClaims],
  );

  const refreshLoginClaims = useCallback(async () => {
    const refreshLoginResponse = await Authenticator.RefreshLogin({
      context: authContext,
    });

    await loginClaimsUpdate(refreshLoginResponse.loginClaims);
  }, [authContext]);

  return (
    <Context.Provider
      value={{
        myRoles: roles,
        myRolesRetrievalErr: rolesRetrievalErr,
        refreshMyRole: fetchMyRoles,
        myClient: client,
        myCompany: company,
        myClientRetrievalErr: clientRetrievalErr,
        myClientKYCStatus: clientKYCStatus,
        refreshMyClient: fetchMyClient,
        refreshMyCompany: fetchMyCompany,
        myProfilePictureURL: profilePictureURL,
        refreshProfilePictureURL,
        applicationContextUpdating,
        loginClaims,
        loginClaimsUpdate,
        refreshLoginClaims,
        authContext,
        logout,
        viewConfiguration,
        userAuthenticated,
        userLoginErr,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useApplicationContext = () => useContext(Context);
export { useApplicationContext };

export default ApplicationContext;
