import React from "react";
import {
  SubscriptionOrderBookContextProvider,
  useSubscriptionOrderBookContext,
} from "./Context";
import { Box } from "@mui/system";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from "@mui/material";
import { SubscriptionOrderBookCreateForm } from "./components/SubscriptionOrderBookCreateForm/SubscriptionOrderBookCreateForm";
import { SubscriptionOrderBookDetail } from "./components/SubscriptionOrderBookDetail";

export type SubscriptionOrderBookManagementProps = {
  system: boolean;
  listingQuoteAssetTokenViewModel: LedgerTokenViewModel;
  smartInstrument: SmartInstrument;
};

export const SubscriptionOrderBookManagement = (
  props: SubscriptionOrderBookManagementProps,
) => {
  return (
    <SubscriptionOrderBookContextProvider
      system={props.system}
      listingQuoteAssetTokenViewModel={props.listingQuoteAssetTokenViewModel}
      smartInstrument={props.smartInstrument}
    >
      <SubscriptionOrderBookManagementBody
        system={props.system}
        smartInstrument={props.smartInstrument}
      />
    </SubscriptionOrderBookContextProvider>
  );
};

const SubscriptionOrderBookManagementBody = ({
  system,
  smartInstrument,
}: {
  system: boolean;
  smartInstrument: SmartInstrument;
}) => {
  const {
    initialised,
    initialisationError,
    clearInitialisationError,
    formData,
  } = useSubscriptionOrderBookContext();

  if (initialisationError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 300,
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Book"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>Something went wrong while initialsing.</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInitialisationError}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (!initialised) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 300,
        }}
      >
        <Card>
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <CircularProgress size={70} />
            <Typography
              variant="h5"
              color="textSecondary"
              children="Getting things ready for you..."
            />
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (formData.subscriptionOrderBook.id === "") {
    return <SubscriptionOrderBookCreateForm />;
  } else {
    return (
      <SubscriptionOrderBookDetail
        system={system}
        assetName={smartInstrument.getName()}
      />
    );
  }
};
