// source: banking/bankAccountRepository_meshproto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var search_sorting_pb = require('../search/sorting_pb.js');
goog.object.extend(proto, search_sorting_pb);
var banking_bankAccount_pb = require('../banking/bankAccount_pb.js');
goog.object.extend(proto, banking_bankAccount_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
var banking_fundingOrderState_pb = require('../banking/fundingOrderState_pb.js');
goog.object.extend(proto, banking_fundingOrderState_pb);
goog.exportSymbol('proto.banking.CreateBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.CreateBankAccountResponse', null, global);
goog.exportSymbol('proto.banking.DeleteBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.DeleteBankAccountResponse', null, global);
goog.exportSymbol('proto.banking.RetrieveBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.RetrieveBankAccountResponse', null, global);
goog.exportSymbol('proto.banking.SearchAllBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.SearchAllBankAccountResponse', null, global);
goog.exportSymbol('proto.banking.SearchBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.SearchBankAccountResponse', null, global);
goog.exportSymbol('proto.banking.UpdateBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.UpdateBankAccountResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.CreateBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.CreateBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.CreateBankAccountRequest.displayName = 'proto.banking.CreateBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.CreateBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.CreateBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.CreateBankAccountResponse.displayName = 'proto.banking.CreateBankAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.RetrieveBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.RetrieveBankAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.RetrieveBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.RetrieveBankAccountRequest.displayName = 'proto.banking.RetrieveBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.RetrieveBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.RetrieveBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.RetrieveBankAccountResponse.displayName = 'proto.banking.RetrieveBankAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankAccountRequest.displayName = 'proto.banking.UpdateBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankAccountResponse.displayName = 'proto.banking.UpdateBankAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SearchBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.SearchBankAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.SearchBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SearchBankAccountRequest.displayName = 'proto.banking.SearchBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SearchBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.SearchBankAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.banking.SearchBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SearchBankAccountResponse.displayName = 'proto.banking.SearchBankAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SearchAllBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.SearchAllBankAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.SearchAllBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SearchAllBankAccountRequest.displayName = 'proto.banking.SearchAllBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SearchAllBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.SearchAllBankAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.banking.SearchAllBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SearchAllBankAccountResponse.displayName = 'proto.banking.SearchAllBankAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.DeleteBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.DeleteBankAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.DeleteBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.DeleteBankAccountRequest.displayName = 'proto.banking.DeleteBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.DeleteBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.DeleteBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.DeleteBankAccountResponse.displayName = 'proto.banking.DeleteBankAccountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.CreateBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.CreateBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.CreateBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
ownerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
countrycode: jspb.Message.getFieldWithDefault(msg, 3, ""),
bankname: jspb.Message.getFieldWithDefault(msg, 4, 0),
accounttype: jspb.Message.getFieldWithDefault(msg, 5, 0),
branchcode: jspb.Message.getFieldWithDefault(msg, 6, ""),
number: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.CreateBankAccountRequest}
 */
proto.banking.CreateBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.CreateBankAccountRequest;
  return proto.banking.CreateBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.CreateBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.CreateBankAccountRequest}
 */
proto.banking.CreateBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountrycode(value);
      break;
    case 4:
      var value = /** @type {!proto.banking.BankName} */ (reader.readEnum());
      msg.setBankname(value);
      break;
    case 5:
      var value = /** @type {!proto.banking.BankAccountType} */ (reader.readEnum());
      msg.setAccounttype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchcode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.CreateBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.CreateBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.CreateBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getOwnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountrycode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBankname();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAccounttype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBranchcode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.CreateBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.CreateBankAccountRequest} returns this
*/
proto.banking.CreateBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.CreateBankAccountRequest} returns this
 */
proto.banking.CreateBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.CreateBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string ownerID = 2;
 * @return {string}
 */
proto.banking.CreateBankAccountRequest.prototype.getOwnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateBankAccountRequest} returns this
 */
proto.banking.CreateBankAccountRequest.prototype.setOwnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string countryCode = 3;
 * @return {string}
 */
proto.banking.CreateBankAccountRequest.prototype.getCountrycode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateBankAccountRequest} returns this
 */
proto.banking.CreateBankAccountRequest.prototype.setCountrycode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BankName bankName = 4;
 * @return {!proto.banking.BankName}
 */
proto.banking.CreateBankAccountRequest.prototype.getBankname = function() {
  return /** @type {!proto.banking.BankName} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.banking.BankName} value
 * @return {!proto.banking.CreateBankAccountRequest} returns this
 */
proto.banking.CreateBankAccountRequest.prototype.setBankname = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional BankAccountType accountType = 5;
 * @return {!proto.banking.BankAccountType}
 */
proto.banking.CreateBankAccountRequest.prototype.getAccounttype = function() {
  return /** @type {!proto.banking.BankAccountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.banking.BankAccountType} value
 * @return {!proto.banking.CreateBankAccountRequest} returns this
 */
proto.banking.CreateBankAccountRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string branchCode = 6;
 * @return {string}
 */
proto.banking.CreateBankAccountRequest.prototype.getBranchcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateBankAccountRequest} returns this
 */
proto.banking.CreateBankAccountRequest.prototype.setBranchcode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string number = 7;
 * @return {string}
 */
proto.banking.CreateBankAccountRequest.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CreateBankAccountRequest} returns this
 */
proto.banking.CreateBankAccountRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.CreateBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.CreateBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.CreateBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.CreateBankAccountResponse}
 */
proto.banking.CreateBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.CreateBankAccountResponse;
  return proto.banking.CreateBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.CreateBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.CreateBankAccountResponse}
 */
proto.banking.CreateBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.CreateBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.CreateBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.CreateBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CreateBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount bankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.CreateBankAccountResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.CreateBankAccountResponse} returns this
*/
proto.banking.CreateBankAccountResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.CreateBankAccountResponse} returns this
 */
proto.banking.CreateBankAccountResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.CreateBankAccountResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.RetrieveBankAccountRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.RetrieveBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.RetrieveBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.RetrieveBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.RetrieveBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.RetrieveBankAccountRequest}
 */
proto.banking.RetrieveBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.RetrieveBankAccountRequest;
  return proto.banking.RetrieveBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.RetrieveBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.RetrieveBankAccountRequest}
 */
proto.banking.RetrieveBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.RetrieveBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.RetrieveBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.RetrieveBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.RetrieveBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.RetrieveBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.RetrieveBankAccountRequest} returns this
*/
proto.banking.RetrieveBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.RetrieveBankAccountRequest} returns this
 */
proto.banking.RetrieveBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.RetrieveBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.RetrieveBankAccountRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.RetrieveBankAccountRequest} returns this
*/
proto.banking.RetrieveBankAccountRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.RetrieveBankAccountRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.RetrieveBankAccountRequest} returns this
 */
proto.banking.RetrieveBankAccountRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.RetrieveBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.RetrieveBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.RetrieveBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.RetrieveBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.RetrieveBankAccountResponse}
 */
proto.banking.RetrieveBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.RetrieveBankAccountResponse;
  return proto.banking.RetrieveBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.RetrieveBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.RetrieveBankAccountResponse}
 */
proto.banking.RetrieveBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.RetrieveBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.RetrieveBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.RetrieveBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.RetrieveBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount bankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.RetrieveBankAccountResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.RetrieveBankAccountResponse} returns this
*/
proto.banking.RetrieveBankAccountResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.RetrieveBankAccountResponse} returns this
 */
proto.banking.RetrieveBankAccountResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.RetrieveBankAccountResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankAccountRequest}
 */
proto.banking.UpdateBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankAccountRequest;
  return proto.banking.UpdateBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankAccountRequest}
 */
proto.banking.UpdateBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.UpdateBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.UpdateBankAccountRequest} returns this
*/
proto.banking.UpdateBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountRequest} returns this
 */
proto.banking.UpdateBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BankAccount bankAccount = 2;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.UpdateBankAccountRequest.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 2));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.UpdateBankAccountRequest} returns this
*/
proto.banking.UpdateBankAccountRequest.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountRequest} returns this
 */
proto.banking.UpdateBankAccountRequest.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountRequest.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankAccountResponse}
 */
proto.banking.UpdateBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankAccountResponse;
  return proto.banking.UpdateBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankAccountResponse}
 */
proto.banking.UpdateBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount bankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.UpdateBankAccountResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.UpdateBankAccountResponse} returns this
*/
proto.banking.UpdateBankAccountResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountResponse} returns this
 */
proto.banking.UpdateBankAccountResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.SearchBankAccountRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SearchBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SearchBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SearchBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SearchBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
query: (f = msg.getQuery()) && search_query_pb.Query.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SearchBankAccountRequest}
 */
proto.banking.SearchBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SearchBankAccountRequest;
  return proto.banking.SearchBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SearchBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SearchBankAccountRequest}
 */
proto.banking.SearchBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_query_pb.Query;
      reader.readMessage(value,search_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SearchBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SearchBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SearchBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SearchBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      search_query_pb.Query.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.SearchBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.SearchBankAccountRequest} returns this
*/
proto.banking.SearchBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SearchBankAccountRequest} returns this
 */
proto.banking.SearchBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SearchBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.SearchBankAccountRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.SearchBankAccountRequest} returns this
*/
proto.banking.SearchBankAccountRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.SearchBankAccountRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.SearchBankAccountRequest} returns this
 */
proto.banking.SearchBankAccountRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional search.Query query = 3;
 * @return {?proto.search.Query}
 */
proto.banking.SearchBankAccountRequest.prototype.getQuery = function() {
  return /** @type{?proto.search.Query} */ (
    jspb.Message.getWrapperField(this, search_query_pb.Query, 3));
};


/**
 * @param {?proto.search.Query|undefined} value
 * @return {!proto.banking.SearchBankAccountRequest} returns this
*/
proto.banking.SearchBankAccountRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SearchBankAccountRequest} returns this
 */
proto.banking.SearchBankAccountRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SearchBankAccountRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.SearchBankAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SearchBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SearchBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SearchBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SearchBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    banking_bankAccount_pb.BankAccount.toObject, includeInstance),
total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SearchBankAccountResponse}
 */
proto.banking.SearchBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SearchBankAccountResponse;
  return proto.banking.SearchBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SearchBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SearchBankAccountResponse}
 */
proto.banking.SearchBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SearchBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SearchBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SearchBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SearchBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated BankAccount records = 1;
 * @return {!Array<!proto.banking.BankAccount>}
 */
proto.banking.SearchBankAccountResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.banking.BankAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {!Array<!proto.banking.BankAccount>} value
 * @return {!proto.banking.SearchBankAccountResponse} returns this
*/
proto.banking.SearchBankAccountResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.banking.BankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.banking.BankAccount}
 */
proto.banking.SearchBankAccountResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.banking.BankAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.SearchBankAccountResponse} returns this
 */
proto.banking.SearchBankAccountResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.banking.SearchBankAccountResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.banking.SearchBankAccountResponse} returns this
 */
proto.banking.SearchBankAccountResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.SearchAllBankAccountRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SearchAllBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SearchAllBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SearchAllBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SearchAllBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
sortingList: jspb.Message.toObjectList(msg.getSortingList(),
    search_sorting_pb.Sorting.toObject, includeInstance),
batchsize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SearchAllBankAccountRequest}
 */
proto.banking.SearchAllBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SearchAllBankAccountRequest;
  return proto.banking.SearchAllBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SearchAllBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SearchAllBankAccountRequest}
 */
proto.banking.SearchAllBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_sorting_pb.Sorting;
      reader.readMessage(value,search_sorting_pb.Sorting.deserializeBinaryFromReader);
      msg.addSorting(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBatchsize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SearchAllBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SearchAllBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SearchAllBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SearchAllBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getSortingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      search_sorting_pb.Sorting.serializeBinaryToWriter
    );
  }
  f = message.getBatchsize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.SearchAllBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.SearchAllBankAccountRequest} returns this
*/
proto.banking.SearchAllBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SearchAllBankAccountRequest} returns this
 */
proto.banking.SearchAllBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SearchAllBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.SearchAllBankAccountRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.SearchAllBankAccountRequest} returns this
*/
proto.banking.SearchAllBankAccountRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.SearchAllBankAccountRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.SearchAllBankAccountRequest} returns this
 */
proto.banking.SearchAllBankAccountRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * repeated search.Sorting sorting = 3;
 * @return {!Array<!proto.search.Sorting>}
 */
proto.banking.SearchAllBankAccountRequest.prototype.getSortingList = function() {
  return /** @type{!Array<!proto.search.Sorting>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_sorting_pb.Sorting, 3));
};


/**
 * @param {!Array<!proto.search.Sorting>} value
 * @return {!proto.banking.SearchAllBankAccountRequest} returns this
*/
proto.banking.SearchAllBankAccountRequest.prototype.setSortingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.search.Sorting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Sorting}
 */
proto.banking.SearchAllBankAccountRequest.prototype.addSorting = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.search.Sorting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.SearchAllBankAccountRequest} returns this
 */
proto.banking.SearchAllBankAccountRequest.prototype.clearSortingList = function() {
  return this.setSortingList([]);
};


/**
 * optional uint64 batchSize = 4;
 * @return {number}
 */
proto.banking.SearchAllBankAccountRequest.prototype.getBatchsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.banking.SearchAllBankAccountRequest} returns this
 */
proto.banking.SearchAllBankAccountRequest.prototype.setBatchsize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.SearchAllBankAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SearchAllBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SearchAllBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SearchAllBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SearchAllBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    banking_bankAccount_pb.BankAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SearchAllBankAccountResponse}
 */
proto.banking.SearchAllBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SearchAllBankAccountResponse;
  return proto.banking.SearchAllBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SearchAllBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SearchAllBankAccountResponse}
 */
proto.banking.SearchAllBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SearchAllBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SearchAllBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SearchAllBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SearchAllBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BankAccount records = 1;
 * @return {!Array<!proto.banking.BankAccount>}
 */
proto.banking.SearchAllBankAccountResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.banking.BankAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {!Array<!proto.banking.BankAccount>} value
 * @return {!proto.banking.SearchAllBankAccountResponse} returns this
*/
proto.banking.SearchAllBankAccountResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.banking.BankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.banking.BankAccount}
 */
proto.banking.SearchAllBankAccountResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.banking.BankAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.SearchAllBankAccountResponse} returns this
 */
proto.banking.SearchAllBankAccountResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.DeleteBankAccountRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.DeleteBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.DeleteBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.DeleteBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.DeleteBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.DeleteBankAccountRequest}
 */
proto.banking.DeleteBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.DeleteBankAccountRequest;
  return proto.banking.DeleteBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.DeleteBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.DeleteBankAccountRequest}
 */
proto.banking.DeleteBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.DeleteBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.DeleteBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.DeleteBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.DeleteBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.DeleteBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.DeleteBankAccountRequest} returns this
*/
proto.banking.DeleteBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.DeleteBankAccountRequest} returns this
 */
proto.banking.DeleteBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.DeleteBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.DeleteBankAccountRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.DeleteBankAccountRequest} returns this
*/
proto.banking.DeleteBankAccountRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.DeleteBankAccountRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.DeleteBankAccountRequest} returns this
 */
proto.banking.DeleteBankAccountRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.DeleteBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.DeleteBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.DeleteBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.DeleteBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.DeleteBankAccountResponse}
 */
proto.banking.DeleteBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.DeleteBankAccountResponse;
  return proto.banking.DeleteBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.DeleteBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.DeleteBankAccountResponse}
 */
proto.banking.DeleteBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.DeleteBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.DeleteBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.DeleteBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.DeleteBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.banking);
