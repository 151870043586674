import React from "react";
import {
  DialogContent,
  Typography,
  useTheme,
  Box,
  Tooltip,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { Amount } from "components/Ledger/Amount";
import { FormData, FormDataUpdaterSpecsType } from "../useValidatedForm";
import { TermsAndConditionsSection } from "../../TermsAndConditionsSection";
import { AssetType } from "james/views/marketListingView/Model";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";

export interface ConfirmationContentProps {
  isMobile: boolean;
  assetType: AssetType | "";
  assetUnit: Unit;
  submittingOrder: boolean;
  formData: FormData;
  formDataUpdate: FormDataUpdaterSpecsType;
  privateOffer: boolean;
}

export const ConfirmationContent = ({
  isMobile,
  assetType,
  assetUnit,
  submittingOrder,
  formData,
  formDataUpdate,
  privateOffer,
}: ConfirmationContentProps) => {
  const theme = useTheme();

  return (
    <>
      <DialogContent
        className="meshScroll"
        sx={{ flexGrow: 1, overflowY: "auto", p: 0 }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.custom.cardInner,
            padding: isMobile
              ? theme.spacing(4, 3, 3, 3)
              : theme.spacing(4, 6, 3, 6),
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", fontSize: "16px" }}
          >
            Confirm your order:
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(0.5),
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.text.secondary,
                }}
                children={`Est. ${assetType} Tokens`}
              />
              <Tooltip
                arrow
                placement={isMobile ? "top" : "right"}
                title={`
                  Final token amount calculated on settlement
                `}
              >
                <InfoIcon
                  sx={{
                    height: 15,
                    width: 15,
                    color: theme.palette.text.secondary,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                mt: 0.5,
              }}
            >
              <Amount
                reverse={true}
                valueTypographyProps={{
                  sx: {
                    fontWeight: "bold",
                    fontSize: "16px",
                  },
                  noWrap: true,
                }}
                codeTypographyProps={{
                  sx: {
                    fontWeight: "bold",
                    fontSize: "16px",
                  },
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
                amount={formData.tokenAmount}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(0.5),
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.text.secondary,
                }}
                children={`Est. Price per Token`}
              />
              <Tooltip
                arrow
                placement={isMobile ? "top" : "right"}
                title={`
                  Final price recorded before settlement
                `}
              >
                <InfoIcon
                  sx={{
                    height: 15,
                    width: 15,
                    color: theme.palette.text.secondary,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                mt: 0.5,
              }}
            >
              <Amount
                valueTypographyProps={{
                  sx: {
                    fontWeight: "bold",
                    fontSize: "16px",
                  },
                  noWrap: true,
                }}
                codeTypographyProps={{
                  sx: {
                    fontWeight: "bold",
                    fontSize: "16px",
                  },
                }}
                amount={formData.subscriptionOrderBookViewModel.unitPrice}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              Investment Amount
            </Typography>
            <Amount
              codeTypographyProps={{
                variant: "body1",
                sx: {
                  fontWeight: "bold",
                  color: theme.palette.custom.yellow,
                  mt: 0.5,
                  fontSize: "16px",
                },
              }}
              valueTypographyProps={{
                variant: "body1",
                sx: {
                  color: theme.palette.warning.light,
                  fontWeight: "bold",
                  mt: 0.5,
                  fontSize: "16px",
                },
              }}
              amount={formData.investmentAmount}
            />
          </Box>
        </Box>
      </DialogContent>
      <TermsAndConditionsSection
        submittingOrder={submittingOrder}
        assetType={assetType}
        assetUnit={assetUnit}
        accepted={formData.termsAndConditionsAccepted}
        setAccepted={formDataUpdate.termsAndConditionsAccepted}
        displayed={formData.termsAndConditionsDisplayed}
        setDisplayed={formDataUpdate.termsAndConditionsDisplayed}
        privateOffer={privateOffer}
      />
    </>
  );
};
