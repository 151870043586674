// source: banking/defundOrderStateController.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
var banking_defundOrder_pb = require('../banking/defundOrder_pb.js');
goog.object.extend(proto, banking_defundOrder_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.banking.MarkDefundOrderAwaitingSettlementRequest', null, global);
goog.exportSymbol('proto.banking.MarkDefundOrderAwaitingSettlementResponse', null, global);
goog.exportSymbol('proto.banking.MarkDefundOrderCancelledRequest', null, global);
goog.exportSymbol('proto.banking.MarkDefundOrderCancelledResponse', null, global);
goog.exportSymbol('proto.banking.MarkDefundOrderSettledRequest', null, global);
goog.exportSymbol('proto.banking.MarkDefundOrderSettledResponse', null, global);
goog.exportSymbol('proto.banking.MarkDefundOrderUnderInvestigationRequest', null, global);
goog.exportSymbol('proto.banking.MarkDefundOrderUnderInvestigationResponse', null, global);
goog.exportSymbol('proto.banking.ResolveDefundOrderStateRequest', null, global);
goog.exportSymbol('proto.banking.ResolveDefundOrderStateResponse', null, global);
goog.exportSymbol('proto.banking.SettleDefundOrderRequest', null, global);
goog.exportSymbol('proto.banking.SettleDefundOrderResponse', null, global);
goog.exportSymbol('proto.banking.SubmitDefundOrderRequest', null, global);
goog.exportSymbol('proto.banking.SubmitDefundOrderResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SubmitDefundOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.SubmitDefundOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SubmitDefundOrderRequest.displayName = 'proto.banking.SubmitDefundOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SubmitDefundOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.SubmitDefundOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SubmitDefundOrderResponse.displayName = 'proto.banking.SubmitDefundOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SettleDefundOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.SettleDefundOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SettleDefundOrderRequest.displayName = 'proto.banking.SettleDefundOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SettleDefundOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.SettleDefundOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SettleDefundOrderResponse.displayName = 'proto.banking.SettleDefundOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkDefundOrderUnderInvestigationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkDefundOrderUnderInvestigationRequest.displayName = 'proto.banking.MarkDefundOrderUnderInvestigationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkDefundOrderUnderInvestigationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkDefundOrderUnderInvestigationResponse.displayName = 'proto.banking.MarkDefundOrderUnderInvestigationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkDefundOrderAwaitingSettlementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkDefundOrderAwaitingSettlementRequest.displayName = 'proto.banking.MarkDefundOrderAwaitingSettlementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkDefundOrderAwaitingSettlementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkDefundOrderAwaitingSettlementResponse.displayName = 'proto.banking.MarkDefundOrderAwaitingSettlementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkDefundOrderSettledRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkDefundOrderSettledRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkDefundOrderSettledRequest.displayName = 'proto.banking.MarkDefundOrderSettledRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkDefundOrderSettledResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkDefundOrderSettledResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkDefundOrderSettledResponse.displayName = 'proto.banking.MarkDefundOrderSettledResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkDefundOrderCancelledRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkDefundOrderCancelledRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkDefundOrderCancelledRequest.displayName = 'proto.banking.MarkDefundOrderCancelledRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkDefundOrderCancelledResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkDefundOrderCancelledResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkDefundOrderCancelledResponse.displayName = 'proto.banking.MarkDefundOrderCancelledResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ResolveDefundOrderStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.ResolveDefundOrderStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ResolveDefundOrderStateRequest.displayName = 'proto.banking.ResolveDefundOrderStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ResolveDefundOrderStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.ResolveDefundOrderStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ResolveDefundOrderStateResponse.displayName = 'proto.banking.ResolveDefundOrderStateResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SubmitDefundOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SubmitDefundOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SubmitDefundOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SubmitDefundOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
accountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
bankaccountid: jspb.Message.getFieldWithDefault(msg, 3, ""),
amount: (f = msg.getAmount()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
express: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SubmitDefundOrderRequest}
 */
proto.banking.SubmitDefundOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SubmitDefundOrderRequest;
  return proto.banking.SubmitDefundOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SubmitDefundOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SubmitDefundOrderRequest}
 */
proto.banking.SubmitDefundOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankaccountid(value);
      break;
    case 4:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SubmitDefundOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SubmitDefundOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SubmitDefundOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SubmitDefundOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankaccountid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getExpress();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.SubmitDefundOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.SubmitDefundOrderRequest} returns this
*/
proto.banking.SubmitDefundOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SubmitDefundOrderRequest} returns this
 */
proto.banking.SubmitDefundOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SubmitDefundOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string accountID = 2;
 * @return {string}
 */
proto.banking.SubmitDefundOrderRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SubmitDefundOrderRequest} returns this
 */
proto.banking.SubmitDefundOrderRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bankAccountID = 3;
 * @return {string}
 */
proto.banking.SubmitDefundOrderRequest.prototype.getBankaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SubmitDefundOrderRequest} returns this
 */
proto.banking.SubmitDefundOrderRequest.prototype.setBankaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ledger.FutureAmount amount = 4;
 * @return {?proto.ledger.FutureAmount}
 */
proto.banking.SubmitDefundOrderRequest.prototype.getAmount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 4));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.banking.SubmitDefundOrderRequest} returns this
*/
proto.banking.SubmitDefundOrderRequest.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SubmitDefundOrderRequest} returns this
 */
proto.banking.SubmitDefundOrderRequest.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SubmitDefundOrderRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool express = 5;
 * @return {boolean}
 */
proto.banking.SubmitDefundOrderRequest.prototype.getExpress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.banking.SubmitDefundOrderRequest} returns this
 */
proto.banking.SubmitDefundOrderRequest.prototype.setExpress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SubmitDefundOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SubmitDefundOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SubmitDefundOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SubmitDefundOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
number: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SubmitDefundOrderResponse}
 */
proto.banking.SubmitDefundOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SubmitDefundOrderResponse;
  return proto.banking.SubmitDefundOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SubmitDefundOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SubmitDefundOrderResponse}
 */
proto.banking.SubmitDefundOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SubmitDefundOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SubmitDefundOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SubmitDefundOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SubmitDefundOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string transactionID = 1;
 * @return {string}
 */
proto.banking.SubmitDefundOrderResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SubmitDefundOrderResponse} returns this
 */
proto.banking.SubmitDefundOrderResponse.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.banking.SubmitDefundOrderResponse.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SubmitDefundOrderResponse} returns this
 */
proto.banking.SubmitDefundOrderResponse.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SettleDefundOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SettleDefundOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SettleDefundOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SettleDefundOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
defundorderid: jspb.Message.getFieldWithDefault(msg, 2, ""),
bankreference: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SettleDefundOrderRequest}
 */
proto.banking.SettleDefundOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SettleDefundOrderRequest;
  return proto.banking.SettleDefundOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SettleDefundOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SettleDefundOrderRequest}
 */
proto.banking.SettleDefundOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefundorderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SettleDefundOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SettleDefundOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SettleDefundOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SettleDefundOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getDefundorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankreference();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.SettleDefundOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.SettleDefundOrderRequest} returns this
*/
proto.banking.SettleDefundOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SettleDefundOrderRequest} returns this
 */
proto.banking.SettleDefundOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SettleDefundOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string defundOrderID = 2;
 * @return {string}
 */
proto.banking.SettleDefundOrderRequest.prototype.getDefundorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SettleDefundOrderRequest} returns this
 */
proto.banking.SettleDefundOrderRequest.prototype.setDefundorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bankReference = 3;
 * @return {string}
 */
proto.banking.SettleDefundOrderRequest.prototype.getBankreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SettleDefundOrderRequest} returns this
 */
proto.banking.SettleDefundOrderRequest.prototype.setBankreference = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SettleDefundOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SettleDefundOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SettleDefundOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SettleDefundOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
defundorder: (f = msg.getDefundorder()) && banking_defundOrder_pb.DefundOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SettleDefundOrderResponse}
 */
proto.banking.SettleDefundOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SettleDefundOrderResponse;
  return proto.banking.SettleDefundOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SettleDefundOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SettleDefundOrderResponse}
 */
proto.banking.SettleDefundOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_defundOrder_pb.DefundOrder;
      reader.readMessage(value,banking_defundOrder_pb.DefundOrder.deserializeBinaryFromReader);
      msg.setDefundorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SettleDefundOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SettleDefundOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SettleDefundOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SettleDefundOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefundorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_defundOrder_pb.DefundOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional DefundOrder defundOrder = 1;
 * @return {?proto.banking.DefundOrder}
 */
proto.banking.SettleDefundOrderResponse.prototype.getDefundorder = function() {
  return /** @type{?proto.banking.DefundOrder} */ (
    jspb.Message.getWrapperField(this, banking_defundOrder_pb.DefundOrder, 1));
};


/**
 * @param {?proto.banking.DefundOrder|undefined} value
 * @return {!proto.banking.SettleDefundOrderResponse} returns this
*/
proto.banking.SettleDefundOrderResponse.prototype.setDefundorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SettleDefundOrderResponse} returns this
 */
proto.banking.SettleDefundOrderResponse.prototype.clearDefundorder = function() {
  return this.setDefundorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SettleDefundOrderResponse.prototype.hasDefundorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkDefundOrderUnderInvestigationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkDefundOrderUnderInvestigationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
defundorderid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkDefundOrderUnderInvestigationRequest}
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkDefundOrderUnderInvestigationRequest;
  return proto.banking.MarkDefundOrderUnderInvestigationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkDefundOrderUnderInvestigationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkDefundOrderUnderInvestigationRequest}
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefundorderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkDefundOrderUnderInvestigationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkDefundOrderUnderInvestigationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getDefundorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.MarkDefundOrderUnderInvestigationRequest} returns this
*/
proto.banking.MarkDefundOrderUnderInvestigationRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkDefundOrderUnderInvestigationRequest} returns this
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string defundOrderID = 2;
 * @return {string}
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.prototype.getDefundorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.MarkDefundOrderUnderInvestigationRequest} returns this
 */
proto.banking.MarkDefundOrderUnderInvestigationRequest.prototype.setDefundorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkDefundOrderUnderInvestigationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkDefundOrderUnderInvestigationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
defundorder: (f = msg.getDefundorder()) && banking_defundOrder_pb.DefundOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkDefundOrderUnderInvestigationResponse}
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkDefundOrderUnderInvestigationResponse;
  return proto.banking.MarkDefundOrderUnderInvestigationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkDefundOrderUnderInvestigationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkDefundOrderUnderInvestigationResponse}
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_defundOrder_pb.DefundOrder;
      reader.readMessage(value,banking_defundOrder_pb.DefundOrder.deserializeBinaryFromReader);
      msg.setDefundorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkDefundOrderUnderInvestigationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkDefundOrderUnderInvestigationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefundorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_defundOrder_pb.DefundOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional DefundOrder defundOrder = 1;
 * @return {?proto.banking.DefundOrder}
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse.prototype.getDefundorder = function() {
  return /** @type{?proto.banking.DefundOrder} */ (
    jspb.Message.getWrapperField(this, banking_defundOrder_pb.DefundOrder, 1));
};


/**
 * @param {?proto.banking.DefundOrder|undefined} value
 * @return {!proto.banking.MarkDefundOrderUnderInvestigationResponse} returns this
*/
proto.banking.MarkDefundOrderUnderInvestigationResponse.prototype.setDefundorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkDefundOrderUnderInvestigationResponse} returns this
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse.prototype.clearDefundorder = function() {
  return this.setDefundorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkDefundOrderUnderInvestigationResponse.prototype.hasDefundorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkDefundOrderAwaitingSettlementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkDefundOrderAwaitingSettlementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
defundorderid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkDefundOrderAwaitingSettlementRequest}
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkDefundOrderAwaitingSettlementRequest;
  return proto.banking.MarkDefundOrderAwaitingSettlementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkDefundOrderAwaitingSettlementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkDefundOrderAwaitingSettlementRequest}
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefundorderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkDefundOrderAwaitingSettlementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkDefundOrderAwaitingSettlementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getDefundorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.MarkDefundOrderAwaitingSettlementRequest} returns this
*/
proto.banking.MarkDefundOrderAwaitingSettlementRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkDefundOrderAwaitingSettlementRequest} returns this
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string defundOrderID = 2;
 * @return {string}
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.prototype.getDefundorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.MarkDefundOrderAwaitingSettlementRequest} returns this
 */
proto.banking.MarkDefundOrderAwaitingSettlementRequest.prototype.setDefundorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkDefundOrderAwaitingSettlementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkDefundOrderAwaitingSettlementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
defundorder: (f = msg.getDefundorder()) && banking_defundOrder_pb.DefundOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkDefundOrderAwaitingSettlementResponse}
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkDefundOrderAwaitingSettlementResponse;
  return proto.banking.MarkDefundOrderAwaitingSettlementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkDefundOrderAwaitingSettlementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkDefundOrderAwaitingSettlementResponse}
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_defundOrder_pb.DefundOrder;
      reader.readMessage(value,banking_defundOrder_pb.DefundOrder.deserializeBinaryFromReader);
      msg.setDefundorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkDefundOrderAwaitingSettlementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkDefundOrderAwaitingSettlementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefundorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_defundOrder_pb.DefundOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional DefundOrder defundOrder = 1;
 * @return {?proto.banking.DefundOrder}
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse.prototype.getDefundorder = function() {
  return /** @type{?proto.banking.DefundOrder} */ (
    jspb.Message.getWrapperField(this, banking_defundOrder_pb.DefundOrder, 1));
};


/**
 * @param {?proto.banking.DefundOrder|undefined} value
 * @return {!proto.banking.MarkDefundOrderAwaitingSettlementResponse} returns this
*/
proto.banking.MarkDefundOrderAwaitingSettlementResponse.prototype.setDefundorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkDefundOrderAwaitingSettlementResponse} returns this
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse.prototype.clearDefundorder = function() {
  return this.setDefundorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkDefundOrderAwaitingSettlementResponse.prototype.hasDefundorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkDefundOrderSettledRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkDefundOrderSettledRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkDefundOrderSettledRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderSettledRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
defundorderid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkDefundOrderSettledRequest}
 */
proto.banking.MarkDefundOrderSettledRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkDefundOrderSettledRequest;
  return proto.banking.MarkDefundOrderSettledRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkDefundOrderSettledRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkDefundOrderSettledRequest}
 */
proto.banking.MarkDefundOrderSettledRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefundorderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkDefundOrderSettledRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkDefundOrderSettledRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkDefundOrderSettledRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderSettledRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getDefundorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.MarkDefundOrderSettledRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.MarkDefundOrderSettledRequest} returns this
*/
proto.banking.MarkDefundOrderSettledRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkDefundOrderSettledRequest} returns this
 */
proto.banking.MarkDefundOrderSettledRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkDefundOrderSettledRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string defundOrderID = 2;
 * @return {string}
 */
proto.banking.MarkDefundOrderSettledRequest.prototype.getDefundorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.MarkDefundOrderSettledRequest} returns this
 */
proto.banking.MarkDefundOrderSettledRequest.prototype.setDefundorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkDefundOrderSettledResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkDefundOrderSettledResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkDefundOrderSettledResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderSettledResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
defundorder: (f = msg.getDefundorder()) && banking_defundOrder_pb.DefundOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkDefundOrderSettledResponse}
 */
proto.banking.MarkDefundOrderSettledResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkDefundOrderSettledResponse;
  return proto.banking.MarkDefundOrderSettledResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkDefundOrderSettledResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkDefundOrderSettledResponse}
 */
proto.banking.MarkDefundOrderSettledResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_defundOrder_pb.DefundOrder;
      reader.readMessage(value,banking_defundOrder_pb.DefundOrder.deserializeBinaryFromReader);
      msg.setDefundorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkDefundOrderSettledResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkDefundOrderSettledResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkDefundOrderSettledResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderSettledResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefundorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_defundOrder_pb.DefundOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional DefundOrder defundOrder = 1;
 * @return {?proto.banking.DefundOrder}
 */
proto.banking.MarkDefundOrderSettledResponse.prototype.getDefundorder = function() {
  return /** @type{?proto.banking.DefundOrder} */ (
    jspb.Message.getWrapperField(this, banking_defundOrder_pb.DefundOrder, 1));
};


/**
 * @param {?proto.banking.DefundOrder|undefined} value
 * @return {!proto.banking.MarkDefundOrderSettledResponse} returns this
*/
proto.banking.MarkDefundOrderSettledResponse.prototype.setDefundorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkDefundOrderSettledResponse} returns this
 */
proto.banking.MarkDefundOrderSettledResponse.prototype.clearDefundorder = function() {
  return this.setDefundorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkDefundOrderSettledResponse.prototype.hasDefundorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkDefundOrderCancelledRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkDefundOrderCancelledRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkDefundOrderCancelledRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderCancelledRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
defundorderid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkDefundOrderCancelledRequest}
 */
proto.banking.MarkDefundOrderCancelledRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkDefundOrderCancelledRequest;
  return proto.banking.MarkDefundOrderCancelledRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkDefundOrderCancelledRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkDefundOrderCancelledRequest}
 */
proto.banking.MarkDefundOrderCancelledRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefundorderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkDefundOrderCancelledRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkDefundOrderCancelledRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkDefundOrderCancelledRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderCancelledRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getDefundorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.MarkDefundOrderCancelledRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.MarkDefundOrderCancelledRequest} returns this
*/
proto.banking.MarkDefundOrderCancelledRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkDefundOrderCancelledRequest} returns this
 */
proto.banking.MarkDefundOrderCancelledRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkDefundOrderCancelledRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string defundOrderID = 2;
 * @return {string}
 */
proto.banking.MarkDefundOrderCancelledRequest.prototype.getDefundorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.MarkDefundOrderCancelledRequest} returns this
 */
proto.banking.MarkDefundOrderCancelledRequest.prototype.setDefundorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkDefundOrderCancelledResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkDefundOrderCancelledResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkDefundOrderCancelledResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderCancelledResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
defundorder: (f = msg.getDefundorder()) && banking_defundOrder_pb.DefundOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkDefundOrderCancelledResponse}
 */
proto.banking.MarkDefundOrderCancelledResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkDefundOrderCancelledResponse;
  return proto.banking.MarkDefundOrderCancelledResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkDefundOrderCancelledResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkDefundOrderCancelledResponse}
 */
proto.banking.MarkDefundOrderCancelledResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_defundOrder_pb.DefundOrder;
      reader.readMessage(value,banking_defundOrder_pb.DefundOrder.deserializeBinaryFromReader);
      msg.setDefundorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkDefundOrderCancelledResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkDefundOrderCancelledResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkDefundOrderCancelledResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkDefundOrderCancelledResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefundorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_defundOrder_pb.DefundOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional DefundOrder defundOrder = 1;
 * @return {?proto.banking.DefundOrder}
 */
proto.banking.MarkDefundOrderCancelledResponse.prototype.getDefundorder = function() {
  return /** @type{?proto.banking.DefundOrder} */ (
    jspb.Message.getWrapperField(this, banking_defundOrder_pb.DefundOrder, 1));
};


/**
 * @param {?proto.banking.DefundOrder|undefined} value
 * @return {!proto.banking.MarkDefundOrderCancelledResponse} returns this
*/
proto.banking.MarkDefundOrderCancelledResponse.prototype.setDefundorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkDefundOrderCancelledResponse} returns this
 */
proto.banking.MarkDefundOrderCancelledResponse.prototype.clearDefundorder = function() {
  return this.setDefundorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkDefundOrderCancelledResponse.prototype.hasDefundorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ResolveDefundOrderStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ResolveDefundOrderStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ResolveDefundOrderStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ResolveDefundOrderStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
defundorderid: jspb.Message.getFieldWithDefault(msg, 2, ""),
ignorefailurecount: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ResolveDefundOrderStateRequest}
 */
proto.banking.ResolveDefundOrderStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ResolveDefundOrderStateRequest;
  return proto.banking.ResolveDefundOrderStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ResolveDefundOrderStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ResolveDefundOrderStateRequest}
 */
proto.banking.ResolveDefundOrderStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefundorderid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnorefailurecount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ResolveDefundOrderStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ResolveDefundOrderStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ResolveDefundOrderStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ResolveDefundOrderStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getDefundorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIgnorefailurecount();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.ResolveDefundOrderStateRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.ResolveDefundOrderStateRequest} returns this
*/
proto.banking.ResolveDefundOrderStateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ResolveDefundOrderStateRequest} returns this
 */
proto.banking.ResolveDefundOrderStateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ResolveDefundOrderStateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string defundOrderID = 2;
 * @return {string}
 */
proto.banking.ResolveDefundOrderStateRequest.prototype.getDefundorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.ResolveDefundOrderStateRequest} returns this
 */
proto.banking.ResolveDefundOrderStateRequest.prototype.setDefundorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool ignoreFailureCount = 3;
 * @return {boolean}
 */
proto.banking.ResolveDefundOrderStateRequest.prototype.getIgnorefailurecount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.banking.ResolveDefundOrderStateRequest} returns this
 */
proto.banking.ResolveDefundOrderStateRequest.prototype.setIgnorefailurecount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ResolveDefundOrderStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ResolveDefundOrderStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ResolveDefundOrderStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ResolveDefundOrderStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
defundorder: (f = msg.getDefundorder()) && banking_defundOrder_pb.DefundOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ResolveDefundOrderStateResponse}
 */
proto.banking.ResolveDefundOrderStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ResolveDefundOrderStateResponse;
  return proto.banking.ResolveDefundOrderStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ResolveDefundOrderStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ResolveDefundOrderStateResponse}
 */
proto.banking.ResolveDefundOrderStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_defundOrder_pb.DefundOrder;
      reader.readMessage(value,banking_defundOrder_pb.DefundOrder.deserializeBinaryFromReader);
      msg.setDefundorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ResolveDefundOrderStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ResolveDefundOrderStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ResolveDefundOrderStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ResolveDefundOrderStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefundorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_defundOrder_pb.DefundOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional DefundOrder defundOrder = 1;
 * @return {?proto.banking.DefundOrder}
 */
proto.banking.ResolveDefundOrderStateResponse.prototype.getDefundorder = function() {
  return /** @type{?proto.banking.DefundOrder} */ (
    jspb.Message.getWrapperField(this, banking_defundOrder_pb.DefundOrder, 1));
};


/**
 * @param {?proto.banking.DefundOrder|undefined} value
 * @return {!proto.banking.ResolveDefundOrderStateResponse} returns this
*/
proto.banking.ResolveDefundOrderStateResponse.prototype.setDefundorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ResolveDefundOrderStateResponse} returns this
 */
proto.banking.ResolveDefundOrderStateResponse.prototype.clearDefundorder = function() {
  return this.setDefundorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ResolveDefundOrderStateResponse.prototype.hasDefundorder = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.banking);
