import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  useTheme,
  Box,
  Link,
  Checkbox,
  FormControlLabel,
  Collapse,
} from "@mui/material";
import { termsAndConditions } from "./const";
import { AssetType } from "james/views/marketListingView/Model";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";

export interface TermsAndConditionsSectionProps {
  submittingOrder: boolean;
  assetType: AssetType | "";
  assetUnit: Unit;
  accepted: boolean;
  setAccepted: (accepted: boolean) => void;
  displayed: boolean;
  setDisplayed: (displayed: boolean) => void;
  privateOffer: boolean;
}

export const TermsAndConditionsSection = ({
  submittingOrder,
  assetType,
  assetUnit,
  accepted,
  setAccepted,
  displayed,
  setDisplayed,
  privateOffer,
}: TermsAndConditionsSectionProps) => {
  const theme = useTheme();
  const [atBottom, setAtBottom] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (container) {
        const isAtBottom =
          container.scrollHeight - container.scrollTop ===
          container.clientHeight;
        setAtBottom(isAtBottom);
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [accepted]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.custom.midnight,
        padding: {
          xs: theme.spacing(0, 3, 0, 3),
          sm: theme.spacing(0, 6, 0, 6),
        },
        borderTopRightRadius: displayed ? 10 : 0,
        borderTopLeftRadius: displayed ? 10 : 0,
      }}
    >
      <Collapse in={displayed}>
        <Box sx={{ pt: 2 }}>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              fontSize: 12,
            })}
          >
            By proceeding with this subscription order, I HEREBY DECLARE THAT:
          </Typography>
          <Box
            ref={scrollContainerRef}
            sx={(theme) => ({
              my: 2,
              pr: 1,
              gap: 1,
              maxHeight: 200,
              overflowY: "auto", // Ensure only this element scrolls
              display: "flex",
              flexDirection: "column",
              color: theme.palette.text.secondary,
              transition: "mask-image 0.5s ease-in-out",
              maskImage: atBottom
                ? "none"
                : "linear-gradient(to bottom, black 60%, transparent 100%)",
            })}
            className="meshScroll"
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "9px auto",
                gap: 2,
              }}
            >
              {termsAndConditions(assetType, assetUnit, privateOffer).map(
                (item, idx) => (
                  <React.Fragment key={idx}>
                    <Typography
                      sx={(theme) => ({
                        maskImage: "inherit",
                        color: theme.palette.text.secondary,
                        fontSize: 12,
                      })}
                    >
                      {String.fromCharCode(65 + idx)}.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {item}
                    </Typography>
                  </React.Fragment>
                ),
              )}
            </Box>
            {assetType !== AssetType.PreferenceShare &&
              assetType !== AssetType.Bond &&
              !privateOffer && (
                // Assume this instrument has a token agreement like Commodity offering
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: 12,
                  }}
                >
                  By checking the box below and proceeding with my subscription,
                  I confirm that I have read, understood, and accepted all
                  statements in this Declaration. I understand and agree that
                  this Declaration forms a legally binding part of my
                  subscription order, alongside the Token Agreement and any
                  additional documentation applicable to this offering.
                </Typography>
              )}
          </Box>
        </Box>
      </Collapse>
      <FormControlLabel
        control={<Checkbox id="termsAndConditionsCheckBox" />}
        onChange={() => {
          setAccepted(!accepted);
        }}
        disabled={submittingOrder}
        checked={accepted}
        sx={{
          height: "auto",
          backgroundColor: theme.palette.custom.midnight,
          my: 3,
        }}
        label={
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.secondary,
                mr: 1,
                fontSize: 12,
                lineHeight: "18px",
              }}
            >
              I confirm that I have read, understood and agree to the{" "}
              <Link
                underline="hover"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setDisplayed(!displayed);
                }}
              >
                Terms & Conditions
              </Link>{" "}
              of this subscription order.
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};
