// source: financial/smartInstrumentType.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.financial.SmartInstrumentType', null, global);
/**
 * @enum {number}
 */
proto.financial.SmartInstrumentType = {
  UNDEFINED_SMART_INSTRUMENT_TYPE: 0,
  OTHER_SMART_INSTRUMENT_TYPE: 1,
  SHARE_SMART_INSTRUMENT_TYPE: 2,
  PREFERENCE_SHARE_SMART_INSTRUMENT_TYPE: 3,
  BOND_SMART_INSTRUMENT_TYPE: 4,
  ETF_SMART_INSTRUMENT_TYPE: 5,
  ETN_SMART_INSTRUMENT_TYPE: 6,
  AMC_SMART_INSTRUMENT_TYPE: 7,
  UNIT_TRUST_SMART_INSTRUMENT_TYPE: 8,
  CRYPTO_CURRENCY_SMART_INSTRUMENT_TYPE: 9,
  FIAT_CURRENCY_SMART_INSTRUMENT_TYPE: 10,
  RIGHTS_SMART_INSTRUMENT_TYPE: 11,
  GOLD_SMART_INSTRUMENT_TYPE: 12,
  SILVER_SMART_INSTRUMENT_TYPE: 13,
  PLATINUM_SMART_INSTRUMENT_TYPE: 14,
  PALLADIUM_SMART_INSTRUMENT_TYPE: 15,
  CRUDE_OIL_SMART_INSTRUMENT_TYPE: 16,
  NATURAL_GAS_SMART_INSTRUMENT_TYPE: 17,
  COPPER_SMART_INSTRUMENT_TYPE: 18,
  CORN_SMART_INSTRUMENT_TYPE: 19,
  WHEAT_SMART_INSTRUMENT_TYPE: 20,
  SOYBEANS_SMART_INSTRUMENT_TYPE: 21
};

goog.object.extend(exports, proto.financial);
