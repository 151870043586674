import { useMemo } from "react";
import { BigNumber } from "bignumber.js";
import { Amount } from "james/ledger/Amount";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";
import { AssetType } from "james/views/marketListingView/Model";
import { priceBreakdownAssetTypes } from "../const";

const parseUnitPriceDescriptionPercentage = (description: string): number => {
  const matches = description.match(/([\d.]+)%/);
  return matches ? parseFloat(matches[1]) : 0;
};

export const usePriceBreakdown = (
  assetType: AssetType | "",
  unit: Unit,
  unitPrice: Amount,
  unitPriceDescription: string,
) => {
  const { hasPriceBreakdown, estSpotPlusMarginPrice, estStorageCost } =
    useMemo(() => {
      const hasPriceBreakdown =
        assetType === "" ? false : priceBreakdownAssetTypes.includes(assetType);

      const spotMarginPercent =
        parseUnitPriceDescriptionPercentage(unitPriceDescription);

      const storageCostPercent = 1.2;
      const totalPercent = spotMarginPercent + storageCostPercent;

      const spotPriceValue = unitPrice.value.div(
        BigNumber(1).plus(BigNumber(totalPercent).div(100)),
      );

      const spotPlusMarginValue = spotPriceValue.multipliedBy(
        BigNumber(1).plus(BigNumber(spotMarginPercent).div(100)),
      );

      const spotPlusMargin = unitPrice.token.newAmountOf(spotPlusMarginValue);
      const storageCostValue = unitPrice.value.minus(spotPlusMarginValue);
      const storageCost = unitPrice.token.newAmountOf(storageCostValue);

      return {
        hasPriceBreakdown: hasPriceBreakdown,
        estSpotPlusMarginPrice: spotPlusMargin,
        estStorageCost: storageCost,
      };
    }, [assetType, unit, unitPrice, unitPriceDescription]);

  return {
    hasPriceBreakdown,
    estSpotPlusMarginPrice,
    estStorageCost,
  };
};
