import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatTextNum } from "utilities/number";
import {
  IndicativePrice,
  IndicativePriceRepository,
  PublicIndicativePrice,
} from "james/market";
import { TextExactCriterion } from "james/search/criterion";
import { NewSorting, Query } from "james/search/query";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { PlaceOrdersDialog } from "views/Marketplace/components/PlaceOrderDialog";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import { DirectOrderType } from "james/market/DirectOrder";
import { useIsMounted } from "hooks";
import { ScopeDeterminer } from "james/search/scope";
import { Permission } from "james/security/Permission";
import { DirectOrderSubmitterServiceProviderName } from "james/market/DirectOrderSubmitter";
import { ClientKYCStatus } from "james/client";
import { useSnackbar } from "notistack";
import { IndicativePriceFetcher } from "james/market/IndicativePriceFetcher";
import { TokenIdentifier } from "james/search/identifier";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LogRocket from "logrocket";
import { AssetEvent } from "const/logRocket";
import { Token } from "james/ledger";
import { LedgerNetwork } from "james/ledger/Network";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { WarningWithActions } from "components/Snackbar/WarningWithActions";
import { useApplicationContext } from "context/Application/Application";
import {
  InteractionAction,
  InteractionType,
  InteractionDriver,
  DataLinkInfoType,
} from "const/gtm";
import { useErrorContext } from "context/Error";
import { PrivatePlacementDialog } from "../PrivatePlacements/PrivatePlacementDialog";

interface OrderSectionProps {
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
}

export const DirectOrderSection = (props: OrderSectionProps) => {
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMounted = useIsMounted();
  const { viewConfiguration, myClientKYCStatus, authContext } =
    useApplicationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isPublic = location.pathname.startsWith("/public");

  const { current: directOrderTradingViewConfig } = useRef(
    viewConfiguration.DirectOrderTrading
      ? viewConfiguration.DirectOrderTrading
      : {},
  );
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();
  const [showPrivatePlacementDialog, setShowPrivatePlacementDialog] =
    useState(false);
  //
  // load indicative price data for token as well as
  // a ledgerTokenView model for the token in which prices are given
  //
  const [indicativePrice, setIndicativePrice] = useState<
    IndicativePrice | PublicIndicativePrice
  >(new IndicativePrice());
  const [priceTokenViewModel, setPriceTokenViewModel] =
    useState<LedgerTokenViewModel>(new LedgerTokenViewModel());
  const [loadingIndicativePriceData, setLoadingIndicativePriceData] =
    useState(false);
  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    (async () => {
      if (isMounted()) {
        setLoadingIndicativePriceData(true);
      }
      try {
        let latestIndicativePrice;
        if (isPublic) {
          latestIndicativePrice = (
            await IndicativePriceFetcher.PublicFetchLatestIndicativePrice({
              assetIdentifier: TokenIdentifier(
                props.marketListingViewModel.token,
              ),
            })
          ).publicIndicativePrice;
        } else {
          latestIndicativePrice = (
            await IndicativePriceRepository.SearchIndicativePrice({
              context: authContext,
              criteria: {
                "token.code": TextExactCriterion(
                  props.marketListingViewModel.token.code,
                ),
                "token.issuer": TextExactCriterion(
                  props.marketListingViewModel.token.issuer,
                ),
                "token.network": TextExactCriterion(
                  props.marketListingViewModel.token.network,
                ),
              },
              query: new Query({
                limit: 1,
                offset: 0,
                sorting: [NewSorting("timeOfPrice", "desc")],
              }),
            })
          ).records[0];
        }

        // confirm at least 1 record was retrieved
        if (!latestIndicativePrice) {
          console.error(
            "expected at least 1 indicative price to exist for asset",
          );
          return;
        }

        // retrieve and set a model for the token in which the price is given
        const retrievedPriceTokenViewModel = await getLedgerTokenViewModel(
          latestIndicativePrice.buyPrice.token,
        );
        if (isMounted()) {
          setPriceTokenViewModel(retrievedPriceTokenViewModel);
        }

        // set indicative price
        if (isMounted()) {
          setIndicativePrice(latestIndicativePrice);
          setLoadingIndicativePriceData(false);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error loading indicative price information: ${
            err.message ? err.message : err.toString()
          }`,
        );
        // intentionally not stopping loading - errors will result in card seeming to load forever
      }
    })();
  }, [isMounted, authContext, props.marketListingViewModel.token]);

  // determine what direct order permissions this user may have
  const [canPushBuy, setCanPushBuy] = useState(false);
  const [canPushSell, setCanPushSell] = useState(false);
  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    (async () => {
      try {
        await Promise.all([
          // determine value of canPushBuy
          (async () => {
            if (
              props.marketListingViewModel instanceof
              PublicMarketListingViewModel
            ) {
              return;
            }

            // user can push the buy button if
            const canPushBuyResult =
              // their view configuration says they can push buy as an investor
              directOrderTradingViewConfig.InvestorPushBuy || // OR
              // they have the IssuerSubmitDirectBuyOrder permission in the group that owns the asset
              (
                await ScopeDeterminer.DetermineScopeAuthorisationByRoles({
                  context: authContext,
                  groupID: props.marketListingViewModel.assetOwnerID,
                  buildScopeTree: false,
                  service: new Permission({
                    serviceName: "IssuerSubmitDirectBuyOrder",
                    serviceProvider: DirectOrderSubmitterServiceProviderName,
                    description: "?",
                  }),
                })
              ).authorized;
            if (isMounted()) {
              setCanPushBuy(canPushBuyResult);
            }
          })(),

          // determine value of canPushSell
          (async () => {
            if (
              props.marketListingViewModel instanceof
              PublicMarketListingViewModel
            ) {
              return;
            }

            // user can push the sell button if
            const canPushSellResult =
              // their view configuration says they can push sell as an investor
              directOrderTradingViewConfig.InvestorPushSell || // OR
              // they have the IssuerSubmitDirectSellOrder permission in the group that owns the asset
              (
                await ScopeDeterminer.DetermineScopeAuthorisationByRoles({
                  context: authContext,
                  groupID: props.marketListingViewModel.assetOwnerID,
                  buildScopeTree: false,
                  service: new Permission({
                    serviceName: "IssuerSubmitDirectSellOrder",
                    serviceProvider: DirectOrderSubmitterServiceProviderName,
                    description: "?",
                  }),
                })
              ).authorized;
            if (isMounted()) {
              setCanPushSell(canPushSellResult);
            }
          })(),
        ]);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining what permissions user has wrt. order buttons: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    })();
  }, [isMounted, authContext, directOrderTradingViewConfig]);

  const [directOrderType, setDirectOrderType] = useState<
    DirectOrderType | undefined
  >(undefined);
  const handleBuy = () => {
    if (isPublic) {
      navigate(
        `/market/asset-overview?code=${props.marketListingViewModel.token.code}&issuer=${props.marketListingViewModel.token.issuer}&network=${props.marketListingViewModel.token.network}&order=${DirectOrderType.Buy}`,
      );
      return;
    }

    if (!canPushBuy) {
      return;
    }
    if (
      props.marketListingViewModel.privateOffer &&
      !showPrivatePlacementDialog
    ) {
      setShowPrivatePlacementDialog(true);
      return;
    }
    if (myClientKYCStatus !== ClientKYCStatus.VerifiedStatus) {
      enqueueSnackbar("Want to Buy? You need to be verified", {
        preventDuplicate: true,
        content: (key, message) => (
          <WarningWithActions
            id={key}
            message={message}
            inLine={!isMobile}
            actions={
              <Button
                id="notVerifiedBuyDirectOrder-submitKYCInfo-button"
                onClick={() => {
                  navigate("/kyc");
                }}
                sx={(theme) => ({
                  color: theme.palette.secondary.contrastText,
                  borderColor: theme.palette.secondary.contrastText,
                  border: "1px solid",
                  width: isMobile ? "140px" : "200px",
                  mr: { sm: 2 },
                })}
                variant="outlined"
              >
                Submit info
              </Button>
            }
          />
        ),
      });
      return;
    }

    LogRocket.track(AssetEvent.openDirectOrder, {
      assetName: props.marketListingViewModel.assetName,
      assetShortName: props.marketListingViewModel.assetShortName,
      assetType: props.marketListingViewModel.assetType,
    });
    setDirectOrderType(DirectOrderType.Buy);
  };
  const handleSell = () => {
    if (isPublic) {
      navigate(
        `/market/asset-overview?code=${props.marketListingViewModel.token.code}&issuer=${props.marketListingViewModel.token.issuer}&network=${props.marketListingViewModel.token.network}&order=${DirectOrderType.Sell}`,
      );
      return;
    }

    if (!canPushSell) {
      return;
    }

    if (myClientKYCStatus !== ClientKYCStatus.VerifiedStatus) {
      enqueueSnackbar("Want to Sell? You need to be verified", {
        preventDuplicate: true,
        content: (key, message) => (
          <WarningWithActions
            id={key}
            message={message}
            inLine={!isMobile}
            actions={
              <Button
                id="notVerifiedSellDirectOrder-submitKYCInfo-button"
                onClick={() => {
                  navigate("/kyc");
                }}
                sx={(theme) => ({
                  color: theme.palette.secondary.contrastText,
                  borderColor: theme.palette.secondary.contrastText,
                  border: "1px solid",
                  width: isMobile ? "140px" : "200px",
                  mr: { sm: 2 },
                })}
                variant="outlined"
              >
                Submit info
              </Button>
            }
          />
        ),
      });
      return;
    }

    LogRocket.track(AssetEvent.openDirectOrder, {
      assetName: props.marketListingViewModel.assetName,
      assetShortName: props.marketListingViewModel.assetShortName,
      assetType: props.marketListingViewModel.assetType,
    });
    setDirectOrderType(DirectOrderType.Sell);
  };

  // check if buy or sell dialogs should open as a result of a redirect
  useEffect(() => {
    const assetToken = new Token({
      code: searchParams.get("code") ?? "",
      issuer: searchParams.get("issuer") ?? "",
      network: (searchParams.get("network") ?? "-") as LedgerNetwork,
    });
    if (
      assetToken.isEqualTo(props.marketListingViewModel.token) &&
      searchParams.get("order") === DirectOrderType.Buy &&
      !isPublic &&
      (directOrderTradingViewConfig.InvestorPushBuy ||
        directOrderTradingViewConfig.IssuerPushBuy)
    ) {
      if (myClientKYCStatus === ClientKYCStatus.VerifiedStatus) {
        setDirectOrderType(DirectOrderType.Buy);
      } else {
        searchParams.delete("order");
        setSearchParams(searchParams, { replace: true });
        enqueueSnackbar("A Verified KYC Status is Required to Buy", {
          variant: "warning",
        });
      }
    }
  }, [props.marketListingViewModel, myClientKYCStatus, enqueueSnackbar]);
  useEffect(() => {
    const assetToken = new Token({
      code: searchParams.get("code") ?? "",
      issuer: searchParams.get("issuer") ?? "",
      network: (searchParams.get("network") ?? "-") as LedgerNetwork,
    });
    if (
      assetToken.isEqualTo(props.marketListingViewModel.token) &&
      searchParams.get("order") === DirectOrderType.Sell &&
      !isPublic &&
      (directOrderTradingViewConfig.InvestorPushSell ||
        directOrderTradingViewConfig.IssuerPushSell)
    ) {
      if (myClientKYCStatus === ClientKYCStatus.VerifiedStatus) {
        setDirectOrderType(DirectOrderType.Sell);
      } else {
        searchParams.delete("order");
        setSearchParams(searchParams, { replace: true });
        enqueueSnackbar("A Verified KYC Status is Required to Sell", {
          variant: "warning",
        });
      }
    }
  }, [props.marketListingViewModel, myClientKYCStatus, enqueueSnackbar]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* BUY section */}
        <Box
          sx={{
            width: "160px",
            marginRight: theme.spacing(2),
            [theme.breakpoints.down("sm")]: {
              width: "calc(100% / 2)",
            },
          }}
        >
          <Tooltip
            title={
              canPushBuy || isPublic
                ? ""
                : "You do not have the required permissions"
            }
            placement={"top"}
          >
            <Box>
              <Button
                id={`orderSection-Buy-button-${props.marketListingViewModel.assetShortName}`}
                fullWidth
                sx={{
                  backgroundColor: theme.palette.custom.midnight,
                  color: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.text.primary,
                  },
                }}
                variant={"contained"}
                children={"buy"}
                disabled={!(canPushBuy || isPublic)}
                onClick={handleBuy}
                data-link-info={JSON.stringify({
                  content_interaction_id: "transact-cta",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Button,
                  content_interaction_text: "buy",
                  content_interaction_driver:
                    InteractionDriver.DriveTransaction,
                } as DataLinkInfoType)}
              />
            </Box>
          </Tooltip>

          <Box
            sx={{
              border: `1px solid ${theme.palette.text.disabled}`,
              borderBottomLeftRadius: theme.spacing(0.5),
              borderBottomRightRadius: theme.spacing(0.5),
              borderTop: "none",
              padding: theme.spacing(1),
              textAlign: "center",
            }}
          >
            {loadingIndicativePriceData ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  margin: `-6px 0px -3px 0px`,
                }}
              >
                <Skeleton width={100} height={30} />
              </Box>
            ) : (
              <>
                <Tooltip
                  title={`Issued by ${priceTokenViewModel.issuer}`}
                  placement={"top"}
                >
                  <Typography
                    id={`orderSection-BuyPriceCode-typography-${props.marketListingViewModel.assetShortName}`}
                    display={"inline"}
                    sx={{
                      color: theme.palette.text.secondary,
                      marginRight: theme.spacing(0.5),
                      textTransform: "none",
                      cursor: "pointer",
                      "&:hover": {
                        color: theme.palette.primary.light,
                      },
                    }}
                    variant={"button"}
                    children={priceTokenViewModel.token.code}
                  />
                </Tooltip>
                <Typography
                  id={`orderSection-BuyPrice-typography-${props.marketListingViewModel.assetName}`}
                  display={"inline"}
                  variant={"button"}
                  children={formatTextNum(indicativePrice.buyPrice.value, {
                    addDecimalPadding: true,
                  })}
                />
              </>
            )}
          </Box>
        </Box>
        {/* SELL section */}
        <Box
          sx={{
            width: "160px",
            [theme.breakpoints.down("sm")]: {
              width: "calc(100% / 2)",
            },
          }}
        >
          <Tooltip
            title={
              canPushSell || isPublic
                ? ""
                : "You do not have the required permissions"
            }
            placement={"top"}
          >
            <Box>
              <Button
                id={`orderSection-Sell-button-${props.marketListingViewModel.assetShortName}`}
                fullWidth
                variant={"contained"}
                children={"sell"}
                disabled={!(canPushSell || isPublic)}
                onClick={handleSell}
                sx={{
                  backgroundColor: theme.palette.custom.midnight,
                  color: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.text.primary,
                  },
                }}
                data-link-info={JSON.stringify({
                  content_interaction_id: "transact-cta",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Button,
                  content_interaction_text: "sell",
                  content_interaction_driver:
                    InteractionDriver.DriveTransaction,
                } as DataLinkInfoType)}
              />
            </Box>
          </Tooltip>

          <Box
            sx={{
              border: `1px solid ${theme.palette.text.disabled}`,
              borderBottomLeftRadius: theme.spacing(0.5),
              borderBottomRightRadius: theme.spacing(0.5),
              borderTop: "none",
              padding: theme.spacing(1),
              textAlign: "center",
            }}
          >
            {loadingIndicativePriceData ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  margin: `-6px 0px -3px 0px`,
                }}
              >
                <Skeleton width={100} height={30} />
              </Box>
            ) : (
              <>
                <Tooltip
                  title={`Issued by ${priceTokenViewModel.issuer}`}
                  placement={"top"}
                >
                  <Typography
                    id={`orderSection-SellPriceCode-typography-${props.marketListingViewModel.assetName}`}
                    display={"inline"}
                    sx={{
                      color: theme.palette.text.secondary,
                      marginRight: theme.spacing(0.5),
                      textTransform: "none",
                      cursor: "pointer",
                      "&:hover": {
                        color: theme.palette.primary.light,
                      },
                    }}
                    variant={"button"}
                    children={priceTokenViewModel.token.code}
                  />
                </Tooltip>
                <Typography
                  id={`orderSection-SellPrice-typography-${props.marketListingViewModel.assetName}`}
                  display={"inline"}
                  variant={"button"}
                  children={formatTextNum(indicativePrice.sellPrice.value, {
                    addDecimalPadding: true,
                  })}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
      {directOrderType &&
        props.marketListingViewModel instanceof MarketListingViewModel &&
        indicativePrice instanceof IndicativePrice && (
          <PlaceOrdersDialog
            closeDialog={() => {
              if (searchParams.get("order")) {
                searchParams.delete("order");
                setSearchParams(searchParams, { replace: true });
              }
              setDirectOrderType(undefined);
            }}
            directOrderType={directOrderType as DirectOrderType}
            marketListingViewModel={props.marketListingViewModel}
            indicativePrice={indicativePrice}
          />
        )}
      <PrivatePlacementDialog
        dialogProps={{ open: showPrivatePlacementDialog }}
        closeDialog={() => setShowPrivatePlacementDialog(false)}
        openNextDialog={() => {
          handleBuy();
        }}
      />
    </div>
  );
};
