import React, { useState } from "react";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { TextField } from "components/FormFields";
import {
  AccountBalance,
  EditOutlined,
  MoreVert,
  Visibility,
} from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";
import {
  LinkedBankAccountDialog,
  LinkedBankAccountMode,
} from "views/Accounts/components/LinkedBankAccountDialog/LinkedBankAccountDialog";
import { BankAccount } from "@mesh/common-js/src/banking/bankAccount_pb";
import { friendlyName } from "james/banking/bankAccount";

interface LinkedBankAccountCardProps {
  bankAccount: BankAccount;
}

export function LinkedBankAccountCard(props: LinkedBankAccountCardProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const [viewBankAccountDetailsDialog, setViewBankAccountDetailsDialog] =
    useState(false);
  const [editBankAccountDetailsDialog, setEditBankAccountDetailsDialog] =
    useState(false);
  const { viewConfiguration } = useApplicationContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 38;

  return (
    <div>
      {!isMobile && (
        <Card
          sx={(theme) => ({
            maxWidth: "100%",
            maxHeight: 318,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
          })}
        >
          <Grid alignItems="center" justifyContent="space-between" container>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ mr: 2 }}>
                  <AccountBalance />
                </Box>
                <TextField
                  inputProps={{ "data-private": "lipsum" }}
                  sx={{
                    mr: 3,
                    minWidth: "180px",
                  }}
                  readOnly
                  fullWidth
                  value={friendlyName(props.bankAccount.getBankname())}
                  label="Bank Name"
                  variant="outlined"
                />
                <TextField
                  inputProps={{ "data-private": "lipsum" }}
                  readOnly
                  fullWidth
                  value={props.bankAccount.getNumber()}
                  label="Account Number"
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item>
              {viewConfiguration.BankAccountViewer && (
                <IconButton
                  sx={{ mr: 1 }}
                  size="small"
                  id="linkedBankAccountCard-viewAccount-button"
                  onClick={() =>
                    setViewBankAccountDetailsDialog(
                      !viewBankAccountDetailsDialog,
                    )
                  }
                >
                  <Visibility />
                </IconButton>
              )}
              {viewConfiguration.BankAccountUpdater && (
                <IconButton
                  size="small"
                  id="linkedBankAccountCard-editAccount-button"
                  onClick={() =>
                    setEditBankAccountDetailsDialog(
                      !editBankAccountDetailsDialog,
                    )
                  }
                >
                  <EditOutlined />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Card>
      )}
      {isMobile && (
        <Box
          sx={(theme) => ({
            display: "grid",
            position: "relative",
            gridTemplateColumns: "47px auto",
            borderRadius: "10px",
            maxWidth: 721,
            maxHeight: 188,
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2, 3, 2, 3),
            marginBottom: theme.spacing(2),
          })}
        >
          <Box sx={(theme) => ({ margin: theme.spacing(1, 1, 1, 0) })}>
            <AccountBalance />
          </Box>
          <Box>
            <Grid
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
                marginBottom: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
              })}
              container
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
                item
                xs={12}
              >
                <TextField
                  readOnly
                  fullWidth
                  value={props.bankAccount.getBankname()}
                  label="Bank Name"
                  variant="outlined"
                />
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  item
                  xs={3}
                >
                  <IconButton
                    id="accountOptions-actions-smButton"
                    size="small"
                    disabled={
                      !(
                        viewConfiguration.BankAccountViewer ||
                        viewConfiguration.BankAccountUpdater
                      )
                    }
                    onClick={handleClick}
                  >
                    <MoreVert />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <TextField
                readOnly
                fullWidth
                value={props.bankAccount.getNumber()}
                label="Account Number"
                variant="outlined"
              />
            </Grid>
          </Box>
          <Menu
            id="accountOptions-actionButton-menu"
            MenuListProps={{
              "aria-labelledby": "accountOptions-actions-smButton",
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                maxHeight: ITEM_HEIGHT * 4,
                width: "12ch",
                alignSelf: "Center",
                transform: "translateX(-16.5%) translateY(-13%)",
              },
            }}
            autoFocus={false}
          >
            {viewConfiguration.BankAccountViewer && (
              <MenuItem
                key={1}
                id="accountOptions-viewAccountOptions-menuItem"
                onClick={() => {
                  setViewBankAccountDetailsDialog(
                    !viewBankAccountDetailsDialog,
                  );
                  handleClose();
                }}
              >
                View
              </MenuItem>
            )}
            {viewConfiguration.BankAccountUpdater && (
              <MenuItem
                key={2}
                id="accountOptions-editAccountOptions-menuItem"
                onClick={() => {
                  setEditBankAccountDetailsDialog(
                    !editBankAccountDetailsDialog,
                  );
                  handleClose();
                }}
              >
                Edit
              </MenuItem>
            )}
          </Menu>
        </Box>
      )}
      {viewBankAccountDetailsDialog && (
        <LinkedBankAccountDialog
          mode={LinkedBankAccountMode.View}
          onClose={() =>
            setViewBankAccountDetailsDialog(!viewBankAccountDetailsDialog)
          }
          open={viewBankAccountDetailsDialog}
          bankAccount={props.bankAccount}
        />
      )}
      {editBankAccountDetailsDialog && (
        <LinkedBankAccountDialog
          mode={LinkedBankAccountMode.Edit}
          onClose={() =>
            setEditBankAccountDetailsDialog(!editBankAccountDetailsDialog)
          }
          open={editBankAccountDetailsDialog}
          bankAccount={props.bankAccount}
        />
      )}
    </div>
  );
}
