// source: banking/bankAccountReader_meshproto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var search_sorting_pb = require('../search/sorting_pb.js');
goog.object.extend(proto, search_sorting_pb);
var banking_bankAccount_pb = require('../banking/bankAccount_pb.js');
goog.object.extend(proto, banking_bankAccount_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
var banking_fundingOrderState_pb = require('../banking/fundingOrderState_pb.js');
goog.object.extend(proto, banking_fundingOrderState_pb);
goog.exportSymbol('proto.banking.ReadAllBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.ReadAllBankAccountResponse', null, global);
goog.exportSymbol('proto.banking.ReadManyBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.ReadManyBankAccountResponse', null, global);
goog.exportSymbol('proto.banking.ReadOneBankAccountRequest', null, global);
goog.exportSymbol('proto.banking.ReadOneBankAccountResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadOneBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadOneBankAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadOneBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadOneBankAccountRequest.displayName = 'proto.banking.ReadOneBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadOneBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.ReadOneBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadOneBankAccountResponse.displayName = 'proto.banking.ReadOneBankAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadManyBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadManyBankAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadManyBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadManyBankAccountRequest.displayName = 'proto.banking.ReadManyBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadManyBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadManyBankAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadManyBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadManyBankAccountResponse.displayName = 'proto.banking.ReadManyBankAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadAllBankAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadAllBankAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadAllBankAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadAllBankAccountRequest.displayName = 'proto.banking.ReadAllBankAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadAllBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadAllBankAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadAllBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadAllBankAccountResponse.displayName = 'proto.banking.ReadAllBankAccountResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadOneBankAccountRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadOneBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadOneBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadOneBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadOneBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadOneBankAccountRequest}
 */
proto.banking.ReadOneBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadOneBankAccountRequest;
  return proto.banking.ReadOneBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadOneBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadOneBankAccountRequest}
 */
proto.banking.ReadOneBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadOneBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadOneBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadOneBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadOneBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.ReadOneBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.ReadOneBankAccountRequest} returns this
*/
proto.banking.ReadOneBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadOneBankAccountRequest} returns this
 */
proto.banking.ReadOneBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadOneBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.ReadOneBankAccountRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.ReadOneBankAccountRequest} returns this
*/
proto.banking.ReadOneBankAccountRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.ReadOneBankAccountRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadOneBankAccountRequest} returns this
 */
proto.banking.ReadOneBankAccountRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadOneBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadOneBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadOneBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadOneBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadOneBankAccountResponse}
 */
proto.banking.ReadOneBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadOneBankAccountResponse;
  return proto.banking.ReadOneBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadOneBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadOneBankAccountResponse}
 */
proto.banking.ReadOneBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadOneBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadOneBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadOneBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadOneBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount bankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.ReadOneBankAccountResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.ReadOneBankAccountResponse} returns this
*/
proto.banking.ReadOneBankAccountResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadOneBankAccountResponse} returns this
 */
proto.banking.ReadOneBankAccountResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadOneBankAccountResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadManyBankAccountRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadManyBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadManyBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadManyBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadManyBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
query: (f = msg.getQuery()) && search_query_pb.Query.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadManyBankAccountRequest}
 */
proto.banking.ReadManyBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadManyBankAccountRequest;
  return proto.banking.ReadManyBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadManyBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadManyBankAccountRequest}
 */
proto.banking.ReadManyBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_query_pb.Query;
      reader.readMessage(value,search_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadManyBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadManyBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadManyBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadManyBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      search_query_pb.Query.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.ReadManyBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.ReadManyBankAccountRequest} returns this
*/
proto.banking.ReadManyBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadManyBankAccountRequest} returns this
 */
proto.banking.ReadManyBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadManyBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.ReadManyBankAccountRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.ReadManyBankAccountRequest} returns this
*/
proto.banking.ReadManyBankAccountRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.ReadManyBankAccountRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadManyBankAccountRequest} returns this
 */
proto.banking.ReadManyBankAccountRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional search.Query query = 3;
 * @return {?proto.search.Query}
 */
proto.banking.ReadManyBankAccountRequest.prototype.getQuery = function() {
  return /** @type{?proto.search.Query} */ (
    jspb.Message.getWrapperField(this, search_query_pb.Query, 3));
};


/**
 * @param {?proto.search.Query|undefined} value
 * @return {!proto.banking.ReadManyBankAccountRequest} returns this
*/
proto.banking.ReadManyBankAccountRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadManyBankAccountRequest} returns this
 */
proto.banking.ReadManyBankAccountRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadManyBankAccountRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadManyBankAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadManyBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadManyBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadManyBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadManyBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    banking_bankAccount_pb.BankAccount.toObject, includeInstance),
total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadManyBankAccountResponse}
 */
proto.banking.ReadManyBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadManyBankAccountResponse;
  return proto.banking.ReadManyBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadManyBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadManyBankAccountResponse}
 */
proto.banking.ReadManyBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadManyBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadManyBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadManyBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadManyBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated BankAccount records = 1;
 * @return {!Array<!proto.banking.BankAccount>}
 */
proto.banking.ReadManyBankAccountResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.banking.BankAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {!Array<!proto.banking.BankAccount>} value
 * @return {!proto.banking.ReadManyBankAccountResponse} returns this
*/
proto.banking.ReadManyBankAccountResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.banking.BankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.banking.BankAccount}
 */
proto.banking.ReadManyBankAccountResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.banking.BankAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadManyBankAccountResponse} returns this
 */
proto.banking.ReadManyBankAccountResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.banking.ReadManyBankAccountResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.banking.ReadManyBankAccountResponse} returns this
 */
proto.banking.ReadManyBankAccountResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadAllBankAccountRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadAllBankAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadAllBankAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadAllBankAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadAllBankAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
sortingList: jspb.Message.toObjectList(msg.getSortingList(),
    search_sorting_pb.Sorting.toObject, includeInstance),
batchsize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadAllBankAccountRequest}
 */
proto.banking.ReadAllBankAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadAllBankAccountRequest;
  return proto.banking.ReadAllBankAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadAllBankAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadAllBankAccountRequest}
 */
proto.banking.ReadAllBankAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_sorting_pb.Sorting;
      reader.readMessage(value,search_sorting_pb.Sorting.deserializeBinaryFromReader);
      msg.addSorting(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBatchsize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadAllBankAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadAllBankAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadAllBankAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadAllBankAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getSortingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      search_sorting_pb.Sorting.serializeBinaryToWriter
    );
  }
  f = message.getBatchsize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.ReadAllBankAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.ReadAllBankAccountRequest} returns this
*/
proto.banking.ReadAllBankAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadAllBankAccountRequest} returns this
 */
proto.banking.ReadAllBankAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadAllBankAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.ReadAllBankAccountRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.ReadAllBankAccountRequest} returns this
*/
proto.banking.ReadAllBankAccountRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.ReadAllBankAccountRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadAllBankAccountRequest} returns this
 */
proto.banking.ReadAllBankAccountRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * repeated search.Sorting sorting = 3;
 * @return {!Array<!proto.search.Sorting>}
 */
proto.banking.ReadAllBankAccountRequest.prototype.getSortingList = function() {
  return /** @type{!Array<!proto.search.Sorting>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_sorting_pb.Sorting, 3));
};


/**
 * @param {!Array<!proto.search.Sorting>} value
 * @return {!proto.banking.ReadAllBankAccountRequest} returns this
*/
proto.banking.ReadAllBankAccountRequest.prototype.setSortingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.search.Sorting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Sorting}
 */
proto.banking.ReadAllBankAccountRequest.prototype.addSorting = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.search.Sorting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadAllBankAccountRequest} returns this
 */
proto.banking.ReadAllBankAccountRequest.prototype.clearSortingList = function() {
  return this.setSortingList([]);
};


/**
 * optional uint64 batchSize = 4;
 * @return {number}
 */
proto.banking.ReadAllBankAccountRequest.prototype.getBatchsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.banking.ReadAllBankAccountRequest} returns this
 */
proto.banking.ReadAllBankAccountRequest.prototype.setBatchsize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadAllBankAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadAllBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadAllBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadAllBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadAllBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    banking_bankAccount_pb.BankAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadAllBankAccountResponse}
 */
proto.banking.ReadAllBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadAllBankAccountResponse;
  return proto.banking.ReadAllBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadAllBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadAllBankAccountResponse}
 */
proto.banking.ReadAllBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadAllBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadAllBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadAllBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadAllBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BankAccount records = 1;
 * @return {!Array<!proto.banking.BankAccount>}
 */
proto.banking.ReadAllBankAccountResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.banking.BankAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {!Array<!proto.banking.BankAccount>} value
 * @return {!proto.banking.ReadAllBankAccountResponse} returns this
*/
proto.banking.ReadAllBankAccountResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.banking.BankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.banking.BankAccount}
 */
proto.banking.ReadAllBankAccountResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.banking.BankAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadAllBankAccountResponse} returns this
 */
proto.banking.ReadAllBankAccountResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


goog.object.extend(exports, proto.banking);
