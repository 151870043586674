import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { ValidationResult } from "common/validation";
import {
  Listing,
  Mechanism,
  MechanismType,
  QuoteParameter,
} from "james/market";
import { Model as LedgerTokenViewModel } from "../../../../../james/views/ledgerTokenView";
import { FutureToken } from "@mesh/common-js/dist/ledger/futureToken_pb";
import { Token } from "james/ledger";
import { BigNumber } from "bignumber.js";
import {
  validateDirectOrderMarketMechanism,
  validateSpotMarketMechanism,
  validateSubscriptionMarketMechanism,
  validateLimitOrderMarketMechanism,
} from "./components";

export type FormData = {
  ledgerTokenViewModels: LedgerTokenViewModel[];
  listing: Listing;
  listingCopy: Listing;
  smartInstrumentToList: SmartInstrument;
};

export type FormUpdaterSpecsType = {
  ledgerTokenViewModels: (
    ledgerTokenViewModels: LedgerTokenViewModel[],
    prevFormData?: FormData,
  ) => FormData;
  listing: (listing: Listing, prevFormData?: FormData) => FormData;
  smartInstrumentToList: (
    smartInstrument: SmartInstrument,
    prevFormData?: FormData,
  ) => FormData;
  addMarketMechanism: (
    mechanismType: MechanismType,
    prevFormData?: FormData,
  ) => FormData;
  removeMarketMechanism: (
    mechanismType: MechanismType | "",
    prevFormData?: FormData,
  ) => FormData;
  updateMarketMechanism: (
    mechanism: Mechanism,
    prevFormData?: FormData,
  ) => FormData;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  ledgerTokenViewModels(
    ledgerTokenViewModels: LedgerTokenViewModel[],
    prevFormData?: FormData,
  ) {
    const formData = prevFormData as FormData;
    return {
      ...formData,
      ledgerTokenViewModels,
    };
  },
  listing(listing: Listing, prevFormData?: FormData) {
    const formData = prevFormData as FormData;
    return {
      ...formData,
      listing: new Listing(listing),
      listingCopy: new Listing(listing),
    };
  },
  smartInstrumentToList(
    smartInstrument: SmartInstrument,
    prevFormData?: FormData,
  ): FormData {
    const formData = prevFormData as FormData;
    return {
      ...formData,
      smartInstrumentToList: smartInstrument,
    };
  },
  addMarketMechanism(
    mechanismType: MechanismType,
    prevFormData?: FormData,
  ): FormData {
    const formData = prevFormData as FormData;

    // confirm at least 1 ledger token view model available
    if (!formData.ledgerTokenViewModels.length) {
      console.error(
        "no ledger token view models available to initialise market mechanism",
      );
      return formData;
    }

    // get a default first view model
    let listingQuoteAssetTokenViewModel = formData.ledgerTokenViewModels.find(
      (v) => v.token.code === "mZAR",
    );
    if (!listingQuoteAssetTokenViewModel) {
      listingQuoteAssetTokenViewModel = formData.ledgerTokenViewModels[0];
    }

    // prepare new market mechanism
    const newMechanism = new Mechanism();
    newMechanism.type = mechanismType;

    // initialise mechanism
    newMechanism.quoteParameters = [
      new QuoteParameter({
        quoteToken: listingQuoteAssetTokenViewModel.token,
        minimumDealSize: Token.fromFutureToken(
          formData.smartInstrumentToList.getToken() ?? new FutureToken(),
        ).newAmountOf(new BigNumber("1")),
        maximumDealSize: Token.fromFutureToken(
          formData.smartInstrumentToList.getToken() ?? new FutureToken(),
        ).newAmountOf(new BigNumber("100000")),
      }),
    ];

    // add mechanism to listing
    formData.listing.marketMechanisms.push(newMechanism);

    return {
      ...formData,
      listing: formData.listing,
    };
  },
  removeMarketMechanism(
    mechanismType: MechanismType | "",
    prevFormData?: FormData,
  ): FormData {
    const formData = prevFormData as FormData;

    formData.listing.marketMechanisms =
      formData.listing.marketMechanisms.filter(
        (mm) => mm.type !== mechanismType,
      );

    return {
      ...formData,
      listing: formData.listing,
    };
  },
  updateMarketMechanism(
    mechanism: Mechanism,
    prevFormData?: FormData,
  ): FormData {
    const formData = prevFormData as FormData;

    const existingMechanismIdx = formData.listing.marketMechanisms.findIndex(
      (m) => m.type === mechanism.type,
    );
    if (existingMechanismIdx === -1) {
      return formData;
    }

    formData.listing.marketMechanisms[existingMechanismIdx] = mechanism;

    return {
      ...formData,
      listing: formData.listing,
    };
  },
};

export const formDataValidationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // at least 1 market mechanism required
  if (formData.listing.marketMechanisms.length == 0) {
    validationResult.valid = false;
    validationResult.fieldValidations.marketMechanisms =
      "At least 1 Market Mechanism Required";
  }

  // each market mechanism should be valid
  formData.listing.marketMechanisms.forEach((mm) => {
    // prepare validation result
    let mmValidationResult: ValidationResult;

    // validate by market mechanism type
    switch (mm.type) {
      case MechanismType.DirectOrder:
        mmValidationResult = validateDirectOrderMarketMechanism(mm);
        break;

      case MechanismType.Spot:
        mmValidationResult = validateSpotMarketMechanism(mm);
        break;

      case MechanismType.Subscription:
        mmValidationResult = validateSubscriptionMarketMechanism(mm);
        break;

      case MechanismType.LimitOrder:
        mmValidationResult = validateLimitOrderMarketMechanism(mm);
        break;

      case "":
      default:
        console.error("invalid market mechanism type");
        return;
    }

    // merge validation result
    validationResult.valid = mmValidationResult.valid && validationResult.valid;
    for (const field in mmValidationResult.fieldValidations) {
      validationResult.fieldValidations[field] =
        mmValidationResult.fieldValidations[field];
    }
  });

  return validationResult;
};
