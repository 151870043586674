import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { DefundOrder } from "@mesh/common-js/src/banking/defundOrder_pb";
import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import {
  ResolveDefundOrderStateRequest,
  SettleDefundOrderRequest,
  SubmitDefundOrderRequest,
  SubmitDefundOrderResponse,
} from "@mesh/common-js/src/banking/defundOrderStateController_pb";
import { ReadManyRequest } from "@mesh/common-js/src/views/bankingDefundOrderView/reader_pb";
import { Model } from "@mesh/common-js/src/views/bankingDefundOrderView/model_pb";

export type DefundingContextType = {
  apiCallInProgress: boolean;

  deFundingOrder: DefundOrder;
  setDeFundingOrder: (deFundingOrder: DefundOrder) => void;

  deFundingOrders: Model[];
  deFundingOrdersLoaded: boolean;
  deFundingOrdersLoadError: string | null;
  clearDeFundOrdersLoadError: () => void;
  reloadDeFundOrders: () => void;

  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;

  submitDeFundOrder: (
    deFundingOrder: DefundOrder,
  ) => Promise<SubmitDefundOrderResponse>;
  settleDeFundOrder: (
    deFundingOrderID: string,
    bankReference: string,
  ) => Promise<void>;
  resolveDeFundOrder: (deFundingOrderID: string) => Promise<void>;
};

export const defaultContext: DefundingContextType = {
  apiCallInProgress: false,
  deFundingOrdersLoaded: false,
  deFundingOrdersLoadError: null,
  clearDeFundOrdersLoadError: () => null,
  reloadDeFundOrders: () => null,

  initialised: false,
  initialisationError: null,
  clearInitialisationError: () => null,

  deFundingOrder: new DefundOrder(),
  deFundingOrders: [],
  setDeFundingOrder: () => Promise<void>,

  submitDeFundOrder: () => new Promise(() => null),
  settleDeFundOrder: () => new Promise(() => null),
  resolveDeFundOrder: () => new Promise(() => null),
};

export const DeFundingContext =
  createContext<DefundingContextType>(defaultContext);

export const useDeFundingContext = () => useContext(DeFundingContext);

export const DeFundingContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    errorContextDefaultWarningFeedback,
    errorContextDefaultErrorFeedback,
  } = useErrorContext();

  const {
    banking: { defundOrderViewReader, defundOrderStateController },
  } = useAPIContext();
  const { authContext } = useApplicationContext();

  const clearInitialisationError = () =>
    useCallback(() => {
      setDeFundingOrdersLoadError(null);
    }, [deFundingOrdersLoadError]);

  const [deFundingOrdersLoadError, setDeFundingOrdersLoadError] =
    React.useState<string | null>(null);
  const [
    fetchingDeFundingOrdersInProgress,
    setFetchingDeFundingOrderInProgress,
  ] = useState<boolean>(false);
  const [settleDeFundingOrderInProgress, setSettleDeFundingOrderInProgress] =
    useState<boolean>(false);
  const [submitDeFundingOrderInProgress, setSubmitDeFundingOrderInProgress] =
    useState<boolean>(false);

  const [deFundingOrder, setDeFundingOrder] = React.useState<DefundOrder>(
    new DefundOrder(),
  );
  const [deFundingOrders, setDeFundingOrders] = React.useState<Model[]>([]);
  const [deFundingOrdersLoaded, setDeFundingOrdersLoaded] =
    useState<boolean>(false);
  const [resolveDeFundingOrderInProgress, setResolveDeFundingOrderInProgress] =
    useState<boolean>(false);
  const reloadDeFundingOrders = () => setDeFundingOrdersLoaded(false);
  const clearDeFundingOrdersLoadError = () => setDeFundingOrdersLoadError(null);

  useEffect(() => {
    (async () => {
      if (deFundingOrdersLoaded || deFundingOrdersLoadError) {
        return;
      }

      setFetchingDeFundingOrderInProgress(true);
      let defundOrders: Model[] = [];
      try {
        defundOrders = (
          await defundOrderViewReader.readMany(
            new ReadManyRequest()
              .setContext(authContext.toFuture())
              .setCriteriaList([]),
          )
        ).getModelsList();
      } catch (e) {
        setDeFundingOrdersLoadError("Failed to fetch defund orders");
        setFetchingDeFundingOrderInProgress(false);
        return;
      }

      setFetchingDeFundingOrderInProgress(false);
      setDeFundingOrders(defundOrders);
      setDeFundingOrdersLoaded(true);
    })();
  }, [deFundingOrdersLoaded, deFundingOrdersLoadError]);

  const settleDeFundingOrder = async (
    deFundingOrderID: string,
    bankReference: string,
  ) => {
    setSettleDeFundingOrderInProgress(true);
    try {
      await defundOrderStateController.settleDefundOrder(
        new SettleDefundOrderRequest()
          .setContext(authContext.toFuture())
          .setDefundorderid(deFundingOrderID)
          .setBankreference(bankReference),
      );
      enqueueSnackbar("DeFunding order Approved", { variant: "success" });
    } catch (e) {
      errorContextDefaultWarningFeedback(e, "Failed to settle defunding order");
    }
    setSettleDeFundingOrderInProgress(false);
  };

  const resolveDeFundingOrder = async (deFundingOrderID: string) => {
    setResolveDeFundingOrderInProgress(true);
    try {
      await defundOrderStateController.resolveDefundOrderState(
        new ResolveDefundOrderStateRequest()
          .setContext(authContext.toFuture())
          .setDefundorderid(deFundingOrderID),
      );
      enqueueSnackbar("DeFunding order resolved", { variant: "success" });
    } catch (e) {
      errorContextDefaultWarningFeedback(
        e,
        "Failed to resolve defunding order",
      );
    }
    setResolveDeFundingOrderInProgress(false);
  };

  const submitDeFundingOrder = async (
    deFundingOrder: DefundOrder,
  ): Promise<SubmitDefundOrderResponse> => {
    if (!deFundingOrder) {
      return new SubmitDefundOrderResponse();
    }
    setSubmitDeFundingOrderInProgress(true);
    try {
      const deFund = await defundOrderStateController.submitDefundOrder(
        new SubmitDefundOrderRequest()
          .setContext(authContext.toFuture())
          .setAccountid(deFundingOrder.getLedgeraccountid())
          .setAmount(deFundingOrder.getAmount())
          .setExpress(deFundingOrder.getExpress())
          .setBankaccountid(deFundingOrder.getBankaccountid()),
      );
      setSubmitDeFundingOrderInProgress(false);
      return deFund;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      errorContextDefaultErrorFeedback(e);
      setSubmitDeFundingOrderInProgress(false);
      throw e;
    }
  };

  return (
    <DeFundingContext.Provider
      value={{
        apiCallInProgress:
          fetchingDeFundingOrdersInProgress ||
          submitDeFundingOrderInProgress ||
          settleDeFundingOrderInProgress ||
          resolveDeFundingOrderInProgress,

        deFundingOrder,
        deFundingOrders,
        setDeFundingOrder,

        deFundingOrdersLoaded,
        deFundingOrdersLoadError,
        clearDeFundOrdersLoadError: clearDeFundingOrdersLoadError,
        reloadDeFundOrders: reloadDeFundingOrders,

        initialised: deFundingOrdersLoaded,
        initialisationError: deFundingOrdersLoadError,
        clearInitialisationError,

        submitDeFundOrder: submitDeFundingOrder,
        settleDeFundOrder: settleDeFundingOrder,
        resolveDeFundOrder: resolveDeFundingOrder,
      }}
    >
      {children}
    </DeFundingContext.Provider>
  );
};
