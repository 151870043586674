import React, { useContext, useEffect, useState } from "react";
import { useIsMounted } from "hooks";
import { useApplicationContext } from "context/Application/Application";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { useErrorContext } from "context/Error";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useFirebaseContext } from "context/Firebase";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
  Reader as MarketListingReader,
} from "james/views/marketListingView";
import { Token } from "james/ledger";
import { LedgerNetwork } from "james/ledger/Network";
import { AssetParticipants } from "james/marketData/AssetParticipants";
import { AssetParticipantInspector } from "james/marketData/AssetParticipantsInspector";
import { DataSheetSection } from "james/marketData/DataSheetSection";
import {
  AssetDataSheetReader,
  AssetDocumentsDataSheetSection,
  AssetInvestmentObjectiveDataSheetSection,
  AssetPerformanceDataSheetSection,
  AssetPriceHistoryDataSheetSection,
  AssetSectorAllocationsAndHoldingsDataSheetSection,
  StellarAssetPublicInfoDataSheetSection,
} from "james/marketData";
import { TokenIdentifier } from "james/search/identifier";
import { AssetIndependentReviewDataSheetSection } from "james/marketData/AssetIndependentReviewDataSheetSection";
import { AssetMarketingMediaDataSheetSection } from "james/marketData/AssetMarketingMedia";
import { AssetRepaymentTermsDataSheetSection } from "james/marketData/AssetRepaymentTermsDataSheetSection";
import { AssetSubscriptionOverviewDataSheetSection } from "james/marketData/AssetSubscriptionOverviewDataSheetSection";
import { AssetType } from "james/views/marketListingView/Model";
dayjs.extend(utc);

export type AssetOverviewContextType = {
  marketListingViewModel:
    | MarketListingViewModel
    | PublicMarketListingViewModel
    | undefined;
  marketListingViewModelLoading: boolean;

  assetParticipants: AssetParticipants;
  assetParticipantsLoading: boolean;

  dataSheetSections: DataSheetSection[];
  dataSheetSectionsLoading: boolean;
};

export const defaultContext: AssetOverviewContextType = {
  marketListingViewModel: undefined,
  marketListingViewModelLoading: false,

  assetParticipants: new AssetParticipants(),
  assetParticipantsLoading: false,

  dataSheetSections: [],
  dataSheetSectionsLoading: false,
};

const AssetOverviewContext =
  React.createContext<AssetOverviewContextType>(defaultContext);

export const useAssetOverviewContext = () => useContext(AssetOverviewContext);

export const AssetOverviewContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { firebaseAuthenticated } = useFirebaseContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isPublic = location.pathname.startsWith("/public");
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();
  const isMounted = useIsMounted();

  // prepare function to get the asset token from the URL
  const getToken: () => Token | undefined = () => {
    const assetTokenCode = searchParams.get("code");
    const assetTokenIssuer = searchParams.get("issuer");
    const assetTokenNetwork = searchParams.get("network");
    if (!(assetTokenCode && assetTokenIssuer && assetTokenNetwork)) {
      navigate("/market");
      return;
    }
    return new Token({
      code: assetTokenCode,
      issuer: assetTokenIssuer,
      network: assetTokenNetwork as LedgerNetwork,
    });
  };

  // ---- market listing view model ----
  const [marketListingViewModelLoaded, setMarketListingViewModelLoaded] =
    useState(false);
  const [marketListingViewModelLoadError, setMarketListingViewModelLoadError] =
    useState<string | null>(null);
  const [marketListingViewModel, setMarketListingViewModel] = useState<
    MarketListingViewModel | PublicMarketListingViewModel | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - firebase not yet authenticated (if not public)
      // - marketListingViewModel already loaded
      // - if there was an marketListingViewModel loading error
      // - component no longer mounted
      if (
        (!firebaseAuthenticated && !isPublic) ||
        marketListingViewModelLoaded ||
        marketListingViewModelLoadError ||
        !isMounted()
      ) {
        return;
      }

      // get token from url
      const token = getToken();
      if (!token) {
        return;
      }

      // retrieve listing view model
      let retrievedListingViewModel:
        | MarketListingViewModel
        | PublicMarketListingViewModel;
      try {
        if (isPublic) {
          retrievedListingViewModel = (
            await MarketListingReader.PublicReadOne({
              criteria: token.toFilter(),
            })
          ).publicModel;
        } else {
          retrievedListingViewModel = (
            await MarketListingReader.ReadOne({
              context: authContext,
              criteria: token.toFilter(),
            })
          ).model;
        }
      } catch (e) {
        navigate("/");
        setMarketListingViewModelLoadError(`${e}`);
        errorContextDefaultWarningFeedback(
          e,
          "error fetching market listing view model",
        );
        return;
      }

      // only update state if the component is still mounted
      if (!isMounted()) {
        return;
      }

      // update the smart marketListingViewModel in state
      if (!retrievedListingViewModel) {
        setMarketListingViewModelLoadError(
          "Market Listing View not correctly initialised.",
        );
        return;
      }

      // update state
      setMarketListingViewModel(retrievedListingViewModel);
      setMarketListingViewModelLoaded(true);
    })();
  }, [
    marketListingViewModelLoaded,
    marketListingViewModelLoadError,
    isMounted,
  ]);

  // ---- asset participants data ----
  const [assetParticipants, setParticipants] = useState<AssetParticipants>(
    new AssetParticipants(),
  );
  const [assetParticipantsLoaded, setAssetParticipantsLoaded] = useState(false);
  const [assetParticipantsLoadError, setAssetParticipantsLoadError] = useState<
    string | null
  >(null);
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - firebase note yet authenticated (if not public)
      // - data not yet loaded
      // - participants already loaded
      // - if there was a participants loading error
      // - component no longer mounted
      if (
        (!firebaseAuthenticated && !isPublic) ||
        assetParticipantsLoaded ||
        assetParticipantsLoadError ||
        !isMounted()
      ) {
        return;
      }

      // get token from url
      const token = getToken();
      if (!token) {
        return;
      }

      // fetch asset participant data
      let retrievedAssetParticipants: AssetParticipants;
      try {
        retrievedAssetParticipants = (
          await AssetParticipantInspector.GetAssetParticipants({
            token: token,
          })
        ).participants;
      } catch (e) {
        navigate("/");
        setAssetParticipantsLoadError(`${e}`);
        errorContextDefaultWarningFeedback(
          e,
          "error fetching asset participants",
        );
        return;
      }

      // only update state if the component is still mounted
      if (!isMounted()) {
        return;
      }

      // update the smart marketListingViewModel in state
      if (!retrievedAssetParticipants) {
        setAssetParticipantsLoadError(
          "Participants not correctly initialised.",
        );
        return;
      }

      // update state
      setParticipants(retrievedAssetParticipants);
      setAssetParticipantsLoaded(true);
    })();
  }, [marketListingViewModelLoaded, marketListingViewModelLoadError]);

  // ---- asset participants data ----
  const [dataSheetSections, setDataSheetSections] = useState<
    DataSheetSection[]
  >([]);
  const [dataSheetSectionsLoaded, setDataSheetSectionsLoaded] = useState(false);
  const [dataSheetSectionsLoadError, setDataSheetSectionsLoadError] = useState<
    string | null
  >(null);
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - firebase note yet authenticated (if not public)
      // - datasheet sections already loaded
      // - if there was a data sheet sections loading error
      // - component no longer mounted
      // - marketListingViewModel not yet loaded
      if (
        (!firebaseAuthenticated && !isPublic) ||
        dataSheetSectionsLoaded ||
        dataSheetSectionsLoadError ||
        !marketListingViewModelLoaded ||
        !isMounted()
      ) {
        return;
      }

      // get token from url
      const token = getToken();
      if (!token) {
        return;
      }

      // fetch asset participant data
      let retrievedDataSheetSections: DataSheetSection[];
      try {
        const dataSheet = (
          await AssetDataSheetReader.ReadAssetDataSheet({
            assetIdentifier: TokenIdentifier(token),
          })
        ).dataSheet;

        retrievedDataSheetSections = dataSheet.sections;
      } catch (e) {
        navigate("/");
        setDataSheetSectionsLoadError(`${e}`);
        errorContextDefaultWarningFeedback(
          e,
          "error fetching datasheet sections",
        );
        return;
      }

      // only update state if the component is still mounted
      if (!isMounted()) {
        return;
      }

      // update the smart marketListingViewModel in state
      if (!retrievedDataSheetSections) {
        setDataSheetSectionsLoadError(
          "Datasheet sections not correctly initialised.",
        );
        return;
      }

      // sort and set data sheet sections
      type SectionClass =
        | typeof AssetSubscriptionOverviewDataSheetSection
        | typeof AssetInvestmentObjectiveDataSheetSection
        | typeof StellarAssetPublicInfoDataSheetSection
        | typeof AssetSectorAllocationsAndHoldingsDataSheetSection
        | typeof AssetRepaymentTermsDataSheetSection
        | typeof AssetPerformanceDataSheetSection
        | typeof AssetPriceHistoryDataSheetSection
        | typeof AssetIndependentReviewDataSheetSection
        | typeof AssetMarketingMediaDataSheetSection
        | typeof AssetDocumentsDataSheetSection;

      const defaultOrderMap = new Map<SectionClass, number>([
        [AssetSubscriptionOverviewDataSheetSection, 0],
        [AssetInvestmentObjectiveDataSheetSection, 1],
        [StellarAssetPublicInfoDataSheetSection, 1],
        [AssetSectorAllocationsAndHoldingsDataSheetSection, 2],
        [AssetRepaymentTermsDataSheetSection, 2],
        [AssetPerformanceDataSheetSection, 3],
        [AssetPriceHistoryDataSheetSection, 4],
        [AssetIndependentReviewDataSheetSection, 5],
        [AssetMarketingMediaDataSheetSection, 6],
        [AssetDocumentsDataSheetSection, 7],
      ]);

      // define custom ordering for the commodity asset type.
      const commodityOrderMap = new Map<SectionClass, number>([
        [AssetSubscriptionOverviewDataSheetSection, 0],
        [AssetPriceHistoryDataSheetSection, 1],
        [AssetDocumentsDataSheetSection, 2],
        [AssetInvestmentObjectiveDataSheetSection, 3],
        [AssetMarketingMediaDataSheetSection, 4],
        [AssetPerformanceDataSheetSection, 5],
        [AssetIndependentReviewDataSheetSection, 6],
        [AssetSectorAllocationsAndHoldingsDataSheetSection, 7],
        [AssetRepaymentTermsDataSheetSection, 8],
        [StellarAssetPublicInfoDataSheetSection, 9],
      ]);

      // map of order maps per asset type
      const orderMaps: Partial<Record<AssetType, Map<SectionClass, number>>> = {
        [AssetType.Gold]: commodityOrderMap,
        [AssetType.Silver]: commodityOrderMap,
        [AssetType.Platinum]: commodityOrderMap,
        [AssetType.Palladium]: commodityOrderMap,
        // add more here in future
      };

      // select the appropriate order map based on the asset type or use default
      const assetType = marketListingViewModel?.assetType;
      const orderMap = (assetType && orderMaps[assetType]) || defaultOrderMap;

      // sort sections
      setDataSheetSections(
        retrievedDataSheetSections
          .map((section) => {
            const sectionType = section.constructor as SectionClass;
            const order = orderMap.get(sectionType) ?? 100;
            return { section, order };
          })
          .sort((a, b) => a.order - b.order)
          .map((item) => item.section),
      );

      setDataSheetSectionsLoaded(true);
    })();
  }, [
    firebaseAuthenticated,
    dataSheetSectionsLoaded,
    dataSheetSectionsLoadError,
    isMounted,
    marketListingViewModelLoaded,
  ]);

  return (
    <AssetOverviewContext.Provider
      value={{
        marketListingViewModel,
        marketListingViewModelLoading: !marketListingViewModelLoaded,

        assetParticipants,
        assetParticipantsLoading: !assetParticipantsLoaded,

        dataSheetSections,
        dataSheetSectionsLoading: !dataSheetSectionsLoaded,
      }}
    >
      {children}
    </AssetOverviewContext.Provider>
  );
};
