import React, { useEffect, useState } from "react";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  useTheme,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import { DescriptionSection } from "./DescriptionSection";
import { StructureSection } from "./StructureSection";
import { RiskSection } from "./RiskSection";
import { DisclaimerSection } from "./DisclaimerSection";
import { BlockChainSection } from "./BlockChainSection";
import { RedemptionSection } from "./RedemptionSection";
import { PrivatePlacementSection } from "./PrivatePlacementSection";
import {
  HowDoesItWorkContentProvider,
  HowDoesItWorkSection,
} from "./HowDoesItWorkContentProvider";
import { DataSheetSectionsTab } from "../AssetOverview";
import { DataComponentInfo, InteractionDriver } from "const/gtm";

export enum SectionRenderers {
  DescriptionSection = "Description section",
  StructureSection = "Structure section",
  Redemption = "Redemption",
  RiskSection = "Risk section",
  Disclaimer = "Disclaimer",
  BlockchainSection = "Blockchain section",
  PrivatePlacement = "Private Placement",
}

interface HowDoesItWorkProps {
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
  expandedState: {
    [key in DataSheetSectionsTab]: Record<string, boolean | undefined>;
  };
  setExpandedState: (
    value: React.SetStateAction<{
      [key in DataSheetSectionsTab]: Record<string, boolean | undefined>;
    }>,
  ) => void;
}

export interface HowDoesItWorkSectionProps {
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
  section: HowDoesItWorkSection;
}

export const HowDoesItWork = ({
  marketListingViewModel,
  expandedState,
  setExpandedState,
}: HowDoesItWorkProps) => {
  const theme = useTheme();
  const [sections, setSections] = useState<HowDoesItWorkSection[]>([]);

  useEffect(() => {
    setSections(
      HowDoesItWorkContentProvider.fetchContent(marketListingViewModel),
    );
  }, []);

  useEffect(() => {
    const openState: Record<string, boolean> = {};
    sections.forEach((i, idx) => {
      openState[idx] = false;
    });
    setExpandedState((state) => ({
      ...state,
      [DataSheetSectionsTab.HowDoesItWork]: {
        ...openState,
        0: true,
      },
    }));
  }, [sections]);

  return (
    <Box>
      {(sections ?? [])
        .filter((section) => {
          // Ensure there's meaningful content in the section
          return section.fields.length > 0;
        })
        .map((section, i) => {
          return (
            <Accordion
              defaultExpanded={i === 0}
              onClick={() =>
                setExpandedState((prevState) => ({
                  ...prevState,
                  [DataSheetSectionsTab.HowDoesItWork]: {
                    ...prevState[DataSheetSectionsTab.HowDoesItWork],
                    [i]: !prevState[DataSheetSectionsTab.HowDoesItWork][i],
                  },
                }))
              }
              expanded={!!expandedState[DataSheetSectionsTab.HowDoesItWork][i]}
              key={i}
              sx={{
                "&.MuiAccordion-root:before": {
                  backgroundColor: "unset",
                },
                "&.Mui-expanded": {
                  margin: "0",
                },
              }}
              data-component-info={JSON.stringify({
                component_id: "asset_detail_card",
                component_business_name: "asset detail card",
                component_title: `tab>How Does It Work>${section.title}`,
                component_driver: InteractionDriver.MoreInfo,
              } as DataComponentInfo)}
            >
              <AccordionSummary
                sx={{
                  flexDirection: "row-reverse",
                  backgroundColor: theme.palette.custom.midnight,
                  padding: theme.spacing(0, 4),
                }}
                expandIcon={
                  <ExpandMore
                    id={`accordion-${i}-expandToggle-icon`}
                    color={"primary"}
                  />
                }
              >
                <Typography
                  sx={{ pl: 2 }}
                  variant={"h6"}
                  children={section.title}
                />
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <div onClick={(event) => event.stopPropagation()}>
                  {(() => {
                    const props: HowDoesItWorkSectionProps = {
                      section: section,
                      marketListingViewModel: marketListingViewModel,
                    };
                    switch (section.sectionName) {
                      case SectionRenderers.DescriptionSection:
                        return <DescriptionSection {...props} />;
                      case SectionRenderers.StructureSection:
                        return <StructureSection {...props} />;
                      case SectionRenderers.PrivatePlacement:
                        return <PrivatePlacementSection {...props} />;
                      case SectionRenderers.Redemption:
                        return <RedemptionSection {...props} />;
                      case SectionRenderers.RiskSection:
                        return <RiskSection {...props} />;
                      case SectionRenderers.Disclaimer:
                        return <DisclaimerSection {...props} />;
                      case SectionRenderers.BlockchainSection:
                        return <BlockChainSection {...props} />;
                    }
                  })()}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};
