import React from "react";
import { styled } from "@mui/material/styles";
import { RouteType } from "routes/Route";
import { Grid, Paper, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ViewConfiguration } from "james/configuration";
import cx from "classnames";
import { Router } from "../../routes";
import { Funding } from "./components/Funding/Funding";
import { useApplicationContext } from "context/Application/Application";
import { FundingContextProvider } from "./components/Funding/Context";
import { DeFundingContextProvider } from "./components/Defunding/Context";
import { Defunding } from "./components/Defunding/Defunding";

const PREFIX = "Banking";

const classes = {
  content: `${PREFIX}-content`,
  tabBar: `${PREFIX}-tabBar`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.tabBar}`]: {
    paddingLeft: theme.spacing(4),
  },
}));

const tabRoutes: RouteType[] = [
  {
    name: "Fund",
    id: "fund-tab-route",
    path: "/market-management/banking/funding",
    component: (
      <FundingContextProvider>
        <Funding />
      </FundingContextProvider>
    ),
  },
  {
    name: "Defund",
    id: "defund-tab-route",
    path: "/market-management/banking/defund",
    component: (
      <DeFundingContextProvider>
        <Defunding />
      </DeFundingContextProvider>
    ),
  },
];

function determineAvailableTabRoutes(
  viewConfig: ViewConfiguration,
): RouteType[] {
  const bankingViewConfigurationRules = viewConfig.Banking;
  if (!bankingViewConfigurationRules) {
    console.error("no banking view configuration");
    return [];
  }

  return tabRoutes.filter((route) => bankingViewConfigurationRules[route.name]);
}

export function Banking() {
  const navigate = useNavigate();
  const { viewConfiguration } = useApplicationContext();
  const availableTabRoutes = determineAvailableTabRoutes(viewConfiguration);

  // determine the active path each time the component renders
  const currentRoute = availableTabRoutes.find((r) =>
    window.location.pathname.includes(r.path),
  );

  // set only the active path if the route is not wallet
  let activePath = availableTabRoutes.length ? availableTabRoutes[0].path : "";
  if (currentRoute) {
    activePath = currentRoute.path;
  }

  return (
    <Root>
      <Paper square className={classes.tabBar}>
        <Grid container>
          <Grid item>
            <Tabs
              value={activePath}
              onChange={(_, value) => {
                if (activePath === value) {
                  return;
                }
                navigate(value);
              }}
              textColor={"inherit"}
            >
              {availableTabRoutes.map((t, i) => (
                <Tab key={i} value={t.path} label={t.name} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      <div className={cx(classes.content, "meshScroll")}>
        <Router
          baseURL={"/market-management/banking"}
          redirectPath={"/market-management/banking/funding"}
          routes={availableTabRoutes}
        />
      </div>
    </Root>
  );
}
