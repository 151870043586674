import React, { useEffect, useState } from "react";
import { useApplicationContext } from "context/Application/Application";

import { Model as MarketSubscriptionOrderViewModel } from "james/views/marketSubscriptionOrderView";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Dialog,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { Reader } from "james/views/marketSubscriptionOrderView/Reader";
import { InfiniteScrollList } from "components/Table/InfCardTable";
import { SortingType } from "james/search/query/Query";
import { Amount } from "components/Ledger/Amount";
import {
  Cancel,
  Clear,
  Close,
  DeleteForever,
  FaceOutlined,
  FilterList,
} from "@mui/icons-material";
import { SubscriptionOrderViewCard } from "./SubscriptionOrderViewCard";
import {
  AllSubscriptionOrderStates,
  SubscriptionOrderState,
} from "james/market/SubscriptionOrder";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import cx from "classnames";
import { SubscriptionOrderStateChip } from "components/MarketSubscriptions/Chips";
import { useErrorContext } from "context/Error";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { Query } from "james/search/query";
import { useEffectRunAfterFirstRender } from "hooks/useEffectRunAfterFirstRender";

export const SubscriptionOrderInfScrollList = () => {
  const { errorContextErrorTranslator } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [models, setModels] = useState<MarketSubscriptionOrderViewModel[]>([]);

  const [filterHeight, setFilterHeight] = useState(0);
  const { NotificationBannerHeight } = useAppNoticeContext();

  const loadMore =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (criteria: any, query: Query, updateOffset: () => void) => async () => {
      setLoading(true);

      try {
        const response = await Reader.Read({
          context: authContext,
          query,
          criteria,
        });
        updateOffset();

        setModels([...models, ...response.models]);

        setTotal(response.total);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading market subscription view models: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error reading market subscription view models:: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }

      setLoading(false);
    };

  return (
    <Box sx={{ px: 2 }}>
      <InfiniteScrollList
        total={total}
        loadMoreData={loadMore}
        data={models}
        renderLoadingRow={<LoadingCard />}
        clearData={() => setModels([])}
        isLoading={!loading}
        renderData={ScrollCard}
        lastRowPadding={24}
        children={(props) => (
          <ScrollFilterComponent
            {...props}
            onOpen={(n) => setFilterHeight(n)}
          />
        )}
        rowHeight={180}
        rowGap={16}
        listHeight={
          window.innerHeight -
          56 -
          75 -
          filterHeight -
          48 -
          NotificationBannerHeight
        }
        rowWidth={"100%"}
        noDataSplashComponent={<NoDataSplash />}
        hideScrollbars
      />
    </Box>
  );
};

export const NoDataSplash = () => {
  return (
    <>
      <FaceOutlined
        sx={(theme) => ({
          fontSize: 110,
          color: alpha(theme.palette.background.default, 0.5),
        })}
      />
      <Typography
        variant="h4"
        sx={(theme) => ({
          fontWeight: "bold",
          color: theme.palette.text.tertiary,
          margin: theme.spacing(2, 0),
        })}
        children={"No Subscriptions Yet?"}
      />
      <Typography
        variant="h5"
        sx={{
          width: "226px",
          color: (theme) => theme.palette.text.disabled,
          textAlign: "center",
        }}
        children="You will see a list once you have a new subscription."
      />
    </>
  );
};

const LoadingCard = () => {
  return (
    <Card sx={{ height: 180 }}>
      <CardContent
        sx={{
          height: 144,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Skeleton sx={{ width: "60px" }} />
          <Skeleton sx={{ width: "40px", justifySelf: "end" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Skeleton sx={{ width: "100px" }} />
          <Skeleton sx={{ width: "160px", justifySelf: "end" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Skeleton sx={{ width: "80px" }} />
          <Skeleton sx={{ width: "80px", justifySelf: "end" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Skeleton sx={{ width: "40px" }} />
          <Skeleton
            sx={{
              width: "80px",
              borderRadius: 3,
              height: "32px",
              justifySelf: "end",
            }}
          />
        </Box>
      </CardContent>

      <CardActionArea
        disabled
        sx={(theme) => ({
          height: 36,
          backgroundColor: theme.palette.custom.grape,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}
      >
        <Skeleton sx={{ width: "120px", height: "24px" }} />
      </CardActionArea>
    </Card>
  );
};

const ScrollCard = (data: MarketSubscriptionOrderViewModel) => {
  const [dialog, setDialog] = useState(false);
  const columns = [
    {
      label: "Number",
      value: data.number,
    },
    {
      label: "Asset Name",
      value: (
        <Typography noWrap sx={{ maxWidth: 180 }}>
          {data.assetName}
        </Typography>
      ),
    },
    {
      label: "Amount",
      value: <Amount amount={data.amount} />,
    },
    {
      label: "State",
      value: <SubscriptionOrderStateChip state={data.state} />,
    },
  ];

  return (
    <Card
      sx={{
        width: "100%",
      }}
    >
      <CardContent
        sx={{
          height: "calc(100% - 42px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {columns.map((col, idx) => (
          <Box
            key={idx}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              {col.label}
            </Typography>
            {col.value}
          </Box>
        ))}
      </CardContent>
      <CardActionArea
        sx={(theme) => ({
          height: 42,
          backgroundColor: theme.palette.custom.grape,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}
        onClick={() => setDialog(true)}
      >
        <Typography>VIEW SUBSCRIPTION</Typography>
      </CardActionArea>
      <Dialog open={dialog} fullScreen>
        <SubscriptionOrderViewCard
          selectedOrder={data}
          actionNode={
            <IconButton onClick={() => setDialog(!dialog)}>
              <Close />
            </IconButton>
          }
        />
      </Dialog>
    </Card>
  );
};

interface ScrollFilterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCriteria: (criteria: any | ((prevCriteria: any) => any)) => void;
  setSorting: (sorting: SortingType[] | (() => SortingType[])) => void;
  onOpen: (height: number) => void;
}

const ScrollFilterComponent = ({ setCriteria, onOpen }: ScrollFilterProps) => {
  const [open, setOpen] = useState(false);

  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [
    subscriptionOrderStatusesCriterion,
    setSubscriptionOrderStatusesCriterion,
  ] = useState<SubscriptionOrderState[]>([]);

  useEffect(() => {
    if (open) {
      onOpen(46 + 156 + subscriptionOrderStatusesCriterion.length * 16);
    } else {
      onOpen(56);
    }
  });

  useEffectRunAfterFirstRender(() => {
    const deb = setTimeout(() => {
      const text = textSearchCriterionTextField
        ? {
            $or: [
              {
                issuerClientName: TextSubstringCriterion(
                  textSearchCriterionTextField,
                ),
              },
              {
                assetName: TextSubstringCriterion(textSearchCriterionTextField),
              },
              { number: TextSubstringCriterion(textSearchCriterionTextField) },
              {
                assetShortName: TextSubstringCriterion(
                  textSearchCriterionTextField,
                ),
              },
            ],
          }
        : {};
      const state =
        subscriptionOrderStatusesCriterion.length > 0
          ? {
              state: TextListCriterion(subscriptionOrderStatusesCriterion),
            }
          : {};

      setCriteria({
        ...text,
        ...state,
      });
    });
    return () => clearTimeout(deb);
  }, [textSearchCriterionTextField, subscriptionOrderStatusesCriterion]);

  return (
    <Box sx={{ pb: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: 48,
          py: 1,
        }}
      >
        <Typography variant="h5">Subscription Orders</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          <FilterList
            className={cx({ open })}
            sx={(theme) => ({
              width: 24,
              height: 24,
              color: theme.palette.text.secondary,
              "&.open": {
                color: theme.palette.text.primary,
              },
            })}
          />
        </IconButton>
      </Box>
      <Collapse in={open}>
        <TextField
          id={"marketSubscriptionOrdersTable-textFilter-textField"}
          variant={"outlined"}
          margin={"dense"}
          fullWidth
          label={"Search Text Field"}
          placeholder={"Start Typing..."}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: textSearchCriterionTextField ? (
              <InputAdornment
                position={"end"}
                children={
                  <IconButton
                    id={
                      "marketSubscriptionOrdersTable-textFilterClearButton-iconButton"
                    }
                    size={"small"}
                    onClick={() => setTextSearchCriterionTextField("")}
                  >
                    <Clear />
                  </IconButton>
                }
              />
            ) : undefined,
          }}
          value={textSearchCriterionTextField}
          onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
        />
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          id={"marketSubscriptionOrdersTable-stateFilter-autocomplete"}
          multiple
          options={AllSubscriptionOrderStates}
          filterSelectedOptions
          onChange={(_, value: SubscriptionOrderState[]) =>
            setSubscriptionOrderStatusesCriterion(value)
          }
          ChipProps={{
            sx: {
              maxWidth: 200,
            },
            color: "info",
            size: "small",
            deleteIcon: (
              <Cancel
                sx={(theme) => ({
                  color: `${theme.palette.text.secondary} !important`,
                  "&:hover": {
                    color: `${theme.palette.secondary.contrastText} !important`,
                  },
                })}
              />
            ),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={
                "marketSubscriptionOrdersTable-stateFilter-autocompleteTextField"
              }
              label={"State"}
              variant={"outlined"}
              margin={"dense"}
              InputLabelProps={{ shrink: true }}
              placeholder={
                subscriptionOrderStatusesCriterion.length
                  ? undefined
                  : "Select..."
              }
            />
          )}
        />
        <Button
          fullWidth
          sx={{ mt: 1, height: "36px" }}
          id={"marketSubscriptionTable-clearAllFilters-button"}
          variant={"contained"}
          color={"secondary"}
          children={"clear all"}
          onClick={() => {
            setTextSearchCriterionTextField("");
            setSubscriptionOrderStatusesCriterion([]);
          }}
          startIcon={<DeleteForever />}
        />
      </Collapse>
    </Box>
  );
};
