import { alpha, Chip, ChipProps, Tooltip } from "@mui/material";

export interface PrivatePlacementChipProps {
  chipProps?: ChipProps;
}

export function PrivatePlacementChip(props: PrivatePlacementChipProps) {
  return (
    <Tooltip
      title="Private assets have limited liquidity and higher risks. Read more in the 'How It Works' tab."
      placement="top"
    >
      <span>
        <Chip
          {...props.chipProps}
          id="directOrderStateChip-pending-chip"
          label="Private Offer"
          sx={(theme) => ({
            backgroundColor: alpha(theme.palette.text.primary, 0.16),
            color: theme.palette.text.primary,
            height: "18px",
            fontSize: "13px",
            py: 1.5,
          })}
        />
      </span>
    </Tooltip>
  );
}
