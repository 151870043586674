import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { useAPIContext } from "context/API";
import { useErrorContext } from "context/Error";
import { ResetPhoneNumberRequest } from "@mesh/common-js/src/legal/personWriteService_pb";
import { useApplicationContext } from "context/Application/Application";

export function ResetPhoneNumberDialog({
  open,
  onClose,
  personID,
}: {
  open: boolean;
  onClose: () => void;
  personID: string;
}) {
  const [resetReason, setResetReason] = useState<string>("");
  const [resetConfirmed, setResetConfirmed] = useState<boolean>(false);
  const {
    person: { writeService },
  } = useAPIContext();
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();

  const handleReset = async () => {
    try {
      await writeService.resetPhoneNumber(
        new ResetPhoneNumberRequest()
          .setContext(authContext.toFuture())
          .setPersonid(personID)
          .setResetreason(resetReason),
      );
      onClose();
      return;
    } catch (e) {
      console.error("error resetting phone numbers", e);
      errorContextDefaultWarningFeedback(e, "error resetting phone numbers");
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={(theme) => ({ height: "52px", padding: theme.spacing(1, 1, 2, 3) })}
      >
        <Typography fontSize="16px">Reset Phone Number</Typography>
        <Grid item>
          <Tooltip title="Close" placement="top">
            <span>
              <IconButton
                id="reset-phoneNumber-close-button"
                size="small"
                onClick={() => onClose()}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          width: "100%",
          display: "grid",
          padding: theme.spacing(0, 3, 3),
        })}
      >
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.hint,
            padding: theme.spacing(2, 0),
          })}
          fontSize="14px"
          fontWeight="400"
        >
          Resetting this phone number will remove the user’s verified number.
        </Typography>
        <TextField
          sx={{ width: "50%", paddingBottom: "16px", margin: 0 }}
          id="reset-phoneNumber-resetReason-textField"
          label="Reset Reason"
          value={resetReason}
          onChange={(e) => setResetReason(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              id="reset-phoneNumber-resetConfirmation-checkbox"
              disabled={resetReason.length < 1}
              color="primary"
              checked={resetConfirmed}
              onChange={() => setResetConfirmed(!resetConfirmed)}
            />
          }
          label="I have verified this persons identity"
        />
        <Button
          id="reset-phoneNumber-button"
          sx={{ width: "68px", marginTop: "16px" }}
          disabled={!resetConfirmed || resetReason.length < 1}
          size="small"
          variant="contained"
          color="primary"
          children="reset"
          onClick={handleReset}
        />
      </DialogContent>
    </Dialog>
  );
}
