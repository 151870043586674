// source: financial/smartInstrumentStateController.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var financial_smartInstrument_pb = require('../financial/smartInstrument_pb.js');
goog.object.extend(proto, financial_smartInstrument_pb);
var financial_smartInstrumentLeg_pb = require('../financial/smartInstrumentLeg_pb.js');
goog.object.extend(proto, financial_smartInstrumentLeg_pb);
var financial_assetClass_pb = require('../financial/assetClass_pb.js');
goog.object.extend(proto, financial_assetClass_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
var ledger_futureToken_pb = require('../ledger/futureToken_pb.js');
goog.object.extend(proto, ledger_futureToken_pb);
var financial_unit_pb = require('../financial/unit_pb.js');
goog.object.extend(proto, financial_unit_pb);
var financial_smartInstrumentType_pb = require('../financial/smartInstrumentType_pb.js');
goog.object.extend(proto, financial_smartInstrumentType_pb);
var financial_callabilityConfiguration_pb = require('../financial/callabilityConfiguration_pb.js');
goog.object.extend(proto, financial_callabilityConfiguration_pb);
var i8n_timezone_pb = require('../i8n/timezone_pb.js');
goog.object.extend(proto, i8n_timezone_pb);
var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var financial_smartInstrumentAttribute_pb = require('../financial/smartInstrumentAttribute_pb.js');
goog.object.extend(proto, financial_smartInstrumentAttribute_pb);
goog.exportSymbol('proto.financial.CancelSmartInstrumentRequest', null, global);
goog.exportSymbol('proto.financial.CancelSmartInstrumentResponse', null, global);
goog.exportSymbol('proto.financial.CreateDraftSmartInstrumentRequest', null, global);
goog.exportSymbol('proto.financial.CreateDraftSmartInstrumentResponse', null, global);
goog.exportSymbol('proto.financial.IssueSmartInstrumentIDRequest', null, global);
goog.exportSymbol('proto.financial.IssueSmartInstrumentIDResponse', null, global);
goog.exportSymbol('proto.financial.IssueSmartInstrumentRequest', null, global);
goog.exportSymbol('proto.financial.IssueSmartInstrumentResponse', null, global);
goog.exportSymbol('proto.financial.MarkSmartInstrumentIssuanceFailedRequest', null, global);
goog.exportSymbol('proto.financial.MarkSmartInstrumentIssuanceFailedResponse', null, global);
goog.exportSymbol('proto.financial.MarkSmartInstrumentIssuedRequest', null, global);
goog.exportSymbol('proto.financial.MarkSmartInstrumentIssuedResponse', null, global);
goog.exportSymbol('proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest', null, global);
goog.exportSymbol('proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse', null, global);
goog.exportSymbol('proto.financial.MarkSmartInstrumentPreIssuedRequest', null, global);
goog.exportSymbol('proto.financial.MarkSmartInstrumentPreIssuedResponse', null, global);
goog.exportSymbol('proto.financial.PreIssueSmartInstrumentIDRequest', null, global);
goog.exportSymbol('proto.financial.PreIssueSmartInstrumentIDResponse', null, global);
goog.exportSymbol('proto.financial.PreIssueSmartInstrumentRequest', null, global);
goog.exportSymbol('proto.financial.PreIssueSmartInstrumentResponse', null, global);
goog.exportSymbol('proto.financial.ResolveSmartInstrumentIDStateRequest', null, global);
goog.exportSymbol('proto.financial.ResolveSmartInstrumentIDStateResponse', null, global);
goog.exportSymbol('proto.financial.ResolveSmartInstrumentStateRequest', null, global);
goog.exportSymbol('proto.financial.ResolveSmartInstrumentStateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CreateDraftSmartInstrumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.CreateDraftSmartInstrumentRequest.repeatedFields_, null);
};
goog.inherits(proto.financial.CreateDraftSmartInstrumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CreateDraftSmartInstrumentRequest.displayName = 'proto.financial.CreateDraftSmartInstrumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CreateDraftSmartInstrumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.CreateDraftSmartInstrumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CreateDraftSmartInstrumentResponse.displayName = 'proto.financial.CreateDraftSmartInstrumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.PreIssueSmartInstrumentIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.PreIssueSmartInstrumentIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.PreIssueSmartInstrumentIDRequest.displayName = 'proto.financial.PreIssueSmartInstrumentIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.PreIssueSmartInstrumentIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.PreIssueSmartInstrumentIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.PreIssueSmartInstrumentIDResponse.displayName = 'proto.financial.PreIssueSmartInstrumentIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.PreIssueSmartInstrumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.PreIssueSmartInstrumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.PreIssueSmartInstrumentRequest.displayName = 'proto.financial.PreIssueSmartInstrumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.PreIssueSmartInstrumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.PreIssueSmartInstrumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.PreIssueSmartInstrumentResponse.displayName = 'proto.financial.PreIssueSmartInstrumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.displayName = 'proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.displayName = 'proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkSmartInstrumentPreIssuedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkSmartInstrumentPreIssuedRequest.displayName = 'proto.financial.MarkSmartInstrumentPreIssuedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkSmartInstrumentPreIssuedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkSmartInstrumentPreIssuedResponse.displayName = 'proto.financial.MarkSmartInstrumentPreIssuedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.IssueSmartInstrumentIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.IssueSmartInstrumentIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.IssueSmartInstrumentIDRequest.displayName = 'proto.financial.IssueSmartInstrumentIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.IssueSmartInstrumentIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.IssueSmartInstrumentIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.IssueSmartInstrumentIDResponse.displayName = 'proto.financial.IssueSmartInstrumentIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.IssueSmartInstrumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.IssueSmartInstrumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.IssueSmartInstrumentRequest.displayName = 'proto.financial.IssueSmartInstrumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.IssueSmartInstrumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.IssueSmartInstrumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.IssueSmartInstrumentResponse.displayName = 'proto.financial.IssueSmartInstrumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkSmartInstrumentIssuanceFailedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkSmartInstrumentIssuanceFailedRequest.displayName = 'proto.financial.MarkSmartInstrumentIssuanceFailedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkSmartInstrumentIssuanceFailedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkSmartInstrumentIssuanceFailedResponse.displayName = 'proto.financial.MarkSmartInstrumentIssuanceFailedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkSmartInstrumentIssuedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkSmartInstrumentIssuedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkSmartInstrumentIssuedRequest.displayName = 'proto.financial.MarkSmartInstrumentIssuedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkSmartInstrumentIssuedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkSmartInstrumentIssuedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkSmartInstrumentIssuedResponse.displayName = 'proto.financial.MarkSmartInstrumentIssuedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CancelSmartInstrumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.CancelSmartInstrumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CancelSmartInstrumentRequest.displayName = 'proto.financial.CancelSmartInstrumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CancelSmartInstrumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.CancelSmartInstrumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CancelSmartInstrumentResponse.displayName = 'proto.financial.CancelSmartInstrumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ResolveSmartInstrumentStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ResolveSmartInstrumentStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ResolveSmartInstrumentStateRequest.displayName = 'proto.financial.ResolveSmartInstrumentStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ResolveSmartInstrumentStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ResolveSmartInstrumentStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ResolveSmartInstrumentStateResponse.displayName = 'proto.financial.ResolveSmartInstrumentStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ResolveSmartInstrumentIDStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ResolveSmartInstrumentIDStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ResolveSmartInstrumentIDStateRequest.displayName = 'proto.financial.ResolveSmartInstrumentIDStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ResolveSmartInstrumentIDStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ResolveSmartInstrumentIDStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ResolveSmartInstrumentIDStateResponse.displayName = 'proto.financial.ResolveSmartInstrumentIDStateResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.CreateDraftSmartInstrumentRequest.repeatedFields_ = [10,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CreateDraftSmartInstrumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CreateDraftSmartInstrumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CreateDraftSmartInstrumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
ownerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
token: (f = msg.getToken()) && ledger_futureToken_pb.FutureToken.toObject(includeInstance, f),
directmintingallowed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
issuedate: (f = msg.getIssuedate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
timezone: jspb.Message.getFieldWithDefault(msg, 7, 0),
type: jspb.Message.getFieldWithDefault(msg, 8, 0),
unit: jspb.Message.getFieldWithDefault(msg, 9, 0),
legsList: jspb.Message.toObjectList(msg.getLegsList(),
    financial_smartInstrumentLeg_pb.SmartInstrumentLeg.toObject, includeInstance),
linkedsmartinstrumentid: jspb.Message.getFieldWithDefault(msg, 11, ""),
privateoffer: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    financial_smartInstrumentAttribute_pb.SmartInstrumentAttribute.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest}
 */
proto.financial.CreateDraftSmartInstrumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CreateDraftSmartInstrumentRequest;
  return proto.financial.CreateDraftSmartInstrumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CreateDraftSmartInstrumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest}
 */
proto.financial.CreateDraftSmartInstrumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new ledger_futureToken_pb.FutureToken;
      reader.readMessage(value,ledger_futureToken_pb.FutureToken.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirectmintingallowed(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIssuedate(value);
      break;
    case 7:
      var value = /** @type {!proto.i8n.Timezone} */ (reader.readEnum());
      msg.setTimezone(value);
      break;
    case 8:
      var value = /** @type {!proto.financial.SmartInstrumentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {!proto.financial.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 10:
      var value = new financial_smartInstrumentLeg_pb.SmartInstrumentLeg;
      reader.readMessage(value,financial_smartInstrumentLeg_pb.SmartInstrumentLeg.deserializeBinaryFromReader);
      msg.addLegs(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedsmartinstrumentid(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateoffer(value);
      break;
    case 13:
      var value = new financial_smartInstrumentAttribute_pb.SmartInstrumentAttribute;
      reader.readMessage(value,financial_smartInstrumentAttribute_pb.SmartInstrumentAttribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CreateDraftSmartInstrumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CreateDraftSmartInstrumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CreateDraftSmartInstrumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getOwnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ledger_futureToken_pb.FutureToken.serializeBinaryToWriter
    );
  }
  f = message.getDirectmintingallowed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIssuedate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLegsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      financial_smartInstrumentLeg_pb.SmartInstrumentLeg.serializeBinaryToWriter
    );
  }
  f = message.getLinkedsmartinstrumentid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPrivateoffer();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      financial_smartInstrumentAttribute_pb.SmartInstrumentAttribute.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
*/
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string ownerID = 2;
 * @return {string}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getOwnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setOwnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ledger.FutureToken token = 4;
 * @return {?proto.ledger.FutureToken}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getToken = function() {
  return /** @type{?proto.ledger.FutureToken} */ (
    jspb.Message.getWrapperField(this, ledger_futureToken_pb.FutureToken, 4));
};


/**
 * @param {?proto.ledger.FutureToken|undefined} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
*/
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.hasToken = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool directMintingAllowed = 5;
 * @return {boolean}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getDirectmintingallowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setDirectmintingallowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp issueDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getIssuedate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
*/
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setIssuedate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.clearIssuedate = function() {
  return this.setIssuedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.hasIssuedate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional i8n.Timezone timezone = 7;
 * @return {!proto.i8n.Timezone}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getTimezone = function() {
  return /** @type {!proto.i8n.Timezone} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.i8n.Timezone} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional SmartInstrumentType type = 8;
 * @return {!proto.financial.SmartInstrumentType}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getType = function() {
  return /** @type {!proto.financial.SmartInstrumentType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.financial.SmartInstrumentType} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional Unit unit = 9;
 * @return {!proto.financial.Unit}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getUnit = function() {
  return /** @type {!proto.financial.Unit} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.financial.Unit} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated SmartInstrumentLeg legs = 10;
 * @return {!Array<!proto.financial.SmartInstrumentLeg>}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getLegsList = function() {
  return /** @type{!Array<!proto.financial.SmartInstrumentLeg>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_smartInstrumentLeg_pb.SmartInstrumentLeg, 10));
};


/**
 * @param {!Array<!proto.financial.SmartInstrumentLeg>} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
*/
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setLegsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.financial.SmartInstrumentLeg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.SmartInstrumentLeg}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.addLegs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.financial.SmartInstrumentLeg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.clearLegsList = function() {
  return this.setLegsList([]);
};


/**
 * optional string linkedSmartInstrumentID = 11;
 * @return {string}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getLinkedsmartinstrumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setLinkedsmartinstrumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool privateOffer = 12;
 * @return {boolean}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getPrivateoffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setPrivateoffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated SmartInstrumentAttribute attributes = 13;
 * @return {!Array<!proto.financial.SmartInstrumentAttribute>}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.financial.SmartInstrumentAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_smartInstrumentAttribute_pb.SmartInstrumentAttribute, 13));
};


/**
 * @param {!Array<!proto.financial.SmartInstrumentAttribute>} value
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
*/
proto.financial.CreateDraftSmartInstrumentRequest.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.financial.SmartInstrumentAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.SmartInstrumentAttribute}
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.financial.SmartInstrumentAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.CreateDraftSmartInstrumentRequest} returns this
 */
proto.financial.CreateDraftSmartInstrumentRequest.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CreateDraftSmartInstrumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CreateDraftSmartInstrumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CreateDraftSmartInstrumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CreateDraftSmartInstrumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CreateDraftSmartInstrumentResponse}
 */
proto.financial.CreateDraftSmartInstrumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CreateDraftSmartInstrumentResponse;
  return proto.financial.CreateDraftSmartInstrumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CreateDraftSmartInstrumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CreateDraftSmartInstrumentResponse}
 */
proto.financial.CreateDraftSmartInstrumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CreateDraftSmartInstrumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CreateDraftSmartInstrumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CreateDraftSmartInstrumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CreateDraftSmartInstrumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.CreateDraftSmartInstrumentResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.CreateDraftSmartInstrumentResponse} returns this
*/
proto.financial.CreateDraftSmartInstrumentResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CreateDraftSmartInstrumentResponse} returns this
 */
proto.financial.CreateDraftSmartInstrumentResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CreateDraftSmartInstrumentResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.PreIssueSmartInstrumentIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.PreIssueSmartInstrumentIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.PreIssueSmartInstrumentIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PreIssueSmartInstrumentIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrumentid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.PreIssueSmartInstrumentIDRequest}
 */
proto.financial.PreIssueSmartInstrumentIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.PreIssueSmartInstrumentIDRequest;
  return proto.financial.PreIssueSmartInstrumentIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.PreIssueSmartInstrumentIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.PreIssueSmartInstrumentIDRequest}
 */
proto.financial.PreIssueSmartInstrumentIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmartinstrumentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.PreIssueSmartInstrumentIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.PreIssueSmartInstrumentIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.PreIssueSmartInstrumentIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PreIssueSmartInstrumentIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrumentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.PreIssueSmartInstrumentIDRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.PreIssueSmartInstrumentIDRequest} returns this
*/
proto.financial.PreIssueSmartInstrumentIDRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PreIssueSmartInstrumentIDRequest} returns this
 */
proto.financial.PreIssueSmartInstrumentIDRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PreIssueSmartInstrumentIDRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string smartInstrumentID = 2;
 * @return {string}
 */
proto.financial.PreIssueSmartInstrumentIDRequest.prototype.getSmartinstrumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.PreIssueSmartInstrumentIDRequest} returns this
 */
proto.financial.PreIssueSmartInstrumentIDRequest.prototype.setSmartinstrumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.PreIssueSmartInstrumentIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.PreIssueSmartInstrumentIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.PreIssueSmartInstrumentIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PreIssueSmartInstrumentIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.PreIssueSmartInstrumentIDResponse}
 */
proto.financial.PreIssueSmartInstrumentIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.PreIssueSmartInstrumentIDResponse;
  return proto.financial.PreIssueSmartInstrumentIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.PreIssueSmartInstrumentIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.PreIssueSmartInstrumentIDResponse}
 */
proto.financial.PreIssueSmartInstrumentIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.PreIssueSmartInstrumentIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.PreIssueSmartInstrumentIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.PreIssueSmartInstrumentIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PreIssueSmartInstrumentIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.PreIssueSmartInstrumentIDResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.PreIssueSmartInstrumentIDResponse} returns this
*/
proto.financial.PreIssueSmartInstrumentIDResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PreIssueSmartInstrumentIDResponse} returns this
 */
proto.financial.PreIssueSmartInstrumentIDResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PreIssueSmartInstrumentIDResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.PreIssueSmartInstrumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.PreIssueSmartInstrumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.PreIssueSmartInstrumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PreIssueSmartInstrumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.PreIssueSmartInstrumentRequest}
 */
proto.financial.PreIssueSmartInstrumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.PreIssueSmartInstrumentRequest;
  return proto.financial.PreIssueSmartInstrumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.PreIssueSmartInstrumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.PreIssueSmartInstrumentRequest}
 */
proto.financial.PreIssueSmartInstrumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.PreIssueSmartInstrumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.PreIssueSmartInstrumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.PreIssueSmartInstrumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PreIssueSmartInstrumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.PreIssueSmartInstrumentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.PreIssueSmartInstrumentRequest} returns this
*/
proto.financial.PreIssueSmartInstrumentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PreIssueSmartInstrumentRequest} returns this
 */
proto.financial.PreIssueSmartInstrumentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PreIssueSmartInstrumentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmartInstrument smartInstrument = 2;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.PreIssueSmartInstrumentRequest.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 2));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.PreIssueSmartInstrumentRequest} returns this
*/
proto.financial.PreIssueSmartInstrumentRequest.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PreIssueSmartInstrumentRequest} returns this
 */
proto.financial.PreIssueSmartInstrumentRequest.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PreIssueSmartInstrumentRequest.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.PreIssueSmartInstrumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.PreIssueSmartInstrumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.PreIssueSmartInstrumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PreIssueSmartInstrumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.PreIssueSmartInstrumentResponse}
 */
proto.financial.PreIssueSmartInstrumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.PreIssueSmartInstrumentResponse;
  return proto.financial.PreIssueSmartInstrumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.PreIssueSmartInstrumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.PreIssueSmartInstrumentResponse}
 */
proto.financial.PreIssueSmartInstrumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.PreIssueSmartInstrumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.PreIssueSmartInstrumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.PreIssueSmartInstrumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PreIssueSmartInstrumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.PreIssueSmartInstrumentResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.PreIssueSmartInstrumentResponse} returns this
*/
proto.financial.PreIssueSmartInstrumentResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PreIssueSmartInstrumentResponse} returns this
 */
proto.financial.PreIssueSmartInstrumentResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PreIssueSmartInstrumentResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest;
  return proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} returns this
*/
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} returns this
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmartInstrument smartInstrument = 2;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 2));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} returns this
*/
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} returns this
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse;
  return proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse} returns this
*/
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse} returns this
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkSmartInstrumentPreIssuedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkSmartInstrumentPreIssuedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedRequest}
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkSmartInstrumentPreIssuedRequest;
  return proto.financial.MarkSmartInstrumentPreIssuedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkSmartInstrumentPreIssuedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedRequest}
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkSmartInstrumentPreIssuedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkSmartInstrumentPreIssuedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedRequest} returns this
*/
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedRequest} returns this
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmartInstrument smartInstrument = 2;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 2));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedRequest} returns this
*/
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedRequest} returns this
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentPreIssuedRequest.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkSmartInstrumentPreIssuedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkSmartInstrumentPreIssuedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedResponse}
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkSmartInstrumentPreIssuedResponse;
  return proto.financial.MarkSmartInstrumentPreIssuedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkSmartInstrumentPreIssuedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedResponse}
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkSmartInstrumentPreIssuedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkSmartInstrumentPreIssuedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedResponse} returns this
*/
proto.financial.MarkSmartInstrumentPreIssuedResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentPreIssuedResponse} returns this
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentPreIssuedResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.IssueSmartInstrumentIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.IssueSmartInstrumentIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.IssueSmartInstrumentIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.IssueSmartInstrumentIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrumentid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.IssueSmartInstrumentIDRequest}
 */
proto.financial.IssueSmartInstrumentIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.IssueSmartInstrumentIDRequest;
  return proto.financial.IssueSmartInstrumentIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.IssueSmartInstrumentIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.IssueSmartInstrumentIDRequest}
 */
proto.financial.IssueSmartInstrumentIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmartinstrumentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.IssueSmartInstrumentIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.IssueSmartInstrumentIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.IssueSmartInstrumentIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.IssueSmartInstrumentIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrumentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.IssueSmartInstrumentIDRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.IssueSmartInstrumentIDRequest} returns this
*/
proto.financial.IssueSmartInstrumentIDRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.IssueSmartInstrumentIDRequest} returns this
 */
proto.financial.IssueSmartInstrumentIDRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.IssueSmartInstrumentIDRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string smartInstrumentID = 2;
 * @return {string}
 */
proto.financial.IssueSmartInstrumentIDRequest.prototype.getSmartinstrumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.IssueSmartInstrumentIDRequest} returns this
 */
proto.financial.IssueSmartInstrumentIDRequest.prototype.setSmartinstrumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.IssueSmartInstrumentIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.IssueSmartInstrumentIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.IssueSmartInstrumentIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.IssueSmartInstrumentIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.IssueSmartInstrumentIDResponse}
 */
proto.financial.IssueSmartInstrumentIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.IssueSmartInstrumentIDResponse;
  return proto.financial.IssueSmartInstrumentIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.IssueSmartInstrumentIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.IssueSmartInstrumentIDResponse}
 */
proto.financial.IssueSmartInstrumentIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.IssueSmartInstrumentIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.IssueSmartInstrumentIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.IssueSmartInstrumentIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.IssueSmartInstrumentIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.IssueSmartInstrumentIDResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.IssueSmartInstrumentIDResponse} returns this
*/
proto.financial.IssueSmartInstrumentIDResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.IssueSmartInstrumentIDResponse} returns this
 */
proto.financial.IssueSmartInstrumentIDResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.IssueSmartInstrumentIDResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.IssueSmartInstrumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.IssueSmartInstrumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.IssueSmartInstrumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.IssueSmartInstrumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.IssueSmartInstrumentRequest}
 */
proto.financial.IssueSmartInstrumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.IssueSmartInstrumentRequest;
  return proto.financial.IssueSmartInstrumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.IssueSmartInstrumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.IssueSmartInstrumentRequest}
 */
proto.financial.IssueSmartInstrumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.IssueSmartInstrumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.IssueSmartInstrumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.IssueSmartInstrumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.IssueSmartInstrumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.IssueSmartInstrumentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.IssueSmartInstrumentRequest} returns this
*/
proto.financial.IssueSmartInstrumentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.IssueSmartInstrumentRequest} returns this
 */
proto.financial.IssueSmartInstrumentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.IssueSmartInstrumentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmartInstrument smartInstrument = 2;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.IssueSmartInstrumentRequest.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 2));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.IssueSmartInstrumentRequest} returns this
*/
proto.financial.IssueSmartInstrumentRequest.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.IssueSmartInstrumentRequest} returns this
 */
proto.financial.IssueSmartInstrumentRequest.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.IssueSmartInstrumentRequest.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.IssueSmartInstrumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.IssueSmartInstrumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.IssueSmartInstrumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.IssueSmartInstrumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.IssueSmartInstrumentResponse}
 */
proto.financial.IssueSmartInstrumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.IssueSmartInstrumentResponse;
  return proto.financial.IssueSmartInstrumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.IssueSmartInstrumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.IssueSmartInstrumentResponse}
 */
proto.financial.IssueSmartInstrumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.IssueSmartInstrumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.IssueSmartInstrumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.IssueSmartInstrumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.IssueSmartInstrumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.IssueSmartInstrumentResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.IssueSmartInstrumentResponse} returns this
*/
proto.financial.IssueSmartInstrumentResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.IssueSmartInstrumentResponse} returns this
 */
proto.financial.IssueSmartInstrumentResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.IssueSmartInstrumentResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkSmartInstrumentIssuanceFailedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkSmartInstrumentIssuanceFailedRequest;
  return proto.financial.MarkSmartInstrumentIssuanceFailedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkSmartInstrumentIssuanceFailedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} returns this
*/
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} returns this
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmartInstrument smartInstrument = 2;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 2));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} returns this
*/
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} returns this
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedRequest.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkSmartInstrumentIssuanceFailedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkSmartInstrumentIssuanceFailedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedResponse}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkSmartInstrumentIssuanceFailedResponse;
  return proto.financial.MarkSmartInstrumentIssuanceFailedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkSmartInstrumentIssuanceFailedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedResponse}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkSmartInstrumentIssuanceFailedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkSmartInstrumentIssuanceFailedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedResponse} returns this
*/
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentIssuanceFailedResponse} returns this
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentIssuanceFailedResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkSmartInstrumentIssuedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkSmartInstrumentIssuedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentIssuedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkSmartInstrumentIssuedRequest}
 */
proto.financial.MarkSmartInstrumentIssuedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkSmartInstrumentIssuedRequest;
  return proto.financial.MarkSmartInstrumentIssuedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkSmartInstrumentIssuedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkSmartInstrumentIssuedRequest}
 */
proto.financial.MarkSmartInstrumentIssuedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkSmartInstrumentIssuedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkSmartInstrumentIssuedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentIssuedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentIssuedRequest} returns this
*/
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentIssuedRequest} returns this
 */
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmartInstrument smartInstrument = 2;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 2));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentIssuedRequest} returns this
*/
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentIssuedRequest} returns this
 */
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentIssuedRequest.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkSmartInstrumentIssuedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkSmartInstrumentIssuedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkSmartInstrumentIssuedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentIssuedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkSmartInstrumentIssuedResponse}
 */
proto.financial.MarkSmartInstrumentIssuedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkSmartInstrumentIssuedResponse;
  return proto.financial.MarkSmartInstrumentIssuedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkSmartInstrumentIssuedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkSmartInstrumentIssuedResponse}
 */
proto.financial.MarkSmartInstrumentIssuedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkSmartInstrumentIssuedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkSmartInstrumentIssuedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkSmartInstrumentIssuedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkSmartInstrumentIssuedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.MarkSmartInstrumentIssuedResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.MarkSmartInstrumentIssuedResponse} returns this
*/
proto.financial.MarkSmartInstrumentIssuedResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkSmartInstrumentIssuedResponse} returns this
 */
proto.financial.MarkSmartInstrumentIssuedResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkSmartInstrumentIssuedResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CancelSmartInstrumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CancelSmartInstrumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CancelSmartInstrumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CancelSmartInstrumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CancelSmartInstrumentRequest}
 */
proto.financial.CancelSmartInstrumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CancelSmartInstrumentRequest;
  return proto.financial.CancelSmartInstrumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CancelSmartInstrumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CancelSmartInstrumentRequest}
 */
proto.financial.CancelSmartInstrumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CancelSmartInstrumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CancelSmartInstrumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CancelSmartInstrumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CancelSmartInstrumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.CancelSmartInstrumentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.CancelSmartInstrumentRequest} returns this
*/
proto.financial.CancelSmartInstrumentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CancelSmartInstrumentRequest} returns this
 */
proto.financial.CancelSmartInstrumentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CancelSmartInstrumentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmartInstrument smartInstrument = 2;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.CancelSmartInstrumentRequest.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 2));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.CancelSmartInstrumentRequest} returns this
*/
proto.financial.CancelSmartInstrumentRequest.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CancelSmartInstrumentRequest} returns this
 */
proto.financial.CancelSmartInstrumentRequest.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CancelSmartInstrumentRequest.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CancelSmartInstrumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CancelSmartInstrumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CancelSmartInstrumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CancelSmartInstrumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CancelSmartInstrumentResponse}
 */
proto.financial.CancelSmartInstrumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CancelSmartInstrumentResponse;
  return proto.financial.CancelSmartInstrumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CancelSmartInstrumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CancelSmartInstrumentResponse}
 */
proto.financial.CancelSmartInstrumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CancelSmartInstrumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CancelSmartInstrumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CancelSmartInstrumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CancelSmartInstrumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.CancelSmartInstrumentResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.CancelSmartInstrumentResponse} returns this
*/
proto.financial.CancelSmartInstrumentResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CancelSmartInstrumentResponse} returns this
 */
proto.financial.CancelSmartInstrumentResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CancelSmartInstrumentResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ResolveSmartInstrumentStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ResolveSmartInstrumentStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ResolveSmartInstrumentStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolveSmartInstrumentStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ResolveSmartInstrumentStateRequest}
 */
proto.financial.ResolveSmartInstrumentStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ResolveSmartInstrumentStateRequest;
  return proto.financial.ResolveSmartInstrumentStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ResolveSmartInstrumentStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ResolveSmartInstrumentStateRequest}
 */
proto.financial.ResolveSmartInstrumentStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ResolveSmartInstrumentStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ResolveSmartInstrumentStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ResolveSmartInstrumentStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolveSmartInstrumentStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.ResolveSmartInstrumentStateRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.ResolveSmartInstrumentStateRequest} returns this
*/
proto.financial.ResolveSmartInstrumentStateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolveSmartInstrumentStateRequest} returns this
 */
proto.financial.ResolveSmartInstrumentStateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolveSmartInstrumentStateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmartInstrument smartInstrument = 2;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.ResolveSmartInstrumentStateRequest.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 2));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.ResolveSmartInstrumentStateRequest} returns this
*/
proto.financial.ResolveSmartInstrumentStateRequest.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolveSmartInstrumentStateRequest} returns this
 */
proto.financial.ResolveSmartInstrumentStateRequest.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolveSmartInstrumentStateRequest.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ResolveSmartInstrumentStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ResolveSmartInstrumentStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ResolveSmartInstrumentStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolveSmartInstrumentStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ResolveSmartInstrumentStateResponse}
 */
proto.financial.ResolveSmartInstrumentStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ResolveSmartInstrumentStateResponse;
  return proto.financial.ResolveSmartInstrumentStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ResolveSmartInstrumentStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ResolveSmartInstrumentStateResponse}
 */
proto.financial.ResolveSmartInstrumentStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ResolveSmartInstrumentStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ResolveSmartInstrumentStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ResolveSmartInstrumentStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolveSmartInstrumentStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.ResolveSmartInstrumentStateResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.ResolveSmartInstrumentStateResponse} returns this
*/
proto.financial.ResolveSmartInstrumentStateResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolveSmartInstrumentStateResponse} returns this
 */
proto.financial.ResolveSmartInstrumentStateResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolveSmartInstrumentStateResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ResolveSmartInstrumentIDStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ResolveSmartInstrumentIDStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrumentid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ResolveSmartInstrumentIDStateRequest}
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ResolveSmartInstrumentIDStateRequest;
  return proto.financial.ResolveSmartInstrumentIDStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ResolveSmartInstrumentIDStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ResolveSmartInstrumentIDStateRequest}
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmartinstrumentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ResolveSmartInstrumentIDStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ResolveSmartInstrumentIDStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrumentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.ResolveSmartInstrumentIDStateRequest} returns this
*/
proto.financial.ResolveSmartInstrumentIDStateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolveSmartInstrumentIDStateRequest} returns this
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string smartInstrumentID = 2;
 * @return {string}
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.prototype.getSmartinstrumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.ResolveSmartInstrumentIDStateRequest} returns this
 */
proto.financial.ResolveSmartInstrumentIDStateRequest.prototype.setSmartinstrumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ResolveSmartInstrumentIDStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ResolveSmartInstrumentIDStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ResolveSmartInstrumentIDStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolveSmartInstrumentIDStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ResolveSmartInstrumentIDStateResponse}
 */
proto.financial.ResolveSmartInstrumentIDStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ResolveSmartInstrumentIDStateResponse;
  return proto.financial.ResolveSmartInstrumentIDStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ResolveSmartInstrumentIDStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ResolveSmartInstrumentIDStateResponse}
 */
proto.financial.ResolveSmartInstrumentIDStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ResolveSmartInstrumentIDStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ResolveSmartInstrumentIDStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ResolveSmartInstrumentIDStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolveSmartInstrumentIDStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmartInstrument smartInstrument = 1;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.ResolveSmartInstrumentIDStateResponse.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 1));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.ResolveSmartInstrumentIDStateResponse} returns this
*/
proto.financial.ResolveSmartInstrumentIDStateResponse.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolveSmartInstrumentIDStateResponse} returns this
 */
proto.financial.ResolveSmartInstrumentIDStateResponse.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolveSmartInstrumentIDStateResponse.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.financial);
