import { UnaryInterceptor, Request, UnaryResponse } from "grpc-web";
import { MFACancelledError } from "../../utilities/network/jsonRPCRequest";
import { ErrCancelled } from "./MFA";

/**
 * `MFAInterceptor` is a class responsible for intercepting requests and appending a mfa-token metadata entry.
 */
export class MFAInterceptor implements UnaryInterceptor<unknown, unknown> {
  // performMFA adds the mfa token to the request if required
  private performMFA: (request: Request<unknown, unknown>) => Promise<string>;

  constructor(
    performMFA: (request: Request<unknown, unknown>) => Promise<string>,
  ) {
    this.performMFA = performMFA;
  }

  /**
   * Intercepts a request to add the mfa token metadata entry to its metadata.
   *
   * @param {Object} request - The request object.
   * @param {Function} request.getMetadata - A function that returns the request's metadata.
   * @param {unknown} invoker - A function responsible for processing the request.
   *                            Expected to be of type `function`, but can be of any type.
   *
   * @returns {unknown} The result of the `invoker` function, if it is a function.
   *                    Otherwise, logs an error and doesn't return anything.
   */
  async intercept(
    request: Request<unknown, unknown>,
    invoker: (
      request: Request<unknown, unknown>,
    ) => Promise<UnaryResponse<unknown, unknown>>,
  ): Promise<UnaryResponse<unknown, unknown>> {
    try {
      const token = await this.performMFA(request);

      // if no token - continue to invoker
      if (token) {
        // set token header (metaData)
        const metadata = request.getMetadata();
        metadata["x-mfa-token"] = token;
        console.debug("intercepted - mfa token added to metadata");
      }
    } catch (e) {
      if (e === ErrCancelled) {
        return Promise.reject(new MFACancelledError("", "")); // MFACancelledError thrown from MFA context - perform
      }
      console.debug("mfa interception failed", e);
      return Promise.reject(e);
    }

    // hand over with modified request - mfa token added to the header
    return await invoker(request);
  }
}
