import React, { useEffect, useMemo, useState } from "react";
import { SxProps, Theme, alpha, styled } from "@mui/material/styles";
import { NewSorting, Query } from "james/search/query";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Model as MarketSubscriptionOrderViewModel,
  Updater as MarketSubscriptionOrderViewUpdater,
  useRead as useMarketSubscriptionOrderViewReaderRead,
} from "james/views/marketSubscriptionOrderView";
import {
  AllSubscriptionOrderStates,
  SubscriptionOrderState,
} from "james/market/SubscriptionOrder";
import {
  TextExactCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { BPTable } from "components/Table";
import { SubscriptionOrderStateChip } from "./Chips";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  Error as ErrorIcon,
  FaceOutlined,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TextField } from "components/FormFields";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { SubscriptionOrderStateController } from "james/market/SubscriptionOrderStateController";
import { Amount } from "../Ledger/Amount";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { TransactionTable } from "components/Ledger/Transaction";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";

const PREFIX = "SubscriptionOrderTable";

const classes = {
  textSearchField: `${PREFIX}-textSearchField`,
  iconButton: `${PREFIX}-iconButton`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
  row: `${PREFIX}-row`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.textSearchField}`]: {
    width: 350,
  },

  [`& .${classes.iconButton}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },

  [`& .${classes.statusSelectFilterField}`]: {
    width: 230,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("priority", "desc"), NewSorting("id", "desc")],
});

export type SubscriptionOrderTableProps = {
  system: boolean;
  height: number;
  hideColumns?: string[];
  subscriptionOrderBookID?: string;
  onSelect?: (m: MarketSubscriptionOrderViewModel) => void;
  onUpdateOrders?: (m: MarketSubscriptionOrderViewModel[]) => void;
  style?: SxProps<Theme>;
  showEmptyCard?: boolean;
  selected?: MarketSubscriptionOrderViewModel;
};

/**
 * @prop {boolean} system
 * @prop {number} height
 * @prop {string[] | undefined} hideColumns
 * @prop {string} subscriptionOrderBookID
 * @prop {func(m: MarketSubscriptionOrderViewModel): void} onSelect
 * @prop {func(MarketSubscriptionOrderViewModel[]): void} onUpdateOrders
 * @returns
 */
export function SubscriptionOrderTable(props: SubscriptionOrderTableProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const { viewConfiguration, authContext } = useApplicationContext();
  const [fullUpdateLoading, setFullUpdateLoading] = useState(false);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const mcView =
    !!viewConfiguration?.["Subscription"]?.["Subscription Order"]?.["MC View"];
  const [
    selectedSubscriptionOrderViewModel,
    setSelectedSubscriptionOrderViewModel,
  ] = useState<MarketSubscriptionOrderViewModel | undefined>(undefined);
  const [resolutionInProgress, setResolutionInProgress] = useState(false);
  const handleResolveSubscriptionOrderState = async () => {
    if (!selectedSubscriptionOrderViewModel) {
      console.error("selected market subscription Order view model not set");
      return;
    }

    setResolutionInProgress(true);
    try {
      await SubscriptionOrderStateController.ResolveSubscriptionOrderIDState({
        context: authContext,
        subscriptionOrderID:
          selectedSubscriptionOrderViewModel.subscriptionOrderID,
        ignoreResolutionCount: true,
      });
      setSelectedSubscriptionOrderViewModel(undefined);
      setReadRequest({ ...readRequest });
      enqueueSnackbar("Subscription Order State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving subscription Order state`, e);
      enqueueSnackbar(
        `error resolving subscription Order state: ${err.message}`,
        { variant: "error" },
      );
    }
    setResolutionInProgress(false);
  };

  const startingCriteria = useMemo(
    () =>
      props.subscriptionOrderBookID
        ? {
            subscriptionOrderBookID: TextExactCriterion(
              props.subscriptionOrderBookID,
            ),
          }
        : {},
    [],
  );
  const { readResponse, loading, setReadRequest, readRequest } =
    useMarketSubscriptionOrderViewReaderRead(
      {
        context: authContext,
        query: initialQuery,
        criteria: startingCriteria,
      },
      props.system,
    );

  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [
    subscriptionOrderStatusesCriterion,
    setSubscriptionOrderStatusesCriterion,
  ] = useState<SubscriptionOrderState[]>([]);
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { number: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            subscriptionOrderBookNumber: TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
          {
            assetName: TextSubstringCriterion(textSearchCriterionTextField),
          },
          {
            "tokens.token.code": TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);

  useEffect(() => {
    if (props.onUpdateOrders) {
      props.onUpdateOrders(readResponse.models);
    }
  }, [readResponse]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = { ...startingCriteria };

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (subscriptionOrderStatusesCriterion.length) {
      criteria.state = TextListCriterion(subscriptionOrderStatusesCriterion);
    }

    setReadRequest({
      context: readRequest.context,
      query: initialQuery,
      criteria,
    });
  }, [
    subscriptionOrderStatusesCriterion,
    textSearchCriterion,
    readRequest.context,
    setReadRequest,
    authContext,
  ]);

  return (
    <Root
      data-component-info={JSON.stringify({
        component_id: "subscription_order_list",
        component_business_name: "subscription orders",
        component_title: "subscription orders list",
        component_driver: InteractionDriver.listSubscriptionOrder,
      } as DataComponentInfo)}
    >
      {readResponse.models.length === 0 &&
      props.showEmptyCard &&
      !loading &&
      !subscriptionOrderStatusesCriterion.length &&
      textSearchCriterionTextField === "" ? (
        <NoDataCard
          title={"No Subscription Orders?"}
          subTitle={[
            "You will see a list once there",
            "is at least 1 Subscription Order.",
          ]}
        />
      ) : (
        <BPTable
          selectedRowCheck={(data) => data["id"] === props.selected?.id}
          style={props.style}
          singleSelect
          loading={loading || fullUpdateLoading || resolutionInProgress}
          height={props.height - noticeBannerHeight}
          title={"Subscription Orders"}
          data={readResponse.models}
          interactionDriver={InteractionDriver.listSubscriptionOrder}
          onSingleSelectChange={(data) => {
            setSelectedSubscriptionOrderViewModel(
              data as MarketSubscriptionOrderViewModel,
            );
            if (!props.onSelect) return;
            props.onSelect(data as MarketSubscriptionOrderViewModel);
          }}
          columns={(() => {
            const columns = [
              // Number
              {
                label: "Number",
                field: "number",
              },
              // Name
              {
                label: "Name",
                field: "investorClientName",
              },
              // Book
              {
                label: "Book",
                field: "subscriptionOrderBookNumber",
              },
              // Asset Name
              {
                label: "Asset Name",
                field: "assetName",
              },
              // Amount
              {
                label: "Amount",
                field: "amount.value.float",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [p: string]: any }) => {
                  return (
                    <Amount
                      amount={(data as MarketSubscriptionOrderViewModel).amount}
                      formatTextNumOpts={{
                        noDecimalPlaces: 7,
                        addDecimalPadding: true,
                      }}
                    />
                  );
                },
              },
              // State
              {
                label: "State",
                field: "state",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: { [key: string]: any }) => {
                  return (
                    // tool tip
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        gap: theme.spacing(1),
                        gridGap: (theme) => theme.spacing(1),
                        alignItems: "center",
                      })}
                    >
                      <SubscriptionOrderStateChip
                        state={(data as MarketSubscriptionOrderViewModel).state}
                      />
                      {(data as MarketSubscriptionOrderViewModel).state ===
                        SubscriptionOrderState.Failed && (
                        <Tooltip
                          title={data.failureReasons.join(", ")}
                          placement={"top"}
                        >
                          <ErrorIcon
                            sx={{
                              color: "secondary.light",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  );
                },
              },
              // Res. Count
              {
                label: "Res. Count",
                field: "resolutionCount",
              },
            ];

            const filterCols = props.hideColumns ?? [];

            return columns.filter((c) => !filterCols.includes(c.label));
          })()}
          expandRowComponent={
            mcView
              ? {
                  maxHeight: 300,
                  component: (selectedRowData) => {
                    const subscriptionOrderViewModel =
                      selectedRowData as MarketSubscriptionOrderViewModel;
                    return (
                      <TransactionTable
                        height={400}
                        title={`Transactions for Subscription Order No ${subscriptionOrderViewModel.number}`}
                        constantCriteria={[
                          newTextExactCriterion(
                            "metadata.subscriptionOrderID",
                            subscriptionOrderViewModel.subscriptionOrderID,
                          ),
                        ]}
                      />
                    );
                  },
                }
              : undefined
          }
          query={readRequest.query}
          onQueryChange={(query: Query) =>
            setReadRequest({
              ...readRequest,
              query,
            })
          }
          totalNoRecords={readResponse.total}
          toolBarControls={(() => {
            const controls: React.ReactNode[] = [];

            if (
              selectedSubscriptionOrderViewModel &&
              viewConfiguration?.["Subscription"]?.["Subscription Order"]
                ?.ResolveState
            ) {
              controls.push(
                <Button
                  disabled={resolutionInProgress}
                  variant={"contained"}
                  color={"primary"}
                  children={"Resolve State"}
                  onClick={handleResolveSubscriptionOrderState}
                />,
              );
            }

            if (
              viewConfiguration?.["Subscription"]?.["Subscription Order"]
                ?.ViewModel?.FullUpdate
            ) {
              controls.push(
                <Button
                  variant={"contained"}
                  color={"primary"}
                  children={"Full Update"}
                  onClick={async () => {
                    setFullUpdateLoading(true);
                    try {
                      await MarketSubscriptionOrderViewUpdater.FullUpdate({
                        context: authContext,
                      });
                      setReadRequest({ ...readRequest });
                      enqueueSnackbar("Full Update Complete", {
                        variant: "success",
                      });
                    } catch (e) {
                      const err = errorContextErrorTranslator.translateError(e);
                      console.error(
                        `error performing full update: ${
                          err.message ? err.message : err.toString()
                        }`,
                      );
                      enqueueSnackbar(
                        `Error Performing Full Update: ${
                          err.message ? err.message : err.toString()
                        }`,
                        { variant: "error" },
                      );
                    }
                    setFullUpdateLoading(false);
                  }}
                />,
              );
            }

            controls.push(
              <Tooltip title={"Refresh"}>
                <span>
                  <IconButton
                    onClick={() =>
                      setReadRequest({
                        ...readRequest,
                        query: new Query(initialQuery),
                      })
                    }
                    id={"marketSubscriptionOrdersTable-refresh-iconButton"}
                    disabled={loading}
                    data-link-info={JSON.stringify({
                      content_interaction_id: "info-search",
                      content_interaction_action: InteractionAction.Click,
                      content_interaction_type: InteractionType.Icon,
                      content_interaction_text: "refresh",
                      content_interaction_driver: InteractionDriver.FindOrders,
                    } as DataLinkInfoType)}
                    size={"small"}
                  >
                    <ReloadIcon
                      data-link-info={JSON.stringify({
                        content_interaction_id: "info-search",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_type: InteractionType.Icon,
                        content_interaction_text: "refresh",
                        content_interaction_driver:
                          InteractionDriver.FindOrders,
                      } as DataLinkInfoType)}
                    />
                  </IconButton>
                </span>
              </Tooltip>,
            );

            return controls;
          })()}
          filters={[
            <TextField
              id={"marketSubscriptionOrdersTable-textFilter-textField"}
              variant={"outlined"}
              margin={"dense"}
              className={classes.textSearchField}
              label={"Search Text Field"}
              placeholder={"Start Typing..."}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: textSearchCriterionTextField ? (
                  <InputAdornment
                    position={"end"}
                    children={
                      <IconButton
                        id={
                          "marketSubscriptionOrdersTable-textFilterClearButton-iconButton"
                        }
                        size={"small"}
                        onClick={() => setTextSearchCriterionTextField("")}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                  />
                ) : undefined,
              }}
              value={textSearchCriterionTextField}
              onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
            />,
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              id={"marketSubscriptionOrdersTable-stateFilter-autocomplete"}
              disabled={loading}
              multiple
              options={AllSubscriptionOrderStates}
              filterSelectedOptions
              sx={{ width: 260 }}
              className="meshScroll"
              onChange={(_, value: SubscriptionOrderState[]) =>
                setSubscriptionOrderStatusesCriterion(value)
              }
              ChipProps={{
                color: "info",
                size: "small",
                deleteIcon: (
                  <CancelIcon
                    sx={(theme) => ({
                      color: `${theme.palette.text.secondary} !important`,
                      "&:hover": {
                        color: `${theme.palette.secondary.contrastText} !important`,
                      },
                    })}
                  />
                ),
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={
                    "marketSubscriptionOrdersTable-stateFilter-autocompleteTextField"
                  }
                  className={classes.statusSelectFilterField}
                  label={"State"}
                  variant={"outlined"}
                  margin={"dense"}
                  InputLabelProps={{ shrink: true }}
                  placeholder={
                    subscriptionOrderStatusesCriterion.length
                      ? undefined
                      : "Select..."
                  }
                />
              )}
            />,
          ]}
          noDataSplashComponent={
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaceOutlined
                sx={(theme) => ({
                  fontSize: 110,
                  color: alpha(theme.palette.background.default, 0.5),
                })}
              />
              <Typography
                variant="h4"
                sx={(theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.text.tertiary,
                  margin: theme.spacing(2, 0),
                })}
                children={
                  !subscriptionOrderStatusesCriterion.length &&
                  textSearchCriterionTextField === ""
                    ? "No Subscription Order Listed?"
                    : "No Subscription Order Found"
                }
              />
              <Typography
                variant="h5"
                sx={{
                  width: "226px",
                  color: (theme) => theme.palette.text.disabled,
                  textAlign: "center",
                }}
                children={
                  !subscriptionOrderStatusesCriterion.length &&
                  textSearchCriterionTextField === ""
                    ? "You will see an entry once you have placed a subscription order."
                    : "Please try using different search criteria"
                }
              />
            </Box>
          }
        />
      )}
    </Root>
  );
}

interface NoDataCardProps {
  title: string;
  subTitle: string[];
  refresh?: () => void;
  loading?: boolean;
}

const NoDataCard = ({ title, subTitle, refresh, loading }: NoDataCardProps) => (
  <Card
    sx={(theme) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.secondary,
      textAlign: "center",
      backgroundColor: theme.palette.custom.grape,
      height: 166,
      position: "relative",
    })}
  >
    {refresh && (
      <IconButton
        sx={{ position: "absolute", top: 4, right: 8 }}
        onClick={refresh}
      >
        <RefreshIcon />
      </IconButton>
    )}
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        <Typography
          sx={{
            mb: 2,
            fontWeight: "bold",
          }}
          variant="h3"
          children={title}
          color="textSecondary"
        />
        {subTitle.map((text, idx) => {
          return (
            <Typography
              key={idx}
              variant="body1"
              children={text}
              color="textSecondary"
            />
          );
        })}
      </>
    )}
  </Card>
);
