import React, { ChangeEvent, useState } from "react";
import ConfigEnvironment from "react-global-configuration";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "../FormFields";
import { useValidatedForm } from "hooks/useForm";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
} from "./useValidateForm";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import { Token } from "james/ledger/Token";
import { useSnackbar } from "notistack";
import { Context } from "james/security";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import cx from "classnames";
import { LoadingButton } from "@mui/lab";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { useApplicationContext } from "context/Application/Application";
import {
  MarketListingViewModel,
  PublicModel,
} from "james/views/marketListingView";
import { useInvestmentRanges } from "./useInvestmentRanges";
import { useAPIContext } from "context/API";
import { CommitToSubscriptionRequest } from "@mesh/common-js/dist/market/subscriptionCommitmentManager_pb";
import { SubscriptionCommitmentUserLink } from "@mesh/common-js/dist/market/subscriptionCommitmentUserLink_pb";
import { IDSubscriptionCommitmentUserLink } from "@mesh/common-js/dist/market/subscriptionCommitmentUserLinkID_pb";
import { PublicCommitToSubscriptionRequest } from "@mesh/common-js/dist/market/publicSubscriptionCommitmentManager_pb";
import { sleep } from "utilities/general";
import { usePersonContext } from "context/Person/PersonService";
import { MarketSubscriptionOrderBookViewModel } from "james/views/marketSubscriptionOrderBookView";

export interface WaitingListPopUpDialogProps {
  dialogProps: DialogProps;
  assetToken: Token;
  closeDialog: () => void;
  isPublic: boolean;
  context: Context;
  marketListingViewModel: MarketListingViewModel | PublicModel;
}

export interface FormData {
  range: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  isChecked: boolean;
  isPublic: boolean;
}

// initialProcessCellphoneNumber prepares the cellphone number input so that it can
// be validated.
const initialProcessCellphoneNumber: (cellphoneNumber: string) => string = (
  cellphoneNumber: string,
) => {
  // remove leading +27 if already provided (will be added back later)
  cellphoneNumber = cellphoneNumber.replace("+27", "");

  // remove any spaces if number already contains spaces
  cellphoneNumber = cellphoneNumber.replace(" ", "");

  if (cellphoneNumber.length < 5) {
    cellphoneNumber = cellphoneNumber.replaceAll("•", "");
  }

  // if the given string contains any characters that are not numerical digits
  // then return a blank string (the user will need to start typing the number again)
  // if (!/^0?[0-9]{0,9}$/.test(cellphoneNumber)) {
  //   return "";
  // }

  // return the processed cellphone number
  return cellphoneNumber;
};

export const WaitingListPopUpDialog = ({
  dialogProps,
  closeDialog,
  assetToken,
  isPublic,
  marketListingViewModel,
}: WaitingListPopUpDialogProps) => {
  const {
    market: {
      publicSubscriptionCommitmentManager,
      subscriptionCommitmentManager,
    },
  } = useAPIContext();
  const [canSubscribe, setCanSubscribe] = useState(false);
  const theme = useTheme();
  const { authContext } = useApplicationContext();
  const { myPerson } = usePersonContext();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const [subscribingToWaitingList, setSubscribingToWaitingList] =
    useState(false);

  const subscriptionBookViewModel =
    marketListingViewModel.marketSubscriptionOrderBookViewModel ??
    new MarketSubscriptionOrderBookViewModel();

  const investmentRanges = useInvestmentRanges(
    subscriptionBookViewModel.minimumOrderAmount,
    subscriptionBookViewModel.subscriptionAmount,
  );

  const [formState, validationResultState, formUpdater] = useValidatedForm(
    formDataValidationFunc,
    async () => {
      const initialFormData: FormData = {
        name: "",
        surname: "",
        phoneNumber: myPerson
          ?.getContactdetails()
          ?.getCellphonenumber()
          ?.getValue()
          ? initialProcessCellphoneNumber(
              myPerson?.getContactdetails()?.getCellphonenumber()?.getValue() ??
                "",
            )
          : "",
        email: "",
        range: "",
        isChecked: false,
        isPublic: isPublic,
      };

      return initialFormData;
    },
    formDataUpdaterSpecs,
    {
      name: "",
      surname: "",
      phoneNumber: "",
      email: "",
      range: "",
      isChecked: false,
      isPublic: isPublic,
    },
    new Set<string>(),
  );

  const handleOnCLick = async () => {
    if (
      formState.name === "" &&
      formState.email === "" &&
      formState.phoneNumber === "" &&
      formState.range === "" &&
      !formState.isChecked
    ) {
      return;
    }

    // first handle the cellphone number post-processing
    // append +27 to the given phone number and update the form
    const postProcessedCellphoneNumber =
      "+27" +
      (formState.phoneNumber.length === 10
        ? // if the length is 10 characters then strip off the leading 0
          formState.phoneNumber.slice(1)
        : // otherwise do nothing
          formState.phoneNumber);

    setSubscribingToWaitingList(true);

    if (!isPublic) {
      if (!myPerson) {
        return;
      }

      // try 5 times regardless of failure
      let tryCount = 0;
      while (tryCount < 5) {
        tryCount++;
        const phoneNumber =
          formState.phoneNumber.charAt(0) === "•"
            ? ""
            : postProcessedCellphoneNumber;

        try {
          // subscribe to waiting list
          await subscriptionCommitmentManager.commitToSubscription(
            new CommitToSubscriptionRequest()
              .setContext(authContext.toFuture())
              .setToken(assetToken.toFutureToken())
              .setRange(formState.range)
              .setUserlink(
                new SubscriptionCommitmentUserLink().setIdsubscriptioncommitmentuserlink(
                  new IDSubscriptionCommitmentUserLink()
                    .setId(authContext.userID)
                    .setCellphonenumber(phoneNumber),
                ),
              ),
          );

          // success - show snackbar, close dialog & return
          enqueueSnackbar("We'll notify you when subscriptions open up", {
            variant: "success",
          });
          closeDialog();
          return;
        } catch (e) {
          console.error(`unable to subscribe to the waiting list`, e);
        }

        await sleep(500);
      }
    } else {
      // try 5 times regardless of failure
      let tryCount = 0;
      while (tryCount < 5) {
        tryCount++;

        try {
          setSubscribingToWaitingList(true);
          await publicSubscriptionCommitmentManager.publicCommitToSubscription(
            new PublicCommitToSubscriptionRequest()
              .setEmail(formState.email)
              .setName(formState.name)
              .setSurname(formState.surname)
              .setCellphonenumber(postProcessedCellphoneNumber)
              .setToken(assetToken.toFutureToken())
              .setRange(formState.range),
          );

          // success - show snackbar, close dialog & return
          enqueueSnackbar("We'll notify you when subscriptions open up", {
            variant: "success",
          });
          closeDialog();
          return;
        } catch (e) {
          console.error(`unable to subscribe to the waiting list`, e);
        }

        await sleep(500);
      }
    }

    // if execution reaches here then there was an error, show warning andstop loading indicator
    enqueueSnackbar(`Something Went Wrong - Please Try Again`, {
      variant: "warning",
    });
    setSubscribingToWaitingList(false);
  };

  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        ...dialogProps.PaperProps,
        "data-component-info": JSON.stringify({
          component_id:
            marketListingViewModel.assetName + "_investment_intent_form",
          component_business_name: "asset_investors_intent_form",
          component_title: "Investment Intent Form",
          component_driver: InteractionDriver.form,
        } as DataComponentInfo),
        sx: {
          width: { sm: 542 },
          m: 0,
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            bottom: 0,
            right: 0,
            left: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          },
        },
      }}
    >
      <WaitingListTicketTitle closeDialog={closeDialog} />
      <DialogContent sx={{ p: 3, pb: 3 }}>
        <Box className={cx({ notPublic: !isPublic })} sx={{ pb: 1 }}>
          <Typography
            sx={{ mt: 3, fontWeight: "bold" }}
            children={"Join the waiting list"}
            variant={"h5"}
          />

          <Typography
            sx={(theme) => ({ color: theme.palette.text.secondary, mt: 2 })}
            children={"We will notify you once the subscription book opens."}
          />
          {isPublic && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
                gap: { sm: 3, xs: 1 },
                mt: { sm: 3, xs: 2 },
              }}
            >
              <TextField
                label={"Preferred Name"}
                fullWidth
                placeholder={"Enter Name"}
                value={formState.name}
                onChange={(e) => formUpdater.name(e.target.value)}
                error={!!validationResultState.fieldValidations.name}
                helperText={validationResultState.fieldValidations.name}
              />
              <TextField
                label={"Surname"}
                fullWidth
                placeholder={"Enter Surname"}
                value={formState.surname}
                onChange={(e) => formUpdater.surname(e.target.value)}
                error={!!validationResultState.fieldValidations.surname}
                helperText={validationResultState.fieldValidations.surname}
              />
            </Box>
          )}
          {isPublic && (
            <TextField
              sx={(theme) => ({ mt: 2, color: theme.palette.text.secondary })}
              label={"Email"}
              fullWidth
              placeholder={"e.g. name@youremail.com"}
              value={formState.email}
              onChange={(e) => formUpdater.email(e.target.value)}
              error={!!validationResultState.fieldValidations.email}
              helperText={validationResultState.fieldValidations.email}
            />
          )}
          <TextField
            sx={(theme) => ({ mt: 2, color: theme.palette.text.secondary })}
            label={"Mobile"}
            fullWidth
            placeholder={" 00 000 0000"}
            disabled={subscribingToWaitingList}
            value={formState.phoneNumber}
            InputProps={{
              // Persistent placeholder styling
              startAdornment: (
                <InputAdornment position="start">+27</InputAdornment>
              ),
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              if (
                value.length < formState.phoneNumber.length &&
                formState.phoneNumber.charAt(0) === "•"
              ) {
                formUpdater.phoneNumber("");
                return;
              }
              // only allow the state to update if the given value contains no invalid digits
              if (/^0?[0-9]{0,9}$/.test(String(value))) {
                formUpdater.phoneNumber(value);
              }
            }}
            error={!!validationResultState.fieldValidations.phoneNumber}
            helperText={validationResultState.fieldValidations.phoneNumber}
          />

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            select
            label={"I am considering an investment of"}
            value={formState.range}
            disabled={subscribingToWaitingList}
            onChange={(e) => {
              formUpdater.range(e.target.value);
              if (!isPublic && !canSubscribe) {
                setCanSubscribe(!canSubscribe);
              }
            }}
            error={!!validationResultState.fieldValidations.range}
            helperText={validationResultState.fieldValidations.range}
            InputProps={{
              // Persistent placeholder styling

              startAdornment: formState.range ? null : (
                <InputAdornment position="start">
                  <Typography
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    Please select a range…
                  </Typography>
                </InputAdornment>
              ),
            }}
            SelectProps={{
              IconComponent: KeyboardArrowDownOutlinedIcon, // Replace the default arrow with your custom icon
            }}
          >
            {investmentRanges.map((range) => (
              <MenuItem
                key={range}
                value={range}
                data-component-info={JSON.stringify({
                  component_id:
                    marketListingViewModel.assetName +
                    "_investment_intent_form",
                  component_business_name: "asset_investors_intent_form",
                  component_title: "Investment Intent Form",
                  component_driver: InteractionDriver.form,
                })}
                data-link-info={JSON.stringify({
                  content_interaction_id:
                    marketListingViewModel.assetName +
                    "_investment_intent_amount",
                  content_interaction_action: InteractionAction.Select,
                  content_interaction_type: InteractionType.Dropdown,
                  content_interaction_text: range,
                  content_interaction_driver:
                    InteractionDriver.WaitingListAmount,
                } as DataLinkInfoType)}
              >
                {range}
              </MenuItem>
            ))}
          </TextField>
          <Typography
            sx={(theme) => ({
              mt: 3,
              color: theme.palette.custom.lavenderLight,
            })}
            variant="body2"
          >
            <Typography fontWeight="bold" component="span">
              Please note:
            </Typography>{" "}
            To participate, you must first verify your identity
            and&nbsp;fund&nbsp;your&nbsp;account.
          </Typography>
          <FormControlLabel
            sx={{ mt: 3 }}
            control={
              <Checkbox
                value={formState.isChecked}
                onChange={(e) => formUpdater.isChecked(e.target.checked)}
              />
            }
            label={
              <Box component="span">
                <Typography
                  component="span"
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                  })}
                  variant="body2"
                >
                  {" "}
                  I hereby give Mesh permission to use my contact details to
                  keep me updated in accordance with the{" "}
                </Typography>
                <Link
                  variant="body2"
                  href="https://storage.mesh.trade/Website-Privacy-Policy/v2.0.0/7.%20Mesh_Website_Privacy_Policy_20210802_v4_FINAL_docx.pdf"
                  underline={"hover"}
                  sx={{ fontWeight: { sm: 400, xs: "bold" } }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mesh Privacy Policy.
                </Link>
              </Box>
            }
          />
        </Box>
        {isPublic && (
          <ReCAPTCHA
            sitekey={ConfigEnvironment.get("recaptchaSiteKey") as string}
            onChange={() => {
              setCanSubscribe(!canSubscribe);
            }}
            style={{
              marginTop: "20px", // Adjust margin as needed
              alignItems: "start",
            }}
            theme={"light"}
          />
        )}
        <Box
          className={cx({ notPublic: !isPublic })}
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            alignItems: { sm: "center", xs: "flex-start" },
            flexDirection: { sm: "row", xs: "column" },
            width: "100%",
            "&.notPublic": {
              justifyContent: { sm: "right" },
            },
          }}
        >
          {isPublic && (
            <Link
              sx={{ mb: { sm: 0, xs: 2 } }}
              target="_blank"
              href="https://www.mesh.trade/"
              underline="none"
            >
              <Typography> More About Mesh</Typography>
            </Link>
          )}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: { sm: "auto", xs: "100%" },
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                height: {
                  sm: 36,
                  xs: 48,
                },
              }}
              fullWidth={smDown}
              variant={"outlined"}
              children={"Close"}
              onClick={closeDialog}
              data-link-info={JSON.stringify({
                content_interaction_id:
                  marketListingViewModel.assetName + "_investment_intent_close",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Button,
                content_interaction_driver:
                  InteractionDriver.NotOptInJoinWaitingList,
              } as DataLinkInfoType)}
            />
            <LoadingButton
              sx={{
                height: {
                  sm: 36,
                  xs: 48,
                },
              }}
              fullWidth={smDown}
              variant="contained"
              color="primary"
              children="Join List"
              loading={subscribingToWaitingList}
              disabled={!canSubscribe || !validationResultState.valid}
              onClick={handleOnCLick}
              data-link-info={JSON.stringify({
                content_interaction_id:
                  marketListingViewModel.assetName +
                  "_investment_intent_submit",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Button,
                content_interaction_driver:
                  InteractionDriver.OptInJoinWaitingList,
              } as DataLinkInfoType)}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

interface WaitingListTicketTitleProps {
  closeDialog: () => void;
}

const WaitingListTicketTitle = ({
  closeDialog,
}: WaitingListTicketTitleProps) => {
  return (
    <DialogTitle
      sx={(theme) => ({
        boxShadow: {
          sm: 0,
          xs: 2,
        },
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gap: theme.spacing(1),
        alignItems: "center",
        backgroundColor: theme.palette.custom.midnight,
        borderBottom: "none",
      })}
    >
      <Typography variant="h5" children={"A Mesh Exclusive!"} />
      <IconButton
        sx={(theme) => ({ color: theme.palette.text.secondary })}
        onClick={() => {
          closeDialog();
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
