import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Theme,
  Tooltip,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { Amount } from "components/Ledger/Amount";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { Model as MarketSubscriptionOrderViewModel } from "james/views/marketSubscriptionOrderView";
import { useApplicationContext } from "context/Application/Application";
import { SubscriptionOrderStateChip } from "components/MarketSubscriptions/Chips";
import { SubscriptionOrderState } from "james/market/SubscriptionOrder";

interface SubscriptionOrderViewCardProps {
  selectedOrder: MarketSubscriptionOrderViewModel;
  actionNode?: React.ReactNode;
}

export const SubscriptionOrderViewCard = ({
  selectedOrder,
  actionNode,
}: SubscriptionOrderViewCardProps) => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const theme = useTheme();
  const { myClient } = useApplicationContext();

  const isEstimated =
    selectedOrder.flexibleUnitPrice &&
    selectedOrder.state !== SubscriptionOrderState.Settled &&
    selectedOrder.state !== SubscriptionOrderState.Cancelled;

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        ".valueTypography": {
          color: theme.palette.warning.main,
          fontWeight: "bold",
        },
        ".codeTypography": {
          color: alpha(theme.palette.warning.main, 0.38),
          cursor: "pointer",
          fontWeight: "bold",
        },
        ".codeTypography:hover": {
          color: alpha(theme.palette.warning.main, 0.5),
        },
      }}
    >
      <CardHeader
        avatar={
          <TokenIconViewUpload size={64} token={selectedOrder.tokens.token} />
        }
        titleTypographyProps={{ variant: "h5", fontWeight: "bold" }}
        title={selectedOrder.assetName}
        action={actionNode}
        subheader={selectedOrder.assetShortName}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: theme.palette.custom.spaceblue,
          color: theme.palette.text.secondary,
          py: 0.5,
          px: 4,
        }}
      >
        <Typography>Order No. {selectedOrder.number}</Typography>
      </Box>

      {/* Investor info */}
      <Box sx={{ p: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Box>
            <Typography
              variant="h4"
              sx={{ fontSize: "18px", fontWeight: "bold" }}
            >
              Subscription Order
            </Typography>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              {selectedOrder.ownerID === myClient?.ownerID ? "To" : "From"}{" "}
              {selectedOrder.issuerClientName}
            </Typography>
          </Box>
          <SubscriptionOrderStateChip state={selectedOrder.state} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", my: 3 }}>
          <Avatar src={selectedOrder.investorProfilePictureURL} />
          <Typography sx={{ ml: 2 }} variant="h6">
            {selectedOrder.investorClientName}
          </Typography>
        </Box>
        <Typography sx={{ color: theme.palette.text.tertiary }}>
          Investment Amount
        </Typography>
        <Amount amount={selectedOrder.amount} />
      </Box>

      {/* Issuer info */}
      <Box
        sx={{
          backgroundColor: theme.palette.custom.spaceblue,
          p: 4,
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box
            sx={{
              height: 40,
              maxWidth: 85,
              borderRadius: 0.5,
              overflow: "hidden",
              display: "flex",
            }}
          >
            <img
              src={selectedOrder.issuerProfilePictureURL}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
                borderRadius: "inherit",
              }}
            />
          </Box>
          <Typography variant="h6" sx={{ ml: 2 }}>
            {selectedOrder.issuerClientName}
          </Typography>
        </Box>
        {isEstimated && (
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              variant="h6"
              children="Estimated Values:"
              sx={{ mb: "2px" }}
            />
            <Tooltip
              arrow
              placement={smUp ? "right" : "top"}
              title={`Final values are calculated on settlement`}
            >
              <InfoIcon
                sx={{
                  height: 20,
                  color: theme.palette.text.secondary,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Box>
        )}
        <Box
          sx={{
            mb: 2,
            display: "grid",
            gridTemplateColumns: "auto auto",
          }}
        >
          {/* Amount of Tokens */}
          <Box>
            <Typography
              color={theme.palette.text.tertiary}
              children={"Tokens"}
            />
            <Amount
              reverse
              id="subscriptionOrderViewCard-tokens-amountField"
              formatTextNumOpts={{
                noDecimalPlaces: 7,
                addDecimalPadding: false,
              }}
              amount={selectedOrder.tokens}
            />
          </Box>

          {/* Price per Token */}
          <Box>
            <Typography
              color={theme.palette.text.tertiary}
              children={"Price per Token"}
            />
            <Amount
              id="subscriptionOrderViewCard-price-amountField"
              formatTextNumOpts={{
                noDecimalPlaces: 7,
                addDecimalPadding: false,
              }}
              amount={selectedOrder.unitPrice}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
