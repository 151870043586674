import React, { useContext, useEffect } from "react";
import { useFirebaseContext } from "context/Firebase";
import { Box, Button, Typography } from "@mui/material";
import { KYCStatusBanner } from "components/Banner/KYCStatusBanner/KYCStatusBanner";
import { useApplicationContext } from "context/Application/Application";
import { useAppTourContext } from "context/AppTour/AppTour";
import { ClientKYCStatus } from "james/client";
import { Client, ClientKind } from "james/client/Client";
import { NoticeBanner } from "components/NoticeBanner/NoticeBanner";
import meshLogo from "assets/images/logo/meshLogo.svg";
import { FullScreenNoticeRemoteConfig } from "context/Firebase/Firebase";
import background from "assets/images/background/background.png";
import { KYBStatusBanner } from "components/Banner/KYBStatusBanner/KYBStatusBanner";

interface ContextType {
  Banner: React.ReactNode;
  KYCBanner: React.ReactNode;
  KYBBanner: React.ReactNode;

  // Heights based on context
  NotificationBannerHeight: number;
  NotificationBannerPresent: boolean;
}

interface FullScreenNoticeProps {
  children: React.ReactNode;
  show?: boolean;
  title?: string;
  text?: string[];
  button?: {
    text?: string;
    link?: string;
  };
}

const BannerHeight = 48;

const Context = React.createContext({} as ContextType);

export function AppNoticeContext({ children }: { children?: React.ReactNode }) {
  const { firebaseRemoteConfig } = useFirebaseContext();

  const [showNotificationBanner, setShowNotificationBanner] =
    React.useState(false);

  const { myClientKYCStatus, myClient } = useApplicationContext();
  const { tourInProgress } = useAppTourContext();

  const [showKYCBanner, setShowKYCBanner] = React.useState(false);
  const [showKYBBanner, setShowKYBBanner] = React.useState(false);

  const [maintenanceScreenConfig, setMaintenanceScreenConfig] =
    React.useState<FullScreenNoticeRemoteConfig>({});

  const [showMaintenanceScreen, setShowMaintenanceScreen] =
    React.useState<boolean>(false);

  useEffect(() => {
    setShowMaintenanceScreen(
      firebaseRemoteConfig?.showFullScreenNotice || false,
    );
    setMaintenanceScreenConfig(
      firebaseRemoteConfig?.fullScreenNoticeConfig || {},
    );
  }, [
    firebaseRemoteConfig?.fullScreenNoticeConfig,
    firebaseRemoteConfig?.fullScreenNoticeConfig?.title,
    firebaseRemoteConfig?.fullScreenNoticeConfig?.text,
    firebaseRemoteConfig?.fullScreenNoticeConfig?.buttonText,
    firebaseRemoteConfig?.fullScreenNoticeConfig?.buttonHref,
  ]);

  // show banner if remote config is set, show banner again if anything changes on the remote conf
  useEffect(() => {
    setShowNotificationBanner(firebaseRemoteConfig?.showBanner || false);
  }, [
    firebaseRemoteConfig?.showBanner,
    firebaseRemoteConfig?.bannerConfig?.title,
    firebaseRemoteConfig?.bannerConfig?.disableClose,
    firebaseRemoteConfig?.bannerConfig?.icon,
    firebaseRemoteConfig?.bannerConfig?.color,
  ]);

  useEffect(() => {
    setShowKYCBanner(
      new Client(myClient).clientKind === ClientKind.IndividualType &&
        myClientKYCStatus !== ClientKYCStatus.VerifiedStatus &&
        !tourInProgress,
    );

    setShowKYBBanner(
      new Client(myClient).clientKind === ClientKind.CompanyType &&
        myClientKYCStatus !== ClientKYCStatus.VerifiedStatus &&
        !tourInProgress,
    );
  }, [myClientKYCStatus, tourInProgress, myClient]);

  const Banner: React.ReactNode = showNotificationBanner ? (
    <NoticeBanner
      onClose={() => {
        setShowNotificationBanner(false);
      }}
      icon={firebaseRemoteConfig?.bannerConfig?.icon}
      color={firebaseRemoteConfig?.bannerConfig?.color}
      closeable={!firebaseRemoteConfig?.bannerConfig?.disableClose}
      title={firebaseRemoteConfig?.bannerConfig?.title}
    />
  ) : null;

  const KYCBanner: React.ReactNode = showKYCBanner ? (
    <Box>
      <KYCStatusBanner
        setHideBanner={() => {
          setShowKYCBanner(false);
        }}
      />
    </Box>
  ) : null;

  const KYBBanner: React.ReactNode = showKYBBanner ? (
    <Box>
      <KYBStatusBanner
        setHideBanner={() => {
          setShowKYBBanner(false);
        }}
      />
    </Box>
  ) : null;

  let notificationBannerHeight = 0;
  if (showNotificationBanner) {
    notificationBannerHeight += BannerHeight;
  }
  if (showKYCBanner) {
    notificationBannerHeight += BannerHeight;
  }
  if (showKYBBanner) {
    notificationBannerHeight += BannerHeight;
  }

  return (
    <Context.Provider
      value={{
        Banner: Banner,
        KYCBanner: KYCBanner,
        KYBBanner: KYBBanner,
        NotificationBannerHeight: notificationBannerHeight,
        NotificationBannerPresent: showNotificationBanner,
      }}
    >
      <FullScreenNotice
        show={showMaintenanceScreen}
        title={maintenanceScreenConfig?.title}
        text={maintenanceScreenConfig?.text}
        button={{
          text: maintenanceScreenConfig?.buttonText,
          link: maintenanceScreenConfig?.buttonHref,
        }}
      >
        {children}
      </FullScreenNotice>
    </Context.Provider>
  );
}

const useAppNoticeContext = () => useContext(Context);
export { useAppNoticeContext };

const FullScreenNotice = (props: FullScreenNoticeProps) => {
  const show =
    props.show && props.title && props.button?.link && props.button.link;

  return show ? (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: 160,
          height: 80,
          top: 24,
          left: { sm: 24, xs: "calc(50% - 80px)" },
        }}
        component="img"
        src={meshLogo}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          color: theme.palette.text.primary,
          maxWidth: 600,
          textAlign: "center",
          p: 3,
        })}
      >
        <Typography
          sx={{
            mb: { sm: 5, xs: 4 },
            fontWeight: "bold",
            fontSize: { sm: "40px", xs: "32px" },
          }}
          variant={"h1"}
        >
          {props.title ?? "Mesh is down temporarily"}
        </Typography>
        {props.text &&
          props.text.map((v) => {
            return (
              <Typography sx={{ mb: 1, fontSize: { sm: "20px", xs: "16px" } }}>
                {v}
              </Typography>
            );
          })}
        {props?.button && (
          <Button
            sx={{
              mt: { sm: 5, xs: 4 },
              height: 36,
            }}
            variant="contained"
            color="primary"
            href={props.button.link}
          >
            {props.button.text ?? ""}
          </Button>
        )}
      </Box>
    </Box>
  ) : (
    <>{props.children}</>
  );
};
