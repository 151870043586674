import {
  BankAccountType,
  BankName,
} from "@mesh/common-js/src/banking/bankAccount_pb";

export function friendlyName(bankName?: BankName): string {
  switch (bankName) {
    case BankName.BANK_NAME_ABSA:
      return "Absa Bank";
    case BankName.BANK_NAME_AFRICAN_BANK:
      return "African Bank";
    case BankName.BANK_NAME_BIDVEST_BANK:
      return "Bidvest Bank";
    case BankName.BANK_NAME_CAPITEC_BANK:
      return "Capitec Bank";
    case BankName.BANK_NAME_DISCOVERY_BANK:
      return "Discovery Bank";
    case BankName.BANK_NAME_FIRST_NATIONAL_BANK:
      return "First National Bank";
    case BankName.BANK_NAME_FIRST_RAND_BANK:
      return "First Rand Bank";
    case BankName.BANK_NAME_GRINDROD_BANK:
      return "GrindRod Bank";
    case BankName.BANK_NAME_INVESTEC:
      return "Investec Bank";
    case BankName.BANK_NAME_MERCANTILE_BANK:
      return "Mercantile Bank";
    case BankName.BANK_NAME_NEDBANK:
      return "Nedbank";
    case BankName.BANK_NAME_OLD_MUTUAL:
      return "Old Mutual";
    case BankName.BANK_NAME_SASFIN_BANK:
      return "Sasfin Bank";
    case BankName.BANK_NAME_TYME_BANK:
      return "TymeBank";
    case BankName.BANK_NAME_POSTBANK:
      return "Postbank";
    case BankName.BANK_NAME_STANDARD_BANK:
      return "Standard Bank";
    default:
      return "";
  }
}

export function friendlyNameToBankName(friendlyName?: string): BankName {
  switch (friendlyName) {
    case "Absa Bank":
      return BankName.BANK_NAME_ABSA;
    case "African Bank":
      return BankName.BANK_NAME_AFRICAN_BANK;
    case "Bidvest Bank":
      return BankName.BANK_NAME_BIDVEST_BANK;
    case "Capitec Bank":
      return BankName.BANK_NAME_CAPITEC_BANK;
    case "Discovery Bank":
      return BankName.BANK_NAME_DISCOVERY_BANK;
    case "First National Bank":
      return BankName.BANK_NAME_FIRST_NATIONAL_BANK;
    case "First Rand Bank":
      return BankName.BANK_NAME_FIRST_RAND_BANK;
    case "GrindRod Bank":
      return BankName.BANK_NAME_GRINDROD_BANK;
    case "Investec Bank":
      return BankName.BANK_NAME_INVESTEC;
    case "Mercantile Bank":
      return BankName.BANK_NAME_MERCANTILE_BANK;
    case "Nedbank":
      return BankName.BANK_NAME_NEDBANK;
    case "Old Mutual":
      return BankName.BANK_NAME_OLD_MUTUAL;
    case "Sasfin Bank":
      return BankName.BANK_NAME_SASFIN_BANK;
    case "TymeBank":
      return BankName.BANK_NAME_TYME_BANK;
    case "Postbank":
      return BankName.BANK_NAME_POSTBANK;
    case "Standard Bank":
      return BankName.BANK_NAME_STANDARD_BANK;
    default:
      return BankName.BANK_NAME_UNDEFINED; // Return BANK_NAME_UNDEFINED for invalid or unknown friendly names
  }
}

export function bankUniversalBranchCode(bankName: BankName): string {
  switch (bankName) {
    case BankName.BANK_NAME_ABSA:
      return "632005";
    case BankName.BANK_NAME_AFRICAN_BANK:
      return "430000";
    case BankName.BANK_NAME_BIDVEST_BANK:
      return "462005";
    case BankName.BANK_NAME_SASFIN_BANK:
      return "683000";
    case BankName.BANK_NAME_CAPITEC_BANK:
      return "470010";
    case BankName.BANK_NAME_DISCOVERY_BANK:
      return "679000";
    case BankName.BANK_NAME_FIRST_NATIONAL_BANK:
      return "250655";
    case BankName.BANK_NAME_FIRST_RAND_BANK:
      return "250655";
    case BankName.BANK_NAME_GRINDROD_BANK:
      return "223626";
    case BankName.BANK_NAME_INVESTEC:
      return "580105";
    case BankName.BANK_NAME_NEDBANK:
      return "198765";
    case BankName.BANK_NAME_MERCANTILE_BANK:
      return "450105";
    case BankName.BANK_NAME_OLD_MUTUAL:
      return "462005";
    case BankName.BANK_NAME_POSTBANK:
      return "460005";
    case BankName.BANK_NAME_TYME_BANK:
      return "678910";
    case BankName.BANK_NAME_STANDARD_BANK:
      return "051001";
    default:
      return "";
  }
}

export const AllBankNames: BankName[] = [
  BankName.BANK_NAME_ABSA,
  BankName.BANK_NAME_BIDVEST_BANK,
  BankName.BANK_NAME_AFRICAN_BANK,
  BankName.BANK_NAME_CAPITEC_BANK,
  BankName.BANK_NAME_DISCOVERY_BANK,
  BankName.BANK_NAME_FIRST_NATIONAL_BANK,
  BankName.BANK_NAME_FIRST_RAND_BANK,
  BankName.BANK_NAME_GRINDROD_BANK,
  BankName.BANK_NAME_INVESTEC,
  BankName.BANK_NAME_MERCANTILE_BANK,
  BankName.BANK_NAME_NEDBANK,
  BankName.BANK_NAME_OLD_MUTUAL,
  BankName.BANK_NAME_SASFIN_BANK,
  BankName.BANK_NAME_POSTBANK,
  BankName.BANK_NAME_TYME_BANK,
  BankName.BANK_NAME_STANDARD_BANK,
];

export const getBankAccountTypes = (
  bankName: BankName | "",
): Array<BankAccountType> => {
  switch (bankName) {
    case BankName.BANK_NAME_ABSA:
    case BankName.BANK_NAME_FIRST_NATIONAL_BANK:
    case BankName.BANK_NAME_NEDBANK:
    case BankName.BANK_NAME_STANDARD_BANK:
    case BankName.BANK_NAME_POSTBANK:
      return AllBankAccountTypes;
    default:
      return [
        BankAccountType.BANK_ACCOUNT_TYPE_CHEQUE_CURRENT,
        BankAccountType.BANK_ACCOUNT_TYPE_SAVINGS,
        BankAccountType.BANK_ACCOUNT_TYPE_MONEY_MARKET,
      ];
  }
};

export const AllBankAccountTypes: BankAccountType[] = [
  BankAccountType.BANK_ACCOUNT_TYPE_CHEQUE_CURRENT,
  BankAccountType.BANK_ACCOUNT_TYPE_SAVINGS,
  BankAccountType.BANK_ACCOUNT_TYPE_MONEY_MARKET,
  BankAccountType.BANK_ACCOUNT_TYPE_MZANSI,
];

export function accountTypeFriendlyName(bankType?: BankAccountType): string {
  switch (bankType) {
    case BankAccountType.BANK_ACCOUNT_TYPE_CHEQUE_CURRENT:
      return "Cheque/Current";
    case BankAccountType.BANK_ACCOUNT_TYPE_SAVINGS:
      return "Savings";
    case BankAccountType.BANK_ACCOUNT_TYPE_MZANSI:
      return "Mzansi";
    case BankAccountType.BANK_ACCOUNT_TYPE_MONEY_MARKET:
      return "Money Market";
    default:
      return "";
  }
}

export function friendlyNameToAccountType(bankType: string): BankAccountType {
  switch (bankType) {
    case "Cheque/Current":
      return BankAccountType.BANK_ACCOUNT_TYPE_CHEQUE_CURRENT;
    case "Savings":
      return BankAccountType.BANK_ACCOUNT_TYPE_SAVINGS;
    case "Mzansi":
      return BankAccountType.BANK_ACCOUNT_TYPE_MZANSI;
    case "Money Market":
      return BankAccountType.BANK_ACCOUNT_TYPE_MONEY_MARKET;
    default:
      return BankAccountType.BANK_ACCOUNT_TYPE_UNDEFINED;
  }
}
