import React, { useEffect, useState } from "react";
import { Box, Typography, TypographyProps } from "@mui/material";
import { Token } from "james/ledger";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useErrorContext } from "context/Error";

export type NetworkLogoProps = {
  token: Token;
  typographyProps?: TypographyProps;
  reverseAlign?: boolean;
};

export const IssuerNameCard = (props: NetworkLogoProps) => {
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { errorContextErrorTranslator } = useErrorContext();

  const [issuerName, setIssuerName] = useState("");
  useEffect(() => {
    (async () => {
      try {
        setIssuerName((await getLedgerTokenViewModel(props.token)).issuer);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error geting issuer name: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    })();
  }, [props.token, getLedgerTokenViewModel]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: props.reverseAlign ? "flex-start" : "flex-end",
      }}
    >
      <Typography
        {...props.typographyProps}
        sx={(theme) => ({
          color: theme.palette.text.tertiary,
          lineHeight: "15px",
          fontSize: "12px",
        })}
        variant={"caption"}
        children={"Issuer:"}
      />
      <Typography
        noWrap
        {...props.typographyProps}
        sx={(theme) => ({
          color: theme.palette.text.primary,
          maxWidth: "100%",
          lineHeight: "15px",
          fontSize: "12px",
        })}
        variant={"body1"}
        children={issuerName}
      />
    </Box>
  );
};

export const IssuerName = (props: NetworkLogoProps) => {
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { errorContextErrorTranslator } = useErrorContext();

  const [issuerName, setIssuerName] = useState("");
  useEffect(() => {
    (async () => {
      try {
        setIssuerName((await getLedgerTokenViewModel(props.token)).issuer);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error geting issuer name: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    })();
  }, [props.token, getLedgerTokenViewModel]);

  return (
    <Typography
      {...props.typographyProps}
      variant={"body1"}
      children={`Issuer: ${issuerName}`}
    />
  );
};
