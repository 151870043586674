import React, { useMemo } from "react";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { HowDoesItWorkSectionProps } from "./HowDoesItWork";
import meshIconWithHoldingDev from "assets/images/howDoesItWork/meshIconWithHoldingDev.png";
import { ContentType } from "./HowDoesItWorkContentProvider";

type PrivatePlacmentType = {
  what: ContentType[];
  disclaimer: ContentType[];
};

export const PrivatePlacementSection = ({
  section,
}: HowDoesItWorkSectionProps) => {
  const theme = useTheme();

  const content: PrivatePlacmentType = useMemo(() => {
    return section.content as PrivatePlacmentType;
  }, []);

  return (
    <>
      <Box
        sx={[
          {
            display: "flex",
            flexDirection: {
              sm: "row",
              xs: "column",
            },
            backgroundColor: theme.palette.custom.cardInner,
          },
        ]}
      >
        <Box
          sx={[
            {
              display: "flex",
              p: {
                sm: 5,
                xs: 3,
              },
            },
          ]}
        >
          {/*What is*/}
          <Box
            sx={{
              position: "relative",
            }}
          >
            {/* funky border handle */}
            <Box
              sx={{
                position: "absolute",
                borderBottomRightRadius: "4px",
                [theme.breakpoints.up("sm")]: {
                  top: "21px",
                  bottom: "21px",
                  right: "-4px",
                  borderTopRightRadius: "4px",
                },
                [theme.breakpoints.down("sm")]: {
                  left: "24px",
                  bottom: "-4px",
                  right: "24px",
                  borderBottomLeftRadius: "4px",
                },
                border: `2px solid ${theme.palette.secondary.main}`,
              }}
            />
            <Card
              sx={{
                p: {
                  sm: theme.spacing(4, 4, 3, 4),
                  xs: theme.spacing(3, 3, 4, 3),
                },
                backgroundColor: theme.palette.custom.grape,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    sm: "row",
                    xs: "column",
                  },
                  alignItems: {
                    xs: "center",
                    sm: "flex-start",
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <Box
                    component={"img"}
                    src={meshIconWithHoldingDev}
                    sx={{
                      width: "90px",
                      height: "90px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    ml: {
                      sm: 3,
                    },
                    mt: {
                      sm: 0,
                      xs: 2,
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: {
                      sm: "flex-start",
                      xs: "center",
                    },
                    textAlign: {
                      sm: "left",
                      xs: "center",
                    },
                    position: "relative",
                  }}
                >
                  {/* What is ... ? */}
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      mb: 2,
                      textAlign: "center",
                    }}
                  >
                    {content.what[0].title}
                  </Typography>

                  {/* Private Placement description */}
                  <Box
                    sx={{
                      pr: { sm: 0, xs: 1 },
                      pb: { sm: 0, xs: 1 },
                    }}
                  >
                    {content.what.length !== 0 &&
                      content.what[0].info?.map((v, i) => {
                        return (
                          <Typography
                            key={i}
                            sx={{
                              fontSize: "14px",
                              textAlign: "left",
                              color: theme.palette.text.secondary,
                              mb: i === 0 ? 2 : 0,
                              lineHeight: "143%",
                            }}
                          >
                            {v}
                          </Typography>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          background: theme.palette.custom.grape,
        })}
      >
        <Box sx={{ pt: 5, pb: 2, px: 5 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {content.disclaimer[0].title}
          </Typography>
          {content.disclaimer[0].info?.map((text) => (
            <Typography
              sx={(theme) => ({
                fontStyle: "italic",
                color: theme.palette.text.secondary,
                lineHeight: "20px",
                fontSize: "14px",
                mb: 3,
              })}
            >
              {text}
            </Typography>
          ))}
        </Box>
      </Box>
    </>
  );
};
