import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { SubscriptionOrderBook } from "./SubscriptionOrderBook";
import { Amount } from "james/ledger";

export type NewSubscriptionOrderBookRequest = {
  context: Context;
  instrumentID: string;
  sourceAccountLedgerID: string;
  unitPrice: Amount;
  unitPriceDescription: string;
  flexibleUnitPrice: boolean;
  openDate: string;
  closeDate: string;
  fractionalisationAllowed: boolean;
  subscriptionAmount: Amount;
  minimumOrderAmount: Amount;
  overSubscriptionAmount: Amount;
  marketingThresholdSubscriptionAmount: Amount;
};

export type NewSubscriptionOrderBookResponse = {
  subscriptionOrderBook: SubscriptionOrderBook;
};

export type ResolveSubscriptionOrderBookIDStateRequest = {
  context: Context;
  subscriptionOrderBookID: string;
  ignoreResolutionCount: boolean;
};

export type ResolveSubscriptionOrderBookIDStateResponse = {
  subscriptionOrderBook: SubscriptionOrderBook;
};

export type RequestSubscriptionOrderBookIDCancellationRequest = {
  context: Context;
  subscriptionOrderBookID: string;
};

export type RequestSubscriptionOrderBookIDCancellationResponse = {
  subscriptionOrderBook: SubscriptionOrderBook;
};

export const SubscriptionOrderBookStateControllerServiceProviderName =
  "market-SubscriptionOrderBookStateController";

export const SubscriptionOrderBookStateController = {
  async NewSubscriptionOrderBook(
    request: NewSubscriptionOrderBookRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<NewSubscriptionOrderBookResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionOrderBookStateControllerServiceProviderName}.NewSubscriptionOrderBook`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrderBook: new SubscriptionOrderBook(
        response.subscriptionOrderBook,
      ),
    };
  },
  async ResolveSubscriptionOrderBookIDState(
    request: ResolveSubscriptionOrderBookIDStateRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ResolveSubscriptionOrderBookIDStateResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionOrderBookStateControllerServiceProviderName}.ResolveSubscriptionOrderBookIDState`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrderBook: new SubscriptionOrderBook(
        response.subscriptionOrderBook,
      ),
    };
  },
  async RequestSubscriptionOrderBookIDCancellation(
    request: RequestSubscriptionOrderBookIDCancellationRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RequestSubscriptionOrderBookIDCancellationResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionOrderBookStateControllerServiceProviderName}.RequestSubscriptionOrderBookIDCancellation`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrderBook: new SubscriptionOrderBook(
        response.subscriptionOrderBook,
      ),
    };
  },
};
