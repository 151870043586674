import { create } from "zustand";
import dayjs from "dayjs";
import {
  BankAccount,
  BankAccountType,
  BankName,
} from "@mesh/common-js/dist/banking/bankAccount_pb";
import { bankUniversalBranchCode } from "james/banking";

interface TBankingDetailsViewStoreState {
  loading: boolean;
  countryCode: string;
  bankName: "" | BankName;
  accountType: "" | BankAccountType;
  number: string;
  branchCode: string;
  lastUpdateDate: string;
  lastUpdateBy: string;
  bankNotFound: boolean;
  cachedBankAccount: BankAccount | undefined;
}

interface TBankingDetailsViewStoreActions {
  setLoading: (val: boolean) => void;
  setCountryCode: (val: string) => void;
  setBankName: (val: "" | BankName) => void;
  setAccountType: (val: "" | BankAccountType) => void;
  setNumber: (val: string) => void;
  setBranchCode: (val: string) => void;
  updateStateWithBankAccount: (b: BankAccount) => void;
  setLastUpdateDate: (val: string) => void;
  setLastUpdateBy: (val: string) => void;
  isBankDetailsPopulated: () => boolean;
  setBankNotFound: (val: boolean) => void;
  setCachedBankAccount: (val: BankAccount) => void;
  clear: () => void;
}

const initialBankingDetailsViewStoreState: TBankingDetailsViewStoreState = {
  loading: false,
  countryCode: "ZA",
  bankName: "",
  accountType: "",
  number: "",
  branchCode: "",
  lastUpdateBy: "",
  lastUpdateDate: dayjs().format(),
  bankNotFound: false,
  cachedBankAccount: undefined,
};

export const useBankingDetailsViewStore = create<
  TBankingDetailsViewStoreState & TBankingDetailsViewStoreActions
>((set, get) => ({
  ...initialBankingDetailsViewStoreState,
  setLoading: (val: boolean) => set(() => ({ loading: val })),
  setCountryCode: (val: string) => set(() => ({ countryCode: val })),
  setBankName: (val: "" | BankName) => {
    set(() => ({
      bankName: val,
      branchCode: bankUniversalBranchCode(val as BankName),
    }));
  },
  setAccountType: (val: "" | BankAccountType) =>
    set(() => ({ accountType: val })),
  setNumber: (val: string) => set(() => ({ number: val })),
  setBranchCode: (val: string) => set(() => ({ branchCode: val })),
  updateStateWithBankAccount: (b: BankAccount) =>
    set(() => ({
      countryCode: b.getCountrycode(),
      bankName: b.getBankname(),
      branchCode: b.getBranchcode(),
      accountType: b.getAccounttype(),
      number: b.getNumber(),
    })),
  setLastUpdateBy: (val: string) => set(() => ({ lastUpdateBy: val })),
  setLastUpdateDate: (val: string) => set(() => ({ lastUpdateDate: val })),
  isBankDetailsPopulated: () => {
    const state = get();

    if (state.countryCode == "") {
      return false;
    }

    if (state.bankName == "") {
      return false;
    }

    if (state.accountType == "") {
      return false;
    }

    if (state.number == "") {
      return false;
    }

    return state.branchCode != "";
  },
  setBankNotFound: (val: boolean) => set(() => ({ bankNotFound: val })),
  clear: () => set(() => initialBankingDetailsViewStoreState),
  setCachedBankAccount: (val: BankAccount | undefined) =>
    set(() => ({ cachedBankAccount: val })),
}));
