import { ValidationResult } from "common/validation";
import { Amount } from "james/ledger";
import { Key } from "james/key/Key";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { MarketSubscriptionOrderBookViewModel } from "james/views/marketSubscriptionOrderBookView";
import {
  getAvailableBalance,
  getTokenBalance,
} from "@mesh/common-js/dist/views/stellarAccountView";

export type FormData = {
  investmentAmount: Amount;
  tokenAmount: Amount;
  accountBalance: Amount;
  termsAndConditionsAccepted: boolean;
  termsAndConditionsDisplayed: boolean;
  subscriptionOrderBookViewModel: MarketSubscriptionOrderBookViewModel;
  selectedSourceAccountViewModel: StellarAccountViewModel;
  selectedAccountIdx: number;
  isSignatoryOnSourceAccount: boolean;
  userKeys: Key[];
  initialized: boolean;
};

export type FormDataUpdaterSpecsType = {
  investmentAmount: (amount: Amount, prevRequest?: FormData) => FormData;
  tokenAmount: (amount: Amount, prevRequest?: FormData) => FormData;
  accountBalance: (amount: Amount, prevRequest?: FormData) => FormData;
  termsAndConditionsAccepted: (
    termsAndConditionsAccepted: boolean,
    prevRequest?: FormData,
  ) => FormData;
  termsAndConditionsDisplayed: (
    termsAndConditionsDisplayed: boolean,
    prevRequest?: FormData,
  ) => FormData;
  selectedSourceAccountViewModel: (
    selectedSourceAccViewModel: StellarAccountViewModel,
    prevRequest?: FormData,
  ) => FormData;
  selectedAccountIdx: (idx: number, prevRequest?: FormData) => FormData;
  initialized: (initialized: boolean, prevRequest?: FormData) => FormData;
};

export const formDataUpdaterSpecs: FormDataUpdaterSpecsType = {
  investmentAmount(amount: Amount, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;

    const model = prevRequest.subscriptionOrderBookViewModel;
    const tokenAmount = model.token.newAmountOf(
      amount.value.dividedBy(model.unitPrice.value),
    );

    return {
      ...prevRequest,
      investmentAmount: amount,
      tokenAmount: tokenAmount,
    };
  },
  tokenAmount(amount: Amount, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      tokenAmount: amount,
    };
  },
  accountBalance(amount: Amount, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      accountBalance: amount,
    };
  },
  termsAndConditionsAccepted(
    termsAndConditionsAccepted: boolean,
    prevRequest?: FormData,
  ): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      termsAndConditionsAccepted: termsAndConditionsAccepted,
    };
  },
  termsAndConditionsDisplayed(
    termsAndConditionsDisplayed: boolean,
    prevRequest?: FormData,
  ): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      termsAndConditionsDisplayed: termsAndConditionsDisplayed,
    };
  },
  selectedSourceAccountViewModel(
    selectedSourceAccountViewModel: StellarAccountViewModel,
    prevRequest?: FormData,
  ): FormData {
    prevRequest = prevRequest as FormData;

    let accountBalance = prevRequest.accountBalance;
    let isSignatoryOnSourceAccount = prevRequest.isSignatoryOnSourceAccount;
    if (selectedSourceAccountViewModel) {
      // get updated account balance
      const updatedAccountBalance = getTokenBalance(
        selectedSourceAccountViewModel,
        accountBalance.token.toFutureToken(),
      );
      accountBalance = updatedAccountBalance
        ? Amount.fromFutureAmount(getAvailableBalance(updatedAccountBalance))
        : accountBalance.setValue("0");

      // get updated signatory status
      isSignatoryOnSourceAccount = false;
      for (const k of prevRequest.userKeys) {
        if (
          selectedSourceAccountViewModel
            .getSignatoriesList()
            .find((s) => s.getKey() === k.publicKey)
        ) {
          isSignatoryOnSourceAccount = true;
          break;
        }
      }
    }

    return {
      ...prevRequest,
      selectedSourceAccountViewModel,
      accountBalance,
      isSignatoryOnSourceAccount,
    };
  },
  selectedAccountIdx(idx: number, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      selectedAccountIdx: idx,
    };
  },
  initialized(initialized: boolean, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      initialized: initialized,
    };
  },
};

export const formDataValidationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  if (!formData.initialized) {
    return { valid: false, fieldValidations: {} };
  }

  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[field] = message;
  };

  const investmentAmount = formData.investmentAmount;
  const minOrderAmount =
    formData.subscriptionOrderBookViewModel.minimumOrderAmount;
  const overSubscriptionAmount =
    formData.subscriptionOrderBookViewModel.overSubscriptionAmount;
  const subscribedAmount =
    formData.subscriptionOrderBookViewModel.subscribedAmount;
  const accountBalance = formData.accountBalance;

  // TO DO: validate when even minOrderAmount in not remaining in book
  // Investment amount is at least min order amount
  if (investmentAmount.value.isLessThan(minOrderAmount.value)) {
    invalid(
      "investmentAmount",
      `Minimum order: ${AmountToString(minOrderAmount)}`,
    );
  }

  // Investment amount is not more than available in book
  if (
    overSubscriptionAmount.value.lt(
      subscribedAmount.value.plus(investmentAmount.value),
    )
  ) {
    const remainingAmount = overSubscriptionAmount.token.newAmountOf(
      overSubscriptionAmount.value.minus(subscribedAmount.value),
    );
    invalid(
      "investmentAmount",
      `Amount remaining: ${AmountToString(remainingAmount)}`,
    );
  }

  // Account balance is more than investment amount
  if (investmentAmount.value.gt(accountBalance.value)) {
    invalid(
      "investmentAmount",
      `Insufficient balance: ${AmountToString(accountBalance)}`,
    );
  }

  // signatory check
  if (!formData.isSignatoryOnSourceAccount) {
    invalid(
      "selectedSourceAccountViewModel",
      "You are not a signatory on this trading account",
    );
  }

  return validationResult;
};

export const AmountToString = (amount: Amount): string => {
  return `${amount.token.code} ${amount.value.toFormat(2)}`;
};
