"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allUnitCategories = void 0;
exports.unitToString = unitToString;
exports.stringToUnit = stringToUnit;
const unit_pb_1 = require("./unit_pb");
// Get all unit category as enum values
exports.allUnitCategories = Object.values(unit_pb_1.Unit).filter((value) => typeof value === "number");
// Define explicit mappings between Unit enums and custom string representations
const unitToStringMapping = {
    [unit_pb_1.Unit.UNDEFINED_UNIT]: "-",
    [unit_pb_1.Unit.NULL_UNIT]: "NULL",
    [unit_pb_1.Unit.NOTE_UNIT]: "Note",
    [unit_pb_1.Unit.SHARE_UNIT]: "Share",
    [unit_pb_1.Unit.PREFERENCE_SHARE_UNIT]: "Preference Share",
    [unit_pb_1.Unit.BOND_UNIT]: "Bond",
    [unit_pb_1.Unit.BILL_UNIT]: "Bill",
    [unit_pb_1.Unit.CONTRACT_UNIT]: "Contract",
    [unit_pb_1.Unit.OPTION_UNIT]: "Option",
    [unit_pb_1.Unit.TOKEN_UNIT]: "Token",
    [unit_pb_1.Unit.BARREL_UNIT]: "Barrel",
    [unit_pb_1.Unit.GRAM_UNIT]: "Gram",
    [unit_pb_1.Unit.KILOGRAM_UNIT]: "Kilogram",
    [unit_pb_1.Unit.METRIC_TON_UNIT]: "Metric Ton",
    [unit_pb_1.Unit.OUNCE_UNIT]: "Ounce",
    [unit_pb_1.Unit.HALF_OUNCE_UNIT]: "1/2 Ounce",
    [unit_pb_1.Unit.QUARTER_OUNCE_UNIT]: "1/4 Ounce",
    [unit_pb_1.Unit.ONE_TENTH_OUNCE_UNIT]: "1/10 Ounce",
    [unit_pb_1.Unit.ONE_HUNDREDTH_OUNCE_UNIT]: "1/100 Ounce",
};
// Reverse mapping from string to Unit enum
const stringToUnitMapping = {};
for (const [key, value] of Object.entries(unitToStringMapping)) {
    stringToUnitMapping[value] = Number(key);
}
class UnsupportedUnitError extends Error {
    constructor(unit) {
        const message = `Unsupported Unit: ${unit}`;
        super(message);
        this.unit = unit;
    }
}
/**
 * Converts a Unit enum instance to a custom string representation.
 * @param {Unit} unit - The unit category to convert.
 * @returns {string} The custom string representation of the unit category.
 */
function unitToString(unit) {
    if (unit in unitToStringMapping) {
        return unitToStringMapping[unit];
    }
    else {
        throw new UnsupportedUnitError(unit);
    }
}
class UnsupportedUnitStringError extends Error {
    constructor(unitStr) {
        const message = `Unsupported unit category string: ${unitStr}`;
        super(message);
        this.unitStr = unitStr;
    }
}
/**
 * Converts a custom string representation to a Unit enum instance.
 * @param {string} unitStr - The custom string representation of the unit category.
 * @returns {Unit} The corresponding Unit enum instance.
 */
function stringToUnit(unitStr) {
    if (unitStr in stringToUnitMapping) {
        return stringToUnitMapping[unitStr];
    }
    else {
        throw new UnsupportedUnitStringError(unitStr);
    }
}
