import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Clear as ClearIcon,
  DeleteForever as ClearFiltersIcon,
  FileCopy as CopyPasteIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
  RemoveRedEye as ViewAccountIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { useAPIContext } from "context/API";
import { useReadManyModel } from "@mesh/common-js-react/dist/views/stellarAccountView/modelReader_meshjsreact";
import { BPTable } from "components/Table/BPTable/BPTable";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Query as OldQuery } from "james/search/query";
import { TextField } from "@mesh/common-js-react/dist/FormFields";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Network } from "@mesh/common-js/dist/stellar/network_pb";
import { Popover } from "components/PopOver/Popover";
import ReactJson from "react-json-view";
// import { AccountStateChip } from "./Chips";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import {
  newORCriterion,
  newTextExactCriterion,
  newTextSubstringCriterion,
} from "@mesh/common-js/dist/search";
import { useIsMounted } from "hooks";
import { ReadManyModelRequest } from "@mesh/common-js/dist/views/stellarAccountView/modelReader_pb";
import { TransactionTable } from "components/Ledger/Transaction";

const initialQuery = new Query()
  .setLimit(15)
  .setOffset(0)
  .setSortingList([
    new Sorting().setField("id").setOrder(SortingOrder.DESC_SORTING_ORDER),
  ]);

export const AccountTable = () => {
  const {
    views: { stellarAccountViewModelReaderUNSCOPED },
  } = useAPIContext();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { viewConfiguration, authContext } = useApplicationContext();
  const [apiLoading, setAPILoading] = useState(false);
  const {
    setReadModelRequest,
    readManyModelRequest,
    readManyModelResponse,
    readManyModelLoading,
  } = useReadManyModel(stellarAccountViewModelReaderUNSCOPED, {
    initialRequest: new ReadManyModelRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  const { errorContextErrorTranslator } = useErrorContext();

  // table criteria
  const [textSearchCriterion, setTextSearchCriterion] = useState("");
  useEffect(() => {
    // prepare new initial criteria
    const criteria: Criterion[] = [];

    if (textSearchCriterion) {
      criteria.push(
        newORCriterion([
          newTextSubstringCriterion("id", textSearchCriterion),
          newTextSubstringCriterion("number", textSearchCriterion),
          newTextSubstringCriterion("label", textSearchCriterion),
          newTextSubstringCriterion("ledgerid", textSearchCriterion),
        ]),
      );
    }

    if (isMounted()) {
      setReadModelRequest(readManyModelRequest.setCriteriaList(criteria));
    }
  }, [textSearchCriterion]);

  const [selectedAccount, setSelectedAccount] = useState<
    StellarAccountViewModel | undefined
  >(undefined);
  const handleResolveState = async () => {
    if (!selectedAccount) {
      console.error("selected ledger account view model not set");
      return;
    }

    setAPILoading(true);
    try {
      // await AccountSubmissionResolver.ResolveAccountIDSubmission({
      //   context: authContext,
      //   accountID: selectedAccount.getId(),
      //   ignoreConfirmationCount: true,
      // });
      setSelectedAccount(undefined);
      // setReadModelRequest({ ...readManyModelRequest });
      enqueueSnackbar("Stellar Account State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving account state`, e);
      enqueueSnackbar(`error resolving account state: ${err.message}`, {
        variant: "error",
      });
    }
    setAPILoading(false);
  };

  const loading = readManyModelLoading || apiLoading;

  return (
    <BPTable
      initialDenseTable={true}
      loading={loading}
      height={window.innerHeight - noticeBannerHeight - 200}
      singleSelect
      onSingleSelectChange={(data) =>
        setSelectedAccount(data as StellarAccountViewModel)
      }
      title={"Accounts"}
      query={OldQuery.fromFutureQuery(readManyModelRequest.getQuery())}
      onQueryChange={(query) =>
        setReadModelRequest(
          readManyModelRequest.setQuery(query.toFutureQuery()),
        )
      }
      data={readManyModelResponse.getRecordsList()}
      totalNoRecords={readManyModelResponse.getTotal()}
      filters={(() => {
        const filters: React.ReactNode[] = [
          <TextField
            id={"stellarAccountTable-textFieldSearch-textField"}
            variant="outlined"
            margin="dense"
            sx={{ width: 400 }}
            label="Search Text Fields"
            placeholder="Start Typing..."
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterion ? (
                <InputAdornment
                  position="end"
                  children={
                    <IconButton
                      size="small"
                      onClick={() => setTextSearchCriterion("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterion}
            onChange={(e) => setTextSearchCriterion(e.target.value)}
          />,
        ];

        // if any criteria is set then show a clear all filters button
        if (textSearchCriterion) {
          filters.push(
            <Button
              sx={{ marginTop: "10px" }}
              id={"stellarAccountTable-clearAllFilters-button"}
              variant={"contained"}
              color={"secondary"}
              children={"clear all"}
              onClick={() => {
                setTextSearchCriterion("");
              }}
              startIcon={<ClearFiltersIcon />}
            />,
          );
        }

        return filters;
      })()}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        if (
          viewConfiguration?.Ledger?.StellarAccountViewModel?.Resolve &&
          selectedAccount
        ) {
          controls.push(
            <Button
              variant={"outlined"}
              id={"stellarAccountTable-resolveState-button"}
              children={"Resolve State"}
              onClick={handleResolveState}
            />,
          );
        }

        controls.push(
          <IconButton
            id={"stellarAccountTable-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={() => {
              setReadModelRequest(
                readManyModelRequest.setQuery(readManyModelRequest.getQuery()),
              );
            }}
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      columns={[
        {
          label: "ID",
          field: "id",
          accessor: (data) => {
            const accountViewModel = data as StellarAccountViewModel;
            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                <Typography
                  sx={{
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  variant="body1"
                  children={accountViewModel.getId()}
                />
                <CopyPasteIcon
                  sx={(theme) => ({
                    fontSize: 20,
                    color: theme.palette.action.disabled,
                    "&:hover": {
                      color: theme.palette.action.active,
                    },
                    cursor: "pointer",
                  })}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(accountViewModel.getId())
                      .then(() => enqueueSnackbar("Stellar Account ID copied"))
                  }
                />
              </Box>
            );
          },
        },
        {
          label: "Public Key",
          field: "ledgerid",
          accessor: (data) => {
            const accountViewModel = data as StellarAccountViewModel;
            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                <Typography
                  sx={{
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  variant="body1"
                  children={accountViewModel.getLedgerid()}
                />
                <CopyPasteIcon
                  sx={(theme) => ({
                    fontSize: 20,
                    color: theme.palette.action.disabled,
                    "&:hover": {
                      color: theme.palette.action.active,
                    },
                    cursor: "pointer",
                  })}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(accountViewModel.getLedgerid())
                      .then(() =>
                        enqueueSnackbar("Stellar Account public key copied"),
                      )
                  }
                />
              </Box>
            );
          },
        },
        {
          label: "Number",
          field: "number",
          accessor: (data) => {
            const accountViewModel = data as StellarAccountViewModel;
            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                <Typography
                  sx={{
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  variant="body1"
                  children={accountViewModel.getNumber()}
                />
                <CopyPasteIcon
                  sx={(theme) => ({
                    fontSize: 20,
                    color: theme.palette.action.disabled,
                    "&:hover": {
                      color: theme.palette.action.active,
                    },
                    cursor: "pointer",
                  })}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(accountViewModel.getNumber())
                      .then(() =>
                        enqueueSnackbar("Stellar Account Number copied"),
                      )
                  }
                />
              </Box>
            );
          },
        },
        {
          label: "Group Owner",
          field: "ownerid",
          accessor: (data) => {
            const accountViewModel = data as StellarAccountViewModel;
            return accountViewModel.getGroupname();
          },
        },
        {
          label: "Label",
          field: "label",
          accessor: (data) => {
            const accountViewModel = data as StellarAccountViewModel;
            return accountViewModel.getLabel();
          },
        },
        {
          field: "",
          label: "",
          minWidth: 40,
          sortable: false,
          accessor: (data) => {
            const controls: React.ReactNode[] = [];
            const accountViewModel = data as StellarAccountViewModel;

            controls.push(
              <Tooltip
                placement="top"
                title="View StellarAccountViewModel in Stellar Expert"
              >
                <IconButton
                  size="small"
                  id={`stellarAccountTable-${(
                    data as StellarAccountViewModel
                  ).getId()}-openAccountInStellarExpert-iconButton`}
                  onClick={() =>
                    window.open(
                      `https://stellar.expert/explorer/${
                        accountViewModel.getNetwork() ===
                        Network.STELLAR_TEST_SDF_NETWORK
                          ? "testnet"
                          : "public"
                      }/account/${accountViewModel.getLedgerid()}`,
                      "_blank",
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>,
            );

            controls.push(
              <Popover
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                popOverComponent={
                  <Card style={{ backgroundColor: "#1d1f21" }}>
                    <CardContent>
                      <ReactJson
                        name={false}
                        enableClipboard={(e) =>
                          navigator.clipboard
                            .writeText(JSON.stringify(e))
                            .then(() => enqueueSnackbar("copied"))
                        }
                        src={accountViewModel.toObject()}
                        theme="google"
                      />
                    </CardContent>
                  </Card>
                }
              >
                <Tooltip
                  title="Select to view account in pop-up"
                  placement="top"
                >
                  <ViewAccountIcon
                    sx={(theme) => ({
                      color: theme.palette.action.disabled,
                      "&:hover": {
                        color: theme.palette.action.active,
                      },
                      cursor: "pointer",
                    })}
                  />
                </Tooltip>
              </Popover>,
            );

            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                {controls.map((c, idx) => (
                  <React.Fragment key={idx}>{c}</React.Fragment>
                ))}
              </Box>
            );
          },
        },
      ]}
      expandRowComponent={{
        maxHeight: 350,
        component: (data) => (
          <TransactionTable
            height={300}
            title={"Transactions for Account"}
            constantCriteria={[
              newTextExactCriterion(
                "metadata.accountID",
                (data as StellarAccountViewModel).getId(),
              ),
            ]}
          />
        ),
      }}
    />
  );
};
