import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Query as PastQuery } from "james/search/query";
import { useAPIContext } from "../../../../context/API";
import { useApplicationContext } from "context/Application/Application";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { decimalToBigNumber, formatTextNum } from "@mesh/common-js/dist/num";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Info as InfoIcon, Refresh as ReloadIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ReadManyRateSourceRecordingRequest } from "@mesh/common-js/dist/financial/rateSourceRecordingReader_meshproto_pb";
import { useReadManyRateSourceRecording } from "@mesh/common-js-react/dist/financial/rateSourceRecordingReader_meshproto_meshjsreact";
import { useReadManyRateSource } from "@mesh/common-js-react/dist/financial/rateSourceReader_meshproto_meshjsreact";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { RateSourceRecording } from "@mesh/common-js/dist/financial/rateSourceRecording_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeFormatWithOffset } from "const/dateformats";
import { BPTable } from "components/Table";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { ReadManyRateSourceRequest } from "@mesh/common-js/dist/financial/rateSourceReader_meshproto_pb";
import {
  newTextExactCriterion,
  newTextSubstringCriterion,
} from "@mesh/common-js/dist/search";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";
import { RecordRateSourceDialog } from "./components/RecordRateSourceDialog";
dayjs.extend(utc);

const initialQuery = new Query().setOffset(0).setLimit(15);

export const RateSourceRecordings = () => {
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const { authContext, viewConfiguration } = useApplicationContext();
  const [showRateRecordDialog, setShowRateRecordDialog] = useState(false);
  const {
    financial: { rateSourceRecordingReader, rateSourceReader },
  } = useAPIContext();
  const [selectedRateSource, setSelectedRateSource] = useState<
    RateSource | undefined
  >(undefined);
  const {
    readManyRateSourceError,
    readManyRateSourceRequest,
    readManyRateSourceResponse,
    setReadRateSourceRequest,
    readManyRateSourceLoading,
  } = useReadManyRateSource(rateSourceReader, {
    initialRequest: new ReadManyRateSourceRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
  });
  const reloadRateSources = () =>
    setReadRateSourceRequest(readManyRateSourceRequest);

  // select a rate source as soon as possible rate sources have loaded
  useEffect(() => {
    if (
      !readManyRateSourceLoading &&
      readManyRateSourceResponse.getRecordsList().length &&
      !selectedRateSource
    ) {
      setSelectedRateSource(readManyRateSourceResponse.getRecordsList()[0]);
    }
  }, [readManyRateSourceResponse, readManyRateSourceLoading]);

  const {
    readManyRateSourceRecordingError,
    readManyRateSourceRecordingRequest,
    readManyRateSourceRecordingResponse,
    setReadRateSourceRecordingRequest,
    readManyRateSourceRecordingLoading,
  } = useReadManyRateSourceRecording(rateSourceRecordingReader, {
    initialRequest: new ReadManyRateSourceRecordingRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
    shouldNotExecute: !selectedRateSource,
  });

  // update to filter for selected rate source each time it changes
  const reloadRateSourceRecordings = () =>
    setReadRateSourceRecordingRequest(readManyRateSourceRecordingRequest);
  useEffect(() => {
    if (selectedRateSource) {
      setReadRateSourceRecordingRequest(
        readManyRateSourceRequest.setCriteriaList([
          newTextExactCriterion("ratesourceid", selectedRateSource.getId()),
        ]),
      );
    }
  }, [selectedRateSource]);

  if (readManyRateSourceRecordingError || readManyRateSourceError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: 200,
        }}
      >
        <Card>
          <CardHeader title={"Error Fetching Rate Source Recordings"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>
              Something went wrong while fetching rate source recordings.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                reloadRateSourceRecordings();
                reloadRateSources();
              }}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <>
      <BPTable
        disableSelect
        height={window.innerHeight - 140 - noticeBannerHeight}
        loading={readManyRateSourceRecordingLoading}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          if (
            viewConfiguration["Rate Sources"]?.["Record"] &&
            selectedRateSource
          ) {
            controls.push(
              <Button
                variant="contained"
                color="primary"
                disabled={
                  readManyRateSourceLoading ||
                  readManyRateSourceRecordingLoading
                }
                onClick={() => setShowRateRecordDialog(true)}
              >
                Record Rate
              </Button>,
            );
          }

          controls.push(
            <IconButton
              size={"small"}
              disabled={readManyRateSourceRecordingLoading}
              onClick={reloadRateSourceRecordings}
            >
              <ReloadIcon />
            </IconButton>,
          );

          return controls;
        })()}
        columns={[
          {
            label: "Date",
            field: "date",
            accessor: (data) => {
              if (!selectedRateSource) {
                return "-";
              }
              data as RateSourceRecording;
              return protobufTimestampToDayjs(data.getDate() ?? new Timestamp())
                .tz(timezoneToString(selectedRateSource.getTimezone()))
                .format(DateTimeFormatWithOffset);
            },
          },
          {
            label: "Rate",
            field: "rate.float",
            accessor: (data) => {
              data as RateSourceRecording;
              return formatTextNum(decimalToBigNumber(data.getRate()));
            },
          },
          {
            label: (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(1),
                })}
                alignItems={"center"}
              >
                <Typography>
                  <b>Last Modified</b>
                </Typography>
                <Tooltip
                  title={
                    selectedRateSource
                      ? `Times shown in ${timezoneToString(selectedRateSource.getTimezone())}, all times stored in UTC.`
                      : ""
                  }
                  onClick={(e) => e.stopPropagation()}
                  placement="top"
                >
                  <InfoIcon sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            ),
            field: "auditentry.date",
            accessor: (data) => {
              if (!selectedRateSource) {
                return "-";
              }
              const rowData = data as RateSourceRecording;
              return protobufTimestampToDayjs(
                rowData.getAuditentry()?.getTime() ?? new Timestamp(),
              )
                .tz(timezoneToString(selectedRateSource.getTimezone()))
                .format(DateTimeFormatWithOffset);
            },
          },
        ]}
        query={PastQuery.fromFutureQuery(
          readManyRateSourceRecordingRequest.getQuery(),
        )}
        onQueryChange={(query) =>
          setReadRateSourceRecordingRequest(
            readManyRateSourceRecordingRequest.setQuery(query.toFutureQuery()),
          )
        }
        title={
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              gap: theme.spacing(1),
              alignItems: "center",
            })}
          >
            <Typography>Rate Source Recordings for:</Typography>
            <Autocomplete
              clearOnBlur={false}
              clearOnEscape={true}
              disabled={readManyRateSourceRecordingLoading}
              sx={{ width: 300 }}
              // when form opens then search for a fresh list
              onOpen={() => {
                setReadRateSourceRequest(
                  readManyRateSourceRequest
                    .setContext(authContext.toFuture())
                    .setCriteriaList([])
                    .setQuery(initialQuery),
                );
              }}
              // when form closes if the rate source is still set then make sure that the
              // list of possible options includes the currently linked rate source
              onClose={() => {
                if (
                  selectedRateSource &&
                  !readManyRateSourceResponse
                    .getRecordsList()
                    .find((r) => r.getId() === selectedRateSource.getId())
                ) {
                  setReadRateSourceRequest(
                    readManyRateSourceRequest
                      .setContext(authContext.toFuture())
                      .setCriteriaList([
                        newTextExactCriterion("id", selectedRateSource.getId()),
                      ])
                      .setQuery(initialQuery),
                  );
                }
              }}
              // while form is open search by name
              onInputChange={(_, newText, reason) => {
                let criteria: Criterion[];

                if (newText && reason !== "reset") {
                  criteria = [newTextSubstringCriterion("name", newText)];
                } else {
                  criteria = [];
                }

                setReadRateSourceRequest(
                  readManyRateSourceRequest
                    .setContext(authContext.toFuture())
                    .setCriteriaList(criteria)
                    .setQuery(initialQuery),
                );
              }}
              isOptionEqualToValue={(option, value) =>
                option.getId() === value.getId()
              }
              getOptionLabel={(option) => option.getName()}
              options={readManyRateSourceResponse.getRecordsList()}
              loading={readManyRateSourceLoading}
              value={
                (selectedRateSource &&
                  readManyRateSourceResponse
                    .getRecordsList()
                    .find((i) => i.getId() === selectedRateSource.getId())) ??
                null
              }
              onChange={(_: React.SyntheticEvent, value: RateSource | null) => {
                if (value) {
                  setSelectedRateSource(value);
                } else {
                  setSelectedRateSource(undefined);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ mt: 0.5 }}
                  placeholder={"Search to find rate source..."}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {readManyRateSourceLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        }
        data={readManyRateSourceRecordingResponse
          .getRecordsList()
          .sort((a, b) => {
            const aTime = a.getAuditentry()?.getTime();
            const bTime = b.getAuditentry()?.getTime();
            if (aTime && bTime) {
              if (aTime > bTime) {
                return -1;
              } else if (bTime > aTime) {
                return 1;
              }
            }
            return 0;
          })}
        totalNoRecords={readManyRateSourceRecordingResponse.getTotal()}
      />

      {selectedRateSource && showRateRecordDialog && (
        <RecordRateSourceDialog
          closeDialog={() => setShowRateRecordDialog(false)}
          rateSourceToRecord={selectedRateSource}
          onRateSourceRecorded={reloadRateSourceRecordings}
        />
      )}
    </>
  );
};
