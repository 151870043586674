import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import cx from "classnames";

const PREFIX = "MetricItem";

const classes = {
  root: `${PREFIX}-root`,
  rootReverse: `${PREFIX}-rootReverse`,
  caption: `${PREFIX}-caption`,
  bold: `${PREFIX}-bold`,
  value: `${PREFIX}-value`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "grid",
    gridTemplateColumns: "auto",
    justifyItems: "end",
  },

  [`&.${classes.rootReverse}`]: {
    justifyItems: "start",
  },

  [`& .${classes.caption}`]: {
    color: theme.palette.text.secondary,
    lineHeight: "15px",
  },

  [`& .${classes.bold}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.value}`]: {
    marginTop: 2,
  },
}));

const formatPrice = (value: string) => {
  // Split the string into currency and amount
  const [currency, amount] = value.split(" ");

  // Parse the amount to a number
  const numberAmount = parseFloat(amount);

  // Check if parsing was successful
  if (isNaN(numberAmount)) {
    // If not a valid number, return the original value
    return value;
  }

  // Format the number with commas as thousand separators and dots as decimal points
  const formattedAmount = numberAmount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Combine currency and formatted amount
  return `${currency} ${formattedAmount}`;
};

const formatValue = (
  description: string,
  value: string,
  disclaimer?: string,
) => {
  // Step 1: Format the value if description includes "Price"
  const formattedValue = description.includes("Price")
    ? formatPrice(value)
    : value;

  // Step 2: Append '*' if disclaimer is true
  const finalValue = disclaimer ? `${formattedValue}*` : formattedValue;

  return finalValue;
};

interface MetricItemProps {
  description: string;
  value: string;
  disclaimer?: string;
  reverseAlign?: boolean;
}

export const MetricItem = ({
  description,
  disclaimer,
  value,
  reverseAlign,
}: MetricItemProps) => {
  return (
    <StyledBox
      className={cx(classes.root, {
        [classes.rootReverse]: reverseAlign,
      })}
    >
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.tertiary,
          lineHeight: "15px",
          fontSize: "12px",
        })}
      >
        {description}
      </Typography>

      <Typography
        id="value-Typography"
        variant={value?.length > 7 ? "h5" : "h4"}
        sx={{
          fontWeight: "bold",
        }}
        children={formatValue(description, value, disclaimer)}
      />

      <Box
        sx={{
          height: disclaimer ? "auto" : 16,
        }}
      >
        <Box>
          <Typography
            variant="subtitle2"
            sx={(theme) => ({
              fontWeight: "bold",
              fontSize: 9,
              color: theme.palette.warning.light,
              lineHeight: "14px",
            })}
          >
            {disclaimer ? `*` + disclaimer : ""}
          </Typography>
        </Box>
      </Box>
    </StyledBox>
  );
};
