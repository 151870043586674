import { defundOrderStateToString } from "@mesh/common-js/dist/banking/defundOrderState";
import { DefundOrderState } from "@mesh/common-js/src/banking/defundOrderState_pb";

export const AllDefundOrderStates: string[] = [
  defundOrderStateToString(
    DefundOrderState.UNDER_INVESTIGATION_DEFUND_ORDER_STATE,
  ),
  defundOrderStateToString(DefundOrderState.PENDING_DEFUND_ORDER_STATE),
  defundOrderStateToString(DefundOrderState.CANCELLED_DEFUND_ORDER_STATE),
  defundOrderStateToString(DefundOrderState.SETTLED_DEFUND_ORDER_STATE),
  defundOrderStateToString(
    DefundOrderState.AWAITING_SETTLEMENT_DEFUND_ORDER_STATE,
  ),
  defundOrderStateToString(
    DefundOrderState.SETTLEMENT_IN_PROGRESS_DEFUND_ORDER_STATE,
  ),
];
