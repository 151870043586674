/**
 * @fileoverview gRPC-Web generated client stub for banking
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: banking/bankAccountCreator.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var banking_bankAccount_pb = require('../banking/bankAccount_pb.js')
const proto = {};
proto.banking = require('./bankAccountCreator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountCreatorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountCreatorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.CreateNewBankAccountRequest,
 *   !proto.banking.CreateNewBankAccountResponse>}
 */
const methodDescriptor_BankAccountCreator_CreateNewBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountCreator/CreateNewBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.CreateNewBankAccountRequest,
  proto.banking.CreateNewBankAccountResponse,
  /**
   * @param {!proto.banking.CreateNewBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.CreateNewBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.CreateNewBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.CreateNewBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.CreateNewBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountCreatorClient.prototype.createNewBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountCreator/CreateNewBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountCreator_CreateNewBankAccount,
      callback);
};


/**
 * @param {!proto.banking.CreateNewBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.CreateNewBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountCreatorPromiseClient.prototype.createNewBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountCreator/CreateNewBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountCreator_CreateNewBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.CreateNewBankAccountUnscopedRequest,
 *   !proto.banking.CreateNewBankAccountUnscopedResponse>}
 */
const methodDescriptor_BankAccountCreator_CreateNewBankAccountUnscoped = new grpc.web.MethodDescriptor(
  '/banking.BankAccountCreator/CreateNewBankAccountUnscoped',
  grpc.web.MethodType.UNARY,
  proto.banking.CreateNewBankAccountUnscopedRequest,
  proto.banking.CreateNewBankAccountUnscopedResponse,
  /**
   * @param {!proto.banking.CreateNewBankAccountUnscopedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.CreateNewBankAccountUnscopedResponse.deserializeBinary
);


/**
 * @param {!proto.banking.CreateNewBankAccountUnscopedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.CreateNewBankAccountUnscopedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.CreateNewBankAccountUnscopedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountCreatorClient.prototype.createNewBankAccountUnscoped =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountCreator/CreateNewBankAccountUnscoped',
      request,
      metadata || {},
      methodDescriptor_BankAccountCreator_CreateNewBankAccountUnscoped,
      callback);
};


/**
 * @param {!proto.banking.CreateNewBankAccountUnscopedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.CreateNewBankAccountUnscopedResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountCreatorPromiseClient.prototype.createNewBankAccountUnscoped =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountCreator/CreateNewBankAccountUnscoped',
      request,
      metadata || {},
      methodDescriptor_BankAccountCreator_CreateNewBankAccountUnscoped);
};


module.exports = proto.banking;

