/**
 * @fileoverview gRPC-Web generated client stub for user
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: user/preApprovalRegistry.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var user_preApproval_pb = require('../user/preApproval_pb.js')

var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')
const proto = {};
proto.user = require('./preApprovalRegistry_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user.PreApprovalRegistryClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user.PreApprovalRegistryPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.SearchPreApprovalRegistryRequest,
 *   !proto.user.SearchPreApprovalRegistryResponse>}
 */
const methodDescriptor_PreApprovalRegistry_SearchPreApprovalRegistry = new grpc.web.MethodDescriptor(
  '/user.PreApprovalRegistry/SearchPreApprovalRegistry',
  grpc.web.MethodType.UNARY,
  proto.user.SearchPreApprovalRegistryRequest,
  proto.user.SearchPreApprovalRegistryResponse,
  /**
   * @param {!proto.user.SearchPreApprovalRegistryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.SearchPreApprovalRegistryResponse.deserializeBinary
);


/**
 * @param {!proto.user.SearchPreApprovalRegistryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.SearchPreApprovalRegistryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.SearchPreApprovalRegistryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.PreApprovalRegistryClient.prototype.searchPreApprovalRegistry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.PreApprovalRegistry/SearchPreApprovalRegistry',
      request,
      metadata || {},
      methodDescriptor_PreApprovalRegistry_SearchPreApprovalRegistry,
      callback);
};


/**
 * @param {!proto.user.SearchPreApprovalRegistryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.SearchPreApprovalRegistryResponse>}
 *     Promise that resolves to the response
 */
proto.user.PreApprovalRegistryPromiseClient.prototype.searchPreApprovalRegistry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.PreApprovalRegistry/SearchPreApprovalRegistry',
      request,
      metadata || {},
      methodDescriptor_PreApprovalRegistry_SearchPreApprovalRegistry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.AddPreApprovalListRequest,
 *   !proto.user.AddPreApprovalListResponse>}
 */
const methodDescriptor_PreApprovalRegistry_AddPreApprovalList = new grpc.web.MethodDescriptor(
  '/user.PreApprovalRegistry/AddPreApprovalList',
  grpc.web.MethodType.UNARY,
  proto.user.AddPreApprovalListRequest,
  proto.user.AddPreApprovalListResponse,
  /**
   * @param {!proto.user.AddPreApprovalListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.AddPreApprovalListResponse.deserializeBinary
);


/**
 * @param {!proto.user.AddPreApprovalListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.AddPreApprovalListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.AddPreApprovalListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.PreApprovalRegistryClient.prototype.addPreApprovalList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.PreApprovalRegistry/AddPreApprovalList',
      request,
      metadata || {},
      methodDescriptor_PreApprovalRegistry_AddPreApprovalList,
      callback);
};


/**
 * @param {!proto.user.AddPreApprovalListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.AddPreApprovalListResponse>}
 *     Promise that resolves to the response
 */
proto.user.PreApprovalRegistryPromiseClient.prototype.addPreApprovalList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.PreApprovalRegistry/AddPreApprovalList',
      request,
      metadata || {},
      methodDescriptor_PreApprovalRegistry_AddPreApprovalList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.DeletePreApprovalsRequest,
 *   !proto.user.DeletePreApprovalsResponse>}
 */
const methodDescriptor_PreApprovalRegistry_DeletePreApprovals = new grpc.web.MethodDescriptor(
  '/user.PreApprovalRegistry/DeletePreApprovals',
  grpc.web.MethodType.UNARY,
  proto.user.DeletePreApprovalsRequest,
  proto.user.DeletePreApprovalsResponse,
  /**
   * @param {!proto.user.DeletePreApprovalsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.DeletePreApprovalsResponse.deserializeBinary
);


/**
 * @param {!proto.user.DeletePreApprovalsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.DeletePreApprovalsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.DeletePreApprovalsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.PreApprovalRegistryClient.prototype.deletePreApprovals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.PreApprovalRegistry/DeletePreApprovals',
      request,
      metadata || {},
      methodDescriptor_PreApprovalRegistry_DeletePreApprovals,
      callback);
};


/**
 * @param {!proto.user.DeletePreApprovalsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.DeletePreApprovalsResponse>}
 *     Promise that resolves to the response
 */
proto.user.PreApprovalRegistryPromiseClient.prototype.deletePreApprovals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.PreApprovalRegistry/DeletePreApprovals',
      request,
      metadata || {},
      methodDescriptor_PreApprovalRegistry_DeletePreApprovals);
};


module.exports = proto.user;

