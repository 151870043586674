import React from "react";
import { CssBaseline } from "@mui/material";
import config from "react-global-configuration";
import "./index.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { FirebaseAppProvider } from "reactfire";
import { FirebaseContext } from "context/Firebase";
import { ErrorContext } from "context/Error";
import { LedgerTokenViewContext } from "./context/LedgerTokenView";
import { MuiThemeContext } from "./context/Theme";
import App from "./App";
import { reportWebVitals } from "./reportWebVitals";
import { PusherContext } from "./context/Pusher";
import { NotificationContext } from "./context/Notification";
import { LedgerContext } from "./context/Ledger";
import { MarketContext } from "./context/Market";
import { StellarContext } from "./context/Stellar";
import { PortfolioContext } from "./context/Portfolio";
import { SnackbarContext } from "./context/Snackbar";
import { BrowserRouter } from "react-router-dom";
import { AccountContext } from "./context/Account/Account";
import { VersionCheckContext } from "./context/VersionCheck";
import ApplicationContext from "./context/Application/Application";
import { AppTour } from "context/AppTour/AppTour";
import { FirebaseProductsProvider } from "components/Firebase/FirebaseProductsProvider";
import { APIProvider } from "context/API";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { QueryCacheControllerContext } from "./context/QueryCacheController/QueryCacheController";
import { AppNoticeContext } from "context/AppNotice/AppNotice";
import { BigNumber } from "bignumber.js";
import { SetupWebApp } from "./config";
import { MUAContext } from "context/MUA/MUA";
import { determineEnvironment } from "./utilities/general";
import { ClarityContext } from "context/MicrosoftClarity";
import { HelmetContext } from "context/Helmet/context";
import { SimulatedTimeContext } from "context/Time/SimulatedTime";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { PersonContext } from "context/Person/PersonService";
import { UserContext } from "context/User/UserService";
import { MFAContext } from "./context/MFA/MFA";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

// almost never show exponential notation
BigNumber.set({
  EXPONENTIAL_AT: [-50, 50],
});

const env = determineEnvironment();

SetupWebApp(env);

const container = document.getElementById("root");
const root = createRoot(container as Element | DocumentFragment);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <MuiThemeContext>
      <VersionCheckContext>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <QueryCacheControllerContext>
              <SnackbarContext>
                <SimulatedTimeContext>
                  <MFAContext>
                    <APIProvider>
                      <ErrorContext>
                        <FirebaseAppProvider
                          firebaseConfig={config.get("firebaseConfig")}
                        >
                          <FirebaseProductsProvider>
                            <FirebaseContext>
                              <ApplicationContext>
                                <PersonContext>
                                  <UserContext>
                                    <PusherContext>
                                      <NotificationContext>
                                        <LedgerTokenViewContext>
                                          <StellarContext>
                                            <AccountContext>
                                              <PortfolioContext>
                                                <MarketContext>
                                                  <LedgerContext>
                                                    <CssBaseline />
                                                    <LocalizationProvider
                                                      dateAdapter={AdapterDayjs}
                                                    >
                                                      <AppTour>
                                                        <AppNoticeContext>
                                                          <MUAContext>
                                                            <ClarityContext>
                                                              <HelmetContext>
                                                                <App />
                                                              </HelmetContext>
                                                            </ClarityContext>
                                                          </MUAContext>
                                                        </AppNoticeContext>
                                                      </AppTour>
                                                    </LocalizationProvider>
                                                  </LedgerContext>
                                                </MarketContext>
                                              </PortfolioContext>
                                            </AccountContext>
                                          </StellarContext>
                                        </LedgerTokenViewContext>
                                      </NotificationContext>
                                    </PusherContext>
                                  </UserContext>
                                </PersonContext>
                              </ApplicationContext>
                            </FirebaseContext>
                          </FirebaseProductsProvider>
                        </FirebaseAppProvider>
                      </ErrorContext>
                    </APIProvider>
                  </MFAContext>
                </SimulatedTimeContext>
              </SnackbarContext>
            </QueryCacheControllerContext>
          </QueryClientProvider>
        </BrowserRouter>
      </VersionCheckContext>
    </MuiThemeContext>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
