import { ValidationResult } from "common/validation";
import { MediaEntry } from "@mesh/common-js/dist/marketing/mediaEntry_pb";
import { NewsArticle } from "@mesh/common-js/dist/marketing/newsArticle_pb";
import { SocialMediaLinks } from "@mesh/common-js/dist/marketing/socialMediaLinks_pb";
import { IndependentReview } from "@mesh/common-js/dist/marketing/independentReview_pb";
import { InstrumentMarketingContent } from "@mesh/common-js/dist/marketing/instrumentMarketingContent_pb";

export type MarketingContentFormData = {
  marketingContent: InstrumentMarketingContent;
  marketingContentCopy: InstrumentMarketingContent;
};

export type FormUpdaterSpecsType = {
  marketingContent: (
    marketingContent: InstrumentMarketingContent,
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  investmentObjective: (
    investmentObjective: string,
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  socialMediaLinks: (
    socialMediaLinks: SocialMediaLinks,
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  setNewsArticles: (
    newsArticles: NewsArticle[],
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  addNewsArticle: (
    newsArticle: NewsArticle,
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  removeNewsArticle: (
    newsArticleIdx: number,
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  updateNewsArticle: (
    args: {
      newsArticle: NewsArticle;
      newsArticleIdx: number;
    },
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  setMediaEntries: (
    mediaEntries: MediaEntry[],
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  addMediaEntry: (
    mediaEntry: MediaEntry,
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  removeMediaEntry: (
    mediaEntryIdx: number,
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  updateMediaEntry: (
    args: {
      mediaEntry: MediaEntry;
      mediaEntryIdx: number;
    },
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  setIndependentReviews: (
    independentReview: IndependentReview[],
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  addIndependentReview: (
    independentReview: IndependentReview,
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  removeIndependentReview: (
    independentReviewIdx: number,
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
  updateIndependentReview: (
    args: {
      independentReview: IndependentReview;
      independentReviewIdx: number;
    },
    prevFormData?: MarketingContentFormData,
  ) => MarketingContentFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  marketingContent(
    marketingContent: InstrumentMarketingContent,
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData {
    const formData = prevFormData as MarketingContentFormData;

    return {
      ...formData,
      marketingContent: marketingContent,
      marketingContentCopy: InstrumentMarketingContent.deserializeBinary(
        marketingContent.serializeBinary(),
      ),
    };
  },
  investmentObjective(
    investmentObjective: string,
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData {
    const formData = prevFormData as MarketingContentFormData;
    return {
      ...formData,
      marketingContent: formData.marketingContent.setInvestmentobjective(
        investmentObjective.length > 10000
          ? formData.marketingContent.getInvestmentobjective()
          : investmentObjective,
      ),
    };
  },
  socialMediaLinks: (
    socialMediaLinks: SocialMediaLinks,
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    return {
      ...formData,
      marketingContent:
        formData.marketingContent.setSocialmedialinks(socialMediaLinks),
    };
  },
  setNewsArticles: (
    newsArticles: NewsArticle[],
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    formData.marketingContent.setNewsarticlesList(newsArticles);

    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  addNewsArticle: (
    newsArticle: NewsArticle,
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    formData.marketingContent.addNewsarticles(newsArticle);
    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  removeNewsArticle: (
    newsArticleIdx: number,
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    formData.marketingContent.setNewsarticlesList(
      formData.marketingContent
        .getNewsarticlesList()
        .filter((_, idx) => idx !== newsArticleIdx),
    );

    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  updateNewsArticle(
    {
      newsArticle,
      newsArticleIdx,
    }: {
      newsArticle: NewsArticle;
      newsArticleIdx: number;
    },
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData {
    const formData = prevFormData as MarketingContentFormData;

    const updatedNewsArticlesList =
      formData.marketingContent.getNewsarticlesList();
    updatedNewsArticlesList[newsArticleIdx] = newsArticle;

    formData.marketingContent.setNewsarticlesList(updatedNewsArticlesList);

    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  setMediaEntries: (
    mediaEntries: MediaEntry[],
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    formData.marketingContent.setMediaentriesList(mediaEntries);

    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  addMediaEntry: (
    mediaEntry: MediaEntry,
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    formData.marketingContent.addMediaentries(mediaEntry);
    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  removeMediaEntry: (
    mediaEntryIdx: number,
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    formData.marketingContent.setMediaentriesList(
      formData.marketingContent
        .getMediaentriesList()
        .filter((_, idx) => idx !== mediaEntryIdx),
    );

    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  updateMediaEntry(
    {
      mediaEntry,
      mediaEntryIdx,
    }: {
      mediaEntry: MediaEntry;
      mediaEntryIdx: number;
    },
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData {
    const formData = prevFormData as MarketingContentFormData;

    const updatedMediaEntriesList =
      formData.marketingContent.getMediaentriesList();
    updatedMediaEntriesList[mediaEntryIdx] = mediaEntry;

    formData.marketingContent.setMediaentriesList(updatedMediaEntriesList);

    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  setIndependentReviews: (
    independentReviews: IndependentReview[],
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    formData.marketingContent.setIndependentreviewsList(independentReviews);

    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  addIndependentReview: (
    independentReview: IndependentReview,
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    formData.marketingContent.addIndependentreviews(independentReview);
    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  removeIndependentReview: (
    independentReviewIdx: number,
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData => {
    const formData = prevFormData as MarketingContentFormData;

    formData.marketingContent.setIndependentreviewsList(
      formData.marketingContent
        .getIndependentreviewsList()
        .filter((_, idx) => idx !== independentReviewIdx),
    );

    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
  updateIndependentReview(
    {
      independentReview,
      independentReviewIdx,
    }: {
      independentReview: IndependentReview;
      independentReviewIdx: number;
    },
    prevFormData?: MarketingContentFormData,
  ): MarketingContentFormData {
    const formData = prevFormData as MarketingContentFormData;

    const updatedIndependentReviewsList =
      formData.marketingContent.getIndependentreviewsList();
    updatedIndependentReviewsList[independentReviewIdx] = independentReview;

    formData.marketingContent.setIndependentreviewsList(
      updatedIndependentReviewsList,
    );

    return {
      ...formData,
      marketingContent: formData.marketingContent,
    };
  },
};

export const formDataValidationFunc =
  async () // formData: MarketingContentFormData,
  : Promise<ValidationResult> => {
    // prepare validation result
    const validationResult: ValidationResult = {
      // assumed to true -
      // any error must set to false regardless of field touched state
      valid: true,
      // contains field validations
      fieldValidations: {},
    };

    // Perform validations

    return validationResult;
  };
