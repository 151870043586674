/**
 * @fileoverview gRPC-Web generated client stub for market
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: market/subscriptionOrderBookDownloader.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')
const proto = {};
proto.market = require('./subscriptionOrderBookDownloader_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.SubscriptionOrderBookDownloaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.SubscriptionOrderBookDownloaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.DownloadSubscriptionOrderBookRequest,
 *   !proto.market.DownloadSubscriptionOrderBookResponse>}
 */
const methodDescriptor_SubscriptionOrderBookDownloader_DownloadSubscriptionOrderBook = new grpc.web.MethodDescriptor(
  '/market.SubscriptionOrderBookDownloader/DownloadSubscriptionOrderBook',
  grpc.web.MethodType.UNARY,
  proto.market.DownloadSubscriptionOrderBookRequest,
  proto.market.DownloadSubscriptionOrderBookResponse,
  /**
   * @param {!proto.market.DownloadSubscriptionOrderBookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.DownloadSubscriptionOrderBookResponse.deserializeBinary
);


/**
 * @param {!proto.market.DownloadSubscriptionOrderBookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.DownloadSubscriptionOrderBookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.DownloadSubscriptionOrderBookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.SubscriptionOrderBookDownloaderClient.prototype.downloadSubscriptionOrderBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.SubscriptionOrderBookDownloader/DownloadSubscriptionOrderBook',
      request,
      metadata || {},
      methodDescriptor_SubscriptionOrderBookDownloader_DownloadSubscriptionOrderBook,
      callback);
};


/**
 * @param {!proto.market.DownloadSubscriptionOrderBookRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.DownloadSubscriptionOrderBookResponse>}
 *     Promise that resolves to the response
 */
proto.market.SubscriptionOrderBookDownloaderPromiseClient.prototype.downloadSubscriptionOrderBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.SubscriptionOrderBookDownloader/DownloadSubscriptionOrderBook',
      request,
      metadata || {},
      methodDescriptor_SubscriptionOrderBookDownloader_DownloadSubscriptionOrderBook);
};


module.exports = proto.market;

