import React, { useEffect, useState } from "react";
import { MarketListingViewModel } from "james/views/marketListingView";
import { MarketSubscriptionOrderBookViewModel } from "james/views/marketSubscriptionOrderBookView";
import { FlexiblePriceDialogFlow } from "./components/FlexiblePriceDialog";
import { FixedPriceInputDialog } from "./components/FixedPriceDialogFlow";

export interface SubscriptionDialogProps {
  open: boolean;
  marketListingViewModel: MarketListingViewModel;
  closeDialog: () => void;
}

export const SubscriptionDialog = ({
  open,
  marketListingViewModel,
  closeDialog,
}: SubscriptionDialogProps) => {
  const [subOrderBookModel, setSubOrderBookModel] = useState<
    MarketSubscriptionOrderBookViewModel | undefined
  >(undefined);

  useEffect(() => {
    if (!marketListingViewModel.marketSubscriptionOrderBookViewModel) {
      return;
    }
    setSubOrderBookModel(
      marketListingViewModel.marketSubscriptionOrderBookViewModel,
    );
  }, [marketListingViewModel]);

  if (subOrderBookModel?.flexibleUnitPrice) {
    return (
      <FlexiblePriceDialogFlow
        open={open}
        marketListingViewModel={marketListingViewModel}
        closeDialog={closeDialog}
      />
    );
  } else if (!subOrderBookModel?.flexibleUnitPrice) {
    return (
      <FixedPriceInputDialog
        open={open}
        marketListingViewModel={marketListingViewModel}
        closeDialog={closeDialog}
      />
    );
  }

  return null;
};
