import React from "react";
import {
  Box,
  Card,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HowDoesItWorkSectionProps } from "./HowDoesItWork";
import { ContentType } from "./HowDoesItWorkContentProvider";
import cx from "classnames";
import meshIconWithHoldingDev from "assets/images/howDoesItWork/meshIconWithHoldingDev.png";

type RedemptionSectionType = {
  body: ContentType[];
  description: ContentType[];
};

export const RedemptionSection = ({ section }: HowDoesItWorkSectionProps) => {
  const content = section.content as RedemptionSectionType;
  const theme = useTheme();
  return (
    <Box>
      {content.description && content.description.length > 0 && (
        <Box
          sx={{
            mx: {
              sm: 5,
              xs: 3,
            },
            mt: {
              sm: 5,
              xs: 3,
            },
            maxWidth: "498px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              mb: 1,
            }}
            variant="h5"
          >
            {content.description[0].title}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.text.secondary,
            }}
          >
            {content.description[0].info}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          pt: {
            sm: 5,
            xs: 3,
          },
          px: {
            sm: 5,
            xs: 3,
          },
          pb: 4,
          mt: 2,
          display: "flex",
          flexDirection: {
            sm: "row",
            xs: "column",
          },
          gap: 2,
        }}
      >
        {content.body.map((v, i, array) => {
          return (
            <Box
              key={i}
              sx={{
                flex: 1,
                pr: {
                  sm: array.length - 1 !== i ? 3 : 0,
                },
                pb: {
                  sm: 0,
                  xs: array.length - 1 !== i ? 2 : 0,
                },
              }}
            >
              <RedemptionCard
                title={v.title}
                info={v.info}
                image={v.imgURL ?? ""}
                end={array.length - 1 === i}
                theme={theme}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

interface ContentSection {
  title: string;
  image: string;
  info?: string[];
  theme: Theme;
}

const RedemptionCard = ({
  title,
  info,
  end,
  theme,
}: ContentSection & { end: boolean }) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        mt: {
          sm: 0,
          xs: 2,
        },
      }}
    >
      {/* Funky Border arrow handle thing */}
      {!end && (
        <Box
          sx={{
            position: "absolute",
            [theme.breakpoints.up("sm")]: {
              top: "16px",
              bottom: "20px",
              right: "-4px",
              width: "0px",
              borderTopRightRadius: "4px",
              mt: 3,
            },
            [theme.breakpoints.down("sm")]: {
              right: "20px",
              bottom: "-4px",
              left: "20px",
              borderBottomLeftRadius: "4px",
              mt: 3,
            },
            borderBottomRightRadius: "4px",
            border: `2px solid ${theme.palette.secondary.main}`,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              [theme.breakpoints.up("sm")]: {
                left: "-11px",
                top: "calc(50% - 11px)",
                transform: "rotate(45deg)",
              },
              [theme.breakpoints.down("sm")]: {
                top: "-11px",
                left: "calc(50% - 11px)",
                transform: "rotate(135deg)",
              },
              width: "24px",
              height: "24px",
              backgroundColor: theme.palette.custom.grape,
              borderTopRightRadius: "4px",
              borderTop: `4px solid ${theme.palette.secondary.main}`,
              borderRight: `4px solid ${theme.palette.secondary.main}`,
            }}
          />
        </Box>
      )}
      {end && smDown && (
        <Box
          sx={{
            position: "absolute",
            [theme.breakpoints.up("sm")]: {
              top: "16px",
              bottom: "20px",
              right: "-4px",
              width: "0px",
              borderTopRightRadius: "4px",
              mt: 3,
            },
            [theme.breakpoints.down("sm")]: {
              right: "20px",
              bottom: "-4px",
              left: "20px",
              borderBottomLeftRadius: "4px",
              mt: 3,
            },
            borderBottomRightRadius: "4px",
            border: `2px solid ${theme.palette.secondary.main}`,
          }}
        ></Box>
      )}
      <Card
        className={cx({
          singular: info?.length === 1,
        })}
        sx={{
          backgroundColor: theme.palette.custom.grape,
          pt: {
            sm: 10,
            xs: 8,
          },
          px: 3,
          pb: 5,
          "&.singular": { pb: 1 },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: {
            sm: info ? "flex-start" : "center",
            xs: "flex-start",
          },
        }}
      >
        <Box
          className={cx({
            singular: info?.length === 1,
          })}
          sx={{
            "&.singular": {
              display: "flex",
              justifyContent: { sm: "center", xs: "flex-start" },
              width: "100%",
            },
          }}
        >
          <Box
            component={"img"}
            //this is not dynamic as I have no requirements to add an additional section to the adv so I am taking a short cut
            src={meshIconWithHoldingDev}
            sx={{
              width: {
                xs: "104px",
                sm: "120px",
              },
              height: {
                xs: "104px",
                sm: "120px",
              },
              position: "absolute",
              top: {
                xs: "-40px",
                sm: "-44px",
              },
              right: "calc(50% - 52px)",
            }}
          />
          <Typography
            sx={{
              maxWidth: "100%",
              fontWeight: "600",
              //Please note this looks dumb but it works if you need to know how or why please ask Liam or Aidan
              pr: { sm: 0, xs: "104px" },
            }}
          >
            {title}
          </Typography>
        </Box>
        {info?.length !== 0 &&
          info?.map((v, i) => {
            return (
              <Typography
                className={cx({
                  singular: info?.length === 1,
                })}
                sx={{
                  mt: 2,
                  width: { sm: "100%" },
                  textAlign: "left",
                  color: theme.palette.text.secondary,
                  "&.singular": {
                    px: { sm: 4 },
                    mb: 2,
                  },
                }}
                key={i}
              >
                {v}
              </Typography>
            );
          })}
      </Card>
    </Box>
  );
};
