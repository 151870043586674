"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionWrapper = void 0;
const transaction_pb_1 = require("../stellar/transaction_pb");
const network_pb_1 = require("../stellar/network_pb");
const transaction_pb_2 = require("./transaction_pb");
const futureNetwork_pb_1 = require("./futureNetwork_pb");
class TransactionWrapper {
    constructor(t) {
        this._transaction = t;
    }
    get transaction() {
        return this._transaction;
    }
    getTransaction() {
        return this.transaction;
    }
    get id() {
        // get possible transactions
        const stellarTransaction = this._transaction.getStellartransaction();
        // convert and return if transaction is a stellar transaction
        if (stellarTransaction) {
            return stellarTransaction.getId();
        }
        // all possible transaction types exhausted
        throw new Error(`invalid/unsupported wrapped transaction type: ${this._transaction.toObject()}`);
    }
    get ledgerid() {
        // get possible transactions
        const stellarTransaction = this._transaction.getStellartransaction();
        // convert and return if transaction is a stellar transaction
        if (stellarTransaction) {
            return stellarTransaction.getLedgerid();
        }
        // all possible transaction types exhausted
        throw new Error(`invalid/unsupported wrapped transaction type: ${this._transaction.toObject()}`);
    }
    getLedgerid() {
        return this.ledgerid;
    }
    getId() {
        return this.id;
    }
    get state() {
        // get possible transactions
        const stellarTransaction = this._transaction.getStellartransaction();
        // convert and return if transaction is a stellar transaction
        if (stellarTransaction) {
            switch (stellarTransaction.getState()) {
                case transaction_pb_1.TransactionState.UNDEFINED_TRANSACTION_STATE:
                    return transaction_pb_2.TransactionState.UNDEFINED_TRANSACTION_STATE;
                case transaction_pb_1.TransactionState.DRAFT_TRANSACTION_STATE:
                    return transaction_pb_2.TransactionState.DRAFT_TRANSACTION_STATE;
                case transaction_pb_1.TransactionState.SIGNING_IN_PROGRESS_TRANSACTION_STATE:
                    return transaction_pb_2.TransactionState.SIGNING_IN_PROGRESS_TRANSACTION_STATE;
                case transaction_pb_1.TransactionState.PENDING_TRANSACTION_STATE:
                    return transaction_pb_2.TransactionState.PENDING_TRANSACTION_STATE;
                case transaction_pb_1.TransactionState.SUBMISSION_IN_PROGRESS_TRANSACTION_STATE:
                    return transaction_pb_2.TransactionState.SUBMISSION_IN_PROGRESS_TRANSACTION_STATE;
                case transaction_pb_1.TransactionState.FAILED_TRANSACTION_STATE:
                    return transaction_pb_2.TransactionState.FAILED_TRANSACTION_STATE;
                case transaction_pb_1.TransactionState.INDETERMINATE_TRANSACTION_STATE:
                    return transaction_pb_2.TransactionState.INDETERMINATE_TRANSACTION_STATE;
                case transaction_pb_1.TransactionState.SUCCESSFUL_TRANSACTION_STATE:
                    return transaction_pb_2.TransactionState.SUCCESSFUL_TRANSACTION_STATE;
                default:
                    throw new TypeError(`invalid/unsupported wrapped stellar transaction state: '${stellarTransaction.getState()}'`);
            }
        }
        // all possible transaction types exhausted
        throw new TypeError(`invalid/unsupported wrapped transaction type: '${this._transaction.toObject()}'`);
    }
    getState() {
        return this.state;
    }
    get network() {
        // get possible transactions
        const stellarTransaction = this._transaction.getStellartransaction();
        // convert and return if transaction is a stellar transaction
        if (stellarTransaction) {
            switch (stellarTransaction.getNetwork()) {
                case network_pb_1.Network.UNDEFINED_NETWORK:
                    return futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK;
                case network_pb_1.Network.STELLAR_TEST_SDF_NETWORK:
                    return futureNetwork_pb_1.FutureNetwork.STELLAR_TEST_SDF_NETWORK;
                case network_pb_1.Network.STELLAR_PUBLIC_NETWORK:
                    return futureNetwork_pb_1.FutureNetwork.STELLAR_PUBLIC_NETWORK;
                default:
                    throw new TypeError(`invalid/unsupported wrapped stellar transaction network: '${stellarTransaction.getNetwork()}'`);
            }
        }
        // all possible transaction types exhausted
        throw new TypeError(`invalid/unsupported wrapped transaction type: '${this._transaction.toObject()}'`);
    }
    getNetwork() {
        return this.network;
    }
    get stateresolutioncount() {
        // get possible transactions
        const stellarTransaction = this._transaction.getStellartransaction();
        // convert and return if transaction is a stellar transaction
        if (stellarTransaction) {
            return stellarTransaction.getStateresolutioncount();
        }
        // all possible transaction types exhausted
        throw new Error(`invalid/unsupported wrapped transaction type: ${this._transaction.toObject()}`);
    }
    getStateresolutioncount() {
        return this.stateresolutioncount;
    }
    get metadata() {
        // get possible transactions
        const stellarTransaction = this._transaction.getStellartransaction();
        // convert and return if transaction is a stellar transaction
        if (stellarTransaction) {
            return stellarTransaction.getMetadataMap();
        }
        // all possible transaction types exhausted
        throw new Error(`invalid/unsupported wrapped transaction type: ${this._transaction.toObject()}`);
    }
    getMetadataMap() {
        return this.metadata;
    }
    get description() {
        let description = this.metadata.get("description");
        if (description) {
            return description;
        }
        description = this.metadata.get("type");
        if (description) {
            return description;
        }
        return "-";
    }
    getDescription() {
        return this.description;
    }
    get auditentry() {
        // get possible transactions
        const stellarTransaction = this._transaction.getStellartransaction();
        // convert and return if transaction is a stellar transaction
        if (stellarTransaction) {
            return stellarTransaction.getAuditentry();
        }
        // all possible transaction types exhausted
        throw new Error(`invalid/unsupported wrapped transaction type: ${this._transaction.toObject()}`);
    }
    getAuditentry() {
        return this.auditentry;
    }
}
exports.TransactionWrapper = TransactionWrapper;
