import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Typography,
  useTheme,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { MetricItem } from "./components/MetricItem";
import { DirectOrderSection } from "./components/DirectOrderSection";
import { SpotSection } from "./components/SpotSection";
import { PreLaunchSection } from "./components/PreLaunchSection";
import { Header } from "./components/Header";
import { InstrumentTypeCard } from "./components/InstrumentType/InstrumentType";
import { CapturePriceDialog } from "views/Marketplace/components/CapturePriceDialog";
import {
  CryptoPerformanceChartSplash,
  PerformanceChartSplash,
} from "./components/PerformanceChart";
import { AssetType } from "james/views/marketListingView/Model";
import { Mechanism, MechanismType } from "james/market";
import { Token } from "james/ledger";

import { YieldCoinLabel } from "components/Labels/YieldCoinLabel";
import LogRocket from "logrocket";
import { AssetEvent } from "const/logRocket";
import { useNavigate } from "react-router-dom";
import { SubscriptionSection } from "./components/SubscriptionSection/SubscriptionSection";
import { MarketListingViewModel } from "james/views/marketListingView";
import { IssuerIconSection } from "./components/IssuerIcon/IssuerIcon";
import { DataComponentInfo } from "const/gtm";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";
import { useIsMounted } from "hooks";
import { useErrorContext } from "context/Error";
import { ScopeDeterminer } from "james/search/scope";
import { useApplicationContext } from "context/Application/Application";
import { Permission } from "james/security/Permission";
import { IndicativePriceRecorderServiceProviderName } from "james/market/IndicativePriceRecorder";
import Lottie from "lottie-react";
import NewListingTagLeftData from "./components/Animations/NewListingTagLeft.json";
import NewListingTagRightData from "./components/Animations/NewListingTagRight.json";
import { PrivatePlacementChip } from "./components/PrivatePlacements/PrivatePlacementChip";

const PREFIX = "MarketplaceCard";

const classes = {
  cardRoot: `${PREFIX}-cardRoot`,
  headerRoot: `${PREFIX}-headerRoot`,
  headerTitle: `${PREFIX}-headerTitle`,
  titleSkeletonLayout: `${PREFIX}-titleSkeletonLayout`,
  cardContent: `${PREFIX}-cardContent`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.cardRoot}`]: {
    width: "100%",
    maxWidth: 328,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 340,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 384,
    },
  },

  [`& .${classes.headerRoot}`]: {
    height: 86,
    borderBottom: "none",
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.headerTitle}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1.5),
    alignItems: "center",
    padding: theme.spacing(0, 0.8, 0, 1),
    margin: `-3px 0px -3px 0px`,
  },

  [`& .${classes.titleSkeletonLayout}`]: {
    marginTop: -8,
  },

  [`& .${classes.cardContent}`]: {
    height: 290,
    [theme.breakpoints.up("sm")]: {
      height: 298,
    },
  },
}));

// Define priority for platform currencies
const quoteTokenPriority: string[] = ["mZAR", "USDC"];

// Asset types categorized for rendering logic
const cryptoCardAssetTypes: (AssetType | "")[] = [
  AssetType.CryptoCurrency,
  AssetType.RightsToACryptoCurrency,
  AssetType.RightsToAFiatCurrency,
  AssetType.YieldBearingStablecoin,
];

interface MarketplaceCardProps {
  marketListingViewModel: MarketListingViewModel;
  onView?: () => void;
}

export const MarketplaceCard = (props: MarketplaceCardProps) => {
  const isMounted = useIsMounted();
  const theme = useTheme();
  const { authContext, viewConfiguration } = useApplicationContext();
  const navigate = useNavigate();
  const [hover, setHover] = React.useState<boolean>(false);

  // State to store all market mechanisms
  const [allMarketMechanisms, setAllMarketMechanisms] = useState<
    Mechanism[] | undefined
  >(undefined);

  // Get limit order mechanism if it exists
  const [limitOrderMechanism] = useState<Mechanism | undefined>(() => {
    return props.marketListingViewModel.listingMarketMechanisms.find(
      (m) => m.type === MechanismType.LimitOrder,
    );
  });

  // Default quote token based on priority
  const [defaultQuoteToken, setDefaultQuoteToken] = useState<Token | undefined>(
    undefined,
  );
  const [capturePriceQuoteToken, setCapturePriceQuoteToken] = useState<
    Token | undefined
  >(undefined);

  // State for displaying Capture Price Dialog
  const [showCapturePrice, setShowCapturePrice] = useState(false);

  // Permissions and error handling
  const { errorContextErrorTranslator } = useErrorContext();
  const [userCanCapturePrice, setUserCanCapturePrice] = useState(false);

  // Responsive design checks
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const animationData = isMobile
    ? NewListingTagLeftData
    : NewListingTagRightData;
  const hasMetricData = props.marketListingViewModel.metricDescription !== "";

  // Determine if the asset is a crypto type
  const isCryptoCard = useRef<boolean>(
    cryptoCardAssetTypes.includes(props.marketListingViewModel.assetType),
  ).current;

  // Check if the asset's issue date has passed
  const assetIsIssued = useRef<boolean>(
    dayjs(props.marketListingViewModel.assetIssueDate).isBefore(dayjs()),
  ).current;

  // Set all market mechanisms
  useEffect(() => {
    if (!props.marketListingViewModel) return;
    setAllMarketMechanisms(
      props.marketListingViewModel.listingMarketMechanisms,
    );
  }, [props.marketListingViewModel]);

  // Determine the relevant market mechanism based on available mechanisms.
  const relevantMarketMechanism = (() => {
    if (!props.marketListingViewModel) {
      return undefined;
    }

    const { listingMarketMechanisms, marketSubscriptionOrderBookViewModel } =
      props.marketListingViewModel;

    // Check for the subscription order book's special case
    const subscriptionMechanism = listingMarketMechanisms.find(
      (m) => m.type === MechanismType.Subscription,
    );

    // If subscription book is the only mechanism or there is an unsettled subscription book
    if (
      subscriptionMechanism &&
      (listingMarketMechanisms.length === 1 ||
        marketSubscriptionOrderBookViewModel?.state !==
          SubscriptionOrderBookState.Settled)
    ) {
      return subscriptionMechanism;
    }

    // Otherwise, follow the usual order of priority
    const priorityTypes = [MechanismType.Spot, MechanismType.DirectOrder];

    for (const type of priorityTypes) {
      const mechanism = listingMarketMechanisms.find((m) => m.type === type);
      if (mechanism) {
        return mechanism;
      }
    }

    return undefined;
  })();

  // Set capture price quote token for subscriptions or direct orders
  useEffect(() => {
    if (!allMarketMechanisms) return;

    let quoteToken: Token | undefined = undefined;

    // Find subscription mechanism
    const subscriptionMechanism = allMarketMechanisms.find(
      (m) => m.type === MechanismType.Subscription,
    );
    if (
      subscriptionMechanism &&
      subscriptionMechanism.quoteParameters.length > 0
    ) {
      quoteToken = subscriptionMechanism.quoteParameters[0].quoteToken;
      setCapturePriceQuoteToken(quoteToken);
      return;
    }

    // Else, find direct orders mechanism
    const directOrdersMechanism = allMarketMechanisms.find(
      (m) => m.type === MechanismType.DirectOrder,
    );
    if (
      directOrdersMechanism &&
      directOrdersMechanism.quoteParameters.length > 0
    ) {
      quoteToken = directOrdersMechanism.quoteParameters[0].quoteToken;
      setCapturePriceQuoteToken(quoteToken);
      return;
    }
  }, [allMarketMechanisms]);

  // Set default quote token for spot mechanism
  useEffect(() => {
    if (!allMarketMechanisms) return;

    let quoteToken: Token | undefined = undefined;

    // Find spot mechanism
    const spotMechanism = allMarketMechanisms.find(
      (m) => m.type === MechanismType.Spot,
    );
    if (spotMechanism && spotMechanism.quoteParameters.length > 0) {
      // Sort quote parameters based on priority
      const sortedQuoteParameters = [...spotMechanism.quoteParameters].sort(
        (a, b) =>
          quoteTokenPriority.indexOf(a.quoteToken.code) -
          quoteTokenPriority.indexOf(b.quoteToken.code),
      );

      quoteToken =
        sortedQuoteParameters.find((qp) =>
          quoteTokenPriority.includes(qp.quoteToken.code),
        )?.quoteToken || sortedQuoteParameters[0].quoteToken;
    }

    // Fallback to first available quote token if none selected
    if (!quoteToken && allMarketMechanisms.length > 0) {
      const firstMechanism = allMarketMechanisms[0];
      if (firstMechanism.quoteParameters.length > 0) {
        quoteToken = firstMechanism.quoteParameters[0].quoteToken;
      }
    }

    setDefaultQuoteToken(quoteToken);
  }, [allMarketMechanisms]);

  // Determine whether user can capture prices
  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    (async () => {
      try {
        const result = (
          await ScopeDeterminer.DetermineScopeAuthorisationByRoles({
            context: authContext,
            groupID: props.marketListingViewModel.assetOwnerID,
            buildScopeTree: false,
            service: new Permission({
              serviceName: "RecordIndicativePrice",
              serviceProvider: IndicativePriceRecorderServiceProviderName,
              description: "?",
            }),
          })
        ).authorized;
        if (isMounted()) {
          setUserCanCapturePrice(result);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user has permission to capture indicative price: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    })();
  }, [isMounted, authContext, props.marketListingViewModel.assetOwnerID]);

  return (
    <>
      <StyledCard
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: 344,
          [theme.breakpoints.up("sm")]: {
            maxWidth: 384,
          },
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          e.preventDefault();
          if (e && e.stopPropagation) {
            e.stopPropagation();
          }
          e.nativeEvent.stopImmediatePropagation();
          LogRocket.track(AssetEvent.explore, {
            assetName: props.marketListingViewModel.assetName,
            assetShortName: props.marketListingViewModel.assetShortName,
            assetType: props.marketListingViewModel.assetType,
          });
          if (props.onView) {
            props.onView();
          } else {
            navigate(
              `/market/asset-overview?code=${props.marketListingViewModel.token.code}&issuer=${props.marketListingViewModel.token.issuer}&network=${props.marketListingViewModel.token.network}`,
            );
          }
        }}
        data-component-info={JSON.stringify({
          component_id: "asset_card",
          component_business_name: "asset card",
          component_title: props.marketListingViewModel.assetName,
          component_driver: "drive_transaction",
        } as DataComponentInfo)}
      >
        {/* New Listing Animation if within 3 weeks of issue date */}
        {dayjs().isBefore(
          dayjs(props.marketListingViewModel.assetIssueDate).add(3, "weeks"),
        ) && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              overflow: "hidden",
              width: "inherit",
              pointerEvents: "none",
              zIndex: 0,
              borderTopLeftRadius: 10,
              opacity: hover ? 0 : 1,
              transition: "opacity 0.3s ease",
              justifyContent: "flex-start",
              [theme.breakpoints.up("sm")]: {
                justifyContent: "flex-end",
              },
            }}
          >
            <Box
              sx={{
                height: 60,
                width: 60,
              }}
            >
              <Lottie
                loop={true}
                autoplay={true}
                animationData={animationData}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Box>
        )}

        {/* Card Header with optional Capture Price */}
        <Header
          cardHover={hover}
          marketListingViewModel={props.marketListingViewModel}
          onView={props.onView}
          onEdit={
            userCanCapturePrice && capturePriceQuoteToken
              ? () => setShowCapturePrice(true)
              : undefined
          }
        />

        {/* Card body */}
        <CardContent
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            height: 245,
            p: {
              xs: theme.spacing(3, 2, 1, 2),
              sm: theme.spacing(3, 3, 1, 3),
            },
          }}
        >
          {(hasMetricData || props.marketListingViewModel.privateOffer) && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              {/* Show metric data if available */}
              {hasMetricData && (
                <MetricItem
                  description={props.marketListingViewModel.metricDescription}
                  value={props.marketListingViewModel.metricValue}
                  disclaimer={props.marketListingViewModel.metricDisclaimer}
                  reverseAlign
                />
              )}
              {props.marketListingViewModel.privateOffer && (
                <PrivatePlacementChip />
              )}
            </Box>
          )}

          {/* Action Section */}
          {(() => {
            // if there are no relevant market mechanisms then no action can be returned
            if (!relevantMarketMechanism) {
              return null;
            }

            switch (relevantMarketMechanism.type) {
              case MechanismType.Subscription:
                return (
                  <SubscriptionSection
                    marketListingViewModel={props.marketListingViewModel}
                  />
                );

              case MechanismType.Spot:
                return assetIsIssued ? (
                  <SpotSection
                    marketListingViewModel={props.marketListingViewModel}
                  />
                ) : (
                  <PreLaunchSection
                    issueDate={props.marketListingViewModel.assetIssueDate}
                  />
                );

              case MechanismType.DirectOrder:
                return assetIsIssued ? (
                  <DirectOrderSection
                    marketListingViewModel={props.marketListingViewModel}
                  />
                ) : (
                  <PreLaunchSection
                    issueDate={props.marketListingViewModel.assetIssueDate}
                  />
                );

              default:
                return null;
            }
          })()}

          {/* Yield Coin Label  */}
          {props.marketListingViewModel.assetType ===
            AssetType.YieldBearingStablecoin && (
            <YieldCoinLabel
              sx={{
                position: "absolute",
                left: 0,
                top: 120,
                borderRadius: "0 5px 5px 0",
              }}
            />
          )}
          {/* Empty space to position instrument type below */}
          {!hasMetricData && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                my: props.marketListingViewModel.privateOffer ? 1.8 : 4.5,
              }}
            />
          )}

          {/* Absolute positioned: Instrument Type & Return */}
          <Box
            sx={{
              zIndex: 1,
              marginTop: "auto",
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "flex-start",
              mt: 3,
              pb: 3,
            }}
          >
            <InstrumentTypeCard
              token={props.marketListingViewModel.token}
              assetType={props.marketListingViewModel.assetType}
            />
          </Box>

          {/* Absolute positioned: PerformanceChart */}
          <Box
            sx={{
              zIndex: 0,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: 80,
            }}
          >
            {isCryptoCard ? (
              defaultQuoteToken ? (
                <CryptoPerformanceChartSplash
                  baseToken={props.marketListingViewModel.token}
                  quoteToken={defaultQuoteToken}
                />
              ) : null
            ) : (
              <PerformanceChartSplash
                assetToken={props.marketListingViewModel.token}
              />
            )}
          </Box>
        </CardContent>
        <CardHeader
          sx={{ p: 0 }}
          title={
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                px: 3,
                py: 1,
                height: 64,
                overflowY: "clip",
              }}
            >
              {limitOrderMechanism &&
                viewConfiguration.Marketplace.Exchange?.User && (
                  <Button
                    sx={{
                      mr: "auto",
                    }}
                    onClick={(e) => {
                      if (e.stopPropagation) {
                        e.stopPropagation();
                      }
                      navigate(
                        `/market/exchange/${props.marketListingViewModel?.token.code}/${limitOrderMechanism?.quoteParameters[0].quoteToken.code}`,
                      );
                    }}
                  >
                    <Typography color="secondary" sx={{ fontWeight: "12px" }}>
                      View Exchange
                    </Typography>
                  </Button>
                )}
              <IssuerIconSection
                marketListingViewModel={props.marketListingViewModel}
              />
            </Box>
          }
        />
      </StyledCard>
      {showCapturePrice && capturePriceQuoteToken && (
        <CapturePriceDialog
          open={showCapturePrice}
          token={props.marketListingViewModel.token}
          assetID={props.marketListingViewModel.assetID}
          handleClose={() => setShowCapturePrice(false)}
          assetName={props.marketListingViewModel.assetName}
          valuationToken={capturePriceQuoteToken}
        />
      )}
    </>
  );
};

export function MarketPlaceSkeletonCard() {
  return (
    <StyledCard classes={{ root: classes.cardRoot }}>
      <CardHeader
        className={classes.headerRoot}
        disableTypography
        title={
          <div className={classes.headerTitle}>
            <Skeleton
              animation="wave"
              variant={"circular"}
              height={50}
              width={50}
            />
            <div className={classes.titleSkeletonLayout}>
              <Skeleton animation="wave" height={30} width={200} />
              <Skeleton animation="wave" height={15} width={160} />
            </div>
          </div>
        }
      />
      <CardContent className={classes.cardContent}>
        <Skeleton animation="wave" height={"100%"} width={"100%"} />
      </CardContent>
    </StyledCard>
  );
}
