import { saveAs } from "file-saver";

export function download(url: string, name: string) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";

  xhr.onload = () => {
    saveAs(xhr.response, name);
  };

  xhr.onerror = () => {
    console.error("could not download file");
  };

  xhr.send();
}

interface DownloadExcelFileProps {
  // encoded string
  encodedString: string;
  // name of the file to save as
  fileName: string;
}

export function downloadExcelFile({
  encodedString,
  fileName,
}: DownloadExcelFileProps) {
  const decodedString = atob(encodedString);

  const uint8Array = new Uint8Array(decodedString.length);
  for (let i = 0; i < decodedString.length; ++i) {
    uint8Array[i] = decodedString.charCodeAt(i);
  }

  // Create a Blob from the binary data (MIME type for Excel files is usually "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
  const blob = new Blob([uint8Array], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a download link
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.xlsx`; // Set the file name

  // Append the link to the body (required for some browsers), then trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
}
