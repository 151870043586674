import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  TransactionBatch,
  TransactionQueueEntryRepository,
} from "james/ledger";
import { TransactionQueueEntry } from "james/ledger/TransactionQueueEntry";
import { useApplicationContext } from "context/Application/Application";
import { TextExactCriterion } from "james/search/criterion";
import {
  Transaction,
  TransactionState,
} from "@mesh/common-js/dist/ledger/transaction_pb";
import { useAPIContext } from "context/API";
import {
  FetchAllTransactionsRequest,
  FetchOneTransactionRequest,
} from "@mesh/common-js/dist/ledger/transactionFetcher_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import {
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  transactionStateToString,
  TransactionWrapper,
} from "@mesh/common-js/dist/ledger";
import { NewSorting, Query } from "james/search/query";
import { Popover } from "components/PopOver/Popover";
import { TransactionFailureReasonsCard } from "components/Ledger/Transaction";
import {
  InfoOutlined as InfoIcon,
  Error as ErrorIcon,
  FileCopy as CopyPasteIcon,
  OpenInNew as OpenInNewIcon,
  RemoveRedEye as ViewTransactionIcon,
} from "@mui/icons-material";
import { ResolveTransactionSigningRequest } from "@mesh/common-js/dist/ledger/transactionSigningResolver_pb";
import { ResolveTransactionIDSubmissionRequest } from "@mesh/common-js/dist/ledger/transactionSubmissionResolver_pb";
import { useSnackbar } from "notistack";
import ReactJson from "react-json-view";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/futureNetwork_pb";

type TransactionBatchDetailProps = {
  transactionBatch: TransactionBatch;
};

const TransactionBatchDetail = (props: TransactionBatchDetailProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const {
    ledger: { transactionFetcher },
  } = useAPIContext();

  const [transactionQueueEntries, setTransactionQueueEntries] = useState<
    | {
        [queueID: string]: TransactionQueueEntry[];
      }
    | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      try {
        const records = (
          await TransactionQueueEntryRepository.SearchTransactionQueueEntries({
            context: authContext,
            criteria: {
              transactionBatchID: TextExactCriterion(props.transactionBatch.id),
            },
            query: new Query({
              limit: 0,
              offset: 0,
              sorting: [NewSorting("id", "asc")],
            }),
          })
        ).records;
        const qes: { [queueID: string]: TransactionQueueEntry[] } = {};
        records.forEach((qe) => {
          if (!qes[qe.queueID]) {
            qes[qe.queueID] = [];
          }
          qes[qe.queueID].push(qe);
        });
        setTransactionQueueEntries(qes);
      } catch (e) {
        console.error("error searching transaction queue entries", e);
      }
    })();
  }, [props.transactionBatch]);

  const [transactionIdx, setTransactionIdx] = useState<
    | {
        [transactionID: string]: Transaction;
      }
    | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      try {
        const records = (
          await transactionFetcher.fetchAllTransactions(
            new FetchAllTransactionsRequest()
              .setContext(authContext.toFuture())
              .setCriteriaList([
                newTextExactCriterion(
                  "metadata.transactionBatchID",
                  props.transactionBatch.id,
                ),
              ]),
          )
        ).getTransactionsList();
        const idx: { [id: string]: Transaction } = {};
        records.forEach((txn) => {
          idx[new TransactionWrapper(txn).id] = txn;
        });
        setTransactionIdx(idx);
      } catch (e) {
        console.error("error searching for transactions in batch", e);
      }
    })();
  }, [props.transactionBatch]);

  if (!(!!transactionQueueEntries && !!transactionIdx)) {
    return (
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          columnGap: theme.spacing(1),
          rowGap: theme.spacing(0.5),
        })}
      >
        Loading...
      </Box>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        alignItems: "center",
        columnGap: theme.spacing(1),
        rowGap: theme.spacing(0.5),
      })}
    >
      {Object.keys(transactionQueueEntries).map((queueKey) => (
        <React.Fragment key={queueKey}>
          <Box>
            <Tooltip
              title={`Copy Transaction Queue Key '${queueKey}'`}
              placement="top"
            >
              <CopyPasteIcon
                sx={(theme) => ({
                  fontSize: 16,
                  color: theme.palette.action.disabled,
                  "&:hover": {
                    color: theme.palette.action.active,
                  },
                  cursor: "pointer",
                })}
                onClick={() =>
                  navigator.clipboard
                    .writeText(queueKey)
                    .then(() => enqueueSnackbar("Transaction Queue Key copied"))
                }
              />
            </Tooltip>
          </Box>
          <Queue
            queueKey={queueKey}
            queueEntries={transactionQueueEntries[queueKey] ?? []}
            transactionIdx={transactionIdx}
          />
        </React.Fragment>
      ))}
    </Box>
  );
};

type QueueProps = {
  queueKey: string;
  queueEntries: TransactionQueueEntry[];
  transactionIdx: { [transactionID: string]: Transaction };
};

const Queue = (props: QueueProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(1),
        backgroundColor: "grey",
        padding: theme.spacing(0.5),
      })}
    >
      {props.queueEntries.map((qe) => (
        <Box key={qe.id}>
          <QueueEntry queueEntry={qe} transactionIdx={props.transactionIdx} />
        </Box>
      ))}
    </Box>
  );
};

export { TransactionBatchDetail };

type QueueEntryProps = {
  queueEntry: TransactionQueueEntry;
  transactionIdx: { [transactionID: string]: Transaction };
};

const QueueEntry = (props: QueueEntryProps) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.custom.cardInner,
        padding: theme.spacing(1),
      })}
    >
      <Tooltip
        title={`Copy Transaction Queue Entry Key '${props.queueEntry.key}'`}
        placement="top"
      >
        <CopyPasteIcon
          sx={(theme) => ({
            fontSize: 16,
            color: theme.palette.action.disabled,
            "&:hover": {
              color: theme.palette.action.active,
            },
            cursor: "pointer",
          })}
          onClick={() =>
            navigator.clipboard
              .writeText(props.queueEntry.key)
              .then(() => enqueueSnackbar("Transaction Queue Entry Key copied"))
          }
        />
      </Tooltip>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1),
        })}
      >
        {props.queueEntry.transactionIDs.map((txnId) => (
          <Txn txnID={txnId} transactionIdx={props.transactionIdx} />
        ))}
      </Box>
    </Box>
  );
};

type TxnProps = {
  txnID: string;
  transactionIdx: { [transactionID: string]: Transaction };
};

interface MenuPosition {
  mouseX: number;
  mouseY: number;
}

const Txn = (props: TxnProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const {
    ledger: {
      transactionFetcher,
      ledgerTransactionSubmissionResolver,
      ledgerTransactionSigningResolver,
    },
  } = useAPIContext();

  const [rawTxn, setRawTxn] = useState<Transaction | undefined>(
    props.transactionIdx[props.txnID],
  );
  const [loading, setLoading] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const reload = () => setReloadTrigger((current) => !current);
  useEffect(() => {
    if (loading) {
      return;
    }
    if (!reloadTrigger) {
      return;
    }
    setLoading(true);
    setReloadTrigger(false);
    (async () => {
      try {
        setLoading(true);
        setRawTxn(
          (
            await transactionFetcher.fetchOneTransaction(
              new FetchOneTransactionRequest()
                .setContext(authContext.toFuture())
                .setCriteriaList([newTextExactCriterion("id", props.txnID)]),
            )
          ).getTransaction(),
        );
      } catch (e) {
        console.error(`error fetching transaction with id ${props.txnID}`, e);
      }
      setLoading(false);
    })();
  }, [props.txnID, reloadTrigger]);
  const txn = new TransactionWrapper(rawTxn || new Transaction());

  const [menuPosition, setMenuPosition] = useState<MenuPosition | null>(null);

  // Handle the right-click
  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    // Store the mouse position so we can show the menu there
    setMenuPosition({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
    });
  };

  const handleResolveSigning = async () => {
    if (!rawTxn) {
      console.error("raw txn not set");
      return;
    }

    setLoading(true);
    try {
      await ledgerTransactionSigningResolver.resolveTransactionSigning(
        new ResolveTransactionSigningRequest()
          .setContext(authContext.toFuture())
          .setTransactionid(txn.id),
      );
      reload();
      enqueueSnackbar("Transaction Signing Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      console.error(`error resolving transaction signing`, e);
      enqueueSnackbar(`error resolving transaction signing`, {
        variant: "error",
      });
    }
    setLoading(false);
  };
  const handleResolveSubmission = async () => {
    if (!rawTxn) {
      console.error("raw txn not set");
      return;
    }

    setLoading(true);
    try {
      await ledgerTransactionSubmissionResolver.resolveTransactionIDSubmission(
        new ResolveTransactionIDSubmissionRequest()
          .setContext(authContext.toFuture())
          .setTransactionid(txn.id)
          .setIgnoreconfirmationcount(true),
      );
      reload();
      enqueueSnackbar("Transaction Submission Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      console.error(`error resolving transaction submission`, e);
      enqueueSnackbar(`error resolving transaction submission`, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  // Close the menu
  const handleClose = () => {
    setMenuPosition(null);
  };

  if (!rawTxn || loading) {
    return (
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: 40,
          height: 40,
          backgroundColor: "grey",
        })}
      >
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          padding: 2,
          width: 40,
          height: 40,
          backgroundColor: (() => {
            switch (txn.state) {
              case TransactionState.DRAFT_TRANSACTION_STATE:
                return "grey";

              case TransactionState.SIGNING_IN_PROGRESS_TRANSACTION_STATE:
                return theme.palette.info.main;

              case TransactionState.PENDING_TRANSACTION_STATE:
                return theme.palette.info.main;

              case TransactionState.SUBMISSION_IN_PROGRESS_TRANSACTION_STATE:
                return theme.palette.info.main;

              case TransactionState.FAILED_TRANSACTION_STATE:
                return theme.palette.warning.main;

              case TransactionState.INDETERMINATE_TRANSACTION_STATE:
                return theme.palette.error.main;

              case TransactionState.SUCCESSFUL_TRANSACTION_STATE:
                return theme.palette.success.main;

              default:
                return "black";
            }
          })(),
        })}
        onContextMenu={handleContextMenu}
      >
        <Tooltip
          title={
            <ul>
              <li>State: {transactionStateToString(txn.state)}</li>
              <li>ID: {txn.id} (click to copy)</li>
            </ul>
          }
          placement="top"
        >
          <InfoIcon
            sx={{
              cursor: "pointer",
              fontSize: "16px",
              padding: 0,
            }}
          />
        </Tooltip>
        {(
          [
            TransactionState.SIGNING_IN_PROGRESS_TRANSACTION_STATE,
            TransactionState.SUBMISSION_IN_PROGRESS_TRANSACTION_STATE,
          ] as TransactionState[]
        ).includes(txn.state) && <CircularProgress size={15} />}
        {(
          [TransactionState.FAILED_TRANSACTION_STATE] as TransactionState[]
        ).includes(txn.state) && (
          <Popover
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            popOverComponent={
              <TransactionFailureReasonsCard transaction={txn.transaction} />
            }
          >
            <Tooltip title="Select to view failure reasons" placement="top">
              <ErrorIcon
                sx={{
                  cursor: "pointer",
                  fontSize: "16px",
                  padding: 0,
                }}
              />
            </Tooltip>
          </Popover>
        )}
        <Popover
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          popOverComponent={
            <Card style={{ backgroundColor: "#1d1f21" }}>
              <CardContent>
                <ReactJson
                  name={false}
                  enableClipboard={(e) =>
                    navigator.clipboard
                      .writeText(JSON.stringify(e))
                      .then(() => enqueueSnackbar("copied"))
                  }
                  src={rawTxn.toObject()}
                  theme="google"
                />
              </CardContent>
            </Card>
          }
        >
          <Tooltip title="Select to view transaction" placement="top">
            <ViewTransactionIcon
              sx={{
                cursor: "pointer",
                fontSize: "16px",
              }}
            />
          </Tooltip>
        </Popover>
        {!!txn.getLedgerid() && (
          <Tooltip placement="top" title="View Transaction in Stellar Expert">
            <IconButton
              size="small"
              onClick={() =>
                window.open(
                  `https://stellar.expert/explorer/${
                    txn.getNetwork() === FutureNetwork.STELLAR_TEST_SDF_NETWORK
                      ? "testnet"
                      : "public"
                  }/tx/${txn.getLedgerid()}`,
                  "_blank",
                )
              }
              sx={{
                width: "24px",
                height: "24px",
                padding: "4px",
              }}
            >
              <OpenInNewIcon
                sx={{
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Menu
        open={menuPosition !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          menuPosition !== null
            ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            reload();
            handleClose();
          }}
        >
          Reload
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleResolveSigning();
            handleClose();
          }}
        >
          Resolve Signing
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleResolveSubmission();
            handleClose();
          }}
        >
          Resolve Submission
        </MenuItem>
      </Menu>
    </>
  );
};
