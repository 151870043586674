import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Skeleton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import { ClientKYCStatus } from "james/client";
import { useSnackbar } from "notistack";
import { Amount, Token } from "james/ledger";
import { Amount as LedgerAmount } from "components/Ledger/Amount";
import { MechanismType } from "james/market";
import { useMarketContext } from "context/Market";
import { CouldNotGetPrice, SpotType } from "pkgTemp/market";
import { Warning } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import LogRocket from "logrocket";
import { AssetEvent } from "const/logRocket";
import { LedgerNetwork } from "james/ledger/Network";
import { SpotTradeDialog } from "components/SpotTradeDialog/SpotTradeDialog";
import { WarningWithActions } from "components/Snackbar/WarningWithActions";
import { useApplicationContext } from "context/Application/Application";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { PrivatePlacementDialog } from "../PrivatePlacements/PrivatePlacementDialog";

const PREFIX = "SpotSection";

const classes = {
  sectionRoot: `${PREFIX}-sectionRoot`,
  tradeButton: `${PREFIX}-tradeButton`,
  bold: `${PREFIX}-bold`,
  price: `${PREFIX}-price`,
  priceUnit: `${PREFIX}-priceUnit`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.sectionRoot}`]: {
    width: "100%",
  },

  [`& .${classes.tradeButton}`]: {
    backgroundColor: theme.palette.custom.midnight,
    color: theme.palette.secondary.main,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
  },

  [`& .${classes.bold}`]: {
    fontWeight: "bold",
  },

  [`& .${classes.price}`]: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    border: `1px solid ${theme.palette.text.disabled}`,
    borderTop: "none",
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
  },

  [`& .${classes.priceUnit}`]: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
}));

interface OrderSectionProps {
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
}

export const SpotSection = (props: OrderSectionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { viewConfiguration, myClientKYCStatus } = useApplicationContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { marketContextSpotPricer } = useMarketContext();
  const { enqueueSnackbar } = useSnackbar();
  const { current: isPublic } = useRef(window.location.href.includes("public"));
  const spotTradingViewConfiguration = viewConfiguration.SpotTrading || {};
  const [showSpotTradeDialog, setShowSpotTradeDialog] = useState(false);
  // get a price
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [indicativePrice, setIndicativePrice] = useState<Amount | undefined>(
    undefined,
  );
  const [showPrivatePlacementDialog, setShowPrivatePlacementDialog] =
    useState(false);
  const [errorGettingPrice, setErrorGettingPrice] = useState("");
  useEffect(() => {
    (async () => {
      setLoadingPrice(true);
      setErrorGettingPrice("");

      // find spot market mechanism
      const spotMarketMechanism =
        props.marketListingViewModel.listingMarketMechanisms.find(
          (mm) => mm.type === MechanismType.Spot,
        );
      if (!spotMarketMechanism) {
        console.error("expected to find spot market mechanism");
        return;
      }
      if (!spotMarketMechanism.quoteParameters.length) {
        console.error("expected at least 1 quote parameter");
        return;
      }

      // get default quote parameter
      let defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
        (qp) => qp.quoteToken.code === "mZAR",
      );
      if (!defaultQuoteParameter) {
        defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
          (qp) => qp.quoteToken.code === "USDC",
        );
      }
      if (
        !defaultQuoteParameter &&
        spotMarketMechanism.quoteParameters.length
      ) {
        defaultQuoteParameter = spotMarketMechanism.quoteParameters[0];
      }

      // return if no quote parameter was found
      if (!defaultQuoteParameter) {
        return;
      }

      // Get an indicative price by Pricing a Spot to BUY the minimum deal size
      // of the base amount, and then dividing the resultant quote amount by
      // the minimum deal size to get a normalised price.
      try {
        setIndicativePrice(
          defaultQuoteParameter.quoteToken.newAmountOf(
            (
              await marketContextSpotPricer.PriceSpot({
                spotType: SpotType.Buy,
                baseAmount: defaultQuoteParameter.minimumDealSize,
                quoteAmount: defaultQuoteParameter.quoteToken.newAmountOf("0"),
              })
            ).quoteAmount.value.div(
              defaultQuoteParameter.minimumDealSize.value,
            ),
          ),
        );
      } catch (e) {
        // log all errors
        console.error(`error pricing spot: ${e}`);

        if (!(e instanceof CouldNotGetPrice)) {
          // return without stopping price loading for unexpected error
          return;
        }

        // show canned error message for CouldNotGetPrice error
        setErrorGettingPrice(
          `Could not find a spot price for ${defaultQuoteParameter.minimumDealSize.token.code}/${defaultQuoteParameter.quoteToken.code} in the market`,
        );
      }

      setLoadingPrice(false);
    })();
  }, [props.marketListingViewModel.listingMarketMechanisms]);

  // check if dialog should open as a result of a redirect
  useEffect(() => {
    const assetToken = new Token({
      code: searchParams.get("code") ?? "",
      issuer: searchParams.get("issuer") ?? "",
      network: (searchParams.get("network") ?? "-") as LedgerNetwork,
    });
    if (
      assetToken.isEqualTo(props.marketListingViewModel.token) &&
      searchParams.get("spot") &&
      !isPublic &&
      spotTradingViewConfiguration.SubmitSpot
    ) {
      if (myClientKYCStatus === ClientKYCStatus.VerifiedStatus) {
        setShowSpotTradeDialog(true);
      } else {
        searchParams.delete("spot");
        setSearchParams(searchParams, { replace: true });
        enqueueSnackbar("A Verified KYC Status is Required to Trade", {
          variant: "warning",
        });
      }
    }
  }, [props.marketListingViewModel, myClientKYCStatus, enqueueSnackbar]);

  const handleTrade = () => {
    if (isPublic) {
      navigate(
        `/market/asset-overview?code=${props.marketListingViewModel.token.code}&issuer=${props.marketListingViewModel.token.issuer}&network=${props.marketListingViewModel.token.network}&spot=true`,
      );

      return;
    }
    if (
      props.marketListingViewModel.privateOffer &&
      !showPrivatePlacementDialog
    ) {
      setShowPrivatePlacementDialog(true);
      return;
    }

    if (myClientKYCStatus !== ClientKYCStatus.VerifiedStatus) {
      enqueueSnackbar("Want to Trade? You need to be verified", {
        preventDuplicate: true,
        content: (key, message) => (
          <WarningWithActions
            id={key}
            message={message}
            inLine={!isMobile}
            actions={
              <Button
                id="notVerifiedTrade-submitKYCInfo-button"
                onClick={() => {
                  navigate("/kyc");
                }}
                sx={(theme) => ({
                  color: theme.palette.secondary.contrastText,
                  borderColor: theme.palette.secondary.contrastText,
                  border: "1px solid",
                  width: isMobile ? theme.spacing(17.5) : theme.spacing(25),
                  mr: { sm: 2 },
                })}
                variant="outlined"
              >
                Submit info
              </Button>
            }
          />
        ),
      });
      return;
    }

    LogRocket.track(AssetEvent.openSpotTrade, {
      assetName: props.marketListingViewModel.assetName,
      assetShortName: props.marketListingViewModel.assetShortName,
      assetType: props.marketListingViewModel.assetType,
    });
    setShowSpotTradeDialog(true);
  };

  return (
    <Root className={classes.sectionRoot} onClick={(e) => e.stopPropagation()}>
      <Tooltip
        title={
          spotTradingViewConfiguration.SubmitSpot || isPublic
            ? ""
            : "You do not have the required permissions"
        }
        placement={"top"}
      >
        <span
          data-link-info={JSON.stringify({
            content_interaction_id: "transact-cta",
            content_interaction_action: InteractionAction.Click,
            content_interaction_type: InteractionType.Button,
            content_interaction_text: "trade now",
            content_interaction_driver: InteractionDriver.TransactTrade,
          } as DataLinkInfoType)}
        >
          <Button
            id={`orderSection-Buy-button-${props.marketListingViewModel.assetShortName}`}
            fullWidth
            disabled={!(spotTradingViewConfiguration.SubmitSpot || isPublic)}
            className={classes.tradeButton}
            variant={"contained"}
            children={"trade now"}
            onClick={handleTrade}
            data-link-info={JSON.stringify({
              content_interaction_id: "transact-cta",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: InteractionType.Button,
              content_interaction_text: "trade now",
              content_interaction_driver: InteractionDriver.TransactTrade,
            } as DataLinkInfoType)}
          />
        </span>
      </Tooltip>
      <div className={classes.price}>
        {(() => {
          if (loadingPrice) {
            return <Skeleton width={100} height={19} />;
          }

          return indicativePrice ? (
            <LedgerAmount
              amount={indicativePrice}
              codeTypographyProps={{
                className: classes.priceUnit,
              }}
            />
          ) : (
            <Tooltip title={errorGettingPrice}>
              <Warning color={"error"} fontSize={"small"} />
            </Tooltip>
          );
        })()}
      </div>

      {showSpotTradeDialog && (
        <SpotTradeDialog
          closeDialog={() => {
            if (searchParams.get("spot")) {
              searchParams.delete("spot");
              setSearchParams(searchParams, { replace: true });
            }
            setShowSpotTradeDialog(false);
          }}
          baseToken={props.marketListingViewModel.token}
          initialQuoteTokenCode={indicativePrice?.token.code}
          listingViewModel={
            props.marketListingViewModel instanceof MarketListingViewModel
              ? props.marketListingViewModel
              : undefined
          }
        />
      )}
      <PrivatePlacementDialog
        dialogProps={{ open: showPrivatePlacementDialog }}
        closeDialog={() => setShowPrivatePlacementDialog(false)}
        openNextDialog={() => {
          handleTrade();
        }}
      />
    </Root>
  );
};
