import { TranslatedError, UnexpectedTranslatedError } from ".";
import {
  JSONRPCCallAbortedError,
  JSONRPCContactFailedError,
  JSONRPCHTTPError,
  JSONRPCTooManyRequestsError,
  JSONRPCUnauthorizedError,
  JSONRPCUnexpectedError,
  MFACancelledError,
} from "utilities/network/jsonRPCRequest";
import { FirebaseUserCancelledError } from "context/Firebase/errors";

export class ErrorTranslator {
  translateError(e: unknown): TranslatedError {
    switch (true) {
      case e instanceof TranslatedError:
        return e as TranslatedError;

      case e instanceof JSONRPCCallAbortedError:
        return new TranslatedError(
          JSONRPCCallAbortedError.ErrorCode,
          "Request was aborted.",
        );

      case e instanceof JSONRPCContactFailedError:
        return new TranslatedError(
          JSONRPCContactFailedError.ErrorCode,
          "There was a connectivity issue. Please try again.",
        );

      case e instanceof JSONRPCHTTPError:
        return new TranslatedError(
          JSONRPCHTTPError.ErrorCode,
          "There was a connectivity issue. Please try again.",
        );

      case e instanceof JSONRPCUnauthorizedError:
        return new TranslatedError(
          JSONRPCUnauthorizedError.ErrorCode,
          "Something went wrong. Please refresh and then try again.",
        );

      case e instanceof JSONRPCUnexpectedError:
        return new TranslatedError(
          JSONRPCUnexpectedError.ErrorCode,
          "Something went wrong. Please refresh and then try again.",
        );

      case e instanceof JSONRPCTooManyRequestsError:
        return new TranslatedError(
          JSONRPCTooManyRequestsError.ErrorCode,
          "Too many requests. Please try again later.",
        );

      case e instanceof FirebaseUserCancelledError:
        return new TranslatedError(
          FirebaseUserCancelledError.ErrorCode,
          "Login was cancelled",
        );

      case e instanceof MFACancelledError:
        return new TranslatedError(
          MFACancelledError.ErrorCode,
          "MFA was cancelled",
        );

      default:
        return UnexpectedTranslatedError;
    }
  }
}
