import { useBankingDetailsViewStore } from "../store";
import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import { useAPIContext } from "context/API";
import { UpdateBankAccountUnscopedRequest } from "@mesh/common-js/src/banking/bankAccountUpdater_pb";
import {
  BankAccount,
  BankAccountType,
} from "@mesh/common-js/dist/banking/bankAccount_pb";
import { BankName } from "@mesh/common-js/src/banking/bankAccount_pb";
import { ReadManyBankAccountRequest } from "@mesh/common-js/src/banking/bankAccountReader_meshproto_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";

// Custom hook to save bank account details.
export const useSaveBankAccountDetails = () => {
  const state = useBankingDetailsViewStore();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();
  const { banking } = useAPIContext();

  const saveBankAccountDetails = async (client: Client) => {
    state.setLoading(true);
    try {
      const cachedBankAccount = state.cachedBankAccount;

      const request = new UpdateBankAccountUnscopedRequest()
        .setContext(authContext.toFuture())
        .setBankaccountid(cachedBankAccount?.getId() || "")
        .setBankaccount(
          new BankAccount()
            .setId(cachedBankAccount?.getId() || "")
            .setOwnerid(cachedBankAccount?.getOwnerid() || "")
            .setCountrycode(state.countryCode)
            .setBankname(state.bankName as BankName)
            .setAccounttype(state.accountType as BankAccountType)
            .setBranchcode(state.branchCode)
            .setNumber(state.number)
            .setAuditentry(cachedBankAccount?.getAuditentry()),
        );

      await banking.bankAccountUpdater.updateBankAccountUnscoped(request);
      const readRequest = new ReadManyBankAccountRequest()
        .setContext(authContext.toFuture())
        .setCriteriaList([newTextExactCriterion("ownerID", client.ownerID)]);
      const response =
        await banking.bankAccountReaderUNSCOPED.readManyBankAccountUNSCOPED(
          readRequest,
        );

      if (response.getTotal() == 0) {
        state.setBankNotFound(true);
      } else {
        // Update local state with the fetched bank account details.
        state.updateStateWithBankAccount(response.getRecordsList()[0]);
        state.setLastUpdateBy(
          response.getRecordsList()[0].getAuditentry()?.getUserid() || "",
        );
        state.setLastUpdateDate(
          response.getRecordsList()[0].getAuditentry()?.getTime()?.toString() ||
            "",
        );
        state.setCachedBankAccount(response.getRecordsList()[0]);
      }

      // Display success message.
      enqueueSnackbar("Bank Account Details Saved", {
        variant: "success",
      });
    } catch (e) {
      // Handle errors.
      console.error(`error saving bank account details`, e);

      errorContextDefaultErrorFeedback(e, "error saving bank account details");
    }
    state.setLoading(false);
  };

  return {
    saveBankAccountDetails,
  };
};
