import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DateTimeFormat } from "const/dateformats";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  DeleteForever as ClearFiltersIcon,
  Error as ErrorIcon,
  FileCopy as CopyPasteIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
  RemoveRedEye as ViewTransactionIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import {
  Transaction,
  TransactionState,
} from "@mesh/common-js/dist/stellar/transaction_pb";
import { Transaction as LedgerTransaction } from "@mesh/common-js/dist/ledger/transaction_pb";
import { useAPIContext } from "context/API";
import { useReadManyTransaction } from "@mesh/common-js-react/dist/stellar/transactionReader_meshproto_meshjsreact";
import { BPTable } from "components/Table/BPTable/BPTable";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Query as OldQuery } from "james/search/query";
import { DateField, TextField } from "@mesh/common-js-react/dist/FormFields";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";
import { ReadManyTransactionRequest } from "@mesh/common-js/dist/stellar/transactionReader_meshproto_pb";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Network } from "@mesh/common-js/dist/stellar/network_pb";
import { Popover } from "components/PopOver/Popover";
import ReactJson from "react-json-view";
import { TransactionStateChip } from "./Chips";
import { TransactionFailureReasonsCard } from "components/Ledger/Transaction/FailureReasonsCard";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import {
  newDateRangeCriterion,
  newORCriterion,
  newTextSubstringCriterion,
  newUint32ListCriterion,
  newUint32NINListCriterion,
} from "@mesh/common-js/dist/search";
import { useIsMounted } from "hooks";
import { allTransactionStates } from "@mesh/common-js/dist/stellar";
import { RangeValue } from "@mesh/common-js/dist/search/dateRangeCriterion_pb";
import { ResolveTransactionIDSubmissionRequest } from "@mesh/common-js/dist/ledger/transactionSubmissionResolver_pb";
import { ResolveTransactionSigningRequest } from "@mesh/common-js/dist/ledger/transactionSigningResolver_pb";

const initialQuery = new Query()
  .setLimit(15)
  .setOffset(0)
  .setSortingList([
    new Sorting().setField("id").setOrder(SortingOrder.DESC_SORTING_ORDER),
  ]);

export const TransactionTable = () => {
  const {
    stellar: { transactionReader },
    ledger: {
      ledgerTransactionSubmissionResolver,
      ledgerTransactionSigningResolver,
    },
  } = useAPIContext();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { viewConfiguration, authContext } = useApplicationContext();
  const [apiLoading, setAPILoading] = useState(false);
  const {
    setReadTransactionRequest,
    readManyTransactionRequest,
    readManyTransactionResponse,
    readManyTransactionLoading,
  } = useReadManyTransaction(transactionReader, {
    initialRequest: new ReadManyTransactionRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  const { errorContextErrorTranslator } = useErrorContext();

  // table criteria
  const [textSearchCriterion, setTextSearchCriterion] = useState("");
  const [transactionStatusesForCriterion, setTransactionStatusesForCriterion] =
    useState<TransactionState[]>([]);
  const [
    transactionNOTStatusesForCriterion,
    setTransactionNOTStatusesForCriterion,
  ] = useState<TransactionState[]>([]);
  const [
    transactionDateTimeCriterionFrom,
    setTransactionDateTimeCriterionFrom,
  ] = useState<Timestamp | null>(null);
  const [transactionDateTimeCriterionTo, setTransactionDateTimeCriterionTo] =
    useState<Timestamp | null>(null);
  useEffect(() => {
    // prepare new initial criteria
    const criteria: Criterion[] = [];

    if (transactionStatusesForCriterion.length) {
      criteria.push(
        newUint32ListCriterion("state", transactionStatusesForCriterion),
      );
    }

    if (transactionNOTStatusesForCriterion.length) {
      criteria.push(
        newUint32NINListCriterion("state", transactionNOTStatusesForCriterion),
      );
    }

    if (transactionDateTimeCriterionFrom || transactionDateTimeCriterionTo) {
      criteria.push(
        newDateRangeCriterion(
          "auditentry.time",
          transactionDateTimeCriterionFrom
            ? new RangeValue().setDate(transactionDateTimeCriterionFrom)
            : undefined,
          transactionDateTimeCriterionTo
            ? new RangeValue().setDate(transactionDateTimeCriterionTo)
            : undefined,
        ),
      );
    }

    if (textSearchCriterion) {
      criteria.push(
        newORCriterion([
          newTextSubstringCriterion("id", textSearchCriterion),
          newTextSubstringCriterion(
            "metadata.description",
            textSearchCriterion,
          ),
          newTextSubstringCriterion("metadata.type", textSearchCriterion),
        ]),
      );
    }

    if (isMounted()) {
      setReadTransactionRequest(
        readManyTransactionRequest.setCriteriaList(criteria),
      );
    }
  }, [
    textSearchCriterion,
    transactionStatusesForCriterion,
    transactionNOTStatusesForCriterion,
    transactionDateTimeCriterionFrom,
    transactionDateTimeCriterionTo,
  ]);

  const [selectedTransaction, setSelectedTransaction] = useState<
    Transaction | undefined
  >(undefined);
  const handleResolveSiging = async () => {
    if (!selectedTransaction) {
      console.error("selected ledger transaction view model not set");
      return;
    }

    setAPILoading(true);
    try {
      await ledgerTransactionSigningResolver.resolveTransactionSigning(
        new ResolveTransactionSigningRequest()
          .setContext(authContext.toFuture())
          .setTransactionid(selectedTransaction.getId()),
      );
      setSelectedTransaction(undefined);
      setReadTransactionRequest(
        readManyTransactionRequest.setQuery(
          readManyTransactionRequest.getQuery(),
        ),
      );
      enqueueSnackbar("Transaction Signing Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving transaction signing`, e);
      enqueueSnackbar(`error resolving transaction signing: ${err.message}`, {
        variant: "error",
      });
    }
    setAPILoading(false);
  };
  const handleResolveSubmission = async () => {
    if (!selectedTransaction) {
      console.error("selected ledger transaction view model not set");
      return;
    }

    setAPILoading(true);
    try {
      await ledgerTransactionSubmissionResolver.resolveTransactionIDSubmission(
        new ResolveTransactionIDSubmissionRequest()
          .setContext(authContext.toFuture())
          .setTransactionid(selectedTransaction.getId())
          .setIgnoreconfirmationcount(true),
      );
      setSelectedTransaction(undefined);
      setReadTransactionRequest(
        readManyTransactionRequest.setQuery(
          readManyTransactionRequest.getQuery(),
        ),
      );
      enqueueSnackbar("Transaction Submission Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving transaction submission`, e);
      enqueueSnackbar(
        `error resolving transaction submission: ${err.message}`,
        {
          variant: "error",
        },
      );
    }
    setAPILoading(false);
  };

  const loading = readManyTransactionLoading || apiLoading;

  return (
    <BPTable
      initialDenseTable={true}
      loading={loading}
      height={window.innerHeight - noticeBannerHeight - 200}
      singleSelect
      onSingleSelectChange={(data) =>
        setSelectedTransaction(data as Transaction)
      }
      title={"Transactions"}
      query={OldQuery.fromFutureQuery(readManyTransactionRequest.getQuery())}
      onQueryChange={(query) =>
        setReadTransactionRequest(
          readManyTransactionRequest.setQuery(query.toFutureQuery()),
        )
      }
      data={readManyTransactionResponse.getRecordsList()}
      totalNoRecords={readManyTransactionResponse.getTotal()}
      filters={(() => {
        const filters: React.ReactNode[] = [
          <TextField
            id={"stellarTransactionTable-textFieldSearch-textField"}
            variant="outlined"
            margin="dense"
            sx={{ width: 400 }}
            label="Search Text Fields"
            placeholder="Start Typing..."
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterion ? (
                <InputAdornment
                  position="end"
                  children={
                    <IconButton
                      size="small"
                      onClick={() => setTextSearchCriterion("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterion}
            onChange={(e) => setTextSearchCriterion(e.target.value)}
          />,
          <DateField
            label={"From"}
            disabled={loading}
            id={
              "stellarTransactionTable-transactionDateTimeFromFilter-dateField"
            }
            value={transactionDateTimeCriterionFrom}
            nullable
            onChange={(newValue) => {
              setTransactionDateTimeCriterionFrom(newValue);
            }}
          />,
          <DateField
            label={"To"}
            disabled={loading}
            id={"stellarTransactionTable-transactionDateTimeToFilter-dateField"}
            value={transactionDateTimeCriterionTo}
            nullable
            onChange={(newValue) => {
              setTransactionDateTimeCriterionTo(newValue);
            }}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"stellarTransactionTable-stateFilter-autocomplete"}
            disabled={loading}
            multiple
            options={allTransactionStates.filter(
              (s) => s !== TransactionState.UNDEFINED_TRANSACTION_STATE,
            )}
            renderOption={(addProps, option: TransactionState) => (
              <li {...addProps}>
                <TransactionStateChip state={option} />
              </li>
            )}
            filterSelectedOptions
            onChange={(_, value: TransactionState[]) =>
              setTransactionStatusesForCriterion(value)
            }
            value={transactionStatusesForCriterion}
            renderTags={(transactionStates: TransactionState[]) =>
              transactionStates.map((s, idx) => (
                <Box sx={{ padding: "4px" }}>
                  <TransactionStateChip
                    key={idx}
                    chipProps={{
                      onDelete: () =>
                        setTransactionStatusesForCriterion((prev) =>
                          prev.filter((prevState) => prevState !== s),
                        ),
                      deleteIcon: (
                        <CancelIcon
                          sx={(theme) => ({
                            color: `${theme.palette.text.secondary} !important`,
                            "&:hover": {
                              color: `${theme.palette.secondary.contrastText} !important`,
                            },
                          })}
                        />
                      ),
                    }}
                    state={s}
                  />
                </Box>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id={"stellarTransactionTable-stateFilter-autocompleteTextField"}
                sx={{ width: 317 }}
                label={"State"}
                variant={"outlined"}
                margin={"dense"}
                InputLabelProps={{ shrink: true }}
                placeholder={
                  transactionStatusesForCriterion.length
                    ? undefined
                    : "Select..."
                }
              />
            )}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"stellarTransactionTable-notStateFilter-autocomplete"}
            disabled={loading}
            multiple
            options={allTransactionStates.filter(
              (s) => s !== TransactionState.UNDEFINED_TRANSACTION_STATE,
            )}
            renderOption={(addProps, option: TransactionState) => (
              <li {...addProps}>
                <TransactionStateChip state={option} />
              </li>
            )}
            filterSelectedOptions
            onChange={(_, value: TransactionState[]) =>
              setTransactionNOTStatusesForCriterion(value)
            }
            value={transactionNOTStatusesForCriterion}
            renderTags={(transactionStates: TransactionState[]) =>
              transactionStates.map((s, idx) => (
                <Box sx={{ padding: "4px" }}>
                  <TransactionStateChip
                    key={idx}
                    chipProps={{
                      onDelete: () =>
                        setTransactionNOTStatusesForCriterion((prev) =>
                          prev.filter((prevState) => prevState !== s),
                        ),
                      deleteIcon: (
                        <CancelIcon
                          sx={(theme) => ({
                            color: `${theme.palette.text.secondary} !important`,
                            "&:hover": {
                              color: `${theme.palette.secondary.contrastText} !important`,
                            },
                          })}
                        />
                      ),
                    }}
                    state={s}
                  />
                </Box>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id={
                  "stellarTransactionTable-notStateFilter-autocompleteTextField"
                }
                sx={{ width: 317 }}
                label={"NOT in State"}
                variant={"outlined"}
                margin={"dense"}
                InputLabelProps={{ shrink: true }}
                placeholder={
                  transactionNOTStatusesForCriterion.length
                    ? undefined
                    : "Select..."
                }
              />
            )}
          />,
        ];

        // if any criteria is set then show a clear all filters button
        if (
          transactionStatusesForCriterion.length ||
          transactionDateTimeCriterionFrom ||
          transactionDateTimeCriterionTo ||
          textSearchCriterion
        ) {
          filters.push(
            <Button
              sx={{ marginTop: "10px" }}
              id={"stellarTransactionTable-clearAllFilters-button"}
              variant={"contained"}
              color={"secondary"}
              children={"clear all"}
              onClick={() => {
                setTransactionStatusesForCriterion([]);
                setTransactionDateTimeCriterionFrom(null);
                setTransactionDateTimeCriterionTo(null);
                setTextSearchCriterion("");
              }}
              startIcon={<ClearFiltersIcon />}
            />,
          );
        }

        return filters;
      })()}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        if (
          viewConfiguration?.Ledger?.Stellar?.Transactions?.Resolve &&
          selectedTransaction
        ) {
          controls.push(
            <Button
              variant={"outlined"}
              id={"stellarTransactionTable-resolveState-button"}
              children={"Resolve Signing State"}
              onClick={handleResolveSiging}
            />,
          );
          controls.push(
            <Button
              variant={"outlined"}
              id={"stellarTransactionTable-resolveState-button"}
              children={"Resolve Submission State"}
              onClick={handleResolveSubmission}
            />,
          );
        }

        controls.push(
          <IconButton
            id={"stellarTransactionTable-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={() => {
              setReadTransactionRequest(
                readManyTransactionRequest.setQuery(
                  readManyTransactionRequest.getQuery(),
                ),
              );
            }}
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      columns={[
        {
          label: "ID",
          field: "id",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const txn = data as Transaction;
            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                <Typography
                  sx={{
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  variant="body1"
                  children={txn.getId()}
                />
                <CopyPasteIcon
                  sx={(theme) => ({
                    fontSize: 20,
                    color: theme.palette.action.disabled,
                    "&:hover": {
                      color: theme.palette.action.active,
                    },
                    cursor: "pointer",
                  })}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(txn.getId())
                      .then(() => enqueueSnackbar("Transaction ID copied"))
                  }
                />
              </Box>
            );
          },
        },
        {
          label: "Description",
          field: "metadata.description",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            return (
              (data as Transaction).getMetadataMap().get("description") ??
              (data as Transaction).getMetadataMap().get("type") ??
              "-"
            );
          },
        },
        {
          label: "Last Modified",
          field: "auditentry.time",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) =>
            dayjs(
              protobufTimestampToDayjs(
                (data as Transaction).getAuditentry()?.getTime() ??
                  new Timestamp(),
              ),
            ).format(DateTimeFormat),
        },
        {
          label: "State",
          field: "state",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [p: string]: any }) => {
            const txn = data as Transaction;
            if (txn.getState() === TransactionState.FAILED_TRANSACTION_STATE) {
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(0.5),
                  })}
                >
                  <TransactionStateChip
                    state={(data as Transaction).getState()}
                  />
                  <Popover
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    popOverComponent={
                      <TransactionFailureReasonsCard
                        transaction={new LedgerTransaction().setStellartransaction(
                          txn,
                        )}
                      />
                    }
                  >
                    <Tooltip
                      title="Select to view failure reasons"
                      placement="top"
                    >
                      <ErrorIcon
                        sx={{
                          color: "secondary.light",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Popover>
                </Box>
              );
            } else {
              return (
                <TransactionStateChip
                  state={(data as Transaction).getState()}
                />
              );
            }
          },
        },
        {
          label: "Resolutions",
          field: "stateresolutioncount",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data) => {
            return (data as Transaction).getStateresolutioncount();
          },
        },
        {
          field: "",
          label: "",
          minWidth: 40,
          sortable: false,
          accessor: (data) => {
            const controls: React.ReactNode[] = [];
            const txn = data as Transaction;

            if (txn.getLedgerid()) {
              controls.push(
                <Tooltip
                  placement="top"
                  title="View Transaction in Stellar Expert"
                >
                  <IconButton
                    size="small"
                    id={`stellarTransactionTable-${(
                      data as Transaction
                    ).getId()}-openTransactionInStellarExpert-iconButton`}
                    onClick={() =>
                      window.open(
                        `https://stellar.expert/explorer/${
                          txn.getNetwork() === Network.STELLAR_TEST_SDF_NETWORK
                            ? "testnet"
                            : "public"
                        }/tx/${txn.getLedgerid()}`,
                        "_blank",
                      )
                    }
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>,
              );

              // add button to copy a URL for transaction data to be viewed in stellar lab
              const labURL = `https://laboratory.stellar.org/#xdr-viewer?input=${encodeURIComponent(
                txn.getData(),
              )}&type=TransactionEnvelope&network=${
                txn.getNetwork() === Network.STELLAR_TEST_SDF_NETWORK
                  ? "test"
                  : "public"
              }`;
              controls.push(
                <Tooltip
                  placement="top"
                  title="Copy URL to view txn data in stellar laboratory (2nd paste-n-go usually works)"
                >
                  <CopyPasteIcon
                    sx={(theme) => ({
                      color: theme.palette.action.disabled,
                      "&:hover": {
                        color: theme.palette.action.active,
                      },
                      cursor: "pointer",
                    })}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(labURL)
                        .then(() => enqueueSnackbar("URL copied"))
                    }
                  />
                </Tooltip>,
              );
            }

            controls.push(
              <Popover
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                popOverComponent={
                  <Card style={{ backgroundColor: "#1d1f21" }}>
                    <CardContent>
                      <ReactJson
                        name={false}
                        enableClipboard={(e) =>
                          navigator.clipboard
                            .writeText(JSON.stringify(e))
                            .then(() => enqueueSnackbar("copied"))
                        }
                        src={txn.toObject()}
                        theme="google"
                      />
                    </CardContent>
                  </Card>
                }
              >
                <Tooltip
                  title="Select to view transaction in pop-up"
                  placement="top"
                >
                  <ViewTransactionIcon
                    sx={(theme) => ({
                      color: theme.palette.action.disabled,
                      "&:hover": {
                        color: theme.palette.action.active,
                      },
                      cursor: "pointer",
                    })}
                  />
                </Tooltip>
              </Popover>,
            );

            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                {controls.map((c, idx) => (
                  <React.Fragment key={idx}>{c}</React.Fragment>
                ))}
              </Box>
            );
          },
        },
      ]}
    />
  );
};
