/**
 * @fileoverview gRPC-Web generated client stub for market
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: market/limitOrderFeeCalculator.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var ledger_futureToken_pb = require('../ledger/futureToken_pb.js')
const proto = {};
proto.market = require('./limitOrderFeeCalculator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.LimitOrderFeeCalculatorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.LimitOrderFeeCalculatorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.CalculatePredictedLimitOrderFeeRequest,
 *   !proto.market.CalculatePredictedLimitOrderFeeResponse>}
 */
const methodDescriptor_LimitOrderFeeCalculator_CalculatePredictedLimitOrderFee = new grpc.web.MethodDescriptor(
  '/market.LimitOrderFeeCalculator/CalculatePredictedLimitOrderFee',
  grpc.web.MethodType.UNARY,
  proto.market.CalculatePredictedLimitOrderFeeRequest,
  proto.market.CalculatePredictedLimitOrderFeeResponse,
  /**
   * @param {!proto.market.CalculatePredictedLimitOrderFeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.CalculatePredictedLimitOrderFeeResponse.deserializeBinary
);


/**
 * @param {!proto.market.CalculatePredictedLimitOrderFeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.CalculatePredictedLimitOrderFeeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.CalculatePredictedLimitOrderFeeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderFeeCalculatorClient.prototype.calculatePredictedLimitOrderFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderFeeCalculator/CalculatePredictedLimitOrderFee',
      request,
      metadata || {},
      methodDescriptor_LimitOrderFeeCalculator_CalculatePredictedLimitOrderFee,
      callback);
};


/**
 * @param {!proto.market.CalculatePredictedLimitOrderFeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.CalculatePredictedLimitOrderFeeResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderFeeCalculatorPromiseClient.prototype.calculatePredictedLimitOrderFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderFeeCalculator/CalculatePredictedLimitOrderFee',
      request,
      metadata || {},
      methodDescriptor_LimitOrderFeeCalculator_CalculatePredictedLimitOrderFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.CalculateExactLimitOrderFeeRequest,
 *   !proto.market.CalculateExactLimitOrderFeeResponse>}
 */
const methodDescriptor_LimitOrderFeeCalculator_CalculateExactLimitOrderFee = new grpc.web.MethodDescriptor(
  '/market.LimitOrderFeeCalculator/CalculateExactLimitOrderFee',
  grpc.web.MethodType.UNARY,
  proto.market.CalculateExactLimitOrderFeeRequest,
  proto.market.CalculateExactLimitOrderFeeResponse,
  /**
   * @param {!proto.market.CalculateExactLimitOrderFeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.CalculateExactLimitOrderFeeResponse.deserializeBinary
);


/**
 * @param {!proto.market.CalculateExactLimitOrderFeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.CalculateExactLimitOrderFeeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.CalculateExactLimitOrderFeeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.LimitOrderFeeCalculatorClient.prototype.calculateExactLimitOrderFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.LimitOrderFeeCalculator/CalculateExactLimitOrderFee',
      request,
      metadata || {},
      methodDescriptor_LimitOrderFeeCalculator_CalculateExactLimitOrderFee,
      callback);
};


/**
 * @param {!proto.market.CalculateExactLimitOrderFeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.CalculateExactLimitOrderFeeResponse>}
 *     Promise that resolves to the response
 */
proto.market.LimitOrderFeeCalculatorPromiseClient.prototype.calculateExactLimitOrderFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.LimitOrderFeeCalculator/CalculateExactLimitOrderFee',
      request,
      metadata || {},
      methodDescriptor_LimitOrderFeeCalculator_CalculateExactLimitOrderFee);
};


module.exports = proto.market;

