import {
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PasswordRevealTextField } from "components/PasswordReveal/PasswordReveal";
import { useFirebaseContext } from "context/Firebase";
import { useNavigate } from "react-router-dom";
import { OnboardingCard } from "views/SignUp/components/OnboardingCard/OnboardingCard";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { ContinueWithGoogle } from "components/Buttons/ContinueWithGoogleButton/ContinueWithGoogle";
import { FirebaseError } from "@firebase/util";

export const SignInCard = () => {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailPasswordError, setEmailPasswordError] = useState(false);
  const { firebaseLoginWithGoogle, firebaseLoginWithEmailAndPassword } =
    useFirebaseContext();
  const navigate = useNavigate();

  const handleLoginWithEmailPassword = async () => {
    try {
      await firebaseLoginWithEmailAndPassword(email, password);
    } catch (e) {
      const err = e as FirebaseError;
      if (err.code) {
        setEmailPasswordError(true);
      }
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <OnboardingCard
      linksFooter={
        <Box
          sx={(theme) => ({
            padding: "0px 0px 44px 0px",
            paddingBottom: theme.spacing(4),
          })}
        >
          <Typography
            sx={{ padding: "0px 8px 0px 0px" }}
            component={"a"}
            variant="body1"
            color={theme.palette.text.secondary}
            children={"Not Registered?"}
          />
          <Typography
            component={"a"}
            variant="body1"
            id={"not_registered_sign_up_link"}
            data-link-info={JSON.stringify({
              content_interaction_id: "sign_in_button",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "not registered - sign up",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.SignUp,
            } as DataLinkInfoType)}
            children={
              <Link
                id="signInCard-signUp-link"
                onClick={() => navigate("/sign-up")}
                sx={{ fontWeight: "bold" }}
                component="span"
                color="secondary"
                underline={"none"}
                data-link-info={JSON.stringify({
                  content_interaction_id: "sign-in-button",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "sign up",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.SignUp,
                } as DataLinkInfoType)}
              >
                Sign Up
              </Link>
            }
          />
        </Box>
      }
      dataComponentAttribute={JSON.stringify({
        component_id: "sign_in",
        component_business_name: "Sign in",
        component_title: "Welcome back",
        component_driver: InteractionDriver.Join,
      } as DataComponentInfo)}
    >
      <Typography
        sx={{
          fontWeight: 600,
          padding: "44px 0px 24px 0px",
          textAlign: "center",
          lineHeight: "29px",
        }}
        variant="h4"
        align="center"
        children="Welcome back!"
      />
      {/* Email and Password Sign In */}
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
        onSubmit={(e) => {
          handleLoginWithEmailPassword().finally();
          e.preventDefault();
        }}
      >
        <TextField
          fullWidth
          label="Email"
          id="mobileSignIn-email-textField"
          InputLabelProps={{ shrink: undefined }}
          inputProps={{ type: "email" }}
          sx={{ mb: 1 }}
          value={email}
          autoComplete="username"
          onChange={(e) => {
            setEmailPasswordError(false);
            setEmail(e.target.value.replace(/\s+/g, ""));
          }}
          error={emailPasswordError}
        />
        <PasswordRevealTextField
          fullWidth
          label="Password"
          id="mobileSignIn-password-textField"
          InputLabelProps={{ shrink: undefined }}
          sx={{ marginBottom: "8px" }}
          autoComplete="current-password"
          value={password}
          onChange={(e) => {
            setEmailPasswordError(false);
            setPassword(e.target.value);
          }}
          error={emailPasswordError}
          helperText={
            emailPasswordError ? "Invalid Email or Password" : undefined
          }
        />
      </form>
      <Button
        sx={{
          height: "48px",
          width: "100%",
        }}
        type="submit"
        id="mobileSignIn-signIn-button"
        onClick={handleLoginWithEmailPassword}
        color="primary"
        variant="contained"
        children="sign in"
        disabled={!password || !email}
        data-link-info={JSON.stringify({
          content_interaction_id: "sign-in-button",
          content_interaction_action: InteractionAction.Click,
          content_interaction_text: "sign in",
          content_interaction_type: InteractionType.Button,
          content_interaction_driver: InteractionDriver.SignIn,
        } as DataLinkInfoType)}
      />
      <Typography
        sx={(theme) => ({
          paddingTop: theme.spacing(2),
        })}
        variant="body1"
        children={
          <Link
            id="SignInCard-forgot-password-link"
            data-link-info={JSON.stringify({
              content_interaction_id: "sign-in-button",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "forgot password",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.SignIn,
            } as DataLinkInfoType)}
            onClick={() => navigate("/forgot-password")}
            sx={{ fontWeight: "bold" }}
            component="span"
            color="secondary"
            underline={"none"}
          >
            Forgot Password?
          </Link>
        }
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Divider />
        <Typography
          sx={{ margin: "16px 0px" }}
          variant="subtitle1"
          children="or"
        />
        <Divider />
      </Box>
      <ContinueWithGoogle
        onClick={firebaseLoginWithGoogle}
        dataLinkAttribute={JSON.stringify({
          content_interaction_id: "sign-in-button",
          content_interaction_action: InteractionAction.Click,
          content_interaction_text: "continue with google",
          content_interaction_type: InteractionType.Button,
          content_interaction_driver: InteractionDriver.Join,
        } as DataLinkInfoType)}
      />
    </OnboardingCard>
  );
};
