/**
 * @fileoverview gRPC-Web generated client stub for banking
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: banking/defundOrderStateController.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var banking_defundOrder_pb = require('../banking/defundOrder_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.banking = require('./defundOrderStateController_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.DefundOrderStateControllerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.DefundOrderStateControllerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.SubmitDefundOrderRequest,
 *   !proto.banking.SubmitDefundOrderResponse>}
 */
const methodDescriptor_DefundOrderStateController_SubmitDefundOrder = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderStateController/SubmitDefundOrder',
  grpc.web.MethodType.UNARY,
  proto.banking.SubmitDefundOrderRequest,
  proto.banking.SubmitDefundOrderResponse,
  /**
   * @param {!proto.banking.SubmitDefundOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.SubmitDefundOrderResponse.deserializeBinary
);


/**
 * @param {!proto.banking.SubmitDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.SubmitDefundOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.SubmitDefundOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderStateControllerClient.prototype.submitDefundOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderStateController/SubmitDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_SubmitDefundOrder,
      callback);
};


/**
 * @param {!proto.banking.SubmitDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.SubmitDefundOrderResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderStateControllerPromiseClient.prototype.submitDefundOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderStateController/SubmitDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_SubmitDefundOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.SettleDefundOrderRequest,
 *   !proto.banking.SettleDefundOrderResponse>}
 */
const methodDescriptor_DefundOrderStateController_SettleDefundOrder = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderStateController/SettleDefundOrder',
  grpc.web.MethodType.UNARY,
  proto.banking.SettleDefundOrderRequest,
  proto.banking.SettleDefundOrderResponse,
  /**
   * @param {!proto.banking.SettleDefundOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.SettleDefundOrderResponse.deserializeBinary
);


/**
 * @param {!proto.banking.SettleDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.SettleDefundOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.SettleDefundOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderStateControllerClient.prototype.settleDefundOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderStateController/SettleDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_SettleDefundOrder,
      callback);
};


/**
 * @param {!proto.banking.SettleDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.SettleDefundOrderResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderStateControllerPromiseClient.prototype.settleDefundOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderStateController/SettleDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_SettleDefundOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.MarkDefundOrderUnderInvestigationRequest,
 *   !proto.banking.MarkDefundOrderUnderInvestigationResponse>}
 */
const methodDescriptor_DefundOrderStateController_MarkDefundOrderUnderInvestigation = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderStateController/MarkDefundOrderUnderInvestigation',
  grpc.web.MethodType.UNARY,
  proto.banking.MarkDefundOrderUnderInvestigationRequest,
  proto.banking.MarkDefundOrderUnderInvestigationResponse,
  /**
   * @param {!proto.banking.MarkDefundOrderUnderInvestigationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.MarkDefundOrderUnderInvestigationResponse.deserializeBinary
);


/**
 * @param {!proto.banking.MarkDefundOrderUnderInvestigationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.MarkDefundOrderUnderInvestigationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.MarkDefundOrderUnderInvestigationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderStateControllerClient.prototype.markDefundOrderUnderInvestigation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderStateController/MarkDefundOrderUnderInvestigation',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_MarkDefundOrderUnderInvestigation,
      callback);
};


/**
 * @param {!proto.banking.MarkDefundOrderUnderInvestigationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.MarkDefundOrderUnderInvestigationResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderStateControllerPromiseClient.prototype.markDefundOrderUnderInvestigation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderStateController/MarkDefundOrderUnderInvestigation',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_MarkDefundOrderUnderInvestigation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.MarkDefundOrderAwaitingSettlementRequest,
 *   !proto.banking.MarkDefundOrderAwaitingSettlementResponse>}
 */
const methodDescriptor_DefundOrderStateController_MarkDefundOrderAwaitingSettlement = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderStateController/MarkDefundOrderAwaitingSettlement',
  grpc.web.MethodType.UNARY,
  proto.banking.MarkDefundOrderAwaitingSettlementRequest,
  proto.banking.MarkDefundOrderAwaitingSettlementResponse,
  /**
   * @param {!proto.banking.MarkDefundOrderAwaitingSettlementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.MarkDefundOrderAwaitingSettlementResponse.deserializeBinary
);


/**
 * @param {!proto.banking.MarkDefundOrderAwaitingSettlementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.MarkDefundOrderAwaitingSettlementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.MarkDefundOrderAwaitingSettlementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderStateControllerClient.prototype.markDefundOrderAwaitingSettlement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderStateController/MarkDefundOrderAwaitingSettlement',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_MarkDefundOrderAwaitingSettlement,
      callback);
};


/**
 * @param {!proto.banking.MarkDefundOrderAwaitingSettlementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.MarkDefundOrderAwaitingSettlementResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderStateControllerPromiseClient.prototype.markDefundOrderAwaitingSettlement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderStateController/MarkDefundOrderAwaitingSettlement',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_MarkDefundOrderAwaitingSettlement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.MarkDefundOrderSettledRequest,
 *   !proto.banking.MarkDefundOrderSettledResponse>}
 */
const methodDescriptor_DefundOrderStateController_MarkDefundOrderSettled = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderStateController/MarkDefundOrderSettled',
  grpc.web.MethodType.UNARY,
  proto.banking.MarkDefundOrderSettledRequest,
  proto.banking.MarkDefundOrderSettledResponse,
  /**
   * @param {!proto.banking.MarkDefundOrderSettledRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.MarkDefundOrderSettledResponse.deserializeBinary
);


/**
 * @param {!proto.banking.MarkDefundOrderSettledRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.MarkDefundOrderSettledResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.MarkDefundOrderSettledResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderStateControllerClient.prototype.markDefundOrderSettled =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderStateController/MarkDefundOrderSettled',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_MarkDefundOrderSettled,
      callback);
};


/**
 * @param {!proto.banking.MarkDefundOrderSettledRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.MarkDefundOrderSettledResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderStateControllerPromiseClient.prototype.markDefundOrderSettled =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderStateController/MarkDefundOrderSettled',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_MarkDefundOrderSettled);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.MarkDefundOrderCancelledRequest,
 *   !proto.banking.MarkDefundOrderCancelledResponse>}
 */
const methodDescriptor_DefundOrderStateController_MarkDefundOrderCancelled = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderStateController/MarkDefundOrderCancelled',
  grpc.web.MethodType.UNARY,
  proto.banking.MarkDefundOrderCancelledRequest,
  proto.banking.MarkDefundOrderCancelledResponse,
  /**
   * @param {!proto.banking.MarkDefundOrderCancelledRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.MarkDefundOrderCancelledResponse.deserializeBinary
);


/**
 * @param {!proto.banking.MarkDefundOrderCancelledRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.MarkDefundOrderCancelledResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.MarkDefundOrderCancelledResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderStateControllerClient.prototype.markDefundOrderCancelled =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderStateController/MarkDefundOrderCancelled',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_MarkDefundOrderCancelled,
      callback);
};


/**
 * @param {!proto.banking.MarkDefundOrderCancelledRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.MarkDefundOrderCancelledResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderStateControllerPromiseClient.prototype.markDefundOrderCancelled =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderStateController/MarkDefundOrderCancelled',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_MarkDefundOrderCancelled);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ResolveDefundOrderStateRequest,
 *   !proto.banking.ResolveDefundOrderStateResponse>}
 */
const methodDescriptor_DefundOrderStateController_ResolveDefundOrderState = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderStateController/ResolveDefundOrderState',
  grpc.web.MethodType.UNARY,
  proto.banking.ResolveDefundOrderStateRequest,
  proto.banking.ResolveDefundOrderStateResponse,
  /**
   * @param {!proto.banking.ResolveDefundOrderStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.ResolveDefundOrderStateResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ResolveDefundOrderStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ResolveDefundOrderStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ResolveDefundOrderStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderStateControllerClient.prototype.resolveDefundOrderState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderStateController/ResolveDefundOrderState',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_ResolveDefundOrderState,
      callback);
};


/**
 * @param {!proto.banking.ResolveDefundOrderStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ResolveDefundOrderStateResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderStateControllerPromiseClient.prototype.resolveDefundOrderState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderStateController/ResolveDefundOrderState',
      request,
      metadata || {},
      methodDescriptor_DefundOrderStateController_ResolveDefundOrderState);
};


module.exports = proto.banking;

