import { DataSheetSection } from "james/marketData/DataSheetSection";
import { Amount } from "james/ledger";
import dayjs from "dayjs";

export const AssetSubscriptionOverviewDataSheetSectionTypeName =
  "mesh::marketData/AssetSubscriptionOverview";

export const AssetSubscriptionOverviewDataSheetSectionName =
  "Subscription Overview";

export class AssetSubscriptionOverviewDataSheetSection
  implements DataSheetSection
{
  public ["@type"] = AssetSubscriptionOverviewDataSheetSectionTypeName;

  /**
   * TargetSubscriptionAmount is the target subscription amount.
   * i.e. the amount that the issuer would like to raise.
   * @type {Amount}
   */
  public targetSubscriptionAmount: Amount = new Amount();

  /**
   * OverSubscriptionAmount is the total possible amount that can be subscribed.
   * i.e. it is >= SubscriptionAmount.
   * @type {Amount}
   */
  public overSubscriptionAmount: Amount = new Amount();

  /**
   * WaitingListCommittedAmount is the amount that has been committed via
   * waiting list additions.
   * @type {Amount}
   */
  public waitingListCommitmentAmount: Amount = new Amount();

  /**
   * UnderwriterCommittmentAmount is the maximum amount of the SubscriptionAmount
   * that is committed to be fullfilled by underwriters.
   * @type {Amount}
   */
  public underwriterCommitmentAmount: Amount = new Amount();

  /**
   * SubscribedAmount is the actual amount that has been subscribed so far.
   * i.e. the sum of all pending subscription orders.
   * @type {Amount}
   */
  public subscribedAmount: Amount = new Amount();

  /**
   * MinimumOrderAmount is the smallest subscription order that can be placed.
   * @type {Amount}
   */
  public minimumOrderAmount: Amount = new Amount();

  /**
   * TokenCode is the code of the token being subscribed to.
   * @type {string}
   */
  public tokenCode: string = "";

  /**
   * OpenDate is the date on which the subscription book opens for investors
   * to start subscribing.
   * @type {dayjs}
   */
  public openDate: string = dayjs().format();

  /**
   * CloseDate is the date on which the subscription book closes and no more
   * subscriptions are accepted.
   * @type {dayjs}
   */
  public closeDate: string = dayjs().format();

  /**
   * SettlementDate is the date on which the subscription book exchanges tokens
   * for funds between the issuer and investors.
   * @type {dayjs}
   */
  public settlementDate: string = dayjs().format();

  /**
   * FlexibleUnitPrice indicates whether the tokens being subscribed to have a variable
   * price, and is only fixed at settlementDate.
   * @type {boolean}
   */
  public flexibleUnitPrice: boolean = false;

  constructor(l?: AssetSubscriptionOverviewDataSheetSection) {
    if (!l) {
      return;
    }
    this.targetSubscriptionAmount = new Amount(l.targetSubscriptionAmount);
    this.overSubscriptionAmount = new Amount(l.overSubscriptionAmount);
    this.waitingListCommitmentAmount = new Amount(
      l.waitingListCommitmentAmount,
    );
    this.underwriterCommitmentAmount = new Amount(
      l.underwriterCommitmentAmount,
    );
    this.subscribedAmount = new Amount(l.subscribedAmount);
    this.minimumOrderAmount = new Amount(l.minimumOrderAmount);
    this.tokenCode = l.tokenCode;
    this.openDate = l.openDate;
    this.closeDate = l.closeDate;
    this.settlementDate = l.settlementDate;
    this.flexibleUnitPrice = l.flexibleUnitPrice;
  }

  public DataSheetSectionName(): string {
    return AssetSubscriptionOverviewDataSheetSectionName;
  }
}
