import React from "react";
import {
  CircularProgress,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { MarketListingViewModel } from "james/views/marketListingView";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export interface SubscriptionDialogHeaderProps {
  marketListingViewModel: MarketListingViewModel;
  submittingOrder?: boolean;
  closeDialog: () => void;
}

export const SubscriptionDialogHeader = ({
  marketListingViewModel,
  submittingOrder,
  closeDialog,
}: SubscriptionDialogHeaderProps) => {
  return (
    <DialogTitle
      sx={{
        display: "grid",
        gridTemplateColumns: "56px auto 40px",
        height: 80,
      }}
    >
      <IconViewUpload
        id={`smallHeader-icon-iconViewUpload-${marketListingViewModel.assetName}`}
        token={marketListingViewModel.token}
        tokenIconDownloadURL={marketListingViewModel.tokenIconURL}
        tokenOwnerID={marketListingViewModel.assetOwnerID}
        size={48}
      />
      <Box sx={{ maxWidth: "80%", ml: 1 }}>
        <Typography
          variant="h5"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
          children={marketListingViewModel.assetName}
        />
        <Typography
          variant="caption"
          sx={(theme) => ({ color: theme.palette.text.secondary })}
          children={marketListingViewModel.assetShortName}
        />
      </Box>
      {submittingOrder && <CircularProgress size={20} />}
      {!submittingOrder && (
        <IconButton
          sx={{ width: 24, height: 24 }}
          disabled={submittingOrder}
          onClick={() => {
            closeDialog();
          }}
          data-link-info={JSON.stringify({
            content_interaction_id:
              marketListingViewModel.assetName + "_subscription_form_close",
            content_interaction_action: InteractionAction.Click,
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.NotSubscribing,
          } as DataLinkInfoType)}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};
