import React from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  Skeleton,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Amount } from "components/Ledger/Amount";
import { LedgerAmountField } from "@mesh/common-js-react/dist/FormFields";
import { FutureAmount } from "@mesh/common-js/dist/ledger/futureAmount_pb";
import cx from "classnames";
import { LimitOrderType } from "@mesh/common-js/dist/market/limitOrder_pb";
import { limitOrderTypeToString } from "@mesh/common-js/dist/market/limitOrderType";
import {
  Balance,
  Model,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Amount as AmountType } from "james/ledger";
import { useExchangeDashboardStore } from "../../store";
import { useExchangeStore } from "../../../../store";
import { LimitOrderConfirmationDialog } from "./components/LimitOrderConfirmationDialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { QuoteParameter } from "james/market";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useFeeFetcher } from "../../hooks/useFeeFetcher";
import { getAvailableBalance } from "@mesh/common-js/dist/views/stellarAccountView";

export interface TradeCardProps {
  loading: boolean;
  price: FutureAmount;
  amount: FutureAmount;
  estimatedTotal: FutureAmount;
  cardOption: LimitOrderType;
  amountFocused: boolean;
  sourceAccount: Model;
  potentialSourceAccounts: Model[];
  isSignatory: boolean;
  balance?: Record<LimitOrderType, Balance>;
  quoteParemeter?: QuoteParameter;
  editedFields: Set<string>;
  fee: {
    fee: FutureAmount;
    vat: FutureAmount;
  };
}

export interface TradeCardActions {
  setLoading: (val: boolean) => void;
  setPrice: (val: FutureAmount) => void;
  setAmount: (val: FutureAmount) => void;
  setEstimatedTotal: (val: FutureAmount) => void;
  setCardOption: (val: LimitOrderType) => void;
  setAmountFocused: (val: boolean) => void;
  setBalance: (val?: Record<LimitOrderType, Balance>) => void;
  setSourceAccount: (val: Model) => void;
  setPotentialSourceAccounts: (val: Model[]) => void;
  setIsSignatory: (val: boolean) => void;
  setQuoteParemeter: (val?: QuoteParameter) => void;
  setEditedFields: (val: Set<string>) => void;
  setFee: (val: { fee: FutureAmount; vat: FutureAmount }) => void;
  setMax: () => void;
  setTradeCardForm: (
    price: FutureAmount,
    amount: FutureAmount,
    estimatedTotal: FutureAmount,
  ) => void;
  clear: () => void;
}

export const TradeCard = ({
  balance,
  loading,
  price,
  amount,
  estimatedTotal,
  cardOption,
  amountFocused,
  sourceAccount,
  potentialSourceAccounts,
  fee,
  ...actions
}: TradeCardProps & TradeCardActions) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const store = useExchangeDashboardStore();
  const exchangeStore = useExchangeStore();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [accordionOpen, setAccordionOpen] = React.useState(false);
  const { stellarAccountContext } = useAccountContext();

  const { fetchFee } = useFeeFetcher();

  React.useEffect(() => {
    const timeout = setTimeout(fetchFee);
    return () => clearTimeout(timeout);
  }, [estimatedTotal, amount]);

  React.useEffect(() => {
    if (!store.tradeCardState.sourceAccount.getLedgerid()) return;
    const timout = setTimeout(async () => {
      try {
        store.setLoading(true);
        const userSignatoryOnSourceAccount =
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(
              store.tradeCardState.sourceAccount.getLedgerid(),
            ),
          );

        store.updateTradeCardState.setIsSignatory(userSignatoryOnSourceAccount);
        store.setLoading(false);
      } catch (e) {
        store.setLoading(false);
        console.error(e);
      }
    });

    return () => {
      clearTimeout(timout);
      store.setLoading(false);
    };
  }, [store.tradeCardState.sourceAccount]);

  return (
    <>
      <Card
        className={limitOrderTypeToString(cardOption)}
        sx={{
          height: {
            sm: "100%",
            xs: "calc(100vh - 180px)",
            lg: 508,
          },
          borderRadius: {
            sm: "10px",
            xs: 0,
          },
        }}
      >
        {!smDown && (
          <CardHeader title="Create Limit Order" sx={{ height: 52 }} />
        )}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: { sm: "calc(100% - 32px)", xs: "100%" },
          }}
        >
          <Box>
            <Box>
              <Box
                className={cx({
                  "multi-account": potentialSourceAccounts.length > 1,
                })}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  "&.multi-account": {
                    gridTemplateColumns: "1fr auto",
                  },
                  alignItems: "center",
                  gap: 2,
                  mb: 1,
                }}
              >
                <ButtonGroup
                  fullWidth
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightMedium,
                  })}
                >
                  <Button
                    onClick={() => actions.setCardOption(LimitOrderType.BUY)}
                    className={cx({
                      disabled: cardOption != LimitOrderType.BUY,
                      "buy-button": true,
                    })}
                    sx={(theme) => ({
                      "&.disabled": {
                        backgroundColor:
                          theme.palette.action.disabledBackground,
                        color: theme.palette.text.disabled,
                      },
                    })}
                    variant="contained"
                    color="secondary"
                  >
                    Buy
                  </Button>
                  <Button
                    onClick={() => actions.setCardOption(LimitOrderType.SELL)}
                    className={cx({
                      disabled: cardOption != LimitOrderType.SELL,
                      "sell-button": true,
                    })}
                    sx={(theme) => ({
                      "&.disabled": {
                        backgroundColor:
                          theme.palette.action.disabledBackground,
                        color: theme.palette.text.disabled,
                      },
                    })}
                    variant="contained"
                    color="primary"
                  >
                    Sell
                  </Button>
                </ButtonGroup>

                {potentialSourceAccounts.length > 1 && (
                  <Autocomplete
                    options={potentialSourceAccounts}
                    sx={{
                      height: 36,
                      mb: 2,
                    }}
                    getOptionLabel={(option) =>
                      `${option.getLabel()} - ${option.getNumber()}`
                    }
                    renderOption={(props, option) => (
                      <Typography
                        {...props}
                      >{`${option.getLabel()} - ${option.getNumber()}`}</Typography>
                    )}
                    value={sourceAccount}
                    onChange={(e, acc) => {
                      if (acc) {
                        actions.setSourceAccount(acc);
                      }
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => {
                      return (
                        <TextField
                          {...params}
                          label={"Accounts"}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                )}
              </Box>

              {balance ? (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "80px 1fr auto",
                    alignContent: "center",
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <Typography variant="body2">Total</Typography>
                  <Typography variant="body2" noWrap sx={{ pr: 2 }}>
                    ............................................................
                  </Typography>
                  <Amount
                    reverse
                    valueTypographyProps={{
                      variant: "body2",
                    }}
                    codeTypographyProps={{
                      variant: "body2",
                      sx: (theme) => ({
                        color: theme.palette.text.secondary,
                      }),
                    }}
                    amount={
                      AmountType.fromFutureAmount(
                        balance[cardOption].getAmount(),
                      ) ?? new AmountType()
                    }
                  />
                  <Typography variant="body2">Available</Typography>
                  <Typography variant="body2" noWrap sx={{ pr: 2 }}>
                    ............................................................
                  </Typography>
                  <Amount
                    reverse
                    valueTypographyProps={{
                      variant: "body2",
                    }}
                    codeTypographyProps={{
                      variant: "body2",
                      sx: (theme) => ({
                        color: theme.palette.text.secondary,
                      }),
                    }}
                    amount={
                      AmountType.fromFutureAmount(
                        getAvailableBalance(balance[cardOption]),
                      ) ?? new AmountType()
                    }
                  />
                </Box>
              ) : (
                <Box>
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                </Box>
              )}

              <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                {/* Price Field  */}
                <LedgerAmountField
                  noDecimalPlaces={
                    exchangeStore.selectedMarketListing
                      ?.assetFractionalisationAllowed
                      ? 7
                      : 0
                  }
                  label="Price"
                  reverse
                  fullWidth
                  disabled={
                    loading || store.tradeCardState.sourceAccount === undefined
                  }
                  className={cx({
                    focused: amountFocused,
                  })}
                  sx={(theme) => ({
                    mb: 2,
                    "&.focused": {
                      ".MuiOutlinedInput-root": {
                        border: `1px solid ${theme.palette.secondary.main}`,
                        transition: "border 0.2s ease-in-out",
                      },
                    },
                  })}
                  adornmentProps={{
                    position: "end",
                    sx: (theme) => ({
                      color: theme.palette.text.disabled,
                    }),
                  }}
                  value={price}
                  onChange={(v) =>
                    actions.setPrice(price.setValue(v.getValue()))
                  }
                />
                {/* Amount Field */}
                {amount ? (
                  <LedgerAmountField
                    sx={{
                      mb: store.tradeCardState.fieldErrors.amount ? 0 : "21px",
                    }}
                    label={`Amount (${amount.getToken()?.getCode()})`}
                    fullWidth
                    noDecimalPlaces={
                      exchangeStore.selectedMarketListing
                        ?.assetFractionalisationAllowed
                        ? 7
                        : 0
                    }
                    disabled={
                      loading ||
                      store.tradeCardState.sourceAccount === undefined
                    }
                    reverse
                    disallowNegative
                    inputProps={{
                      sx: {
                        textAlign: "left",
                        px: 2,
                      },
                    }}
                    value={amount}
                    onChange={(_v) => {
                      actions.setAmount(amount.setValue(_v.getValue()));
                    }}
                    endAdornment={
                      <Link
                        underline="none"
                        onClick={() => store.updateTradeCardState.setMax()}
                      >
                        Max
                      </Link>
                    }
                    adornmentProps={{
                      position: "end",
                      sx: (theme) => ({
                        color: theme.palette.secondary.main,
                      }),
                    }}
                    error={!!store.tradeCardState.fieldErrors.amount}
                    helperText={store.tradeCardState.fieldErrors.amount}
                  />
                ) : (
                  <Skeleton width={"100%"} height={"32px"} />
                )}
                {/* Estimated Total */}
                <LedgerAmountField
                  disallowNegative
                  noDecimalPlaces={
                    exchangeStore.selectedMarketListing
                      ?.assetFractionalisationAllowed
                      ? 6
                      : 0
                  }
                  reverse
                  label={`Est. Trade Value (${store.tradeCardState.estimatedTotal.getToken()?.getCode()})`}
                  fullWidth
                  disabled={
                    loading ||
                    store.tradeCardState.sourceAccount === undefined ||
                    !exchangeStore.selectedMarketListing
                      ?.assetFractionalisationAllowed
                  }
                  value={estimatedTotal}
                  onChange={(v) =>
                    actions.setEstimatedTotal(
                      estimatedTotal.setValue(v.getValue()),
                    )
                  }
                  endAdornment={
                    <Link
                      underline="none"
                      onClick={() => store.updateTradeCardState.setMax()}
                    >
                      Max
                    </Link>
                  }
                  adornmentProps={{
                    position: "end",
                    sx: (theme) => ({
                      color: theme.palette.secondary.main,
                    }),
                  }}
                  error={!!store.tradeCardState.fieldErrors.estimatedTotal}
                  helperText={store.tradeCardState.fieldErrors.estimatedTotal}
                />
              </Box>
            </Box>

            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
                onClick={() => setAccordionOpen(!accordionOpen)}
              >
                <IconButton>
                  <KeyboardArrowDownIcon
                    className={cx({
                      open: accordionOpen,
                    })}
                    sx={(theme) => ({
                      color: theme.palette.primary.main,
                      transition: "transform 160ms ease-in-out",
                      "&.open": {
                        transform: "rotate(-180deg)",
                      },
                    })}
                  />
                </IconButton>
                <Typography fontWeight={"bold"}>Trade Preview</Typography>
              </Box>
              <Collapse in={accordionOpen}>
                <Box
                  sx={{
                    "& > *": {
                      display: "flex",
                      gap: 2,
                    },
                  }}
                >
                  <Box>
                    <Typography color="textSecondary" variant="body2">
                      Trade Fee:
                    </Typography>
                    <Amount amount={AmountType.fromFutureAmount(fee.fee)} />
                  </Box>
                  <Box>
                    <Typography color="textSecondary" variant="body2">
                      Tax (VAT):
                    </Typography>
                    <Amount amount={AmountType.fromFutureAmount(fee.vat)} />
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </Box>
          {/* Button */}
          <Tooltip
            title={
              !store.tradeCardState.isSignatory ? (
                "User is not signatory on account"
              ) : store.tradeCardState.valid ? (
                ""
              ) : (
                <Box>
                  {Object.keys(store.tradeCardState.fieldErrors).map((key) => {
                    return (
                      <Typography>
                        -{" "}
                        {
                          store.tradeCardState.fieldErrors[
                            key as keyof TradeCardProps
                          ]
                        }
                      </Typography>
                    );
                  })}
                </Box>
              )
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  loading ||
                  store.loading ||
                  !store.tradeCardState.valid ||
                  !store.tradeCardState.isSignatory ||
                  store.tradeCardState.sourceAccount === undefined
                }
                sx={{
                  height: {
                    sm: 36,
                    xs: 48,
                  },
                  mt: 2,
                  mb: 2,
                }}
                onClick={() => setDialogOpen(true)}
              >
                Place Limit Order{" "}
                {loading && <CircularProgress sx={{ ml: 1 }} size={24} />}
              </Button>
            </span>
          </Tooltip>
        </CardContent>
      </Card>
      <LimitOrderConfirmationDialog
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
      />
    </>
  );
};
