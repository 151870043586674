/**
 * @fileoverview gRPC-Web generated client stub for banking
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: banking/bankAccountRepository_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var banking_bankAccount_pb = require('../banking/bankAccount_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var banking_fundingOrderState_pb = require('../banking/fundingOrderState_pb.js')
const proto = {};
proto.banking = require('./bankAccountRepository_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountRepositoryClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountRepositoryPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.CreateBankAccountRequest,
 *   !proto.banking.CreateBankAccountResponse>}
 */
const methodDescriptor_BankAccountRepository_CreateBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountRepository/CreateBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.CreateBankAccountRequest,
  proto.banking.CreateBankAccountResponse,
  /**
   * @param {!proto.banking.CreateBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.CreateBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.CreateBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.CreateBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.CreateBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountRepositoryClient.prototype.createBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountRepository/CreateBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_CreateBankAccount,
      callback);
};


/**
 * @param {!proto.banking.CreateBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.CreateBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountRepositoryPromiseClient.prototype.createBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountRepository/CreateBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_CreateBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.RetrieveBankAccountRequest,
 *   !proto.banking.RetrieveBankAccountResponse>}
 */
const methodDescriptor_BankAccountRepository_RetrieveBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountRepository/RetrieveBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.RetrieveBankAccountRequest,
  proto.banking.RetrieveBankAccountResponse,
  /**
   * @param {!proto.banking.RetrieveBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.RetrieveBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.RetrieveBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.RetrieveBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.RetrieveBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountRepositoryClient.prototype.retrieveBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountRepository/RetrieveBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_RetrieveBankAccount,
      callback);
};


/**
 * @param {!proto.banking.RetrieveBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.RetrieveBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountRepositoryPromiseClient.prototype.retrieveBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountRepository/RetrieveBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_RetrieveBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.UpdateBankAccountRequest,
 *   !proto.banking.UpdateBankAccountResponse>}
 */
const methodDescriptor_BankAccountRepository_UpdateBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountRepository/UpdateBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.UpdateBankAccountRequest,
  proto.banking.UpdateBankAccountResponse,
  /**
   * @param {!proto.banking.UpdateBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.UpdateBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.UpdateBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.UpdateBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.UpdateBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountRepositoryClient.prototype.updateBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountRepository/UpdateBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_UpdateBankAccount,
      callback);
};


/**
 * @param {!proto.banking.UpdateBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.UpdateBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountRepositoryPromiseClient.prototype.updateBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountRepository/UpdateBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_UpdateBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.SearchBankAccountRequest,
 *   !proto.banking.SearchBankAccountResponse>}
 */
const methodDescriptor_BankAccountRepository_SearchBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountRepository/SearchBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.SearchBankAccountRequest,
  proto.banking.SearchBankAccountResponse,
  /**
   * @param {!proto.banking.SearchBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.SearchBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.SearchBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.SearchBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.SearchBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountRepositoryClient.prototype.searchBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountRepository/SearchBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_SearchBankAccount,
      callback);
};


/**
 * @param {!proto.banking.SearchBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.SearchBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountRepositoryPromiseClient.prototype.searchBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountRepository/SearchBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_SearchBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.SearchAllBankAccountRequest,
 *   !proto.banking.SearchAllBankAccountResponse>}
 */
const methodDescriptor_BankAccountRepository_SearchAllBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountRepository/SearchAllBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.SearchAllBankAccountRequest,
  proto.banking.SearchAllBankAccountResponse,
  /**
   * @param {!proto.banking.SearchAllBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.SearchAllBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.SearchAllBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.SearchAllBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.SearchAllBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountRepositoryClient.prototype.searchAllBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountRepository/SearchAllBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_SearchAllBankAccount,
      callback);
};


/**
 * @param {!proto.banking.SearchAllBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.SearchAllBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountRepositoryPromiseClient.prototype.searchAllBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountRepository/SearchAllBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_SearchAllBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.DeleteBankAccountRequest,
 *   !proto.banking.DeleteBankAccountResponse>}
 */
const methodDescriptor_BankAccountRepository_DeleteBankAccount = new grpc.web.MethodDescriptor(
  '/banking.BankAccountRepository/DeleteBankAccount',
  grpc.web.MethodType.UNARY,
  proto.banking.DeleteBankAccountRequest,
  proto.banking.DeleteBankAccountResponse,
  /**
   * @param {!proto.banking.DeleteBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.DeleteBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.DeleteBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.DeleteBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.DeleteBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountRepositoryClient.prototype.deleteBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountRepository/DeleteBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_DeleteBankAccount,
      callback);
};


/**
 * @param {!proto.banking.DeleteBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.DeleteBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountRepositoryPromiseClient.prototype.deleteBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountRepository/DeleteBankAccount',
      request,
      metadata || {},
      methodDescriptor_BankAccountRepository_DeleteBankAccount);
};


module.exports = proto.banking;

