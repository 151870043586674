import React, { ReactElement, useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { LoadingContent } from "components/SubscriptionTicketDialog/components/LoadingContent";

export interface TwoStepDialogProps {
  open: boolean;
  isMobile: boolean;
  initialising: boolean;
  isStepTwo: boolean;
  header: ReactElement;
  stepOneDataComponentInfo?: string;
  stepOneContent: ReactElement;
  stepOneFooter: ReactElement;
  stepTwoDataComponentInfo?: string;
  stepTwoContent: ReactElement;
  stepTwoFooter: ReactElement;
}

export const TwoStepDialog = ({
  open,
  isMobile,
  initialising,
  isStepTwo,
  header,
  stepOneDataComponentInfo,
  stepOneContent,
  stepOneFooter,
  stepTwoDataComponentInfo,
  stepTwoContent,
  stepTwoFooter,
}: TwoStepDialogProps) => {
  const [stepTwoTriggered, setStepTwoTriggered] = useState(false); // Prevents enter animation on first open
  const [content, setContent] = useState<ReactElement>(stepOneContent);
  const [footer, setFooter] = useState<ReactElement>(stepOneFooter);

  // set confirmation triggered
  useEffect(() => {
    if (isStepTwo && !stepTwoTriggered) {
      setStepTwoTriggered(true);
    }
  }, [isStepTwo]);

  // update content and footer
  useEffect(() => {
    if (isStepTwo) {
      setContent(stepTwoContent);
      setFooter(stepTwoFooter);
    } else {
      setContent(stepOneContent);
      setFooter(stepOneFooter);
    }
  }, [
    isStepTwo,
    initialising,
    stepOneContent,
    stepOneFooter,
    stepTwoContent,
    stepTwoFooter,
  ]);

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          width: { sm: 420 },
          maxHeight: { sm: "calc(95%)" },
        },
        "data-component-info": !isStepTwo
          ? stepOneDataComponentInfo
          : stepTwoDataComponentInfo,
      }}
    >
      {header}

      {initialising ? (
        <LoadingContent />
      ) : (
        <>
          {content}
          {footer}
        </>
      )}
    </Dialog>
  );
};
