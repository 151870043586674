"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allSmartInstrumentTypes = void 0;
exports.smartInstrumentTypeToString = smartInstrumentTypeToString;
exports.stringToSmartInstrumentType = stringToSmartInstrumentType;
const smartInstrumentType_pb_1 = require("./smartInstrumentType_pb");
// Get all instrument types as enum values
exports.allSmartInstrumentTypes = Object.values(smartInstrumentType_pb_1.SmartInstrumentType).filter((value) => typeof value === "number");
// Define explicit mappings between SmartInstrumentType enums and custom string representations
const smartInstrumentTypeToStringMapping = {
    [smartInstrumentType_pb_1.SmartInstrumentType.UNDEFINED_SMART_INSTRUMENT_TYPE]: "-",
    [smartInstrumentType_pb_1.SmartInstrumentType.OTHER_SMART_INSTRUMENT_TYPE]: "Other",
    [smartInstrumentType_pb_1.SmartInstrumentType.SHARE_SMART_INSTRUMENT_TYPE]: "Share",
    [smartInstrumentType_pb_1.SmartInstrumentType.PREFERENCE_SHARE_SMART_INSTRUMENT_TYPE]: "Preference Share",
    [smartInstrumentType_pb_1.SmartInstrumentType.BOND_SMART_INSTRUMENT_TYPE]: "Bond",
    [smartInstrumentType_pb_1.SmartInstrumentType.ETF_SMART_INSTRUMENT_TYPE]: "ETF",
    [smartInstrumentType_pb_1.SmartInstrumentType.ETN_SMART_INSTRUMENT_TYPE]: "ETN",
    [smartInstrumentType_pb_1.SmartInstrumentType.AMC_SMART_INSTRUMENT_TYPE]: "AMC",
    [smartInstrumentType_pb_1.SmartInstrumentType.UNIT_TRUST_SMART_INSTRUMENT_TYPE]: "Unit Trust",
    [smartInstrumentType_pb_1.SmartInstrumentType.FIAT_CURRENCY_SMART_INSTRUMENT_TYPE]: "Fiat Currency",
    [smartInstrumentType_pb_1.SmartInstrumentType.CRYPTO_CURRENCY_SMART_INSTRUMENT_TYPE]: "Crypto Currency",
    [smartInstrumentType_pb_1.SmartInstrumentType.RIGHTS_SMART_INSTRUMENT_TYPE]: "Rights",
    [smartInstrumentType_pb_1.SmartInstrumentType.GOLD_SMART_INSTRUMENT_TYPE]: "Gold",
    [smartInstrumentType_pb_1.SmartInstrumentType.SILVER_SMART_INSTRUMENT_TYPE]: "Silver",
    [smartInstrumentType_pb_1.SmartInstrumentType.PLATINUM_SMART_INSTRUMENT_TYPE]: "Platinum",
    [smartInstrumentType_pb_1.SmartInstrumentType.PALLADIUM_SMART_INSTRUMENT_TYPE]: "Palladium",
    [smartInstrumentType_pb_1.SmartInstrumentType.CRUDE_OIL_SMART_INSTRUMENT_TYPE]: "Crude Oil",
    [smartInstrumentType_pb_1.SmartInstrumentType.NATURAL_GAS_SMART_INSTRUMENT_TYPE]: "Natural Gas",
    [smartInstrumentType_pb_1.SmartInstrumentType.COPPER_SMART_INSTRUMENT_TYPE]: "Copper",
    [smartInstrumentType_pb_1.SmartInstrumentType.CORN_SMART_INSTRUMENT_TYPE]: "Corn",
    [smartInstrumentType_pb_1.SmartInstrumentType.WHEAT_SMART_INSTRUMENT_TYPE]: "Wheat",
    [smartInstrumentType_pb_1.SmartInstrumentType.SOYBEANS_SMART_INSTRUMENT_TYPE]: "Soybeans",
};
// Reverse mapping from string to SmartInstrumentType enum
const stringToSmartInstrumentTypeMapping = {};
for (const [key, value] of Object.entries(smartInstrumentTypeToStringMapping)) {
    stringToSmartInstrumentTypeMapping[value] = Number(key);
}
class UnsupportedSmartInstrumentTypeError extends Error {
    constructor(smartInstrumentType) {
        const message = `Unsupported SmartInstrumentType: ${smartInstrumentType}`;
        super(message);
        this.smartInstrumentType = smartInstrumentType;
    }
}
/**
 * Converts a SmartInstrumentType enum instance to a custom string representation.
 * @param {SmartInstrumentType} smartInstrumentType - The instrument type to convert.
 * @returns {string} The custom string representation of the instrument type.
 */
function smartInstrumentTypeToString(smartInstrumentType) {
    if (smartInstrumentType in smartInstrumentTypeToStringMapping) {
        return smartInstrumentTypeToStringMapping[smartInstrumentType];
    }
    else {
        throw new UnsupportedSmartInstrumentTypeError(smartInstrumentType);
    }
}
class UnsupportedSmartInstrumentTypeStringError extends Error {
    constructor(smartInstrumentTypeStr) {
        const message = `Unsupported instrument type string: ${smartInstrumentTypeStr}`;
        super(message);
        this.smartInstrumentTypeStr = smartInstrumentTypeStr;
    }
}
/**
 * Converts a custom string representation to a SmartInstrumentType enum instance.
 * @param {string} smartInstrumentTypeStr - The custom string representation of the instrument type.
 * @returns {SmartInstrumentType} The corresponding SmartInstrumentType enum instance.
 */
function stringToSmartInstrumentType(smartInstrumentTypeStr) {
    if (smartInstrumentTypeStr in stringToSmartInstrumentTypeMapping) {
        return stringToSmartInstrumentTypeMapping[smartInstrumentTypeStr];
    }
    else {
        throw new UnsupportedSmartInstrumentTypeStringError(smartInstrumentTypeStr);
    }
}
