import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Link,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import meshLogo from "assets/images/logo/meshLogo.svg";
import cx from "classnames";
import backgroundCroppedDark from "assets/images/background/backgroundCropped.jpeg";
import { useWidth } from "utilities/general";
import { isWidthDown, isWidthUp } from "@mui/material/Hidden/withWidth";
import { GuidesController } from "james/user/GuidesController";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import { motion, useMotionValue } from "framer-motion";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";
import Lottie from "lottie-react";
import step1 from "assets/lotties/MESH-FTUE-Animation-01.json";
import step2 from "assets/lotties/MESH-FTUE-Animation-02.json";
import step3 from "assets/lotties/MESH-FTUE-Animation-03.json";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { GettingStartedStepWrapper } from "./components/GettingStartedStepGTMWrapper";
import { TutorialNames, TutorialTypes } from "types/gtm";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { FirstTimeLoginButton } from "./components/FirstTimeLoginButton";
import { useErrorContext } from "context/Error";
import range from "lodash/range";
import { useNavigate } from "react-router-dom";
import config from "react-global-configuration";
import { usePersonContext } from "context/Person/PersonService";

export enum IndividualUserFirstLoginViewState {
  GettingStartedStepOne,
  GettingStartedStepTwo,
  GettingStartedStepThree,
  GettingStartedStepFour,
  Loading,
}

export function FirstLoginSteps() {
  const { errorContextErrorTranslator } = useErrorContext();
  const width = useWidth();
  const isMobile = isWidthDown("sm", width);
  const isDesktop = isWidthUp("sm", width);
  const { authContext } = useApplicationContext();
  const { myPerson } = usePersonContext();
  const [viewState, setViewState] = useState<IndividualUserFirstLoginViewState>(
    IndividualUserFirstLoginViewState.GettingStartedStepOne,
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { refreshLoginClaims } = useApplicationContext();
  const { pushTutorialStartedEvent, pushTutorialCompletedEvent } =
    useGTMTriggersPusher();

  const handleCompleteFirstLoginSteps = async () => {
    setViewState(IndividualUserFirstLoginViewState.Loading);
    try {
      await GuidesController.CompleteFirstTimeLoginSteps({
        context: authContext,
      });
      await refreshLoginClaims();
      pushTutorialCompletedEvent({
        tutorial_name: TutorialNames.First_Time_Login_Steps,
        tutorial_type: TutorialTypes.Onboarding,
        tutorial_start: false,
        tutorial_complete: true,
      });
      if (
        config.get("allowMFAVerification") &&
        !myPerson.getContactdetails()?.getCellphonenumber()?.getVerified()
      ) {
        navigate("/verify-mobile-number");
      } else {
        navigate("/market");
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error Completing Profile: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      console.error(
        `error completing profile: ${
          err.message ? err.message : err.toString()
        }`,
      );
    }
  };

  // Create a scroll box reference so that we can auto-scroll to the top of each
  // step section when proceeding to next step
  const scrollBoxRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    scrollBoxRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [viewState]);

  const handleOnDrag = (newStep: IndividualUserFirstLoginViewState) => {
    setViewState(newStep);
  };

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up("sm")]: {
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "hidden",
          backgroundImage: `url(${backgroundCroppedDark})`,
          backgroundSize: "cover",
        },
      })}
    >
      {isDesktop && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "grid",
            gridTemplateRows: "auto 1fr auto",
          }}
        >
          <Box
            className={"meshScroll"}
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "100%",
            }}
          >
            <Box
              sx={(theme) => ({
                height: "100%",
                paddingBottom: theme.spacing(2.5),
                overflowY: "visible",
                display: "grid",
                gridTemplateRows: "0px 1fr",
              })}
            >
              <Box ref={scrollBoxRef} />
              {(() => {
                switch (viewState) {
                  case IndividualUserFirstLoginViewState.GettingStartedStepFour:
                    return (
                      <GettingStartedStepFour
                        onStartCLick={handleCompleteFirstLoginSteps}
                      />
                    );
                  case IndividualUserFirstLoginViewState.GettingStartedStepThree:
                    return (
                      <GettingStartedStepThree
                        onNextClick={() =>
                          setViewState(
                            IndividualUserFirstLoginViewState.GettingStartedStepFour,
                          )
                        }
                        onSkipClick={handleCompleteFirstLoginSteps}
                      />
                    );
                  case IndividualUserFirstLoginViewState.GettingStartedStepTwo:
                    return (
                      <GettingStartedStepTwo
                        onNextClick={() =>
                          setViewState(
                            IndividualUserFirstLoginViewState.GettingStartedStepThree,
                          )
                        }
                        onSkipClick={handleCompleteFirstLoginSteps}
                      />
                    );
                  case IndividualUserFirstLoginViewState.GettingStartedStepOne:
                    return (
                      <GettingStartedStepOne
                        onNextClick={() =>
                          setViewState(
                            IndividualUserFirstLoginViewState.GettingStartedStepTwo,
                          )
                        }
                        onSkipClick={handleCompleteFirstLoginSteps}
                        triggerTutorialStarted={() => {
                          pushTutorialStartedEvent({
                            tutorial_name: TutorialNames.First_Time_Login_Steps,
                            tutorial_type: TutorialTypes.Onboarding,
                            tutorial_start: false,
                            tutorial_complete: true,
                          });
                        }}
                      />
                    );
                  case IndividualUserFirstLoginViewState.Loading:
                    return <LoadingScreen />;
                }
              })()}
            </Box>
          </Box>
          {viewState !== IndividualUserFirstLoginViewState.Loading && (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                py: 3,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Ellipses
                noEllipses={4}
                selectedEllipse={viewState}
                clickActions={[
                  () =>
                    setViewState(
                      IndividualUserFirstLoginViewState.GettingStartedStepOne,
                    ),
                  () =>
                    setViewState(
                      IndividualUserFirstLoginViewState.GettingStartedStepTwo,
                    ),
                  () =>
                    setViewState(
                      IndividualUserFirstLoginViewState.GettingStartedStepThree,
                    ),
                  () =>
                    setViewState(
                      IndividualUserFirstLoginViewState.GettingStartedStepFour,
                    ),
                ]}
              />
            </Box>
          )}
        </Box>
      )}
      {isMobile && (
        <Grid>
          {(() => {
            switch (viewState) {
              case IndividualUserFirstLoginViewState.GettingStartedStepFour:
                return (
                  <GettingStartedStepFour
                    onStartCLick={handleCompleteFirstLoginSteps}
                    onDrag={handleOnDrag}
                  />
                );
              case IndividualUserFirstLoginViewState.GettingStartedStepThree:
                return (
                  <GettingStartedStepThree
                    onSkipClick={handleCompleteFirstLoginSteps}
                    onDrag={handleOnDrag}
                  />
                );
              case IndividualUserFirstLoginViewState.GettingStartedStepTwo:
                return (
                  <GettingStartedStepTwo
                    onSkipClick={handleCompleteFirstLoginSteps}
                    onDrag={handleOnDrag}
                  />
                );
              case IndividualUserFirstLoginViewState.GettingStartedStepOne:
                return (
                  <GettingStartedStepOne
                    onSkipClick={handleCompleteFirstLoginSteps}
                    onDrag={handleOnDrag}
                    triggerTutorialStarted={() => {
                      pushTutorialStartedEvent({
                        tutorial_name: TutorialNames.First_Time_Login_Steps,
                        tutorial_type: TutorialTypes.Onboarding,
                        tutorial_start: false,
                        tutorial_complete: true,
                      });
                    }}
                  />
                );
              case IndividualUserFirstLoginViewState.Loading:
                return <LoadingScreen />;
            }
          })()}
          {viewState !== IndividualUserFirstLoginViewState.Loading && (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Ellipses
                noEllipses={4}
                selectedEllipse={viewState}
                clickActions={[
                  () =>
                    setViewState(
                      IndividualUserFirstLoginViewState.GettingStartedStepOne,
                    ),
                  () =>
                    setViewState(
                      IndividualUserFirstLoginViewState.GettingStartedStepTwo,
                    ),
                  () =>
                    setViewState(
                      IndividualUserFirstLoginViewState.GettingStartedStepThree,
                    ),
                  () =>
                    setViewState(
                      IndividualUserFirstLoginViewState.GettingStartedStepFour,
                    ),
                ]}
              />
            </Box>
          )}
        </Grid>
      )}
    </Box>
  );
}

export interface GettingStartedStepOneProps {
  onNextClick?: () => void;
  onSkipClick: () => void;
  // only called on mobile to set a view state depending on the
  // drag direction
  onDrag?: (newStep: IndividualUserFirstLoginViewState) => void;
  triggerTutorialStarted: () => void;
}

export function GettingStartedStepOne({
  onNextClick,
  onSkipClick,
  onDrag,
  triggerTutorialStarted,
}: GettingStartedStepOneProps) {
  const width = useWidth();
  const isMobile = isWidthDown("sm", width);
  const isDesktop = isWidthUp("sm", width);
  const x = useMotionValue(0);
  const theme = useTheme();

  return (
    <>
      {isDesktop && (
        <GettingStartedStepWrapper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: window.innerWidth,
              padding: theme.spacing(7 / 8, 2, 0),
            })}
          >
            <Box
              component={motion.img}
              style={{
                width: "123px",
                height: "56px",
              }}
              src={meshLogo}
            />
            <FirstTimeLoginButton
              id="firstTimeLogin-skipStep1-button"
              sx={(theme) => ({
                height: theme.spacing(4.5),
                width: "110px",
              })}
              variant="outlined"
              onClick={() => {
                triggerTutorialStarted();
                onSkipClick();
              }}
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "skip",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Skip
            </FirstTimeLoginButton>
          </Box>
          <Lottie
            animationData={step1}
            autoplay
            loop={false}
            style={{
              width: 312,
              height: 320,
              margin: theme.spacing(10, 0, 5),
            }}
          />
          <VerticalSlide transitionDuration={1 / 3}>
            <Typography
              sx={(theme) => ({
                margin: 0,
                padding: theme.spacing(0, 2),
              })}
              fontSize="20px"
              fontWeight="bold"
              variant="h4"
              align="center"
            >
              Crypto and Digital Instruments
            </Typography>
          </VerticalSlide>
          <VerticalSlide transitionDuration={2 / 3}>
            <Typography
              sx={(theme) => ({
                margin: 0,
                padding: theme.spacing(1, 2, 3),
                color: theme.palette.custom.lavender,
                width: "476px",
              })}
              fontSize="16px"
              align="center"
            >
              Whether you want to buy, sell or invest, access one market for all
              your TradFi and DeFi instruments
            </Typography>
          </VerticalSlide>
          <VerticalSlide transitionDuration={1}>
            <FirstTimeLoginButton
              sx={(theme) => ({
                height: theme.spacing(4.5),
                width: "164px",
              })}
              id="firstTimeLogin-step1-next-button"
              onClick={() => {
                if (onNextClick) {
                  triggerTutorialStarted();
                  onNextClick();
                }
              }}
              variant="contained"
              color="primary"
              size="large"
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "next",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Next
            </FirstTimeLoginButton>
          </VerticalSlide>
        </GettingStartedStepWrapper>
      )}
      {isMobile && (
        <GettingStartedStepWrapper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: window.innerWidth,
              padding: theme.spacing(7 / 8, 2, 6.5),
            })}
          >
            <Box
              component={motion.img}
              style={{
                width: 40,
                height: 39,
              }}
              src={meshMiniLogo}
            />
            <Link
              sx={(theme) => ({
                color: theme.palette.secondary.main,
              })}
              onClick={onSkipClick}
              fontSize="12px"
              fontWeight="bold"
              component="button"
              underline="none"
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "skip intro",
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Skip intro
            </Link>
          </Box>
          <motion.div
            drag={"x"}
            dragConstraints={{
              left: 0,
              right: 0,
            }}
            dragElastic={0.7}
            style={{
              x,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignSelf: "center",
            }}
            // checks whether the x-motion value is less than -50 after the user
            // stops swiping/dragging
            onDragEnd={() => {
              if (x.get() < -50) {
                onDrag?.(
                  IndividualUserFirstLoginViewState.GettingStartedStepTwo,
                );
              }
            }}
          >
            <Lottie
              animationData={step1}
              autoplay
              loop={false}
              style={{
                width: 312,
                height: 320,
                marginBottom: 40,
              }}
            />
            <VerticalSlide transitionDuration={1 / 2}>
              <Typography
                sx={(theme) => ({
                  margin: 0,
                  padding: theme.spacing(0, 2),
                })}
                fontSize="20px"
                fontWeight="bold"
                variant="h4"
              >
                Crypto and Digital Instruments
              </Typography>
            </VerticalSlide>
            <VerticalSlide transitionDuration={1}>
              <Typography
                sx={(theme) => ({
                  margin: 0,
                  padding: theme.spacing(1, 2, 5.5),
                  color: theme.palette.custom.lavender,
                })}
                fontSize="16px"
                align="center"
              >
                Whether you want to buy, sell or invest, access one market for
                all your TradFi and DeFi instruments
              </Typography>
            </VerticalSlide>
          </motion.div>
        </GettingStartedStepWrapper>
      )}
    </>
  );
}

export interface GettingStartedStepTwoProps {
  onNextClick?: () => void;
  onSkipClick: () => void;
  // only called on mobile to set a view state depending on the
  // drag direction
  onDrag?: (newStep: IndividualUserFirstLoginViewState) => void;
}

export function GettingStartedStepTwo({
  onNextClick,
  onSkipClick,
  onDrag,
}: GettingStartedStepTwoProps) {
  const x = useMotionValue(0);
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const theme = useTheme();

  return (
    <>
      {smUp ? (
        <GettingStartedStepWrapper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          data-component-info={JSON.stringify({
            component_id: "onboarding_tour",
            component_business_name: "login tour",
            component_title: "orders & trades",
            component_driver: "tutorial",
          } as DataComponentInfo)}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: window.innerWidth,
              padding: theme.spacing(7 / 8, 2, 0),
            })}
          >
            <Box
              component={motion.img}
              style={{
                width: "123px",
                height: "56px",
              }}
              src={meshLogo}
            />
            <FirstTimeLoginButton
              id="firstTimeLogin-skipStep1-button"
              sx={(theme) => ({
                height: theme.spacing(4.5),
                width: "110px",
              })}
              variant="outlined"
              onClick={onSkipClick}
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "skip",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Skip
            </FirstTimeLoginButton>
          </Box>
          <Lottie
            animationData={step2}
            autoplay
            loop={false}
            style={{
              width: 312,
              height: 320,
              margin: theme.spacing(10, 0, 5),
            }}
          />
          <VerticalSlide transitionDuration={1 / 3}>
            <Typography
              sx={(theme) => ({
                margin: 0,
                padding: theme.spacing(0, 2),
              })}
              fontSize="20px"
              fontWeight="bold"
              variant="h4"
              align="center"
            >
              Orders & Trades
            </Typography>
          </VerticalSlide>
          <VerticalSlide transitionDuration={2 / 3}>
            <Typography
              sx={(theme) => ({
                margin: 0,
                padding: theme.spacing(1, 2, 3),
                color: theme.palette.custom.lavender,
                width: "476px",
              })}
              fontSize="16px"
              align="center"
            >
              Manage all your assets with complete visibility into {<br />} your
              current and historical activity
            </Typography>
          </VerticalSlide>
          <VerticalSlide transitionDuration={1}>
            <FirstTimeLoginButton
              sx={(theme) => ({
                height: theme.spacing(4.5),
                width: "164px",
              })}
              id="firstTimeLogin-step2-next-button"
              onClick={onNextClick}
              variant="contained"
              color="primary"
              size="large"
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "next",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Next
            </FirstTimeLoginButton>
          </VerticalSlide>
        </GettingStartedStepWrapper>
      ) : (
        <GettingStartedStepWrapper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          data-component-info={JSON.stringify({
            component_id: "onboarding_tour",
            component_business_name: "login tour",
            component_title: "orders & trades",
            component_driver: "tutorial",
          } as DataComponentInfo)}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: window.innerWidth,
              padding: theme.spacing(7 / 8, 2, 6.5),
            })}
          >
            <Box
              component="img"
              style={{
                width: 40,
                height: 39,
              }}
              src={meshMiniLogo}
            />
            <Link
              sx={(theme) => ({
                color: theme.palette.secondary.main,
              })}
              onClick={onSkipClick}
              fontSize="12px"
              fontWeight="bold"
              component="button"
              underline="none"
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "skip intro",
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Skip intro
            </Link>
          </Box>
          <motion.div
            drag={"x"}
            dragConstraints={{
              left: 0,
              right: 0,
            }}
            dragElastic={0.7}
            style={{
              x,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignSelf: "center",
            }}
            // checks whether the x-motion value is greater than 50 or less than -50 after the user
            // stops swiping/dragging
            onDragEnd={() => {
              if (x.get() < -50) {
                onDrag?.(
                  IndividualUserFirstLoginViewState.GettingStartedStepThree,
                );
              } else if (x.get() > 50) {
                onDrag?.(
                  IndividualUserFirstLoginViewState.GettingStartedStepOne,
                );
              }
            }}
          >
            <Lottie
              animationData={step2}
              autoplay
              loop={false}
              style={{
                width: 312,
                height: 320,
                marginBottom: 44,
              }}
            />
            <VerticalSlide transitionDuration={1 / 2}>
              <Typography
                sx={(theme) => ({
                  margin: 0,
                  padding: theme.spacing(0, 2),
                })}
                fontSize="20px"
                fontWeight="bold"
                variant="h4"
              >
                Orders & Trades
              </Typography>
            </VerticalSlide>
            <VerticalSlide transitionDuration={1}>
              <Typography
                sx={(theme) => ({
                  margin: 0,
                  padding: theme.spacing(1, 2, 5.5),
                  color: theme.palette.custom.lavender,
                })}
                fontSize="16px"
                align="center"
              >
                Manage all your assets with complete visibility into your
                current and {<br />} historical activity
              </Typography>
            </VerticalSlide>
          </motion.div>
        </GettingStartedStepWrapper>
      )}
    </>
  );
}

export interface GettingStartedStepThreeProps {
  onNextClick?: () => void;
  onSkipClick: () => void;
  // only called on mobile to set a view state depending on the
  // drag direction
  onDrag?: (newStep: IndividualUserFirstLoginViewState) => void;
}

export function GettingStartedStepThree(props: GettingStartedStepThreeProps) {
  const { onNextClick, onSkipClick } = props;
  const x = useMotionValue(0);
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const theme = useTheme();

  return (
    <>
      {smUp ? (
        <GettingStartedStepWrapper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: window.innerWidth,
              padding: theme.spacing(7 / 8, 2, 0),
            })}
          >
            <Box
              component={motion.img}
              style={{
                width: "123px",
                height: "56px",
              }}
              src={meshLogo}
            />
            <FirstTimeLoginButton
              id="firstTimeLogin-skipStep1-button"
              sx={(theme) => ({
                height: theme.spacing(4.5),
                width: "110px",
              })}
              variant="outlined"
              onClick={onSkipClick}
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "skip",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Skip
            </FirstTimeLoginButton>
          </Box>
          <Lottie
            animationData={step3}
            autoplay
            loop={false}
            style={{
              width: 312,
              height: 320,
              margin: theme.spacing(10, 0, 5),
            }}
          />
          <VerticalSlide transitionDuration={1 / 3}>
            <Typography
              sx={(theme) => ({
                margin: 0,
                padding: theme.spacing(0, 2),
              })}
              fontSize="20px"
              fontWeight="bold"
              variant="h4"
              align="center"
            >
              Mesh Accounts
            </Typography>
          </VerticalSlide>
          <VerticalSlide transitionDuration={2 / 3}>
            <Typography
              sx={(theme) => ({
                pb: 3,
                pt: 1,
                color: theme.palette.custom.lavender,
                width: "482px",
              })}
              fontSize="16px"
              align="center"
            >
              Your investment decisions can be informed by the latest
              performance data and profit and loss metrics
            </Typography>
          </VerticalSlide>
          <VerticalSlide transitionDuration={1}>
            <FirstTimeLoginButton
              sx={(theme) => ({
                height: theme.spacing(4.5),
                width: "164px",
              })}
              id="firstTimeLogin-step3-next-button"
              onClick={onNextClick}
              variant="contained"
              color="primary"
              size="large"
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "next",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Next
            </FirstTimeLoginButton>
          </VerticalSlide>
        </GettingStartedStepWrapper>
      ) : (
        <GettingStartedStepWrapper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: window.innerWidth,
              padding: theme.spacing(7 / 8, 2, 0),
            })}
          >
            <Box
              component="img"
              style={{
                width: 40,
                height: 39,
              }}
              src={meshMiniLogo}
            />
            <Link
              sx={(theme) => ({
                color: theme.palette.secondary.main,
              })}
              onClick={onSkipClick}
              fontSize="12px"
              fontWeight="bold"
              component="button"
              underline="none"
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "skip intro",
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Skip intro
            </Link>
          </Box>
          <motion.div
            drag={"x"}
            dragConstraints={{
              left: 0,
              right: 0,
            }}
            dragElastic={0.7}
            style={{
              x,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignSelf: "center",
            }}
            // checks whether the x-motion value is greater than 50 or less than -50 after the user
            // stops swiping/dragging
            onDragEnd={() => {
              if (x.get() < -50) {
                props.onDrag?.(
                  IndividualUserFirstLoginViewState.GettingStartedStepFour,
                );
              } else if (x.get() > 50) {
                props.onDrag?.(
                  IndividualUserFirstLoginViewState.GettingStartedStepTwo,
                );
              }
            }}
          >
            <Lottie
              animationData={step3}
              autoplay
              loop={false}
              style={{
                width: 312,
                height: 320,
                margin: theme.spacing(6, 0, 6),
              }}
            />
            <VerticalSlide transitionDuration={1 / 2}>
              <Typography
                sx={(theme) => ({
                  margin: 0,
                  padding: theme.spacing(0, 2),
                })}
                fontSize="20px"
                fontWeight="bold"
                variant="h4"
              >
                Mesh Accounts
              </Typography>
            </VerticalSlide>
            <VerticalSlide transitionDuration={1}>
              <Typography
                sx={(theme) => ({
                  margin: 0,
                  padding: theme.spacing(1, 2, 5.5),
                  color: theme.palette.custom.lavender,
                })}
                fontSize="16px"
                align="center"
              >
                Your investment decisions can be informed by the latest
                performance data and profit loss metrics
              </Typography>
            </VerticalSlide>
          </motion.div>
        </GettingStartedStepWrapper>
      )}
    </>
  );
}

export interface GettingStartedStepFourProps {
  onStartCLick: () => void;
  // only called on mobile to set a view state depending on the
  // drag direction
  onDrag?: (newStep: IndividualUserFirstLoginViewState) => void;
}

export function GettingStartedStepFour({
  onDrag,
  onStartCLick,
}: GettingStartedStepFourProps) {
  const width = useWidth();
  const x = useMotionValue(0);

  return (
    <>
      {isWidthDown("sm", width) && (
        <motion.div
          drag={"x"}
          dragConstraints={{
            left: 0,
            right: 0,
          }}
          dragElastic={0.7}
          style={{ x }}
          // checks whether the x-motion value is greater than 50 after the user
          // stops swiping/dragging
          onDragEnd={() => {
            if (x.get() > 50) {
              onDrag?.(
                IndividualUserFirstLoginViewState.GettingStartedStepThree,
              );
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                paddingTop: "167px",
                marginBottom: "42px",
              }}
            >
              <Box
                component={motion.img}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                style={{
                  width: 154,
                }}
                src={meshMiniLogo}
              />
            </Box>
            <VerticalSlide transitionDuration={1 / 3}>
              <Typography fontSize="20px" variant="h4" fontWeight="bold">
                Welcome to Mesh
              </Typography>
            </VerticalSlide>
            <VerticalSlide transitionDuration={2 / 3}>
              <Typography
                sx={(theme) => ({
                  margin: 0,
                  padding: theme.spacing(1, 2, 5.5),
                  color: theme.palette.custom.lavender,
                })}
                fontSize="16px"
                align="center"
              >
                Buy, sell, and invest in all digital {<br />} assets and
                instruments
              </Typography>
            </VerticalSlide>
            <VerticalSlide transitionDuration={1}>
              <FirstTimeLoginButton
                sx={(theme) => ({
                  height: theme.spacing(6),
                  width: "312px",
                  marginBottom: theme.spacing(9.125),
                })}
                id="firstTimeLogin-start-DeskTopButton"
                onClick={onStartCLick}
                variant="contained"
                color="primary"
                size="large"
                data-link-info={JSON.stringify({
                  content_interaction_id: "onboarding-tour-navlink",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "start",
                  content_interaction_type: InteractionType.Button,
                  content_interaction_driver: InteractionDriver.Navigation,
                } as DataLinkInfoType)}
              >
                Start
              </FirstTimeLoginButton>
            </VerticalSlide>
          </Box>
        </motion.div>
      )}
      {isWidthUp("sm", width) && (
        <GettingStartedStepWrapper
          sx={{
            display: "flex",
            justifyContent: "auto",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={(theme) => ({
              mt: theme.spacing(39),
              mb: theme.spacing(5),
            })}
          >
            <Box
              component={motion.img}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
              style={{
                width: 154,
              }}
              src={meshMiniLogo}
            />
          </Box>
          <VerticalSlide transitionDuration={1 / 3}>
            <Typography
              fontSize="20px"
              fontWeight="bold"
              variant="h4"
              align="center"
            >
              Welcome to Mesh
            </Typography>
          </VerticalSlide>
          <VerticalSlide transitionDuration={2 / 3}>
            <Typography
              sx={(theme) => ({
                margin: 0,
                padding: theme.spacing(1, 2, 3),
                color: theme.palette.custom.lavender,
              })}
              fontSize="16px"
              align="center"
            >
              Buy, sell, and invest in all digital {<br />} assets and
              instruments
            </Typography>
          </VerticalSlide>
          <VerticalSlide transitionDuration={1}>
            <FirstTimeLoginButton
              sx={(theme) => ({
                height: theme.spacing(4.5),
                width: "164px",
              })}
              id="firstTimeLogin-step4-start-mobileButton"
              onClick={onStartCLick}
              variant="contained"
              color="primary"
              size="large"
              data-link-info={JSON.stringify({
                content_interaction_id: "onboarding-tour-navlink",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "start",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
            >
              Start
            </FirstTimeLoginButton>
          </VerticalSlide>
        </GettingStartedStepWrapper>
      )}
    </>
  );
}

export interface EllipsesProps {
  noEllipses: number;
  selectedEllipse: number;
  clickActions?: (() => void)[];
}

export function Ellipses(props: EllipsesProps) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
      }}
    >
      {range(props.noEllipses).map((v, i) => {
        return (
          <Box
            onClick={() =>
              props.clickActions ? props.clickActions[i]() : undefined
            }
            key={i}
            className={cx({
              "selected-ellipse": props.selectedEllipse === i,
              cursor:
                props.clickActions !== undefined &&
                props.clickActions.length !== 0,
            })}
            sx={(theme) => ({
              width: theme.spacing(1),
              height: theme.spacing(1),
              borderRadius: "100%",
              backgroundColor: "grey",
              "&.cursor": {
                cursor: "pointer",
              },
              "&.selected-ellipse": {
                backgroundColor: theme.palette.primary.main,
              },
            })}
          />
        );
      })}
    </Box>
  );
}
