import { Close as CloseIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Fade,
  FadeProps,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { TextField } from "components/FormFields";
import { countries } from "james/country";
import { AllBankNames, bankUniversalBranchCode } from "james/banking";
import { useApplicationContext } from "context/Application/Application";
import { AccountsContext } from "../../Accounts";
import { useErrorContext } from "context/Error";
import {
  accountTypeFriendlyName,
  friendlyName,
  friendlyNameToAccountType,
  friendlyNameToBankName,
  getBankAccountTypes,
} from "james/banking/bankAccount";
import { useAPIContext } from "context/API";
import {
  UpdateBankAccountNumberRequest,
  UpdateBankAccountTypeRequest,
  UpdateBankNameRequest,
  UpdateBranchCodeRequest,
} from "@mesh/common-js/src/banking/bankAccountUpdater_pb";
import { BankAccount } from "@mesh/common-js/src/banking/bankAccount_pb";
import { CreateNewBankAccountRequest } from "@mesh/common-js/src/banking/bankAccountCreator_pb";

export enum LinkedBankAccountMode {
  View,
  Create,
  Edit,
}

export interface LinkedBankAccountDialogProps {
  mode: LinkedBankAccountMode;
  onClose: () => void;
  open: boolean;
  bankAccount?: BankAccount;
  ownerID?: string;
}

export function LinkedBankAccountDialog(props: LinkedBankAccountDialogProps) {
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("ZA");
  const [bankAccountName, setBankAccountName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const { authContext } = useApplicationContext();
  const [validationState, setValidationState] = useState<{
    [key: string]: string | undefined;
  }>({});
  const { refreshBankAccounts } = useContext(AccountsContext);
  const [changesMade, setChangesMade] = useState(false);
  const { banking } = useAPIContext();

  const performValidations = () => {
    const newValidationState: { [key: string]: string | undefined } = {};

    if (!bankAccountName) {
      newValidationState.bankAccountName = "Cannot be blank.";
    }

    if (!branchCode) {
      newValidationState.branchCode = "Cannot be blank.";
    }

    if (!Number.isInteger(+branchCode)) {
      newValidationState.branchCode = "Must be a number.";
    }

    if (!accountNumber) {
      newValidationState.accountNumber = "Cannot be blank.";
    }

    if (!accountType) {
      newValidationState.accountType = "Cannot be blank.";
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  // useEffect that sets the form field value when the bank account is in view mode or edit mode
  useEffect(() => {
    let isMounted = true;
    if (
      (props.mode === LinkedBankAccountMode.View ||
        props.mode === LinkedBankAccountMode.Edit) &&
      props.bankAccount?.getOwnerid()
    ) {
      if (isMounted) {
        setAccountType(
          accountTypeFriendlyName(props.bankAccount?.getAccounttype()),
        );
        setBankAccountName(friendlyName(props.bankAccount?.getBankname()));
        setBranchCode(props.bankAccount?.getBranchcode());
        setAccountNumber(props.bankAccount?.getNumber());
      }
    }
    return () => {
      isMounted = false;
    };
  }, [props.mode, props.bankAccount]);

  // useEffect that  checks if changes were made need to be saved to the bank account
  useEffect(() => {
    let isMounted = true;
    if (props.mode === LinkedBankAccountMode.Edit) {
      if (props.bankAccount?.getBranchcode() !== branchCode && isMounted) {
        setChangesMade(true);
      } else if (
        props.bankAccount?.getNumber() !== accountNumber &&
        isMounted
      ) {
        setChangesMade(true);
      } else if (
        accountTypeFriendlyName(props.bankAccount?.getAccounttype()) !==
          accountType &&
        isMounted
      ) {
        setChangesMade(true);
      } else if (
        friendlyName(props.bankAccount?.getBankname()) !== bankAccountName &&
        isMounted
      ) {
        setChangesMade(true);
      } else if (isMounted) {
        setChangesMade(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    props.mode,
    props.bankAccount,
    changesMade,
    accountNumber,
    branchCode,
    accountType,
    bankAccountName,
  ]);

  const handleCreateLinkedBankAccount = async () => {
    if (!performValidations()) {
      return;
    }

    setLoading(true);
    try {
      const request = new CreateNewBankAccountRequest()
        .setContext(authContext.toFuture())
        .setOwnerid(props.ownerID ? props.ownerID : "")
        .setCountrycode(countryCode)
        .setBankname(friendlyNameToBankName(bankAccountName))
        .setAccounttype(friendlyNameToAccountType(accountType))
        .setBranchcode(branchCode)
        .setNumber(accountNumber);

      await banking.bankAccountCreator.createNewBankAccount(request);
      refreshBankAccounts();
      props.onClose();
      return;
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
    }
    setLoading(false);
  };

  // validate the bank account name is not empty
  const handleUpdateLinkedBankAccount = async () => {
    if (!performValidations()) {
      return;
    }

    let bankAccountID = "";
    if (props.bankAccount) {
      bankAccountID = props.bankAccount.getId();
    }

    if (!bankAccountID) {
      console.error("bank account id is blank");
      return;
    }

    setLoading(true);
    try {
      if (bankAccountName !== friendlyName(props.bankAccount?.getBankname())) {
        const request = new UpdateBankNameRequest()
          .setContext(authContext.toFuture())
          .setBankaccountid(bankAccountID)
          .setBankname(friendlyNameToBankName(bankAccountName));

        await banking.bankAccountUpdater.updateBankName(request);
      }

      if (branchCode !== props.bankAccount?.getBranchcode()) {
        const request = new UpdateBranchCodeRequest()
          .setContext(authContext.toFuture())
          .setBankaccountid(bankAccountID)
          .setBranchcode(branchCode);

        await banking.bankAccountUpdater.updateBranchCode(request);
      }

      if (
        friendlyNameToAccountType(accountType) !==
        props.bankAccount?.getAccounttype()
      ) {
        const request = new UpdateBankAccountTypeRequest()
          .setContext(authContext.toFuture())
          .setBankaccountid(bankAccountID)
          .setBankaccounttype(friendlyNameToAccountType(accountType));

        await banking.bankAccountUpdater.updateBankAccountType(request);
      }

      if (accountNumber !== props.bankAccount?.getNumber()) {
        const request = new UpdateBankAccountNumberRequest()
          .setContext(authContext.toFuture())
          .setBankaccountid(bankAccountID)
          .setBankaccountnumber(accountNumber);

        await banking.bankAccountUpdater.updateBankAccountNumber(request);
      }
      refreshBankAccounts();
      props.onClose();
      return;
    } catch (e) {
      refreshBankAccounts();
      errorContextDefaultErrorFeedback(e);
      setLoading(false);
    }
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={props.open}
      TransitionComponent={isMobile ? slideTransition : fadeTransition}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.midnight,
          padding: theme.spacing(2, 2, 2, 3),
        })}
      >
        <Grid
          sx={{ alignItems: "center" }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid
            item
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gridColumnGap: theme.spacing(1),
            })}
          >
            {props.mode === LinkedBankAccountMode.Create && (
              <Typography variant="h5">Link a New Account</Typography>
            )}
            {props.mode === LinkedBankAccountMode.Edit && (
              <Typography variant="h5">Edit Account</Typography>
            )}
            {props.mode === LinkedBankAccountMode.View && (
              <Typography variant="h5">Linked Account</Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton
              id="linkedBankAccountDialog-close-button"
              onClick={props.onClose}
              disabled={loading}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={[
          { padding: `${theme.spacing(4, 5, 5, 5)} !important` },
          isMobile && {
            padding: `${theme.spacing(4, 3, 5, 3)} !important`,
          },
        ]}
      >
        <Autocomplete
          id="linkedBankAccountDialog-country-autocomplete"
          sx={[
            isMobile && {
              marginBottom: theme.spacing(1),
            },
          ]}
          options={countries}
          disabled
          getOptionLabel={(option) => (option ? option.label : "")}
          isOptionEqualToValue={(option, value) => {
            if (value.value === "") {
              return true;
            }
            return option.value === value.value;
          }}
          onChange={(__: object, country) => {
            if (country) {
              setCountryCode(country.value);
            }
          }}
          value={
            countryCode === ""
              ? {
                  value: "",
                  label: "",
                }
              : {
                  value: countryCode,
                  label: countries.filter((c) => c.value === countryCode)[0]
                    .label,
                }
          }
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Country"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Autocomplete
          id="linkedBankAccountDialog-bankAccountName-autocomplete"
          fullWidth
          disabled={
            loading ||
            props.mode === LinkedBankAccountMode.View ||
            props.mode === LinkedBankAccountMode.Edit
          }
          sx={[
            { marginBottom: theme.spacing(4) },
            isMobile && { marginBottom: theme.spacing(1) },
          ]}
          options={AllBankNames.map((name) => friendlyName(name))}
          isOptionEqualToValue={(option, value) => {
            if (value === "") {
              return true;
            }
            return option === value;
          }}
          value={bankAccountName || ""}
          onChange={(__: object, bankName) => {
            handleClearValidation("bankAccountName");
            if (bankName != "") {
              setBankAccountName(bankName || "");
              if (
                !getBankAccountTypes(
                  friendlyNameToBankName(bankName || ""),
                ).includes(friendlyNameToAccountType(accountType))
              ) {
                handleClearValidation("accountType");
                setAccountType("");
              }
              // set the universal branch code
              handleClearValidation("branchCode");
              setBranchCode(
                bankUniversalBranchCode(friendlyNameToBankName(bankName || "")),
              );
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Bank"
              error={!!validationState.bankAccountName}
              helperText={validationState.bankAccountName}
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <Box
          sx={[{ display: "flex" }, isMobile && { flexDirection: "column" }]}
        >
          <Autocomplete
            id="linkedBankAccountDialog-accountType-autocomplete"
            fullWidth
            disabled={loading || props.mode === LinkedBankAccountMode.View}
            options={getBankAccountTypes(
              friendlyNameToBankName(bankAccountName),
            ).map((type) => accountTypeFriendlyName(type))}
            sx={[
              { marginRight: theme.spacing(2) },
              isMobile && {
                marginRight: theme.spacing(0),
                marginBottom: theme.spacing(1),
              },
            ]}
            isOptionEqualToValue={(option, value) => {
              if (value === "") {
                return true;
              }
              return option === value;
            }}
            value={accountType || ""}
            onChange={(__: object, bankAccountType) => {
              handleClearValidation("accountType");
              if (bankAccountType != "") {
                setAccountType(bankAccountType || "");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!validationState.accountType}
                helperText={validationState.accountType}
                label="Account Type"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          <TextField
            id="linkedBankAccountDialog-branchCode-autocomplete"
            fullWidth={isMobile}
            sx={[isMobile && { marginBottom: theme.spacing(1) }]}
            disabled={loading || props.mode === LinkedBankAccountMode.View}
            label="Branch Code"
            value={branchCode}
            error={!!validationState.branchCode}
            helperText={validationState.branchCode}
            onChange={(e) => {
              handleClearValidation("branchCode");
              setBranchCode(e.target.value);
            }}
            variant="outlined"
          />
        </Box>
        <TextField
          id="linkedBankAccountDialog-accountNumber-autocomplete"
          fullWidth
          disabled={loading || props.mode === LinkedBankAccountMode.View}
          value={accountNumber}
          error={!!validationState.accountNumber}
          helperText={validationState.accountNumber}
          onChange={(e) => {
            handleClearValidation("accountNumber");
            setAccountNumber(e.target.value);
          }}
          label="Account Number"
          variant="outlined"
        />
        {!isMobile && (
          <Box
            sx={{
              marginTop: theme.spacing(3),
              display: "flex",
            }}
          >
            {props.mode === LinkedBankAccountMode.Create && (
              <Button
                id="linkedBankAccountDialog-createLinkedBankAccount-button"
                color="primary"
                sx={[
                  !isMobile && { marginRight: theme.spacing(1) },
                  isMobile && {
                    height: "48px",
                  },
                  loading && { marginRight: theme.spacing(2) },
                ]}
                disabled={loading}
                variant="contained"
                onClick={handleCreateLinkedBankAccount}
                children="Link Account"
                fullWidth={isMobile}
              />
            )}
            {props.mode === LinkedBankAccountMode.Edit && (
              <Button
                id="linkedBankAccountDialog-updateLinkedBankAccount-button"
                color="primary"
                sx={[
                  !isMobile && { marginRight: theme.spacing(1) },
                  isMobile && {
                    height: "48px",
                  },
                  loading && { marginRight: theme.spacing(2) },
                ]}
                disabled={loading || !changesMade}
                variant="contained"
                onClick={handleUpdateLinkedBankAccount}
                children="Save Changes"
                fullWidth={isMobile}
              />
            )}
            {loading && <CircularProgress />}
          </Box>
        )}
      </DialogContent>
      {props.mode === LinkedBankAccountMode.Create && isMobile && (
        <DialogTitle sx={{ paddingTop: 0 }}>
          <Box
            sx={{
              margin: theme.spacing(3, 3, 5, 3),
            }}
          >
            <Button
              id="mobileLinkedBankAccountDialog-createLinkedBankAccount-button"
              color="primary"
              sx={[
                !isMobile && { marginRight: theme.spacing(1) },
                isMobile && {
                  height: "48px",
                },
                loading && { marginRight: theme.spacing(2) },
              ]}
              component="div"
              disabled={loading}
              variant="contained"
              onClick={handleCreateLinkedBankAccount}
              children="Link Account"
              fullWidth
            />
          </Box>
        </DialogTitle>
      )}
      {props.mode === LinkedBankAccountMode.Edit && isMobile && (
        <DialogTitle sx={{ paddingTop: 0 }}>
          <Box
            sx={{
              margin: theme.spacing(3, 3, 5, 3),
            }}
          >
            <Button
              id="mobileLinkedBankAccountDialog-updateLinkedBankAccount-button"
              color="primary"
              sx={[
                !isMobile && { marginRight: theme.spacing(1) },
                isMobile && {
                  height: "48px",
                },
                loading && { marginRight: theme.spacing(2) },
              ]}
              component="div"
              disabled={loading || !changesMade}
              variant="contained"
              onClick={handleUpdateLinkedBankAccount}
              children="Save Changes"
              fullWidth
            />
          </Box>
        </DialogTitle>
      )}
    </Dialog>
  );
}

const slideTransition = (tprops: SlideProps) => {
  return <Slide direction="up" in {...tprops} />;
};

const fadeTransition = (tprops: FadeProps) => {
  return <Fade in {...tprops} />;
};

// validate account type is supported by bank
