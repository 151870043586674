import { Token, Amount } from "james/ledger";
import dayjs from "dayjs";
import { Entry } from "../audit/Entry";
import { LedgerNetwork } from "../ledger/Network";
import { BigNumber } from "bignumber.js";

const SubscriptionOrderBookTypeName = "mesh::market/SubscriptionOrderBook";

export enum SubscriptionOrderBookState {
  Pending = "Pending",
  Opening = "Opening",
  Open = "Open",
  Closed = "Closed",
  RequestingCancellation = "Requesting Cancellation",
  Cancelling = "Cancelling",
  Cancelled = "Cancelled",
  PreparingSettlement = "Preparing Settlement",
  Filling = "Filling",
  Settling = "Settling",
  Settled = "Settled",
}

export const AllSubscriptionOrderBookStates: SubscriptionOrderBookState[] = [
  SubscriptionOrderBookState.Pending,
  SubscriptionOrderBookState.Opening,
  SubscriptionOrderBookState.Open,
  SubscriptionOrderBookState.Closed,
  SubscriptionOrderBookState.RequestingCancellation,
  SubscriptionOrderBookState.Cancelling,
  SubscriptionOrderBookState.Cancelled,
  SubscriptionOrderBookState.PreparingSettlement,
  SubscriptionOrderBookState.Filling,
  SubscriptionOrderBookState.Settling,
  SubscriptionOrderBookState.Settled,
];

export class SubscriptionOrderBook {
  public ["@type"]: string = SubscriptionOrderBookTypeName;
  public id = "";
  public ownerID = "";
  public sourceAccountLedgerID = "";
  public instrumentID = "";
  public number = "";
  public token: Token = new Token();
  public exchangeNetwork: LedgerNetwork | "" = "";
  public unitPrice: Amount = new Amount();
  public unitPriceDescription = "";
  public flexibleUnitPrice: boolean = false;
  public state: SubscriptionOrderBookState | "" = "";
  public openDate: string = dayjs().format();
  public closeDate: string = dayjs().format();
  public settlementDate: string = dayjs().format();
  public fractionalisationAllowed = false;
  public subscriptionAmount: Amount = new Amount();
  public overSubscriptionAmount: Amount = new Amount();
  public minimumOrderAmount: Amount = new Amount();
  public marketingThresholdSubscriptionAmount: Amount = new Amount();
  public subscribedAmount: Amount = new Amount();
  public subscribedTokens: Amount = new Amount();
  public settlementAmount: Amount = new Amount();
  public settlementTokens: Amount = new Amount();
  public leftOverAmount: BigNumber = new BigNumber("0");
  public leftOverTokens: BigNumber = new BigNumber("0");
  public stateResolutionCount = 0;
  public underwritingIDs: string[] = [];
  public auditEntry: Entry = new Entry();

  constructor(l?: SubscriptionOrderBook) {
    if (!l) {
      return;
    }
    this.id = l.id;
    this.ownerID = l.ownerID;
    this.sourceAccountLedgerID = l.sourceAccountLedgerID;
    this.instrumentID = l.instrumentID;
    this.number = l.number;
    this.token = new Token(l.token);
    this.exchangeNetwork = l.exchangeNetwork;
    this.unitPrice = new Amount(l.unitPrice);
    this.unitPriceDescription = l.unitPriceDescription;
    this.flexibleUnitPrice = l.flexibleUnitPrice;
    this.state = l.state;
    this.openDate = l.openDate;
    this.closeDate = l.closeDate;
    this.settlementDate = l.settlementDate;
    this.fractionalisationAllowed = l.fractionalisationAllowed;
    this.subscriptionAmount = new Amount(l.subscriptionAmount);
    this.minimumOrderAmount = new Amount(l.minimumOrderAmount);
    this.overSubscriptionAmount = new Amount(l.overSubscriptionAmount);
    this.marketingThresholdSubscriptionAmount = new Amount(
      l.marketingThresholdSubscriptionAmount,
    );
    this.subscribedAmount = new Amount(l.subscribedAmount);
    this.subscribedTokens = new Amount(l.subscribedTokens);
    this.settlementAmount = new Amount(l.settlementTokens);
    this.settlementTokens = new Amount(l.settlementTokens);
    this.leftOverAmount = new BigNumber(l.leftOverAmount);
    this.leftOverTokens = new BigNumber(l.leftOverTokens);
    this.stateResolutionCount = l.stateResolutionCount;
    if (l.underwritingIDs) {
      this.underwritingIDs = [...l.underwritingIDs];
    }
    this.auditEntry = new Entry(l.auditEntry);
  }
}
