// source: banking/bankAccountUpdater.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
var banking_bankAccount_pb = require('../banking/bankAccount_pb.js');
goog.object.extend(proto, banking_bankAccount_pb);
goog.exportSymbol('proto.banking.UpdateBankAccountNumberRequest', null, global);
goog.exportSymbol('proto.banking.UpdateBankAccountNumberResponse', null, global);
goog.exportSymbol('proto.banking.UpdateBankAccountTypeRequest', null, global);
goog.exportSymbol('proto.banking.UpdateBankAccountTypeResponse', null, global);
goog.exportSymbol('proto.banking.UpdateBankAccountUnscopedRequest', null, global);
goog.exportSymbol('proto.banking.UpdateBankAccountUnscopedResponse', null, global);
goog.exportSymbol('proto.banking.UpdateBankNameRequest', null, global);
goog.exportSymbol('proto.banking.UpdateBankNameResponse', null, global);
goog.exportSymbol('proto.banking.UpdateBranchCodeRequest', null, global);
goog.exportSymbol('proto.banking.UpdateBranchCodeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankAccountTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankAccountTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankAccountTypeRequest.displayName = 'proto.banking.UpdateBankAccountTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankAccountTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankAccountTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankAccountTypeResponse.displayName = 'proto.banking.UpdateBankAccountTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankNameRequest.displayName = 'proto.banking.UpdateBankNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankNameResponse.displayName = 'proto.banking.UpdateBankNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankAccountNumberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankAccountNumberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankAccountNumberRequest.displayName = 'proto.banking.UpdateBankAccountNumberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankAccountNumberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankAccountNumberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankAccountNumberResponse.displayName = 'proto.banking.UpdateBankAccountNumberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBranchCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBranchCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBranchCodeRequest.displayName = 'proto.banking.UpdateBranchCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBranchCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBranchCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBranchCodeResponse.displayName = 'proto.banking.UpdateBranchCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankAccountUnscopedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankAccountUnscopedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankAccountUnscopedRequest.displayName = 'proto.banking.UpdateBankAccountUnscopedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.UpdateBankAccountUnscopedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.UpdateBankAccountUnscopedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.UpdateBankAccountUnscopedResponse.displayName = 'proto.banking.UpdateBankAccountUnscopedResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankAccountTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankAccountTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankAccountTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
bankaccountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
bankaccounttype: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankAccountTypeRequest}
 */
proto.banking.UpdateBankAccountTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankAccountTypeRequest;
  return proto.banking.UpdateBankAccountTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankAccountTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankAccountTypeRequest}
 */
proto.banking.UpdateBankAccountTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankaccountid(value);
      break;
    case 3:
      var value = /** @type {!proto.banking.BankAccountType} */ (reader.readEnum());
      msg.setBankaccounttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankAccountTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankAccountTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankAccountTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getBankaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankaccounttype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.UpdateBankAccountTypeRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.UpdateBankAccountTypeRequest} returns this
*/
proto.banking.UpdateBankAccountTypeRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountTypeRequest} returns this
 */
proto.banking.UpdateBankAccountTypeRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountTypeRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string bankAccountID = 2;
 * @return {string}
 */
proto.banking.UpdateBankAccountTypeRequest.prototype.getBankaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.UpdateBankAccountTypeRequest} returns this
 */
proto.banking.UpdateBankAccountTypeRequest.prototype.setBankaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BankAccountType bankAccountType = 3;
 * @return {!proto.banking.BankAccountType}
 */
proto.banking.UpdateBankAccountTypeRequest.prototype.getBankaccounttype = function() {
  return /** @type {!proto.banking.BankAccountType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.banking.BankAccountType} value
 * @return {!proto.banking.UpdateBankAccountTypeRequest} returns this
 */
proto.banking.UpdateBankAccountTypeRequest.prototype.setBankaccounttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankAccountTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankAccountTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankAccountTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankAccountTypeResponse}
 */
proto.banking.UpdateBankAccountTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankAccountTypeResponse;
  return proto.banking.UpdateBankAccountTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankAccountTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankAccountTypeResponse}
 */
proto.banking.UpdateBankAccountTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankAccountTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankAccountTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankAccountTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount BankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.UpdateBankAccountTypeResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.UpdateBankAccountTypeResponse} returns this
*/
proto.banking.UpdateBankAccountTypeResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountTypeResponse} returns this
 */
proto.banking.UpdateBankAccountTypeResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountTypeResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
bankaccountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
bankname: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankNameRequest}
 */
proto.banking.UpdateBankNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankNameRequest;
  return proto.banking.UpdateBankNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankNameRequest}
 */
proto.banking.UpdateBankNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankaccountid(value);
      break;
    case 3:
      var value = /** @type {!proto.banking.BankName} */ (reader.readEnum());
      msg.setBankname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getBankaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankname();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.UpdateBankNameRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.UpdateBankNameRequest} returns this
*/
proto.banking.UpdateBankNameRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankNameRequest} returns this
 */
proto.banking.UpdateBankNameRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankNameRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string bankAccountID = 2;
 * @return {string}
 */
proto.banking.UpdateBankNameRequest.prototype.getBankaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.UpdateBankNameRequest} returns this
 */
proto.banking.UpdateBankNameRequest.prototype.setBankaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BankName BankName = 3;
 * @return {!proto.banking.BankName}
 */
proto.banking.UpdateBankNameRequest.prototype.getBankname = function() {
  return /** @type {!proto.banking.BankName} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.banking.BankName} value
 * @return {!proto.banking.UpdateBankNameRequest} returns this
 */
proto.banking.UpdateBankNameRequest.prototype.setBankname = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankNameResponse}
 */
proto.banking.UpdateBankNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankNameResponse;
  return proto.banking.UpdateBankNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankNameResponse}
 */
proto.banking.UpdateBankNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount BankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.UpdateBankNameResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.UpdateBankNameResponse} returns this
*/
proto.banking.UpdateBankNameResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankNameResponse} returns this
 */
proto.banking.UpdateBankNameResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankNameResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankAccountNumberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankAccountNumberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankAccountNumberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountNumberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
bankaccountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
bankaccountnumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankAccountNumberRequest}
 */
proto.banking.UpdateBankAccountNumberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankAccountNumberRequest;
  return proto.banking.UpdateBankAccountNumberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankAccountNumberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankAccountNumberRequest}
 */
proto.banking.UpdateBankAccountNumberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankaccountid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankaccountnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankAccountNumberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankAccountNumberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankAccountNumberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountNumberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getBankaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankaccountnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.UpdateBankAccountNumberRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.UpdateBankAccountNumberRequest} returns this
*/
proto.banking.UpdateBankAccountNumberRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountNumberRequest} returns this
 */
proto.banking.UpdateBankAccountNumberRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountNumberRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string bankAccountID = 2;
 * @return {string}
 */
proto.banking.UpdateBankAccountNumberRequest.prototype.getBankaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.UpdateBankAccountNumberRequest} returns this
 */
proto.banking.UpdateBankAccountNumberRequest.prototype.setBankaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bankAccountNumber = 3;
 * @return {string}
 */
proto.banking.UpdateBankAccountNumberRequest.prototype.getBankaccountnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.UpdateBankAccountNumberRequest} returns this
 */
proto.banking.UpdateBankAccountNumberRequest.prototype.setBankaccountnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankAccountNumberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankAccountNumberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankAccountNumberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountNumberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankAccountNumberResponse}
 */
proto.banking.UpdateBankAccountNumberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankAccountNumberResponse;
  return proto.banking.UpdateBankAccountNumberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankAccountNumberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankAccountNumberResponse}
 */
proto.banking.UpdateBankAccountNumberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankAccountNumberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankAccountNumberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankAccountNumberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountNumberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount BankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.UpdateBankAccountNumberResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.UpdateBankAccountNumberResponse} returns this
*/
proto.banking.UpdateBankAccountNumberResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountNumberResponse} returns this
 */
proto.banking.UpdateBankAccountNumberResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountNumberResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBranchCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBranchCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBranchCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBranchCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
bankaccountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
branchcode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBranchCodeRequest}
 */
proto.banking.UpdateBranchCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBranchCodeRequest;
  return proto.banking.UpdateBranchCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBranchCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBranchCodeRequest}
 */
proto.banking.UpdateBranchCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankaccountid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBranchCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBranchCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBranchCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBranchCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getBankaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBranchcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.UpdateBranchCodeRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.UpdateBranchCodeRequest} returns this
*/
proto.banking.UpdateBranchCodeRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBranchCodeRequest} returns this
 */
proto.banking.UpdateBranchCodeRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBranchCodeRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string bankAccountID = 2;
 * @return {string}
 */
proto.banking.UpdateBranchCodeRequest.prototype.getBankaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.UpdateBranchCodeRequest} returns this
 */
proto.banking.UpdateBranchCodeRequest.prototype.setBankaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string branchCode = 3;
 * @return {string}
 */
proto.banking.UpdateBranchCodeRequest.prototype.getBranchcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.UpdateBranchCodeRequest} returns this
 */
proto.banking.UpdateBranchCodeRequest.prototype.setBranchcode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBranchCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBranchCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBranchCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBranchCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBranchCodeResponse}
 */
proto.banking.UpdateBranchCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBranchCodeResponse;
  return proto.banking.UpdateBranchCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBranchCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBranchCodeResponse}
 */
proto.banking.UpdateBranchCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBranchCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBranchCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBranchCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBranchCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount BankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.UpdateBranchCodeResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.UpdateBranchCodeResponse} returns this
*/
proto.banking.UpdateBranchCodeResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBranchCodeResponse} returns this
 */
proto.banking.UpdateBranchCodeResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBranchCodeResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankAccountUnscopedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankAccountUnscopedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountUnscopedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
bankaccountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankAccountUnscopedRequest}
 */
proto.banking.UpdateBankAccountUnscopedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankAccountUnscopedRequest;
  return proto.banking.UpdateBankAccountUnscopedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankAccountUnscopedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankAccountUnscopedRequest}
 */
proto.banking.UpdateBankAccountUnscopedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankaccountid(value);
      break;
    case 3:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankAccountUnscopedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankAccountUnscopedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountUnscopedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getBankaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.UpdateBankAccountUnscopedRequest} returns this
*/
proto.banking.UpdateBankAccountUnscopedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountUnscopedRequest} returns this
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string bankAccountID = 2;
 * @return {string}
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.getBankaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.UpdateBankAccountUnscopedRequest} returns this
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.setBankaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BankAccount BankAccount = 3;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 3));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.UpdateBankAccountUnscopedRequest} returns this
*/
proto.banking.UpdateBankAccountUnscopedRequest.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountUnscopedRequest} returns this
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountUnscopedRequest.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.UpdateBankAccountUnscopedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.UpdateBankAccountUnscopedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.UpdateBankAccountUnscopedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountUnscopedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankaccount: (f = msg.getBankaccount()) && banking_bankAccount_pb.BankAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.UpdateBankAccountUnscopedResponse}
 */
proto.banking.UpdateBankAccountUnscopedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.UpdateBankAccountUnscopedResponse;
  return proto.banking.UpdateBankAccountUnscopedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.UpdateBankAccountUnscopedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.UpdateBankAccountUnscopedResponse}
 */
proto.banking.UpdateBankAccountUnscopedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_bankAccount_pb.BankAccount;
      reader.readMessage(value,banking_bankAccount_pb.BankAccount.deserializeBinaryFromReader);
      msg.setBankaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.UpdateBankAccountUnscopedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.UpdateBankAccountUnscopedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.UpdateBankAccountUnscopedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.UpdateBankAccountUnscopedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_bankAccount_pb.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional BankAccount BankAccount = 1;
 * @return {?proto.banking.BankAccount}
 */
proto.banking.UpdateBankAccountUnscopedResponse.prototype.getBankaccount = function() {
  return /** @type{?proto.banking.BankAccount} */ (
    jspb.Message.getWrapperField(this, banking_bankAccount_pb.BankAccount, 1));
};


/**
 * @param {?proto.banking.BankAccount|undefined} value
 * @return {!proto.banking.UpdateBankAccountUnscopedResponse} returns this
*/
proto.banking.UpdateBankAccountUnscopedResponse.prototype.setBankaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.UpdateBankAccountUnscopedResponse} returns this
 */
proto.banking.UpdateBankAccountUnscopedResponse.prototype.clearBankaccount = function() {
  return this.setBankaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.UpdateBankAccountUnscopedResponse.prototype.hasBankaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.banking);
