import React from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { SignInCard } from "views/SignIn/SignInCard";
import { Helmet } from "react-helmet-async";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import background from "assets/images/background/background.png";
import { useTestURLQueryParamsHandler } from "hooks/useTestURLQueryParamsHandler";
import cx from "classnames";

export function SignIn() {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { Banner, NotificationBannerHeight } = useAppNoticeContext();
  useTestURLQueryParamsHandler();

  return (
    <>
      <Helmet>
        <title>Mesh | Sign in</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Sign in to your Mesh account" />
      </Helmet>
      {Banner}
      {!smDown ? (
        <Box
          className={cx({
            meshScroll: smDown,
          })}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            height: `calc(100vh - ${NotificationBannerHeight}px)`,
            overflowY: "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
              position: "fixed",
              top: NotificationBannerHeight,
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            sx={{
              height: "100%",
              display: "grid",
              gridTemplateRows: `
                "a" 10px
                "b" auto
                "c" 10px
              `,
              minWidth: "100vw",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                height: "100%",
                gridArea: "b",
                pb: 2,
                pt: 4,
              }}
            >
              <SignInCard />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <SignInCard />
        </>
      )}
    </>
  );
}
