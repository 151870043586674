import { Transaction } from "@mesh/common-js/dist/ledger/transaction_pb";
import {
  transactionFailureCodeToString,
  TransactionWrapper,
} from "@mesh/common-js/dist/ledger";
import { AnalyseFailedTransactionIDRequest } from "@mesh/common-js/dist/ledger/transactionFailureAnalyser_pb";
import { TransactionFailureReason } from "@mesh/common-js/dist/ledger/transactionFailureReason_pb";
import { Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { useIsMounted } from "hooks";
import React, { useEffect, useState } from "react";

export type TransactionFailureReasonsCardProps = {
  transaction: Transaction;
};

export const TransactionFailureReasonsCard = (
  props: TransactionFailureReasonsCardProps,
) => {
  const {
    ledger: { transactionFailureAnalyser },
  } = useAPIContext();
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [failureReasons, setFailureReasons] = useState<
    TransactionFailureReason[]
  >([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const failureReasons = (
          await transactionFailureAnalyser.analyseFailedTransactionID(
            new AnalyseFailedTransactionIDRequest()
              .setContext(authContext.toFuture())
              .setTransactionid(new TransactionWrapper(props.transaction).id),
          )
        ).getFailurereasonsList();
        if (isMounted()) {
          setFailureReasons(failureReasons);
        }
      } catch (e) {
        if (isMounted()) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error getting transaction failure reasons: ${
              err.message ? err.message : err.toString()
            }`,
          );
          setError(err.message ? err.message : err.toString());
        }
      }
      if (isMounted()) {
        setLoading(false);
      }
    })();
  }, [props.transaction]);

  if (loading) {
    return (
      <Card style={{ backgroundColor: "#1d1f21" }}>
        <CardContent>
          <CircularProgress size={50} />
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card style={{ backgroundColor: "#1d1f21" }}>
        <CardContent>
          <Typography color={"error"} children={error} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card style={{ backgroundColor: "#1d1f21" }}>
      <CardContent>
        <ul>
          {failureReasons.map((fr, idx) => (
            <li
              key={idx}
            >{`Code: '${fr.getCode()} - ${transactionFailureCodeToString(fr.getCode())}'. Description: '${fr.getDescription()}'`}</li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};
