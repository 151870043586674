import React, { useEffect, useState } from "react";
import { useAPIContext } from "context/API";
import { SearchPreApprovalRegistryRequest } from "@mesh/common-js/dist/user/preApprovalRegistry_pb";
import { Box, Button, Typography } from "@mui/material";
import { useErrorContext } from "context/Error";
import { PreApproval as PreApprovalType } from "@mesh/common-js/dist/user/preApproval_pb";
import { useApplicationContext } from "context/Application/Application";
import { UploadCSV } from "./components/UploadCSV";
import { Query } from "@mesh/common-js/dist/search/query_pb";

// PreApproval is Temporary - to be removed after aTrade Farming (Ticket created)
export const PreApproval = () => {
  const { authContext } = useApplicationContext();
  const { user } = useAPIContext();
  const { errorContextDefaultErrorFeedback, errorContextErrorTranslator } =
    useErrorContext();
  const [, setLoading] = useState<boolean>(false);
  const [, setRegistry] = useState<PreApprovalType[]>();
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    const deb = setTimeout(async () => {
      try {
        setLoading(true);
        const r = await user.preApprovalRegistry.searchPreApprovalRegistry(
          new SearchPreApprovalRegistryRequest()
            .setContext(authContext.toFuture())
            .setQuery(new Query().setLimit(100)),
        );

        setLoading(false);
        setRegistry(r.getPreapprovalList());
      } catch (e) {
        const error = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(error);
        setLoading(false);
        console.error(e);
      }
    });

    return () => clearTimeout(deb);
  }, []);

  return (
    <>
      <UploadCSV open={dialog} close={() => setDialog(false)} />
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            px: 3,
            py: 1,
          }}
        >
          <Typography variant="h4">Pre Approvals</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDialog(!dialog)}
          >
            Upload CSV
          </Button>
        </Box>
      </Box>
    </>
  );
};
