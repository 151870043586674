import React from "react";
import { styled } from "@mui/material/styles";
import { Chip, ChipProps, Theme } from "@mui/material";
import { DefundOrderState } from "@mesh/common-js/src/banking/defundOrderState_pb";
import { defundOrderStateToString } from "@mesh/common-js/dist/banking/defundOrderState";

const Root = styled("div")(({ theme }) => ({
  [`& .info`]: { backgroundColor: theme.palette.info.main },
  [`& .warning`]: { backgroundColor: theme.palette.warning.main },
  [`& .error`]: { backgroundColor: theme.palette.error.main },
  [`& .success`]: { backgroundColor: theme.palette.success.main },
}));

export interface DefundOrderStateChipProps {
  state: DefundOrderState | "";
  chipProps?: ChipProps;
}

export function DefundOrderStateChip(props: DefundOrderStateChipProps) {
  switch (props.state) {
    case DefundOrderState.PENDING_DEFUND_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id="defundOrderStateChip-pending-chip"
          size="small"
          label={defundOrderStateToString(props.state)}
        />
      );

    case DefundOrderState.UNDER_INVESTIGATION_DEFUND_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id="defundOrderStateChip-awaitingConfirmation-chip"
          size="small"
          label={defundOrderStateToString(props.state)}
        />
      );

    case DefundOrderState.AWAITING_SETTLEMENT_DEFUND_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id="defundOrderStateChip-accepted-chip"
          size="small"
          label={defundOrderStateToString(props.state)}
        />
      );

    case DefundOrderState.SETTLEMENT_IN_PROGRESS_DEFUND_ORDER_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id="defundOrderStateChip-settled-chip"
          size="small"
          label={defundOrderStateToString(props.state)}
        />
      );

    case DefundOrderState.SETTLED_DEFUND_ORDER_STATE:
      return (
        <Chip
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id="defundOrderStateChip-cancelling-chip"
          size="small"
          label={defundOrderStateToString(props.state)}
        />
      );

    case DefundOrderState.CANCELLED_DEFUND_ORDER_STATE:
      return (
        <Chip
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id="defundOrderStateChip-cancelled-chip"
          size="small"
          label={defundOrderStateToString(props.state)}
        />
      );

    default:
      return <Root>{props.state}</Root>;
  }
}
