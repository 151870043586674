import { useEffect, useState } from "react";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { useAccountContext } from "context/Account/Account";
import { LedgerAccountCategory } from "james/ledger";
import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";

export const usePotentialSourceAccount = () => {
  const { errorContextErrorTranslator } = useErrorContext();
  const [potentialSourceAccounts, setPotentialSourceAccounts] = useState<
    StellarAccountViewModel[]
  >([]);
  const { stellarAccountContext } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const [
    initialAccountLoadedSuccessfully,
    setInitialAccountLoadedSuccessfully,
  ] = useState(false);
  const [potentialSourceAccountsLoaded, setPotentialSourceAccountsLoaded] =
    useState(false);
  const stellarAccountContextError = stellarAccountContext.error;
  const stellarAccountContextLoading = stellarAccountContext.loading;
  const stellarAccountContextKeys = stellarAccountContext.keys;

  useEffect(() => {
    if (stellarAccountContextLoading) {
      return;
    }
    if (!initialAccountLoadedSuccessfully) {
      return;
    }
    setPotentialSourceAccountsLoaded(false);
    try {
      (async () => {
        const retrievedPotentialSourceAccounts: StellarAccountViewModel[] =
          stellarAccountContext.accounts.filter(
            (val) => val.getLabel() !== LedgerAccountCategory.Issuance,
          );

        if (!retrievedPotentialSourceAccounts.length) {
          console.error("expected at least 1 potential source account, got 0");
          throw new Error(
            "expected at least 1 potential source account, got 0",
          );
        }

        setPotentialSourceAccounts(retrievedPotentialSourceAccounts);

        setPotentialSourceAccountsLoaded(true);
      })();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error fetching required data: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `error fetching required data: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
  }, [initialAccountLoadedSuccessfully]);

  return {
    potentialSourceAccounts,
    potentialSourceAccountsLoaded,
    stellarAccountContextKeys,
    initialAccountLoadedSuccessfully,
    stellarAccountContextLoading,
    stellarAccountContextError,
    setInitialAccountLoadedSuccessfully,
  };
};
