import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useApplicationContext } from "context/Application/Application";
import { useAPIContext } from "context/API";
import { AddPreApprovalListRequest } from "@mesh/common-js/dist/user/preApprovalRegistry_pb";
import { PreApproval as PreApprovalType } from "@mesh/common-js/dist/user/preApproval_pb";
import { useErrorContext } from "context/Error";
import { Close } from "@mui/icons-material";
import { range } from "lodash";
import { useSnackbar } from "notistack";

export interface UploadCSVProps {
  open: boolean;
  close: () => void;
}

// UploadCSV is Temporary - to be removed after aTrade Farming (Ticket created)
export const UploadCSV = ({ open, close }: UploadCSVProps) => {
  const { authContext } = useApplicationContext();
  const { user } = useAPIContext();
  const { errorContextDefaultErrorFeedback, errorContextErrorTranslator } =
    useErrorContext();
  const [mappedValues, setMappedValues] = useState<PreApprovalType[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      const generator = processCSV(file);

      const allValues: PreApprovalType[] = [];
      let headers: string[] = [];
      let firstLine = true;

      for await (const row of generator) {
        if (firstLine) {
          headers = row;
          firstLine = false;
          continue;
        }

        const keyIndex = headers.indexOf("email");
        if (keyIndex !== -1) {
          allValues.push(
            new PreApprovalType()
              .setUseremail(row[keyIndex])
              .setApprovingcompanyname("a-trade"),
          );
        }
      }

      setMappedValues(allValues);
    },
    [],
  );

  const AddEmails = useCallback(async () => {
    const chunkSize = 200;
    const idx = Math.floor(mappedValues.length / chunkSize);
    setLoading(true);
    try {
      await Promise.all([
        range(idx).map((i) => {
          user.preApprovalRegistry.addPreApprovalList(
            new AddPreApprovalListRequest()
              .setContext(authContext.toFuture())
              .setPreapprovalList(
                mappedValues.slice(i * chunkSize, i * chunkSize + chunkSize),
              ),
          );
        }),
      ]);
      setLoading(false);
      enqueueSnackbar("Upload complete", { variant: "success" });
      setMappedValues([]);
    } catch (e) {
      setLoading(false);
      const error = errorContextErrorTranslator.translateError(e);
      errorContextDefaultErrorFeedback(error);
      console.error(e);
    }
  }, [mappedValues]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography>Upload CSV</Typography>
        {loading ? (
          <CircularProgress size={16} />
        ) : (
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        className="meshScroll"
        sx={(theme) => ({
          overflowY: "auto",
          overflowX: "hidden",
          height: 400,
          width: 500,
          pb: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        {mappedValues.length ? (
          <Box
            sx={{
              px: 1,
              pb: 2,
              mb: 1,
              mt: 2,
              width: 480,
            }}
          >
            <Box>
              {mappedValues.map((value, index) => (
                <Typography key={index}>{value.getUseremail()}</Typography>
              ))}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              mt: 3,
              px: 2,
            }}
          >
            <input type="file" accept=".csv" onChange={handleFileChange} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {mappedValues.length > 0 && (
          <Button onClick={() => setMappedValues([])} disabled={loading}>
            Clear Data
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => AddEmails()}
          disabled={loading || mappedValues.length === 0}
        >
          UploadCSV
        </Button>
      </DialogActions>
    </Dialog>
  );
};

async function* processCSV(file: File) {
  const reader = file.stream().getReader();
  const decoder = new TextDecoder("utf-8");
  let partialLine = "";

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    const chunk = decoder.decode(value, { stream: true });
    const lines = (partialLine + chunk).split("\n");

    partialLine = lines.pop() || "";

    for (const line of lines) {
      const values = line.split(","); // Adjust for actual delimiter
      yield values;
    }
  }

  if (partialLine) yield partialLine.split(",");
}
