import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextFieldProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BPTable } from "components/Table";
import { Amount as LedgerAmount, LedgerAccountCategory } from "james/ledger";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
  Receipt,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { DateField, TextField } from "components/FormFields";
import { formatTextNum } from "utilities/number";
import { useSnackbar } from "notistack";
import { dateIsValid } from "utilities/date/dateIsValid";
import { DefundOrderStateChip } from "./DefundingOrderStateChips/Chips";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { TransactionTable } from "components/Ledger/Transaction";
import { DefundOrderState } from "@mesh/common-js/dist/banking/defundOrderState_pb";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Sorting, SortingOrder } from "@mesh/common-js/src/search/sorting_pb";
import { RangeValue } from "@mesh/common-js/src/search/dateRangeCriterion_pb";
import { Query as PastQuery } from "james/search/query";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import {
  Criterion,
  ORCriterion,
} from "@mesh/common-js/src/search/criterion_pb";
import { Model } from "@mesh/common-js/src/views/bankingDefundOrderView/model_pb";
import { useAPIContext } from "context/API";
import { useIsMounted } from "hooks";
import {
  ReadManyRequest,
  ReadManyResponse,
} from "@mesh/common-js/src/views/bankingDefundOrderView/reader_pb";
import { AllDefundOrderStates } from "james/banking/defundOrder";
import {
  defundOrderStateToString,
  stringToDefundOrderState,
} from "@mesh/common-js/dist/banking/defundOrderState";
import { useDeFundingContext } from "./Context";
import {
  newTextSubstringCriterion,
  newUint32ListCriterion,
  newTextExactCriterion,
} from "@mesh/common-js/dist/search";
import { friendlyName } from "james/banking/bankAccount";
import { DefundOrder } from "@mesh/common-js/src/banking/defundOrder_pb";

const PREFIX = "Defunding";

const classes = {
  dialogTitleContent: `${PREFIX}-dialogTitleContent`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  textSearchField: `${PREFIX}-textSearchField`,
  dateFilterField: `${PREFIX}-dateFilterField`,
  iconButton: `${PREFIX}-iconButton`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
};

const initialQuery = new Query()
  .setOffset(0)
  .setLimit(15)
  .setSortingList([
    new Sorting()
      .setField("creationdate")
      .setOrder(SortingOrder.DESC_SORTING_ORDER),
  ]);

export function Defunding() {
  const { authContext } = useApplicationContext();
  const {
    banking: { defundOrderViewReader },
  } = useAPIContext();
  const isMounted = useIsMounted();
  const { errorContextDefaultWarningFeedback } = useErrorContext();

  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedDefundOrderViewModel, setSelectedDefundOrderViewModel] =
    useState<Model | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const [queryChanged, setQueryChanged] = useState(false);
  const [readManyRequest, setReadManyRequest] = useState<ReadManyRequest>(
    new ReadManyRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
  );
  const [readManyResponse, setReadManyResponse] = useState<ReadManyResponse>(
    new ReadManyResponse(),
  );
  const [dateCriteriaSet, setDateCriteriaSet] = useState(false);
  const [dateTimeCriterionFrom, setDateTimeCriterionFrom] =
    useState<RangeValue>(new RangeValue());
  const [dateTimeCriterionTo, setDateTimeCriterionTo] = useState<RangeValue>(
    new RangeValue(),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [orderStatusesCriterion, setOrderStatusesCriterion] = useState<
    DefundOrderState[]
  >([]);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      try {
        const response = await defundOrderViewReader.readMany(readManyRequest);

        if (isMounted()) {
          setReadManyResponse(response);
        }
      } catch (e) {
        console.error("error reading funding orders", e);
        errorContextDefaultWarningFeedback(e, "error reading funding orders");
      }
      setLoading(false);
      setQueryChanged(false);
    }, 600);
  }, [readManyRequest, isMounted, queryChanged]);

  useEffect(() => {
    if (textSearchCriterionTextField) {
      for (let i = 0; i < readManyRequest.getCriteriaList().length; i++) {
        if (
          readManyRequest
            .getCriteriaList()
            [i].getOrcriterion()
            ?.getCriteriaList()
            ?.some(
              (criterion) =>
                criterion.getTextsubstringcriterion()?.getField() === "number",
            )
        ) {
          readManyRequest.getCriteriaList().splice(i, 1);
        }
      }
      readManyRequest.setCriteriaList([
        ...readManyRequest.getCriteriaList(),
        new Criterion().setOrcriterion(
          new ORCriterion().setCriteriaList([
            newTextSubstringCriterion("number", textSearchCriterionTextField),
            newTextSubstringCriterion(
              "bankreference",
              textSearchCriterionTextField,
            ),
            newTextSubstringCriterion(
              "`accountnumber`",
              textSearchCriterionTextField,
            ),
          ]),
        ),
      ]);
    }

    if (orderStatusesCriterion.length) {
      for (let i = 0; i < readManyRequest.getCriteriaList().length; i++) {
        if (
          readManyRequest
            .getCriteriaList()
            [i].getUint32listcriterion()
            ?.getField() === "state"
        ) {
          readManyRequest.getCriteriaList().splice(i, 1);
        }
      }
      readManyRequest.setCriteriaList([
        ...readManyRequest.getCriteriaList(),
        newUint32ListCriterion("state", orderStatusesCriterion),
      ]);
    }

    if (
      !textSearchCriterionTextField &&
      readManyRequest.getCriteriaList().length
    ) {
      for (let i = 0; i < readManyRequest.getCriteriaList().length; i++) {
        if (
          readManyRequest
            .getCriteriaList()
            [i].getOrcriterion()
            ?.getCriteriaList()
            ?.some(
              (criterion) =>
                criterion.getTextsubstringcriterion()?.getField() ===
                "accountnumber",
            )
        ) {
          readManyRequest.getCriteriaList().splice(i, 1);
        }
      }
    }

    if (
      !orderStatusesCriterion.length &&
      readManyRequest.getCriteriaList().length
    ) {
      for (let i = 0; i < readManyRequest.getCriteriaList().length; i++) {
        if (
          readManyRequest
            .getCriteriaList()
            [i].getUint32listcriterion()
            ?.getField() === "state"
        ) {
          readManyRequest.getCriteriaList().splice(i, 1);
        }
      }
    }

    if (
      !(
        dateTimeCriterionTo.getInclusive() ||
        dateTimeCriterionFrom.getInclusive()
      ) &&
      readManyRequest.getCriteriaList().length
    ) {
      for (let i = 0; i < readManyRequest.getCriteriaList().length; i++) {
        if (
          readManyRequest
            .getCriteriaList()
            [i].getDaterangecriterion()
            ?.getField() === "valuedate"
        ) {
          readManyRequest.getCriteriaList().splice(i, 1);
        }
      }
    }

    setReadManyRequest(
      readManyRequest.setContext(authContext.toFuture()).setQuery(initialQuery),
    );
    setQueryChanged(true);
  }, [
    orderStatusesCriterion,
    readManyRequest.getContext(),
    readManyRequest.getCriteriaList(),
    authContext,
    dateTimeCriterionFrom,
    dateTimeCriterionTo,
    dateCriteriaSet,
    textSearchCriterionTextField,
  ]);

  return (
    <>
      <BPTable
        // loading={loading || apiCallInProgress}
        loading={loading}
        height={window.innerHeight - 138 - noticeBannerHeight}
        title="Defunding Orders"
        data={readManyResponse.getModelsList()}
        singleSelect
        onSingleSelectChange={(data) =>
          setSelectedDefundOrderViewModel(data as Model)
        }
        columns={[
          {
            label: "Number",
            field: "number",
            accessor: (data) => {
              const model = data as Model;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {model.getNumber()}
                </Typography>
              );
            },
          },
          {
            label: "Client Name",
            field: "clientname",
            accessor: (data) => {
              const model = data as Model;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {model.getClientname()}
                </Typography>
              );
            },
            sortable: false,
          },
          {
            label: "Account Number",
            field: "accountnumber",
            sortable: false,
            accessor: (data) => {
              const model = data as Model;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {model.getAccountnumber()}
                </Typography>
              );
            },
          },
          {
            label: "Bank Ref",
            field: "bankreference",
            accessor: (data) => {
              const model = data as Model;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {model.getBankreference()}
                </Typography>
              );
            },
          },
          {
            label: "Fee",
            field: "fee",
            sortable: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data) => {
              const feeAmount = LedgerAmount.fromFutureAmount(
                (data as Model).getFeeamount(),
              );
              return formatTextNum(feeAmount.value, {
                addDecimalPadding: true,
              });
            },
          },
          {
            label: "VAT Amount",
            field: "vatrate",
            sortable: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data) => {
              const model = data as Model;
              const feeAmount = LedgerAmount.fromFutureAmount(
                model.getFeeamount(),
              );
              return formatTextNum(
                feeAmount.value.multipliedBy(
                  parseFloat(model.getVatrate()?.getValue() ?? ""),
                ),
                {
                  addDecimalPadding: true,
                },
              );
            },
          },
          {
            label: "Amount",
            field: "amount.value.float",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data) => {
              const amount = LedgerAmount.fromFutureAmount(
                (data as Model).getAmount(),
              );
              return formatTextNum(amount.value, {
                addDecimalPadding: true,
              });
            },
          },
          {
            label: "State",
            field: "state",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => (
              <DefundOrderStateChip state={(data as Model).getState()} />
            ),
          },
          {
            label: "Express",
            field: "express",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              if ((data as Model).getExpress()) {
                return (
                  <Chip
                    id="expressDefundOrderChip-yes-chip"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.success.main,
                    })}
                    size="small"
                    label={"Yes"}
                  />
                );
              }
              return (
                <Chip
                  sx={(theme) => ({
                    backgroundColor: theme.palette.warning.main,
                  })}
                  id="expressDefundOrderChip-no-chip"
                  size="small"
                  label={"No"}
                />
              );
            },
          },
          {
            label: "Creation Date",
            field: "creationdate",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              const model = data as DefundOrder;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {protobufTimestampToDayjs(
                    model.getCreationdate() ?? new Timestamp(),
                  )
                    .local()
                    .format("YYYY/MM/DD HH:mm")}
                </Typography>
              );
            },
          },
        ]}
        query={PastQuery.fromFutureQuery(readManyRequest.getQuery())}
        onQueryChange={(query) => {
          setQueryChanged(true);
          setReadManyRequest(readManyRequest.setQuery(query.toFutureQuery()));
        }}
        totalNoRecords={readManyResponse.getTotal()}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          if (
            selectedDefundOrderViewModel &&
            selectedDefundOrderViewModel.getState() ===
              DefundOrderState.AWAITING_SETTLEMENT_DEFUND_ORDER_STATE
          ) {
            controls.push(
              <Tooltip title="Process defund">
                <span>
                  <IconButton
                    onClick={() => setDisplayDialog(true)}
                    id="defund-processDefund-button"
                    disabled={loading}
                    size="small"
                  >
                    <Receipt />
                  </IconButton>
                </span>
              </Tooltip>,
            );
          }

          controls.push(
            <Tooltip title="Refresh">
              <span>
                <IconButton
                  onClick={() => {
                    setQueryChanged(true);
                    setReadManyRequest(readManyRequest.setQuery(initialQuery));
                  }}
                  id="defund-refresh-button"
                  disabled={loading}
                  size="small"
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );

          return controls;
        })()}
        filters={[
          <TextField
            id="defundOrdersTable-textFilter-textField"
            variant="outlined"
            margin="dense"
            className={classes.textSearchField}
            label="Search Number or Bank Reference"
            placeholder="Start Typing..."
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterionTextField ? (
                <InputAdornment
                  position="end"
                  children={
                    <IconButton
                      size="small"
                      onClick={() => setTextSearchCriterionTextField("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterionTextField}
            onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
          />,
          <DateField
            label="From"
            id="defundOrdersTable-dateRangeFromFilter-dateField"
            className={classes.dateFilterField}
            value={
              dateTimeCriterionFrom.getDate()
                ? protobufTimestampToDayjs(
                    dateTimeCriterionFrom?.getDate() ?? new Timestamp(),
                  ).format("MM/DD/YYYY")
                : ""
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionFrom(new RangeValue());
                setDateCriteriaSet(true);
              } else {
                setDateTimeCriterionFrom(
                  dateTimeCriterionFrom
                    .setDate(dayjsToProtobufTimestamp(newValue))
                    .setInclusive(true)
                    .setIgnore(false),
                );
                setDateCriteriaSet(true);
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionFrom && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                            onClick={() =>
                              setDateTimeCriterionFrom(new RangeValue())
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
          <DateField
            label="To"
            id="defundOrdersTable-dateRangeToFilter-dateField"
            className={classes.dateFilterField}
            value={
              dateTimeCriterionTo.getDate()
                ? protobufTimestampToDayjs(
                    dateTimeCriterionTo?.getDate() ?? new Timestamp(),
                  ).format("MM/DD/YYYY")
                : ""
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setDateTimeCriterionTo(new RangeValue());
                setDateCriteriaSet(true);
              } else {
                setDateTimeCriterionTo(
                  dateTimeCriterionTo
                    .setDate(dayjsToProtobufTimestamp(newValue))
                    .setInclusive(true)
                    .setIgnore(false),
                );
                setDateCriteriaSet(true);
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {dateTimeCriterionTo && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                            onClick={() => {
                              setDateTimeCriterionTo(new RangeValue());
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id="defundOrdersTable-stateFilter-autocomplete"
            disabled={loading}
            multiple
            options={AllDefundOrderStates}
            filterSelectedOptions
            onChange={(_, value: string[]) =>
              setOrderStatusesCriterion(
                value.map((s) => stringToDefundOrderState(s)),
              )
            }
            value={orderStatusesCriterion.map((s) =>
              defundOrderStateToString(s),
            )}
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderTags={(defundOrderStates: string[]) =>
              defundOrderStates.map((s, idx) => (
                <Box sx={{ padding: "4px" }}>
                  <DefundOrderStateChip
                    key={idx}
                    chipProps={{
                      onDelete: () =>
                        setOrderStatusesCriterion((prev) =>
                          prev.filter(
                            (prevState) =>
                              prevState !== stringToDefundOrderState(s),
                          ),
                        ),
                      deleteIcon: (
                        <CancelIcon
                          sx={(theme) => ({
                            color: `${theme.palette.text.secondary} !important`,
                            "&:hover": {
                              color: `${theme.palette.secondary.contrastText} !important`,
                            },
                          })}
                        />
                      ),
                    }}
                    state={stringToDefundOrderState(s)}
                  />
                </Box>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: 317 }}
                className={classes.statusSelectFilterField}
                label="State"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={
                  orderStatusesCriterion.length ? undefined : "Select..."
                }
              />
            )}
          />,
        ]}
        expandRowComponent={{
          maxHeight: 300,
          component: (selectedRowData) => {
            const viewModel = selectedRowData as Model;
            return (
              <TransactionTable
                height={400}
                title={`Defund Order No ${viewModel.getNumber()}`}
                constantCriteria={[
                  newTextExactCriterion(
                    "metadata.defundOrderID",
                    viewModel.getId(),
                  ),
                ]}
              />
            );
          },
        }}
      />
      {displayDialog && (
        <ProcessDefundRequest
          onClose={() => {
            setDisplayDialog(false);
            setReadManyRequest(readManyRequest.setQuery(initialQuery));
          }}
          open={displayDialog}
          model={selectedDefundOrderViewModel as Model}
        />
      )}
    </>
  );
}

interface ProcessDefundRequestProps {
  open: boolean;
  onClose: () => void;
  model: Model;
}

function ProcessDefundRequest(props: ProcessDefundRequestProps) {
  const theme = useTheme();
  const { errorContextErrorTranslator } = useErrorContext();
  const { settleDeFundOrder } = useDeFundingContext();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [bankReference, setBankReferemce] = useState("");
  const [validationState, setValidationState] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>({});
  const [isUserASignatoryOnAccount, setIsUserSignatoryOnAccount] =
    useState(false);
  const { stellarAccountContext } = useAccountContext();

  // determine if user is signatory on market infrastructure account
  useEffect(() => {
    (async () => {
      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialisation error::${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialisation Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        // close the dialog
        props.onClose();
      }

      // search for the market infrastructure account
      const marketInfrastructureAccount = stellarAccountContext.accounts.find(
        (val) => val.getLabel() === LedgerAccountCategory.MarketInfrastructure,
      );

      // if the market infrastructure account is not found close the dialog
      if (!marketInfrastructureAccount) {
        enqueueSnackbar(
          "Unexpected Error Finding Market Infrastructure Account",
          {
            variant: "error",
          },
        );
        props.onClose();
        return;
      }

      try {
        setIsUserSignatoryOnAccount(
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(marketInfrastructureAccount.getLedgerid()),
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on destination account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }

      setLoading(false);
    })();
  }, [enqueueSnackbar, stellarAccountContext.loading]);

  const performValidations = () => {
    const newValidationState: { [key: string]: string | undefined } = {};

    if (!bankReference) {
      newValidationState.bankReference = "Cannot be blank";
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  const handleProcessDefundOrder = async () => {
    if (!performValidations()) {
      return;
    }

    setLoading(true);
    try {
      await settleDeFundOrder(props.model.getId(), bankReference);
      enqueueSnackbar("Defund Order Settlement In Progress", {
        variant: "success",
      });

      props.onClose();
      return;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`unable to settle defund order`, e);
      enqueueSnackbar(`unable to settle defund order: ${err.message}`, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>
        <Grid
          className={classes.dialogTitleContent}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item className={classes.dialogTitle}>
            <Typography variant="h5">Defund Order</Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={loading}
              id="processdefundDialog-close-button"
              onClick={props.onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ width: "570px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            fullWidth
            id="processdefundDialog-client-textField"
            label="Client"
            value={props.model.getClientname()}
            readOnly
          />
          <TextField
            style={{ width: "180px" }}
            id="processdefundDialog-asset-textField"
            label="Asset"
            value="mZAR"
            readOnly
          />
          <TextField
            fullWidth
            id="processdefundDialog-amount-textField"
            label="Amount"
            value={LedgerAmount.fromFutureAmount(
              props.model.getAmount(),
            ).value.toString()}
            readOnly
          />
        </div>
        <Divider />
        <Typography
          style={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}
          variant="h5"
          children="Pay To"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            fullWidth
            id="processdefundDialog-bankName-textField"
            label="Bank"
            value={friendlyName(props.model.getBankaccount()?.getBankname())}
            readOnly
          />
          <TextField
            fullWidth
            id="processdefundDialog-bankcode-textField"
            label="Branch"
            value={props.model.getBankaccount()?.getBranchcode()}
            readOnly
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            fullWidth
            id="processdefundDialog-bankAccountNumber-textField"
            label="Linked Account Number"
            value={props.model.getBankaccount()?.getNumber()}
            readOnly
          />
          <TextField
            fullWidth
            id="processdefundDialog-accountNumber-textField"
            label="Reference"
            value={props.model.getAccountnumber()}
            readOnly
          />
        </div>
        <Divider style={{ marginBottom: theme.spacing(2) }} />
        <TextField
          id="processdefundDialog-bankReference-textField"
          style={{ marginBottom: theme.spacing(4) }}
          fullWidth
          disabled={loading}
          value={bankReference}
          helperText={validationState.bankReference}
          error={!!validationState.bankReference}
          onChange={(e) => {
            handleClearValidation("bankReference");
            setBankReferemce(e.target.value);
          }}
          label="Bank Reference"
        />
        <div style={{ display: "flex" }}>
          <Tooltip
            placement="top"
            title={
              isUserASignatoryOnAccount
                ? ""
                : "user is not a signatory on market infrastructure account"
            }
          >
            <span>
              <Button
                id="processdefundDialog-processRequest-button"
                variant="contained"
                color="secondary"
                disabled={
                  loading ||
                  props.model.getState() ===
                    DefundOrderState.SETTLED_DEFUND_ORDER_STATE ||
                  !isUserASignatoryOnAccount
                }
                onClick={handleProcessDefundOrder}
                style={{ marginRight: theme.spacing(1) }}
              >
                Process Request
              </Button>
            </span>
          </Tooltip>
          {loading && <CircularProgress size={25} />}
        </div>
      </DialogContent>
    </Dialog>
  );
}
