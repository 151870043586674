import {
  AssetDocumentsDataSheetSection,
  AssetDocumentsDataSheetSectionTypeName,
} from "./AssetDocumentsDataSheetSection";
import {
  AssetInvestmentObjectiveDataSheetSection,
  AssetInvestmentObjectiveDataSheetSectionTypeName,
} from "./AssetInvestmentObjectiveDataSheetSection";
import {
  AssetPriceHistoryDataSheetSection,
  AssetPriceHistoryDataSheetSectionTypeName,
} from "./AssetPriceHistoryDataSheetSection";
import {
  AssetPerformanceDataSheetSection,
  AssetPerformanceDataSheetSectionTypeName,
} from "./AssetPerformanceDataSheetSection";
import {
  StellarAssetPublicInfoDataSheetSection,
  StellarAssetPublicInfoDataSheetSectionTypeName,
} from "./StellarAssetPublicInfoDataSheetSection";
import {
  AssetSectorAllocationsAndHoldingsDataSheetSection,
  AssetSectorAllocationsAndHoldingsDataSheetSectionTypeName,
} from "./AssetSectorAllocationsAndHoldingsDataSheetSection";
import {
  ETNRollCorporateActionsDataSheetSection,
  ETNRollCorporateActionsDataSheetSectionTypeName,
} from "./ETNRollCorporateActionsDataSheetSection";
import {
  AssetIndependentReviewDataSheetSection,
  AssetIndependentReviewDataSheetSectionTypeName,
} from "./AssetIndependentReviewDataSheetSection";
import {
  AssetSubscriptionOverviewDataSheetSection,
  AssetSubscriptionOverviewDataSheetSectionTypeName,
} from "./AssetSubscriptionOverviewDataSheetSection";
import {
  AssetMarketingMediaDataSheetSection,
  AssetMarketingMediaDataSheetSectionTypeName,
} from "./AssetMarketingMedia";
import {
  AssetRepaymentTermsDataSheetSection,
  AssetRepaymentTermsDataSheetSectionTypeName,
} from "./AssetRepaymentTermsDataSheetSection";

export interface DataSheetSection {
  ["@type"]: string;

  DataSheetSectionName(): string;
}

export function NewDataSheetSection(
  dataSheetSection: DataSheetSection,
): DataSheetSection {
  switch (dataSheetSection["@type"]) {
    case AssetMarketingMediaDataSheetSectionTypeName:
      return new AssetMarketingMediaDataSheetSection(
        dataSheetSection as AssetMarketingMediaDataSheetSection,
      );
    case AssetRepaymentTermsDataSheetSectionTypeName:
      return new AssetRepaymentTermsDataSheetSection(
        dataSheetSection as AssetRepaymentTermsDataSheetSection,
      );
    case AssetSubscriptionOverviewDataSheetSectionTypeName:
      return new AssetSubscriptionOverviewDataSheetSection(
        dataSheetSection as AssetSubscriptionOverviewDataSheetSection,
      );
    case AssetIndependentReviewDataSheetSectionTypeName:
      return new AssetIndependentReviewDataSheetSection(
        dataSheetSection as AssetIndependentReviewDataSheetSection,
      );
    case AssetDocumentsDataSheetSectionTypeName:
      return new AssetDocumentsDataSheetSection(
        dataSheetSection as AssetDocumentsDataSheetSection,
      );
    case AssetInvestmentObjectiveDataSheetSectionTypeName:
      return new AssetInvestmentObjectiveDataSheetSection(
        dataSheetSection as AssetInvestmentObjectiveDataSheetSection,
      );
    case AssetPriceHistoryDataSheetSectionTypeName:
      return new AssetPriceHistoryDataSheetSection(
        dataSheetSection as AssetPriceHistoryDataSheetSection,
      );
    case AssetPerformanceDataSheetSectionTypeName:
      return new AssetPerformanceDataSheetSection(
        dataSheetSection as AssetPerformanceDataSheetSection,
      );
    case AssetSectorAllocationsAndHoldingsDataSheetSectionTypeName:
      return new AssetSectorAllocationsAndHoldingsDataSheetSection(
        dataSheetSection as AssetSectorAllocationsAndHoldingsDataSheetSection,
      );
    case StellarAssetPublicInfoDataSheetSectionTypeName:
      return new StellarAssetPublicInfoDataSheetSection(
        dataSheetSection as StellarAssetPublicInfoDataSheetSection,
      );
    case ETNRollCorporateActionsDataSheetSectionTypeName:
      return new ETNRollCorporateActionsDataSheetSection(
        dataSheetSection as ETNRollCorporateActionsDataSheetSection,
      );
    default:
      throw new TypeError(
        `unknown data sheet section type ${dataSheetSection["@type"]}`,
      );
  }
}
