import { ValidationResult } from "common/validation";
import { Amount, Token } from "james/ledger";
import { CalculateSpotResponse, SpotType } from "pkgTemp/market";
import { EstValueField } from "./SpotTradeDialog";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { NumFieldHlpTxt } from "validationHelperText";
import { BigNumber } from "bignumber.js";
import { formatTextNum } from "utilities/number";
import { MarketListingViewModel } from "james/views/marketListingView";
import { Mechanism, MechanismType } from "james/market";
import { Key } from "james/key/Key";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import {
  getAvailableBalance,
  getTokenBalance,
} from "@mesh/common-js/dist/views/stellarAccountView";

const big100 = new BigNumber("100");

export type FormData = {
  baseTokenListingViewModel?: MarketListingViewModel;
  userID: string;
  userKeys: Key[];
  spotType: SpotType;
  selectedSourceAccountViewModel: StellarAccountViewModel | null;
  signatoryOnSourceAccount: boolean;
  payAmount: Amount;
  receiveAmount: Amount;
  availablePayAssetBalance: Amount;
  feeAmount: Amount;
  vatAmount: Amount;
  estimatedPrice: Amount;
  path: Token[];
  slippage: BigNumber;
  estField: EstValueField | undefined;
  marketMechanism?: Mechanism;
  recalculationRequired: boolean;
  ledgerTokenViewModel: LedgerTokenViewModel;
};

export type FormDataUpdaterSpecsType = {
  spotType: (spotType: SpotType, prevRequest?: FormData) => FormData;
  selectedSourceAccViewModel: (
    selectedSourceAccViewModel: StellarAccountViewModel,
    prevRequest?: FormData,
  ) => FormData;
  payAmount: (payAmount: Amount, prevRequest?: FormData) => FormData;
  receiveAmount: (receiveAmount: Amount, prevRequest?: FormData) => FormData;
  quoteAmountToken: (
    quoteAmountToken: Token,
    prevRequest?: FormData,
  ) => FormData;
  baseTokenListingViewModel: (
    baseTokenListingViewModel: MarketListingViewModel,
    prevRequest?: FormData,
  ) => FormData;
  slippage: (slippage: string, prevRequest?: FormData) => FormData;
  calculateSpotResponse: (
    calculateSpotResponse: CalculateSpotResponse,
    prevRequest?: FormData,
  ) => FormData;
  recalculationRequired: (
    newValue?: boolean,
    prevRequest?: FormData,
  ) => FormData;
  userSignatoryOnSourceAccount: (
    newValue: boolean,
    prevRequest?: FormData,
  ) => FormData;
  availablePayAssetBalance: (
    newValue: Amount,
    prevRequest?: FormData,
  ) => FormData;
};

export const formDataUpdaterSpecs: FormDataUpdaterSpecsType = {
  spotType(spotType: SpotType, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;

    // switch pay and receive amounts
    const payAmount = prevRequest.receiveAmount;
    const receiveAmount = prevRequest.payAmount;

    // switch est field
    const estField =
      prevRequest.estField === undefined
        ? prevRequest.estField
        : prevRequest.estField === EstValueField.Receive
          ? EstValueField.Pay
          : EstValueField.Receive;

    // update available pay asset balance
    let availablePayAssetBalance = prevRequest.availablePayAssetBalance;
    if (prevRequest.selectedSourceAccountViewModel) {
      const updatedAvailablePayAssetBalance = getTokenBalance(
        prevRequest.selectedSourceAccountViewModel,
        payAmount.token.toFutureToken(),
      );
      availablePayAssetBalance = updatedAvailablePayAssetBalance
        ? Amount.fromFutureAmount(
            getAvailableBalance(updatedAvailablePayAssetBalance),
          )
        : payAmount.token.newAmountOf("0");
    }

    return {
      ...prevRequest,
      spotType,
      payAmount,
      receiveAmount,
      estField,
      availablePayAssetBalance,
      recalculationRequired: true,
    };
  },
  selectedSourceAccViewModel(
    selectedSourceAccountViewModel: StellarAccountViewModel,
    prevRequest?: FormData,
  ): FormData {
    prevRequest = prevRequest as FormData;

    let availablePayAssetBalance = prevRequest.availablePayAssetBalance;
    let signatoryOnSourceAccount = prevRequest.signatoryOnSourceAccount;
    if (selectedSourceAccountViewModel) {
      // get updated available pay asset balance
      const updatedAvailablePayAssetBalance = getTokenBalance(
        selectedSourceAccountViewModel,
        availablePayAssetBalance.token.toFutureToken(),
      );
      availablePayAssetBalance = updatedAvailablePayAssetBalance
        ? Amount.fromFutureAmount(
            getAvailableBalance(updatedAvailablePayAssetBalance),
          )
        : availablePayAssetBalance.setValue("0");

      // get updated signatory status
      signatoryOnSourceAccount = false;
      for (const k of prevRequest.userKeys) {
        if (
          selectedSourceAccountViewModel
            .getSignatoriesList()
            .find((s) => s.getKey() === k.publicKey)
        ) {
          signatoryOnSourceAccount = true;
          break;
        }
      }
    }

    return {
      ...prevRequest,
      selectedSourceAccountViewModel,
      availablePayAssetBalance,
      signatoryOnSourceAccount,
    };
  },
  payAmount(payAmount: Amount, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      payAmount,
      estField: EstValueField.Receive,
      recalculationRequired: true,
    };
  },
  receiveAmount(receiveAmount: Amount, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      receiveAmount,
      estField: EstValueField.Pay,
      recalculationRequired: true,
    };
  },
  quoteAmountToken(quoteAmountToken: Token, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;

    let marketMechanism: Mechanism | undefined;
    if (prevRequest.baseTokenListingViewModel) {
      // get spot market mechanism from currently selected base token listing
      marketMechanism =
        prevRequest.baseTokenListingViewModel.listingMarketMechanisms.find(
          (mm) => mm.type === MechanismType.Spot,
        );

      // if spot market mechanism was found but it has no quote parameter for the currently
      // selected quote token then clear the market mechanism
      if (
        marketMechanism &&
        !marketMechanism.quoteParameters.find((qp) =>
          qp.quoteToken.isEqualTo(quoteAmountToken),
        )
      ) {
        marketMechanism = undefined;
      }
    }

    // update the asset of either pay or receive amount as required
    if (prevRequest.spotType === SpotType.Buy) {
      // update available pay asset balance
      let availablePayAssetBalance = prevRequest.availablePayAssetBalance;
      if (prevRequest.selectedSourceAccountViewModel) {
        const updatedAvailablePayAssetBalance = getTokenBalance(
          prevRequest.selectedSourceAccountViewModel,
          quoteAmountToken.toFutureToken(),
        );
        availablePayAssetBalance = updatedAvailablePayAssetBalance
          ? Amount.fromFutureAmount(
              getAvailableBalance(updatedAvailablePayAssetBalance),
            )
          : quoteAmountToken.newAmountOf("0");
      }

      // change asset on pay amount
      return {
        ...prevRequest,
        availablePayAssetBalance,
        marketMechanism,
        payAmount: quoteAmountToken.newAmountOf(prevRequest.payAmount.value),
        recalculationRequired: true,
      };
    } else {
      // change asset on receive amount
      return {
        ...prevRequest,
        marketMechanism,
        receiveAmount: quoteAmountToken.newAmountOf(
          prevRequest.receiveAmount.value,
        ),
        recalculationRequired: true,
      };
    }
  },
  baseTokenListingViewModel(
    baseTokenListingViewModel: MarketListingViewModel,
    prevRequest?: FormData,
  ): FormData {
    prevRequest = prevRequest as FormData;

    // get quote token
    const quoteToken =
      prevRequest.spotType === SpotType.Buy
        ? prevRequest.payAmount.token
        : prevRequest.receiveAmount.token;

    // get spot market mechanism from selected base token listing
    let marketMechanism =
      baseTokenListingViewModel.listingMarketMechanisms.find(
        (mm) => mm.type === MechanismType.Spot,
      );

    // if spot market mechanism was found but it has no quote parameter for the currently
    // selected quote token then clear the market mechanism
    if (
      marketMechanism &&
      !marketMechanism.quoteParameters.find((qp) =>
        qp.quoteToken.isEqualTo(quoteToken),
      )
    ) {
      marketMechanism = undefined;
    }

    // update the asset of either pay or receive amount as required
    if (prevRequest.spotType === SpotType.Buy) {
      // change asset on receive amount
      return {
        ...prevRequest,
        baseTokenListingViewModel,
        marketMechanism,
        receiveAmount: baseTokenListingViewModel.token.newAmountOf(
          prevRequest.receiveAmount.value,
        ),
        recalculationRequired: true,
      };
    } else {
      // update available pay asset balance
      let availablePayAssetBalance = prevRequest.availablePayAssetBalance;
      if (prevRequest.selectedSourceAccountViewModel) {
        const updatedAvailablePayAssetBalance = getTokenBalance(
          prevRequest.selectedSourceAccountViewModel,
          baseTokenListingViewModel.token.toFutureToken(),
        );
        availablePayAssetBalance = updatedAvailablePayAssetBalance
          ? Amount.fromFutureAmount(
              getAvailableBalance(updatedAvailablePayAssetBalance),
            )
          : baseTokenListingViewModel.token.newAmountOf("0");
      }

      // change asset on pay amount
      return {
        ...prevRequest,
        availablePayAssetBalance,
        baseTokenListingViewModel,
        marketMechanism,
        payAmount: baseTokenListingViewModel.token.newAmountOf(
          prevRequest.payAmount.value,
        ),
        recalculationRequired: true,
      };
    }
  },
  slippage(slippage: string, prevRequest?: FormData): FormData {
    return {
      ...(prevRequest as FormData),
      slippage: new BigNumber(slippage),
    };
  },
  calculateSpotResponse(
    calculateSpotResponse: CalculateSpotResponse,
    prevRequest?: FormData,
  ): FormData {
    prevRequest = prevRequest as FormData;

    return {
      ...prevRequest,
      payAmount: new Amount(
        (prevRequest.spotType === SpotType.Buy
          ? // BUY: pay Quote & receive Base
            calculateSpotResponse.quoteAmount
          : // SELL: pay Base, receive Quote
            calculateSpotResponse.baseAmount) as Amount,
      ),
      receiveAmount: new Amount(
        (prevRequest.spotType === SpotType.Buy
          ? // BUY: pay Quote & receive Base
            calculateSpotResponse.baseAmount
          : // SELL: pay Base, receive Quote
            calculateSpotResponse.quoteAmount) as Amount,
      ),
      // Estimated fee amount already calculated and set to either base
      // or quote amount asset depending on swap type. Ideally estFeeAmount
      // should be calculated here from the exact fee amount, but this code
      // is not unit testable right now and so the calculation is left in the
      // spot calculator.
      feeAmount: new Amount(calculateSpotResponse.estFeeAmount as Amount),
      vatAmount: new Amount(calculateSpotResponse.vatAmount as Amount),
      estimatedPrice: new Amount(
        calculateSpotResponse.estimatedPrice as Amount,
      ),
      recalculationRequired: false,
      path: calculateSpotResponse.path.map((t) => new Token(t as Token)),
    };
  },
  recalculationRequired(newValue?: boolean, prevRequest?: FormData): FormData {
    return {
      ...(prevRequest as FormData),
      recalculationRequired: true,
    };
  },

  userSignatoryOnSourceAccount(
    newValue: boolean,
    prevRequest?: FormData,
  ): FormData {
    return {
      ...(prevRequest as FormData),
      signatoryOnSourceAccount: newValue,
    };
  },
  availablePayAssetBalance(newValue: Amount, prevRequest?: FormData): FormData {
    return {
      ...(prevRequest as FormData),
      availablePayAssetBalance: newValue,
    };
  },
};

export const formDataValidationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // determine base and quote amounts and fields
  const baseAmount =
    formData.spotType === SpotType.Buy
      ? formData.receiveAmount
      : formData.payAmount;
  const baseAmountField =
    formData.spotType === SpotType.Buy ? "receiveAmount" : "payAmount";
  const quoteAmount =
    formData.spotType === SpotType.Buy
      ? formData.payAmount
      : formData.receiveAmount;
  const quoteAmountField =
    formData.spotType === SpotType.Buy ? "payAmount" : "receiveAmount";

  // confirm market mechanism is set
  if (!formData.marketMechanism) {
    validationResult.valid = false;
    validationResult.fieldValidations.marketMechanism = "Not Set";
  } else {
    // get relevant quote parameter off market mechanism
    const quoteParameter = formData.marketMechanism.quoteParameters.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (qp: { quoteToken: { isEqualTo: (arg0: any) => any } }) =>
        qp.quoteToken.isEqualTo(quoteAmount.token),
    );
    if (!quoteParameter) {
      console.error("could not find quote parameter on market mechanism");
      validationResult.valid = false;
      validationResult.fieldValidations[quoteAmountField] =
        "could not find quote parameter on market mechanism";
      return validationResult;
    }

    // confirm that base amount is within deal size as set on quote parameter
    if (baseAmount.value.isLessThan(quoteParameter.minimumDealSize.value)) {
      validationResult.valid = false;
      validationResult.fieldValidations[baseAmountField] = `Min ${formatTextNum(
        quoteParameter.minimumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: false,
        },
      )}`;
    } else if (
      baseAmount.value.isGreaterThan(quoteParameter.maximumDealSize.value)
    ) {
      validationResult.valid = false;
      validationResult.fieldValidations[baseAmountField] = `Max ${formatTextNum(
        quoteParameter.maximumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: false,
        },
      )} ${baseAmount.token.code}`;
    }

    // confirm that quote amount > 0
    if (quoteAmount.value.isZero()) {
      validationResult.valid = false;
      validationResult.fieldValidations[quoteAmountField] =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  }

  // pay amount balance check
  if (
    formData.availablePayAssetBalance.value.isLessThan(formData.payAmount.value)
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations.payAmount = `Insufficient Balance: ${
      formData.payAmount.token.code
    } ${formatTextNum(formData.availablePayAssetBalance.value, {
      noDecimalPlaces: 7,
      addDecimalPadding: false,
    })}`;
  }

  // signatory check
  if (!formData.signatoryOnSourceAccount) {
    validationResult.valid = false;
    validationResult.fieldValidations.selectedSourceAccountViewModel =
      "You are not a signatory on this account";
  }

  // confirm slippage in bounds
  if (formData.slippage.isZero()) {
    validationResult.valid = false;
    validationResult.fieldValidations.slippage =
      NumFieldHlpTxt.MustBeGreaterThan0;
  } else if (formData.slippage.isGreaterThanOrEqualTo(big100)) {
    validationResult.valid = false;
    validationResult.fieldValidations.slippage = "Must be less than 100";
  }

  return validationResult;
};
