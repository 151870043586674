import { InstrumentRiskProfile } from "james/financial";
import { Token } from "james/ledger";
import { MarketSubscriptionOrderBookViewModel } from "james/views/marketSubscriptionOrderBookView";
import dayjs from "dayjs";
import { ListingState } from "james/market/Listing";
import { Mechanism, MechanismType, QuoteParameter } from "james/market";
import { LedgerNetwork } from "james/ledger/Network";
import { MediaImage } from "james/media/Image";
import { Frequency } from "@mesh/common-js/dist/financial/frequency_pb";
import { AssetflowCategory } from "@mesh/common-js/dist/financial/assetflowCategory_pb";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";

export enum AssetType {
  Share = "Share",
  RightsToAShare = "Rights to a Share",
  PreferenceShare = "Preference Share",
  RightsToAPreferenceShare = "Rights to a Preference Share",
  Bond = "Bond",
  RightsToABond = "Rights to a Bond",
  ETF = "ETF",
  RightsToAnETF = "Rights to an ETF",
  ETN = "ETN",
  RightsToAnETN = "Rights to an ETN",
  AMC = "AMC",
  RightsToAnAMC = "Rights to an AMC",
  UnitTrust = "Unit Trust",
  RightsToAUnitTrust = "Rights to a Unit Trust",
  CryptoCurrency = "Crypto Currency",
  RightsToACryptoCurrency = "Rights to a Crypto Currency",
  FiatCurrency = "Fiat Currency",
  RightsToAFiatCurrency = "Rights to a Fiat Currency",
  YieldBearingStablecoin = "Yield Bearing Stablecoin",
  Gold = "Gold",
  RightsToGold = "Rights to Gold",
  Silver = "Silver",
  RightsToSilver = "Rights to Silver",
  Platinum = "Platinum",
  RightsToPlatinum = "Rights to Platinum",
  Palladium = "Palladium",
  RightsToPalladium = "Rights to Palladium",
  CrudeOil = "Crude Oil",
  RightsToCrudeOil = "Rights to Crude Oil",
  NaturalGas = "Natural Gas",
  RightsToNaturalGas = "Rights to Natural Gas",
  Copper = "Copper",
  RightsToCopper = "Rights to Copper",
  Corn = "Corn",
  RightsToCorn = "Rights to Corn",
  Wheat = "Wheat",
  RightsToWheat = "Rights to Wheat",
  Soybeans = "Soybeans",
  RightsToSoybeans = "Rights to Soybeans",
  Other = "Other",
}

export const AllAssetTypes: AssetType[] = [
  AssetType.Share,
  AssetType.RightsToAShare,
  AssetType.PreferenceShare,
  AssetType.RightsToAPreferenceShare,
  AssetType.Bond,
  AssetType.RightsToABond,
  AssetType.ETF,
  AssetType.RightsToAnETF,
  AssetType.ETN,
  AssetType.RightsToAnETN,
  AssetType.AMC,
  AssetType.RightsToAnAMC,
  AssetType.UnitTrust,
  AssetType.RightsToAUnitTrust,
  AssetType.CryptoCurrency,
  AssetType.RightsToACryptoCurrency,
  AssetType.FiatCurrency,
  AssetType.RightsToAFiatCurrency,
  AssetType.YieldBearingStablecoin,
  AssetType.Gold,
  AssetType.RightsToGold,
  AssetType.Silver,
  AssetType.RightsToSilver,
  AssetType.Platinum,
  AssetType.RightsToPlatinum,
  AssetType.Palladium,
  AssetType.RightsToPalladium,
  AssetType.CrudeOil,
  AssetType.RightsToCrudeOil,
  AssetType.NaturalGas,
  AssetType.RightsToNaturalGas,
  AssetType.Copper,
  AssetType.RightsToCopper,
  AssetType.Corn,
  AssetType.RightsToCorn,
  AssetType.Wheat,
  AssetType.RightsToWheat,
  AssetType.Soybeans,
  AssetType.RightsToSoybeans,
  AssetType.Other,
];

export const ModelTypeName = "mesh::marketListingView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;
  public id = "";
  public ownerID = "";

  public listingState: ListingState | "" = "";

  // ListingID is a reference to the market.Listing with which the Model is associated.
  public listingID = "";
  public listingMarketMechanisms: Mechanism[] = [];
  public listingLastActionAnnotation = "";
  public isin = "";
  public exchangeCode = "";

  public token: Token = new Token();
  public tokenIconURL = "";

  // AssetID is a reference to an implementation of the market.Asset interface
  // whose issuance ledger.Token is associated with the market.Listing referenced by
  // ListingID.
  // e.g. this could the ID of a financial.DigitalETF or financial.ETFStablecoin
  // (both of which implement the market.Asset interface)
  public assetID = "";
  public assetOwnerID = "";
  public assetOwnerClientShortName = "";
  public assetName = "";
  public assetShortName = "";
  public assetType: AssetType | "" = "";
  public assetUnit: Unit = Unit.UNDEFINED_UNIT;
  public assetIssueDate: string = dayjs().format();
  public assetFractionalisationAllowed: boolean = false;

  public instrumentRiskProfile: InstrumentRiskProfile | "" = "";
  public instrumentRiskProfileDescription = "";

  // MetricDescription provides a description that gives context to the MetricValue.
  // This is different depending on what metric is available for the associated financial.Asset.
  // e.g.:
  // 	- Return (June 2020)
  // 	- Return (2022)
  //	- Coupon Rate
  //	- Current Price per Ounce
  public metricDescription = "";

  // MetricValue is a value that, together with MetricDescription, gives an indication
  // of the indicative future or history return, or current offering price of the financial.Asset.
  // e.g.:
  // 	- 12% --> Return (2022)
  // 	- -3.2% --> Return (2022)
  // 	- 11.32% --> Coupon Rate
  // 	- mZAR 53,228.41 --> Current Price per Ounce
  public metricValue = "";

  // MetricDisclaimer provides an indication of how the MetricValue was calculated.
  // e.g.:
  // 	- Prime + 2% --> 11.32% --> Coupon Rate
  // 	- Spot + 5% --> mZAR 53,228.41 --> Current Price per Ounce
  public metricDisclaimer = "";

  public priority = 0;

  public exchangeNetwork: LedgerNetwork | "" = "";

  public marketSubscriptionOrderBookViewModel: MarketSubscriptionOrderBookViewModel | null =
    null;

  public issuerLogo: MediaImage = new MediaImage();

  public frequency: Frequency = Frequency.UNDEFINED_FREQUENCY;

  public newInstrumentModel: boolean = false;

  public firstAssetflowDate: string = dayjs().format();

  public firstAssetflowCategory: AssetflowCategory =
    AssetflowCategory.UNDEFINED_ASSETFLOW_CATEGORY;

  public privateOffer: boolean = false;

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;
    this.ownerID = model.ownerID;
    this.listingState = model.listingState;
    this.listingID = model.listingID;
    this.listingMarketMechanisms = model.listingMarketMechanisms.map(
      (m) => new Mechanism(m),
    );
    this.listingLastActionAnnotation = model.listingLastActionAnnotation;
    this.isin = model.isin;
    this.exchangeCode = model.exchangeCode;
    this.token = new Token(model.token);
    this.tokenIconURL = model.tokenIconURL;
    this.assetID = model.assetID;
    this.assetOwnerID = model.assetOwnerID;
    this.assetOwnerClientShortName = model.assetOwnerClientShortName;
    this.assetName = model.assetName;
    this.assetShortName = model.assetShortName;
    this.assetType = model.assetType;
    this.assetUnit = model.assetUnit;
    this.assetIssueDate = model.assetIssueDate;
    this.assetFractionalisationAllowed = model.assetFractionalisationAllowed;
    this.instrumentRiskProfile = model.instrumentRiskProfile;
    this.instrumentRiskProfileDescription =
      model.instrumentRiskProfileDescription;
    this.metricDescription = model.metricDescription;
    this.metricValue = model.metricValue;
    this.metricDisclaimer = model.metricDisclaimer;
    this.priority = model.priority;
    if (model.marketSubscriptionOrderBookViewModel) {
      this.marketSubscriptionOrderBookViewModel =
        new MarketSubscriptionOrderBookViewModel(
          model.marketSubscriptionOrderBookViewModel,
        );
    }
    this.issuerLogo = new MediaImage(model.issuerLogo);
    this.frequency = model.frequency;
    this.newInstrumentModel = model.newInstrumentModel;
    this.firstAssetflowDate = model.firstAssetflowDate;
    this.firstAssetflowCategory = model.firstAssetflowCategory;
    this.privateOffer = model.privateOffer;
  }

  getMarketMechanismQuoteParameter(
    mechanismType: MechanismType,
    quoteToken: Token,
  ): QuoteParameter {
    // find given mechanism type
    for (const marketMechanism of this.listingMarketMechanisms) {
      if (marketMechanism.type === mechanismType) {
        // find quote parameter for given quote token
        for (const quoteParameter of marketMechanism.quoteParameters) {
          if (quoteParameter.quoteToken.isEqualTo(quoteToken)) {
            return quoteParameter;
          }
        }

        // matching quote parameter not found
        throw new Error(`quote token '${quoteToken.string()}' not supported`);
      }
    }

    // matching market mechanism not found
    throw new Error(`market mechanism ${mechanismType} not supported`);
  }
}
