import React from "react";
import { TextField } from "@mesh/common-js-react/dist/FormFields";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ValidationResult } from "common/validation";
import { TextNumField } from "components/FormFields";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { Token } from "james/ledger";
import { Mechanism, MechanismType, QuoteParameter } from "james/market";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import {
  Info as InfoIcon,
  Close as DeleteIcon,
  Add as AddIcon,
} from "@mui/icons-material";

export type LimitOrderMechanismFormFormProps = {
  ledgerTokenViewModels: LedgerTokenViewModel[];
  marketMechanism: Mechanism;
  onUpdate: (updatedMarketMechanism: Mechanism) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

export const LimitOrderMechanismForm = (
  props: LimitOrderMechanismFormFormProps,
) => {
  if (props.marketMechanism.type !== MechanismType.LimitOrder) {
    return "invalid market mechanism";
  }

  const getFieldValidation = (field: string, idx?: number) => {
    const fieldKey =
      idx !== undefined
        ? `${MechanismType.LimitOrder}-${idx}-${field}`
        : `${MechanismType.LimitOrder}-${field}`;
    return props.formDataValidationResult.fieldValidations[fieldKey];
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        justifyItems: "center",
      })}
    >
      {props.marketMechanism.quoteParameters.map((quoteParameter, qpIdx) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <TextField
            id={`marketListing-mechanisms-limitOrderMechanism-quoteParameter-${qpIdx}-quoteToken-selectField`}
            disabled={props.disabled}
            readOnly={props.readOnly}
            fullWidth
            sx={{ maxWidth: 200 }}
            label="Quote Token"
            select
            value={
              props.ledgerTokenViewModels.find((cst) =>
                cst.token.isEqualTo(quoteParameter.quoteToken),
              )?.id ?? ""
            }
            onChange={(e) => {
              quoteParameter.quoteToken =
                props.ledgerTokenViewModels.find(
                  (cst) => cst.id === e.target.value,
                )?.token ?? new Token();
              props.marketMechanism.quoteParameters[qpIdx] = quoteParameter;
              props.onUpdate(props.marketMechanism);
            }}
            error={!!getFieldValidation("quoteToken", qpIdx)}
            helperText={getFieldValidation("quoteToken", qpIdx)}
          >
            {props.ledgerTokenViewModels.map((cst, idx) => (
              <MenuItem key={idx} value={cst.id}>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: theme.spacing(1),
                  })}
                >
                  <TokenIconViewUpload size={23} token={cst.token} />
                  <Typography children={cst.token.code} />
                </Box>
              </MenuItem>
            ))}
          </TextField>
          <TextNumField
            id={`marketListing-mechanisms-limitOrderMechanism-quoteParameter-${qpIdx}-minimumDealSize-textNumField`}
            readOnly={props.readOnly}
            disabled={props.disabled}
            fullWidth
            noDecimalPlaces={7}
            label={"Minimum Deal Size"}
            disallowNegative
            value={quoteParameter.minimumDealSize.value}
            sx={{ maxWidth: 320 }}
            onChange={(e) => {
              quoteParameter.minimumDealSize =
                quoteParameter.minimumDealSize.setValue(e.target.value);
              props.marketMechanism.quoteParameters[qpIdx] = quoteParameter;
              props.onUpdate(props.marketMechanism);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                  {quoteParameter.minimumDealSize.token.code}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  children={
                    <Tooltip
                      placement="top"
                      title={`Minimum amount of "${quoteParameter.minimumDealSize.token.code}" that can be bought or sold for "${quoteParameter.quoteToken.code}"`}
                    >
                      <InfoIcon
                        sx={(theme) => ({
                          cursor: "pointer",
                          color: theme.palette.text.secondary,
                          width: "16px",
                          height: "16px",
                          mb: 0.5,
                        })}
                      />
                    </Tooltip>
                  }
                />
              ),
            }}
            error={!!getFieldValidation("minimumDealSize", qpIdx)}
            helperText={getFieldValidation("minimumDealSize", qpIdx)}
          />
          <TextNumField
            id={`marketListing-mechanisms-limitOrderMechanism-quoteParameter-${qpIdx}-maximumDealSize-textNumField`}
            readOnly={props.readOnly}
            disabled={props.disabled}
            fullWidth
            noDecimalPlaces={7}
            label={"Maximum Deal Size"}
            disallowNegative
            value={quoteParameter.maximumDealSize.value}
            sx={{ maxWidth: 320 }}
            onChange={(e) => {
              quoteParameter.maximumDealSize =
                quoteParameter.maximumDealSize.setValue(e.target.value);
              props.marketMechanism.quoteParameters[qpIdx] = quoteParameter;
              props.onUpdate(props.marketMechanism);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                  {quoteParameter.maximumDealSize.token.code}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  children={
                    <Tooltip
                      placement="top"
                      title={`Maximum amount of "${quoteParameter.maximumDealSize.token.code}" that can be bought or sold for "${quoteParameter.quoteToken.code}"`}
                    >
                      <InfoIcon
                        sx={(theme) => ({
                          cursor: "pointer",
                          color: theme.palette.text.secondary,
                          width: "16px",
                          height: "16px",
                          mb: 0.5,
                        })}
                      />
                    </Tooltip>
                  }
                />
              ),
            }}
            error={!!getFieldValidation("maximumDealSize", qpIdx)}
            helperText={getFieldValidation("maximumDealSize", qpIdx)}
          />
          {!!qpIdx &&
            !props.readOnly && ( // do not show delete button for first mechanism
              <Tooltip placement="top" title={"Remove Quote Parameter"}>
                <IconButton
                  id={`marketListing-mechanisms-limitOrderMechanism-quoteParameter-${qpIdx}-removeQuoteParameter-textNumField`}
                  onClick={() => {
                    props.marketMechanism.quoteParameters =
                      props.marketMechanism.quoteParameters.filter(
                        (_, idx) => idx !== qpIdx,
                      );
                    props.onUpdate(props.marketMechanism);
                  }}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          {!props.readOnly && (
            <Tooltip placement="top" title={"Add Quote Parameter"}>
              <IconButton
                id={`marketListing-mechanisms-limitOrderMechanism-quoteParameter-${qpIdx}-addQuoteParameter-textNumField`}
                onClick={() => {
                  props.marketMechanism.quoteParameters.push(
                    new QuoteParameter({
                      quoteToken: props.ledgerTokenViewModels[0].token,
                      minimumDealSize: quoteParameter.minimumDealSize,
                      maximumDealSize: quoteParameter.maximumDealSize,
                    }),
                  );
                  props.onUpdate(props.marketMechanism);
                }}
                size="small"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ))}
    </Box>
  );
};
