import React, { useEffect, useRef, useState } from "react";
import {
  alpha,
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MarketDirectOrderViewCard } from "components/Cards/MarketDirectOrderViewCard";
import { FaceOutlined as FaceIcon } from "@mui/icons-material";
import {
  Model as MarketDirectOrderViewModel,
  useRead as useMarketDirectOrderViewReaderRead,
} from "james/views/marketDirectOrderView";
import { Table, TableMode } from "./Table";
import { useWindowSize } from "utilities/general";
import { OrdersScrollList } from "./components/OrdersScrollList/OrdersScrollList";
import { TextListCriterion } from "james/search/criterion";
import { NewSorting, Query } from "james/search/query";
import { DirectOrderState } from "james/market/DirectOrder";
import { useApplicationContext } from "context/Application/Application";
import { Helmet } from "react-helmet-async";
import { DataComponentInfo, InteractionDriver } from "const/gtm";
import cx from "classnames";

const awaitingConfirmationDirectOrderStates = [
  DirectOrderState.AwaitingConfirmation,
  DirectOrderState.Pending,
  DirectOrderState.UnderInvestigation,
  DirectOrderState.ClearanceFailed,
];

const historyTableModeDirectOrderStates = [
  DirectOrderState.Cancelling,
  DirectOrderState.Cancelled,
  DirectOrderState.Declining,
  DirectOrderState.Declined,
  DirectOrderState.Failing,
  DirectOrderState.Failed,
  DirectOrderState.Accepted,
  DirectOrderState.Settled,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tableModeInitialCriteriaIdx: { [key: string]: any } = {
  [TableMode.awaitingConfirmation]: {
    state: TextListCriterion(awaitingConfirmationDirectOrderStates),
  },
  [TableMode.history]: {
    state: TextListCriterion(historyTableModeDirectOrderStates),
  },
};

const tableModeInitialQueryIdx: { [key: string]: Query } = {
  [TableMode.awaitingConfirmation]: new Query({
    limit: 10,
    offset: 0,
    sorting: [NewSorting("id", "asc")],
  }),
  [TableMode.history]: new Query({
    limit: 10,
    offset: 0,
    sorting: [NewSorting("lastModifiedTime", "desc")],
  }),
};

export function Orders() {
  const [windowWidth] = useWindowSize();
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { authContext, loginClaims } = useApplicationContext();
  const [tableMode, setTableMode] = useState<TableMode>(
    TableMode.awaitingConfirmation,
  );
  const { loading, readResponse, readRequest, setReadRequest } =
    useMarketDirectOrderViewReaderRead({
      context: authContext,
      criteria: tableModeInitialCriteriaIdx[tableMode],
      query: tableModeInitialQueryIdx[tableMode],
    });
  const [initialRender, setInitialRender] = useState(true);
  const [initialApiCall, setInitialApiCall] = useState(false);
  const [
    selectedMarketDirectOrderViewModel,
    setSelectedMarketDirectOrderViewModel,
  ] = useState<MarketDirectOrderViewModel | undefined>(undefined);
  const refreshTableCallbackRef = useRef<() => void>(() => undefined);
  const changeTableSideCallbackRef = useRef<
    (updatedTableMode: TableMode) => void
  >(() => undefined);

  useEffect(() => {
    if (loading) {
      setInitialApiCall(true);
    }

    if (initialRender && tableMode === TableMode.awaitingConfirmation) {
      if (readResponse.total !== 0 && initialApiCall && initialRender) {
        setInitialRender(false);
        setInitialApiCall(false);
      } else if (readResponse.total === 0 && initialApiCall && initialRender) {
        setTableMode(TableMode.history);
        setInitialApiCall(false);
      }
    } else {
      if (!loading && initialRender && initialApiCall) {
        setInitialRender(false);
        setInitialApiCall(false);
      }
    }
  }, [readResponse.total, loading]);

  return isMobile ? (
    <OrdersScrollList />
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        className={cx({
          "not-empty": readResponse.total !== 0,
        })}
        sx={(theme) => ({
          width: "100%",
          maxWidth: theme.breakpoints.values.lg,
          padding: theme.spacing(3, 3, 3, 3),
          justifyContent: "center",
          columnGap: theme.spacing(4),
          rowGap: theme.spacing(4),
          "&.not-empty": {
            display: "grid",
            [theme.breakpoints.down("lg")]: {
              gridTemplateRows: "auto auto",
            },
            [theme.breakpoints.up("lg")]: {
              gridTemplateColumns: "800px 400px",
            },
          },
        })}
      >
        <Helmet>
          <title>Mesh | Direct Orders</title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="View all your direct orders in one place, those awaiting confirmation as well as past direct orders"
          />
        </Helmet>
        <Box
          sx={[
            lgDown && {
              width: windowWidth - 40,
            },
            readResponse.total === 0 && {
              width: "100%",
            },
          ]}
          data-component-info={JSON.stringify({
            component_id: "orders_information",
            component_business_name: "orders information",
            component_title: "order list",
            component_driver: InteractionDriver.showOrderStatus,
          } as DataComponentInfo)}
        >
          <Table
            refreshTableCallbackRef={refreshTableCallbackRef}
            changeTableSideCallbackRef={changeTableSideCallbackRef}
            selectedMarketDirectOrderViewModel={
              selectedMarketDirectOrderViewModel
                ? selectedMarketDirectOrderViewModel
                : readResponse.models[0]
            }
            selectMarketDirectOrderViewModel={
              setSelectedMarketDirectOrderViewModel
            }
            loading={loading}
            initialRender={initialRender}
            setReadRequest={setReadRequest}
            readResponse={readResponse}
            readRequest={readRequest}
            setTableMode={setTableMode}
            tableMode={tableMode}
          />
        </Box>

        {!initialRender && readResponse.total !== 0 && (
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                width: windowWidth - 40,
              },
            })}
          >
            {readResponse.models[0] ? (
              <MarketDirectOrderViewCard
                onActionComplete={() => {
                  refreshTableCallbackRef.current();
                  setSelectedMarketDirectOrderViewModel(undefined);
                }}
                clientIDPerspective={loginClaims.clientID}
                marketDirectOrderViewModel={
                  selectedMarketDirectOrderViewModel
                    ? selectedMarketDirectOrderViewModel
                    : readResponse.models[0]
                }
              />
            ) : (
              <Card>
                <CardContent
                  sx={{
                    height: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto",
                      justifyItems: "center",
                      gridRowGap: theme.spacing(2),
                    }}
                  >
                    <FaceIcon
                      sx={{
                        fontSize: 110,
                        color: alpha(theme.palette.background.default, 0.5),
                      }}
                    />
                    {lgDown ? (
                      <Typography
                        variant="subtitle1"
                        align="center"
                        sx={{
                          color: theme.palette.text.secondary,
                          width: 250,
                        }}
                        children={`Please select a row from above to display an order`}
                      />
                    ) : (
                      <Typography
                        variant="subtitle1"
                        align="center"
                        sx={{
                          color: theme.palette.text.secondary,
                          width: 250,
                        }}
                        children={`Please select a row on the left to display an order`}
                      />
                    )}
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
