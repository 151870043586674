import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ListingState } from "james/market/Listing";
import { useListingContext } from "../../Context";

interface Props {
  onClose: () => void;
}

export const ToggleActivationDialog: React.FC<Props> = ({ onClose }: Props) => {
  const { formData, toggleActivation } = useListingContext();
  const theme = useTheme();

  return (
    <Dialog open>
      <DialogTitle sx={(theme) => ({ padding: theme.spacing(2) })}>
        {formData.listing.state === ListingState.Active
          ? "Deactivation"
          : "Activation"}{" "}
        Confirmation
      </DialogTitle>
      <DialogContent
        style={{
          alignItems: "center",
          minWidth: "590px",
          padding: theme.spacing(3),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <Typography>
            Please confirm that you would like to{" "}
            <b>
              {formData.listing.state === ListingState.Active
                ? "Deactivate"
                : "Activate"}
            </b>
            :
          </Typography>
          <Typography>
            Listing for <b>{formData.smartInstrumentToList.getName()}</b>
          </Typography>
        </Box>
        <DialogActions>
          <Tooltip placement="top" title={"Close"}>
            <span>
              <Button
                id="listingDeactivationDialog-close-button"
                variant="outlined"
                fullWidth
                size="small"
                onClick={onClose}
                children="Close"
              />
            </span>
          </Tooltip>
          <span>
            <LoadingButton
              id="listingDeactivationDialog-activationToggle-button"
              variant={"contained"}
              color={"primary"}
              fullWidth
              size="small"
              onClick={() => {
                toggleActivation();
                onClose();
              }}
            >
              {formData.listing.state === ListingState.Active
                ? "Deactivate"
                : "Activate"}
            </LoadingButton>
          </span>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
