import React from "react";
import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import { DateTimeFormatWithOffset } from "const/dateformats";

interface PreLaunchSectionProps {
  issueDate: string;
}

export function PreLaunchSection(props: PreLaunchSectionProps) {
  return (
    <Box
      sx={{ position: "relative", width: "100%" }}
      onClick={(e) => {
        if (e && e.stopPropagation) {
          e.stopPropagation();
        }
      }}
    >
      <Box>
        <Button
          id="prelaunch-section-action-button"
          fullWidth
          sx={(theme) => ({
            height: 36,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: "#716F88",
            color: theme.palette.text.primary,
            fontSize: "14px",
          })}
          variant="contained"
        >
          <Typography sx={{ fontWeight: "bold" }}>Watch this space!</Typography>
        </Button>
      </Box>
      <Box
        sx={(theme) => ({
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          border: `1px solid ${theme.palette.text.disabled}`,
          borderTop: 0,
          height: 36,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}
      >
        <Typography variant="subtitle2" fontSize={12}>
          Launching on:
        </Typography>
        <Typography
          variant="subtitle2"
          fontSize={12}
          fontWeight={"Bold"}
          sx={{ ml: 1 }}
        >
          {dayjs(props.issueDate).format(DateTimeFormatWithOffset)}
        </Typography>
      </Box>
    </Box>
  );
}
