import { Box } from "@mui/system";
import React, { useState } from "react";
import { usePaymentContext } from "./Context";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { PaymentStateChip } from "../StateChip";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount } from "james/ledger";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { DateTimeFormatWithOffset } from "const/dateformats";
import { assetflowCategoryToString } from "@mesh/common-js/dist/financial/assetFlowCategory";
import { isString } from "lodash";
import { AssetHolderLookupStateChip } from "components/Ledger/Ownership/AssetHolderLookupStateChip";
import { Table as PaymentRecipientTable } from "./PaymentRecipients";
import { Table as PaymentFundingTable } from "./PaymentFunding";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { PaymentTriggerWrapper } from "@mesh/common-js/dist/financial";
import { Refresh as ReloadIcon } from "@mui/icons-material";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";

export type PaymentDetailProps = {
  system: boolean;
};

enum PaymentDetailTab {
  Recipients = "Recipients",
  Funding = "Funding",
  BatchPayment = "Batch Payment",
}

const allPaymentDetailTabs: PaymentDetailTab[] = [
  PaymentDetailTab.Recipients,
  PaymentDetailTab.Funding,
  PaymentDetailTab.BatchPayment,
];

export const PaymentDetail = ({ system }: PaymentDetailProps) => {
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const {
    initialisationError,
    initialised,
    clearInitialisationError,
    payment,
    paymentTrigger,
    assetHolderLookup,
    reload,
  } = usePaymentContext();
  const [selectedTab, setSelectedTab] = useState(PaymentDetailTab.Recipients);

  if (initialisationError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 55,
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Listing"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>Something went wrong while initialsing.</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInitialisationError}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (!initialised) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 55,
        }}
      >
        <Card>
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <CircularProgress size={70} />
            <Typography
              variant="h5"
              color="textSecondary"
              children="Getting things ready for you..."
            />
          </CardContent>
        </Card>
      </Box>
    );
  }

  const wrappedPaymentTrigger = new PaymentTriggerWrapper(paymentTrigger);

  const renderDetailItem = (
    title: string,
    content: string | React.ReactNode,
  ) => {
    return (
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(0.2),
        })}
      >
        <Typography
          variant="body2"
          sx={(theme) => ({ color: theme.palette.text.secondary })}
        >
          {title}
        </Typography>
        {isString(content) ? <Typography>{content}</Typography> : content}
      </Box>
    );
  };

  return (
    <Box sx={{ height: window.innerHeight - 90 }}>
      <Card sx={{ height: "100%" }}>
        <CardHeader
          title={
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: theme.spacing(1),
              })}
            >
              <Typography variant="h5">
                Payment <b>{payment.getNumber()}</b> for{" "}
                <b>{payment.getSmartinstrumentname()}</b>
              </Typography>
              <PaymentStateChip state={payment.getState()} />
              <IconButton
                sx={{ marginLeft: "auto" }}
                id={"paymentDetailView-reload-iconButton"}
                size={"small"}
                onClick={reload}
              >
                <ReloadIcon />
              </IconButton>
            </Box>
          }
        />
        <CardContent
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: theme.spacing(2),
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
              borderRight: `1px solid ${theme.palette.divider}`,
              paddingRight: theme.spacing(2),
            })}
          >
            <Typography variant="h6">Payment Details</Typography>
            <Box
              sx={(theme) => ({
                display: "flex",
                flexWrap: "wrap",
                columnGap: theme.spacing(2),
                alignItems: "center",
              })}
            >
              {renderDetailItem(
                "Amount",
                <Amount
                  amount={LedgerAmount.fromFutureAmount(payment.getAmount())}
                  formatTextNumOpts={{ noDecimalPlaces: 7 }}
                />,
              )}
              {renderDetailItem(
                "Payment Date",
                protobufTimestampToDayjs(payment.getDate() ?? new Timestamp())
                  .tz(timezoneToString(payment.getTimezone()))
                  .format(DateTimeFormatWithOffset),
              )}
              {renderDetailItem(
                "Category",
                assetflowCategoryToString(payment.getCategory()),
              )}
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            })}
          >
            <Typography variant="h6">Payment Trigger Details</Typography>
            <Box
              sx={(theme) => ({
                display: "flex",
                flexWrap: "wrap",
                columnGap: theme.spacing(2),
                alignItems: "center",
              })}
            >
              {renderDetailItem(
                "Description",
                wrappedPaymentTrigger.description,
              )}
              {renderDetailItem(
                "Record Date",
                protobufTimestampToDayjs(wrappedPaymentTrigger.recorddate)
                  .tz(timezoneToString(payment.getTimezone()))
                  .format(DateTimeFormatWithOffset),
              )}
              {renderDetailItem(
                "Amount (Per Token)",
                <Amount
                  amount={LedgerAmount.fromFutureAmount(
                    wrappedPaymentTrigger.amount,
                  )}
                  formatTextNumOpts={{ noDecimalPlaces: 7 }}
                />,
              )}
              <Chip
                sx={(theme) => ({
                  fontWeight: 400,
                  backgroundColor: wrappedPaymentTrigger.fixed
                    ? theme.palette.success.main
                    : theme.palette.info.main,
                })}
                size="small"
                label={wrappedPaymentTrigger.fixed ? "Fixed" : "Not Fixed"}
              />
            </Box>
          </Box>
        </CardContent>
        <Divider />
        <CardContent
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          })}
        >
          <Typography variant="h6">Asset Holder Lookup Details</Typography>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              columnGap: theme.spacing(2),
            })}
          >
            <AssetHolderLookupStateChip state={assetHolderLookup.getState()} />
            {renderDetailItem(
              "Lookup Date",
              protobufTimestampToDayjs(
                assetHolderLookup.getDate() ?? new Timestamp(),
              )
                .tz(timezoneToString(payment.getTimezone()))
                .format(DateTimeFormatWithOffset),
            )}
            {renderDetailItem(
              "Lookup Instrument Token",
              assetHolderLookup.getToken()?.getCode() ?? "",
            )}
          </Box>
        </CardContent>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.custom.grapeDark,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          })}
        >
          <Tabs scrollButtons visibleScrollbar value={selectedTab}>
            {(system
              ? allPaymentDetailTabs
              : allPaymentDetailTabs.filter(
                  (t) => t != PaymentDetailTab.BatchPayment,
                )
            ).map((tab: PaymentDetailTab, tabIdx: number) => (
              <Tab
                key={tabIdx}
                value={tab}
                label={tab}
                onClick={() => setSelectedTab(tab)}
              />
            ))}
          </Tabs>
        </Box>
        <Box
          className="meshScroll"
          sx={(theme) => ({
            padding: theme.spacing(2),
          })}
        >
          {(() => {
            switch (selectedTab) {
              case PaymentDetailTab.Recipients:
                return (
                  <PaymentRecipientTable
                    system={system}
                    height={window.innerHeight - 425 - noticeBannerHeight}
                    filter={[
                      newTextExactCriterion(
                        "paymenttriggerid",
                        payment.getTriggerid(),
                      ),
                    ]}
                  />
                );

              case PaymentDetailTab.Funding:
                return (
                  <PaymentFundingTable
                    system={system}
                    height={window.innerHeight - 425 - noticeBannerHeight}
                    payment={payment}
                  />
                );

              case PaymentDetailTab.BatchPayment:
                return <Box>go see batch</Box>;

              default:
                return null;
            }
          })()}
        </Box>
      </Card>
    </Box>
  );
};
