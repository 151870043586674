/**
 * @fileoverview gRPC-Web generated client stub for banking
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: banking/bankAccountReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var banking_bankAccount_pb = require('../banking/bankAccount_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var banking_fundingOrderState_pb = require('../banking/fundingOrderState_pb.js')

var banking_bankAccountReader_meshproto_pb = require('../banking/bankAccountReader_meshproto_pb.js')
const proto = {};
proto.banking = require('./bankAccountReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.BankAccountReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ReadOneBankAccountRequest,
 *   !proto.banking.ReadOneBankAccountResponse>}
 */
const methodDescriptor_BankAccountReaderUNSCOPED_ReadOneBankAccountUNSCOPED = new grpc.web.MethodDescriptor(
  '/banking.BankAccountReaderUNSCOPED/ReadOneBankAccountUNSCOPED',
  grpc.web.MethodType.UNARY,
  banking_bankAccountReader_meshproto_pb.ReadOneBankAccountRequest,
  banking_bankAccountReader_meshproto_pb.ReadOneBankAccountResponse,
  /**
   * @param {!proto.banking.ReadOneBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  banking_bankAccountReader_meshproto_pb.ReadOneBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ReadOneBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ReadOneBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ReadOneBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountReaderUNSCOPEDClient.prototype.readOneBankAccountUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountReaderUNSCOPED/ReadOneBankAccountUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_BankAccountReaderUNSCOPED_ReadOneBankAccountUNSCOPED,
      callback);
};


/**
 * @param {!proto.banking.ReadOneBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ReadOneBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountReaderUNSCOPEDPromiseClient.prototype.readOneBankAccountUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountReaderUNSCOPED/ReadOneBankAccountUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_BankAccountReaderUNSCOPED_ReadOneBankAccountUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ReadManyBankAccountRequest,
 *   !proto.banking.ReadManyBankAccountResponse>}
 */
const methodDescriptor_BankAccountReaderUNSCOPED_ReadManyBankAccountUNSCOPED = new grpc.web.MethodDescriptor(
  '/banking.BankAccountReaderUNSCOPED/ReadManyBankAccountUNSCOPED',
  grpc.web.MethodType.UNARY,
  banking_bankAccountReader_meshproto_pb.ReadManyBankAccountRequest,
  banking_bankAccountReader_meshproto_pb.ReadManyBankAccountResponse,
  /**
   * @param {!proto.banking.ReadManyBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  banking_bankAccountReader_meshproto_pb.ReadManyBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ReadManyBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ReadManyBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ReadManyBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountReaderUNSCOPEDClient.prototype.readManyBankAccountUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountReaderUNSCOPED/ReadManyBankAccountUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_BankAccountReaderUNSCOPED_ReadManyBankAccountUNSCOPED,
      callback);
};


/**
 * @param {!proto.banking.ReadManyBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ReadManyBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountReaderUNSCOPEDPromiseClient.prototype.readManyBankAccountUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountReaderUNSCOPED/ReadManyBankAccountUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_BankAccountReaderUNSCOPED_ReadManyBankAccountUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ReadAllBankAccountRequest,
 *   !proto.banking.ReadAllBankAccountResponse>}
 */
const methodDescriptor_BankAccountReaderUNSCOPED_ReadAllBankAccountUNSCOPED = new grpc.web.MethodDescriptor(
  '/banking.BankAccountReaderUNSCOPED/ReadAllBankAccountUNSCOPED',
  grpc.web.MethodType.UNARY,
  banking_bankAccountReader_meshproto_pb.ReadAllBankAccountRequest,
  banking_bankAccountReader_meshproto_pb.ReadAllBankAccountResponse,
  /**
   * @param {!proto.banking.ReadAllBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  banking_bankAccountReader_meshproto_pb.ReadAllBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ReadAllBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ReadAllBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ReadAllBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.BankAccountReaderUNSCOPEDClient.prototype.readAllBankAccountUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.BankAccountReaderUNSCOPED/ReadAllBankAccountUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_BankAccountReaderUNSCOPED_ReadAllBankAccountUNSCOPED,
      callback);
};


/**
 * @param {!proto.banking.ReadAllBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ReadAllBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.banking.BankAccountReaderUNSCOPEDPromiseClient.prototype.readAllBankAccountUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.BankAccountReaderUNSCOPED/ReadAllBankAccountUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_BankAccountReaderUNSCOPED_ReadAllBankAccountUNSCOPED);
};


module.exports = proto.banking;

