import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { SubscriptionOrderBook } from "./SubscriptionOrderBook";
import { BigNumber } from "bignumber.js";
import { Amount } from "james/ledger";

export type UpdateSubscriptionOrderBookUnitPriceRequest = {
  context: Context;
  subscriptionOrderBookID: string;
  unitPrice: Amount;
};

export type UpdateSubscriptionOrderBookUnitPriceResponse = {
  subscriptionOrderBook: SubscriptionOrderBook;
};

export type AddUnderwritingToSubscriptionOrderBookRequest = {
  context: Context;
  underwritingClientID: string;
  subscriptionOrderBookID: string;
  percentage: BigNumber;
};

export type AddUnderwritingToSubscriptionOrderBookResponse = {
  subscriptionOrderBook: SubscriptionOrderBook;
};

export const SubscriptionOrderBookUpdaterServiceProviderName =
  "market-SubscriptionOrderBookUpdater";

export const SubscriptionOrderBookUpdater = {
  async UpdateSubscriptionOrderBookUnitPrice(
    request: UpdateSubscriptionOrderBookUnitPriceRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<UpdateSubscriptionOrderBookUnitPriceResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionOrderBookUpdaterServiceProviderName}.UpdateSubscriptionOrderBookUnitPrice`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrderBook: new SubscriptionOrderBook(
        response.subscriptionOrderBook,
      ),
    };
  },
  async AddUnderwritingToSubscriptionOrderBook(
    request: AddUnderwritingToSubscriptionOrderBookRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<AddUnderwritingToSubscriptionOrderBookResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionOrderBookUpdaterServiceProviderName}.AddUnderwritingToSubscriptionOrderBook`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrderBook: new SubscriptionOrderBook(
        response.subscriptionOrderBook,
      ),
    };
  },
};
