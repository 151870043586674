// source: banking/defundOrderReader_meshproto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var search_sorting_pb = require('../search/sorting_pb.js');
goog.object.extend(proto, search_sorting_pb);
var banking_defundOrder_pb = require('../banking/defundOrder_pb.js');
goog.object.extend(proto, banking_defundOrder_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
var banking_defundOrderState_pb = require('../banking/defundOrderState_pb.js');
goog.object.extend(proto, banking_defundOrderState_pb);
goog.exportSymbol('proto.banking.ReadAllDefundOrderRequest', null, global);
goog.exportSymbol('proto.banking.ReadAllDefundOrderResponse', null, global);
goog.exportSymbol('proto.banking.ReadManyDefundOrderRequest', null, global);
goog.exportSymbol('proto.banking.ReadManyDefundOrderResponse', null, global);
goog.exportSymbol('proto.banking.ReadOneDefundOrderRequest', null, global);
goog.exportSymbol('proto.banking.ReadOneDefundOrderResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadOneDefundOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadOneDefundOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadOneDefundOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadOneDefundOrderRequest.displayName = 'proto.banking.ReadOneDefundOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadOneDefundOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.ReadOneDefundOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadOneDefundOrderResponse.displayName = 'proto.banking.ReadOneDefundOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadManyDefundOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadManyDefundOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadManyDefundOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadManyDefundOrderRequest.displayName = 'proto.banking.ReadManyDefundOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadManyDefundOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadManyDefundOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadManyDefundOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadManyDefundOrderResponse.displayName = 'proto.banking.ReadManyDefundOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadAllDefundOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadAllDefundOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadAllDefundOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadAllDefundOrderRequest.displayName = 'proto.banking.ReadAllDefundOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ReadAllDefundOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.banking.ReadAllDefundOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.banking.ReadAllDefundOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ReadAllDefundOrderResponse.displayName = 'proto.banking.ReadAllDefundOrderResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadOneDefundOrderRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadOneDefundOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadOneDefundOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadOneDefundOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadOneDefundOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadOneDefundOrderRequest}
 */
proto.banking.ReadOneDefundOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadOneDefundOrderRequest;
  return proto.banking.ReadOneDefundOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadOneDefundOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadOneDefundOrderRequest}
 */
proto.banking.ReadOneDefundOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadOneDefundOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadOneDefundOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadOneDefundOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadOneDefundOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.ReadOneDefundOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.ReadOneDefundOrderRequest} returns this
*/
proto.banking.ReadOneDefundOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadOneDefundOrderRequest} returns this
 */
proto.banking.ReadOneDefundOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadOneDefundOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.ReadOneDefundOrderRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.ReadOneDefundOrderRequest} returns this
*/
proto.banking.ReadOneDefundOrderRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.ReadOneDefundOrderRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadOneDefundOrderRequest} returns this
 */
proto.banking.ReadOneDefundOrderRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadOneDefundOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadOneDefundOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadOneDefundOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadOneDefundOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
defundorder: (f = msg.getDefundorder()) && banking_defundOrder_pb.DefundOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadOneDefundOrderResponse}
 */
proto.banking.ReadOneDefundOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadOneDefundOrderResponse;
  return proto.banking.ReadOneDefundOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadOneDefundOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadOneDefundOrderResponse}
 */
proto.banking.ReadOneDefundOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_defundOrder_pb.DefundOrder;
      reader.readMessage(value,banking_defundOrder_pb.DefundOrder.deserializeBinaryFromReader);
      msg.setDefundorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadOneDefundOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadOneDefundOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadOneDefundOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadOneDefundOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefundorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_defundOrder_pb.DefundOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional DefundOrder defundOrder = 1;
 * @return {?proto.banking.DefundOrder}
 */
proto.banking.ReadOneDefundOrderResponse.prototype.getDefundorder = function() {
  return /** @type{?proto.banking.DefundOrder} */ (
    jspb.Message.getWrapperField(this, banking_defundOrder_pb.DefundOrder, 1));
};


/**
 * @param {?proto.banking.DefundOrder|undefined} value
 * @return {!proto.banking.ReadOneDefundOrderResponse} returns this
*/
proto.banking.ReadOneDefundOrderResponse.prototype.setDefundorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadOneDefundOrderResponse} returns this
 */
proto.banking.ReadOneDefundOrderResponse.prototype.clearDefundorder = function() {
  return this.setDefundorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadOneDefundOrderResponse.prototype.hasDefundorder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadManyDefundOrderRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadManyDefundOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadManyDefundOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadManyDefundOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadManyDefundOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
query: (f = msg.getQuery()) && search_query_pb.Query.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadManyDefundOrderRequest}
 */
proto.banking.ReadManyDefundOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadManyDefundOrderRequest;
  return proto.banking.ReadManyDefundOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadManyDefundOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadManyDefundOrderRequest}
 */
proto.banking.ReadManyDefundOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_query_pb.Query;
      reader.readMessage(value,search_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadManyDefundOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadManyDefundOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadManyDefundOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadManyDefundOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      search_query_pb.Query.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.ReadManyDefundOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.ReadManyDefundOrderRequest} returns this
*/
proto.banking.ReadManyDefundOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadManyDefundOrderRequest} returns this
 */
proto.banking.ReadManyDefundOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadManyDefundOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.ReadManyDefundOrderRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.ReadManyDefundOrderRequest} returns this
*/
proto.banking.ReadManyDefundOrderRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.ReadManyDefundOrderRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadManyDefundOrderRequest} returns this
 */
proto.banking.ReadManyDefundOrderRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional search.Query query = 3;
 * @return {?proto.search.Query}
 */
proto.banking.ReadManyDefundOrderRequest.prototype.getQuery = function() {
  return /** @type{?proto.search.Query} */ (
    jspb.Message.getWrapperField(this, search_query_pb.Query, 3));
};


/**
 * @param {?proto.search.Query|undefined} value
 * @return {!proto.banking.ReadManyDefundOrderRequest} returns this
*/
proto.banking.ReadManyDefundOrderRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadManyDefundOrderRequest} returns this
 */
proto.banking.ReadManyDefundOrderRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadManyDefundOrderRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadManyDefundOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadManyDefundOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadManyDefundOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadManyDefundOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadManyDefundOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    banking_defundOrder_pb.DefundOrder.toObject, includeInstance),
total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadManyDefundOrderResponse}
 */
proto.banking.ReadManyDefundOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadManyDefundOrderResponse;
  return proto.banking.ReadManyDefundOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadManyDefundOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadManyDefundOrderResponse}
 */
proto.banking.ReadManyDefundOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_defundOrder_pb.DefundOrder;
      reader.readMessage(value,banking_defundOrder_pb.DefundOrder.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadManyDefundOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadManyDefundOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadManyDefundOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadManyDefundOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      banking_defundOrder_pb.DefundOrder.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated DefundOrder records = 1;
 * @return {!Array<!proto.banking.DefundOrder>}
 */
proto.banking.ReadManyDefundOrderResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.banking.DefundOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, banking_defundOrder_pb.DefundOrder, 1));
};


/**
 * @param {!Array<!proto.banking.DefundOrder>} value
 * @return {!proto.banking.ReadManyDefundOrderResponse} returns this
*/
proto.banking.ReadManyDefundOrderResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.banking.DefundOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.banking.DefundOrder}
 */
proto.banking.ReadManyDefundOrderResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.banking.DefundOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadManyDefundOrderResponse} returns this
 */
proto.banking.ReadManyDefundOrderResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.banking.ReadManyDefundOrderResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.banking.ReadManyDefundOrderResponse} returns this
 */
proto.banking.ReadManyDefundOrderResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadAllDefundOrderRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadAllDefundOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadAllDefundOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadAllDefundOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadAllDefundOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
sortingList: jspb.Message.toObjectList(msg.getSortingList(),
    search_sorting_pb.Sorting.toObject, includeInstance),
batchsize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadAllDefundOrderRequest}
 */
proto.banking.ReadAllDefundOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadAllDefundOrderRequest;
  return proto.banking.ReadAllDefundOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadAllDefundOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadAllDefundOrderRequest}
 */
proto.banking.ReadAllDefundOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_sorting_pb.Sorting;
      reader.readMessage(value,search_sorting_pb.Sorting.deserializeBinaryFromReader);
      msg.addSorting(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBatchsize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadAllDefundOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadAllDefundOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadAllDefundOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadAllDefundOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getSortingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      search_sorting_pb.Sorting.serializeBinaryToWriter
    );
  }
  f = message.getBatchsize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.ReadAllDefundOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.ReadAllDefundOrderRequest} returns this
*/
proto.banking.ReadAllDefundOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ReadAllDefundOrderRequest} returns this
 */
proto.banking.ReadAllDefundOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ReadAllDefundOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.banking.ReadAllDefundOrderRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.banking.ReadAllDefundOrderRequest} returns this
*/
proto.banking.ReadAllDefundOrderRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.banking.ReadAllDefundOrderRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadAllDefundOrderRequest} returns this
 */
proto.banking.ReadAllDefundOrderRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * repeated search.Sorting sorting = 3;
 * @return {!Array<!proto.search.Sorting>}
 */
proto.banking.ReadAllDefundOrderRequest.prototype.getSortingList = function() {
  return /** @type{!Array<!proto.search.Sorting>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_sorting_pb.Sorting, 3));
};


/**
 * @param {!Array<!proto.search.Sorting>} value
 * @return {!proto.banking.ReadAllDefundOrderRequest} returns this
*/
proto.banking.ReadAllDefundOrderRequest.prototype.setSortingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.search.Sorting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Sorting}
 */
proto.banking.ReadAllDefundOrderRequest.prototype.addSorting = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.search.Sorting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadAllDefundOrderRequest} returns this
 */
proto.banking.ReadAllDefundOrderRequest.prototype.clearSortingList = function() {
  return this.setSortingList([]);
};


/**
 * optional uint64 batchSize = 4;
 * @return {number}
 */
proto.banking.ReadAllDefundOrderRequest.prototype.getBatchsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.banking.ReadAllDefundOrderRequest} returns this
 */
proto.banking.ReadAllDefundOrderRequest.prototype.setBatchsize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.banking.ReadAllDefundOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ReadAllDefundOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ReadAllDefundOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ReadAllDefundOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadAllDefundOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    banking_defundOrder_pb.DefundOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ReadAllDefundOrderResponse}
 */
proto.banking.ReadAllDefundOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ReadAllDefundOrderResponse;
  return proto.banking.ReadAllDefundOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ReadAllDefundOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ReadAllDefundOrderResponse}
 */
proto.banking.ReadAllDefundOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_defundOrder_pb.DefundOrder;
      reader.readMessage(value,banking_defundOrder_pb.DefundOrder.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ReadAllDefundOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ReadAllDefundOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ReadAllDefundOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ReadAllDefundOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      banking_defundOrder_pb.DefundOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DefundOrder records = 1;
 * @return {!Array<!proto.banking.DefundOrder>}
 */
proto.banking.ReadAllDefundOrderResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.banking.DefundOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, banking_defundOrder_pb.DefundOrder, 1));
};


/**
 * @param {!Array<!proto.banking.DefundOrder>} value
 * @return {!proto.banking.ReadAllDefundOrderResponse} returns this
*/
proto.banking.ReadAllDefundOrderResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.banking.DefundOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.banking.DefundOrder}
 */
proto.banking.ReadAllDefundOrderResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.banking.DefundOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.banking.ReadAllDefundOrderResponse} returns this
 */
proto.banking.ReadAllDefundOrderResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


goog.object.extend(exports, proto.banking);
