import React from "react";
import { DialogTitle, useTheme, Button, Divider } from "@mui/material";
import { SubscribeButton } from "components/Buttons/SubscriptionButton/SubscriptionConfirmationButton";

export interface ConfirmationFooterProps {
  isMobile: boolean;
  termsAndConditionsAccepted: boolean;
  assetName: string;
  submittingOrder: boolean;
  submitOrder: () => void;
  setShowConfirmation: (showConfirmation: boolean) => void;
  closeDialog: () => void;
}

export const ConfirmationFooter = ({
  isMobile,
  termsAndConditionsAccepted,
  assetName,
  submittingOrder,
  setShowConfirmation,
  submitOrder,
}: ConfirmationFooterProps) => {
  const theme = useTheme();

  return (
    <>
      {!isMobile && <Divider></Divider>}
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: {
            xs: theme.spacing(3, 3, 4, 3),
            sm: theme.spacing(3, 6, 4, 6),
          },
          boxShadow: {
            xs: "0px 0px 18px 4px rgba(0,0,0,0.4);",
            sm: 0,
          },
          gap: 2,
          zIndex: 1,
          minHeight: "89px",
        }}
      >
        <Button
          sx={{
            width: { sm: "168px", xs: "50%" },
            border: 1,
          }}
          size={isMobile ? "large" : "medium"}
          variant="outlined"
          onClick={() => setShowConfirmation(false)}
          disabled={submittingOrder}
        >
          Back
        </Button>
        <SubscribeButton
          smDown={isMobile}
          fullWidth={false}
          width={isMobile ? "50%" : "168px"}
          assetName={assetName}
          onClick={submitOrder}
          buttonDisabled={submittingOrder || !termsAndConditionsAccepted}
          title={(() => {
            if (!termsAndConditionsAccepted) {
              return "You must agree to the terms and conditions to submit this order";
            }
            return "";
          })()}
        />
      </DialogTitle>
    </>
  );
};
