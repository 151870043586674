import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { MobileNumberVerificationCard } from "../MobileNumberVerificationCard/MobileNumberVerificationCard";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { Box } from "@mui/system";
import { OTPInputComponent } from "components/OTPInput/OTPInput";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { TOTPVerifier } from "james/security/verification/TOTPVerifier";
import { CountryCode, getCountryCallingCode } from "libphonenumber-js";
import { Close as CloseIcon } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import { MobileNumberVerificationStep } from "../../MobileNumberVerification";
import { SMSTOTPSender } from "james/security/verification/SMSTOTPSender";

export interface VerifyMobileNumberCardProps {
  phoneNumber: string;
  countryCode: CountryCode;
  setOTPValues(): [number, boolean];
  stopTimer: () => void;
  firstTimeLogin: boolean;
  isMobile: boolean;
  setViewState: Dispatch<SetStateAction<MobileNumberVerificationStep>>;
  closeDialog?: Dispatch<SetStateAction<boolean>>;
  setVerifiedNumber?: Dispatch<SetStateAction<boolean>>;
}

export function VerifyMobileNumberCard({
  phoneNumber,
  countryCode,
  setOTPValues,
  stopTimer,
  firstTimeLogin,
  isMobile,
  setViewState,
  closeDialog,
  setVerifiedNumber,
}: VerifyMobileNumberCardProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const [loading, setLoading] = useState(false);
  const [timerValue, timerCompleted] = setOTPValues();
  const [openDialog, setOpenDialog] = useState(true);
  const [error, setError] = useState(false);

  const postProcessedPhoneNumber =
    "+" +
    getCountryCallingCode(countryCode) +
    (phoneNumber.replace(/\D/g, "").length === 10
      ? // if the length is 10 characters then strip off the leading 0
        phoneNumber.replace(/\D/g, "").slice(1)
      : // otherwise do nothing
        phoneNumber.replace(/\D/g, ""));

  const handleVerifyPhoneNumber = async (otp: string) => {
    setLoading(true);
    try {
      await TOTPVerifier.VerifyPhoneNumber({
        context: authContext,
        phoneNumber: postProcessedPhoneNumber,
        totp: otp,
      });
      enqueueSnackbar("Verification Successful", {
        variant: "success",
      });
      stopTimer();
      if (!firstTimeLogin) {
        if (setVerifiedNumber) {
          setVerifiedNumber(true);
        }
        if (closeDialog) {
          closeDialog(false);
        }
        setOpenDialog(false);
      } else {
        navigate("/market/markets");
      }
    } catch (e) {
      setError(true);
      errorContextDefaultErrorFeedback(e);
    }
    setLoading(false);
  };

  const handleSendVerificationCode = async () => {
    setLoading(true);
    try {
      await SMSTOTPSender.SendSMSTOTP({
        context: authContext,
        phoneNumber: postProcessedPhoneNumber,
      });
      enqueueSnackbar("Verification code sent", {
        variant: "success",
      });
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
    }
    setLoading(false);
  };
  if (!firstTimeLogin && isMobile) {
    return (
      <Drawer
        sx={{
          zIndex: 1400,
        }}
        className={"verify-mobile-number-drawer"}
        open={openDialog}
        anchor="bottom"
      >
        <DialogTitle sx={{ height: "52px" }}>
          <Typography fontSize="16px">Verify Your Mobile Number</Typography>
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  id="Verify-mobile-number-dialog-close-button"
                  size="small"
                  onClick={() => {
                    setOpenDialog(false);
                    if (closeDialog) {
                      closeDialog(false);
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            width: "100%",
            display: "grid",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(3),
          })}
        >
          <Typography
            fontSize="20px"
            sx={(theme) => ({
              padding: theme.spacing(3, 0, 4, 0),
              color: theme.palette.text.primary,
            })}
            fontWeight="600"
          >
            Let&apos;s secure your account
          </Typography>
          <Typography
            sx={(theme) => ({
              alignSelf: "center",
              color: theme.palette.text.hint,
            })}
            fontSize="14px"
            fontWeight="400"
          >
            Enter the security code that was sent to
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Typography
              sx={(theme) => ({
                alignSelf: "center",
                color: theme.palette.text.primary,
                padding: theme.spacing(0, 1, 4, 0),
              })}
            >
              {postProcessedPhoneNumber}.
            </Typography>
            <Link
              component="button"
              underline="none"
              variant="body2"
              style={{ height: "20px", alignSelf: "start" }}
              fontSize="14px"
              onClick={() =>
                setViewState(MobileNumberVerificationStep.sendVerificationCode)
              }
            >
              Edit Number
            </Link>
          </Box>
          <Box
            sx={(theme) => ({
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: theme.spacing(8.25),
              overflowX: "hidden",
            })}
          >
            <OTPInputComponent
              error={error}
              onChange={async (value) => {
                setError(false);
                if (value.length === 6) {
                  await handleVerifyPhoneNumber(value);
                }
              }}
            />
          </Box>
          <Button
            sx={(theme) => ({
              height: theme.spacing(6),
              width: "95%",
              ml: theme.spacing(1),
            })}
            id="mobileNumberVerification-verify-number-button"
            disabled={loading || timerValue !== 0}
            variant="contained"
            color="secondary"
            onClick={handleSendVerificationCode}
            size="large"
          >
            {!timerCompleted
              ? `Resend Code (${timerValue === 0 ? `` : `${Math.round(timerValue)}`}s)`
              : "Resend Code"}
          </Button>
          <Link
            id="mobileNumberVerification-faq-link"
            sx={(theme) => ({
              fontWeight: "bold",
              padding: theme.spacing(3, 0, 1),
            })}
            component="a"
            href="https://support.mesh.trade/support/solutions/articles/80001161880-what-should-i-do-if-i-m-not-receiving-my-otp-code-"
            fontSize="16px"
            target="_blank"
            underline="hover"
            color="secondary"
          >
            Didn&apos;t Receive the Code?
          </Link>
        </DialogContent>
      </Drawer>
    );
  }

  if (!firstTimeLogin) {
    return (
      <Dialog
        className={"verify-mobile-number-dialog"}
        open={openDialog}
        sx={{ width: "100%", justifySelf: "center" }}
      >
        <DialogTitle sx={{ height: "60px" }}>
          <Typography fontSize="16px">Verify Your Mobile Number</Typography>
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  id="Verify-mobile-number-dialog-close-button"
                  size="small"
                  onClick={() => {
                    setOpenDialog(false);
                    if (closeDialog) {
                      closeDialog(false);
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            width: "392px",
            height: "407px",
            display: "grid",
            margin: theme.spacing(1),
          })}
        >
          <Typography
            fontSize="20px"
            sx={(theme) => ({
              padding: theme.spacing(3, 0, 4, 0),
              color: theme.palette.text.primary,
            })}
            fontWeight="600"
          >
            Let&apos;s secure your account
          </Typography>
          <Typography
            sx={(theme) => ({
              alignSelf: "center",
              color: theme.palette.text.hint,
            })}
            fontSize="14px"
            fontWeight="400"
          >
            Enter the security code that was sent to
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Typography
              sx={(theme) => ({
                alignSelf: "center",
                color: theme.palette.text.primary,
                padding: theme.spacing(0, 1, 4, 0),
              })}
            >
              {postProcessedPhoneNumber}.
            </Typography>
            <Link
              component="button"
              underline="none"
              variant="body2"
              style={{ height: "20px", alignSelf: "start" }}
              fontSize="14px"
              onClick={() =>
                setViewState(MobileNumberVerificationStep.sendVerificationCode)
              }
            >
              Edit Number
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              overflowX: "hidden",
              width: "348px",
            }}
          >
            <OTPInputComponent
              error={error}
              onChange={async (value) => {
                setError(false);
                if (value.length === 6) {
                  await handleVerifyPhoneNumber(value);
                }
              }}
            />
          </Box>
          <Button
            sx={(theme) => ({
              height: theme.spacing(6),
              marginBottom: theme.spacing(1),
              justifySelf: "center",
              width: "200px",
            })}
            id="mobileNumberVerification-verify-number-button"
            disabled={loading || timerValue !== 0}
            variant="contained"
            onClick={handleSendVerificationCode}
            color="secondary"
            size="large"
          >
            {!timerCompleted
              ? `Resend Code (${timerValue === 0 ? `` : `${Math.round(timerValue)}`}s)`
              : "Resend Code"}
          </Button>
          <Link
            id="mobileNumberVerification-faq-link"
            sx={(theme) => ({
              fontWeight: "bold",
              padding: theme.spacing(3, 0, 1),
            })}
            component="a"
            href="https://support.mesh.trade/support/solutions/articles/80001161880-what-should-i-do-if-i-m-not-receiving-my-otp-code-"
            fontSize="16px"
            target="_blank"
            underline="hover"
            color="secondary"
          >
            Didn&apos;t Receive the Code?
          </Link>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <MobileNumberVerificationCard
      linksFooter={
        <Box
          sx={(theme) => ({
            height: theme.spacing(8),
            display: "flex",
            flexDirection: "column",
          })}
        >
          <Link
            id="mobileNumberVerification-faq-link"
            sx={(theme) => ({
              fontWeight: "bold",
              paddingBottom: theme.spacing(3),
            })}
            component="a"
            href="https://support.mesh.trade/support/solutions/articles/80001161880-what-should-i-do-if-i-m-not-receiving-my-otp-code-"
            fontSize="16px"
            target="_blank"
            underline="hover"
            color="secondary"
          >
            Didn&apos;t Receive the Code?
          </Link>
          <Link
            id="mobileNumberVerification-logout-link"
            sx={(theme) => ({
              fontWeight: "bold",
              marginBottom: theme.spacing(3),
            })}
            onClick={async () => {
              navigate("/market/markets");
            }}
            fontSize={"16px"}
            component="button"
            underline="hover"
            color="secondary"
          >
            Skip this Step
          </Link>
        </Box>
      }
    >
      <VerticalSlide transitionDuration={0.5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.primary,
            padding: theme.spacing(5, 0, 4, 0),
          })}
          fontSize="20px"
          fontWeight="600"
        >
          Verify your mobile number
        </Typography>
      </VerticalSlide>
      <VerticalSlide transitionDuration={0.5}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.hint,
          })}
          fontSize="14px"
          fontWeight="400"
        >
          Enter the security code that was sent to
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Typography
            sx={(theme) => ({
              alignSelf: "center",
              color: theme.palette.text.primary,
              padding: theme.spacing(0, 1, 4, 0),
            })}
          >
            {postProcessedPhoneNumber}.
          </Typography>
          <Link
            component="button"
            underline="none"
            variant="body2"
            style={{ height: "20px", alignSelf: "start" }}
            fontSize="14px"
            onClick={() =>
              setViewState(MobileNumberVerificationStep.sendVerificationCode)
            }
          >
            Edit Number
          </Link>
        </Box>
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={0.67}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <OTPInputComponent
            error={error}
            onChange={async (value) => {
              setError(false);
              if (value.length === 6) {
                await handleVerifyPhoneNumber(value);
              }
            }}
          />
        </Box>
      </VerticalSlide>
      <VerticalSlide width="100%" transitionDuration={1}>
        <Button
          sx={(theme) => ({
            height: theme.spacing(6),
            width: "100%",
            marginBottom: theme.spacing(21),
          })}
          id="mobileNumberVerification-verify-number-button"
          disabled={loading || timerValue !== 0}
          variant="contained"
          onClick={handleSendVerificationCode}
          color="secondary"
          size="large"
        >
          {timerCompleted
            ? "Resend Code"
            : `Resend Code (${timerValue === 0 ? `` : `${Math.round(timerValue)}`}s)`}
        </Button>
      </VerticalSlide>
    </MobileNumberVerificationCard>
  );
}
