// source: legal/person.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var audit_entry_pb = require('../audit/entry_pb.js');
goog.object.extend(proto, audit_entry_pb);
goog.exportSymbol('proto.legal.Address', null, global);
goog.exportSymbol('proto.legal.CellphoneNumber', null, global);
goog.exportSymbol('proto.legal.ContactDetails', null, global);
goog.exportSymbol('proto.legal.IdentificationType', null, global);
goog.exportSymbol('proto.legal.Person', null, global);
goog.exportSymbol('proto.legal.SourceOfIncomeAndWealthType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.legal.Person = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.legal.Person, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.legal.Person.displayName = 'proto.legal.Person';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.legal.ContactDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.legal.ContactDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.legal.ContactDetails.displayName = 'proto.legal.ContactDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.legal.CellphoneNumber = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.legal.CellphoneNumber, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.legal.CellphoneNumber.displayName = 'proto.legal.CellphoneNumber';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.legal.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.legal.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.legal.Address.displayName = 'proto.legal.Address';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.legal.Person.prototype.toObject = function(opt_includeInstance) {
  return proto.legal.Person.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.legal.Person} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.legal.Person.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
auditentry: (f = msg.getAuditentry()) && audit_entry_pb.Entry.toObject(includeInstance, f),
firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
middlenames: jspb.Message.getFieldWithDefault(msg, 4, ""),
lastname: jspb.Message.getFieldWithDefault(msg, 5, ""),
nationality: jspb.Message.getFieldWithDefault(msg, 6, ""),
identificationtype: jspb.Message.getFieldWithDefault(msg, 7, 0),
influentialperson: jspb.Message.getFieldWithDefault(msg, 8, ""),
identificationnumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
identificationnumberexpiry: jspb.Message.getFieldWithDefault(msg, 10, ""),
dateofbirth: jspb.Message.getFieldWithDefault(msg, 11, ""),
taxreference: jspb.Message.getFieldWithDefault(msg, 12, ""),
sarstaxdeclaration: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
contactdetails: (f = msg.getContactdetails()) && proto.legal.ContactDetails.toObject(includeInstance, f),
residentialaddress: (f = msg.getResidentialaddress()) && proto.legal.Address.toObject(includeInstance, f),
sourceofwealth: jspb.Message.getFieldWithDefault(msg, 16, 0),
sourceofincome: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.legal.Person}
 */
proto.legal.Person.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.legal.Person;
  return proto.legal.Person.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.legal.Person} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.legal.Person}
 */
proto.legal.Person.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new audit_entry_pb.Entry;
      reader.readMessage(value,audit_entry_pb.Entry.deserializeBinaryFromReader);
      msg.setAuditentry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlenames(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 7:
      var value = /** @type {!proto.legal.IdentificationType} */ (reader.readEnum());
      msg.setIdentificationtype(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfluentialperson(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentificationnumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentificationnumberexpiry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateofbirth(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxreference(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSarstaxdeclaration(value);
      break;
    case 14:
      var value = new proto.legal.ContactDetails;
      reader.readMessage(value,proto.legal.ContactDetails.deserializeBinaryFromReader);
      msg.setContactdetails(value);
      break;
    case 15:
      var value = new proto.legal.Address;
      reader.readMessage(value,proto.legal.Address.deserializeBinaryFromReader);
      msg.setResidentialaddress(value);
      break;
    case 16:
      var value = /** @type {!proto.legal.SourceOfIncomeAndWealthType} */ (reader.readEnum());
      msg.setSourceofwealth(value);
      break;
    case 17:
      var value = /** @type {!proto.legal.SourceOfIncomeAndWealthType} */ (reader.readEnum());
      msg.setSourceofincome(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.legal.Person.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.legal.Person.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.legal.Person} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.legal.Person.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditentry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      audit_entry_pb.Entry.serializeBinaryToWriter
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMiddlenames();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNationality();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIdentificationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getInfluentialperson();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIdentificationnumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIdentificationnumberexpiry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDateofbirth();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTaxreference();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSarstaxdeclaration();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getContactdetails();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.legal.ContactDetails.serializeBinaryToWriter
    );
  }
  f = message.getResidentialaddress();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.legal.Address.serializeBinaryToWriter
    );
  }
  f = message.getSourceofwealth();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getSourceofincome();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.legal.Person.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional audit.Entry auditEntry = 2;
 * @return {?proto.audit.Entry}
 */
proto.legal.Person.prototype.getAuditentry = function() {
  return /** @type{?proto.audit.Entry} */ (
    jspb.Message.getWrapperField(this, audit_entry_pb.Entry, 2));
};


/**
 * @param {?proto.audit.Entry|undefined} value
 * @return {!proto.legal.Person} returns this
*/
proto.legal.Person.prototype.setAuditentry = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.clearAuditentry = function() {
  return this.setAuditentry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.legal.Person.prototype.hasAuditentry = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.legal.Person.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string middleNames = 4;
 * @return {string}
 */
proto.legal.Person.prototype.getMiddlenames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setMiddlenames = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastName = 5;
 * @return {string}
 */
proto.legal.Person.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string nationality = 6;
 * @return {string}
 */
proto.legal.Person.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setNationality = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional IdentificationType identificationType = 7;
 * @return {!proto.legal.IdentificationType}
 */
proto.legal.Person.prototype.getIdentificationtype = function() {
  return /** @type {!proto.legal.IdentificationType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.legal.IdentificationType} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setIdentificationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string influentialPerson = 8;
 * @return {string}
 */
proto.legal.Person.prototype.getInfluentialperson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setInfluentialperson = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string identificationNumber = 9;
 * @return {string}
 */
proto.legal.Person.prototype.getIdentificationnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setIdentificationnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string identificationNumberExpiry = 10;
 * @return {string}
 */
proto.legal.Person.prototype.getIdentificationnumberexpiry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setIdentificationnumberexpiry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string dateOfBirth = 11;
 * @return {string}
 */
proto.legal.Person.prototype.getDateofbirth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setDateofbirth = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string taxReference = 12;
 * @return {string}
 */
proto.legal.Person.prototype.getTaxreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setTaxreference = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool sarsTaxDeclaration = 13;
 * @return {boolean}
 */
proto.legal.Person.prototype.getSarstaxdeclaration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setSarstaxdeclaration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional ContactDetails contactDetails = 14;
 * @return {?proto.legal.ContactDetails}
 */
proto.legal.Person.prototype.getContactdetails = function() {
  return /** @type{?proto.legal.ContactDetails} */ (
    jspb.Message.getWrapperField(this, proto.legal.ContactDetails, 14));
};


/**
 * @param {?proto.legal.ContactDetails|undefined} value
 * @return {!proto.legal.Person} returns this
*/
proto.legal.Person.prototype.setContactdetails = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.clearContactdetails = function() {
  return this.setContactdetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.legal.Person.prototype.hasContactdetails = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Address residentialAddress = 15;
 * @return {?proto.legal.Address}
 */
proto.legal.Person.prototype.getResidentialaddress = function() {
  return /** @type{?proto.legal.Address} */ (
    jspb.Message.getWrapperField(this, proto.legal.Address, 15));
};


/**
 * @param {?proto.legal.Address|undefined} value
 * @return {!proto.legal.Person} returns this
*/
proto.legal.Person.prototype.setResidentialaddress = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.clearResidentialaddress = function() {
  return this.setResidentialaddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.legal.Person.prototype.hasResidentialaddress = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional SourceOfIncomeAndWealthType sourceOfWealth = 16;
 * @return {!proto.legal.SourceOfIncomeAndWealthType}
 */
proto.legal.Person.prototype.getSourceofwealth = function() {
  return /** @type {!proto.legal.SourceOfIncomeAndWealthType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.legal.SourceOfIncomeAndWealthType} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setSourceofwealth = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional SourceOfIncomeAndWealthType sourceOfIncome = 17;
 * @return {!proto.legal.SourceOfIncomeAndWealthType}
 */
proto.legal.Person.prototype.getSourceofincome = function() {
  return /** @type {!proto.legal.SourceOfIncomeAndWealthType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.legal.SourceOfIncomeAndWealthType} value
 * @return {!proto.legal.Person} returns this
 */
proto.legal.Person.prototype.setSourceofincome = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.legal.ContactDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.legal.ContactDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.legal.ContactDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.legal.ContactDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
emailaddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
telephonenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
cellphonenumber: (f = msg.getCellphonenumber()) && proto.legal.CellphoneNumber.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.legal.ContactDetails}
 */
proto.legal.ContactDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.legal.ContactDetails;
  return proto.legal.ContactDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.legal.ContactDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.legal.ContactDetails}
 */
proto.legal.ContactDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephonenumber(value);
      break;
    case 3:
      var value = new proto.legal.CellphoneNumber;
      reader.readMessage(value,proto.legal.CellphoneNumber.deserializeBinaryFromReader);
      msg.setCellphonenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.legal.ContactDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.legal.ContactDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.legal.ContactDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.legal.ContactDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTelephonenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCellphonenumber();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.legal.CellphoneNumber.serializeBinaryToWriter
    );
  }
};


/**
 * optional string emailAddress = 1;
 * @return {string}
 */
proto.legal.ContactDetails.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.ContactDetails} returns this
 */
proto.legal.ContactDetails.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string telephoneNumber = 2;
 * @return {string}
 */
proto.legal.ContactDetails.prototype.getTelephonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.ContactDetails} returns this
 */
proto.legal.ContactDetails.prototype.setTelephonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CellphoneNumber cellphoneNumber = 3;
 * @return {?proto.legal.CellphoneNumber}
 */
proto.legal.ContactDetails.prototype.getCellphonenumber = function() {
  return /** @type{?proto.legal.CellphoneNumber} */ (
    jspb.Message.getWrapperField(this, proto.legal.CellphoneNumber, 3));
};


/**
 * @param {?proto.legal.CellphoneNumber|undefined} value
 * @return {!proto.legal.ContactDetails} returns this
*/
proto.legal.ContactDetails.prototype.setCellphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.legal.ContactDetails} returns this
 */
proto.legal.ContactDetails.prototype.clearCellphonenumber = function() {
  return this.setCellphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.legal.ContactDetails.prototype.hasCellphonenumber = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.legal.CellphoneNumber.prototype.toObject = function(opt_includeInstance) {
  return proto.legal.CellphoneNumber.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.legal.CellphoneNumber} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.legal.CellphoneNumber.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFieldWithDefault(msg, 1, ""),
verified: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
resetreason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.legal.CellphoneNumber}
 */
proto.legal.CellphoneNumber.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.legal.CellphoneNumber;
  return proto.legal.CellphoneNumber.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.legal.CellphoneNumber} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.legal.CellphoneNumber}
 */
proto.legal.CellphoneNumber.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerified(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetreason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.legal.CellphoneNumber.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.legal.CellphoneNumber.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.legal.CellphoneNumber} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.legal.CellphoneNumber.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVerified();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getResetreason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.legal.CellphoneNumber.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.CellphoneNumber} returns this
 */
proto.legal.CellphoneNumber.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool verified = 2;
 * @return {boolean}
 */
proto.legal.CellphoneNumber.prototype.getVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.legal.CellphoneNumber} returns this
 */
proto.legal.CellphoneNumber.prototype.setVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string resetReason = 3;
 * @return {string}
 */
proto.legal.CellphoneNumber.prototype.getResetreason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.CellphoneNumber} returns this
 */
proto.legal.CellphoneNumber.prototype.setResetreason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.legal.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.legal.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.legal.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.legal.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
addressline1: jspb.Message.getFieldWithDefault(msg, 1, ""),
addressline2: jspb.Message.getFieldWithDefault(msg, 2, ""),
suburb: jspb.Message.getFieldWithDefault(msg, 3, ""),
city: jspb.Message.getFieldWithDefault(msg, 4, ""),
province: jspb.Message.getFieldWithDefault(msg, 5, ""),
countrycode: jspb.Message.getFieldWithDefault(msg, 6, ""),
postalcode: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.legal.Address}
 */
proto.legal.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.legal.Address;
  return proto.legal.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.legal.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.legal.Address}
 */
proto.legal.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressline1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressline2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuburb(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvince(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountrycode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.legal.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.legal.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.legal.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.legal.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressline1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressline2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSuburb();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProvince();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountrycode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string addressLine1 = 1;
 * @return {string}
 */
proto.legal.Address.prototype.getAddressline1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Address} returns this
 */
proto.legal.Address.prototype.setAddressline1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string addressLine2 = 2;
 * @return {string}
 */
proto.legal.Address.prototype.getAddressline2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Address} returns this
 */
proto.legal.Address.prototype.setAddressline2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string suburb = 3;
 * @return {string}
 */
proto.legal.Address.prototype.getSuburb = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Address} returns this
 */
proto.legal.Address.prototype.setSuburb = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.legal.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Address} returns this
 */
proto.legal.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string province = 5;
 * @return {string}
 */
proto.legal.Address.prototype.getProvince = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Address} returns this
 */
proto.legal.Address.prototype.setProvince = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string countryCode = 6;
 * @return {string}
 */
proto.legal.Address.prototype.getCountrycode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Address} returns this
 */
proto.legal.Address.prototype.setCountrycode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postalCode = 7;
 * @return {string}
 */
proto.legal.Address.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.legal.Address} returns this
 */
proto.legal.Address.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * @enum {number}
 */
proto.legal.IdentificationType = {
  SOUTH_AFRICAN_ID_IDENTIFICATION_TYPE: 0,
  PASSPORT_IDENTIFICATION_TYPE: 1
};

/**
 * @enum {number}
 */
proto.legal.SourceOfIncomeAndWealthType = {
  ALLOWANCES: 0,
  BURSARY: 1,
  COURT_ORDER: 2,
  LOAN_FINANCIAL_INSTITUTION: 3,
  LOAN_OTHER: 4,
  MAINTENANCE: 5,
  MATURING_INVESTMENT: 6,
  PENSION: 7,
  RENTAL_INCOME: 8,
  COMPANY_PROFITS: 9,
  COMPANY_SALE: 10,
  DECEASED_STATE: 11,
  DIVORCE_SETTLEMENT: 12,
  GIFT_OR_DONATION: 13,
  INCOME_FROM_EMPLOYMENT: 14,
  INCOME_FROM_PREVIOUS_EMPLOYMENT: 15,
  INHERITANCE: 16,
  SALE_OF_ASSET: 17,
  SALE_OF_SHARES: 18,
  SAVINGS_INVESTMENT_OR_DIVIDEND: 19,
  TRUST_DISTRIBUTIONS: 20,
  LOTTERY_WINNINGS_OR_GAMBLING: 21
};

goog.object.extend(exports, proto.legal);
