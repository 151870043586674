import { useState, useEffect, useCallback } from "react";
import { BigNumber } from "bignumber.js";
import { Amount } from "james/ledger/Amount";
import { formatTextNum } from "utilities/number/format";

const allRanges = [
  2000, 5000, 20000, 50000, 200000, 500000, 1000000, 2000000, 5000000, 10000000,
  20000000, 100000000,
];

export const useInvestmentRanges = (
  minOrderAmount: Amount,
  targetRaiseAmount: Amount,
) => {
  const [investmentRanges, setInvestmentRanges] = useState<string[]>([]);

  const calculateRanges = useCallback(() => {
    const ranges = [];
    const numberofRanges = 7;
    let maximumRangeIdx = allRanges.length - 1;
    let firstRangeIdx = 0;
    let finalRangeIdx = numberofRanges - 1;

    // Determine valid range indices
    for (let i = 0; i < allRanges.length; i++) {
      if (minOrderAmount.value.toNumber() > allRanges[i] * 0.5) {
        firstRangeIdx = i + 1;
        finalRangeIdx = i + numberofRanges;
      }

      if (allRanges[i] > targetRaiseAmount.value.toNumber() * 0.5) {
        maximumRangeIdx = i - 1;
        break;
      }
    }

    if (finalRangeIdx > maximumRangeIdx) {
      finalRangeIdx = maximumRangeIdx;
    }
    // Build the investment ranges
    if (firstRangeIdx < finalRangeIdx) {
      const firstMinRangeLabel = `${formatTextNum(minOrderAmount.value)} ${minOrderAmount.token.code}`;
      const firstMaxRangeLabel = `${formatTextNum(new BigNumber(allRanges[firstRangeIdx]))} ${minOrderAmount.token.code}`;
      const firstInvestmentRangeLabel = `${firstMinRangeLabel} - ${firstMaxRangeLabel}`;
      ranges.push(firstInvestmentRangeLabel);
    }

    for (let i = firstRangeIdx; i < finalRangeIdx; i++) {
      const minRangeLabel = `${formatTextNum(new BigNumber(allRanges[i]))} ${minOrderAmount.token.code}`;
      const maxRangeLabel = `${formatTextNum(new BigNumber(allRanges[i + 1]))} ${minOrderAmount.token.code}`;
      const investmentRangeLabel = `${minRangeLabel} - ${maxRangeLabel}`;
      ranges.push(investmentRangeLabel);
    }

    const finalInvestmentRangeSign =
      minOrderAmount.value.toNumber() === targetRaiseAmount.value.toNumber()
        ? ""
        : "+";
    const finalInvestmentRangeLabel = `${formatTextNum(new BigNumber(allRanges[finalRangeIdx]))} ${minOrderAmount.token.code} ${finalInvestmentRangeSign}`;
    ranges.push(finalInvestmentRangeLabel);

    setInvestmentRanges(ranges);
  }, [minOrderAmount, targetRaiseAmount]);

  useEffect(() => {
    calculateRanges();
  }, [calculateRanges]);

  return investmentRanges;
};
