import React, { ReactNode, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { SubscribeButton } from "components/Buttons/SubscriptionButton/SubscriptionConfirmationButton";
import { Amount } from "components/Ledger/Amount";
import { Amount as AmountType } from "james/ledger";
import { TermsAndConditionsSection } from "../TermsAndConditionsSection";
import { AssetType } from "james/views/marketListingView/Model";
import { Unit } from "@mesh/common-js/dist/financial/unit_pb";

export interface FixedPriceConfirmationDialogProps {
  open: boolean;
  smDown: boolean;
  subscriptionTicketHeader: ReactNode;
  subscribe: () => void;
  subscriptionAmount: AmountType;
  investmentAmount: AmountType;
  numberOfUnits: AmountType;
  assetType: AssetType | "";
  assetUnit: Unit;
  assetUnitLabel: string;
  closeDialog: () => void;
  submittingOrder: boolean;
  height: string;
  assetName: string;
  privateOffer: boolean;
}

export const FixedPriceConfirmationDialog = ({
  open,
  smDown,
  subscriptionTicketHeader,
  subscribe,
  investmentAmount,
  subscriptionAmount,
  numberOfUnits,
  assetType,
  assetUnit,
  assetUnitLabel,
  closeDialog,
  submittingOrder,
  height,
  assetName,
  privateOffer,
}: FixedPriceConfirmationDialogProps) => {
  const [termsSelected, setTermsSelected] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);

  return (
    <Dialog
      open={open}
      fullScreen={smDown}
      PaperProps={{
        sx: {
          width: { sm: 450 },
          minHeight: { sm: height, xs: "auto" },
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {subscriptionTicketHeader}
      <DialogContent
        className="meshScroll"
        sx={{ flexGrow: 1, overflowY: "auto", px: { sm: 6, xs: 3 } }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.custom.cardInner,
            my: 3,
          })}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", fontSize: "16px" }}
          >
            Confirm Order
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              children={`Number of ${assetUnitLabel}s`}
            />
            <Typography sx={{ mt: 0.5, fontSize: "16px", fontWeight: "bold" }}>
              {numberOfUnits.toString().split(" ")[1]}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              children={`Price per ${assetUnitLabel}`}
            />
            <Amount
              valueTypographyProps={{
                sx: {
                  fontWeight: "bold",
                  mt: 0.5,
                  fontSize: "16px",
                },
                noWrap: true,
              }}
              codeTypographyProps={{
                sx: {
                  mt: 0.5,
                  fontSize: "16px",
                },
              }}
              amount={subscriptionAmount}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              Investment Amount
            </Typography>
            <Amount
              codeTypographyProps={{
                variant: "body1",
                sx: (theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.custom.yellow,
                  mt: 0.5,
                  fontSize: "16px",
                }),
              }}
              valueTypographyProps={{
                variant: "body1",
                sx: (theme) => ({
                  color: theme.palette.warning.light,
                  fontWeight: "bold",
                  mt: 0.5,
                  fontSize: "16px",
                }),
              }}
              amount={investmentAmount}
            />
          </Box>
        </Box>
      </DialogContent>
      <TermsAndConditionsSection
        submittingOrder={submittingOrder}
        assetType={assetType}
        assetUnit={assetUnit}
        accepted={termsAgreed}
        setAccepted={setTermsAgreed}
        displayed={termsSelected}
        setDisplayed={setTermsSelected}
        privateOffer={privateOffer}
      />
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.midnight,
        })}
      >
        {!smDown && <Divider />}
      </Box>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          px: { sm: 6, xs: 3 },
          pb: 4,
          pt: 3,
          boxShadow: {
            xs: "0px 0px 18px 4px rgba(0,0,0,0.4);",
            sm: 0,
          },
          gap: 2,
          zIndex: 1,
          minHeight: "89px",
        }}
      >
        <Button
          sx={{
            width: { sm: "168px", xs: "50%" },
            border: 1,
          }}
          size={smDown ? "large" : "medium"}
          variant="outlined"
          onClick={closeDialog}
          disabled={submittingOrder}
        >
          Back
        </Button>
        <SubscribeButton
          onClick={subscribe}
          buttonDisabled={submittingOrder || !termsAgreed}
          width={smDown ? "50%" : "168px"}
          title={(() => {
            if (!termsAgreed) {
              return "You need to agree to terms and conditions";
            }
            return "";
          })()}
          smDown={smDown}
          fullWidth={false}
          assetName={assetName}
        />
      </DialogTitle>
    </Dialog>
  );
};
