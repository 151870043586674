import React from "react";
import { AssetSectorAllocationsAndHoldingsDataSheetSection } from "james/marketData";
import { SectorAllocationPieChart } from "views/InstrumentBuilder/Instruments/SectorAllocationPieChart";
import { Box, Typography, useMediaQuery, Theme } from "@mui/material";

interface SectorAllocationsProps {
  dataSheetSection: AssetSectorAllocationsAndHoldingsDataSheetSection;
}

export function AssetSectorAllocationsAndHoldingsDataSheetSectionRenderer(
  props: SectorAllocationsProps,
) {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const pieChartHeight = smDown ? 140 : 188;

  return smDown ? (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(4),
        padding: theme.spacing(2, 3, 2, 0),
      })}
    >
      <Typography variant={"h6"}>Sector Allocations</Typography>
      <SectorAllocationPieChart
        sectorAllocations={props.dataSheetSection.sectorAllocations}
        height={pieChartHeight}
        showLegend
      />
      <Typography variant={"h6"}>Holdings</Typography>
      <Box
        sx={{
          height: "160px",
          overflowY: "auto",
          width: "100%",
        }}
        className={"meshScroll"}
      >
        {props.dataSheetSection.holdings.map((v, i) => (
          <Box
            key={i}
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gridColumnGap: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              marginBottom: theme.spacing(1),
              borderBottom: `solid 0.5px ${theme.palette.divider}`,
            })}
          >
            <Typography variant={"body1"}>
              {v.percentage.toString()}%
            </Typography>
            <Typography variant={"body1"} sx={{ color: "text.disabled" }}>
              {v.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        margin: theme.spacing(3, 3, 3, 0),
        columnGap: theme.spacing(2),
      })}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "auto 1fr",
          alignItems: "center",
        }}
      >
        <Typography variant={"h6"}>Sector Allocations</Typography>
        <SectorAllocationPieChart
          sectorAllocations={props.dataSheetSection.sectorAllocations}
          height={pieChartHeight}
          showLegend
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "auto 1fr",
          gap: 3,
          borderLeft: `solid 0.5px ${theme.palette.secondary.main}`,
          paddingLeft: theme.spacing(4),
        })}
      >
        <Typography variant={"h6"}>Holdings</Typography>
        <Box
          sx={{
            height: "180px",
            overflowY: "auto",
            pr: 1,
          }}
          className={"meshScroll"}
        >
          {props.dataSheetSection.holdings.map((v, i) => (
            <Box
              key={i}
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "50px 1fr",
                alignItems: "center",
                gridColumnGap: theme.spacing(1),
                pb: 1,
                mb: 1,
                borderBottom: `solid 0.5px ${theme.palette.divider}`,
              })}
            >
              <Typography variant={"body1"}>
                {v.percentage.toString()}%
              </Typography>
              <Typography variant={"body1"} sx={{ color: "text.disabled" }}>
                {v.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
