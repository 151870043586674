import React, { useCallback, useContext, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import { MFADialog } from "./MFADialog";
import { PermissionFuture } from "@mesh/common-js/src/security/permission_pb";
import {
  GetMFATokenForMyUserRequest,
  GetMFATokenForMyUserResponse,
  RequestMFAForMyUserRequest,
} from "@mesh/common-js/src/security/authentication/mfaService_pb";
import { User } from "@mesh/common-js/dist/iam/user_pb";
import { Context as AuthContext } from "james/security";
import { MFAServicePromiseClient } from "@mesh/common-js/src/security/authentication/mfaService_grpc_web_pb";
import { Request } from "grpc-web";
import { Person } from "@mesh/common-js/dist/legal/person_pb";
import { useTimer } from "hooks/useTimer";

export const ErrCancelled = "mfaCancelled"; // error message when MFA is cancelled

interface ContextType {
  performMFA: (request: Request<unknown, unknown>) => Promise<string>;
  setUserState: (myUser: User) => void;
  setAuthState: (authContext: AuthContext) => void;
  setPersonState: (personState: Person) => void;
  setAuthServiceProviderState: (
    authServiceProvider: MFAServicePromiseClient,
  ) => void;
}

// MFA context state
const MFAState = {
  None: 0,
  RequestingMFA: 1,
  RequestingUserMFAInput: 2,
  VerifyingMFACode: 3,
} as const;

const Context = React.createContext({} as ContextType);

// Function to check if any object from arr2 exists in arr1
function isObjectEqual(
  obj1: PermissionFuture,
  obj2: PermissionFuture,
): boolean {
  return obj1.getServicename() == obj2.getServicename();
}

// Function to check if any object from arr2 exists in arr1
function containsAny(
  arr1: PermissionFuture[],
  arr2: PermissionFuture[],
): boolean {
  return arr2.some((obj2) => arr1.some((obj1) => isObjectEqual(obj1, obj2)));
}

export function MFAContext({ children }: { children?: React.ReactNode }) {
  const [mfaState, setMFAState] = useState<number>(0);
  const [mfaTokenExpiry, setMFATokenExpiry] = useState<number>(0);
  const [mfaToken, setMFAToken] = useState<string>("");

  const [myUser, setMyUser] = useState<User | undefined>(undefined);
  const [myPerson, setMyPerson] = useState<Person | undefined>(undefined);
  const [authServiceProvider, setAuthServiceProvider] = useState<
    MFAServicePromiseClient | undefined
  >(undefined);
  const [authContext, setAuthContext] = useState<AuthContext | undefined>(
    undefined,
  );
  const { timerValue, timerCompleted, startTimer } = useTimer();

  const onSubmitMFACodeRef = useRef<(code: string) => void>(() => null);
  const onCancelRef = useRef<() => void>(() => null);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<boolean>(false);

  const [invokedService, setInvokedService] = useState("");

  const setOTPValues = (): [number, boolean] => {
    return [timerValue, timerCompleted];
  };

  const setAuthServiceProviderState = (
    authServiceProvider: MFAServicePromiseClient,
  ) => {
    setAuthServiceProvider(authServiceProvider);
  };

  const setUserState = (myUser: User) => {
    setMyUser(myUser);
  };

  const setPersonState = (myPerson: Person) => {
    setMyPerson(myPerson);
  };

  const setAuthContextState = (authContext: AuthContext) => {
    setAuthContext(authContext);
  };

  const isMFARequired = (permissions: PermissionFuture[]): boolean => {
    if (!myUser) {
      return false;
    }

    const requiredBasedOnPermission = containsAny(
      myUser?.getMfasettings()?.getEnabledforList() || [],
      permissions,
    );

    if (!requiredBasedOnPermission) {
      return false; // mfa not required
    }

    // check mfa token has expired
    return mfaTokenExpiry <= Date.now() / 1000;
  };

  const invokeRequestMFAService = async (
    authContext: AuthContext,
    authServiceProvider: MFAServicePromiseClient,
  ) => {
    try {
      // request MUA from authorising user
      await authServiceProvider.requestMFAForMyUser(
        new RequestMFAForMyUserRequest().setContext(authContext.toFuture()),
      );
    } catch (e) {
      Sentry.captureException(`failed to request MFA: ${e}`);
      enqueueSnackbar("failed to request MFA", {
        variant: "error",
        persist: true,
      });
      throw e;
    }
  };

  const invokeGetMFATokenService = async (
    code: string,
    authContext: AuthContext,
    authServiceProvider: MFAServicePromiseClient,
  ): Promise<GetMFATokenForMyUserResponse> => {
    try {
      return await authServiceProvider.getMFATokenForMyUser(
        new GetMFATokenForMyUserRequest()
          .setContext(authContext.toFuture())
          .setMfacode(code),
      );
    } catch (e) {
      Sentry.captureException(`failed to validate mfa code: ${e}`);
      throw e;
    }
  };

  const getMFAInputFromUser = async (): Promise<string> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<string>((resolve: any) => {
      onSubmitMFACodeRef.current = async (code: string) => {
        resolve(code);
      };

      onCancelRef.current = async () => {
        setError(false);
        resolve("");
      };

      setMFAState(MFAState.RequestingUserMFAInput);
    });
  };

  const performMFA = useCallback(
    async (request: Request<unknown, unknown>) => {
      if (authContext == undefined) {
        return Promise.resolve("");
      }

      if (authServiceProvider == undefined) {
        return Promise.reject("no auth service provider");
      }

      const serviceProviderService = request
        .getMethodDescriptor()
        .getName()
        .split("/");

      if (serviceProviderService.length != 3) {
        return Promise.resolve("");
      }

      const serviceProvider = serviceProviderService[1];
      const service = serviceProviderService[2];
      setInvokedService(service);

      const serviceProviderRef = serviceProvider.replace(".", "-");

      const perm = new PermissionFuture()
        .setServicename(service)
        .setServiceprovider(serviceProviderRef);

      // check if required
      if (!isMFARequired([perm])) {
        return Promise.resolve(mfaToken);
      }

      // mfa is required - request MFA from backed (send sms etc.)
      setMFAState(MFAState.RequestingMFA);
      try {
        await invokeRequestMFAService(authContext, authServiceProvider);
      } catch (e) {
        setMFAState(MFAState.None);
        return Promise.reject(e);
      }

      startTimer(15);

      // enter for loop
      let getUserInputAttempts = 0;
      while (getUserInputAttempts < 5) {
        getUserInputAttempts++;
        // request MFA code from user
        const code = await getMFAInputFromUser();

        if (code == "") {
          // this means the user cancelled
          setMFAState(MFAState.None);
          return Promise.reject(ErrCancelled);
        }

        // verify MFA code from user
        setMFAState(MFAState.VerifyingMFACode);
        try {
          const getMFATokenResponse = await invokeGetMFATokenService(
            code,
            authContext,
            authServiceProvider,
          );

          // mfa code valid - resolve
          setMFATokenExpiry(getMFATokenResponse.getExpiry());
          setMFAToken(getMFATokenResponse.getToken());
          setMFAState(MFAState.None);
          return Promise.resolve(getMFATokenResponse.getToken());
        } catch (e) {
          setError(true);
          console.error("error validating mfa code", e);
        }
      }
      setMFAState(MFAState.None);
      return "";
    },
    [
      authContext,
      authServiceProvider,
      mfaToken,
      mfaTokenExpiry,
      MFAState,
      myUser,
      myPerson,
    ],
  );

  const handleResend = async () => {
    if (authContext == undefined) {
      return Promise.resolve("");
    }

    if (authServiceProvider == undefined) {
      return Promise.reject("no auth service provider");
    }

    setMFAState(MFAState.RequestingMFA);
    setError(false);
    try {
      await invokeRequestMFAService(authContext, authServiceProvider);
    } catch (e) {
      setMFAState(MFAState.None);
    }

    // request MFA code from user
    startTimer(15);
    setMFAState(MFAState.RequestingUserMFAInput);
  };

  return (
    <Context.Provider
      value={{
        performMFA: performMFA,
        setUserState: setUserState,
        setPersonState: setPersonState,
        setAuthState: setAuthContextState,
        setAuthServiceProviderState: setAuthServiceProviderState,
      }}
    >
      <MFADialog
        resendCode={handleResend}
        setOTPValues={setOTPValues}
        open={
          mfaState === MFAState.RequestingUserMFAInput ||
          mfaState === MFAState.VerifyingMFACode ||
          mfaState === MFAState.RequestingMFA
        }
        loading={
          mfaState === MFAState.VerifyingMFACode ||
          mfaState === MFAState.RequestingMFA
        }
        cellPhoneNumber={myPerson
          ?.getContactdetails()
          ?.getCellphonenumber()
          ?.getValue()}
        method={invokedService}
        onSubmit={onSubmitMFACodeRef.current}
        onCancel={onCancelRef.current}
        error={error}
      />
      {children}
    </Context.Provider>
  );
}

const useMFAContext = () => useContext(Context);
export { useMFAContext };
