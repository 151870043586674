/**
 * @fileoverview gRPC-Web generated client stub for ledger
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: ledger/transactionFailureAnalyser.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var ledger_transactionFailureReason_pb = require('../ledger/transactionFailureReason_pb.js')

var ledger_transaction_pb = require('../ledger/transaction_pb.js')
const proto = {};
proto.ledger = require('./transactionFailureAnalyser_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ledger.TransactionFailureAnalyserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ledger.TransactionFailureAnalyserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ledger.AnalyseFailedTransactionIDRequest,
 *   !proto.ledger.AnalyseFailedTransactionIDResponse>}
 */
const methodDescriptor_TransactionFailureAnalyser_AnalyseFailedTransactionID = new grpc.web.MethodDescriptor(
  '/ledger.TransactionFailureAnalyser/AnalyseFailedTransactionID',
  grpc.web.MethodType.UNARY,
  proto.ledger.AnalyseFailedTransactionIDRequest,
  proto.ledger.AnalyseFailedTransactionIDResponse,
  /**
   * @param {!proto.ledger.AnalyseFailedTransactionIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ledger.AnalyseFailedTransactionIDResponse.deserializeBinary
);


/**
 * @param {!proto.ledger.AnalyseFailedTransactionIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ledger.AnalyseFailedTransactionIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ledger.AnalyseFailedTransactionIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ledger.TransactionFailureAnalyserClient.prototype.analyseFailedTransactionID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ledger.TransactionFailureAnalyser/AnalyseFailedTransactionID',
      request,
      metadata || {},
      methodDescriptor_TransactionFailureAnalyser_AnalyseFailedTransactionID,
      callback);
};


/**
 * @param {!proto.ledger.AnalyseFailedTransactionIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ledger.AnalyseFailedTransactionIDResponse>}
 *     Promise that resolves to the response
 */
proto.ledger.TransactionFailureAnalyserPromiseClient.prototype.analyseFailedTransactionID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ledger.TransactionFailureAnalyser/AnalyseFailedTransactionID',
      request,
      metadata || {},
      methodDescriptor_TransactionFailureAnalyser_AnalyseFailedTransactionID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ledger.AnalyseFailedTransactionRequest,
 *   !proto.ledger.AnalyseFailedTransactionResponse>}
 */
const methodDescriptor_TransactionFailureAnalyser_AnalyseFailedTransaction = new grpc.web.MethodDescriptor(
  '/ledger.TransactionFailureAnalyser/AnalyseFailedTransaction',
  grpc.web.MethodType.UNARY,
  proto.ledger.AnalyseFailedTransactionRequest,
  proto.ledger.AnalyseFailedTransactionResponse,
  /**
   * @param {!proto.ledger.AnalyseFailedTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ledger.AnalyseFailedTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.ledger.AnalyseFailedTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ledger.AnalyseFailedTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ledger.AnalyseFailedTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ledger.TransactionFailureAnalyserClient.prototype.analyseFailedTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ledger.TransactionFailureAnalyser/AnalyseFailedTransaction',
      request,
      metadata || {},
      methodDescriptor_TransactionFailureAnalyser_AnalyseFailedTransaction,
      callback);
};


/**
 * @param {!proto.ledger.AnalyseFailedTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ledger.AnalyseFailedTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.ledger.TransactionFailureAnalyserPromiseClient.prototype.analyseFailedTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ledger.TransactionFailureAnalyser/AnalyseFailedTransaction',
      request,
      metadata || {},
      methodDescriptor_TransactionFailureAnalyser_AnalyseFailedTransaction);
};


module.exports = proto.ledger;

