/**
 * @fileoverview gRPC-Web generated client stub for banking
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: banking/defundOrderReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var banking_defundOrder_pb = require('../banking/defundOrder_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var banking_defundOrderState_pb = require('../banking/defundOrderState_pb.js')
const proto = {};
proto.banking = require('./defundOrderReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.DefundOrderReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.DefundOrderReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ReadOneDefundOrderRequest,
 *   !proto.banking.ReadOneDefundOrderResponse>}
 */
const methodDescriptor_DefundOrderReader_ReadOneDefundOrder = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderReader/ReadOneDefundOrder',
  grpc.web.MethodType.UNARY,
  proto.banking.ReadOneDefundOrderRequest,
  proto.banking.ReadOneDefundOrderResponse,
  /**
   * @param {!proto.banking.ReadOneDefundOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.ReadOneDefundOrderResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ReadOneDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ReadOneDefundOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ReadOneDefundOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderReaderClient.prototype.readOneDefundOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderReader/ReadOneDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderReader_ReadOneDefundOrder,
      callback);
};


/**
 * @param {!proto.banking.ReadOneDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ReadOneDefundOrderResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderReaderPromiseClient.prototype.readOneDefundOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderReader/ReadOneDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderReader_ReadOneDefundOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ReadManyDefundOrderRequest,
 *   !proto.banking.ReadManyDefundOrderResponse>}
 */
const methodDescriptor_DefundOrderReader_ReadManyDefundOrder = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderReader/ReadManyDefundOrder',
  grpc.web.MethodType.UNARY,
  proto.banking.ReadManyDefundOrderRequest,
  proto.banking.ReadManyDefundOrderResponse,
  /**
   * @param {!proto.banking.ReadManyDefundOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.ReadManyDefundOrderResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ReadManyDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ReadManyDefundOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ReadManyDefundOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderReaderClient.prototype.readManyDefundOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderReader/ReadManyDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderReader_ReadManyDefundOrder,
      callback);
};


/**
 * @param {!proto.banking.ReadManyDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ReadManyDefundOrderResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderReaderPromiseClient.prototype.readManyDefundOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderReader/ReadManyDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderReader_ReadManyDefundOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ReadAllDefundOrderRequest,
 *   !proto.banking.ReadAllDefundOrderResponse>}
 */
const methodDescriptor_DefundOrderReader_ReadAllDefundOrder = new grpc.web.MethodDescriptor(
  '/banking.DefundOrderReader/ReadAllDefundOrder',
  grpc.web.MethodType.UNARY,
  proto.banking.ReadAllDefundOrderRequest,
  proto.banking.ReadAllDefundOrderResponse,
  /**
   * @param {!proto.banking.ReadAllDefundOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.ReadAllDefundOrderResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ReadAllDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ReadAllDefundOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ReadAllDefundOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.DefundOrderReaderClient.prototype.readAllDefundOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.DefundOrderReader/ReadAllDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderReader_ReadAllDefundOrder,
      callback);
};


/**
 * @param {!proto.banking.ReadAllDefundOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ReadAllDefundOrderResponse>}
 *     Promise that resolves to the response
 */
proto.banking.DefundOrderReaderPromiseClient.prototype.readAllDefundOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.DefundOrderReader/ReadAllDefundOrder',
      request,
      metadata || {},
      methodDescriptor_DefundOrderReader_ReadAllDefundOrder);
};


module.exports = proto.banking;

