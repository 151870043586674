/**
 * @fileoverview gRPC-Web generated client stub for bankingDefundOrderView
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: views/bankingDefundOrderView/reader.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var views_bankingDefundOrderView_model_pb = require('../../views/bankingDefundOrderView/model_pb.js')

var security_context_pb = require('../../security/context_pb.js')

var search_query_pb = require('../../search/query_pb.js')

var search_criterion_pb = require('../../search/criterion_pb.js')
const proto = {};
proto.bankingDefundOrderView = require('./reader_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.bankingDefundOrderView.ViewerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.bankingDefundOrderView.ViewerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bankingDefundOrderView.ReadManyRequest,
 *   !proto.bankingDefundOrderView.ReadManyResponse>}
 */
const methodDescriptor_Viewer_ReadMany = new grpc.web.MethodDescriptor(
  '/bankingDefundOrderView.Viewer/ReadMany',
  grpc.web.MethodType.UNARY,
  proto.bankingDefundOrderView.ReadManyRequest,
  proto.bankingDefundOrderView.ReadManyResponse,
  /**
   * @param {!proto.bankingDefundOrderView.ReadManyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankingDefundOrderView.ReadManyResponse.deserializeBinary
);


/**
 * @param {!proto.bankingDefundOrderView.ReadManyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.bankingDefundOrderView.ReadManyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.bankingDefundOrderView.ReadManyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bankingDefundOrderView.ViewerClient.prototype.readMany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bankingDefundOrderView.Viewer/ReadMany',
      request,
      metadata || {},
      methodDescriptor_Viewer_ReadMany,
      callback);
};


/**
 * @param {!proto.bankingDefundOrderView.ReadManyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.bankingDefundOrderView.ReadManyResponse>}
 *     Promise that resolves to the response
 */
proto.bankingDefundOrderView.ViewerPromiseClient.prototype.readMany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bankingDefundOrderView.Viewer/ReadMany',
      request,
      metadata || {},
      methodDescriptor_Viewer_ReadMany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bankingDefundOrderView.ReadOneRequest,
 *   !proto.bankingDefundOrderView.ReadOneResponse>}
 */
const methodDescriptor_Viewer_ReadOne = new grpc.web.MethodDescriptor(
  '/bankingDefundOrderView.Viewer/ReadOne',
  grpc.web.MethodType.UNARY,
  proto.bankingDefundOrderView.ReadOneRequest,
  proto.bankingDefundOrderView.ReadOneResponse,
  /**
   * @param {!proto.bankingDefundOrderView.ReadOneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankingDefundOrderView.ReadOneResponse.deserializeBinary
);


/**
 * @param {!proto.bankingDefundOrderView.ReadOneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.bankingDefundOrderView.ReadOneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.bankingDefundOrderView.ReadOneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bankingDefundOrderView.ViewerClient.prototype.readOne =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bankingDefundOrderView.Viewer/ReadOne',
      request,
      metadata || {},
      methodDescriptor_Viewer_ReadOne,
      callback);
};


/**
 * @param {!proto.bankingDefundOrderView.ReadOneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.bankingDefundOrderView.ReadOneResponse>}
 *     Promise that resolves to the response
 */
proto.bankingDefundOrderView.ViewerPromiseClient.prototype.readOne =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bankingDefundOrderView.Viewer/ReadOne',
      request,
      metadata || {},
      methodDescriptor_Viewer_ReadOne);
};


module.exports = proto.bankingDefundOrderView;

