import React from "react";
import {
  InteractionAction,
  InteractionType,
  InteractionDriver,
  DataLinkInfoType,
} from "const/gtm";
import { Button, Tooltip } from "@mui/material";

interface SubscribeButtonProps {
  onClick: () => void;
  buttonDisabled: boolean;
  title: string;
  width?: string;
  fullWidth?: boolean;
  height?: string;
  smDown: boolean;
  assetName: string;
}

export const SubscribeButton = ({
  onClick,
  buttonDisabled,
  title,
  width = "324px",
  fullWidth = false,
  smDown,
  assetName,
}: SubscribeButtonProps) => (
  <Tooltip placement={"bottom"} arrow title={title}>
    <span style={{ width: width }}>
      <Button
        fullWidth={fullWidth}
        id={"subscriptionTicketDialog-subscribe-button"}
        size={smDown ? "large" : "medium"}
        sx={{
          width: "100%",
          //quick fix I need to do a better soloution when I have time
          maxHeight: "37px",
          whiteSpace: "nowrap",
          //this is for the edge case of the fold
          "@media (max-width: 280px)": {
            whiteSpace: "wrap",
          },
        }}
        data-link-info={JSON.stringify({
          content_interaction_id: assetName + "_subscription_form_submit",
          content_interaction_action: InteractionAction.Click,
          content_interaction_type: InteractionType.Button,
          content_interaction_text: "Submit Order",
          content_interaction_driver: InteractionDriver.SubmitSubscription,
        } as DataLinkInfoType)}
        variant={"contained"}
        color={"primary"}
        children={"Submit Order"}
        disabled={buttonDisabled}
        onClick={onClick}
      />
    </span>
  </Tooltip>
);
