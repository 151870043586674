import React, { useEffect, useState } from "react";
import { NewSorting, Query } from "james/search/query";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {
  MarketSubscriptionOrderBookViewModel,
  MarketSubscriptionOrderBookViewUpdater,
  useMarketSubscriptionOrderBookViewReaderRead,
} from "james/views/marketSubscriptionOrderBookView";
import {
  AllSubscriptionOrderBookStates,
  SubscriptionOrderBookState,
} from "james/market/SubscriptionOrderBook";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { BPTable } from "components/Table";
import { SubscriptionOrderBookStateChip } from "./Chips";
import {
  Cancel as CancelIcon,
  Clear as ClearIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import dayjs from "dayjs";
import { TransactionTable } from "../Ledger/Transaction";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { Amount } from "components/Ledger/Amount";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { useAPIContext } from "context/API";
import { ResolveSubscriptionOrderBookStateFUTURERequest } from "@mesh/common-js/dist/market/subscriptionOrderBookStateResolver_pb";

const PREFIX = "SubscriptionOrderBookTable";

const classes = {
  textSearchField: `${PREFIX}-textSearchField`,
  iconButton: `${PREFIX}-iconButton`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
  row: `${PREFIX}-row`,
};

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export type SubscriptionOrderBookTableProps = {
  system: boolean;
  height: number;
  hideColumns?: string[];
};

export function SubscriptionOrderBookTable(
  props: SubscriptionOrderBookTableProps,
) {
  const {
    market: { subscriptionOrderBookStateResolver },
  } = useAPIContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const { viewConfiguration, authContext } = useApplicationContext();
  const [fullUpdateLoading, setFullUpdateLoading] = useState(false);
  const mcView =
    !!viewConfiguration?.["Subscription"]?.["Subscription Order Book"]?.[
      "MC View"
    ];
  const [
    selectedSubscriptionOrderBookViewModel,
    setSelectedSubscriptionOrderBookViewModel,
  ] = useState<MarketSubscriptionOrderBookViewModel | undefined>(undefined);
  const [resolutionInProgress, setResolutionInProgress] = useState(false);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const handleResolveSubscriptionOrderBookState = async () => {
    if (!selectedSubscriptionOrderBookViewModel) {
      console.error("selected market subscription Book view model not set");
      return;
    }

    setResolutionInProgress(true);
    try {
      if (!selectedSubscriptionOrderBookViewModel) {
        console.error("Selected Subscription Book Not Set");
        return;
      }
      await subscriptionOrderBookStateResolver.resolveSubscriptionOrderBookStateFUTURE(
        new ResolveSubscriptionOrderBookStateFUTURERequest()
          .setContext(authContext.toFuture())
          .setSubscriptionorderbookid(
            selectedSubscriptionOrderBookViewModel.subscriptionOrderBookID,
          ),
      );
      setSelectedSubscriptionOrderBookViewModel(undefined);
      setReadRequest({ ...readRequest });
      enqueueSnackbar("Subscription Book State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving subscription Book state`, e);
      enqueueSnackbar(
        `error resolving subscription Book state: ${err.message}`,
        { variant: "error" },
      );
    }
    setResolutionInProgress(false);
  };
  const { readResponse, loading, setReadRequest, readRequest } =
    useMarketSubscriptionOrderBookViewReaderRead(
      {
        context: authContext,
        query: new Query(initialQuery),
        criteria: {},
      },
      props.system,
    );

  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const [
    subscriptionOrderBookStatusesCriterion,
    setSubscriptionOrderBookStatusesCriterion,
  ] = useState<SubscriptionOrderBookState[]>([]);
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { number: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            assetName: TextSubstringCriterion(textSearchCriterionTextField),
          },
          {
            "token.code": TextSubstringCriterion(textSearchCriterionTextField),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    if (subscriptionOrderBookStatusesCriterion.length) {
      criteria.subscriptionOrderBookState = TextListCriterion(
        subscriptionOrderBookStatusesCriterion,
      );
    }

    setReadRequest({
      context: readRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    subscriptionOrderBookStatusesCriterion,
    textSearchCriterion,
    readRequest.context,
    setReadRequest,
    authContext,
  ]);

  return (
    <BPTable
      singleSelect
      loading={loading || fullUpdateLoading || resolutionInProgress}
      height={props.height - noticeBannerHeight}
      title={"Subscription Books"}
      data={readResponse.models}
      onSingleSelectChange={(data) =>
        setSelectedSubscriptionOrderBookViewModel(
          data as MarketSubscriptionOrderBookViewModel,
        )
      }
      columns={(() => {
        const columns = [
          {
            label: "Number",
            field: "number",
          },
          {
            label: "Asset Name",
            field: "assetName",
          },
          {
            field: "openDate",
            label: "Open Date",
            minWidth: 180,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) =>
              dayjs(
                (data as MarketSubscriptionOrderBookViewModel).openDate,
              ).format("DD/MM/YYYY HH:mm:ss"),
          },
          {
            field: "closeDate",
            label: "Close Date",
            minWidth: 180,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) =>
              dayjs(
                (data as MarketSubscriptionOrderBookViewModel).closeDate,
              ).format("DD/MM/YYYY HH:mm:ss"),
          },
          {
            field: "settlementDate",
            label: "Settlement Date",
            minWidth: 180,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) =>
              dayjs(
                (data as MarketSubscriptionOrderBookViewModel).settlementDate,
              ).format("DD/MM/YYYY HH:mm:ss"),
          },
          {
            field: "subscriptionAmount",
            label: "Issuance Value",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => (
              <Box sx={{ pr: 1 }}>
                <Amount
                  amount={
                    (data as MarketSubscriptionOrderBookViewModel)
                      .subscriptionAmount
                  }
                />
              </Box>
            ),
          },
          {
            field: "subscribedAmount",
            label: "Subscribed Amount",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => (
              <Amount
                amount={
                  (data as MarketSubscriptionOrderBookViewModel)
                    .subscribedAmount
                }
              />
            ),
          },
          {
            label: "State",
            field: "state",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [key: string]: any }) => {
              return (
                <SubscriptionOrderBookStateChip
                  sx={{ mr: 1 }}
                  state={(data as MarketSubscriptionOrderBookViewModel).state}
                />
              );
            },
          },
          {
            label: "Res. Count",
            field: "stateResolutionCount",
          },
        ];

        columns.push({
          field: "",
          label: "",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (data: { [key: string]: any }) => {
            const model = data as MarketSubscriptionOrderBookViewModel;
            return (
              <Tooltip placement="top" title="View book detail in new tab">
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `${window.location.origin}/builder/market-listing/listing?token-code=${model.token.code}&token-issuer=${model.token.issuer}&token-network=${model.token.toFutureToken().getNetwork()}&mode=view`,
                      "_blank",
                    );
                  }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            );
          },
        });

        const filterCols = props.hideColumns ?? [];

        return columns.filter((c) => !filterCols.includes(c.label));
      })()}
      expandRowComponent={
        mcView
          ? {
              maxHeight: 420,
              component: (selectedRowData) => (
                <TransactionTable
                  height={400}
                  title={`Transactions for Subscription Order Book ${
                    (selectedRowData as MarketSubscriptionOrderBookViewModel)
                      .number
                  }`}
                  constantCriteria={[
                    newTextExactCriterion(
                      "metadata.subscriptionOrderBookID",
                      (selectedRowData as MarketSubscriptionOrderBookViewModel)
                        .subscriptionOrderBookID,
                    ),
                  ]}
                />
              ),
            }
          : undefined
      }
      query={readRequest.query}
      onQueryChange={(query: Query) =>
        setReadRequest({
          ...readRequest,
          query,
        })
      }
      totalNoRecords={readResponse.total}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        if (
          selectedSubscriptionOrderBookViewModel &&
          viewConfiguration?.["Subscription"]?.["Subscription Order Book"]
            ?.ResolveState
        ) {
          controls.push(
            <Button
              disabled={resolutionInProgress}
              variant={"contained"}
              color={"primary"}
              children={"Resolve State"}
              onClick={handleResolveSubscriptionOrderBookState}
            />,
          );
        }

        if (
          viewConfiguration?.["Subscription"]?.["Subscription Order Book"]
            ?.ViewModel?.FullUpdate
        ) {
          controls.push(
            <Button
              variant={"contained"}
              color={"primary"}
              children={"Full Update"}
              onClick={async () => {
                setFullUpdateLoading(true);
                try {
                  await MarketSubscriptionOrderBookViewUpdater.FullUpdate({
                    context: authContext,
                  });
                  setReadRequest({ ...readRequest });
                  enqueueSnackbar("Full Update Complete", {
                    variant: "success",
                  });
                } catch (e) {
                  const err = errorContextErrorTranslator.translateError(e);
                  console.error(
                    `error performing full update: ${
                      err.message ? err.message : err.toString()
                    }`,
                  );
                  enqueueSnackbar(
                    `Error Performing Full Update: ${
                      err.message ? err.message : err.toString()
                    }`,
                    { variant: "error" },
                  );
                }
                setFullUpdateLoading(false);
              }}
            />,
          );
        }

        controls.push(
          <Tooltip title={"Refresh"}>
            <span>
              <IconButton
                onClick={() =>
                  setReadRequest({
                    ...readRequest,
                    query: new Query(initialQuery),
                  })
                }
                id={"marketSubscriptionOrderBooksTable-refresh-iconButton"}
                disabled={loading}
                size={"small"}
              >
                <ReloadIcon />
              </IconButton>
            </span>
          </Tooltip>,
        );

        return controls;
      })()}
      filters={[
        <TextField
          id={"marketSubscriptionOrderBooksTable-textFilter-textField"}
          variant={"outlined"}
          margin={"dense"}
          className={classes.textSearchField}
          label={"Search Text Field"}
          placeholder={"Start Typing..."}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: textSearchCriterionTextField ? (
              <InputAdornment
                position={"end"}
                children={
                  <IconButton
                    id={
                      "marketSubscriptionOrderBooksTable-textFilterClearButton-iconButton"
                    }
                    size={"small"}
                    onClick={() => setTextSearchCriterionTextField("")}
                  >
                    <ClearIcon />
                  </IconButton>
                }
              />
            ) : undefined,
          }}
          value={textSearchCriterionTextField}
          onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
        />,
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          id={"marketSubscriptionOrderBooksTable-stateFilter-autocomplete"}
          disabled={loading}
          multiple
          options={AllSubscriptionOrderBookStates}
          filterSelectedOptions
          onChange={(_, value: SubscriptionOrderBookState[]) =>
            setSubscriptionOrderBookStatusesCriterion(value)
          }
          ChipProps={{
            color: "info",
            size: "small",
            deleteIcon: (
              <CancelIcon
                sx={(theme) => ({
                  color: `${theme.palette.text.secondary} !important`,
                  "&:hover": {
                    color: `${theme.palette.secondary.contrastText} !important`,
                  },
                })}
              />
            ),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={
                "marketSubscriptionOrderBooksTable-stateFilter-autocompleteTextField"
              }
              className={classes.statusSelectFilterField}
              label={"State"}
              variant={"outlined"}
              margin={"dense"}
              InputLabelProps={{ shrink: true }}
              placeholder={
                subscriptionOrderBookStatusesCriterion.length
                  ? undefined
                  : "Select..."
              }
            />
          )}
        />,
      ]}
    />
  );
}
