import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { OTPInputComponent } from "components/OTPInput/OTPInput";
import { Close as CloseIcon } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface VerifyMobileNumberCardProps {
  open: boolean;
  onSubmit: (code: string) => void;
  onCancel: () => void;
  loading: boolean;
  resendCode: () => void;
  cellPhoneNumber: string | undefined;
  error: boolean;
  method: string;
  setOTPValues(): [number, boolean];
}

export function MFADialog({
  open,
  onSubmit,
  onCancel,
  loading,
  resendCode,
  error,
  method,
  cellPhoneNumber,
  setOTPValues,
}: VerifyMobileNumberCardProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [timerValue, timerCompleted] = setOTPValues();

  if (isMobile) {
    return (
      <Drawer open={open} anchor="bottom" sx={{ zIndex: 1301, width: "100%" }}>
        <DialogTitle sx={{ height: "52px" }}>
          <Typography fontSize="16px" sx={{ paddingRight: "16px" }}>
            Authenticate Action
          </Typography>
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  id="authenticate-action-dialog-close-button"
                  size="small"
                  onClick={() => onCancel()}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "grid",
            width: "100%",
          }}
        >
          <Typography
            sx={(theme) => ({
              alignSelf: "center",
              color: theme.palette.text.hint,
              paddingTop: theme.spacing(3),
            })}
            fontSize="14px"
            fontWeight="400"
          >
            Enter the security code that was sent to
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Typography
              sx={(theme) => ({
                alignSelf: "center",
                color: theme.palette.text.primary,
                padding: theme.spacing(0, 1, 3, 0),
              })}
            >
              {cellPhoneNumber ? cellPhoneNumber : ""}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <OTPInputComponent
              error={error}
              onChange={async (value) => {
                if (value.length === 6) {
                  onSubmit(value);
                }
              }}
            />
          </Box>
          <Button
            sx={(theme) => ({
              height: theme.spacing(6),
              justifySelf: "center",
              width: "200px",
            })}
            id="mobileNumberVerification-verify-number-button"
            disabled={loading || !timerCompleted}
            variant="contained"
            onClick={resendCode}
            color="secondary"
            size="large"
          >
            {!timerCompleted
              ? `Resend Code (${timerValue === 0 ? `` : `${Math.round(timerValue)}`}s)`
              : "Resend Code"}
          </Button>
          <Link
            id="mfa-verification-faq-link"
            sx={(theme) => ({
              fontWeight: "bold",
              padding: theme.spacing(4, 0, 1),
              justifySelf: "center",
            })}
            component="a"
            href="https://support.mesh.trade/support/solutions/articles/80001161880-what-should-i-do-if-i-m-not-receiving-my-otp-code-"
            fontSize="16px"
            target="_blank"
            underline="hover"
            color="secondary"
          >
            Didn&apos;t Receive the Code?
          </Link>
        </DialogContent>
      </Drawer>
    );
  }

  return (
    <Dialog
      open={open}
      sx={(theme) => ({
        height: theme.spacing(56),
        alignSelf: "center",
        justifyContent: "center",
      })}
    >
      <DialogTitle sx={{ height: "52px" }}>
        <Typography fontSize="16px" sx={{ paddingRight: "16px" }}>
          Authenticate{" "}
          {method
            .split(/(?=[A-Z])/)
            .filter((word) => word !== "New")
            .join(" ")}
        </Typography>
        <Grid item>
          <Tooltip title="Close" placement="top">
            <span>
              <IconButton
                id="authenticate-action-dialog-close-button"
                size="small"
                onClick={() => onCancel()}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          width: theme.spacing(46),
          display: "grid",
          margin: theme.spacing(1),
        })}
      >
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.hint,
            paddingTop: theme.spacing(3),
          })}
          fontSize="14px"
          fontWeight="400"
        >
          Enter the security code that was sent to
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Typography
            sx={(theme) => ({
              alignSelf: "center",
              color: theme.palette.text.primary,
              padding: theme.spacing(0, 1, 3, 0),
            })}
          >
            {cellPhoneNumber ? cellPhoneNumber : ""}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <OTPInputComponent
            error={error}
            onChange={async (value) => {
              if (value.length === 6) {
                onSubmit(value);
              }
            }}
          />
        </Box>
        <Button
          sx={(theme) => ({
            height: theme.spacing(6),
            justifySelf: "center",
            width: "200px",
          })}
          id="mobileNumberVerification-verify-number-button"
          disabled={loading || !timerCompleted}
          variant="contained"
          onClick={resendCode}
          color="secondary"
          size="large"
        >
          {!timerCompleted
            ? `Resend Code (${timerValue === 0 ? `` : `${Math.round(timerValue)}`}s)`
            : "Resend Code"}
        </Button>
        <Link
          id="mfa-verification-faq-link"
          sx={(theme) => ({
            fontWeight: "bold",
            padding: theme.spacing(4, 0, 1),
            justifySelf: "center",
          })}
          component="a"
          href="https://support.mesh.trade/support/solutions/articles/80001161880-what-should-i-do-if-i-m-not-receiving-my-otp-code-"
          fontSize="16px"
          target="_blank"
          underline="hover"
          color="secondary"
        >
          Didn&apos;t Receive the Code?
        </Link>
      </DialogContent>
    </Dialog>
  );
}
