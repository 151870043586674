"use client";
import { OTPInput, SlotProps, REGEXP_ONLY_DIGITS } from "input-otp";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const SlotContainer = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.3s",
  outline: `1px solid ${theme.palette.text.primary}20`,
  borderRadius: theme.spacing(0.5),
  "&.error": {
    border: `1px solid ${theme.palette.error.light}`,
  },
  "&:hover, &:focus-within": {
    border: `1px solid ${theme.palette.text.primary}20`,
  },
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.custom.midnight,
}));

function Slot(props: SlotProps & { error: boolean }) {
  return (
    <SlotContainer
      className={props.error ? "error" : ""}
      elevation={props.isActive ? 3 : 1}
      sx={(theme) => ({
        border: props.isActive
          ? `1px solid ${theme.palette.text.primary}`
          : props.char !== null
            ? `1px solid ${theme.palette.text.primary}`
            : 0,
      })}
    >
      {props.char !== null && <div>{props.char}</div>}
      {props.hasFakeCaret && <FakeCaret />}
    </SlotContainer>
  );
}

function FakeCaret() {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "1px",
        height: "2rem",
        backgroundColor: "white",
        animation: "caret-blink 1.2s ease-out infinite",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "none",
      }}
    />
  );
}

export function OTPInputComponent({
  onChange,
  error,
}: {
  onChange: (newValue: string) => unknown;
  error: boolean;
}) {
  return (
    <OTPInput
      maxLength={6}
      pattern={REGEXP_ONLY_DIGITS}
      onChange={onChange}
      render={({ slots }) => (
        <Box sx={{ display: "grid", gridTemplateRows: "1fr 1fr", gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {slots.map((slot, idx) => (
              <Slot key={idx} {...slot} error={error} />
            ))}
          </Box>
          {error && (
            <Typography
              sx={{
                justifySelf: "center",
              }}
              color="error.light"
              fontSize="12px"
              children="Incorrect code, please try again."
            />
          )}
        </Box>
      )}
    />
  );
}

const keyframes = `
@keyframes caret-blink {
  0%, 70%, 100% { opacity: 1; }
  20%, 50% { opacity: 0; }
}`;

const styles = document.createElement("style");
styles.innerHTML = keyframes;
document.head.appendChild(styles);
