import React, { useState } from "react";
import { Box, Card, Link, Typography } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";
import { VerticalSlide } from "components/Animations/VerticalSlide";
import { OnboardingCard } from "views/SignUp/components/OnboardingCard/OnboardingCard";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import {
  InitiateCompanyAdminRegistration,
  InitiateUserRegistration,
} from "views/SignUp/const";

export function ClientSelectorCard() {
  const navigate = useNavigate();
  const [onCard1Hover, setOnCard1Hover] = useState(false);
  const [onCard2Hover, setOnCard2Hover] = useState(false);
  const { logout } = useApplicationContext();

  const handleClientSelection = (clientKind: string) => {
    localStorage.removeItem(InitiateUserRegistration);
    localStorage.removeItem(InitiateCompanyAdminRegistration);
    localStorage.setItem(clientKind, clientKind);
    navigate("/sign-up/country-selector");
  };

  return (
    <OnboardingCard
      dataComponentAttribute={JSON.stringify({
        component_id: "sign_up",
        component_business_name: "Sign up",
        component_title: "Sign up as",
        component_driver: InteractionDriver.Join,
      } as DataComponentInfo)}
      linksFooter={
        <Box
          sx={(theme) => ({
            display: "flex",
            paddingBottom: theme.spacing(5.5),
          })}
        >
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              fontSize: "14px",
            })}
            variant="body1"
          >
            Already Registered?
          </Typography>
          <Link
            id={"SignUp-ClientSelectorCard-SignIn-Link"}
            sx={(theme) => ({
              paddingLeft: theme.spacing(1),
              fontWeight: "bold",
            })}
            onClick={() => {
              logout().finally(() => navigate("/sign-in"));
            }}
            fontSize={"14px"}
            component="button"
            underline="hover"
            color="secondary"
            data-link-info={JSON.stringify({
              content_interaction_id: "sign-in-button",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "already registered - sign in",
              content_interaction_type: InteractionType.Link,
              content_interaction_driver: InteractionDriver.SignIn,
            } as DataLinkInfoType)}
          >
            Sign In
          </Link>
        </Box>
      }
    >
      <VerticalSlide transitionDuration={1 / 8}>
        <Typography
          sx={(theme) => ({
            alignSelf: "center",
            color: theme.palette.text.primary,
            padding: theme.spacing(5.5, 0, 5.5, 0.5),
          })}
          fontSize="20px"
          fontWeight="600"
          children="Sign up as..."
        />
      </VerticalSlide>
      <VerticalSlide transitionDuration={2 / 8}>
        <Card
          id={"SignUp-ClientSelectorCard-IndividualClientSelection"}
          data-link-info={JSON.stringify({
            content_interaction_id: "sign-up-button",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "an individual",
            content_interaction_type: InteractionType.Link,
            content_interaction_driver: InteractionDriver.SignUp,
          } as DataLinkInfoType)}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: theme.spacing(3),
            flexDirection: "row",
            ":hover": {
              boxShadow: 20,
            },
            cursor: "pointer",
          })}
          onClick={() => handleClientSelection(InitiateUserRegistration)}
          onMouseEnter={() => {
            setOnCard1Hover(true);
          }}
          onMouseLeave={() => {
            setOnCard1Hover(false);
          }}
        >
          <VerticalSlide transitionDuration={6 / 8}>
            <PersonIcon
              sx={(theme) => ({
                width: "30px",
                height: "30px",
                margin: theme.spacing(0, 3),
                color: onCard1Hover
                  ? theme.palette.custom.lavender
                  : theme.palette.text.secondary,
              })}
            />
          </VerticalSlide>
          <Box
            sx={(theme) => ({
              padding: theme.spacing(2, 3, 2.5, 0),
            })}
          >
            <VerticalSlide transitionDuration={7 / 8}>
              <Typography
                data-link-info={JSON.stringify({
                  content_interaction_id: "sign-up-button",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "an individual",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.SignUp,
                } as DataLinkInfoType)}
                sx={(theme) => ({
                  alignSelf: "center",
                  color: onCard1Hover
                    ? theme.palette.text.primary
                    : theme.palette.text.tertiary,
                  paddingBottom: theme.spacing(1),
                })}
                fontWeight="600"
                children="An Individual"
              />
            </VerticalSlide>
            <VerticalSlide transitionDuration={1}>
              <Typography
                sx={(theme) => ({
                  color: onCard1Hover
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                })}
                fontSize="12px"
                data-link-info={JSON.stringify({
                  content_interaction_id: "sign-up-button",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "an individual",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.SignUp,
                } as DataLinkInfoType)}
              >
                Invest in a wide range of traditional and digital assets on the
                Mesh Market.
              </Typography>
            </VerticalSlide>
          </Box>
        </Card>
      </VerticalSlide>
      <VerticalSlide transitionDuration={2 / 8}>
        <Card
          id={"SignUp-ClientSelectorCard-CompanyClientSelection"}
          data-link-info={JSON.stringify({
            content_interaction_id: "sign-up-button",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "a company",
            content_interaction_type: InteractionType.Link,
            content_interaction_driver: InteractionDriver.SignUp,
          } as DataLinkInfoType)}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            width: "100%",
            marginBottom: theme.spacing(12),
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            ":hover": {
              boxShadow: 20,
            },
            cursor: "pointer",
          })}
          onClick={() =>
            handleClientSelection(InitiateCompanyAdminRegistration)
          }
          onMouseEnter={() => {
            setOnCard2Hover(true);
          }}
          onMouseLeave={() => {
            setOnCard2Hover(false);
          }}
        >
          <VerticalSlide transitionDuration={6 / 8}>
            <ApartmentIcon
              sx={(theme) => ({
                width: "30px",
                height: "30px",
                margin: theme.spacing(0, 3),
                color: onCard2Hover
                  ? theme.palette.custom.lavender
                  : theme.palette.text.secondary,
              })}
            />
          </VerticalSlide>
          <Box
            sx={(theme) => ({
              padding: theme.spacing(2, 3, 2, 0),
            })}
          >
            <VerticalSlide transitionDuration={7 / 8}>
              <Typography
                data-link-info={JSON.stringify({
                  content_interaction_id: "sign-up-button",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "a company",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.SignUp,
                } as DataLinkInfoType)}
                sx={(theme) => ({
                  alignSelf: "center",
                  color: onCard2Hover
                    ? theme.palette.text.primary
                    : theme.palette.text.tertiary,
                  paddingBottom: theme.spacing(1),
                })}
                fontWeight="600"
                children="A Company"
              />
            </VerticalSlide>
            <VerticalSlide transitionDuration={1}>
              <Typography
                data-link-info={JSON.stringify({
                  content_interaction_id: "sign-up-button",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_text: "a company",
                  content_interaction_type: InteractionType.Link,
                  content_interaction_driver: InteractionDriver.SignUp,
                } as DataLinkInfoType)}
                sx={(theme) => ({
                  color: onCard2Hover
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                })}
                fontSize="12px"
              >
                Become a Market Maker and issue financial instruments on Mesh or
                invest in and manage your portfolio of Traditional and Digital
                assets.
              </Typography>
            </VerticalSlide>
          </Box>
        </Card>
      </VerticalSlide>
    </OnboardingCard>
  );
}
