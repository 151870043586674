import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import React, { useEffect, useState } from "react";
import {
  Model as StellarAccountViewModel,
  Balance,
  Model,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Amount as LedgerAmount } from "james/ledger";
import { useSnackbar } from "notistack";
import { useStellarContext } from "context/Stellar";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CurrencyStablecoin,
  FinancialCurrencyStablecoinRepository,
} from "james/financial";
import { useAccountContext } from "context/Account/Account";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { TextExactCriterion } from "james/search/criterion";
import { LedgerIDIdentifier } from "james/search/identifier";
import { TransactionTypes } from "types/gtm";
import { Close as CloseIcon } from "@mui/icons-material";
import { TextField, TextNumField } from "components/FormFields";
import { styled } from "@mui/material/styles";
import { useFundingContext } from "views/Banking/components/Funding/Context";
import { FundingOrder } from "@mesh/common-js/dist/banking/fundingOrder_pb";
import { newAmountFromBigNumber } from "@mesh/common-js/dist/ledger";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeField } from "@mesh/common-js-react/dist/FormFields";
import { DateTimeFormat } from "const/dateformats";
import { useAPIContext } from "context/API";
import { ReadOneModelRequest } from "@mesh/common-js/dist/views/marketingInstrumentMarketingContentView/modelReader_meshproto_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import {
  getAvailableBalance,
  getTokenBalance,
} from "@mesh/common-js/dist/views/stellarAccountView";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { BigNumber } from "bignumber.js";
import { BankName } from "@mesh/common-js/src/banking/bankAccount_pb";

const PREFIX = "Funding";

const classes = {
  assetToMintSection: `${PREFIX}-assetToMintSection`,
  detail: `${PREFIX}-detail`,
  collapse: `${PREFIX}-collapse`,
  dialogTitleContent: `${PREFIX}-dialogTitleContent`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  textSearchField: `${PREFIX}-textSearchField`,
  dateFilterField: `${PREFIX}-dateFilterField`,
  iconButton: `${PREFIX}-iconButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.assetToMintSection}`]: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.detail}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(3, auto)",
  },

  [`& .${classes.dialogTitleContent}`]: {
    alignItems: "center",
  },

  [`& .${classes.collapse}`]: {
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.custom.midnight,
  },

  [`& .${classes.dialogTitle}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.textSearchField}`]: {
    width: 400,
  },

  [`& .${classes.dateFilterField}`]: {
    width: 180,
  },

  [`& .${classes.iconButton}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },
}));

interface FundingOrderProps {
  open: boolean;
  onClose: () => void;
}

export function FundingOrderDialog(props: FundingOrderProps) {
  const {
    views: { stellarAccountViewModelReaderUNSCOPED },
  } = useAPIContext();
  const { submitFundingOrder } = useFundingContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const [reference, setReference] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [targetStellarAccountViewModel, setTargetStellarAccountViewModel] =
    useState(new StellarAccountViewModel());
  const [mZARStablecoinBalance, setMZARStablecoinBalance] = useState<
    Balance | undefined
  >(undefined);
  const [unitsToMint, setUnitsToMint] = useState<LedgerAmount>(
    new LedgerAmount(),
  );
  const [apiLoading, setApiLoading] = useState(false);
  const [paymentReference, setPaymentReference] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { stellarAccountContextPopulateModelWithLedgerDetails } =
    useStellarContext();
  const [validationState, setValidationState] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>({});
  const theme = useTheme();
  const [collapse, setCollapse] = useState(false);
  const [valueDate, setValueDate] = useState<Timestamp | undefined>(undefined);

  // on dialog open:
  // - Retrieve mZAR stablecoin model
  // - Determine if user is signatory on stablecoin issuance account
  const [initialising, setInitialising] = useState(true);
  const [userSignatoryOnAccount, setUserSignatoryOnAccount] = useState(false);
  const [mZARCurrencyStablecoin, setMZARCurrencyStablecoin] = useState(
    new CurrencyStablecoin(),
  );
  const { stellarAccountContext } = useAccountContext();
  const { pushFundAccountComplete } = useGTMTriggersPusher();
  const accountLoading = stellarAccountContext.loading;
  const accountLoadingError = stellarAccountContext.error;

  useEffect(() => {
    (async () => {
      if (accountLoading) return;

      if (accountLoadingError) {
        console.error(`initialisation error::${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialisation Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        // close the dialog
        props.onClose();
      }

      // retrieve mZAR stablecoin view model and issuance token
      let retrievedMZARCurrencyStablecoin: CurrencyStablecoin;
      try {
        const currencyStablecoins = (
          await FinancialCurrencyStablecoinRepository.SearchCurrencyStablecoin({
            context: authContext,
            criteria: {
              "token.code": TextExactCriterion("mZAR"),
            },
          })
        ).records;
        if (currencyStablecoins.length !== 1) {
          console.error(
            "unexpected number of currency stablecoins retrieved for mZAR",
          );
          enqueueSnackbar(
            "unexpected number of currency stablecoins retrieved for mZAR",
            { variant: "error" },
          );
          setInitialising(false);
          return;
        }
        retrievedMZARCurrencyStablecoin = currencyStablecoins[0];
        if (!unitsToMint) {
          setUnitsToMint(
            retrievedMZARCurrencyStablecoin.token.newAmountOf("0"),
          );
        }
        setMZARCurrencyStablecoin(currencyStablecoins[0]);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving mZAR currency stablecoin: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error retrieving mZAR currency stablecoin", {
          variant: "error",
        });
        setInitialising(false);
        return;
      }

      try {
        setUserSignatoryOnAccount(
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(retrievedMZARCurrencyStablecoin.token.issuer),
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on destination account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }

      setInitialising(false);
    })();
  }, [
    targetStellarAccountViewModel,
    authContext,
    enqueueSnackbar,
    stellarAccountContext.loading,
    stellarAccountContext.error,
  ]);

  const performValidations = () => {
    const newValidationState: { [key: string]: string | undefined } = {};

    if (!mZARStablecoinBalance) {
      newValidationState.limit = "Limit not set on destination account.";
    }

    if (unitsToMint.value.isZero()) {
      newValidationState.unitsToMint = "Cannot be zero";
    }

    if (!paymentReference) {
      newValidationState.paymentReference = "Cannot be blank.";
    } else if (!/^\d+$/.test(paymentReference)) {
      newValidationState.paymentReference = "Should contain numbers only";
    }

    if (valueDate === undefined) {
      newValidationState.valueDate = "Cannot be blank.";
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  const handleFund = async () => {
    if (!performValidations()) {
      return;
    }

    setApiLoading(true);
    try {
      await submitFundingOrder(
        new FundingOrder()
          .setAccountnumber(targetStellarAccountViewModel.getNumber())
          .setBankreference(paymentReference)
          .setAmount(
            newAmountFromBigNumber(
              unitsToMint.value,
              unitsToMint.token.toFutureToken(),
            ),
          )
          .setReference(targetStellarAccountViewModel.getNumber())
          .setBankname(BankName.BANK_NAME_INVESTEC.toString())
          .setValuedate(valueDate),
      );
      pushFundAccountComplete({
        account_transaction_type: TransactionTypes.Fund,
        account_transaction_amount: unitsToMint.value.toString(),
        account_no: targetStellarAccountViewModel.getNumber(),
      });
      props.onClose();
      return;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error submitting funding order: ${
          err.message ? err.message : err.toString()
        }`,
      );
    }
    setApiLoading(false);
  };

  const getAccountDetails = async () => {
    setApiLoading(true);
    setValidationState({});
    try {
      setCollapse(true);
      // retrieve view model of account that is to be funded
      const ledgerAccViewModel =
        await stellarAccountContextPopulateModelWithLedgerDetails(
          (
            await stellarAccountViewModelReaderUNSCOPED.readOneModelUNSCOPED(
              new ReadOneModelRequest()
                .setContext(authContext.toFuture())
                .setCriteriaList([newTextExactCriterion("number", reference)]),
            )
          ).getModel() ?? new Model(),
        );
      setTargetStellarAccountViewModel(ledgerAccViewModel);

      // look for mZAR balance in balances of the account
      const balance = getTokenBalance(
        ledgerAccViewModel,
        mZARCurrencyStablecoin.token.toFutureToken(),
      );

      setMZARStablecoinBalance(balance);
    } catch (e) {
      setCollapse(false);
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error retrieving target stellar account view model : ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error retrieving account details: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setApiLoading(false);
  };

  const handleClear = () => {
    setReference("");
    setUnitsToMint(mZARCurrencyStablecoin.token.newAmountOf("0"));
    setPaymentReference("");
    setValidationState({});
    setCollapse(false);
  };

  useEffect(() => {
    if (!isTouched) {
      return;
    }
    performValidations();
  }, [
    mZARStablecoinBalance,
    unitsToMint,
    paymentReference,
    valueDate,
    isTouched,
  ]);

  return (
    <StyledDialog open={props.open}>
      <DialogTitle>
        <Grid
          className={classes.dialogTitleContent}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item className={classes.dialogTitle}>
            <Typography variant="h5">Fund Account</Typography>
          </Grid>
          <Grid item>
            <IconButton
              id="fundingOrder-close-button"
              disabled={apiLoading}
              onClick={props.onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ width: "570px", paddingBottom: 0 }}>
        <Typography sx={{ padding: "16px 0px", fontSize: "20px" }} variant="h3">
          Client Reference
        </Typography>
        <div className={classes.assetToMintSection}>
          <TextField
            disabled={initialising || apiLoading}
            id="fundingOrder-assetCode-textField"
            label="Asset Code"
            value={mZARCurrencyStablecoin.token.code}
            readOnly
          />
          <TextNumField
            disabled={initialising || apiLoading}
            id="fundingOrder-mintAmount-textNumField"
            label="Units to Mint"
            value={unitsToMint.value}
            error={isTouched && !!validationState.unitsToMint}
            helperText={isTouched && validationState.unitsToMint}
            disallowNegative
            onChange={(e) => {
              handleClearValidation("unitsToMint");
              setUnitsToMint(
                mZARCurrencyStablecoin.token.newAmountOf(e.target.value),
              );
            }}
          />
        </div>
        <div className={classes.assetToMintSection}>
          <TextField
            disabled={initialising || apiLoading || collapse}
            fullWidth
            id="fundingOrder-reference-textField"
            onChange={(e) => setReference(e.target.value)}
            label="Client Reference (Account Nr.)"
            value={reference}
          />
          <div
            style={{
              color: "secondary",
              display: "flex",
              paddingLeft: theme.spacing(2),
              alignItems: "center",
            }}
          >
            {collapse ? (
              <Button
                id="fundingOrder-fund-button"
                variant="outlined"
                disabled={apiLoading || initialising}
                onClick={handleClear}
              >
                Clear
              </Button>
            ) : (
              <Button
                id="fundingOrder-fund-button"
                variant="contained"
                color="secondary"
                disabled={apiLoading || initialising}
                onClick={getAccountDetails}
              >
                Fetch
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
      <Collapse className={classes.collapse} in={collapse}>
        {!apiLoading && (
          <Typography
            sx={{ padding: "24px 16px 0px 0px", fontSize: "20px" }}
            variant="h3"
          >
            Client Info
          </Typography>
        )}
        {!apiLoading && (
          <div className={classes.detail}>
            <TextField
              id="fundingOrder-client-name"
              label="Client"
              readOnly
              value={targetStellarAccountViewModel.getClientname()}
            />
            <TextField
              id="fundingOrder-group-name"
              label="Group"
              readOnly
              value={targetStellarAccountViewModel.getGroupname()}
            />
            <TextField
              id="fundingOrder-account-number"
              label="Account Number"
              readOnly
              value={targetStellarAccountViewModel.getNumber()}
            />
            {targetStellarAccountViewModel.getLedgerid() && (
              <TextNumField
                id="fundingOrder-stablecoinBalance-textfield"
                label="mZAR Balance"
                readOnly
                value={
                  mZARCurrencyStablecoin
                    ? decimalToBigNumber(
                        getAvailableBalance(mZARStablecoinBalance).getValue() ??
                          new Decimal(),
                      )
                    : new BigNumber(0)
                }
              />
            )}
            <TextNumField
              id="fundingOrder-stablecoinlimit-textfield"
              label="mZAR limit"
              fullWidth
              readOnly
              value={decimalToBigNumber(
                mZARStablecoinBalance?.getLimit()?.getValue(),
              )}
              error={!!validationState.limit || !mZARStablecoinBalance}
              helperText={validationState.limit}
            />
            <DateTimeField
              value={valueDate}
              format={DateTimeFormat}
              error={!!validationState.valueDate}
              helperText={validationState.valueDate}
              onChange={(e) => {
                setIsTouched(true);
                setValueDate(e);
              }}
            />
          </div>
        )}
        {!apiLoading && (
          <TextField
            id="fundingOrder-stablecoinledgerID-textfield"
            label="Ledger ID"
            fullWidth
            readOnly
            value={targetStellarAccountViewModel.getLedgerid()}
          />
        )}
        {!apiLoading && (
          <TextField
            fullWidth
            style={{ marginBottom: theme.spacing(2) }}
            id="fundingOrder-paymentReference-balance"
            label="Bank Transaction Ref."
            value={paymentReference}
            error={!!validationState.paymentReference}
            helperText={validationState.paymentReference}
            onChange={(e) => {
              setIsTouched(true);
              handleClearValidation("paymentReference");
              setPaymentReference(e.target.value);
            }}
          />
        )}
        {!apiLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              gap: "16px",
              marginBottom: 3,
            }}
          >
            <Tooltip
              placement="top"
              title={
                userSignatoryOnAccount
                  ? ""
                  : "user is not a signatory on issuance account"
              }
              sx={{ alignSelf: "end" }}
            >
              <span>
                <Button
                  id="fundingOrder-fundAccount-balance"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleFund}
                  disabled={
                    !userSignatoryOnAccount ||
                    validationState.unitsToMint ||
                    validationState.limit ||
                    validationState.valueDate ||
                    validationState.paymentReference
                  }
                  children="fund"
                />
              </span>
            </Tooltip>
          </Box>
        )}
      </Collapse>
    </StyledDialog>
  );
}
