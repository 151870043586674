import React, { useMemo, useState } from "react";
import {
  Box,
  Card,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HowDoesItWorkSectionProps } from "./HowDoesItWork";
import meshIconWithHoldingDev from "assets/images/howDoesItWork/meshIconWithHoldingDev.png";
import meshArtUsers from "assets/images/howDoesItWork/meshArtUsers.png";
import { ContentType } from "./HowDoesItWorkContentProvider";
import { AssetType } from "../../../../../james/views/marketListingView/Model";
import cx from "classnames";

type DescriptionType = {
  definition: ContentType[];
  who: ContentType[];
  what: ContentType[];
  investors?: ContentType[];
  outro?: ContentType[];
  links?: ContentType[];
};

export const DescriptionSection = ({
  section,
  marketListingViewModel,
}: HowDoesItWorkSectionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const commodityTypes = [
    AssetType.Gold,
    AssetType.Silver,
    AssetType.Platinum,
    AssetType.Palladium,
  ];

  const isBond = [AssetType.Bond, ""].includes(
    marketListingViewModel.assetType,
  );
  const [isPrefshare] = useState(
    marketListingViewModel.assetType === AssetType.PreferenceShare,
  );

  const [isRightsToAMC] = useState(
    marketListingViewModel.assetType === AssetType.RightsToAnAMC,
  );

  const isCommodity =
    marketListingViewModel.assetType !== "" &&
    commodityTypes.includes(marketListingViewModel.assetType);

  const content: DescriptionType | undefined = useMemo(() => {
    if (section.content) {
      return section.content as DescriptionType;
    }
    return;
  }, []);

  return (
    <>
      {content && (
        <Box
          sx={[
            {
              display: "flex",
              flexDirection: {
                sm: "row",
                xs: "column",
              },
              backgroundColor: theme.palette.custom.cardInner,
            },
            (isBond || isPrefshare || isRightsToAMC || isCommodity) && {
              flexDirection: { sm: "column", xs: "column" },
              backgroundColor: theme.palette.background.paper,
            },
          ]}
        >
          <Box
            //Note class names with cx do not work here for some reason I have tried to refactor and it failed
            sx={[
              {
                display: "flex",
                flexDirection: "column",
                p: {
                  sm: 5,
                  xs: 3,
                },
                mb: 2,
                width: {
                  sm: "60%",
                },
              },
              (isBond || isCommodity || isRightsToAMC) && {
                flexDirection: { sm: "row", xs: "column" },
                width: {
                  sm: "100%",
                },
                pb: 4,
                mb: 0,
                columnGap: { sm: 4, md: 6 },
              },
              isPrefshare && {
                width: {
                  sm: "100%",
                },
                mb: 0,
              },
            ]}
          >
            {/*What is*/}
            <Box
              sx={{
                position: "relative",
              }}
            >
              {/* funky border handle */}
              <Box
                className={cx({
                  isBond: isBond,
                  isRightsToAMC: isRightsToAMC,
                  notRightsToAMC: !isRightsToAMC,
                  isCommodity: isCommodity,
                })}
                sx={{
                  position: "absolute",
                  "&.notRightsToAMC": {
                    borderBottomRightRadius: "4px",
                    [theme.breakpoints.up("sm")]: {
                      top: "21px",
                      bottom: "21px",
                      right: "-4px",
                      borderTopRightRadius: "4px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      left: "24px",
                      bottom: "-4px",
                      right: "24px",
                      borderBottomLeftRadius: "4px",
                    },
                    border: `2px solid ${theme.palette.secondary.main}`,
                  },
                  "&.isBond": {
                    [theme.breakpoints.up("sm")]: {
                      top: "32px",
                      bottom: "32px",
                      right: "-4px",
                      borderTopRightRadius: "4px",
                    },
                  },
                  "&.isCommodity": {
                    [theme.breakpoints.up("sm")]: {
                      top: "32px",
                      bottom: "32px",
                      right: "-4px",
                      borderTopRightRadius: "4px",
                    },
                  },
                  "&.isRightsToAMC": {
                    borderBottomRightRadius: "4px",
                    border: `2px solid ${theme.palette.secondary.main}`,
                    [theme.breakpoints.up("sm")]: {
                      top: "32px",
                      bottom: "32px",
                      right: "-4px",
                      borderTopRightRadius: "4px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      left: "24px",
                      bottom: "-4px",
                      right: "24px",
                      borderBottomLeftRadius: "4px",
                    },
                  },
                }}
              />
              <Card
                className={cx({
                  isBond: isBond,
                  isRightsToAMC: isRightsToAMC,
                  isCommodity: isCommodity,
                })}
                sx={{
                  p: {
                    sm: theme.spacing(4, 4, 3, 4),
                    xs: theme.spacing(3, 3, 4, 3),
                  },
                  backgroundColor: theme.palette.custom.grape,
                  "&.isBond": {
                    width: { sm: 395, sx: "full" },
                  },
                  "&.isCommodity": {
                    width: { sm: 395, sx: "full" },
                  },
                  "&.isRightsToAMC": {
                    width: { sm: 412, sx: "full" },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      sm: "row",
                      xs: "column",
                    },
                    alignItems: {
                      xs: "center",
                      sm: "flex-start",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      "&::after": {
                        content: `"${content.what[0].imageLabel}"`,
                        color: theme.palette.custom.cardInner,
                        fontSize: () => {
                          if (content.what[0].imageLabel) {
                            const l = content.what[0].imageLabel.length;
                            return l > 3 ? "7px" : l === 3 ? "10px" : "12px";
                          }
                        },
                        letterSpacing: 0.05,
                        fontWeight: 900,
                        position: "absolute",
                        textAlign: "center",
                        borderRadius: "50%",
                        top: "-6px",
                        right: "-6px",
                        width: "36px",
                        height: "36px",
                        backgroundColor: theme.palette.secondary.main,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Box
                      component={"img"}
                      src={meshIconWithHoldingDev}
                      sx={{
                        width: "90px",
                        height: "90px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      ml: {
                        sm: 3,
                      },
                      mt: {
                        sm: 0,
                        xs: 2,
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: {
                        sm: "flex-start",
                        xs: "center",
                      },
                      textAlign: {
                        sm: "left",
                        xs: "center",
                      },
                      position: "relative",
                    }}
                  >
                    {/* What is ... ? */}
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        mb: 2,
                        textAlign: "left",
                      }}
                    >
                      {content.what[0].title}
                    </Typography>

                    {/* Asset type description */}
                    <Box
                      sx={{
                        pr: { sm: 0, xs: 1 },
                        pb: { sm: 0, xs: 1 },
                      }}
                    >
                      {content.what.length !== 0 &&
                        content.what[0].info?.map((v, i) => {
                          return (
                            <Typography
                              key={i}
                              sx={{
                                fontSize: "14px",
                                textAlign: "left",
                                color: theme.palette.text.secondary,
                                mb: i === 0 ? 2 : 0,
                              }}
                            >
                              {v}
                            </Typography>
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
                {content.links && content.links[0] && (
                  <Box
                    className={cx({
                      isPrefshare: isPrefshare,
                    })}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 1,
                      px: { sm: 14 },
                    }}
                  >
                    <Link
                      underline="none"
                      target="_blank"
                      rel="noopener"
                      href="https://support.mesh.trade/support/solutions/articles/80001148609-what-is-a-preference-share-"
                    >
                      Read More
                    </Link>
                  </Box>
                )}
              </Card>
            </Box>

            {content.definition && (
              <Box
                className={cx({
                  isBond: isBond,
                  isRightsToAMC: isRightsToAMC,
                  isCommodity: isCommodity,
                })}
                sx={{
                  pt: { sm: 5, xs: 5 },
                  "&.isBond": {
                    pt: { sm: 0 },
                  },
                  "&.isRightsToAMC": {
                    pt: { sm: 0 },
                  },
                  "&.isCommodity": {
                    pt: { sm: 0 },
                  },
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                    fontSize: "14px",
                    mb: 3,
                  }}
                >
                  {content.definition[0].title}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    columnGap: 2,
                  }}
                >
                  {content.definition.length > 1 &&
                    content.definition
                      .slice(1, content.definition.length)
                      .map((def, idx) => {
                        return (
                          <Box
                            key={idx}
                            sx={{
                              minWidth: "220px",
                              maxWidth: {
                                lg: "300px",
                                md: "400px",
                                xs: "100%",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                mb: 1,
                                fontSize: "16px",
                              }}
                              children={`${def.title}`}
                            />
                            <Typography
                              sx={{
                                color: theme.palette.text.secondary,
                                mb: 3,
                              }}
                            >
                              {def.info}
                            </Typography>
                          </Box>
                        );
                      })}
                </Box>
              </Box>
            )}
          </Box>
          {/*Who is this for*/}
          {content.who.length > 0 && (
            <Box
              className={cx({
                isBond: isBond,
                isPrefshare: isPrefshare,
                isRightsToAMC: isRightsToAMC,
              })}
              sx={{
                backgroundColor: theme.palette.custom.grape,
                width: {
                  sm: "60%",
                  xs: "100%",
                },
                "&.isBond": {
                  backgroundColor: theme.palette.custom.grape,
                  width: "100%",
                },
                "&.isPrefshare": {
                  width: "100%",
                },
                "&.isRightsToAMC": {
                  width: "100%",
                },
              }}
            >
              <Box
                sx={[
                  {
                    px: {
                      sm: 5,
                      xs: 3,
                    },
                    pt: {
                      sm: 10,
                      xs: 5,
                    },
                    pb: 4,
                  },
                  isBond && {
                    display: "flex",
                    flexDirection: { sm: "row-reverse", xs: "column" },
                    flexWrap: { sm: "wrap-reverse", xs: "no-wrap" },
                    alignItems: "center",
                    justifyContent: "flex-end",
                    pt: { xs: 5 },
                    gap: 2,
                  },
                  isPrefshare && {
                    display: "flex",
                    flexDirection: { sm: "row-reverse", xs: "column" },
                    flexWrap: { sm: "wrap-reverse", xs: "no-wrap" },
                    alignItems: "center",
                    justifyContent: "flex-end",
                    pt: { xs: 5 },
                  },
                  isRightsToAMC && {
                    display: "flex",
                    flexDirection: { sm: "row-reverse", xs: "column" },
                    alignItems: "center",
                    justifyContent: "flex-end",
                    py: { sm: 5, xs: 5 },
                    px: { sm: 5, xs: 3 },
                    gap: 10,
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      display: "flex",
                      justifyContent: { xs: "flex-start", md: "space-around" },
                    },
                    isPrefshare && {
                      mt: 3,
                    },
                    isBond && {
                      flexGrow: 1,
                      ml: {
                        sm: 5,
                      },
                    },
                  ]}
                >
                  <img src={meshArtUsers} width="275px" height="118px" />
                </Box>
                <Box
                  className={cx({
                    isPrefshare: isPrefshare,
                  })}
                >
                  <Typography
                    className={cx({
                      isBond: isBond,
                      isPrefshare: isPrefshare,
                    })}
                    sx={{
                      mb: 2,
                      fontSize: "20px",
                      fontWeight: "bold",
                      "&.isBond": {
                        mt: { xs: 4, sm: 0 },
                      },
                      "&.isPrefshare": {
                        mt: { xs: 4, sm: 0 },
                      },
                    }}
                  >
                    Who is this for?
                  </Typography>
                  {content &&
                    content.who[0].info &&
                    content.who[0].info?.map((v, i) => {
                      return (
                        <Typography
                          className={cx({
                            isBond: isBond,
                            isPrefshare: isPrefshare,
                            isRightsToAMC: isRightsToAMC,
                          })}
                          key={i}
                          sx={{
                            color: theme.palette.text.secondary,
                            "&.isBond": {
                              width: { sm: 375 },
                            },
                            "&.isPrefshare": { mt: 2 },
                            "&.isRightsToAMC": { mt: 2 },
                          }}
                        >
                          {v}
                        </Typography>
                      );
                    })}
                </Box>
              </Box>
              <Box
                sx={{
                  pb: 4,
                  px: {
                    xs: 3, // Extra small screens
                    sm: 5, // Small screens
                  },
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
                  gap: 3,
                }}
              >
                {content.investors &&
                  content.investors.map((c, i) => (
                    <Box
                      key={i}
                      sx={{
                        boxSizing: "border-box", // Include padding in the width calculation
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "30px",
                            height: "100%",
                            textAlign: "right",
                          }}
                        >
                          {isMobile ? (
                            <Box
                              sx={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "8px",
                                backgroundColor: theme.palette.secondary.main,
                                mt: 1,
                                mr: 2,
                              }}
                            />
                          ) : (
                            <Typography
                              sx={{
                                color: theme.palette.secondary.main,
                                fontWeight: "bold",
                                fontSize: "20px",
                                mt: 0.5,
                                mr: 2,
                                textAlign: "right",
                              }}
                            >
                              {c.title}.
                            </Typography>
                          )}
                        </Box>
                        <Box>
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.text.secondary,
                            })}
                          >
                            {c.info}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
              {content.outro && (
                <Box
                  sx={{
                    px: {
                      sm: 5,
                      xs: 3,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      // note this needs to be done better when we redo adv
                      mb: content.links ? 1 : 5,
                      color: theme.palette.text.secondary,
                      width: { sm: "auto" },
                    }}
                  >
                    {content.outro[0].info}
                  </Typography>
                </Box>
              )}

              {content.links && content.links[1] && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    px: {
                      sm: 5,
                      xs: 3,
                    },
                    pb: 5,
                  }}
                >
                  <Link
                    underline="none"
                    target="_blank"
                    rel="noopener"
                    href={content.links[1].link}
                  >
                    Read More
                  </Link>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
