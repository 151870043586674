import { Unit } from "@mesh/common-js/dist/financial/unit_pb";
import { BigNumber } from "bignumber.js";
import { AssetType } from "james/views/marketListingView/Model";

export enum UnitLabelType {
  Singular = "Singular",
  Plural = "Plural",
}

export interface UnitLabelData {
  Singular: string;
  Plural: string;
}

export const allUnitLabels: Record<Unit, UnitLabelData> = {
  [Unit.UNDEFINED_UNIT]: { Singular: "-", Plural: "-" },
  [Unit.NULL_UNIT]: { Singular: "Unit", Plural: "Units" },
  [Unit.NOTE_UNIT]: { Singular: "Note", Plural: "Notes" },
  [Unit.SHARE_UNIT]: { Singular: "Share", Plural: "Shares" },
  [Unit.PREFERENCE_SHARE_UNIT]: {
    Singular: "Pref. Share",
    Plural: "Pref. Shares",
  },
  [Unit.BOND_UNIT]: { Singular: "Bond", Plural: "Bonds" },
  [Unit.BILL_UNIT]: { Singular: "Bill", Plural: "Bills" },
  [Unit.CONTRACT_UNIT]: { Singular: "Contract", Plural: "Contracts" },
  [Unit.OPTION_UNIT]: { Singular: "Option", Plural: "Options" },
  [Unit.TOKEN_UNIT]: { Singular: "Token", Plural: "Tokens" },
  [Unit.BARREL_UNIT]: { Singular: "Barrel", Plural: "Barrels" },
  [Unit.GRAM_UNIT]: { Singular: "Gram", Plural: "Grams" },
  [Unit.KILOGRAM_UNIT]: { Singular: "Kilogram", Plural: "Kilograms" },
  [Unit.METRIC_TON_UNIT]: { Singular: "Metric Ton", Plural: "Metric Tons" },
  [Unit.OUNCE_UNIT]: { Singular: "Oz", Plural: "Ounces" },
  [Unit.HALF_OUNCE_UNIT]: { Singular: "1/2 Oz", Plural: "1/2 Oz" },
  [Unit.QUARTER_OUNCE_UNIT]: { Singular: "1/4 Oz", Plural: "1/4 Oz" },
  [Unit.ONE_TENTH_OUNCE_UNIT]: { Singular: "1/10 Oz", Plural: "1/10 Oz" },
  [Unit.ONE_HUNDREDTH_OUNCE_UNIT]: { Singular: "1/100 Oz", Plural: "1/100 Oz" },
};

export const getUnitLabel = (unit: Unit, type: UnitLabelType): string => {
  return allUnitLabels[unit]?.[type] || "Unit";
};

export const getFullOunceMultiplier = (unit: Unit): BigNumber => {
  switch (unit) {
    case Unit.HALF_OUNCE_UNIT:
      return BigNumber(2);
    case Unit.QUARTER_OUNCE_UNIT:
      return BigNumber(4);
    case Unit.ONE_TENTH_OUNCE_UNIT:
      return BigNumber(10);
    case Unit.ONE_HUNDREDTH_OUNCE_UNIT:
      return BigNumber(100);
    default:
      return BigNumber(0);
  }
};

export const priceBreakdownAssetTypes = [
  AssetType.Gold,
  AssetType.Silver,
  AssetType.Palladium,
  AssetType.Platinum,
  AssetType.Copper,
  AssetType.Wheat,
  AssetType.NaturalGas,
  AssetType.CrudeOil,
  AssetType.Soybeans,
];

export const isOunceFraction = (unit: Unit): boolean => {
  switch (unit) {
    case Unit.HALF_OUNCE_UNIT:
    case Unit.QUARTER_OUNCE_UNIT:
    case Unit.ONE_TENTH_OUNCE_UNIT:
    case Unit.ONE_HUNDREDTH_OUNCE_UNIT:
      return true;
    default:
      return false;
  }
};

export const termsAndConditions = (
  assetType: AssetType | "",
  assetUnit: Unit,
  privateOffer: boolean,
) => {
  if (privateOffer) {
    return [
      `I have received the Programme Memorandum, Private Placement Memorandum, the applicable Pricing Supplement, 
      the Investor Memorandum, and any other relevant documentation related to this subscription (collectively, 
      the “Placement terms and conditions”);`,

      `I confirm that I have read and understood these Placement terms and conditions and agree to be bound by them;`,

      `I acknowledge that the Placement, meaning the offer, described is made only to specifically addressed recipients and 
      is not available to the public and that no action has been taken to permit a public offering in any jurisdiction 
      I confirm that I will become the beneficial owner of any token/s issued to me under this Private Placement;`,

      `I have read and understood the risk factors disclosed, including the possibility of partial or total loss of 
      my initial investment, and I recognise that neither historical performance nor any statements by the platform o
      r issuer guarantee future results;`,

      `To the best of my knowledge, the personal, financial, and/or corporate information 
      I have provided is correct and I undertake to promptly update any changes;`,

      `I am fully aware that no personalised financial, legal, or tax advice has been provided to me and I have 
      independently assessed the suitability of this investment for my own needs, objectives, and risk tolerance; 
      I understand that, under extraordinary circumstances, trading, transfers, or redemptions may be suspended or 
      limited, and that my ability to transact or withdraw could be affected;`,

      `I confirm that I am legally authorised and of legal age, or otherwise eligible, to enter into this subscription,
      and I am not a sanctioned individual or related to any sanctioned person or entity; and I acknowledge that this 
      offer is being made under private placement provisions, that distribution of the Placement terms and conditions 
      to anyone outside of the addressees is not permissible.`,
    ];
  }
  switch (assetType) {
    case AssetType.Bond:
    case AssetType.PreferenceShare:
      return [
        `I have received the Prospectus and have read the terms and conditions of the
        Offer contained in the Prospectus and agree to be bound by them;`,

        `I will become the beneficial owner of the ${getUnitLabel(assetUnit, UnitLabelType.Plural)} of the Company
        issued to me under the Offer;`,

        `I have read and understood the risk factors set out in the Prospectus;`,

        `To the best of my knowledge and belief, the personal details and information
        I have given are correct;`,

        `I am aware of the fact that a full analysis could not be undertaken and that
        there may thus be limitations on the appropriateness of the advice or
        information I received in relation to the Offer;`,

        `I have carefully considered whether the advice or information on its own
        that I have received is appropriate considering my financial situation,
        needs and objectives; and`,

        `To prevent the risk of concluding a transaction that is not appropriate to
        my financial situation, objectives and needs, I have declined the offer of a
        needs analysis and has decided to continue with the Application in this
        Application Request.`,
      ];

    default:
      return [
        `I have received and reviewed all relevant documentation applicable to
        this subscription order, including (where applicable) the Platform’s
        Terms and Conditions, the Investor Memorandum with its risk disclosures,
        the Token Agreement, and any other offering or platform related documents,
        such as the Privacy Policy. I understand the terms and agree to be bound by them.`,

        `I acknowledge that I possess sufficient knowledge, experience, and
        understanding to evaluate the merits and risks of this investment. I
        recognise that this investment may not be suitable for all investors
        and that I have carefully considered my own financial situation,
        objectives, and risk tolerance before subscribing. To this end I
        further confirm that I have conducted my own due diligence on this
        investment and have sought independent financial, legal, and tax
        advice as appropriate. I understand that neither Mesh nor its
        affiliates provides personalised, or otherwise, professional advice
        of any nature to me.`,

        `I have read and understood the risk factors associated with this
        investment, which may include, but are not limited to: Market Risk,
        Credit Risk, Liquidity Risk, Interest Rate Risk, Inflation Risk, and
        Operational Risk. `,

        `I understand that all investments in financial instruments, particularly
        those of a speculative nature, carry the risk of partial or total loss of
        my initial investment. There is no guarantee of profit, and historical
        performance is not indicative of future results. `,

        `I acknowledge that I am solely responsible for ensuring that my
        subscription complies with all applicable local and international laws,
        regulations, and requirements, including but not limited to tax reporting
        and anti-money laundering obligations. I understand that non-compliance
        could lead to penalties, or account restrictions. `,

        `To the maximum extent permitted by applicable law, the platform and its
        affiliates disclaim liability for any direct, indirect, incidental, special,
        or consequential damages, including but not limited to losses resulting from
        market fluctuations, operational disruptions, or regulatory changes. `,

        `I acknowledge that the platform may, under extraordinary circumstances
        (events like force majeure, hardship, regulatory interventions, system outages),
        suspend or limit trading, transfers, or redemptions. Such measures do not
        constitute a waiver of my investor rights but may affect the timing and manner
        in which I can transact or withdraw.  `,

        `I confirm that all personal, financial, or corporate information I have
        provided is accurate and truthful. I agree to promptly update any information
        that becomes outdated or inaccurate and should I not do so, nothing will
        prohibit Mesh from suspending my account on its platform. `,

        `I confirm that I am legally authorised and of legal age, or otherwise
        eligible, to enter into this subscription. If investing on behalf of an
        entity, I warrant that I have due authority to bind that entity.  I am not
        a sanctioned individual under the FATF, TFS, or similar lists nor am I
        related to such a person or entity. `,

        `I understand that any statements or materials from the platform or issuer
        are for informational purposes only and do not constitute personalised
        investment advice. I have used my own independent judgment or consulted
        professional advisors in deciding to participate in this subscription.`,
      ];
  }
};
