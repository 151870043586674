import React, { useMemo, useState } from "react";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import { useApplicationContext } from "context/Application/Application";
import { ClientKYCStatus } from "james/client";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import cx from "classnames";
import { FundAccountDialog } from "views/Accounts/components/FundAccountDialog/FundAccountDialog";

interface TimeButtonProps {
  accountID: string;
  unfunded?: boolean;
  hover: boolean;
  disabled?: boolean;
  time: string;
}

export const TimeButton = ({
  accountID,
  unfunded,
  hover,
  time,
  disabled,
}: TimeButtonProps) => {
  const [endDate] = useState(dayjs(time));
  dayjs.extend(duration);

  const { myClientKYCStatus } = useApplicationContext();
  const [timeLeft] = useState(dayjs.duration(endDate.diff(dayjs())));
  const [fundAccountDialog, setFundAccountDialog] = useState(false);

  const interval = useMemo(() => {
    if (timeLeft.days()) {
      return {
        interval: `${timeLeft.days() > 1 ? "days" : "day"}`,
        time: timeLeft.days(),
      };
    }
    if (timeLeft.hours()) {
      return {
        interval: `${timeLeft.hours() > 1 ? "hours" : "hour"}`,
        time: timeLeft.hours(),
      };
    }
    if (timeLeft.minutes()) {
      return {
        interval: "min",
        time: timeLeft.minutes(),
      };
    }

    return {
      interval: "sec",
      time: timeLeft.seconds(),
    };
  }, []);

  return (
    <>
      {fundAccountDialog && (
        <FundAccountDialog
          open={fundAccountDialog}
          accountID={accountID}
          onClose={() => setFundAccountDialog(!fundAccountDialog)}
        />
      )}
      <Tooltip
        placement={"left"}
        PopperProps={{
          sx: { maxWidth: 280 },
        }}
        title={
          myClientKYCStatus !== ClientKYCStatus.VerifiedStatus && unfunded ? (
            <Typography>
              Subscriptions will close soon, please allow for 3 working days to{" "}
              <Link
                sx={{
                  "&:hover": {
                    color: "#141414",
                  },
                }}
                color="#000000"
                href="https://localhost:3000/kyc"
              >
                verify and fund your account
              </Link>
            </Typography>
          ) : unfunded ? (
            <Typography>
              Subscriptions will close soon, please allow for up to 3 working
              days to{" "}
              <Link
                sx={{
                  "&:hover": {
                    color: "#141414",
                  },
                }}
                color="#000000"
                onClick={() => setFundAccountDialog(!fundAccountDialog)}
              >
                fund your account
              </Link>
            </Typography>
          ) : (
            "Subscriptions close soon, don’t miss out on this offer!"
          )
        }
      >
        <Box
          className={cx({
            hover: hover,
            disabled: disabled,
          })}
          id="time-button"
          sx={(theme) => ({
            background: `radial-gradient(circle, ${theme.palette.primary.main} 0%, #6F0E2B 100%)`,
            border: `2px solid ${theme.palette.custom.midnight}`,
            borderRadius: "50%",
            height: "42px",
            width: "42px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "&.hover, &:hover": {
              background: `radial-gradient(circle, ${theme.palette.primary.main} 40%, #6F0E2B 90%)`,
            },
          })}
        >
          <Typography
            id={"time-button-time-remaining"}
            sx={{ fontSize: "10px", lineHeight: "10px", fontWeight: "bold" }}
            align="center"
          >
            {interval.time}
          </Typography>
          <Typography
            id="time-button-interval"
            sx={{ fontSize: "10px", lineHeight: "10px" }}
            align="center"
          >
            {interval.interval}
          </Typography>
          <Typography
            sx={{ fontSize: "10px", lineHeight: "10px" }}
            align="center"
          >
            left
          </Typography>
        </Box>
      </Tooltip>
    </>
  );
};
