import { useEffect } from "react";
import { useErrorContext } from "context/Error";
import { Client } from "james/client";
import { useApplicationContext } from "context/Application/Application";
import { useBankingDetailsViewStore } from "../store";
import { useAPIContext } from "context/API";
import { ReadManyBankAccountRequest } from "@mesh/common-js/src/banking/bankAccountReader_meshproto_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";

// custom hook to fetch bank account details when the component mounts.
export const useInitialDataFetch = (client: Client) => {
  const state = useBankingDetailsViewStore();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { authContext } = useApplicationContext();
  const {
    banking: { bankAccountReaderUNSCOPED },
  } = useAPIContext();

  useEffect(() => {
    const timeout = setTimeout(async () => {
      state.setLoading(true);
      // IIFE to handle async operations within useEffect.
      // Attempt to fetch bank account details using ClientDetailsAccessor.

      const request = new ReadManyBankAccountRequest()
        .setContext(authContext.toFuture())
        .setCriteriaList([newTextExactCriterion("ownerID", client.ownerID)]);

      try {
        const response =
          await bankAccountReaderUNSCOPED.readManyBankAccountUNSCOPED(request);

        if (response.getTotal() == 0) {
          state.setBankNotFound(true);
        } else {
          // Update local state with the fetched bank account details.
          state.updateStateWithBankAccount(response.getRecordsList()[0]);
          state.setLastUpdateBy(
            response.getRecordsList()[0].getAuditentry()?.getUserid() || "",
          );
          state.setLastUpdateDate(
            response
              .getRecordsList()[0]
              .getAuditentry()
              ?.getTime()
              ?.toString() || "",
          );
          state.setCachedBankAccount(response.getRecordsList()[0]);
        }
      } catch (e) {
        // Handle errors by translating and possibly setting UI-specific flags.
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error update client bank account details: ${
            err.message ? err.message : err.toString()
          }`,
        );
        errorContextDefaultErrorFeedback(e);
      }
      // Reset loading state regardless of outcome.
      state.setLoading(false);
    });

    return () => {
      clearTimeout(timeout);
      state.setLoading(false);
    };
  }, [client, authContext]);
};
