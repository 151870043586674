"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToDefundOrderState = stringToDefundOrderState;
exports.defundOrderStateToString = defundOrderStateToString;
const defundOrderState_pb_1 = require("./defundOrderState_pb");
const defundOrderStateToStringMap = {
    [defundOrderState_pb_1.DefundOrderState.CANCELLED_DEFUND_ORDER_STATE]: "Cancelled",
    [defundOrderState_pb_1.DefundOrderState.PENDING_DEFUND_ORDER_STATE]: "Pending",
    [defundOrderState_pb_1.DefundOrderState.SETTLED_DEFUND_ORDER_STATE]: "Settled",
    [defundOrderState_pb_1.DefundOrderState.AWAITING_SETTLEMENT_DEFUND_ORDER_STATE]: "Awaiting Settlement",
    [defundOrderState_pb_1.DefundOrderState.SETTLEMENT_IN_PROGRESS_DEFUND_ORDER_STATE]: "Settlement In Progress",
    [defundOrderState_pb_1.DefundOrderState.UNDER_INVESTIGATION_DEFUND_ORDER_STATE]: "Under Investigation",
};
const defundOrderStringToStateMap = {
    Cancelled: defundOrderState_pb_1.DefundOrderState.CANCELLED_DEFUND_ORDER_STATE,
    Pending: defundOrderState_pb_1.DefundOrderState.PENDING_DEFUND_ORDER_STATE,
    Settled: defundOrderState_pb_1.DefundOrderState.SETTLED_DEFUND_ORDER_STATE,
    "Awaiting Settlement": defundOrderState_pb_1.DefundOrderState.AWAITING_SETTLEMENT_DEFUND_ORDER_STATE,
    "Settlement In Progress": defundOrderState_pb_1.DefundOrderState.SETTLEMENT_IN_PROGRESS_DEFUND_ORDER_STATE,
    "Under Investigation": defundOrderState_pb_1.DefundOrderState.UNDER_INVESTIGATION_DEFUND_ORDER_STATE,
};
class UnsupportedDefundOrderStringError extends Error {
    constructor(defundOrderStateString) {
        const message = `Unsupported defund order state: ${defundOrderStateString}`;
        super(message);
        this.defundOrderStateString = defundOrderStateString;
    }
}
/**
 * Converts a custom string representation of a DefundOrderState to a DefundOrderState enum instance.
 * @param {string} defundOrderStateString - The custom string representation of the defund order state to convert.
 * @returns {DefundOrderState} The defund order state.
 */
function stringToDefundOrderState(defundOrderStateString) {
    if (defundOrderStateString in defundOrderStringToStateMap) {
        return defundOrderStringToStateMap[defundOrderStateString];
    }
    else {
        throw new UnsupportedDefundOrderStringError(defundOrderStateString);
    }
}
class UnsupportedDefundOrderStateError extends Error {
    constructor(defundOrderStateState) {
        const message = `Unsupported defund order state: ${defundOrderStateState}`;
        super(message);
        this.defundOrderStateState = defundOrderStateState;
    }
}
/**
 * Converts a DefundOrderState enum instance to a custom string representation.
 * @param {DefundOrderState} defundOrderState - The defund order state to convert.
 * @returns {string} The custom string representation of the defund order state.
 */
function defundOrderStateToString(defundOrderState) {
    if (defundOrderState in defundOrderStateToStringMap) {
        return defundOrderStateToStringMap[defundOrderState];
    }
    else {
        throw new UnsupportedDefundOrderStateError(defundOrderState);
    }
}
