import React, { useState } from "react";
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import { RouteType } from "routes/Route";
import { Router } from "routes";
import { Table } from "./components/Table/Table";
import { PreApproval } from "./PreApproval/PreApproval";
import { useNavigate } from "react-router-dom";

const complianceTabRoutes: RouteType[] = [
  {
    name: "Client List",
    id: "compliance-table",
    path: "/market-management/compliance",
    component: <Table />,
  },
  {
    name: "Pre-Approval", // PreApproval is Temporary - to be removed after aTrade Farming (Ticket created)
    id: "pre-approval",
    path: "/market-management/compliance/pre-approval",
    component: <PreApproval />,
  },
];

export function Compliance() {
  return (
    <Box>
      <NavigationSection />
      <Box
        sx={{
          padding: (theme) => theme.spacing(2, 2, 0, 2),
          height: "calc(100vh - 100px)",
        }}
        className={"meshScroll"}
      >
        <Router
          baseURL={"/market-management/compliance"}
          routes={complianceTabRoutes}
        />
      </Box>
    </Box>
  );
}

const NavigationSection = () => {
  const [tab, setTab] = useState("");
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        paddingLeft: (theme) => theme.spacing(4),
      }}
      square
    >
      <Grid container>
        <Grid item>
          <Tabs
            value={tab}
            textColor={"inherit"}
            onChange={(e, v) => {
              navigate(v);
              setTab(v);
            }}
          >
            <Tab key={0} value={""} label={"Clients"} />
            <Tab key={1} value={"pre-approval"} label={"Pre-Approval"} />
          </Tabs>
        </Grid>
      </Grid>
    </Paper>
  );
};
