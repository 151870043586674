import React, { useEffect, useState } from "react";
import { useMediaQuery, Theme } from "@mui/material";
import { MarketListingViewModel } from "james/views/marketListingView";
import { useIsMounted } from "hooks";
import { Amount as AmountType } from "james/ledger";
import { SubscriptionOrderStateController } from "james/market/SubscriptionOrderStateController";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import LogRocket from "logrocket";
import {
  FormData,
  FormDataUpdaterSpecsType,
  formDataUpdaterSpecs,
  formDataValidationFunc,
} from "./useValidatedForm";
import { useValidatedForm } from "hooks/useForm";
import { SubscriptionOrder } from "james/market/SubscriptionOrder";
import { AssetEvent } from "const/logRocket";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { usePotentialSourceAccount } from "../usePotentialSourceAccount";
import { useErrorContext } from "context/Error";
import { useMarketContext } from "context/Market";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import {
  getAvailableBalance,
  getTokenBalance,
} from "@mesh/common-js/dist/views/stellarAccountView";
import { MarketSubscriptionOrderBookViewModel } from "james/views/marketSubscriptionOrderBookView";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { ConfirmationContent } from "./components/ConfirmationContent";
import { InputContent } from "./components/InputContent";
import { TwoStepDialog } from "components/TwoStepDialog";
import { DataComponentInfo, InteractionDriver } from "const/gtm";
import { SubscriptionDialogHeader } from "../SubscriptionDialogHeader";
import { InputFooter } from "./components/InputFooter";
import { ConfirmationFooter } from "./components/ConfirmationFooter";
import { usePriceBreakdown } from "./usePriceBreakdown";

export interface FlexiblePriceDialogFlowProps {
  open: boolean;
  marketListingViewModel: MarketListingViewModel;
  closeDialog: () => void;
}

export const FlexiblePriceDialogFlow = ({
  open,
  marketListingViewModel,
  closeDialog,
}: FlexiblePriceDialogFlowProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const { marketContextMonitorSubscriptionOrder } = useMarketContext();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submittingOrder, setSubmittingOrder] = useState(false);

  const {
    initialAccountLoadedSuccessfully,
    potentialSourceAccountsLoaded,
    stellarAccountContextKeys,
    potentialSourceAccounts,
    setInitialAccountLoadedSuccessfully,
    stellarAccountContextLoading,
    stellarAccountContextError,
  } = usePotentialSourceAccount();

  // Validated form
  const defaultFormData: FormData = {
    investmentAmount: new AmountType(),
    tokenAmount: new AmountType(),
    accountBalance: new AmountType(),
    termsAndConditionsAccepted: false,
    termsAndConditionsDisplayed: false,
    subscriptionOrderBookViewModel: new MarketSubscriptionOrderBookViewModel(),
    selectedSourceAccountViewModel: new StellarAccountViewModel(),
    selectedAccountIdx: 0,
    isSignatoryOnSourceAccount: false,
    userKeys: [],
    initialized: false,
  };

  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm<FormData, FormDataUpdaterSpecsType>(
    formDataValidationFunc,
    async () => {
      const subscriptionOrderBookViewModel =
        marketListingViewModel.marketSubscriptionOrderBookViewModel ||
        new MarketSubscriptionOrderBookViewModel();

      const investmentAmount =
        subscriptionOrderBookViewModel.minimumOrderAmount || new AmountType();

      const unitPrice =
        subscriptionOrderBookViewModel.unitPrice || new AmountType();

      const tokenAmount = subscriptionOrderBookViewModel.token.newAmountOf(
        investmentAmount.value.dividedBy(unitPrice.value),
      );

      const initialFormData: FormData = {
        investmentAmount: new AmountType(investmentAmount),
        tokenAmount: new AmountType(tokenAmount),
        accountBalance: new AmountType(),
        termsAndConditionsAccepted: false,
        termsAndConditionsDisplayed: false,
        subscriptionOrderBookViewModel,
        selectedSourceAccountViewModel: new StellarAccountViewModel(),
        selectedAccountIdx: 0,
        isSignatoryOnSourceAccount: false,
        userKeys: stellarAccountContextKeys,
        initialized: false,
      };

      return initialFormData;
    },
    formDataUpdaterSpecs,
    defaultFormData,
    new Set<string>(["investmentAmount", "selectedSourceAccountViewModel"]),
  );

  // Check for stellar account context load error
  // until initial account is loaded
  useEffect(() => {
    if (initialAccountLoadedSuccessfully || stellarAccountContextLoading)
      return;

    if (stellarAccountContextError) {
      console.error(`initialization error: ${stellarAccountContextError}`);
      enqueueSnackbar(`Initialization Error: ${stellarAccountContextError}`, {
        variant: "error",
      });
      closeDialog();
    }
    setInitialAccountLoadedSuccessfully(true);
  }, [
    stellarAccountContextError,
    stellarAccountContextLoading,
    initialAccountLoadedSuccessfully,
    enqueueSnackbar,
  ]);

  // Set the source account and update account balance in quote token
  const setSourceAccount = (accountIndex: number) => {
    if (!potentialSourceAccountsLoaded) return;
    if (
      !marketListingViewModel.marketSubscriptionOrderBookViewModel
        ?.minimumOrderAmount.token
    )
      return;

    const quoteToken =
      marketListingViewModel.marketSubscriptionOrderBookViewModel
        .minimumOrderAmount.token;

    const quoteTokenBalance = getTokenBalance(
      potentialSourceAccounts[accountIndex],
      quoteToken.toFutureToken(),
    );
    formDataUpdate.selectedSourceAccountViewModel(
      potentialSourceAccounts[accountIndex],
    );
    formDataUpdate.accountBalance(
      quoteTokenBalance
        ? AmountType.fromFutureAmount(getAvailableBalance(quoteTokenBalance))
        : quoteToken.newAmountOf("0"),
    );

    formDataUpdate.initialized(true);
  };

  // Set source account when something account related changes
  useEffect(() => {
    setSourceAccount(formData.selectedAccountIdx);
  }, [
    potentialSourceAccounts,
    potentialSourceAccountsLoaded,
    formData.selectedAccountIdx,
  ]);

  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const [tokenViewModelsLoaded, setTokenViewModelsLoaded] = useState(false);
  const [quoteTokenViewModel, setQuoteTokenViewModel] = useState<
    LedgerTokenViewModel | undefined
  >(undefined);

  // Fetch quote and base token view models
  useEffect(() => {
    if (!marketListingViewModel.marketSubscriptionOrderBookViewModel) return;
    const subscriptionBookViewModel =
      marketListingViewModel.marketSubscriptionOrderBookViewModel;

    (async () => {
      if (stellarAccountContextLoading) {
        return;
      }

      try {
        let retrievedQuoteTokenViewModel!: LedgerTokenViewModel;

        await Promise.all([
          // retrieve quote token view model
          (async () => {
            retrievedQuoteTokenViewModel = await getLedgerTokenViewModel(
              subscriptionBookViewModel.minimumOrderAmount.token,
            );
          })(),
        ]);

        if (!retrievedQuoteTokenViewModel) {
          console.error("Error retrieving base and quote token view models");
          return;
        }
        setQuoteTokenViewModel(retrievedQuoteTokenViewModel);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error initialising: ${err.message ? err.message : err.toString()}`,
        );
      }
      if (isMounted()) {
        setTokenViewModelsLoaded(true);
      }
    })();
  }, [
    isMounted,
    stellarAccountContextLoading,
    stellarAccountContextError,
    authContext,
    getLedgerTokenViewModel,
  ]);

  // Submit subscription order and track progress
  const handleOrderSubmission = async () => {
    setSubmittingOrder(true);
    let subscriptionOrder: SubscriptionOrder;
    try {
      subscriptionOrder = (
        await SubscriptionOrderStateController.SubmitSubscriptionOrder({
          context: authContext,
          sourceAccountID: formData.selectedSourceAccountViewModel.getId(),
          token: marketListingViewModel.token,
          amount: formData.investmentAmount,
        })
      ).subscriptionOrder;

      // notify that submission is in progress
      enqueueSnackbar(
        `Subscription #${subscriptionOrder.number} is being submitted`,
        {
          variant: "info",
        },
      );
      getLedgerTokenViewModel(marketListingViewModel.token)
        .then((ledgerTokenViewModel) => {
          marketContextMonitorSubscriptionOrder({
            subscriptionOrder: subscriptionOrder,
            assetListingViewModel: marketListingViewModel,
            ledgerTokenViewModel: ledgerTokenViewModel,
          });
        })
        .catch((e) => {
          console.error("error fetching ledger token view model:", e);
        });
      closeDialog();
      setSubmittingOrder(false);

      LogRocket.track(AssetEvent.placeSubscription, {
        assetName: marketListingViewModel.assetName,
        assetToken: marketListingViewModel.token.code,
        assetType: marketListingViewModel.assetType,
      });
    } catch (e) {
      console.error("`error submitting subscription order`", e);
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Submitting Order: ${err.message}`, {
        variant: "warning",
      });
      setSubmittingOrder(false);
      return;
    }
  };

  // get price breakdown info
  const [subOrderBookViewModel, setSubOrderBookViewModel] =
    useState<MarketSubscriptionOrderBookViewModel>(
      new MarketSubscriptionOrderBookViewModel(),
    );

  useEffect(() => {
    if (!marketListingViewModel.marketSubscriptionOrderBookViewModel) {
      return;
    }
    setSubOrderBookViewModel(
      marketListingViewModel.marketSubscriptionOrderBookViewModel,
    );
  }, [marketListingViewModel]);

  const { hasPriceBreakdown, estSpotPlusMarginPrice, estStorageCost } =
    usePriceBreakdown(
      marketListingViewModel.assetType,
      marketListingViewModel.assetUnit,
      subOrderBookViewModel.unitPrice,
      subOrderBookViewModel.unitPriceDescription,
    );

  return (
    <TwoStepDialog
      open={open}
      isMobile={isMobile}
      initialising={
        stellarAccountContextLoading ||
        !tokenViewModelsLoaded ||
        !potentialSourceAccountsLoaded ||
        formDataValidationInProgress ||
        !formData.initialized
      }
      isStepTwo={showConfirmation}
      header={
        <SubscriptionDialogHeader
          marketListingViewModel={marketListingViewModel}
          submittingOrder={submittingOrder}
          closeDialog={closeDialog}
        />
      }
      stepOneDataComponentInfo={JSON.stringify({
        component_id: "subscription_ticket",
        component_business_name: "subscription_ticket",
        component_title: marketListingViewModel.assetName,
        component_driver: InteractionDriver.form,
      } as DataComponentInfo)}
      stepOneContent={
        <InputContent
          isMobile={isMobile}
          loading={submittingOrder} // CBFIX
          marketListingViewModel={marketListingViewModel}
          quoteTokenViewModel={quoteTokenViewModel}
          formData={formData}
          formDataUpdate={formDataUpdate}
          formDataValidationResult={formDataValidationResult}
          potentialSourceAccounts={potentialSourceAccounts}
          hasPriceBreakdown={hasPriceBreakdown}
          unitPrice={subOrderBookViewModel.unitPrice}
          unitPriceDescription={subOrderBookViewModel.unitPriceDescription}
          estSpotPlusMarginPrice={estSpotPlusMarginPrice}
          estStorageCost={estStorageCost}
        />
      }
      stepOneFooter={
        <InputFooter
          isMobile={isMobile}
          loading={submittingOrder} // CBFIX
          assetName={marketListingViewModel.assetName}
          formDataValidationResult={formDataValidationResult}
          setShowConfirmation={setShowConfirmation}
        />
      }
      // stepTwoDataComponentInfo={}
      stepTwoContent={
        <ConfirmationContent
          isMobile={isMobile}
          assetType={marketListingViewModel.assetType}
          assetUnit={marketListingViewModel.assetUnit}
          submittingOrder={submittingOrder}
          formData={formData}
          formDataUpdate={formDataUpdate}
          privateOffer={marketListingViewModel.privateOffer}
        />
      }
      stepTwoFooter={
        <ConfirmationFooter
          isMobile={isMobile}
          termsAndConditionsAccepted={formData.termsAndConditionsAccepted}
          assetName={marketListingViewModel.assetName}
          submittingOrder={submittingOrder}
          submitOrder={handleOrderSubmission}
          setShowConfirmation={setShowConfirmation}
          closeDialog={closeDialog}
        />
      }
    />
  );
};
