import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { HowDoesItWorkSectionProps } from "./HowDoesItWork";
import { ContentType } from "./HowDoesItWorkContentProvider";

type DisclaimerSectionType = {
  body: ContentType[];
};

export const DisclaimerSection = ({ section }: HowDoesItWorkSectionProps) => {
  const content = section.content as DisclaimerSectionType;
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.custom.grape,
          gap: 2,
          padding: {
            sm: 5,
            xs: theme.spacing(5, 3),
          },
        }}
      >
        {content.body.map((v, i, array) => {
          return (
            <Box
              key={i}
              sx={{
                flex: 1,
                pb: {
                  sm: 0,
                  xs: array.length - 1 !== i ? 2 : 0,
                },
              }}
            >
              <Typography sx={{ color: theme.palette.text.secondary }}>
                {content.body[i].info}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
