import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import background from "./background.webp";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export const TroyGoldLaunchBanner = () => {
  return (
    <Box
      id="troygold_explore_bond_banner"
      sx={{
        height: "200px",
        backgroundSize: "1280px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundImage: `url(${background})`,
        backgroundPosition: {
          md: "0%",
          sm: "5%",
        },
      }}
      data-component-info={JSON.stringify({
        component_id: "hero_banner",
        component_business_name: "hero banner",
        component_title: "troygold",
        component_driver: "showcase",
      })}
      data-testid="troygold_explore_bond_banner"
    >
      <Box
        sx={{
          width: 600,
          ml: {
            lg: "600px",
            md: "440px",
            sm: "328px",
          },
          mr: {
            sm: 2,
            md: 0,
          },
          textAlign: {
            md: "left",
            sm: "right",
          },
        }}
      >
        <Typography fontSize={28} letterSpacing={0} lineHeight={"32px"}>
          The best time to invest in gold, is now.
        </Typography>
        <Stack
          direction={"column"}
          spacing={1}
          sx={{
            alignItems: {
              md: "start",
              sm: "end",
            },
            textAlign: {
              md: "left",
              sm: "right",
            },
          }}
        >
          <Typography
            fontSize={14}
            letterSpacing={0}
            color={(theme) => theme.palette.text.secondary}
            sx={(theme) => ({
              wordBreak: "keep-all",
              [theme.breakpoints.between("sm", "md")]: {
                maxWidth: "calc(100% - 50px)",
              },
            })}
          >
            Best reatail price for a Krugerrand <sup> TM</sup> for 2 - weeks
            only.
          </Typography>
          <Button
            id="troygoldBanner-explore-button"
            href="https://app.mesh.trade/public/market/asset-overview?code=TGLD&issuer=GCRCLUSKGUASW2PMIGZXAKGCI7NSP3MTEJ7PYA62NA5LZ5AX2OESW6CV&network=Stellar%20Public&utm_source=mesh-app&utm_medium=organic&utm_campaign=mtg_get_ttl_troygold-krugerrand_ssc-83_feb-25_mar-25_za_pfmza_commodity_mesh-app_cro_none_platform_none&utm_marketing_tactic=cro&utm_creative_format=button&utm_id=mtg_get_ttl_troygold-krugerrand_ssc-83&utm_content=join-wl&utm_term=organic_join-wl&utm_source_platform=carousel-banners"
            variant="contained"
            color="primary"
            rel="noopener noreferrer"
            target="_blank"
            data-link-info={JSON.stringify({
              content_interaction_id: "troy-gold-banner-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "join waiting list",
              content_interaction_type: InteractionType.Button,
              content_interaction_driver: InteractionDriver.Explore,
            } as DataLinkInfoType)}
          >
            Subscribe Now
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
