import React from "react";
import {
  DialogProps,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  Theme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface PrivatePlacementDialogProps {
  dialogProps: DialogProps;
  closeDialog: () => void;
  openNextDialog: () => void;
}

export const PrivatePlacementDialog = ({
  dialogProps,
  closeDialog,
  openNextDialog,
}: PrivatePlacementDialogProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        sx: {
          maxWidth: smDown ? "100%" : 640, // Set width conditionally
        },
      }}
    >
      <DialogTitle
        sx={(theme) => ({
          boxShadow: {
            sm: 0,
            xs: 2,
          },
          pl: 3,
          display: "grid",
          gridTemplateColumns: "1fr auto",
          gap: theme.spacing(1),
          alignItems: "center",
          backgroundColor: theme.palette.custom.midnight,
          borderBottom: "none",
        })}
      >
        <Typography variant="h5" children={"Trading a Private Offer"} />
        <IconButton
          sx={(theme) => ({ color: theme.palette.text.secondary })}
          onClick={() => {
            closeDialog();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, mx: 3, my: 2 }}>
        <Typography sx={(theme) => ({ color: theme.palette.text.secondary })}>
          You are about to trade a privately placed asset. These assets are not
          publicly listed and may have limited liquidity, restricted market
          data, and higher investment risks. By proceeding, you acknowledge and
          accept these risks and confirm that you meet the necessary investor
          qualifications.
        </Typography>
      </DialogContent>
      <DialogActions sx={(theme) => ({ padding: theme.spacing(2, 3, 3, 3) })}>
        <Button
          variant="outlined"
          onClick={() => {
            closeDialog();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            closeDialog();
            openNextDialog();
          }}
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};
