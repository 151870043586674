import { useCurrentAPICall, useIsMounted } from "hooks";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import {
  DirectOrderSubmitter,
  InvestorSubmitDirectSellOrderRequest,
} from "james/market/DirectOrderSubmitter";
import {
  DirectOrderFeeCalculator,
  CalculateDirectOrderFeeResponse,
} from "james/remuneration/DirectOrderFeeCalculator";
import {
  DirectOrder,
  DirectOrderState,
  DirectOrderType,
} from "james/market/DirectOrder";
import { TouchedFields, ValidationResult } from "common/validation";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { TextNumField } from "components/FormFields";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount } from "james/ledger";
import cx from "classnames";
import InfoIcon from "@mui/icons-material/Info";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Balance,
  Model as StellarAccountViewModel,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { formatTextNum } from "utilities/number";
import { NumFieldHlpTxt } from "validationHelperText";
import { GroupNotificationChannel } from "james/group";
import {
  MarketDirectOrderViewModelChangedNotification,
  MarketDirectOrderViewModelChangedNotificationTypeName,
  MarketDirectOrderViewNotificationChannelName,
} from "james/views/marketDirectOrderView";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";
import { QuoteParameter } from "james/market/Mechanism";
import { Header } from "../Header";
import { InvestorDirectOrderCardProps } from "./InvestorDirectOrderCard";
import { BigNumber } from "bignumber.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import range from "lodash/range";
import LogRocket from "logrocket";
import { AssetEvent } from "const/logRocket";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { userTypingAPICallDebounceIntervalMS } from "common/debouncing";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import {
  TransactionDetails,
  TransactionStage,
  TransactionTypes,
} from "types/gtm";
import dayjs from "dayjs";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import {
  getAvailableBalance,
  getTokenBalance,
} from "@mesh/common-js/dist/views/stellarAccountView";

const PREFIX = "SellCard";

const classes = {
  sectionWithPadding: `${PREFIX}-sectionWithPadding`,
  sectionWithBottomBorder: `${PREFIX}-sectionWithBottomBorder`,
  sectionWithColumnsPoint5Gap: `${PREFIX}-sectionWithColumnsPoint5Gap`,
  titleMessage: `${PREFIX}-titleMessage`,
  textNumFieldCode: `${PREFIX}-textNumFieldCode`,
  balanceLayout: `${PREFIX}-balanceLayout`,
  balanceAvailableText: `${PREFIX}-balanceAvailableText`,
  errorHelperTextLayout: `${PREFIX}-errorHelperTextLayout`,
  estimatedValuesTitle: `${PREFIX}-estimatedValuesTitle`,
  estimatedValuesInfoIcon: `${PREFIX}-estimatedValuesInfoIcon`,
  estimatedValuesLayout: `${PREFIX}-estimatedValuesLayout`,
  tradeFeeLayout: `${PREFIX}-tradeFeeLayout`,
  tradeFeeWhyTheseFeesLink: `${PREFIX}-tradeFeeWhyTheseFeesLink`,
  orderBreakdownControlRow: `${PREFIX}-orderBreakdownControlRow`,
  orderBreakDownLayout: `${PREFIX}-orderBreakDownLayout`,
  ledgerAmountCode: `${PREFIX}-ledgerAmountCode`,
  ledgerAmountValue: `${PREFIX}-ledgerAmountValue`,
  secondaryText: `${PREFIX}-secondaryText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Dialog)(({ theme }) => ({
  [`& .${classes.sectionWithPadding}`]: {
    width: "100%",
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 6),
    },
  },

  [`& .${classes.sectionWithBottomBorder}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.sectionWithColumnsPoint5Gap}`]: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
  },

  [`& .${classes.titleMessage}`]: {
    padding: theme.spacing(3, 0, 3, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 0, 3, 0),
    },
    maxWidth: 350,
    fontWeight: "bold",
  },

  [`& .${classes.textNumFieldCode}`]: {
    marginRight: 8,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.balanceLayout}`]: {
    paddingLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(3),
    },
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.balanceAvailableText}`]: {
    color: theme.palette.text.secondary,
  },

  [`& .${classes.errorHelperTextLayout}`]: {
    maxWidth: 300,
    paddingLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(3),
    },
  },

  [`& .${classes.estimatedValuesTitle}`]: {
    padding: theme.spacing(3, 0),
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${classes.estimatedValuesInfoIcon}`]: {
    color: theme.palette.secondary.light,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },

  [`& .${classes.estimatedValuesLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.tradeFeeLayout}`]: {
    padding: theme.spacing(3, 0),
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },

  [`& .${classes.tradeFeeWhyTheseFeesLink}`]: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.orderBreakdownControlRow}`]: {
    padding: theme.spacing(3, 0),
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },

  [`& .${classes.orderBreakDownLayout}`]: {
    display: "grid",
    paddingBottom: theme.spacing(3),
    gridTemplateColumns: "auto 1fr",
  },

  [`& .${classes.ledgerAmountCode}`]: {
    color: alpha(theme.palette.warning.main, 0.38),
    cursor: "pointer",
    "&:hover": {
      color: alpha(theme.palette.warning.main, 0.5),
    },
  },

  [`& .${classes.ledgerAmountValue}`]: {
    color: theme.palette.warning.main,
  },

  [`& .${classes.secondaryText}`]: {
    color: theme.palette.text.secondary,
  },
}));

const infoIconContent =
  "The price displayed is an estimation. " +
  "The final price is captured with confirmation, and might differ from the " +
  "indicative price. Tokens are therefore also estimated when ordering.";

function validateInvestorSubmitDirectSellOrderRequest(
  request: InvestorSubmitDirectSellOrderRequest,
  feeCalculationResult: CalculateDirectOrderFeeResponse,
  assetIssuanceTokenBalance: Balance,
  assetIssuanceTokenViewModel: LedgerTokenViewModel,
  touchedFields: TouchedFields,
  ignoreTouchedFields: boolean,
  marketMechanismQuoteParameter: QuoteParameter,
  assetFractionalisationAllowed: boolean,
): ValidationResult {
  // prepare a validation result
  const validationResult: ValidationResult = {
    // assumed to be true -
    // any error must set to false regardless of touched field state
    valid: true,
    // field validations
    fieldValidations: {},
  };

  if (request.slippage.gt(100)) {
    validationResult.fieldValidations.slippage = "Must be less than 100%";
    validationResult.valid = false;
  }

  // if tokens not set
  if (request.tokens.value.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.tokens) {
      // then an error message should be shown on it
      validationResult.fieldValidations.tokens =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  } else if (
    decimalToBigNumber(assetIssuanceTokenBalance.getAmount()?.getValue()).lt(
      request.tokens.value,
    )
  ) {
    // if issuance token balance is not sufficient
    // then validation has failed
    validationResult.valid = false;

    // then an error message should be shown on it REGARDLESS of touched
    // field state
    validationResult.fieldValidations.tokens = `Insufficient Balance: ${formatTextNum(
      decimalToBigNumber(assetIssuanceTokenBalance.getAmount()?.getValue()),
      {
        noDecimalPlaces: 7,
        addDecimalPadding: false,
      },
    )} ${assetIssuanceTokenViewModel.token.code}`;
  }

  if (
    // if resultant token amount is out of
    // the deal size bounds as set on the listing
    feeCalculationResult.tokens.gt(
      marketMechanismQuoteParameter.maximumDealSize.value,
    ) ||
    feeCalculationResult.tokens.lt(
      marketMechanismQuoteParameter.minimumDealSize.value,
    )
  ) {
    // then validation has failed
    validationResult.valid = false;

    // and if the amountIncl field has been touched
    if (ignoreTouchedFields || touchedFields.tokens) {
      // then an error message should be shown on the 'buttonTokens'
      validationResult.fieldValidations.buttonTokens = `Order size out of bounds. Min ${formatTextNum(
        marketMechanismQuoteParameter.minimumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: false,
        },
      )} Max ${formatTextNum(
        marketMechanismQuoteParameter.maximumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: false,
        },
      )} ${assetIssuanceTokenViewModel.token.code}`;
    }
  }

  // apply asset fractionalisation allowed check
  if (
    !(assetFractionalisationAllowed || feeCalculationResult.tokens.isInteger())
  ) {
    // if the check fails then validation has failed
    validationResult.valid = false;

    // and if the tokensField has been touched
    if (ignoreTouchedFields || touchedFields.tokens) {
      // then an error message should be shwon on the tokens text num field
      validationResult.fieldValidations.tokens = "Must be a whole number";
    }
  }

  return validationResult;
}

export function SellCard(props: InvestorDirectOrderCardProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const isMounted = useIsMounted();

  const { authContext, myClient } = useApplicationContext();
  const { stellarAccountContext } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const { registerNotificationCallback } = useNotificationContext();
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [showOrderBreakdown, setShowOrderBreakdown] = useState(false);
  const toggleShowOrderBreakDown = () => {
    // open order breakdown
    setShowOrderBreakdown(!showOrderBreakdown);

    // and scroll to the bottom in 500ms
    range(10, 500, 10).forEach((v) =>
      setTimeout(() => {
        if (dialogContentRef.current) {
          dialogContentRef.current.scrollTop =
            dialogContentRef.current.scrollHeight;
        }
      }, v),
    );
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { pushTransactionStart, pushTransactionAbandon } =
    useGTMTriggersPusher();

  // ---- request ----
  const [investorDirectSellOrderRequest, setInvestorDirectSellOrderRequest] =
    useState<InvestorSubmitDirectSellOrderRequest>({
      context: authContext,
      initiatingPartyGroupID: new Client(myClient).ownerID,
      tokens: props.listing.token.newAmountOf("0"),
      estimatedPrice: props.indicativePrice.sellPrice,
      slippage: new BigNumber(10),
    });

  // ---- fee calculation (updated each time request amountIncl changes) ----
  const [calculateDirectOrderFeeResponse, setCalculateDirectOrderFeeResponse] =
    useState<CalculateDirectOrderFeeResponse>({
      feeAmount: new BigNumber("0"),
      vatAmount: new BigNumber("0"),
      amountIncl: new BigNumber("0"),
      amountExcl: new BigNumber("0"),
      tokens: new BigNumber("0"),
    });
  const [feeCalculationInProgress, setFeeCalculationInProgress] =
    useState(false);
  const feeCalculationTimeoutRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const [
    isCurrentCalculateDirectOrderFeeAPICall,
    initCalculateDirectOrderFeeAPICall,
  ] = useCurrentAPICall();
  useEffect(() => {
    if (!isMounted()) {
      return;
    }

    // only perform fee calculation of a non-zero amountIncl is entered
    if (investorDirectSellOrderRequest.tokens.value.isZero()) {
      setCalculateDirectOrderFeeResponse({
        feeAmount: new BigNumber("0"),
        vatAmount: new BigNumber("0"),
        amountIncl: new BigNumber("0"),
        amountExcl: new BigNumber("0"),
        tokens: new BigNumber("0"),
      });
      return;
    }

    // indicate that fee calculation is in progress
    setFeeCalculationInProgress(true);

    // initialise API call
    const { apiCallID, abortController } = initCalculateDirectOrderFeeAPICall();

    // set up new fee calculation to take place after debounce interval
    clearTimeout(feeCalculationTimeoutRef.current);
    feeCalculationTimeoutRef.current = setTimeout(async () => {
      try {
        const result = await DirectOrderFeeCalculator.CalculateDirectOrderFee(
          {
            context: authContext,
            directOrderType: DirectOrderType.Sell,
            price: props.indicativePrice.sellPrice.value,
            tokens: investorDirectSellOrderRequest.tokens.value,
          },
          { signal: abortController.signal },
        );
        if (isMounted() && isCurrentCalculateDirectOrderFeeAPICall(apiCallID)) {
          setCalculateDirectOrderFeeResponse(result);
        } else {
          return;
        }
      } catch (e) {
        if (
          errorContextErrorTranslator.translateError(e).code ===
          JSONRPCCallAbortedError.ErrorCode
        ) {
          return;
        }
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error calculating direct order fee: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setFeeCalculationInProgress(false);
    }, userTypingAPICallDebounceIntervalMS);
  }, [
    isMounted,
    authContext,
    investorDirectSellOrderRequest.tokens,
    props.indicativePrice.sellPrice,
  ]);

  // ----- Request update and validation -----
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [requestValidationResult, setRequestValidationResult] =
    useState<ValidationResult>({
      valid: false,
      fieldValidations: {},
    });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  type InvestorSubmitDirectSellOrderRequestField =
    keyof InvestorSubmitDirectSellOrderRequest;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type InvestorSubmitDirectSellOrderRequestValue<
    T extends InvestorSubmitDirectSellOrderRequestField,
  > = InvestorSubmitDirectSellOrderRequest[T];
  // request update handler
  const handleUpdateRequest =
    (field: string, fieldsAffected?: string[]) =>
    <T extends InvestorSubmitDirectSellOrderRequestField>(
      newValue: InvestorSubmitDirectSellOrderRequestValue<T>,
    ) => {
      // prepare updated request
      const updatedRequest = {
        ...investorDirectSellOrderRequest,
        [field]: newValue,
      };

      // prepare updated touched fields
      const updatedTouchedFields = {
        ...touchedFields,
        [field]: true,
      };
      if (fieldsAffected) {
        fieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      setTouchedFields(updatedTouchedFields);
      setInvestorDirectSellOrderRequest(updatedRequest);
    };

  // update asset balance and signatory status each time selected account changes
  const [assetIssuanceTokenBalance, setAssetIssuanceTokenBalance] = useState(
    new Balance(),
  );
  const [userIsSignatoryOnTradingAcc, setUserIsSignatoryOnTradingAcc] =
    useState(false);
  useEffect(() => {
    (async () => {
      const selectedAccount = props.potentialTradingAccounts.find(
        (acc) =>
          acc.getOwnerid() ===
          investorDirectSellOrderRequest.initiatingPartyGroupID,
      );
      if (selectedAccount) {
        try {
          if (isMounted()) {
            setUserIsSignatoryOnTradingAcc(
              await stellarAccountContext.checkUserSignatoryOnAccount(
                LedgerIDIdentifier(selectedAccount.getLedgerid()),
              ),
            );
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining if user is signatory on account: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Determining Signatory Status", {
            variant: "error",
          });
        }

        // get relevant asset balance
        const newAssetIssuanceTokenBalance = getTokenBalance(
          selectedAccount,
          props.marketListingViewModel.token.toFutureToken(),
        );
        if (newAssetIssuanceTokenBalance) {
          setAssetIssuanceTokenBalance(newAssetIssuanceTokenBalance);
        } else {
          setAssetIssuanceTokenBalance(
            new Balance()
              .setAmount(
                props.marketListingViewModel.token
                  .newAmountOf("0")
                  .toFutureAmount(),
              )
              .setLimit(
                props.marketListingViewModel.token
                  .newAmountOf("0")
                  .toFutureAmount(),
              )
              .setSellingliabilities(
                props.marketListingViewModel.token
                  .newAmountOf("0")
                  .toFutureAmount(),
              )
              .setBuyingliabilities(
                props.marketListingViewModel.token
                  .newAmountOf("0")
                  .toFutureAmount(),
              ),
          );
        }
      }
    })();
  }, [investorDirectSellOrderRequest.initiatingPartyGroupID]);

  // form validation each time request changes
  useEffect(() => {
    // clear any pending validation
    clearTimeout(validationTimeoutRef.current);

    // defer validation to take place in 200ms
    setValidationInProgress(true);
    clearTimeout(validationTimeoutRef.current);
    validationTimeoutRef.current = setTimeout(() => {
      setRequestValidationResult(
        validateInvestorSubmitDirectSellOrderRequest(
          investorDirectSellOrderRequest,
          calculateDirectOrderFeeResponse,
          assetIssuanceTokenBalance,
          props.assetIssuanceTokenViewModel,
          touchedFields,
          false,
          props.marketMechanismQuoteParameter,
          props.marketListingViewModel.assetFractionalisationAllowed,
        ),
      );
      setValidationInProgress(false);
    }, 200);
  }, [
    investorDirectSellOrderRequest,
    props.listing,
    calculateDirectOrderFeeResponse,
    assetIssuanceTokenBalance,
    props.assetIssuanceTokenViewModel,
    touchedFields,
  ]);

  // order submission
  const [orderSubmissionInProgress, setOrderSubmissionInProgress] =
    useState(false);
  const handleSubmitOrder = async () => {
    setOrderSubmissionInProgress(true);
    let directOrder: DirectOrder;
    try {
      // submit direct order
      directOrder = (
        await DirectOrderSubmitter.InvestorSubmitDirectSellOrder(
          investorDirectSellOrderRequest,
        )
      ).directOrder;

      // notify that submission is in progress
      enqueueSnackbar(`Order #${directOrder.number} is being submitted`, {
        variant: "info",
      });

      LogRocket.track(AssetEvent.placeDirectOrder, {
        assetName: props.marketListingViewModel.assetName,
        assetShortName: props.marketListingViewModel.assetShortName,
        assetType: props.marketListingViewModel.assetType,
        revenue: calculateDirectOrderFeeResponse.feeAmount.toNumber(),
      });

      // close the dialog
      props.closeDialog();
    } catch (e) {
      console.error(`submitting order`, e);
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(`Error Submitting Order: ${err.message}`, {
        variant: "warning",
      });
      setOrderSubmissionInProgress(false);
      return;
    }

    try {
      // register callback to fire once the order has reached awaiting confirmation
      const deregister = await registerNotificationCallback(
        new GroupNotificationChannel({
          groupID: directOrder.initiatingPartyGroupID,
          name: MarketDirectOrderViewNotificationChannelName,
          private: true,
        }),
        [MarketDirectOrderViewModelChangedNotificationTypeName],
        (n: Notification) => {
          if (
            n instanceof MarketDirectOrderViewModelChangedNotification &&
            n.model.directOrderID === directOrder.id
          ) {
            // notify based on state
            switch (n.model.state) {
              case DirectOrderState.Pending:
                // Do nothing during transient states
                // Return so that deregister is not called.
                return;

              case DirectOrderState.AwaitingConfirmation:
                // trigger the transaction started analytics event
                pushTransactionStart({
                  // use the Direct Order Number to uniquely identify the transaction
                  transaction_id: n.model.number,
                  transaction_type: TransactionTypes.directOrder,
                  // use the asset short name as transaction_asset_name
                  transaction_asset_name: n.model.assetName,
                  // use the combination asset code:issuer:network to create an asset id
                  transaction_asset_id: `${n.model.tokens.token.code}:${n.model.tokens.token.issuer}:${n.model.tokens.token.network}`,
                  // use the price on model to set asset sell price
                  transaction_asset_sell_price:
                    n.model.orderType === DirectOrderType.Sell
                      ? n.model.price.value.toString()
                      : "0",
                  // use the price on model to set asset buy price
                  transaction_asset_buy_price:
                    n.model.orderType === DirectOrderType.Buy
                      ? n.model.price.value.toString()
                      : "0",
                  // set the type of transaction in the case spot trades
                  transaction_asset_type:
                    props.marketListingViewModel.assetType,
                  // set transaction asset issuer
                  transaction_asset_issuer:
                    props.assetIssuanceTokenViewModel.issuer,
                  // use the instrument risk profile to set the asset risk rating
                  transaction_asset_risk_rating:
                    props.marketListingViewModel.instrumentRiskProfile,
                  transaction_stage: TransactionStage.start,
                  // this is the date in which the transaction is initiated
                  transaction_date: dayjs().format(),
                  // set the transaction slippage
                  transaction_slippage:
                    investorDirectSellOrderRequest.slippage.toString(),
                  // set transaction trade fee
                  transaction_trade_fee: n.model.feeAmount.value.toString(),
                  // set the transaction currency
                  transaction_currency:
                    props.assetValuationTokenViewModel.token.code,
                  // use the quote amount on the spot as the investment amount
                  transaction_investment_amount:
                    n.model.amountIncl.value.toString(),
                  // use asset listing instrument risk profile as investor profile
                  transaction_asset_investor_profile:
                    props.marketListingViewModel.instrumentRiskProfile.toString(),
                } as TransactionDetails);

                enqueueSnackbar(
                  `Order #${n.model.number} is awaiting confirmation`,
                  { variant: "success" },
                );
                break;

              case DirectOrderState.Failed:
                pushTransactionAbandon({
                  // use the Direct Order Number to uniquely identify the transaction
                  transaction_id: n.model.number,
                  transaction_type: TransactionTypes.directOrder,
                  // use the asset short name as transaction_asset_name
                  transaction_asset_name: n.model.assetName,
                  // use the combination asset code:issuer:network to create an asset id
                  transaction_asset_id: `${n.model.tokens.token.code}:${n.model.tokens.token.issuer}:${n.model.tokens.token.network}`,
                  // use the price on model to set asset sell price
                  transaction_asset_sell_price:
                    n.model.orderType === DirectOrderType.Sell
                      ? n.model.price.value.toString()
                      : "0",
                  // use the price on model to set asset buy price
                  transaction_asset_buy_price:
                    n.model.orderType === DirectOrderType.Buy
                      ? n.model.price.value.toString()
                      : "0",
                  // set the type of transaction in the case spot trades
                  transaction_asset_type:
                    props.marketListingViewModel.assetType,
                  // set transaction asset issuer
                  transaction_asset_issuer:
                    props.assetIssuanceTokenViewModel.issuer,
                  // use the instrument risk profile to set the asset risk rating
                  transaction_asset_risk_rating:
                    props.marketListingViewModel.instrumentRiskProfile,
                  transaction_stage: TransactionStage.abandon,
                  // this is the date in which the transaction is initiated
                  transaction_date: dayjs().format(),
                  // set the transaction slippage
                  transaction_slippage:
                    investorDirectSellOrderRequest.slippage.toString(),
                  // set transaction trade fee
                  transaction_trade_fee: n.model.feeAmount.value.toString(),
                  // set the transaction currency
                  transaction_currency: n.model.feeAmount.token.code.toString(),
                  // use the quote amount on the spot as the investment amount
                  transaction_investment_amount:
                    n.model.amountIncl.value.toString(),
                  // use asset listing instrument risk profile as investor profile
                  transaction_asset_investor_profile:
                    props.marketListingViewModel.instrumentRiskProfile.toString(),
                } as TransactionDetails);

                enqueueSnackbar(`Order #${n.model.number} has failed`, {
                  variant: "error",
                });
                break;

              case DirectOrderState.UnderInvestigation:
                enqueueSnackbar(
                  `Something has gone wrong with Order #${n.model.number} - it's status is being investigated`,
                  { variant: "warning" },
                );
                break;
            }
            deregister();
          }
        },
      );
    } catch (e) {
      console.error("error registring for order transaction notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Notifications on Order - Please Check the Orders Table and Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  const placeOrderButton = (
    <Tooltip
      placement={isMobile ? "top" : "bottom"}
      arrow
      title={(() => {
        switch (true) {
          case !requestValidationResult.valid:
            return requestValidationResult.fieldValidations.buttonTokens
              ? requestValidationResult.fieldValidations.buttonTokens
              : "Please ensure that all fields have been completed correctly";
          case feeCalculationInProgress:
            return "Calculation in Progress";
          case validationInProgress:
            return "Validation in Progress";
          case !userIsSignatoryOnTradingAcc:
            return "You are not a Signatory on the Trading Account";
        }
        return "";
      })()}
    >
      <span>
        <Button
          data-link-info={JSON.stringify({
            content_interaction_id: "transact-cta",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "place order",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.TransactionComplete,
          } as DataLinkInfoType)}
          sx={{
            height: {
              xs: "48px",
              sm: "33px",
            },
          }}
          fullWidth
          id="marketDirectOrderInvestorSellCard-submitOrder-button"
          color="primary"
          variant="contained"
          children="place order"
          disabled={
            feeCalculationInProgress ||
            validationInProgress ||
            !requestValidationResult.valid ||
            orderSubmissionInProgress ||
            !userIsSignatoryOnTradingAcc
          }
          onClick={handleSubmitOrder}
        />
      </span>
    </Tooltip>
  );

  return (
    <Root open fullScreen={isMobile}>
      <Header
        {...{
          disableClose: orderSubmissionInProgress,
          showLoading: orderSubmissionInProgress,
          ...props,
        }}
      />
      <DialogContent
        ref={dialogContentRef}
        sx={{
          backgroundColor: theme.palette.custom.midnight,
          width: { sm: 420 },
          display: "flex",
          flexDirection: "column",
          alignItems: { sm: "center" },
          padding: "unset !important",
        }}
        className={isMobile ? undefined : "meshScroll"}
      >
        {props.potentialTradingAccounts.length > 1 && (
          <Box
            sx={{
              width: "100%",
              margin: theme.spacing(1, 0),
              padding: {
                sm: theme.spacing(0, 6),
                xs: theme.spacing(0, 3),
              },
            }}
          >
            <Typography
              sx={{
                color: "text.disabled",
                marginBottom: theme.spacing(1),
              }}
              variant={"body2"}
            >
              Choose which account you would like to trade with:
            </Typography>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              id="marketPlaceDirectOrderInvestorSellCard-tradingAccount-autocomplete"
              disabled={orderSubmissionInProgress}
              fullWidth
              value={props.potentialTradingAccounts.find(
                (psa) =>
                  psa.getOwnerid() ===
                  investorDirectSellOrderRequest.initiatingPartyGroupID,
              )}
              disableClearable
              onChange={(
                e,
                selectedSourceAccountViewModel: StellarAccountViewModel | null,
              ) => {
                if (selectedSourceAccountViewModel) {
                  handleUpdateRequest("initiatingPartyGroupID")(
                    selectedSourceAccountViewModel.getOwnerid(),
                  );
                }
              }}
              options={props.potentialTradingAccounts}
              getOptionLabel={(option: StellarAccountViewModel) =>
                `${option.getGroupname()} ${option.getLabel()}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="marketPlaceDirectOrderInvestorSellCard-tradingAccount-autocompleteTextField"
                  label={"Account"}
                />
              )}
            />
          </Box>
        )}
        <Box
          className={classes.sectionWithPadding}
          sx={{
            backgroundColor: theme.palette.custom.cardInner,
          }}
        >
          <Typography
            className={classes.titleMessage}
            variant="h4"
            children="How much of the instrument would you like to sell?"
          />
          <TextNumField
            id="marketPlaceDirectOrderInvestorSellCard-tokens-textNumField"
            disallowNegative
            label="Tokens"
            noDecimalPlaces={7}
            fullWidth
            disabled={orderSubmissionInProgress}
            value={investorDirectSellOrderRequest.tokens.value}
            onChange={(e) =>
              handleUpdateRequest("tokens")(
                investorDirectSellOrderRequest.tokens.setValue(e.target.value),
              )
            }
            InputProps={{
              endAdornment: (
                <>
                  <Box
                    sx={{
                      borderRight: `0.5px solid ${theme.palette.text.disabled}`,
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      paddingRight: theme.spacing(1),
                      marginRight: theme.spacing(1),
                    }}
                  >
                    <InputAdornment
                      position={"end"}
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <Button
                        id={
                          "marketDirectOrderInvestorDirectOrder-sellCard-paySetMax-button"
                        }
                        sx={{
                          width: "39px !important",
                          minWidth: 0,
                          padding: theme.spacing(0, 0.5),
                        }}
                        variant={"text"}
                        color={"secondary"}
                        children={"max"}
                        onClick={() => {
                          if (
                            decimalToBigNumber(
                              assetIssuanceTokenBalance.getAmount()?.getValue(),
                            ).isZero() ||
                            !assetIssuanceTokenBalance.getTokenviewmodel()
                          ) {
                            return;
                          }
                          handleUpdateRequest("tokens")(
                            LedgerAmount.fromFutureAmount(
                              assetIssuanceTokenBalance.getAmount(),
                            ).setValue(
                              LedgerAmount.fromFutureAmount(
                                getAvailableBalance(assetIssuanceTokenBalance),
                              ).value,
                            ),
                          );
                        }}
                      />
                    </InputAdornment>
                  </Box>
                  <Tooltip
                    title={`Issued by ${props.assetIssuanceTokenViewModel.issuer}`}
                    placement="top"
                  >
                    <Typography
                      id={
                        "marketDirectOrderInvestorSellCard-tokensTextNumField-unitsEndAdornment"
                      }
                      variant="body1"
                      className={classes.textNumFieldCode}
                      children={props.assetIssuanceTokenViewModel.token.code}
                    />
                  </Tooltip>
                </>
              ),
            }}
            error={!!requestValidationResult.fieldValidations.tokens}
          />
          {!requestValidationResult.fieldValidations.tokens ? (
            // if there are no field validations to show then available balance
            <div className={classes.balanceLayout}>
              <Typography
                className={classes.balanceAvailableText}
                variant="caption"
                children="Available:"
              />
              <Amount
                id="marketDirectOrderInvestorSellCard-availableBalance-amountField"
                reverse
                codeTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                valueTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
                amount={
                  assetIssuanceTokenBalance.getTokenviewmodel()
                    ? LedgerAmount.fromFutureAmount(
                        getAvailableBalance(assetIssuanceTokenBalance),
                      )
                    : new LedgerAmount()
                }
              />
            </div>
          ) : (
            // otherwise show error text
            <Typography
              className={classes.errorHelperTextLayout}
              variant="body2"
              color="error"
              children={requestValidationResult.fieldValidations.tokens}
            />
          )}
        </Box>
        <div
          className={cx(
            classes.sectionWithPadding,
            classes.sectionWithBottomBorder,
          )}
        >
          <div className={classes.estimatedValuesTitle}>
            <Typography variant="h6" children="Estimated Values:" />
            <Tooltip
              title={infoIconContent}
              arrow
              placement={isMobile ? "top" : "right"}
            >
              <InfoIcon className={classes.estimatedValuesInfoIcon} />
            </Tooltip>
            {feeCalculationInProgress && <CircularProgress size={20} />}
          </div>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                mr: theme.spacing(2),
                mb: theme.spacing(3),
              }}
            >
              <Typography
                sx={(theme) => ({ marginBottom: theme.spacing(1) })}
                color="textSecondary"
                variant="subtitle1"
                children="Amount"
              />
              <Amount
                id="marketDirectOrderInvestorSellCard-amount-amountField"
                codeTypographyProps={{
                  variant: "h6",
                  className: cx(classes.ledgerAmountCode, {
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h6",
                  className: cx(classes.ledgerAmountValue, {
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.amountIncl,
                )}
              />
            </Box>
            <Box
              sx={{
                minWidth: "124px",
                mb: theme.spacing(3),
              }}
            >
              <Typography
                sx={(theme) => ({ marginBottom: theme.spacing(1) })}
                color="textSecondary"
                variant="subtitle1"
                children="Price per Token"
              />
              <Amount
                id="marketDirectOrderInvestorSellCard-price-amountField"
                codeTypographyProps={{
                  variant: "h6",
                  className: cx(classes.ledgerAmountCode, {
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "h6",
                  className: cx(classes.ledgerAmountValue, {
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={investorDirectSellOrderRequest.estimatedPrice}
              />
            </Box>
          </Box>

          <Box
            sx={{
              pb: theme.spacing(3),
              display: "flex",
              gap: theme.spacing(2),
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              className={classes.secondaryText}
              children="Total Fee:"
            />
            <Amount
              id="marketDirectOrderInvestorSellCard-totalFee-amountField"
              codeTypographyProps={{
                variant: "caption",
                color: "textSecondary",
                className: cx({
                  [classes.secondaryText]: feeCalculationInProgress,
                }),
              }}
              valueTypographyProps={{
                variant: "caption",
                color: "textSecondary",
                className: cx({
                  [classes.secondaryText]: feeCalculationInProgress,
                }),
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 2,
                addDecimalPadding: true,
              }}
              amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                calculateDirectOrderFeeResponse.feeAmount.plus(
                  calculateDirectOrderFeeResponse.vatAmount,
                ),
              )}
            />
            <Typography
              data-link-info={JSON.stringify({
                content_interaction_id: "info-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "Why these fees",
                content_interaction_type: InteractionType.Link,
                content_interaction_driver: InteractionDriver.Navigation,
              } as DataLinkInfoType)}
              id="marketDirectOrderInvestorSellCard-whyTheseFees-link"
              variant="caption"
              color="secondary"
              className={classes.tradeFeeWhyTheseFeesLink}
              onClick={() => window.open("https://mesh.trade/fees", "_blank")}
              children="Why these fees?"
            />
          </Box>
        </div>

        <div className={cx(classes.sectionWithPadding)}>
          <div
            data-link-info={JSON.stringify({
              content_interaction_id: "info-link",
              content_interaction_action: InteractionAction.Click,
              content_interaction_text: "Order Preview & Slippage",
              content_interaction_type: InteractionType.Icon,
              content_interaction_driver: InteractionDriver.MoreInfo,
            } as DataLinkInfoType)}
            className={classes.orderBreakdownControlRow}
          >
            <IconButton
              id="marketDirectOrderInvestorSellCard-orderBreakDownToggle-iconButton"
              data-link-info={JSON.stringify({
                content_interaction_id: "info-link",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "Order Preview & Slippage",
                content_interaction_type: InteractionType.Icon,
                content_interaction_driver: InteractionDriver.MoreInfo,
              } as DataLinkInfoType)}
              size="small"
              onClick={toggleShowOrderBreakDown}
            >
              {showOrderBreakdown ? (
                <ExpandLessIcon
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "Order Preview & Slippage",
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                  color="primary"
                />
              ) : (
                <ExpandMoreIcon
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-link",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "Order Preview & Slippage",
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                  color="primary"
                />
              )}
            </IconButton>
            <Typography variant="h6" children="Order Preview & Slippage" />
          </div>
          <Collapse in={showOrderBreakdown}>
            <Box
              sx={{
                display: "grid",
                paddingBottom: theme.spacing(3),
                gridTemplateColumns: "auto 1fr",
                columnGap: theme.spacing(5),
              }}
            >
              <Typography
                children="Pay Tokens:"
                className={classes.secondaryText}
                variant="caption"
              />
              <Amount
                id="marketDirectOrderInvestorSellCard-orderBreakDown-payTokens-amountField"
                reverse
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 7,
                  addDecimalPadding: false,
                }}
                amount={investorDirectSellOrderRequest.tokens}
              />
              <Typography
                children="Trade Fee:"
                className={classes.secondaryText}
                variant="caption"
              />
              <Amount
                id="marketDirectOrderInvestorSellCard-orderBreakDown-tradeFee-amountField"
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.feeAmount,
                )}
              />
              <Typography
                children="VAT on Fee:"
                className={classes.secondaryText}
                variant="caption"
              />
              <Amount
                id="marketDirectOrderInvestorSellCard-orderBreakDown-vat-amountField"
                codeTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                valueTypographyProps={{
                  variant: "caption",
                  className: cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  }),
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: 2,
                  addDecimalPadding: true,
                }}
                amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                  calculateDirectOrderFeeResponse.vatAmount,
                )}
              />
              <Typography
                className={classes.secondaryText}
                variant="caption"
                children="Receive Stablecoin:"
              />
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(0.5),
                  alignItems: "center",
                }}
              >
                <Amount
                  id="marketDirectOrderInvestorSellCard-orderBreakDown-receiveStablecoin-amountField"
                  codeTypographyProps={{
                    variant: "caption",
                    className: cx({
                      [classes.secondaryText]: feeCalculationInProgress,
                    }),
                  }}
                  valueTypographyProps={{
                    variant: "caption",
                    className: cx({
                      [classes.secondaryText]: feeCalculationInProgress,
                    }),
                  }}
                  formatTextNumOpts={{
                    noDecimalPlaces: 2,
                    addDecimalPadding: true,
                  }}
                  amount={props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                    calculateDirectOrderFeeResponse.amountIncl,
                  )}
                />
                <Typography
                  children="(Est.)"
                  variant="caption"
                  className={cx({
                    [classes.secondaryText]: feeCalculationInProgress,
                  })}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pt: 2,
                }}
              >
                <TextNumField
                  id="investorSellCard-Slippage-TextNumField"
                  disallowNegative
                  label="Slippage"
                  onChange={(e) =>
                    handleUpdateRequest("slippage")(
                      new BigNumber(e.target.value),
                    )
                  }
                  value={investorDirectSellOrderRequest.slippage}
                  error={!!requestValidationResult.fieldValidations.slippage}
                  helperText={requestValidationResult.fieldValidations.slippage}
                  FormHelperTextProps={{
                    sx: {
                      mx: 0,
                    },
                  }}
                  InputProps={{
                    margin: "dense",
                    endAdornment: (
                      <InputAdornment position="end" children="%" />
                    ),
                    sx: {
                      width: "120px",
                    },
                  }}
                />
                <Tooltip
                  arrow
                  title={`The amount of unfavourable price movement you are 
                          willing to accept, for movement in price between 
                          quoted price and execution price`}
                >
                  <InfoIcon
                    width="24px"
                    height="24px"
                    sx={{
                      ml: 1,
                      mt: 0.5,
                      color: theme.palette.secondary.light,
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Collapse>
          {!isMobile && (
            <Box
              sx={{
                width: "100%",
                paddingBottom: 5,
              }}
            >
              {placeOrderButton}
            </Box>
          )}
        </div>
        {isMobile && (
          <Box
            sx={{
              marginTop: "auto",
              position: "sticky",
              bottom: 0,
              backgroundColor: theme.palette.custom.midnight,
              padding: theme.spacing(3, 3, 4, 3),
              height: "104px",
              width: "100%",
              boxShadow: 24,
            }}
          >
            {placeOrderButton}
          </Box>
        )}
      </DialogContent>
    </Root>
  );
}
